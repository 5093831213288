import { ReadyPlanStatus, useSavePlanPackageMutation } from "src/generated/graphql-types";
import { Css, useModal } from "@homebound/beam";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";

export function ArchivePlanPackageModal({ planPackageId }: { planPackageId: string }) {
  const [savePlanPackage] = useSavePlanPackageMutation();
  const { closeModal } = useModal();

  async function archivePlanPackage() {
    await savePlanPackage({ variables: { input: { id: planPackageId, status: ReadyPlanStatus.Archived } } });
    closeModal();
  }

  return (
    <ConfirmationModal
      title="Archive Plan"
      label="Archive"
      danger
      onConfirmAction={async () => await archivePlanPackage()}
      confirmationMessage={
        <>
          <div css={Css.smSb.mb1.$}>Are you sure you want to archive this Plan?</div>
          <div>Once archived, this plan will not be available for creating Design Packages and Product Offerings.</div>
        </>
      }
    />
  );
}
