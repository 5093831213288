import { Css, IconButton, useTestIds } from "@homebound/beam";
import { CommentEditor } from "src/components";
import { getStreamForVisibility } from "src/components/comments/CommentFeed";
import { CommentFeedFragment, CommentStreamVisibility, useSaveCommentMutation } from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { Comment2 } from "./Comment";
import { formatAttachments } from "src/components/boundAttachments/BoundAttachments";

export type CommentFeedStyles = {
  mainStyles?: any;
  commentsContainerStyles?: any;
};

export type CommentFeedProps2 = {
  commentable: CommentFeedFragment;
  isInitiallyOpen?: boolean;
  hideToggle?: boolean;
  customStyles?: CommentFeedStyles;
};

export function CommentFeed2(props: CommentFeedProps2) {
  const { commentable, isInitiallyOpen = false, hideToggle = false, customStyles = {} } = props;
  const stream = getStreamForVisibility(commentable.streams, CommentStreamVisibility.Internal);
  const comments = stream?.comments || [];

  const testIds = useTestIds(props);
  const [saveCommentMutation] = useSaveCommentMutation();
  const [isOpen, toggleIsOpen] = useToggle(hideToggle || isInitiallyOpen); // If we hideToggle must be opened

  const styles: CommentFeedStyles = {
    mainStyles: Css.wPx(360).br8.px3.py2.bgGray100.$,
    commentsContainerStyles: Css.df.fdc.gap3.mt2.mb2.$,
    ...customStyles,
  };

  return (
    <div css={styles.mainStyles}>
      {!hideToggle && (
        <div css={Css.df.aic.baseMd.$}>
          <IconButton icon={isOpen ? "chevronDown" : "chevronRight"} onClick={toggleIsOpen} />
          <h2 css={Css.ml2.baseMd.$}>Comments</h2>
        </div>
      )}
      {isOpen && (
        <>
          {/** TODO: how literally should this follow bottom margin in mocks? Seems like mb4/6/sth might be better? */}
          {comments.nonEmpty && (
            <div css={styles.commentsContainerStyles}>
              {comments.map((comment) => (
                <Comment2 key={comment.id} comment={comment} {...testIds.comment} />
              ))}
            </div>
          )}
          <CommentEditor
            {...testIds.commentEditor}
            onSave={async (text, html, attachments, mentions) => {
              // TODO: The convention is that "anyone mentioned is a follower on the commentable" which makes notifications
              // work, because notifications only care about followers vs specific mentions in the comment.
              // However the designs don't include followers at all, so we will need to follow up on how to manage that
              await saveCommentMutation({
                variables: {
                  input: {
                    text,
                    html,
                    attachments: formatAttachments(attachments),
                    streamVisibility: CommentStreamVisibility.Internal,
                    commentableId: commentable.id,
                    internalUserTags: mentions,
                  },
                },
              });
            }}
          />
        </>
      )}
    </div>
  );
}
