import { BoundSelectField, Css, useTestIds } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { StatusIndicator } from "src/components/StatusIndicator";
import { PotentialOperationDetailsFragment, TaskStatus, TaskStatusesFragment } from "src/generated/graphql-types";

export type TaskStatusSelectProps = {
  label?: string;
  hideLabel?: boolean;
  options: TaskStatusesFragment[];
  statusField: FieldState<TaskStatus | null | undefined>;
  canComplete: PotentialOperationDetailsFragment;
  canStart: PotentialOperationDetailsFragment;
  disabled: boolean;
  dataTestId?: string;
  onSelect?: (status?: TaskStatus) => void;
};

export function TaskStatusSelect({
  label,
  hideLabel,
  options,
  statusField,
  canComplete,
  canStart,
  disabled,
  dataTestId,
  onSelect,
}: TaskStatusSelectProps) {
  const invalidOptions = [TaskStatus.OnHold];

  if (statusField.value !== TaskStatus.Delayed) invalidOptions.push(TaskStatus.Delayed);

  const disabledCompleteOptionTooltip = canComplete.disabledReasons.map((dr) => dr.message).join(", ");
  const disabledInProgressOptionTooltip = canStart.disabledReasons.map((dr) => dr.message).join(", ");
  const tids = useTestIds({}, `${dataTestId ? dataTestId : ""}`);

  return (
    <BoundSelectField
      getOptionValue={(ts) => ts.code}
      getOptionLabel={(ts) => ts.name}
      options={options.filter((status) => !invalidOptions.includes(status.code))}
      label={label}
      labelStyle={hideLabel ? "hidden" : "above"}
      field={statusField}
      fieldDecoration={(ts) => <StatusIndicator status={ts.code} />}
      getOptionMenuLabel={(ts) => (
        <div css={Css.df.aic.$}>
          <StatusIndicator status={ts.code} />
          <span css={Css.ml1.$}>{ts.name}</span>
        </div>
      )}
      disabledOptions={[
        ...(canComplete.allowed ? [] : [{ value: TaskStatus.Complete, reason: disabledCompleteOptionTooltip }]),
        ...(canStart.allowed ? [] : [{ value: TaskStatus.InProgress, reason: disabledInProgressOptionTooltip }]),
      ]}
      disabled={disabled}
      onSelect={onSelect}
      {...tids.status}
      compact
    />
  );
}
