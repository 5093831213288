import { Css, useTestIds } from "@homebound/beam";
import { Link } from "react-router-dom";
import { Price } from "src/components";
import { TaskDetailsPage_BillFragment } from "src/generated/graphql-types";

type BillsProps = {
  bills: TaskDetailsPage_BillFragment[];
};

export function Bills({ bills }: BillsProps) {
  const tid = useTestIds({}, "bills");
  return (
    <div css={Css.pb1.$}>
      <div css={Css.mt2.$}>
        <span css={Css.df.aic.jcsb.baseSb.gray800.$}>Bills</span>
      </div>
      {bills.nonEmpty ? (
        bills.map((b) => {
          return (
            <div css={Css.df.fdc.w100.mt1.$} key={b.id}>
              <div css={Css.dg.gtc("113px 80px 1fr 1fr").w100.cg1.rg1.smSb.aic.$}>
                <Link target="_blank" to={b.blueprintUrl.path} {...tid.billNumber}>
                  #{b.tradePartnerNumber}
                </Link>
                <span css={Css.smMd.$} {...tid.tradePartner}>
                  {b.tradePartner.name}
                </span>
                <span css={Css.maxwPx(120).tac.$}>
                  <span css={Css.smMd.$}>{b.status.name}</span>
                </span>
                <div css={Css.smMd.tar.$} {...tid.billTotal}>
                  <Price valueInCents={b.billedInCents} />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div {...tid.noBills} css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no bills associated to this task
        </div>
      )}
    </div>
  );
}
