import { Css } from "@homebound/beam";
import { MaterialCatalog_ItemFragment, MaterialCatalog_MaterialBrandFragment } from "src/generated/graphql-types";
import { MaterialForm } from "../../MaterialPage";
import { useMaterialSuperDrawerContext } from "./MaterialSuperDrawerContext";

export type MaterialOverviewTabProps = {
  items: MaterialCatalog_ItemFragment[];
  brands: MaterialCatalog_MaterialBrandFragment[];
};

/** Used in the superdrawer. */
export function MaterialOverviewTab(props: MaterialOverviewTabProps) {
  const { items, brands } = props;
  const { formState, variant } = useMaterialSuperDrawerContext();

  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.w100.bgWhite.p1.br8.$}>
        <MaterialForm
          isNew={false}
          hideNameField={true}
          canEditItem={variant?.listing.canEditItem}
          formState={formState}
          brands={brands}
          items={items}
        />
      </div>
    </div>
  );
}
