import { Accordion, BoundSelectField, Button, Css, FormLines, HasIdAndName, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { queryResult } from "src/utils";
import { BidPackageFormState } from "../AddBidPackagePage";
import {
  AssignTradeContacts_BidPackageFragment,
  AssignTradeContacts_BidPackageRequestFragment,
  NamedFragment,
  useAssignTradeContacts_BidPackageGroupQuery,
} from "src/generated/graphql-types";
import { useParams } from "react-router-dom";
import { BidPackageParams } from "src/routes/routesDef";
import { Card } from "src/components/Card";
import { TradePartnerContactModal } from "src/routes/trade-partners/TradePartnerContactModal";

export function AssignTradeContactsStep({ formState }: { formState: ObjectState<BidPackageFormState> }) {
  const { bidPackageGroupId } = useParams<BidPackageParams>();
  const query = useAssignTradeContacts_BidPackageGroupQuery({
    variables: { id: formState.bidPackageGroupId.value || bidPackageGroupId },
  });
  return queryResult(query, ({ bidPackageGroup, markets, tradePartnerMailingLists }) => (
    <AssignTradeContactsStepView
      bidPackages={bidPackageGroup.bidPackages}
      formState={formState}
      markets={markets}
      tradePartnerMailingLists={tradePartnerMailingLists}
    />
  ));
}

type AssignTradeContactsStepViewProps = {
  bidPackages: AssignTradeContacts_BidPackageFragment[];
  formState: ObjectState<BidPackageFormState>;
  markets: HasIdAndName[];
  tradePartnerMailingLists: HasIdAndName[];
};

function AssignTradeContactsStepView({
  bidPackages,
  formState,
  markets,
  tradePartnerMailingLists,
}: AssignTradeContactsStepViewProps) {
  // Bid packages will have the same market, so we can just grab the first one
  const marketId = bidPackages.first?.developments.first?.market.id;
  return (
    <>
      <div css={Css.df.fdc.aic.$}>
        <div css={Css.df.fdc.aic.my6.$}>
          <div css={Css.xl3Sb.$}>Assign Trade Contacts</div>
          <div css={Css.base.pt2.$}>
            Assign the primary and secondary trade contacts for your selected bidders. The estimator on file for
          </div>
          <div css={Css.base.$}>
            the trades are default selected, if there isn’t an estimator, the signatory is the default selection.
          </div>
        </div>
      </div>
      {bidPackages.map((bidPackage, idx) => {
        return (
          <Card xss={Css.wfc.mxa.$} key={bidPackage.id}>
            <div css={Css.mw("730px").$}>
              <Accordion
                defaultExpanded
                topBorder={false}
                title={<div css={Css.lgSb.$}>{`${bidPackage.name} Trades`}</div>}
              >
                <TradeContactsSection
                  requestInfos={bidPackage.requests}
                  bidPackageId={bidPackage.id}
                  formState={formState}
                  markets={markets}
                  tradePartnerMailingLists={tradePartnerMailingLists}
                  marketId={marketId}
                />
              </Accordion>
            </div>
          </Card>
        );
      })}
      ;
    </>
  );
}

type TradeContactsSectionProps = {
  requestInfos: AssignTradeContacts_BidPackageRequestFragment[];
  bidPackageId: string;
  markets: HasIdAndName[];
  tradePartnerMailingLists: HasIdAndName[];
  formState: ObjectState<BidPackageFormState>;
  marketId?: string;
};

function TradeContactsSection({
  requestInfos,
  bidPackageId,
  formState,
  markets,
  tradePartnerMailingLists,
  marketId,
}: TradeContactsSectionProps) {
  const modal = useModal();
  const fBidPackage = formState.bidPackages.rows.find((bp) => bp.id.value === bidPackageId);
  if (!fBidPackage) {
    return;
  }
  return requestInfos.map((request, idx) => {
    const contacts = request.tradePartner.contacts;
    // create a new array with updated names based on market contact role if it exists
    const contactsWithMarket: NamedFragment[] = [];
    if (marketId) {
      contacts.forEach((contact) => {
        const marketContact = contact.tradePartnerMarketContacts.find((tpmc) => tpmc.market.id === marketId);
        if (marketContact) {
          const nameAndRole = contact.name + " - " + marketContact.role.name;
          contactsWithMarket.push({ name: nameAndRole, id: contact.id });
        } else {
          contactsWithMarket.push({ name: contact.name, id: contact.id });
        }
      });
    }
    return (
      <div key={request.id} css={Css.my1.bgGray100.br8.mb2.df.fdc.p2.$}>
        <div css={Css.df.jcsb.pb3.$}>
          <div css={Css.df.fdc.$}>
            <div css={Css.xsBd.$}>BIDDER #{idx + 1}</div>
            <div css={Css.baseSb.$}>{request.tradePartner.name}</div>
          </div>
          <Button
            size="sm"
            label="+ Add Contact"
            variant="tertiary"
            onClick={() => {
              modal.openModal({
                size: "xl",
                content: (
                  <TradePartnerContactModal
                    tradePartnerId={request.tradePartner.id}
                    contact={undefined}
                    markets={markets}
                    tradePartnerMailingLists={tradePartnerMailingLists}
                  />
                ),
              });
            }}
          />
        </div>
        <FormLines labelStyle="left" compact width="full">
          <BoundSelectField
            label="Primary Contact"
            options={contactsWithMarket.isEmpty ? contacts : contactsWithMarket}
            field={fBidPackage.requests.rows[idx].primaryContactId}
          />
          <BoundSelectField
            label="Secondary Contact"
            helperText="(Optional)"
            options={contactsWithMarket.isEmpty ? contacts : contactsWithMarket}
            field={fBidPackage.requests.rows[idx].secondaryContactId}
          />
        </FormLines>
      </div>
    );
  });
}
