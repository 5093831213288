import {
  BoundNumberField,
  BoundRadioGroupField,
  Css,
  FormHeading,
  FormLines,
  ScrollableContent,
  useComputed,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useParams } from "react-router";
import {
  InvoiceSettingsTabQuery,
  InvoiceTabProjectFragment,
  MarkupApplicationMethod,
  Maybe,
  SaveProjectInvoiceSettingInput,
  useInvoiceSettingsTabQuery,
  useSaveInvoiceSettingsTabMutation,
} from "src/generated/graphql-types";

import { empty, queryResult } from "src/utils";

export function InvoicesTab() {
  const { projectId } = useParams<{ projectId: string }>();
  const query = useInvoiceSettingsTabQuery({ variables: { projectId } });

  return queryResult(query, {
    data: (data) => {
      return <InvoicesTabDataView data={data} />;
    },
  });
}

type InvoicesTabDataViewProps = {
  data: InvoiceSettingsTabQuery;
};

function InvoicesTabDataView({ data }: InvoicesTabDataViewProps) {
  const [saveInvoiceSettingsTab] = useSaveInvoiceSettingsTabMutation();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: data?.project.invoiceSettings,
      ifUndefined: {
        ...empty<InvoiceSettingsForm>(),
        requireWorkAuthorization: "no",
        requireGFormat: "no",
        requireTpCopiesBills: "no",
        requireTpSummaryListBills: "no",
        requireTpConditionalLw: "no",
        requireTpUnconditionalLw: "no",
        requireHbConditionalLw: "no",
        requireHbUnconditionalLw: "no",
        includesContractIncludesRetainage: "no",
      },
      map: (pis) => mapToForm(pis),
    },
    autoSave: async () => {
      if (formState.canSave()) {
        const values = mapToInput(formState.value);
        await saveInvoiceSettingsTab({
          variables: {
            input: {
              ...values,
              projectId: data.project.id,
            },
          },
        });
      }
    },
  });

  const showRetainageAmountPercent = useComputed(
    () => formState.includesContractIncludesRetainage.value === "yes",
    [formState],
  );

  return (
    <ScrollableContent>
      <div css={Css.df.gap6.mt2.$}>
        <div>
          <FormLines width="md">
            <FormHeading data-testid="formHeading" title="Invoice View" />
            <div css={Css.mb2.gray700.$}>These settings impact how project invoices will be generated.</div>
            <BoundRadioGroupField
              label="Markup Application Method"
              field={formState.markupApplicationMethod}
              options={[
                { value: MarkupApplicationMethod.PerLineItem, label: "Per Line Item" },
                { value: MarkupApplicationMethod.PerTotalCost, label: "Per Total Cost" },
              ]}
            />
            <BoundNumberField
              label="Markup Value"
              type="percent"
              field={formState.markupValuePercent}
              helperText="e.g. 20.00%"
            />
            <BoundNumberField label="Insurance Cost (USD)" type="cents" field={formState.insuranceCostInCents} />
            <BoundNumberField
              label="Insurance Markup"
              type="percent"
              field={formState.insuranceMarkupPercent}
              helperText="e.g. 5.00%"
            />
            <BoundRadioGroupField
              label="Work Authorizations Required?"
              field={formState.requireWorkAuthorization}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
            <BoundRadioGroupField
              label="Contract Includes Retainage"
              field={formState.includesContractIncludesRetainage}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
            {showRetainageAmountPercent ? (
              <BoundNumberField
                label="Retainage Amount"
                type="percent"
                field={formState.retainageAmountPercent}
                helperText="e.g. 10.00%"
              />
            ) : null}
            <BoundRadioGroupField
              label="G702/703 Format Required"
              field={formState.requireGFormat}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
            <BoundRadioGroupField
              label="Trade Partner Requires Copies of Bills"
              field={formState.requireTpCopiesBills}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
            <BoundRadioGroupField
              label="Trade Partner Requires Summary List of Bills"
              field={formState.requireTpSummaryListBills}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
            <div css={Css.mb2.gray900.baseMd.$}>Lien Waivers</div>
            <BoundRadioGroupField
              label="Trade Partner Conditional Lien Waivers Required? (Current Month)"
              field={formState.requireTpConditionalLw}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
            <BoundRadioGroupField
              label="Trade Partner Unconditional Lien Waivers Required? (Prior Month)"
              field={formState.requireTpUnconditionalLw}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
            <BoundRadioGroupField
              label="Homebound Conditional Lien Waivers Required? (Current Month)"
              field={formState.requireHbConditionalLw}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
            <BoundRadioGroupField
              label="Homebound Unconditional Lien Waivers Required? (Prior Month)"
              field={formState.requireHbUnconditionalLw}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
          </FormLines>
        </div>
      </div>
    </ScrollableContent>
  );
}

type InvoiceSettingsForm = Omit<
  SaveProjectInvoiceSettingInput,
  | "requireWorkAuthorization"
  | "includesContractIncludesRetainage"
  | "requireGFormat"
  | "requireTpCopiesBills"
  | "requireTpSummaryListBills"
  | "requireTpConditionalLw"
  | "requireTpUnconditionalLw"
  | "requireHbConditionalLw"
  | "requireHbUnconditionalLw"
> & {
  requireWorkAuthorization: Maybe<string>;
  includesContractIncludesRetainage: Maybe<string>;
  requireGFormat: Maybe<string>;
  requireTpCopiesBills: Maybe<string>;
  requireTpSummaryListBills: Maybe<string>;
  requireTpConditionalLw: Maybe<string>;
  requireTpUnconditionalLw: Maybe<string>;
  requireHbConditionalLw: Maybe<string>;
  requireHbUnconditionalLw: Maybe<string>;
};

const formConfig: ObjectConfig<InvoiceSettingsForm> = {
  id: { type: "value" },
  projectId: { type: "value" },
  markupApplicationMethod: { type: "value" },
  markupValuePercent: { type: "value", rules: [required] },
  insuranceCostInCents: { type: "value", rules: [required] },
  insuranceMarkupPercent: { type: "value", rules: [required] },
  requireWorkAuthorization: { type: "value", rules: [required] },
  includesContractIncludesRetainage: { type: "value", rules: [required] },
  retainageAmountPercent: { type: "value" },
  requireGFormat: { type: "value", rules: [required] },
  requireTpCopiesBills: { type: "value", rules: [required] },
  requireTpSummaryListBills: { type: "value", rules: [required] },
  requireTpConditionalLw: { type: "value", rules: [required] },
  requireTpUnconditionalLw: { type: "value", rules: [required] },
  requireHbConditionalLw: { type: "value", rules: [required] },
  requireHbUnconditionalLw: { type: "value", rules: [required] },
};

function mapToInput(formState: InvoiceSettingsForm): SaveProjectInvoiceSettingInput {
  const {
    requireWorkAuthorization,
    includesContractIncludesRetainage,
    requireGFormat,
    requireTpCopiesBills,
    requireTpSummaryListBills,
    requireTpConditionalLw,
    requireTpUnconditionalLw,
    requireHbConditionalLw,
    requireHbUnconditionalLw,
    ...others
  } = formState;

  return {
    requireWorkAuthorization: requireWorkAuthorization === "yes",
    includesContractIncludesRetainage: includesContractIncludesRetainage === "yes",
    requireGFormat: requireGFormat === "yes",
    requireTpCopiesBills: requireTpCopiesBills === "yes",
    requireTpSummaryListBills: requireTpSummaryListBills === "yes",
    requireTpConditionalLw: requireTpConditionalLw === "yes",
    requireTpUnconditionalLw: requireTpUnconditionalLw === "yes",
    requireHbConditionalLw: requireHbConditionalLw === "yes",
    requireHbUnconditionalLw: requireHbUnconditionalLw === "yes",
    ...others,
  };
}
function mapToForm(data: InvoiceTabProjectFragment): InvoiceSettingsForm {
  const {
    requireWorkAuthorization,
    includesContractIncludesRetainage,
    requireGFormat,
    requireTpCopiesBills,
    requireTpSummaryListBills,
    requireTpConditionalLw,
    requireTpUnconditionalLw,
    requireHbConditionalLw,
    requireHbUnconditionalLw,
    markupApplicationMethod,
    project,
    ...others
  } = data;

  return {
    requireWorkAuthorization: requireWorkAuthorization ? "yes" : "no",
    includesContractIncludesRetainage: includesContractIncludesRetainage ? "yes" : "no",
    requireGFormat: requireGFormat ? "yes" : "no",
    requireTpCopiesBills: requireTpCopiesBills ? "yes" : "no",
    requireTpSummaryListBills: requireTpSummaryListBills ? "yes" : "no",
    requireTpConditionalLw: requireTpConditionalLw ? "yes" : "no",
    requireTpUnconditionalLw: requireTpUnconditionalLw ? "yes" : "no",
    requireHbConditionalLw: requireHbConditionalLw ? "yes" : "no",
    requireHbUnconditionalLw: requireHbUnconditionalLw ? "yes" : "no",
    markupApplicationMethod: markupApplicationMethod?.code,
    projectId: project?.id,
    ...others,
  };
}
