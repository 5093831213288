import { BoundTextAreaField, Button, Css, ToggleButton, useComputed, useSnackbar } from "@homebound/beam";
import { useStepperContext, StepActions } from "src/components/stepper";
import { useDraftScheduleStore } from "./scheduleDraftStore";
import { useCallback, useMemo, Fragment, useState } from "react";
import {
  InputMaybe,
  SavePlanTaskScheduleFlagInput,
  ScheduleDraftMode_PlanTaskFragment,
  ScheduleDraftMode_ScheduleFlagReasonFragment,
  useSaveDraftPlanScheduleMutation,
  useScheduleDraftModeScheduleFlagReasonsQuery,
} from "src/generated/graphql-types";
import { differenceInBusinessDays, differenceInDays, isAfter, isSameDay } from "date-fns";
import { Card } from "src/components/Card";
import { DateOnly } from "src/utils/dates";
import { formatDate } from "src/components";
import { isDefined, numToStringWithSign, pluralize } from "src/utils";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { generateClientId } from "./ScheduleDraftModeDelayFlagModal";
import {
  BoundAttachments,
  SaveAttachmentModel,
  attachmentConfig,
} from "src/components/boundAttachments/BoundAttachments";
import pick from "lodash/pick";
import { BoundScheduleFlagReasonSelects } from "../components/BoundScheduleFlagReasonSelects";
import { reaction } from "mobx";
import { createListViewScheduleUrl } from "src/RouteUrls";
import { useHistory } from "react-router";

type DelayFlagPublishStepProps = {
  draftTasks: ScheduleDraftMode_PlanTaskFragment[];
  scheduleParentId: string;
};

type DraftTasksWithChanges = {
  draftTask: ScheduleDraftMode_PlanTaskFragment;
  initialValues: {
    initialStartDate: DateOnly | undefined;
    initialEndDate: DateOnly | undefined;
    initialDuration: number | undefined;
  };
};

type DraftTaskWithVariance = DraftTasksWithChanges & {
  varianceInDays: number;
};

export function DelayFlagPublishStep({ draftTasks, scheduleParentId }: DelayFlagPublishStepProps) {
  const { setSteps, nextStep } = useStepperContext();
  const history = useHistory();
  const { triggerNotice } = useSnackbar();
  const { data } = useScheduleDraftModeScheduleFlagReasonsQuery();
  const { scheduleFlagReasons } = data || { scheduleFlagReasons: [] };

  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const getInitialTaskValue = useDraftScheduleStore((state) => state.getInitialTaskValue);
  const userAddedDelayFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);

  const [saveDraftSchedule] = useSaveDraftPlanScheduleMutation();

  const taskWithDateOrDurationChanges: DraftTasksWithChanges[] = useMemo(() => {
    const taskChanges = draftTasks.map((draftTask) => {
      return getInitialValuesIfChanged(draftTask, getInitialTaskValue(draftTask.id));
    });
    return taskChanges.compact().sortBy((taskChange) => taskChange.draftTask.startDate);
  }, [draftTasks, getInitialTaskValue]);

  // Earliest "critical path" task that was delayed
  const taskToDelay: DraftTaskWithVariance | undefined = useMemo(() => {
    const delayedTask = taskWithDateOrDurationChanges.find((planTask) => planTask.draftTask.isCriticalPath);
    if (!delayedTask) return;

    const varianceInDays = getTaskVarianceInDays(delayedTask);
    // if the variance is negative, we don't want to show the delay flag
    if (varianceInDays < 0) return;

    return { ...delayedTask, varianceInDays };
  }, [taskWithDateOrDurationChanges]);

  const delayDuration = useMemo(() => {
    if (!taskToDelay) return;

    const { draftTask, initialValues } = taskToDelay;

    // Calculate delay if the start date has been pushed back
    if (isDefined(initialValues.initialStartDate) && isAfter(draftTask.startDate, initialValues.initialStartDate)) {
      return differenceInBusinessDays(draftTask.startDate, initialValues.initialStartDate);
    }

    // Calculate delay if the duration in days has been increased
    if (isDefined(initialValues.initialDuration) && draftTask.durationInDays > initialValues.initialDuration) {
      return draftTask.durationInDays - initialValues.initialDuration;
    }
  }, [taskToDelay]);

  const formState = useFormState({
    config: delayFormConfig,
    init: {
      // If we don't have an oldTaskStartDate or oldTaskEndDate set it to the current task start and end date (new BE rule requires this field)
      oldTaskStartDate: taskToDelay?.initialValues.initialStartDate ?? taskToDelay?.draftTask.startDate,
      oldTaskEndDate: taskToDelay?.initialValues.initialEndDate ?? taskToDelay?.draftTask.endDate,
      newTaskStartDate: taskToDelay?.draftTask.startDate,
      newTaskEndDate: taskToDelay?.draftTask.endDate,
      durationInDays: delayDuration,
      attachments: [],
    },
  });

  const selectedReason = useComputed(
    () => getSelectedReason(scheduleFlagReasons, formState.reasonId.value),
    [scheduleFlagReasons, formState.reasonId.value],
  );

  const noActiveAttachments = useComputed(
    () => formState.attachments.value.every(({ asset }) => asset.delete),
    [formState.attachments.value],
  );

  // Conditionally add/remove `required` to the description field. Using `reaction` as formstate was not re-rendering
  useMemo(() => {
    return reaction(
      () => formState.description.value,
      () => {
        const reasonId = formState.reasonId.value;
        if (reasonId && selectedReason?.descriptionIsRequired) {
          formState.description.rules.push(required);
        } else {
          formState.description.rules.splice(0, 1);
        }
      },
      { fireImmediately: true },
    );
  }, [formState, selectedReason?.descriptionIsRequired]);

  // Since attachments don't have a required field, we conditionally add the rules to the reasonId
  useMemo(() => {
    return reaction(
      () => formState.reasonId.value,
      () => {
        formState.reasonId.rules.push(() => {
          const reasonId = formState.reasonId.value;
          if (reasonId && selectedReason?.attachmentIsRequired && noActiveAttachments) {
            return "Photo is required for this reason type";
          } else {
            formState.reasonId.rules.splice(0, 1);
          }
        });
      },
      { fireImmediately: true },
    );
  }, [formState, selectedReason?.attachmentIsRequired, noActiveAttachments]);

  const onBackSelect = useCallback(() => {
    // use history to go back to previous step (calendar or table) instead of directly pushing a URL link
    history.goBack();
  }, [history]);

  const onPublish = useCallback(async () => {
    const { attachments, ...others } = formState.value;
    const delayedTaskChanges = taskToDelay
      ? {
          id: taskToDelay.draftTask.id,
          scheduleFlags: [
            {
              clientId: generateClientId(taskToDelay.draftTask.id, formState.reasonId.value),
              attachments: attachments?.map(({ asset }) => ({
                asset: pick(asset, [
                  // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
                  "contentType",
                  "fileName",
                  "id",
                  "s3Key",
                  "sizeInBytes",
                  "delete",
                ]),
              })),
              ...others,
            },
          ],
        }
      : null;

    await saveDraftSchedule({
      variables: {
        input: {
          scheduleParentId,
          draftTaskChanges: [
            ...draftTaskChanges,
            // If we have a task to delay, add the delay flag to the task
            ...(delayedTaskChanges ? [delayedTaskChanges] : []),
            // remove unnecessary fields from sending to the backend,merge the user added flags with draft changes
            ...userAddedDelayFlags.map(({ taskId, taskName, title, scheduleFlagReasonType, ...others }) => ({
              id: taskId,
              scheduleFlags: [{ ...others }],
            })),
          ],
        },
      },
    });
    history.push(createListViewScheduleUrl(scheduleParentId));
    triggerNotice({ message: "Draft schedule changes have been saved" });
  }, [
    saveDraftSchedule,
    draftTaskChanges,
    scheduleParentId,
    history,
    triggerNotice,
    formState,
    taskToDelay,
    userAddedDelayFlags,
  ]);

  // Will come back to using the stepper once we have the Trade Availability workflow
  // const onContinueSelect = useCallback(() => {
  //   if (taskToDelay) {
  //     const { attachments, ...others } = formState.value;
  //     setRequiredScheduleFlags([
  //       {
  //         clientId: generateClientId(taskToDelay?.draftTask.id, formState.reasonId.value),
  //         attachments: attachments?.map(({ asset }) => ({
  //           asset: pick(asset, [
  //             // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
  //             "contentType",
  //             "fileName",
  //             "id",
  //             "s3Key",
  //             "sizeInBytes",
  //             "delete",
  //           ]),
  //         })),
  //         ...others,
  //       },
  //     ]);
  //   }
  //   nextStep();
  //   setSteps((prevState) => [{ ...prevState[0], state: "complete" }, { ...prevState[1] }]);
  // }, [nextStep, setSteps, setRequiredScheduleFlags, taskToDelay, formState.value, formState.reasonId.value]);

  return (
    <Observer>
      {() => (
        <div css={Css.maxwPx(1200).ma.tac.mt6.$} data-testid="delayFlagPublishStep">
          <h1 css={Css.xl3Sb.$}>Add Delay Flag</h1>
          <p css={Css.base.mt2.mb5.$}>
            Review the tasks that have delay flags added to them. <br />
            (These include any manually added delay flags and the first task in critical path that you moved).
          </p>
          {taskToDelay && (
            <DelayFlagPublishForm
              taskToDelay={taskToDelay}
              formState={formState}
              scheduleFlagReasons={scheduleFlagReasons}
              selectedReason={selectedReason}
            />
          )}
          {!taskToDelay && userAddedDelayFlags.isEmpty && (
            <div data-testid="noDelays" css={Css.bgGray200.gray700.br8.p4.my2.$}>
              The changes made do not require any delay flags
            </div>
          )}
          {userAddedDelayFlags.map((flag) => {
            return (
              // adding another unique identifier since we may have delay flags on the same task
              <div key={`${flag.clientId}`}>
                <ZeroDayDelayFlagPublishForm
                  zeroDayDraftTask={flag}
                  key={`${flag.taskName}:${flag.reasonId}`}
                  name={flag.taskName}
                  scheduleFlagReasons={scheduleFlagReasons}
                />
              </div>
            );
          })}
          <AffectedTasks taskWithDateOrDurationChanges={taskWithDateOrDurationChanges} />
          <StepActions>
            <>
              <Button label="Back" onClick={onBackSelect} variant="tertiary" />
              {/* <Button
                label="Continue"
                onClick={onContinueSelect}
                size="md"
                disabled={taskToDelay && !formState.valid}
              /> */}
              <Button label="Publish" onClick={onPublish} disabled={taskToDelay && !formState.valid} />
            </>
          </StepActions>
        </div>
      )}
    </Observer>
  );
}

function AffectedTasks({ taskWithDateOrDurationChanges }: { taskWithDateOrDurationChanges: DraftTasksWithChanges[] }) {
  const [showTaskCard, setShowTaskCard] = useState(false);

  return (
    <>
      <ToggleButton
        label={`${taskWithDateOrDurationChanges.length} ${
          taskWithDateOrDurationChanges.length === 1 ? "Task was" : "Tasks were"
        } affected by the change above`}
        icon={showTaskCard ? "chevronUp" : "chevronDown"}
        onChange={() => setShowTaskCard(!showTaskCard)}
        selected={showTaskCard}
        disabled={taskWithDateOrDurationChanges.isEmpty}
      />

      <div
        css={Css.mt1.o100.if(!showTaskCard).o0.add("transition", "opacity 300ms").$}
        data-testid="draftModeAffectedTasks"
      >
        <Card>
          <div css={Css.dg.gtc("repeat(5, 1fr)").rgPx(4).sm.tal.$}>
            <div css={headerCellCss}>Task</div>
            <div css={headerCellCss}>Start Date</div>
            <div css={headerCellCss}>End Date</div>
            <div css={headerCellCss}>Duration</div>
            <div></div>
            {taskWithDateOrDurationChanges.map(({ draftTask, initialValues }) => (
              <Fragment key={draftTask.id}>
                <div css={taskNameCellCss} data-testid={`${draftTask.id}-name`}>
                  {draftTask.name}
                </div>
                <div css={changeCellCss} data-testid={`${draftTask.id}-start`}>
                  {getDateChangeText(initialValues.initialStartDate, draftTask.startDate) ?? "--"}
                </div>
                <div css={changeCellCss} data-testid={`${draftTask.id}-end`}>
                  {getDateChangeText(initialValues.initialEndDate, draftTask.endDate) ?? "--"}
                </div>
                <div css={changeCellCss} data-testid={`${draftTask.id}-duration`}>
                  {getDurationChangeText(initialValues?.initialDuration, draftTask.durationInDays)}
                </div>
                <div></div>
              </Fragment>
            ))}
          </div>
        </Card>
      </div>
    </>
  );
}

const headerCellCss = Css.smMd.$;
const taskNameCellCss = Css.xs.pr1.$;
const changeCellCss = Css.xs.gray700.$;

function getDateChangeText(initialDate: DateOnly | undefined, currentDate: DateOnly) {
  if (!initialDate) return undefined;
  return `${formatDate(initialDate, "medium")} -> ${formatDate(currentDate, "medium")}`;
}

function getDurationChangeText(initialDuration: number | undefined, currentDuration: number) {
  if (!initialDuration) return "--";
  const diff = currentDuration - initialDuration;
  const formattedNumber = numToStringWithSign(diff);
  return `${formattedNumber} ${pluralize(Math.abs(diff), "day")}`;
}

function getInitialValuesIfChanged(
  draftTask: ScheduleDraftMode_PlanTaskFragment,
  initialTaskValues?: ScheduleDraftMode_PlanTaskFragment,
) {
  if (!initialTaskValues) return undefined;

  const initialStartDate = isSameDay(initialTaskValues.startDate, draftTask.startDate)
    ? undefined
    : initialTaskValues.startDate;
  const initialEndDate = isSameDay(initialTaskValues.endDate, draftTask.endDate)
    ? undefined
    : initialTaskValues.endDate;
  const initialDuration =
    initialTaskValues.durationInDays === draftTask.durationInDays ? undefined : initialTaskValues.durationInDays;

  if (initialStartDate || initialEndDate || initialDuration) {
    return { draftTask, initialValues: { initialStartDate, initialEndDate, initialDuration } };
  }

  // If there are no date or duration changes, return undefined for easy filtering
  return undefined;
}

type DelayFlagPublishFormProps = {
  taskToDelay: DraftTaskWithVariance;
  formState: ObjectState<PlanTaskScheduleFlagFormInput>;
  scheduleFlagReasons: ScheduleDraftMode_ScheduleFlagReasonFragment[];
  selectedReason: ScheduleDraftMode_ScheduleFlagReasonFragment | undefined;
};

function DelayFlagPublishForm({
  taskToDelay,
  formState,
  scheduleFlagReasons,
  selectedReason,
}: DelayFlagPublishFormProps) {
  const { varianceInDays } = taskToDelay;
  return (
    <div data-testid="delayFlagForm" css={Css.bgWhite.br8.p4.my2.$}>
      <div css={Css.dg.cgPx(16).rgPx(4).sm.tal.$}>
        <div css={{ ...headerCellCss, ...Css.gc("1 / span 1").$ }}>Task</div>
        <div css={{ ...headerCellCss, ...Css.gc("2 / span 1").$ }}>Change</div>
        <div css={{ ...headerCellCss, ...Css.gc("3 / span 1").$ }}>Delay Category*</div>
        <div css={{ ...headerCellCss, ...Css.gc("4 / span 1").$ }}>Delay Reason*</div>
        <div css={{ ...taskNameCellCss, ...Css.gc("1 / span 1").$ }}>{taskToDelay.draftTask.name}</div>
        <div css={{ ...changeCellCss, ...Css.truncate.$ }}>
          {`Start ${getDateChangeText(taskToDelay.initialValues.initialStartDate, taskToDelay.draftTask.startDate)}` ??
            "No change"}
          <div>
            {`End ${getDateChangeText(taskToDelay.initialValues.initialEndDate, taskToDelay.draftTask.endDate)}` ??
              "No change"}
            {` (${numToStringWithSign(varianceInDays)} ${pluralize(varianceInDays, "day")})`}
          </div>
        </div>
        <BoundScheduleFlagReasonSelects
          field={formState.reasonId}
          scheduleFlagReasons={scheduleFlagReasons}
          isDelayFlagPublishStep
        />
        {selectedReason?.descriptionIsRequired && (
          <div css={Css.df.fdc.jcfe.gap1.mt2.$}>
            <div css={headerCellCss}>Description*</div>
            <BoundTextAreaField field={formState.description} labelStyle="hidden" />
          </div>
        )}
        <div></div>
        {selectedReason?.attachmentIsRequired && (
          <div css={Css.df.fdc.mt2.$}>
            <div css={headerCellCss}>Photo*</div>
            <BoundAttachments
              showTitle={false}
              field={formState.attachments}
              title="Photo*"
              uppyUploaderProps={{ dragDropHeight: 96, dragDropWidth: 96, dragDropText: "" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

type ZeroDayDelayFlagPublishFormProps = {
  zeroDayDraftTask: SavePlanTaskScheduleFlagInput;
  name: string;
  scheduleFlagReasons: ScheduleDraftMode_ScheduleFlagReasonFragment[];
};

function ZeroDayDelayFlagPublishForm({
  zeroDayDraftTask,
  name,
  scheduleFlagReasons,
}: ZeroDayDelayFlagPublishFormProps) {
  const scheduleFlagReason = scheduleFlagReasons.find((sfr) => zeroDayDraftTask.reasonId === sfr.id);
  return (
    <>
      <div data-testid="zeroDaydelayFlagForm" css={Css.bgWhite.br8.p4.my2.$}>
        <div css={Css.dg.gtc("repeat(3, 1fr)").rgPx(4).sm.tal.$}>
          <div css={headerCellCss}>Task</div>
          <div css={headerCellCss}>Change</div>
          <div css={headerCellCss}>Delay Reason</div>
          <div css={taskNameCellCss}>{name}</div>
          <div css={changeCellCss}>{"No change"}</div>
          <div css={taskNameCellCss}>
            {`${scheduleFlagReason?.scheduleFlagReasonType.name}, (${scheduleFlagReason?.title})`}
          </div>
        </div>
      </div>
    </>
  );
}

function getTaskVarianceInDays(task: DraftTasksWithChanges): number {
  const { initialEndDate } = task.initialValues;
  const { endDate } = task.draftTask;
  return differenceInDays(endDate.date, initialEndDate ?? endDate);
}

export type PlanTaskScheduleFlagFormInput = SavePlanTaskScheduleFlagInput & {
  attachments: InputMaybe<SaveAttachmentModel[]>;
};

export const delayFormConfig: ObjectConfig<PlanTaskScheduleFlagFormInput> = {
  id: { type: "value" },
  reasonId: { type: "value", rules: [required] },
  description: { type: "value" },
  attachments: {
    type: "list",
    config: attachmentConfig,
  },
  durationInDays: { type: "value" },
  oldTaskEndDate: { type: "value" },
  oldTaskStartDate: { type: "value" },
  newTaskEndDate: { type: "value" },
  newTaskStartDate: { type: "value" },
};

function getSelectedReason(
  selectedReasons: ScheduleDraftMode_ScheduleFlagReasonFragment[],
  reasonId: InputMaybe<string>,
) {
  return selectedReasons.find((reason) => reason.id === reasonId);
}
