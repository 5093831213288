import { useModal, useSnackbar } from "@homebound/beam";
import React, { useCallback, useState } from "react";
import { useStepperWizard, WizardStep } from "src/components/stepper/useStepperWizard/useStepperWizard";
import {
  useInternallySignBidContractRevisionMutation,
  useSaveOwnerPandaDocMutation,
} from "src/generated/graphql-types";
import { UploadOptions } from "../DevelopmentContractHeader";
import { ManageProjectBudgetsStep } from "./manageProjectsBudgetStep/ManageProjectsBudgetsStep";
import { SubmitChangeEventApprovalStepView } from "src/components/approvals/SubmitChangeEventApprovalStepView";
import { foldEnum } from "src/utils";

type UploadDevContractContextType = {
  bidContractRevisionId: string;
  selectedProjectIds: string[];
  setSelectedProjectIds: React.Dispatch<React.SetStateAction<string[]>>;
  /** The newly-created Change Event IDs from either Budget updates or managing POs */
  changeEventIds: string[];
  setChangeEventIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const UploadDevContractContext = React.createContext<UploadDevContractContextType>({
  bidContractRevisionId: "",
  selectedProjectIds: [],
  setSelectedProjectIds: () => {},
  changeEventIds: [],
  setChangeEventIds: () => {},
});

export function UploadDevelopmentContractProvider({
  bidContractRevisionId,
  children,
}: React.PropsWithChildren<{ bidContractRevisionId: string }>) {
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
  const [changeEventIds, setChangeEventIds] = useState<string[]>([]);
  return (
    <UploadDevContractContext.Provider
      value={{ bidContractRevisionId, selectedProjectIds, setSelectedProjectIds, changeEventIds, setChangeEventIds }}
    >
      {children}
    </UploadDevContractContext.Provider>
  );
}

export function useDevContractWizard(bidContractRevisionId: string, mode: UploadOptions) {
  const { uploadBcr, signBcr } = useDevContractWizardBcrUpload(bidContractRevisionId);
  const { openWizard } = useStepperWizard({
    steps,
    Provider: ({ children }) => (
      <UploadDevelopmentContractProvider bidContractRevisionId={bidContractRevisionId}>
        {children}
      </UploadDevelopmentContractProvider>
    ),
  });
  const { closeModal } = useModal();

  return useCallback(async () => {
    closeModal();
    await foldEnum(mode, {
      uploadOnly: () => uploadBcr,
      publishOnly: () => signBcr,
      else: () => openWizard,
    })();
  }, [closeModal, mode, openWizard, uploadBcr, signBcr]);
}

export function useDevContractWizardBcrUpload(bcrId: string) {
  const { triggerNotice } = useSnackbar();
  const [savePandaDoc] = useSaveOwnerPandaDocMutation({
    variables: { input: { ownerId: bcrId } },
    onCompleted: () => {
      triggerNotice({
        message: "Contract is uploading! Check back in a few minutes to view it in pandaDoc.",
        icon: "success",
      });
    },
  });
  const [signBidContractRevision] = useInternallySignBidContractRevisionMutation({
    variables: { input: { id: bcrId } },
    onCompleted: () => {
      triggerNotice({
        message: "Homebound budget estimate successfully published! Template costs will be updated in a few minutes.",
        icon: "success",
      });
    },
  });

  return { uploadBcr: savePandaDoc, signBcr: signBidContractRevision };
}

const steps: WizardStep[] = [
  { label: "Search Projects", render: ManageProjectBudgetsStep },
  {
    label: "Submit for Approval",
    render: () => (
      <UploadDevContractContext.Consumer>
        {({ changeEventIds, bidContractRevisionId }) => (
          <SubmitChangeEventApprovalStepView changeEventIds={changeEventIds} sourceId={bidContractRevisionId} />
        )}
      </UploadDevContractContext.Consumer>
    ),
  },
];
