import {
  BoundRichTextField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Css,
  FieldGroup,
  px,
  StaticField,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import {
  CeliSelectionOverviewTabChangeEventLineItemsFragment,
  CeliSelectionOverviewTabQuery,
  ChangeEventLineItemType,
  CostCode,
  CostType,
  SaveChangeEventLineItemInput,
  useCeliSelectionOverviewTabQuery,
  useCeliSelectionOverviewTabSaveCeliMutation,
} from "src/generated/graphql-types";
import { NotFound } from "src/routes/NotFound";
import { queryResult } from "src/utils";
import { entityNotFoundError } from "src/utils/error";

export type CeliSelectionOverviewTabProps = {
  changeEventLineItemId: string;
};

export function CeliSelectionOverviewTab({ changeEventLineItemId }: CeliSelectionOverviewTabProps) {
  const query = useCeliSelectionOverviewTabQuery({ variables: { filter: { id: [changeEventLineItemId] } } });

  return queryResult(query, {
    data: ({ changeEventLineItems, locations }) => {
      const celi = changeEventLineItems[0];

      if (!celi) {
        return <NotFound error={entityNotFoundError(changeEventLineItemId)} />;
      }

      return (
        <div css={Css.df.jcsb.gap2.$}>
          <OverviewTabForm celi={celi} locations={locations} />
        </div>
      );
    },
  });
}

type OverviewTabFormProps = {
  celi: CeliSelectionOverviewTabChangeEventLineItemsFragment;
  locations: CeliSelectionOverviewTabQuery["locations"];
};

function OverviewTabForm({ celi, locations }: OverviewTabFormProps) {
  const readOnly = !celi.changeEvent.canEditLineItems.allowed;
  const [saveCeli] = useCeliSelectionOverviewTabSaveCeliMutation();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: celi,
      map: (celi) => mapCeliToFormState(celi),
    },
    autoSave: async ({ changedValue }) => {
      await saveCeli({ variables: { celi: changedValue } });
      formState.commitChanges();
    },
    readOnly,
  });

  return (
    <form css={Css.fg1.maxw(px(480)).df.gap5.fdc.$}>
      <fieldset css={Css.df.fdc.gap2.$}>
        <legend css={Css.baseMd.gray900.mb2.$}>Details</legend>
        <FieldGroup>
          <StaticField label="Cost Type" value={formState.costType.value} />
          <StaticField label="Trade Category" value={formState.tradeCategoryName.value || "Unassigned"} />
          <StaticField label="Cost Code" value={formState.costCode.value} />
        </FieldGroup>
        <BoundTextField field={formState.name} label="Display Name" compact />
        <BoundSelectField
          compact
          field={formState.locationId}
          getOptionMenuLabel={(l) => l.name}
          options={locations}
          readOnly={readOnly || celi.type !== ChangeEventLineItemType.Add}
        />
      </fieldset>
      <fieldset css={Css.df.fdc.gap2.$}>
        <legend css={Css.baseMd.gray900.mb2.$}>Notes</legend>
        <BoundRichTextField field={formState.specifications} label="Specifications (visible to everyone)" />
        <BoundTextAreaField field={formState.tradePartnerNote} label="Trade Note (only visible to trades)" />
        <BoundTextAreaField
          field={formState.internalNote}
          label="Internal Note (only visible to Homebound employees)"
        />
      </fieldset>
    </form>
  );
}

type FormValue = Omit<SaveChangeEventLineItemInput, "costType"> & {
  costCode: CostCode["name"];
  costType: CostType;
  tradeCategoryName: string | null | undefined;
};

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  costCode: { type: "value", rules: [required], readOnly: true },
  costType: { type: "value", rules: [required], readOnly: true },
  internalNote: { type: "value" },
  locationId: { type: "value" },
  name: { type: "value", rules: [required] },
  specifications: { type: "value" },
  tradeCategoryName: { type: "value", readOnly: true },
  tradePartnerNote: { type: "value" },
};

function mapCeliToFormState(celi: CeliSelectionOverviewTabChangeEventLineItemsFragment): FormValue {
  return {
    id: celi.id,
    costCode: celi.projectItem.item.costCode.displayName,
    costType: celi.projectItem.costType,
    internalNote: celi.internalNote,
    locationId: celi.location?.id,
    name: celi.name,
    specifications: celi.specifications,
    tradeCategoryName: celi.projectItem.item.tradeCategory?.name,
    tradePartnerNote: celi.tradePartnerNote,
  };
}
