import FullCalendar, { CalendarOptions, createRef } from "@fullcalendar/react";
import { Css, Palette, ScrollableContent } from "@homebound/beam";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { RefObject, useMemo } from "react";
import {
  DynamicSchedulesCalendar_PlanTaskFragment,
  useDynamicSchedulesCalendarQuery,
} from "src/generated/graphql-types";
import { getPlanTaskData, renderTaskEventContent } from "./dynamicSchedulesCalendarUtils";
import { useHistory, useParams } from "react-router";
import { useQueryStorage } from "../../schedule-v2/table/filterUtils";
import { customDynamicSchedulesFilterDefault, mapToFilter } from "../components/DynamicSchedulesFilterModal";
import { TaskColorLegend } from "../../schedule-v2/components/TaskColorLegend";
import { StringParam, useQueryParams } from "use-query-params";
import { createTaskDetailsPageUrl } from "src/RouteUrls";

type DynamicSchedulesCalendarWrapperProps = Pick<
  CalendarOptions,
  "eventResize" | "eventDrop" | "eventContent" | "events" | "editable" | "eventClick"
> & {
  calendarRef: RefObject<FullCalendar>;
};

export function DynamicSchedulesCalendar() {
  const { projectId } = useParams<{ projectId: string }>();
  const [{ search }] = useQueryParams({ search: StringParam });

  const { queryStorage: filter } = useQueryStorage({
    storageKey: "dynamicSchedulesFilter",
    initialQueryStorage: customDynamicSchedulesFilterDefault,
  });

  const query = useDynamicSchedulesCalendarQuery({
    variables: {
      filter: {
        scheduleParent: [projectId],
        ...mapToFilter(filter),
        search,
      },
      page: {
        offset: 0,
        limit: 1000,
      },
    },
  });

  // FullCalendar still uses legacy refs
  const calendarRef = createRef<FullCalendar>();

  return (
    <ScrollableContent virtualized>
      <div css={Css.mr3.h100.$} data-testid="calendarViewContent">
        <DynamicScheduleCalendarDataView
          planTasks={query.data?.planTasks.entities ?? []}
          calendarRef={calendarRef}
          parentId={projectId}
        />
      </div>
    </ScrollableContent>
  );
}

type DynamicSchedulesCalendarDataProps = {
  planTasks: DynamicSchedulesCalendar_PlanTaskFragment[];
  calendarRef: RefObject<FullCalendar>;
  parentId: string;
};

export function DynamicScheduleCalendarDataView({
  planTasks,
  calendarRef,
  parentId,
}: DynamicSchedulesCalendarDataProps) {
  const history = useHistory();
  const { events, planTasksById } = useMemo(() => getPlanTaskData(planTasks), [planTasks]);

  return (
    <DynamicSchedulesCalendarWrapper
      calendarRef={calendarRef}
      editable={false}
      eventClick={({ event }) => history.push(createTaskDetailsPageUrl(parentId, event.id))}
      eventContent={(event) => renderTaskEventContent(event, planTasksById)}
      events={events}
    />
  );
}

// Copied from Beam to make FullCalendar's button groups match Beam's button styles
// TODO: Maybe on a hackday, create a fully custom view to allow using Beam components in lieu of FullCalendar defaults
const buttonStyles = {
  ...Css.z1.px2.bgWhite.bcGray300.bw1.ba.gray900.br0.$,
  "&:disabled": Css.gray400.cursorNotAllowed.bcGray300.$,
  // Our first button should have a rounded left border
  "&:first-of-type": Css.add("borderRadius", "4px 0 0 4px").$,
  // Our last button should have a rounded right border
  "&:last-of-type": Css.add("borderRadius", "0 4px 4px 0").$,
  // Nudge buttons one pixel to the left so they visually share a border
  "&:not(:first-of-type)": Css.mlPx(-1).$,
};

const baseStyles = {
  ...Css.h100.bgWhite
    .addIn(".fc-theme-standard", Css.bgWhite.bcGray100.$)
    .addIn(
      ".fc-daygrid-event, .fc-daygrid-block-event .fc-h-event fc-event .fc-event-start .fc-event-end .fc-event-past",
      Css.bn.$,
    )
    .addIn(".fc .fc-daygrid-day.fc-day-today", Css.bgBlue50.bcGray300.$)
    .addIn(".fc-highlight", Css.bgWhite.outline(`2px solid ${Palette.Blue700}`).add("outlineOffset", "-1px").$)
    .addIn(".fc", Css.p3.br8.$)
    .addIn("table.fc-scrollgrid, .fc .fc-scrollgrid-section-liquid > td", Css.br8.$)
    .addIn("h2.fc-toolbar-title", Css.xl.$)
    .addIn("div.fc-toolbar-chunk > div", Css.df.aic.$)
    .addIn("div.fc-toolbar-chunk > button.fc-today-button", Css.smMd.bgWhite.bcGray300.gray900.br4.$)
    .addIn("div.fc-toolbar-chunk > button.fc-today-button:disabled", Css.bgWhite.gray400.cursorNotAllowed.$)
    .addIn("div.fc div.fc-button-group > button, .fc .fc-button-primary:not(:disabled).fc-button-active", {
      ...Css.smMd.bgWhite.bcGray300.gray900.$,
      ...buttonStyles,
    })
    .addIn("button.fc-prev-button", {
      ...Css.bgWhite.bcGray300.gray900.mlPx(12).$,
      ...buttonStyles,
    })
    .addIn("button.fc-next-button", {
      ...Css.bgWhite.bcGray300.gray900.$,
      ...buttonStyles,
    })
    .addIn("button.fc-button:active:not(.fc-today-button:disabled)", Css.bgGray300.bcGray300.gray900.important.$)
    .addIn("button.fc-button:focus, button.fc-button:active", Css.important.bsh0.$)
    .addIn(".fc-timeline-event-harness", Css.top0.important.$)
    .addIn(".fc-resource-timeline table tbody tr .fc-datagrid-cell div", Css.hPx(33).important.$).$,
};

export function DynamicSchedulesCalendarWrapper(props: DynamicSchedulesCalendarWrapperProps) {
  const { calendarRef, eventContent, events, eventDrop, eventResize, eventClick, editable } = props;
  return (
    <div css={baseStyles}>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, ...(editable ? [interactionPlugin] : [])]}
        headerToolbar={{ left: "title,prev,next", center: "today,dayGridWeek,dayGridMonth", end: "" }}
        events={events}
        eventClick={eventClick}
        height={"calc(100% - 30px)"}
        dayHeaderContent={({ text }) => <div css={Css.smMd.gray900.mb1.$}>{text}</div>}
        dayCellContent={({ dayNumberText }) => <div css={Css.xs.blue700.$}>{dayNumberText}</div>}
        eventContent={eventContent}
        editable={editable}
        droppable={editable}
        eventResize={eventResize}
        eventDrop={eventDrop}
      />
      <TaskColorLegend
        title="Color Legend:"
        items={[
          { color: Palette.Blue500, label: "A day that this task was worked on" },
          { color: Palette.Blue200, label: "Estimated work days / No work done" },
          { color: Palette.Gray800, icon: "truck", label: "Material Drop", sizeOverride: 2 },
          { color: Palette.Red600, icon: "flag", label: "Delay Flag", sizeOverride: 2 },
          { color: Palette.Gray800, icon: "hardHatInverse", label: "Trade - Worked", sizeOverride: 2 },
          { color: Palette.Gray800, icon: "hardHat", label: "Trade - Scheduled", sizeOverride: 2 },
        ]}
      />
    </div>
  );
}
