import { BoundSelectField, BoundTextField, Css, useComputed } from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  BusinessFunctionType,
  SaveGlobalMilestoneGroupInput,
  useBusinessFunctionTypeQuery,
  useSaveGlobalPlanMilestoneGroupMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { SaveGlobalPlanMilestoneFormValue } from "../MilestoneCatalogForm";
import { useMemo } from "react";

export function AddGlobalPlanMilestoneGroupModal({
  formState,
}: {
  formState: ObjectState<SaveGlobalPlanMilestoneFormValue>;
}) {
  const { data } = useBusinessFunctionTypeQuery();
  const [saveGlobalPlanMilestoneGroup] = useSaveGlobalPlanMilestoneGroupMutation();
  const groupFormState = useFormState({
    config: globalPlanMilestoneGroupConfig,
  });
  const enums = data?.enumDetails.businessFunctionType;

  const currentTreeItem = useMemo(() => {
    return formState.groups.value ?? [];
  }, [formState]);

  return (
    <Observer>
      {() => (
        <ConfirmationModal
          confirmationMessage={
            <div css={Css.df.fdc.gap2.$}>
              <BoundTextField field={groupFormState.name} label="Group Name" />
              <BoundSelectField
                label="Assign to a team"
                options={enums ?? []}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ code }) => code as BusinessFunctionType}
                field={groupFormState.businessFunctionType}
              />
            </div>
          }
          label="Add"
          disabled={!groupFormState.valid}
          onConfirmAction={async () => {
            const result = await saveGlobalPlanMilestoneGroup({
              variables: { input: groupFormState.value },
              refetchQueries: ["MilestoneCatalogFormDropdown"],
            });
            const groupId = result.data?.saveGlobalPlanMilestoneGroup.globalPlanMilestoneGroup.id;
            if (groupId) {
              // reinitialize the tree select
              formState.set({ groups: [...currentTreeItem, groupId] });
            }
          }}
          title="Add a New Group"
        />
      )}
    </Observer>
  );
}

const globalPlanMilestoneGroupConfig: ObjectConfig<SaveGlobalMilestoneGroupInput> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  businessFunctionType: { type: "value", rules: [required] },
};
