import { Tag, useTestIds } from "@homebound/beam";
import { Link } from "react-router-dom";
import { TaskParentPillFragment } from "src/generated/graphql-types";
import { getTaskParentTitleAndUrl } from "../utils";
import { ActionItemTypes } from "../enums";

// generic component to render a tag with a link to the task parent's schedule
export function TaskParentTag({
  taskParent,
  actionType,
}: {
  taskParent: TaskParentPillFragment;
  actionType?: ActionItemTypes;
}) {
  const { parentTitle, url } = getTaskParentTitleAndUrl(taskParent);
  const tids = useTestIds({}, actionType);
  return (
    <Link to={url}>
      <Tag text={parentTitle} {...tids.taskParentTag} />
    </Link>
  );
}
