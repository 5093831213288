import { column, Css, GridTable, Icon, useComputed } from "@homebound/beam";
import { ListFieldState } from "@homebound/form-state";
import { FormMode } from "src/components";
import { WorkAuthorizationDocumentFragment } from "src/generated/graphql-types";

type WorkAuthorizationDocumentsTableProps = {
  mode: FormMode;
  documents: ListFieldState<WorkAuthorizationDocumentFragment>;
  onDelete: (document: WorkAuthorizationDocumentFragment) => void;
};

export function WorkAuthorizationDocumentsTable({ mode, documents, onDelete }: WorkAuthorizationDocumentsTableProps) {
  const columns = createColumns(onDelete, mode);
  const rows = useComputed(() => createRows(documents), [documents]);

  if (documents.rows.length === 0) return null;

  return (
    <div css={Css.w50.$}>
      <GridTable
        id="workAuthorizationDocumentsTable"
        style={{ rowHeight: "fixed" }}
        columns={columns}
        rows={rows}
        data-testid="WorkAuthorizationDocumentsTable"
      />
    </div>
  );
}

function createColumns(onDelete: WorkAuthorizationDocumentsTableProps["onDelete"], mode: FormMode) {
  return [
    column<DocumentRow>({
      document: ({ name, asset }) => ({
        content: name,
        onClick: asset.downloadUrl,
      }),
    }),
    column<DocumentRow>({
      document: (document) => ({
        alignment: "right",
        content: mode === "read" ? <></> : <Icon icon="trash" data-testid="trash" color="inherit" />,
        onClick: () => onDelete(document),
      }),
    }),
  ];
}

function createRows(documents: WorkAuthorizationDocumentsTableProps["documents"]) {
  return documents.value.map((document) => ({
    id: document.id,
    kind: "document" as const,
    data: document,
  }));
}

type DocumentRow = {
  kind: "document";
  id: string;
  data: WorkAuthorizationDocumentFragment;
};
