import { Button, useModal, useSnackbar } from "@homebound/beam";
import { usePublishTakeoffsMutation } from "src/generated/graphql-types";
import { useCallback } from "react";
import { AddItemTemplatesReasonCodesModal } from "src/routes/developments/templates/components/AddItemTemplatesReasonCodesModal";

export function PublishTakeoffsButton({ id }: { id: string }) {
  const { openModal } = useModal();
  const [activateTemplates] = usePublishTakeoffsMutation();
  const { triggerNotice } = useSnackbar();

  // Given the Review step is generally considered useless - we will simply publish after the reason is provided
  const publish = useCallback(async () => {
    const response = await activateTemplates({ variables: { input: { templateId: [id] } } });
    const { activatedTemplates } = response.data?.activateItemTemplateVersions ?? {
      activatedTemplates: [],
    };
    if (activatedTemplates.length === 1) {
      // template activated
      triggerNotice({ message: "Template successfully published", icon: "success" });
    } else {
      // failed for some reason
      triggerNotice({ message: "Some templates can't be activated", icon: "alert" });
    }
  }, [activateTemplates, id, triggerNotice]);

  return (
    <Button
      variant="primary"
      label="Publish"
      onClick={() =>
        openModal({
          content: <AddItemTemplatesReasonCodesModal onContinue={publish} itemTemplatesIds={[id]} />,
        })
      }
    />
  );
}
