import {
  BoundSelectField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, Rule, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import {
  InvoiceProjectStageFragment,
  Maybe,
  SaveInvoiceInput,
  Stage,
  useInvoiceProjectStageModalDetailsQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { isContractualStage } from "src/utils/projects";

export type InvoiceProjectStageModalProps = {
  projectId: string;
  onSave: (stage: Stage) => void;
};

export function InvoiceProjectStageModal({ onSave, projectId }: InvoiceProjectStageModalProps) {
  const query = useInvoiceProjectStageModalDetailsQuery({ variables: { projectId } });

  return queryResult(query, {
    data: ({ project }) => <InvoiceProjectStageContent onSave={onSave} projectStages={project.stages} />,
  });
}

type InvoiceProjectStageContentProps = {
  projectStages: InvoiceProjectStageFragment[];
  onSave: InvoiceProjectStageModalProps["onSave"];
};

function InvoiceProjectStageContent({ onSave, projectStages }: InvoiceProjectStageContentProps) {
  const { closeModal } = useModal();
  const formConfig = useMemo(() => createFormConfig(projectStages), [projectStages]);
  const formState = useFormState({ config: formConfig });

  const selectedStage = useComputed(
    () => projectStages.find((ps) => ps.id === formState.projectStageId.value),
    [projectStages, formState],
  );

  return (
    <>
      <ModalHeader>Create Invoice</ModalHeader>
      <ModalBody>
        <div css={Css.mb2.$}>
          <BoundSelectField
            label={"Select the project stage"}
            field={formState.projectStageId}
            options={projectStages.filter((s) => isContractualStage(s.stage.code))}
            getOptionLabel={({ stage }) => stage.name}
            getOptionValue={({ id }) => id}
            disabledOptions={projectStages.filter((ps) => !ps.hasSignedContract).map((ps) => ps.id)}
            helperText={"Only signed contracts can be selected"}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Close" variant="secondary" onClick={closeModal} />
        <Button
          label="Create"
          disabled={selectedStage === undefined}
          variant="primary"
          onClick={() => {
            onSave(selectedStage!.stage.code);
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}

type FormInput = Pick<SaveInvoiceInput, "projectStageId">;

function createFormConfig(stages: InvoiceProjectStageFragment[]): ObjectConfig<FormInput> {
  return {
    projectStageId: { type: "value", rules: [required, projectStageRule(stages)] },
  };
}

function projectStageRule(projectStages: InvoiceProjectStageFragment[]): Rule<Maybe<string>> {
  return ({ value }) => {
    if (value) {
      const selectedStage = projectStages.find((ps) => ps.id === value);
      if (
        selectedStage &&
        !selectedStage.hasCostsToInvoice &&
        !selectedStage.hasDrawsToInvoice &&
        !selectedStage.hasFixedToInvoice
      ) {
        return `This project stage doesn't have any bills, draws or fixed line items to invoice.`;
      }
    }
    return undefined;
  };
}
