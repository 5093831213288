import { Css, useTestIds } from "@homebound/beam";
import { Link } from "react-router-dom";
import { HomeboundLogo } from "src/components";
import { Pdf_TradePartnerContactFragment } from "src/generated/graphql-types";

type PdfFooterProps = { tradeContact: Pdf_TradePartnerContactFragment | undefined | null };

export function PdfFooter({ tradeContact }: PdfFooterProps) {
  const tid = useTestIds({}, "footer");
  return (
    <div css={Css.bt.mt8.bcGray300.bw1.$}>
      <div css={Css.dg.gtc("200px 240px 200px").tiny.gap4.mt5.$}>
        <div css={Css.mt3.$} {...tid.logo}>
          <HomeboundLogo width={100} height={31} />
        </div>

        <div css={Css.df.fdc.jcfs.aifs.$} {...tid.hbAccountingInfo}>
          <div css={Css.gray600.mb1.$}>Pay from</div>
          <div>Homebound</div>
          <div>1 Letterman Dr, C-3500</div>
          <div>San Francisco, CA 94219</div>
          <div>901-566-6789</div>
          <Link
            to="mailto:accounting@homebound.com"
            css={Css.tiny.addIn("&:hover", Css.textDecoration("none").bb.bcWhite.pb2.$).$}
          >
            accounting@homebound.com
          </Link>
        </div>

        {tradeContact && (
          <div css={Css.df.fdc.jcfs.aifs.$} {...tid.tradeSignatoryInfo}>
            <div css={Css.gray600.mb1.$}>Pay to</div>
            <div>{tradeContact.tradePartner.name}</div>
            <div>{tradeContact.officePhone}</div>
            <Link
              to={`mailto:${tradeContact?.email}`}
              css={Css.tiny.addIn("&:hover", Css.textDecoration("none").bb.bcWhite.pb2.$).$}
            >
              {tradeContact.email}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
