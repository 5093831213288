import { Banner, Button, Css, IconButton, ScrollableContent, ScrollableParent, useModal } from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  BidContractType,
  BidPackageItemTemplateItemFragment,
  EditBidContractTradeLineItemFragment,
  Maybe,
  SaveBidContractTradeLineItemInput,
  SaveBidContractTradeLineItemProrationInput,
  SaveBidContractTradeLineItemsInput,
  useAddProrationBidMutation,
  useEditProratedBidRevisionQuery,
  useSaveProrationTradeLineItemsMutation,
} from "src/generated/graphql-types";
import { isDefined, prorate, queryResult, roundToDigits } from "src/utils";
import { PlanAndOptionTables, initializePlanAndOptionFormState } from "./proration/PlanAndOptionTables";
import { MaterialTables, initializeMaterialFormState } from "./proration/MaterialTables";
import { setupPriceFields } from "src/routes/projects/selections/recalc";
import { DateOnly } from "src/utils/dates";
import { useState } from "react";
import { useReaction } from "src/hooks";
import { getStage } from "src/context";

type NewBidContractParams = {
  bidRequestId: string;
  developmentIds: string[];
  tradePartnerId: string;
  type: BidContractType;
};

type AddProrationBidModalProps = {
  newBidContractParams: NewBidContractParams;
  tradePartnerName: string;
  scopeLineItems: BidPackageItemTemplateItemFragment[];
};

export function AddProrationBidModal({
  newBidContractParams,
  scopeLineItems,
  tradePartnerName,
}: AddProrationBidModalProps) {
  return (
    <ProrateBidView
      newBidContractParams={newBidContractParams}
      scopeLineItems={scopeLineItems}
      type={newBidContractParams.type}
      tradePartnerName={tradePartnerName}
    />
  );
}

type EditProrationBidModalProps = {
  bidContractRevisionId: string;
  scopeLineItems: BidPackageItemTemplateItemFragment[];
};

export function EditProrationBidModal({ bidContractRevisionId, scopeLineItems }: EditProrationBidModalProps) {
  const query = useEditProratedBidRevisionQuery({ variables: { bidContractRevisionId } });
  return queryResult(query, ({ bidContractRevision }) => (
    <ProrateBidView
      bidContractRevisionId={bidContractRevisionId}
      scopeLineItems={scopeLineItems}
      type={bidContractRevision.bidContract.type!.code}
      tradePartnerName={bidContractRevision.bidContract.tradePartner?.name ?? ""}
      existingTradeLineItems={bidContractRevision.tradeLineItems}
    />
  ));
}

type ProrateBidViewProps = {
  bidContractRevisionId?: string;
  newBidContractParams?: Maybe<NewBidContractParams>;
  tradePartnerName: string;
  type: BidContractType;
  scopeLineItems: BidPackageItemTemplateItemFragment[];
  existingTradeLineItems?: EditBidContractTradeLineItemFragment[];
};

function ProrateBidView({
  bidContractRevisionId,
  newBidContractParams,
  scopeLineItems,
  tradePartnerName,
  type,
  existingTradeLineItems = [],
}: ProrateBidViewProps) {
  const [error, setError] = useState<string | undefined>();
  const [addProrationBid] = useAddProrationBidMutation({
    // Show BE validation errors in the modal
    onError: (error) => {
      setError(error.message);
      throw error;
    },
  });
  const [saveProrationTradeLineItems] = useSaveProrationTradeLineItemsMutation({
    // Show BE validation errors in the modal
    onError: (error) => {
      setError(error.message);
      throw error;
    },
  });
  const { closeModal } = useModal();
  // This allows us to fail when creating the line items but still reference a bid revision that was created
  const [persistedRevisionId, setPersistedRevisionId] = useState(bidContractRevisionId);

  const formState = useFormState({
    config: formConfig,
    // Update when we are supporting more than just grouped by material & plan and option
    init:
      type === BidContractType.GroupedByMaterial
        ? initializeMaterialFormState(scopeLineItems, existingTradeLineItems)
        : initializePlanAndOptionFormState(scopeLineItems, existingTradeLineItems),
    addRules(state) {
      state.bidContractTradeLineItems.rows.forEach((row) => {
        // Recalc cost/markup/price for each trade line item
        setupPriceFields(
          {
            totalCostInCents: row.totalCostInCents,
            unitCostInCents: row.unitCostInCents,
            quantity: row.quantity,
          },
          { canEditPrice: false, unitFieldDecimalPlaces: 1 },
        );

        row.prorations.rows.forEach((proration) => {
          // Recalc cost/markup/price for each proration
          setupPriceFields(
            {
              totalCostInCents: proration.costInCents,
              unitCostInCents: proration.unitCostInCents,
              quantity: proration.quantity,
            },
            { canEditPrice: false, unitFieldDecimalPlaces: 1 },
          );
        });
      });
    },
  });

  // If one of the trade totals have changed then we should update the prorations
  useReaction(
    () => [...formState.bidContractTradeLineItems.rows.map((r) => r.totalCostInCents.value)],
    (totals, prevTotals) => {
      // For now we are hiding proration estimates in prod, until we get real cost estimates to prorate
      if (getStage() === "prod") return;

      for (let index = 0; index < totals.length; index++) {
        if (isDefined(totals[index]) && totals[index] !== prevTotals[index]) {
          updateProrationCosts(formState.bidContractTradeLineItems.rows[index]);
        }
      }
    },
    [formState],
  );

  // If a proration cost was user entered or if a user cleared out a proration cost then we should update the prorations
  useReaction(
    () => [
      ...formState.bidContractTradeLineItems.rows.flatMap((r, i) =>
        r.prorations.rows.map((p) => ({
          tradeIndex: i,
          prorationCost: p.costInCents.value,
          userEntered: p.userEntered.value,
        })),
      ),
    ],
    (totals, prevTotals) => {
      // For now we are hiding proration estimates in prod, until we get real cost estimates to prorate
      if (getStage() === "prod") return;

      for (let index = 0; index < totals.length; index++) {
        if (
          // If the user modified a cost
          (isDefined(totals[index]) &&
            totals[index].prorationCost !== prevTotals[index].prorationCost &&
            totals[index].userEntered) ||
          // if the user cleared out a cost
          totals[index].userEntered !== prevTotals[index].userEntered
        ) {
          updateProrationCosts(formState.bidContractTradeLineItems.rows[totals[index].tradeIndex]);
        }
      }
    },
    [formState],
  );

  async function saveAndExit() {
    let revisionId = persistedRevisionId;
    // If we are creating a new prorated bid we will first need to create the bid contract
    if (newBidContractParams && !revisionId) {
      const result = await addProrationBid({
        variables: {
          input: {
            ...newBidContractParams,
            startDate: new DateOnly(new Date()),
          },
        },
      });
      revisionId = result.data?.saveBidContract.bidContract.currentRevision.id;
      setPersistedRevisionId(revisionId);
    }

    await saveProrationTradeLineItems({
      variables: {
        input: mapFormValuesToInput(formState.value, revisionId),
      },
      refetchQueries: ["BidPackageDetailPage"],
    });
    closeModal();
  }

  return (
    <ScrollableParent xss={Css.df.fdc.p2.bgGray100.h100.relative.$}>
      <div css={Css.absolute.right3.top3.$}>
        <IconButton icon="x" onClick={closeModal} />
      </div>
      <ScrollableContent>
        {error && (
          <div css={Css.relative.z1.$}>
            <Banner message={error} type="error" onClose={() => setError(undefined)} />
          </div>
        )}
        <div css={Css.xl3Sb.tac.$}>Enter Bid Information</div>
        <div css={Css.base.tac.pt2.w50.mxa.$}>
          Using your trade's bid as a reference, enter the line items in the form below. The system will attempt to
          calculate the details for you. Adjust values where necessary to accurately reflect the actual numbers.
        </div>

        <div css={Css.mbPx(80).$}>
          {
            {
              [BidContractType.GroupedByMaterial]: (
                <MaterialTables
                  formState={formState}
                  scopeLineItems={scopeLineItems}
                  tradePartnerName={tradePartnerName}
                />
              ),
              [BidContractType.GroupedByPlanAndOption]: (
                <PlanAndOptionTables
                  formState={formState}
                  scopeLineItems={scopeLineItems}
                  tradePartnerName={tradePartnerName}
                />
              ),
              [BidContractType.ItemTemplateBased]: null,
              [BidContractType.UnitBased]: null,
            }[type]
          }
        </div>
      </ScrollableContent>

      <div css={Css.fixed.bottom0.left0.w100.px6.py3.bshHover.df.z2.bgWhite.$}>
        <div css={Css.mla.df.gap2.$}>
          <Button label="Cancel" onClick={closeModal} size="lg" variant="text" />
          <Observer>
            {/* TODO: This should be disabled if there aren't any trade line items */}
            {() => <Button label="Save" onClick={saveAndExit} size="lg" />}
          </Observer>
        </div>
      </div>
    </ScrollableParent>
  );
}

export type ProrationFormValues = SaveBidContractTradeLineItemProrationInput & {
  // we need a field for the unit cost on prorations
  quantity?: Maybe<number>;
  unitCostInCents?: Maybe<number>;
  estimatedCostInCents: number;
};

type BidContractTradeLineItemFormValues = Omit<SaveBidContractTradeLineItemInput, "prorations"> & {
  // we need a field for the unit cost/quantity on trade line items
  quantity?: Maybe<number>;
  unitCostInCents?: Maybe<number>;
  prorations: ProrationFormValues[];
};

export type FormValues = {
  bidContractTradeLineItems: BidContractTradeLineItemFormValues[];
};

const formConfig: ObjectConfig<FormValues> = {
  bidContractTradeLineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      quantity: { type: "value" },
      unitCostInCents: { type: "value" },
      totalCostInCents: { type: "value" },
      productOfferingId: { type: "value" },
      optionIds: { type: "value" },
      bidItemId: { type: "value" },
      prorations: {
        type: "list",
        config: {
          id: { type: "value" },
          quantity: { type: "value" },
          unitCostInCents: { type: "value" },
          costInCents: { type: "value" },
          itemTemplateItemId: { type: "value" },
          estimatedCostInCents: { type: "value" },
          userEntered: { type: "value" },
        },
      },
    },
  },
};

function mapFormValuesToInput(formValues: FormValues, revisionId?: string): SaveBidContractTradeLineItemsInput {
  return {
    bidContractTradeLineItems: formValues.bidContractTradeLineItems
      // filter out trade line items that don't have any costs or ids
      .filter(
        ({ id, totalCostInCents, prorations }) =>
          id ||
          totalCostInCents !== undefined ||
          prorations.some(({ id, costInCents }) => id || costInCents !== undefined),
      )
      // filter out quantity and unitCostInCents
      .map(({ quantity, unitCostInCents, ...rest }) => ({
        ...rest,
        revisionId,
        prorations: rest.prorations.map(({ quantity, unitCostInCents, estimatedCostInCents, ...proration }) => ({
          ...proration,
        })),
      })),
  };
}

function updateProrationCosts(bidContractTradeLineItemsState: ObjectState<BidContractTradeLineItemFormValues>): void {
  const tradeCostInCents = bidContractTradeLineItemsState.totalCostInCents.value || 0;
  const prorations = bidContractTradeLineItemsState.prorations.rows;
  const [userProrations, estimatedProrations] = prorations.partition((p) => !!p.userEntered.value);
  const tradeCostsWithoutUserProrations = tradeCostInCents - userProrations.sum((p) => p.costInCents.value || 0);
  if (tradeCostsWithoutUserProrations <= 0) {
    return;
  }
  // Calculate the estimated total cost based on estimated cost from the prorations
  const estimatedTotal = estimatedProrations.sum((p) => p.estimatedCostInCents.value);
  // Calculate the ratios by dividing the proration estimated cost by the total
  const ratios = estimatedProrations.map((p) => p.estimatedCostInCents.value / estimatedTotal);
  const proratedCosts = prorate(tradeCostsWithoutUserProrations, ratios);
  // Set the prorated Costs
  for (let index = 0; index < estimatedProrations.length; ++index) {
    const proration = estimatedProrations[index];
    proration.set({
      costInCents: proratedCosts[index],
      // We need to set the unit costs here to where it doesn't get in a recalc loop
      unitCostInCents: roundToDigits(proratedCosts[index] / (proration.quantity.value || 1), 1),
      userEntered: false,
    });
  }
}
