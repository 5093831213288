import { Css, IconButton } from "@homebound/beam";
import { UppyUploader } from "src/components";
import { UppyFile } from "@uppy/core";
import { readFileAsDataURL } from "src/components/uploader/utils";
import { useState } from "react";

type CoverPhotoUploaderProps = {
  coverPhotoUrl: string | null | undefined;
  saveCoverPhoto: (file: UppyFile | undefined) => void;
};

export function CoverPhotoUploader(props: CoverPhotoUploaderProps) {
  const { coverPhotoUrl, saveCoverPhoto } = props;
  // Holds a base64 string of the local file of the uploaded image in order to immediately display it.
  const [localCoverPhoto, setLocalCoverPhoto] = useState<string>();
  const imageToDisplay = localCoverPhoto ?? coverPhotoUrl;

  return imageToDisplay ? (
    <div css={Css.p2.relative.ba.bcGray100.br4.bw2.w100.mhPx(60).df.aic.jcc.$}>
      {/* Setting the <img>'s width as defined on the asset resizer request.
          This ensures consistent rendering between the local & remote assets */}
      <img src={imageToDisplay} alt="Cover Photo" css={Css.maxwPx(321).$} loading="lazy" />
      <div css={Css.absolute.top1.right1.$}>
        <IconButton
          inc={5}
          data-testid="exit"
          icon="x"
          onClick={() => {
            // Unset the asset from local state
            setLocalCoverPhoto(undefined);
            saveCoverPhoto(undefined);
          }}
        />
      </div>
    </div>
  ) : (
    <UppyUploader
      allowedFileTypes={["image/*"]}
      onFinish={async (uppyFile: UppyFile) => {
        const result = await readFileAsDataURL(uppyFile.data);
        setLocalCoverPhoto(result);
        saveCoverPhoto(uppyFile);
      }}
      dragDropText="Drag & Drop or Click to Upload Cover Photo"
      maxNumberOfFiles={1}
      dragDropWidth="100%"
      dragDropHeight={60}
    />
  );
}
