import { Chips, Css, useTestIds } from "@homebound/beam";
import { TaskDetailsPage_ProjectItemFragment } from "src/generated/graphql-types";

type CostAllocationsProps = {
  projectItems: TaskDetailsPage_ProjectItemFragment[];
};

export function CostAllocations({ projectItems }: CostAllocationsProps) {
  const tid = useTestIds({}, "costAllocations");
  return (
    <div css={Css.bcGray300.bb.pb3.$}>
      <div css={Css.my2.$}>
        <span css={Css.df.aic.jcsb.baseSb.gray800.$}>Cost Allocations</span>
      </div>
      {projectItems.nonEmpty ? (
        <Chips values={projectItems.map((pi) => pi.item.fullCode)} />
      ) : (
        <div {...tid.noCostAllocations} css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no allocated project items
        </div>
      )}
    </div>
  );
}
