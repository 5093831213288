import {
  Chip,
  Css,
  RightPaneLayout,
  RouteTabWithContent,
  TabContent,
  useBreakpoint,
  usePersistedFilter,
  useSuperDrawer,
} from "@homebound/beam";
import { useEffect, useState } from "react";
import { Redirect, useParams, useRouteMatch } from "react-router";
import {
  PersonalDashboard_ProjectFragment,
  PersonalDashboardsPageQuery,
  useAssignedToDosQuery,
  usePersonalDashboardsPageQuery,
  useTeamMembersProjectsLazyQuery,
} from "src/generated/graphql-types";
import { useOnDismount } from "src/hooks/useOnDismount";
import { useToDoTypes } from "src/hooks/enums/useToDoTypes";
import { otherJobLogParentTypes } from "src/routes/my-blueprint/activity-feed/UserEventsTable";
import { PersonalDashboardRoutes } from "src/routes/personal-dashboard/enums";
import { ActionItemsPage } from "src/routes/personal-dashboard/pages/action-items/ActionItemsPage";
import { ActivityPage } from "src/routes/personal-dashboard/pages/activity/ActivityPage";
import { ToDosPage } from "src/routes/personal-dashboard/pages/to-dos/ToDosPage";
import { UpcomingPage } from "src/routes/personal-dashboard/pages/upcoming/UpcomingPage";
import { PersonalDashboardFilter, useHeaderFilterDefs } from "src/routes/personal-dashboard/useHeaderFilterDefs";
import { personalDashboardPaths } from "src/routes/routesDef";
import { queryResult, sortBy } from "src/utils";
import { PersonalDashboardHeader } from "./components/PersonalDashboardHeader";
import { PersonalDashboardProvider } from "./DashboardFilterContext";
import { ApprovalsDashboard } from "./pages/approvals-dashboard/ApprovalsDashboard";
import { PersonalDashboardRightPane } from "./components/PersonalDashboardRightPane";
import { TaskBillModalProvider } from "../projects/schedule-v2/contexts/TaskBillModalContext";

export type Section =
  | PersonalDashboardRoutes.ACTIONS
  | PersonalDashboardRoutes.UPCOMING
  | PersonalDashboardRoutes.TO_DOS
  | PersonalDashboardRoutes.APPROVALS
  | PersonalDashboardRoutes.ACTIVITY;

type RouteParams = {
  section?: Section;
};

export function PersonalDashboardPage() {
  const query = usePersonalDashboardsPageQuery({ fetchPolicy: "cache-first" });

  const { section } = useParams<RouteParams>();

  if (!section) return <Redirect to={personalDashboardPaths.actions} />;

  return queryResult(query, {
    data: (data) => {
      return <PersonalDashboardLayout data={data} section={section} />;
    },
  });
}

function PersonalDashboardLayout({ data, section }: { data: PersonalDashboardsPageQuery; section: Section }) {
  const { currentInternalUser, userEventParentTypes } = data;
  const { closeDrawer } = useSuperDrawer();
  const [loadProjects] = useTeamMembersProjectsLazyQuery({ fetchPolicy: "cache-and-network" });
  const { data: toDosData } = useAssignedToDosQuery({
    variables: { assignee: [currentInternalUser?.id].compact() },
  });
  const routesWithoutWidgets = !!useRouteMatch([personalDashboardPaths.toDos, personalDashboardPaths.approvals]);
  const { sm } = useBreakpoint();

  /**
   * When un-mounting this page, close open Superdrawers.
   * This is to prevent having a SuperDrawer stay open when navigating multiple
   * pages back/forward as the SuperDrawer is outside the ReactRouter scope.
   */
  useOnDismount(closeDrawer);

  const filteredEventTypes = userEventParentTypes.filter(
    (type) => !["comment", "t", ...otherJobLogParentTypes].includes(type.id),
  );

  // selectedUsers is the value of the internalUser filter
  const [selectedUsers, setSelectedUsers] = useState<undefined | null | string[]>([]);
  const [projects, setProjects] = useState<PersonalDashboard_ProjectFragment[]>([]);

  useEffect(() => {
    const refetchProjects = async () => {
      const { data } = await loadProjects({ variables: { teamMembers: selectedUsers! } });
      if (data) {
        setProjects(data.projects);
      }
    };
    if (selectedUsers?.nonEmpty) {
      void refetchProjects();
    } else {
      setProjects([]);
    }
  }, [loadProjects, selectedUsers]);

  const toDoTypes = sortBy(useToDoTypes(), (t) => t.name);

  const filterDefs = useHeaderFilterDefs({
    ...data,
    projects: projects,
    userEventTypes: filteredEventTypes,
    section,
    currentUserId: currentInternalUser?.id,
    toDoTypes,
  });

  const { setFilter, filter } = usePersistedFilter<PersonalDashboardFilter>({
    storageKey: "personalDashboard",
    filterDefs,
  });

  useEffect(() => {
    // TODO: we need to find a better solution to update the filterDefs when the assigned to changes that does not require a render loop.
    setSelectedUsers(filter.internalUser);
  }, [filter.internalUser]);

  const assignedToDosCount = toDosData?.toDos?.length ?? 0;

  const tabs: RouteTabWithContent[] = [
    {
      name: "Actions",
      href: personalDashboardPaths.actions,
      path: personalDashboardPaths.actions,
      render: () => <ActionItemsPage />,
    },
    {
      name: "Upcoming",
      href: personalDashboardPaths.upcoming,
      path: personalDashboardPaths.upcoming,
      render: () => <UpcomingPage />,
    },
    {
      name: "To-Dos",
      href: personalDashboardPaths.toDos,
      path: [personalDashboardPaths.toDos, personalDashboardPaths.toDoModal],
      render: () => <ToDosPage />,
      endAdornment:
        assignedToDosCount > 0 ? (
          <Chip text={assignedToDosCount.toString()} compact xss={Css.bgBlue700.white.$} />
        ) : undefined,
    },
    {
      name: "Approvals",
      href: personalDashboardPaths.approvals,
      path: personalDashboardPaths.approvals,
      render: () => <ApprovalsDashboard />,
    },
    {
      name: "Activity",
      href: personalDashboardPaths.activity,
      path: personalDashboardPaths.activity,
      render: () => <ActivityPage />,
    },
  ].compact();

  return (
    <PersonalDashboardProvider filter={filter} setFilter={setFilter}>
      <TaskBillModalProvider>
        <PersonalDashboardHeader filterDefs={filterDefs} tabs={tabs} section={section} />

        <div css={Css.df.fg1.h100.oh.bgGray100.w100.$}>
          <div css={Css.px3.pt4.pb3.w100.if(sm).p0.$}>
            <RightPaneLayout defaultPaneContent={!routesWithoutWidgets ? <PersonalDashboardRightPane /> : undefined}>
              <div css={Css.bgWhite.p3.br8.f1.h100.if(sm).p1.$}>
                <TabContent tabs={tabs} contentXss={Css.h100.$} />
              </div>
            </RightPaneLayout>
          </div>
        </div>
      </TaskBillModalProvider>
    </PersonalDashboardProvider>
  );
}
