import { HbLoadingSpinner, SuperDrawerContent, useSuperDrawer } from "@homebound/beam";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ApprovalSuperDrawer_ApprovalFragment, useApprovalSuperDrawerQuery } from "src/generated/graphql-types";
import { foldEnum } from "src/utils";
import { ApprovalOverviewAdapter } from "./ApprovalOverviewAdapter";
import { ApprovalReviewForm } from "./ApprovalReviewForm";
import { ApprovalSuperDrawerHeader } from "./ApprovalSuperDrawerHeader";
import { useEffectOnce, usePrevious } from "react-use";
import difference from "lodash/difference";
import { useQueryParams, ArrayParam } from "use-query-params";

export enum ApprovalDrawerViews {
  Create,
  Review, // Also serves as reopen
  Rejecting,
  RequestingChanges,
  Retracting,
}

export type ApprovalContextType = {
  view: ApprovalDrawerViews;
  /** So buttons can trickle-up state e.g. Approving, Rejecting, RequestingChanges, Retracting */
  setView: React.Dispatch<React.SetStateAction<ApprovalDrawerViews>>;
  loading: boolean;
  approvals: ApprovalSuperDrawer_ApprovalFragment[] | undefined;
  subjects: ApprovalSuperDrawer_ApprovalFragment["subject"][] | undefined;
  /** So mutations can pass through IDs but if we can expose a Mutation through Context that'll prewrap a mutation and simply require an ApproverStatus we may be good. */
  approvalIds: string[];
  /** UX Optimization - After creating an Approval, trickle it back up and show Review so users don't have to Close and Reopen the drawer. */
  setApprovalIds: React.Dispatch<React.SetStateAction<string[]>>;
  currentUserId: string;
};

export const ApprovalContext = React.createContext<ApprovalContextType>({
  view: ApprovalDrawerViews.Review,
  setView() {},
  loading: false,
  approvals: undefined,
  subjects: undefined,
  approvalIds: [],
  setApprovalIds() {},
  currentUserId: "",
});

type ApprovalSuperDrawerProps = {
  /** If >1, will utilize the Bulk Review views. Will auto-compact. */
  approvalIds: (string | undefined)[];
  /**
   * Only provided by Subjects capable of Creating or Reopening an Approval to enable caller-specific "Create" flows
   * not directly tethered to Approvals. In the Create case, an Approval (And therefore `approval.subject` do not exist)
   * and we don't want to drill through a mess of `<Approval subjectType="changeEvent" subjectId="ce:123" />`.
   */
  children?: React.ReactNode;
};

export function ApprovalSuperDrawer({ children, approvalIds: givenApprovalIds }: ApprovalSuperDrawerProps) {
  /** Contextualizing this so Create components can trickle new approval IDs back up the tree */
  const [approvalIds, setApprovalIds] = useState<string[]>(() => givenApprovalIds.compact());

  const [view, setView] = useState<ApprovalDrawerViews>(
    // Default to Review unless no Approval ID was provided
    approvalIds.nonEmpty ? ApprovalDrawerViews.Review : ApprovalDrawerViews.Create,
  );

  // Need the bulk endpoint but it's not ready yet
  const { loading, data } = useApprovalSuperDrawerQuery({
    variables: { approvalId: approvalIds.first! },
    skip: !approvalIds.first, // Skip if there is no ID, which would occur in the `Create` workflow
  });

  const value = useMemo<ApprovalContextType>(
    () => ({
      view,
      setView,
      loading,
      approvals: [data?.approval].compact(),
      subjects: [data?.approval].map((a) => a?.subject).compact(),
      approvalIds,
      currentUserId: data?.currentUser?.internalUser?.id || "",
      setApprovalIds,
    }),
    [approvalIds, data, loading, view],
  );

  // TODO
  /** Do not allow user to escape Create/Reopen view while Approval is ChangesNeeded */
  /** Enforce bulk view if applicable */
  /** Enforce can't create or reopen if child props weren't provided */
  if (loading) return <HbLoadingSpinner />;

  return (
    <ApprovalContext.Provider value={value}>
      <SuperDrawerContent>
        <ApprovalSuperDrawerHeader />
        {foldEnum(view, {
          [ApprovalDrawerViews.Create]: children,
          [ApprovalDrawerViews.Review]: () => <ApprovalOverviewAdapter />,
          [ApprovalDrawerViews.Rejecting]: () => <ApprovalReviewForm />,
          [ApprovalDrawerViews.RequestingChanges]: () => <ApprovalReviewForm />,
          [ApprovalDrawerViews.Retracting]: () => <ApprovalReviewForm />,
        })}
      </SuperDrawerContent>
    </ApprovalContext.Provider>
  );
}

export const useApprovalContext = () => useContext(ApprovalContext);

/**
 * A wrapper for openInDrawer({ content: <ApprovalSuperDrawer /> }) which also sets
 * and clears approvalId from the URL Params. To actually open drawer via deep-link,
 * ensure <MonitorApprovalSuperdrawerDeepLinks /> is mounted on the page you'd like
 * to land on.
 */
export function useApprovalSuperDrawer() {
  const { openInDrawer } = useSuperDrawer();
  const [_, setQs] = useQueryParams({ approvalIds: ArrayParam });

  return useCallback(
    (approvalIds: string[] | string | undefined) => {
      const arr = Array.isArray(approvalIds) ? approvalIds : [approvalIds];
      setQs({ approvalIds: arr as string[] });
      openInDrawer({
        content: <ApprovalSuperDrawer approvalIds={arr} />,
        onClose: () => setQs({ approvalIds: undefined }),
      });
    },
    [openInDrawer, setQs],
  );
}

/**
 * This monitors the URL for approvalIds[] and auto-opens the Superdrawer when it detects
 * non-nullish/non-empty. It can be used on any page you want to deeplink to an Approval on.
 */
export function MonitorApprovalSuperdrawerDeepLinks({ children }: React.PropsWithChildren<unknown>) {
  const [qs] = useQueryParams({ approvalIds: ArrayParam });
  const approvalIds = qs.approvalIds as string[];
  const prevIds = usePrevious(approvalIds?.compact() ?? []);
  const openApproval = useApprovalSuperDrawer();
  const { isDrawerOpen } = useSuperDrawer();

  // First-render only, check if this should open. Use case: User clicked a deep link
  // and navigated directly to this page expecting drawer to open.
  useEffectOnce(() => {
    if (approvalIds?.compact().nonEmpty && !isDrawerOpen) openApproval(approvalIds);
  });

  // There's an issue attempting to close the drawer and this forcing it back open, so we're only
  // going to check for NEW IDs and trigger an Open if detected
  useEffect(() => {
    const newIds = difference(approvalIds?.compact(), prevIds ?? []);
    if (newIds.nonEmpty) openApproval(approvalIds);
  }, [approvalIds, openApproval, prevIds]);

  return children;
}
