import { BoundRichTextField, Css, FormLines } from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { ReactNode } from "react";
import {
  CommitmentEditorDataFragment,
  CommitmentStatus,
  SaveCommitmentInput,
  useSaveNewCommitmentMutation,
} from "src/generated/graphql-types";

type ScheduleAndScopeTabProps = {
  commitment: CommitmentEditorDataFragment;
};

export function ScheduleAndScopeTab({ commitment }: ScheduleAndScopeTabProps) {
  const [saveCommitment] = useSaveNewCommitmentMutation();

  const isSigned = commitment.status === CommitmentStatus.Signed;

  const { costTypes, ...commitmentWithoutCostType } = commitment;

  const formState = useFormState({
    config: formConfig,
    init: commitmentWithoutCostType,
    autoSave: async (c) => {
      if (commitment && !isSigned) {
        await saveCommitment({ variables: { input: c.changedValue } });
        c.commitChanges();
      }
    },
    readOnly: isSigned,
  });

  return (
    <>
      <FormWrapper xss={{}}>
        <FormLines width="lg">
          <div css={{ "[role='textbox']": Css.mh("40px").maxh("320px").oya.$ }}>
            <BoundRichTextField field={formState.contractSchedule} label="Schedule" />
          </div>
          <div css={{ "[role='textbox'], [data-readonly='true']": Css.maxh("320px").oya.$ }}>
            <BoundRichTextField field={formState.contractExhibits} label="Drawings and Exhibits" />
          </div>
          <div css={{ "[role='textbox'], [data-readonly='true']": Css.maxh("320px").oya.$ }}>
            <BoundRichTextField field={formState.contractExclusions} label="Exclusions (Optional)" />
          </div>
          <div css={{ "[role='textbox'], [data-readonly='true']": Css.maxh("320px").oya.$ }}>
            <BoundRichTextField field={formState.contractTerms} label="Terms &amp; Conditions" />
          </div>
        </FormLines>
      </FormWrapper>
    </>
  );
}

type FormInput = Omit<SaveCommitmentInput, "commitmentType">;

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  contractExclusions: { type: "value" },
  contractExhibits: { type: "value" },
  contractSchedule: { type: "value" },
  contractTerms: { type: "value" },
};

function FormWrapper({ children, xss }: { children: ReactNode; xss: Record<string, any> }) {
  return <form css={xss}>{children}</form>;
}
