import { makeAutoObservable, reaction } from "mobx";
import {
  ChangeEventLineItemsTabChangeEventLineItemFragment,
  ChangeEventLineItemsTabProjectItemFragment,
  ChangeEventLineItemType,
  CostType,
  SaveChangeEventLineItemInput,
} from "src/generated/graphql-types";

export class ObservableChangeEventLineItemInlineAdd {
  changeEventId: string;
  costType: ChangeEventLineItemsTabChangeEventLineItemFragment["costType"] | undefined;
  initialState: boolean;
  itemId: string | undefined;
  locationId: string | undefined;
  unitOfMeasureId: string | undefined;
  projectItemsList: ChangeEventLineItemsTabProjectItemFragment[];
  type: ChangeEventLineItemsTabChangeEventLineItemFragment["type"] | undefined;
  isMatch: boolean;

  constructor(celi: InlineAddCeli, onCreate?: (input: SaveChangeEventLineItemInput) => void) {
    this.changeEventId = celi.changeEventId;
    this.costType = celi.costType;
    this.initialState = celi.initialState;
    this.itemId = celi.itemId;
    this.locationId = celi.locationId;
    this.unitOfMeasureId = celi.unitOfMeasureId;
    this.projectItemsList = celi.projectItemsList;
    this.type = celi.type;
    this.isMatch = celi.isMatch || false;
    makeAutoObservable(this, { projectItemsList: false });

    // listen for changes to properties that we care about
    if (onCreate) {
      reaction(
        () => ({
          changeEventId: this.changeEventId,
          costType: this.costType,
          initialState: this.initialState,
          itemId: this.itemId,
          locationId: this.locationId,
          unitOfMeasureId: this.unitOfMeasureId,
          projectItemsList: this.projectItemsList,
          type: this.type,
        }),
        (newValues: MutableProperties, previousValues: MutableProperties) => {
          const { costType, itemId, locationId, unitOfMeasureId, type } = newValues;
          const areRequiredBaseValuesProvided = costType && itemId && locationId;
          const canDisplayUomSelect = type === ChangeEventLineItemType.Add;
          const areRequiredValuesProvided =
            (areRequiredBaseValuesProvided && !canDisplayUomSelect) ||
            (areRequiredBaseValuesProvided && canDisplayUomSelect && unitOfMeasureId);
          if (!areRequiredValuesProvided) return;

          // attempt to match - projectItemsList already filters out project items that exist on CE
          const piMatches = this.projectItemsList?.filter((pi) => {
            return pi.item.id === itemId && pi.location.id === locationId && pi.costType === costType;
          });

          // enable both "modify" and "add" when there are matches
          // disable "modify" and enable "add" when there are no matches
          this.isMatch = !!piMatches.length;

          // attempt to save when type is chosen
          if (!type) return;

          const { changeEventId } = this;

          if (type === ChangeEventLineItemType.Modify) {
            // always pick first match
            onCreate({ changeEventId, projectItemId: piMatches[0].id });
          } else {
            onCreate({ changeEventId, type, itemId, locationId, unitOfMeasureId, costType });
          }
        },
      );
    }
  }

  updateInitialState(initialState: boolean) {
    this.initialState = initialState;
  }

  updateItemId(item?: string) {
    this.itemId = item;
  }

  updateLocationId(location?: string) {
    this.locationId = location;
  }

  updateCostType(costType: CostType) {
    this.costType = costType;
  }

  updateType(type: ChangeEventLineItemType) {
    this.type = type;
  }

  updateUnitOfMeasureId(unitOfMeasure?: string) {
    this.unitOfMeasureId = unitOfMeasure;
  }
}

type MutableKey = "itemId" | "locationId" | "costType" | "type" | "unitOfMeasureId";
type MutableProperties = Pick<ObservableChangeEventLineItemInlineAdd, MutableKey>;

export type InlineAddCeli = {
  changeEventId: string;
  costType?: CostType | undefined;
  initialState: boolean;
  itemId?: string | undefined;
  locationId?: string | undefined;
  unitOfMeasureId?: string | undefined;
  projectItemsList: ChangeEventLineItemsTabProjectItemFragment[];
  type?: ChangeEventLineItemType | undefined;
  isMatch?: boolean;
};
