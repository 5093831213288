import { Css, Palette } from "@homebound/beam";
import { Document, Page } from "react-pdf";
import { Icon } from "src/components/Icon";
import { AssetInfoFragment } from "src/generated/graphql-types";

type AssetPreviewProps = {
  onClick?: Function;
  asset: AssetInfoFragment;
  dimensions?: {
    width: number;
    height: number;
  };
};

export function AssetPreview({ asset, onClick, dimensions }: AssetPreviewProps) {
  const docType = asset.contentType;

  if (docType === "application/pdf") {
    return <PdfPreview asset={asset} onClick={onClick} dimensions={dimensions} />;
  } else if (docType.includes("image/")) {
    return <ImagePreview asset={asset} onClick={onClick} dimensions={dimensions} />;
  } else if (docType.includes("video/")) {
    return <VideoPreview asset={asset} onClick={onClick} dimensions={dimensions} />;
  } else {
    return <NoPreviewComponent asset={asset} onClick={onClick} dimensions={dimensions} />;
  }
}

function PdfPreview({ asset, onClick, dimensions }: AssetPreviewProps) {
  return (
    <button
      onClick={(e) => {
        e.currentTarget.blur();
        onClick && onClick();
      }}
      css={{
        ...previewStyles,
        ...Css.if(!!dimensions?.width).wPx(dimensions?.width!).mwPx(dimensions?.width!).else.w100.$,
        ...Css.hPx(dimensions?.height ?? 208).mhPx(dimensions?.height ?? 208).$,
        "&:focus": Css.add("outline", `1px ${Palette.Gray900} dashed`).$,
        "&:hover": Css.bshHover.$,
      }}
      data-testid="docThumbnail"
    >
      <Document file={asset.downloadUrl} loading="" renderMode="svg">
        <Page
          css={Css.maxwPx(250).maxhPx(200).$}
          height={dimensions?.height ?? 200}
          width={dimensions?.width}
          loading=""
          pageNumber={1}
          renderMode="canvas"
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </button>
  );
}

function ImagePreview({ asset, onClick, dimensions }: AssetPreviewProps) {
  const { previewUrl, downloadUrl, contentType } = asset;
  const src = contentType === "image/gif" ? downloadUrl : previewUrl || downloadUrl;

  return (
    <img
      src={src}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick && onClick(e);
        }
      }}
      css={{
        ...previewStyles,
        ...Css.objectContain.$,
        ...Css.if(!!dimensions?.width).wPx(dimensions?.width!).mwPx(dimensions?.width!).else.w100.$,
        ...Css.hPx(dimensions?.height ?? 208).mhPx(dimensions?.height ?? 208).$,
      }}
    />
  );
}

function VideoPreview({ asset, onClick, dimensions }: AssetPreviewProps) {
  const { downloadUrl } = asset;

  return (
    <video
      src={downloadUrl}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick && onClick(e);
        }
      }}
      css={{
        ...previewStyles,
        ...Css.objectContain.$,
        ...Css.if(!!dimensions?.width).wPx(dimensions?.width!).mwPx(dimensions?.width!).else.w100.$,
        ...Css.hPx(dimensions?.height ?? 208).mhPx(dimensions?.height ?? 208).$,
      }}
    />
  );
}

function NoPreviewComponent({ dimensions }: AssetPreviewProps) {
  return (
    <div
      css={{
        ...previewStyles,
        ...Css.if(!!dimensions?.width).wPx(dimensions?.width!).mwPx(dimensions?.width!).else.w100.$,
        ...Css.hPx(dimensions?.height ?? 80).mhPx(dimensions?.height ?? 80).$,
      }}
    >
      <span css={Css.mr2.fs0.$}>
        <Icon icon="polaroids" />
      </span>
      <div>No preview available</div>
    </div>
  );
}
const previewStyles = Css.bn.br4.df.aic.jcc.bshBasic.cursorPointer.bgWhite.$;
