import { IconButton, useRightPane } from "@homebound/beam";
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { PersonalDashboard_ScheduleTaskFragment, useSaveScheduleTasksMutation } from "src/generated/graphql-types";
import { usePersistedToggle } from "src/hooks";
import { useDelayFlagModal } from "src/hooks/useDelayFlagModal";
import { createDevelopmentScheduleUrl, createProjectScheduleUrl } from "src/RouteUrls";
import { openNewTab } from "src/utils/window";
import { StandaloneTaskDetailPane } from "../developments/schedule/components/StandaloneTaskDetailPane";
import {
  TradePartnerAvailabilityProvider,
  TradePartnerAvailabilityRequestDetailPaneForm,
} from "./components/TradePartnerAvailabilityRequestDetailPane";
import { PersonalDashboardRefetchQueries } from "./enums/consts";
import type { PersonalDashboardFilter } from "./useHeaderFilterDefs";

type PersonalDashboardFilterProps = PropsWithChildren<{
  filter: PersonalDashboardFilter;
  setFilter: (filter: PersonalDashboardFilter) => void;
  selectedTaskId: string | undefined;
  openTaskDetailPane: (
    task: PersonalDashboard_ScheduleTaskFragment,
    tabSelected?: string,
    scrollIntoViewType?: any,
  ) => void;
  toggleFullScreen: () => void;
  fullScreen: boolean;
  openTradePartnerAvailabilityRequestDetailPane: (task: PersonalDashboard_ScheduleTaskFragment) => void;
  selectedTPAvailabilityRequestTask: string | undefined;
}>;

export const DashboardFilterContext = createContext<PersonalDashboardFilterProps>({
  filter: {},
  setFilter: () => {},
  selectedTaskId: undefined,
  openTaskDetailPane: () => {},
  toggleFullScreen: () => {},
  fullScreen: false,
  openTradePartnerAvailabilityRequestDetailPane: () => {},
  selectedTPAvailabilityRequestTask: undefined,
});

type PersonalDashboardProviderProps = Omit<
  PersonalDashboardFilterProps,
  | "selectedTaskId"
  | "openTaskDetailPane"
  | "toggleFullScreen"
  | "fullScreen"
  | "openTradePartnerAvailabilityRequestDetailPane"
  | "selectedTPAvailabilityRequestTask"
>;

export function PersonalDashboardProvider(props: PersonalDashboardProviderProps) {
  const { children, ...contextValue } = props;
  const { openRightPane, closeRightPane } = useRightPane();
  const { onCompleted } = useDelayFlagModal();
  const [saveScheduleTasks] = useSaveScheduleTasksMutation({ onCompleted });
  const location = useLocation();
  const [fullScreen, toggleFullScreen] = usePersistedToggle("dashboardFullScreen", false);

  // Intentionally not putting the taskId in the query params due to the time based nature of this dashboard,
  // there's no guarantee that the task will still be in the filtered view if the user is linked.
  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>(undefined);
  // Creating a separate state to hold the availability request tasks so users can switch between the request pane and details pane
  const [selectedTPAvailabilityRequestTask, setSelectedTPAvailabilityRequestTask] = useState<string | undefined>(
    undefined,
  );

  const openTradePartnerAvailabilityRequestDetailPane = useCallback(
    (task: PersonalDashboard_ScheduleTaskFragment) => {
      const { id } = task;
      setSelectedTPAvailabilityRequestTask(id);
      setSelectedTaskId(undefined);
      openRightPane({
        content: (
          <TradePartnerAvailabilityProvider
            onClose={() => {
              closeRightPane();
              setSelectedTPAvailabilityRequestTask(undefined);
              setSelectedTaskId(undefined);
            }}
            scheduleTask={task}
          >
            {/* Adding a key to force react to re-create the form when the task changes */}
            <TradePartnerAvailabilityRequestDetailPaneForm key={task.id} />
          </TradePartnerAvailabilityProvider>
        ),
      });
    },
    [closeRightPane, openRightPane],
  );

  const openTaskDetailPane = (
    task: PersonalDashboard_ScheduleTaskFragment,
    tabSelected?: string,
    scrollIntoViewType?: any,
  ) => {
    setSelectedTaskId(task.id);
    setSelectedTPAvailabilityRequestTask(undefined);
    const { id, schedule } = task;

    openRightPane({
      content: (
        <StandaloneTaskDetailPane
          taskId={id}
          scheduleId={schedule.id}
          stage={schedule.stage}
          onClose={() => {
            closeRightPane();
            setSelectedTaskId(undefined);
            setSelectedTPAvailabilityRequestTask(undefined);
          }}
          onSave={async (changedValues) => {
            await saveScheduleTasks({
              variables: { input: { tasks: [{ ...changedValues, id: task.id }] } },
              refetchQueries: PersonalDashboardRefetchQueries,
            });
          }}
          disableQueryParams
          headerActions={
            <IconButton
              data-testid="externalIcon"
              icon="linkExternal"
              onClick={() =>
                task.parent.id.includes("dev")
                  ? openNewTab(createDevelopmentScheduleUrl(task.parent.id))
                  : openNewTab(createProjectScheduleUrl(task.parent.id, schedule.stage, task.id))
              }
              inc={2}
            />
          }
          taskPaneState={{
            scrollIntoViewType,
            tab: tabSelected || "details",
            taskPaneId: task.id,
          }}
        />
      ),
    });
  };

  // The task sidebar only really applies to a few views, so we want to close it when the user navigates to another tab/page
  useEffect(() => {
    return () => {
      closeRightPane();
      setSelectedTaskId(undefined);
      setSelectedTPAvailabilityRequestTask(undefined);
    };
  }, [location, closeRightPane]);

  return (
    <DashboardFilterContext.Provider
      value={{
        ...contextValue,
        selectedTaskId,
        openTaskDetailPane,
        fullScreen,
        toggleFullScreen,
        openTradePartnerAvailabilityRequestDetailPane,
        selectedTPAvailabilityRequestTask,
      }}
    >
      {children}
    </DashboardFilterContext.Provider>
  );
}

DashboardFilterContext.displayName = "DashboardFilterContext";

export function useDashboardFilterContext() {
  return useContext(DashboardFilterContext);
}
