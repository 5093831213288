import {
  BoundRadioGroupField,
  BoundSelectField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import {
  NewCommitmentDataProjectStageDetailFragment,
  SaveCommitmentInput,
  useCreateNewCommitmentDataQuery,
  useSaveNewCommitmentMutation,
} from "src/generated/graphql-types";
import { createCommitmentUrl } from "src/RouteUrls";
import { isContractualStage } from "src/utils/projects";

type NewCommitmentModalProps = {
  projectId: string;
};
export function NewCommitmentModal({ projectId }: NewCommitmentModalProps) {
  const { closeModal } = useModal();
  const history = useHistory();
  const [saveCommitment] = useSaveNewCommitmentMutation({
    onCompleted: ({
      saveCommitment: {
        commitment: {
          id,
          commitmentType: { code },
        },
      },
    }) => history.push(createCommitmentUrl(projectId, id, code)),
  });

  const { data } = useCreateNewCommitmentDataQuery({ variables: { projectId } });
  const formState = useFormState({ config: formConfig });
  // TODO: trade categories will be sent to BE to use as filter when auto creating line items
  // const tradeCategories: { id: string; name: string }[] | undefined = data?.tradeCategories.map((tc) => {
  //   return {
  //     id: tc.id,
  //     name: tc.name,
  //   };
  // });
  const projectStages: RadioButtonInputProjectStage[] | undefined = data?.project.stages.map((ps) => {
    return {
      id: ps.id,
      label: ps.stage.name,
      stage: ps.stage,
      value: ps.id,
    };
  });
  return (
    <>
      <ModalHeader>Add New Commitment</ModalHeader>
      <ModalBody>
        <FormWrapper xss={{}}>
          <FormLines width="full">
            {/* TODO: trade categories will be sent to BE to use as filter when auto creating line items  */}
            {/* <BoundMultiSelectField
              field={formState.tradeCategoryIds}
              label="Trade Categories"
              options={tradeCategories || []}
            /> */}
            <BoundSelectField
              field={formState.tradePartnerId}
              label="Trade Partner"
              options={data?.tradePartners || []}
            />
            <BoundRadioGroupField
              field={formState.projectStageId}
              label="Stage"
              options={projectStages?.filter((s) => isContractualStage(s.stage.code)) || []}
            />
          </FormLines>
        </FormWrapper>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button variant="tertiary" label="Cancel" onClick={closeModal} />
              <Button
                disabled={!formState.valid}
                label="Add Commitment"
                onClick={async () => {
                  // set default T+Cs content for new commitments
                  try {
                    await saveCommitment({
                      variables: { input: formState.value },
                    });
                  } catch (e) {
                    console.error(e);
                  }
                  // close modal regardless if error or not
                  closeModal();
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}
type RadioButtonInputProjectStage = NewCommitmentDataProjectStageDetailFragment & {
  label: string;
  value: string;
};
// type FormInput = SaveCommitmentInput & {
//   tradeCategoryIds: Maybe<string[]>;
// };
const formConfig: ObjectConfig<SaveCommitmentInput> = {
  projectStageId: { type: "value", rules: [required] },
  // tradeCategoryIds: { type: "value", rules: [required] },
  tradePartnerId: { type: "value", rules: [required] },
};

function FormWrapper({ children, xss }: { children: ReactNode; xss: Record<string, any> }) {
  return <form css={xss}>{children}</form>;
}
