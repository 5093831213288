import { Css, GridDataRow, GridStyle, GridTable, Palette, useTestIds } from "@homebound/beam";
import { historyColumns } from "src/components/HistoryFeed";
import { sortBy } from "src/utils/arrays";
import { History, HistoryFeedInfoFragment } from "src/generated/graphql-types";

export type HistoryProps = {
  historyItems: History[];
};

export function BillHistory({ historyItems }: HistoryProps) {
  const testIds = useTestIds({}, "historyFeed");

  return (
    <div css={Css.w100.p2.$}>
      {!historyItems.length ? (
        <span css={Css.mt1.$} {...testIds.empty}>
          No history available
        </span>
      ) : (
        <div {...testIds}>
          <GridTable
            rows={createHistoryRows(sortBy(historyItems, (historyItem) => -historyItem.recordedAt))}
            columns={historyColumns}
            style={compact}
          />
        </div>
      )}
    </div>
  );
}

const compact: GridStyle = {
  rootCss: Css.dig.xs.gray900.w100.$,
  cellCss: Css.py(0.5).sm.$,
  rowHoverColor: Palette.Gray200,
};

type HistoryRow = { kind: "data"; id: string; data: HistoryFeedInfoFragment };

function createHistoryRows(historyItems: History[]): GridDataRow<HistoryRow>[] {
  return historyItems.map((history, index) => ({ kind: "data", id: index.toString(), data: history }));
}
