import { PlanTask } from "src/generated/graphql-types";
import { ProgressPill } from "src/components";
import { useMemo } from "react";

type PlanTaskProgressProps = {
  task: Pick<PlanTask, "durationInDays" | "workingDaysProgress">;
};

export function PlanTaskProgress({ task }: PlanTaskProgressProps) {
  const { durationInDays, workingDaysProgress } = task;
  const progressPercent = useMemo(() => {
    if (!workingDaysProgress || !durationInDays) return 0;
    return Math.round((workingDaysProgress / durationInDays) * 100);
  }, [durationInDays, workingDaysProgress]);

  return <ProgressPill progress={progressPercent} changeColorOnCompleted />;
}
