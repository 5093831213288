import { BillSummary } from "./components/BillSummary";
import {
  BillPageV2_BillFragment,
  BillPageV2_BillProjectStageFragment,
  BillPageV2_BillTradePartnerFragment,
  BillPageV2_CommitmentLineItemFragment,
  BillPageV2_DocumentFragment,
  BillStatus,
  BillType,
  SaveBillInput,
  SaveBillLineItemInput,
  SaveCommentInput,
  useBillPageV2Query,
  useBillPageV2_DeleteBillMutation,
  useBillPageV2_SaveBillMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";

import { Button, Css, IconButton, ModalProps, useBreakpoint, useModal, useToast } from "@homebound/beam";
import { PageHeader } from "../layout/PageHeader";
import { createBillPageUrl, createBillsAndCreditsPageUrl } from "src/RouteUrls";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { CommentFeed, PdfViewer } from "src/components";
import { DateOnly } from "src/utils/dates";
import { Card } from "src/components/Card";
import { BillLineItemsDataTable } from "./components/BillLineItemsDataTable";
import { BillLienWaiversDataTable } from "./components/BillLienWaiversDataTable";
import { BillHistory } from "../projects/bills/components/BillHistory";
import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BillActionConfirmationModal,
  BillActionConfirmationModalProps,
} from "./components/BillActionConfirmationModal";
import { isHeadlessBill } from "src/utils/bills";
import { BillFormLineItemOtherBills } from "./BillEditorV3";
import { BillBanner } from "../projects/bills/components/BillBanner";
import { getDynamicPdfVierwerWidth } from "./utils";
import { invoiceDoc } from "src/testData";

type BillPageV2Props = { billId?: string };
export function BillPageV2({ billId }: BillPageV2Props) {
  const params = useParams<{ billId: string }>();
  // If the billId is not passed in, use the billId from the URL params
  billId ??= params.billId;

  const query = useBillPageV2Query({ variables: { billId } });
  return queryResult(query, ({ bill }) => <BillPageView bill={bill} />);
}

const useGetBrowserWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = () => setWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return width;
};

type BillPageViewProps = { bill: BillPageV2_BillFragment };
function BillPageView({ bill }: BillPageViewProps) {
  const history = useHistory();
  const { showToast } = useToast();
  const { openModal } = useModal();
  const [deleteBill] = useBillPageV2_DeleteBillMutation();
  const [saveBill] = useBillPageV2_SaveBillMutation();
  const { sm, smOrMd } = useBreakpoint();

  const browserWidth = useGetBrowserWidth();
  const [showBillFile, setShowBillFile] = useState(false);

  const formState = useFormState({
    config: formConfig,
    init: { input: bill, map: mapToForm },
    readOnly: true,
  });

  const hasLienWaivers = bill.lienWaivers.nonEmpty;
  const isReleasabledBill =
    bill?.type.code === BillType.Deferred && [BillStatus.Draft, BillStatus.Unreleased].includes(bill.status.code);

  const onDelete = useCallback(async () => {
    await deleteBill({ variables: { input: { id: bill.id } } });
    showToast({ message: `Bill #${bill?.tradePartnerNumber} successfully deleted`, type: "success" });
    history.push(createBillsAndCreditsPageUrl());
  }, [bill.id, bill?.tradePartnerNumber, deleteBill, history, showToast]);

  const onReverse = useCallback(
    async (comment?: SaveCommentInput) => {
      await saveBill({ variables: { input: { id: bill.id, status: BillStatus.Reversed, comment } } });
      showToast({ message: `Bill #${bill?.tradePartnerNumber} processing reversal...`, type: "info" });
    },
    [bill.id, bill?.tradePartnerNumber, saveBill, showToast],
  );

  const onCancel = useCallback(
    async (comment?: SaveCommentInput) => {
      await saveBill({ variables: { input: { id: bill.id, status: BillStatus.Cancelled, comment } } });
      showToast({ message: `Bill #${bill?.tradePartnerNumber} has been successfully cancelled`, type: "success" });
    },
    [bill.id, bill?.tradePartnerNumber, saveBill, showToast],
  );

  const onRelease = useCallback(async () => {
    const res = await saveBill({
      // Pass bill status as ReleaseScheduled when unreleased bills are released
      variables: { input: { id: bill.id, status: BillStatus.ReleaseScheduled } },
    });
    if (res.data?.saveBill.bill) {
      showToast({
        message: `Bill ${bill.tradePartnerNumber} sent to ${bill.tradePartner.name} for Approval`,
        type: "info",
      });
    }
  }, [bill.id, bill.tradePartnerNumber, bill.tradePartner.name, saveBill, showToast]);

  const PAGE_DESKTOP_WIDTH = 1800;
  const billDataSectionSize = browserWidth > PAGE_DESKTOP_WIDTH ? "60%" : "50%";
  const billFileSectionSize = browserWidth > PAGE_DESKTOP_WIDTH ? "40%" : "50%";

  return (
    <>
      <PageHeader
        title={bill.name}
        breadcrumb={{ href: createBillsAndCreditsPageUrl(), label: "Bills & Credits" }}
        right={
          <div css={Css.if(smOrMd).df.fdr.gap1.else.df.$}>
            {/* Delete/Cancel/Reverse */}
            <FinalBillFormActions {...{ bill, onDelete, onCancel, onReverse, openModal }} />
            {/* Edit */}
            {bill.canEdit.allowed && (
              <Button
                label="Edit"
                variant="secondary"
                onClick={() => history.push(createBillPageUrl({ idOrAdd: bill.id, edit: true }))}
              />
            )}
            {/* Release */}
            {isReleasabledBill && (
              <div css={Css.if(sm).mx0.else.mx1.$}>
                <Button label="Release" onClick={onRelease} />
              </div>
            )}
          </div>
        }
      />

      {(bill.isBilled || bill.status.code === BillStatus.Reversed) && (
        <BillBanner billId={bill!.id} hasSyncError={Boolean(bill!.intacctSyncError)} />
      )}

      <div css={Css.df.gapPx(20).$}>
        <div css={Css.w(showBillFile ? billDataSectionSize : "100%").pr2.$}>
          <div css={Css.df.fdc.rg3.$}>
            <BillSummary
              formState={formState}
              bill={bill}
              showBillFile={showBillFile}
              showBillFileCallback={() => setShowBillFile((show) => !show)}
            />
            <section css={Css.df.$}>
              <div css={Css.w("100%").df.fdc.rg2.if(hasLienWaivers).w("60%").$}>
                <h1 css={Css.pl1.base.$}>Line Items</h1>
                <Card>
                  <BillLineItemsDataTable formState={formState} isHeadlessBill={isHeadlessBill(bill)} />
                </Card>
              </div>
              {hasLienWaivers && (
                <div css={Css.w("40%").df.fdc.rg2.pl4.$}>
                  <h1 css={Css.pl1.base.$}>Lien Waivers</h1>
                  <Card>
                    <BillLienWaiversDataTable
                      isLienWaiverReceiver={bill.tradePartner.isLienWaiverReceiver}
                      lienWaivers={bill.lienWaivers}
                    />
                  </Card>
                </div>
              )}
            </section>
            <section css={Css.df.cg3.$}>
              <div css={Css.w("60%").df.fdc.rg2.$}>
                <h1 css={Css.pl1.base.$}>History</h1>
                <BillHistory historyItems={bill.history} />
              </div>
              <div css={Css.w("40%").$}>
                <CommentFeed commentable={bill} />
              </div>
            </section>
          </div>
        </div>
        {showBillFile && (
          <div css={Css.w(billFileSectionSize).pr2.$}>
            <div css={Css.bgWhite.bshBasic.br8.mhPx(400).oa.$}>
              <div css={Css.df.jcfe.p1.$}>
                <IconButton icon="x" onClick={() => setShowBillFile(false)} />
              </div>
              <PdfViewer
                hasHeader
                assets={formState.documents.value.map(({ asset }) => asset)}
                handlePdfDelete={(assetId) =>
                  formState.documents.remove(formState.documents.value.findIndex((d) => d.asset.id === assetId))
                }
                pdfPageWidth={getDynamicPdfVierwerWidth(browserWidth)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

type FinalBillFormActionsProps = {
  bill: BillPageV2_BillFragment;
  onDelete: () => Promise<void>;
  onReverse: () => Promise<void>;
  onCancel: () => Promise<void>;
  openModal: (props: ModalProps) => void;
};

type FinalActionButton = { [label: string]: Pick<BillActionConfirmationModalProps, "action" | "onConfirm"> };

function FinalBillFormActions({ bill, onCancel, onDelete, onReverse, openModal }: FinalBillFormActionsProps) {
  // Get the final options (Delete, Reverse, Cancel) the user can take on the bill
  const finalActionButtons = useMemo<FinalActionButton>(() => {
    const { canDelete, canReverse, canCancel } = bill;
    return {
      ...(canDelete.allowed && { Delete: { onConfirm: onDelete, action: "delete" } }),
      ...(canReverse.allowed && { Reverse: { onConfirm: onReverse, action: "reverse" } }),
      ...(canCancel.allowed && { Cancel: { onConfirm: onCancel, action: "cancel" } }),
    };
  }, [bill, onCancel, onDelete, onReverse]);

  // Render the final action buttons
  return finalActionButtons.toEntries().map(([label, props]) => (
    <div css={Css.mr1.$} key={label}>
      <Button
        label={label}
        variant="danger"
        onClick={() => openModal({ content: <BillActionConfirmationModal bill={bill} {...props} /> })}
      />
    </div>
  ));
}

export type FormLineItem = Omit<SaveBillLineItemInput, "costCode"> &
  Pick<BillPageV2_CommitmentLineItemFragment, "costChangeInCents"> & {
    unbilledInCents?: number;
    pendingBilledInCents?: number;
    uncommittedBudgetAmountInCents?: number;
    revisedApprovedBudgetInCents?: number;
    isPendingOrBilled?: boolean;
    displayName: string;
    bliId?: string;
    otherBillInCents?: number | null;
    owner?: { __typename?: "Commitment" | "CommitmentChangeOrder" | undefined; id: string; accountingNumber: number };
    bills?: BillFormLineItemOtherBills[];
  };

export type FormInput = Omit<
  SaveBillInput,
  "tradePartnerId" | "balanceInCents" | "documents" | "quickbooksId" | "lineItems" | "dueDate" | "billDate"
> & {
  name: string;
  documents: BillPageV2_DocumentFragment[];
  dueDate?: DateOnly | null;
  billDate?: DateOnly | null;
  paidInCents?: number | null;
  billedInCents?: number | null;
  type: BillType;
  lineItems?: FormLineItem[];
  intacctId?: string | null;
  tradePartner?: BillPageV2_BillTradePartnerFragment;
  projectStage?: BillPageV2_BillProjectStageFragment;
};

export const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  name: { type: "value" },
  billDate: { type: "value", rules: [required] },
  dueDate: { type: "value" },
  postedDate: { type: "value" },
  paidDate: { type: "value" },
  billedInCents: { type: "value" },
  paidInCents: { type: "value" },
  internalNote: { type: "value" },
  type: { type: "value" },
  intacctId: { type: "value" },
  isTradePartnerCredit: { type: "value" },
  lineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      bliId: { type: "value", readOnly: true },
      displayName: { type: "value" },
      costChangeInCents: { type: "value" },
      commitmentLineItemId: { type: "value" },
      projectItemId: { type: "value" },
      amountInCents: { type: "value" },
      pendingBilledInCents: { type: "value" },
      isPendingOrBilled: { type: "value" },
      unbilledInCents: { type: "value" },
      revisedApprovedBudgetInCents: { type: "value" },
      uncommittedBudgetAmountInCents: { type: "value" },
      otherBillInCents: { type: "value" },
      owner: { type: "object", config: { id: { type: "value" }, accountingNumber: { type: "value" } } },
      bills: {
        type: "list",
        config: {
          id: { type: "value" },
          name: { type: "value" },
          dueDate: { type: "value" },
          billedInCents: { type: "value" },
          lineItems: {
            type: "list",
            config: {
              id: { type: "value" },
              amountInCents: { type: "value" },
              projectItem: {
                type: "object",
                config: {
                  id: { type: "value" },
                  displayName: { type: "value" },
                },
              },
            },
          },
          paidInCents: { type: "value" },
          status: { type: "value" },
          type: { type: "value" },
        },
      },
    },
  },
  tradePartner: {
    type: "object",
    config: {
      id: { type: "value" },
      name: { type: "value" },
      isLienWaiverReceiver: { type: "value" },
      paymentTerm: {
        type: "object",
        config: {
          id: { type: "value" },
          dueDay: { type: "value" },
          alignWithPaymentCycle: { type: "value" },
          dueFrom: {
            type: "object",
            config: {
              code: { type: "value" },
              name: { type: "value" },
            },
          },
        },
      },
    },
  },
  projectStage: {
    type: "object",
    config: {
      id: { type: "value" },
      stage: {
        type: "object",
        config: {
          code: { type: "value" },
        },
      },
      project: {
        type: "object",
        config: {
          id: { type: "value" },
          name: { type: "value" },
        },
      },
    },
  },
  documents: {
    type: "list",
    rules: [required],
    config: {
      id: { type: "value" },
      name: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          attachmentUrl: { type: "value" },
          contentType: { type: "value" },
          createdAt: { type: "value" },
          downloadUrl: { type: "value" },
          version: { type: "value" },
        },
      },
    },
  },
};

export function mapToForm(bill: BillPageV2_BillFragment): FormInput {
  const { type, status, lineItems, ...others } = bill;
  return {
    ...others,
    type: type.code,
    lineItems: isHeadlessBill(bill)
      ? lineItems.map((li) => ({
          id: li.id,
          amountInCents: li.amountInCents,
          projectItem: li.projectItem,
          bliId: li.id,
          displayName: li.projectItem.displayName,
          pendingBilledInCents: 0,
          isPendingOrBilled: bill.isPendingOrBilled,
          costChangeInCents: 0,
          projectItemId: li.projectItem.id,
          unbilledInCents: Number(li.projectItem.uncommittedBudgetAmountInCents),
          uncommittedBudgetAmountInCents: li.projectItem.uncommittedBudgetAmountInCents,
          revisedApprovedBudgetInCents: li.projectItem.revisedApprovedBudgetInCents,
        }))
      : lineItems.map((li) => ({
          id: li.id,
          commitmentLineItemId: li.commitmentLineItem?.id,
          amountInCents: li.amountInCents,
          pendingBilledInCents: li.commitmentLineItem?.pendingBilledInCents,
          isPendingOrBilled: bill.isPendingOrBilled,
          costChangeInCents: li.commitmentLineItem?.costChangeInCents,
          owner: li.commitmentLineItem?.owner,
          displayName: li.commitmentLineItem?.projectItem.displayName ?? "",
          unbilledInCents: li.commitmentLineItem?.unbilledInCents ?? 0,
          otherBillInCents: 0,
          projectItemId: li.projectItem.id,
          bills: li.projectItem.bills.filter((b) => b.id !== bill.id),
        })),
  };
}

export function calcLineItemValues(li: ObjectState<FormLineItem>, isCredit?: boolean) {
  const {
    pendingBilledInCents: { value: pendingBilledInCents = 0 },
    costChangeInCents: { value: costChangeInCents = 0 },
    isPendingOrBilled: { value: isPendingOrBilled = true },
  } = li;

  const sign = isCredit ? -1 : 1;
  const amountInCents = isPendingOrBilled ? Number(li.amountInCents.value) || 0 : 0;
  const amountInCentsOriginal = isPendingOrBilled ? Number(li.amountInCents.originalValue) || 0 : 0;
  // We do not want to include this bill amount in the "billedInCents" column, so extract this amount out.
  const otherBilledInCents = Number(pendingBilledInCents) - amountInCentsOriginal * sign;
  return {
    otherBilledInCents,
    thisBilledInCents: Number(amountInCents),
    unBilledInCents: Number(costChangeInCents) - (otherBilledInCents + Number(amountInCents) * sign),
  };
}
