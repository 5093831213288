import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal, useSnackbar } from "@homebound/beam";
import { useHistory } from "react-router";
import { useSaveGlobalPlanTaskMutation } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { catalogTasksPath } from "src/routes/routesDef";

type ArchiveCatalogTaskModalProps = {
  catalogTaskName: string;
  catalogTaskId: string;
};

export function ArchiveCatalogTaskModal({ catalogTaskName, catalogTaskId }: ArchiveCatalogTaskModalProps) {
  const [saveGlobalPlanTask] = useSaveGlobalPlanTaskMutation();
  const { triggerNotice } = useSnackbar();
  const { push } = useHistory();

  const onSubmit = async () => {
    await saveGlobalPlanTask({
      variables: { input: { id: catalogTaskId, archived: true } },
      onCompleted: () => {
        triggerNotice({
          message: "Task was successfully archived.",
        });
        push(catalogTasksPath);
      },
      refetchQueries: ["TaskCatalogTable"],
    });
  };

  return (
    <ConfirmationModal
      title="Archive Task"
      label=" Yes, Archive"
      confirmationMessage={
        <>
          <p>
            Are you sure you want to archive this task <span css={Css.smSb.$}>{catalogTaskName}</span> from the Task
            Catalog? It can be kept for historical data associated with it
          </p>{" "}
          <br />
          <p>
            {`Existing packages and plans that use this task are unaffected, but the task can no longer be added to a
      Building Shell Package or Build Plan.`}
          </p>
        </>
      }
      onConfirmAction={onSubmit}
    />
  );
}
