import { IconButton, SelectField, SelectFieldProps, TextField, Value, Css } from "@homebound/beam";
import { useState, ReactNode } from "react";
import { HasIdAndName } from "src/utils";

type SelectMaybeNewFieldProps<O extends HasIdAndName, V extends Value> = SelectFieldProps<O, V> & {
  onAdd: (value: string) => void;
  helperText?: string | ReactNode;
};

export function SelectMaybeNewField<O extends HasIdAndName, V extends Value>(props: SelectMaybeNewFieldProps<O, V>) {
  const [adding, setAdding] = useState(false);
  const [newOption, setNewOption] = useState<string | undefined>(undefined);
  const { onAdd, helperText, ...selectProps } = props;

  if (adding) {
    return (
      <div
        onKeyUp={(e) => {
          if (e.key === "Escape") {
            setNewOption(undefined);
            setAdding(false);
          }
        }}
      >
        <TextField
          label={`New ${props.label}`}
          helperText={helperText}
          value={newOption}
          autoFocus
          compact
          onEnter={() => {
            if (newOption) {
              setNewOption(undefined);
              onAdd(newOption);
            }
            setAdding(false);
          }}
          endAdornment={
            <IconButton
              icon="x"
              onClick={() => {
                setNewOption(undefined);
                setAdding(false);
              }}
            />
          }
          onChange={(v) => setNewOption(v)}
        />
      </div>
    );
  }

  return (
    <SelectField
      {...selectProps}
      onAddNew={(v) => {
        setAdding(true);
        setNewOption(v);
      }}
      compact
    />
  );
}
