import { Global } from "@emotion/react";
import { Css } from "@homebound/beam";
import {
  FinishScheduleVisualModePdfQuery,
  FinishScheduleVisualMode_ItiVersionFragment,
  FinishScheduleVisualMode_ReadyPlanOptionFragment,
  NamedFragment,
  useFinishScheduleVisualModePdfQuery,
} from "src/generated/graphql-types";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { fail, queryResult } from "src/utils";
import { ArrayParam, StringParam, useQueryParams } from "use-query-params";
import { generateCombinations, parseFilterArray } from "./FinishSchedulePdf";

export function FinishScheduleVisualModePdf() {
  const [{ designTemplateId, planTemplateIds, optionIds, costCodeIds, locationInPath }] = useQueryParams({
    designTemplateId: StringParam,
    planTemplateIds: ArrayParam,
    optionIds: ArrayParam,
    costCodeIds: ArrayParam,
    locationInPath: ArrayParam,
  });

  const parsedOptionIds = parseFilterArray(optionIds);

  const query = useFinishScheduleVisualModePdfQuery({
    variables: {
      filter: {
        designTemplateId: designTemplateId ?? fail("Design Package Template Id is required"),
        optionIds: parsedOptionIds,
        planTemplateIds: parseFilterArray(planTemplateIds),
        costCodeIds: parseFilterArray(costCodeIds),
        locationInPath: parseFilterArray(locationInPath),
      },
    },
  });

  return queryResult(query, (data) => <VisualModePdfView data={data} optionIds={parsedOptionIds} />);
}

function VisualModePdfView(props: {
  data: FinishScheduleVisualModePdfQuery;
  optionIds: (string | null)[] | null | undefined;
}) {
  const { data, optionIds } = props;
  const itivs = data.designPackageFinishSchedule.entities;
  // Generate all possible combinations of forDesignPackage GOGs
  const idps = getIndividualDesignPackages(itivs, data.locations, optionIds);
  return (
    <>
      <Global styles={{ "@page": { size: "landscape" } }} />

      {idps.map(({ rpos, locations, order }) => (
        <IndividualDesignPackageView key={order} rpos={rpos} locations={locations} order={order} />
      ))}
    </>
  );
}

function IndividualDesignPackageView({ rpos, locations, order }: IndividualDesignPackage) {
  // Spec Level group should always be ordered first ==> [Deluxe, Contemporary, Light]
  const [primaryRpoName, ...otherRpoNames] = rpos.map((rpo) => rpo.name);
  // After troubleshooting, we found that the background color wasn't filling the entire page.
  // The standard size of a PDF page is 8.5x11 inches, which translates to 816px x 1056px.
  // To ensure the background color covers the entire page, we set the margins to 0 and adjusted the height and width to 1056px and 816px, respectively.
  const pageWidth = 1056;
  const pageHeight = 816;
  return (
    <div css={Css.wPx(pageWidth).$} key={order} style={{ pageBreakBefore: "always" }}>
      <div /* Cover Page */ css={Css.hPx(pageHeight).df.fdc.p4.add("backgroundColor", "rgba(235, 234, 228, 1)").$}>
        <div>
          <img src="/wordmark.svg" alt="Homebound" css={Css.hPx(40).$} />
        </div>
        <div css={Css.hPx(600).df.fdc.jcc.gap2.$}>
          <div css={Css.xl4Md.fontFamily("PT Serif").$}>{otherRpoNames.join(" ") ?? ""}</div>
          <div css={Css.df.gap1.$}>
            <img css={Css.hPx(40).$} src="/images/spec-level-pdf-icon.svg" alt="" />
            <div css={Css.p1.fw(400).color("rgba(198, 151, 117, 1)").fs("26px").$}>
              {/* Spec Level Name */}
              {primaryRpoName}
            </div>
          </div>
        </div>
      </div>
      {/* Loop over each location to create a page, i.e. Kitchen, Whole House, etc. */}
      {locations.map((locationGroup) => {
        return (
          <div key={locationGroup.location?.id}>
            {/* Loop through each group of ITIVs within a location, setting groups of 8 to limit the number displayed per page. */}
            {locationGroup.locationItivs.batched(8).map((itivs, i) => (
              <div key={i} style={{ pageBreakBefore: "always" }} css={Css.hPx(pageHeight).df.fdc.p4.$}>
                <div /* Location Header */ css={Css.df.jcsb.$}>
                  <div css={Css.xl4Md.fontFamily("PT Serif").$}>{locationGroup.location?.name}</div>
                  <div css={Css.df.gap1.$}>
                    <img css={Css.hPx(26).$} src="/images/spec-level-pdf-icon.svg" alt="" />
                    <div css={Css.pPx(2).fw(400).color("rgba(198, 151, 117, 1)").fs("20px").$}>
                      {/* Spec Level Name */}
                      {primaryRpoName}
                    </div>
                  </div>
                </div>

                {/* Setting a height here will contain the product cards and prevent them from spilling onto the next page if a card's height exceeds a certain limit. */}
                <div /* List of Product (itiv) Cards */ css={Css.df.fww.gap3.pt2.hPx(700).$}>
                  {itivs.map((itiv) => (
                    <div key={itiv.id} css={Css.wPx(175).$}>
                      <div /* Product Image */ css={Css.hPx(175).w100.ba.bcGray200.bw2.p1.df.aic.jcc.$}>
                        <img
                          src={itiv.materialVariant?.featuredImage?.asset?.previewUrl ?? PRODUCT_FALLBACK_IMG_URL}
                          alt={itiv.scope.item.name}
                          css={Css.maxw100.maxh100.$}
                        />
                      </div>

                      <div /* Product Details */ css={Css.df.fdc.mt1.$}>
                        <div css={Css.smBd.fontFamily("PT Serif").$}>{itiv.scope.item.name}</div>
                        <div css={Css.smMd.fs("12px").$}>{itiv.materialVariant?.listing.brand?.name}</div>
                        <div css={Css.fs("12px").$}>{itiv.materialVariant?.listing.name}</div>
                        {itiv.materialVariant?.materialAttributeValues
                          .filter((value) => value.dimension.useInVisualModePdf)
                          .map((value) => (
                            <div key={value.id} css={Css.fs("12px").$}>
                              {value.value}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>

                <div /* Footer */ css={Css.df.jcfe.$}>
                  <div>
                    <img src="/wordmark.svg" alt="Homebound" css={Css.hPx(30).$} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

type IndividualDesignPackage = {
  rpos: FinishScheduleVisualMode_ReadyPlanOptionFragment[];
  order: number;
  locations: {
    location: NamedFragment | undefined;
    locationItivs: FinishScheduleVisualMode_ItiVersionFragment[];
  }[];
};

function getIndividualDesignPackages(
  itivs: FinishScheduleVisualMode_ItiVersionFragment[],
  locations: NamedFragment[],
  optionIds: (string | null)[] | null | undefined,
): IndividualDesignPackage[] {
  // Generate all possible combinations of forDesignPackage GOGs
  const rposByGOG = itivs
    .flatMap((iti) =>
      iti.scope.options.filter((rpo) =>
        // only include RPOs that are in the filter optionIds || are forDesignPackages
        optionIds?.nonEmpty ? optionIds.includes(rpo.id) : rpo.optionGroup.forDesignPackages,
      ),
    )
    .uniqueByKey("id")
    .groupByObject((rpo) => rpo.optionGroup)
    .map(([_, rpos]) => rpos);

  const combinations = generateCombinations(rposByGOG);

  // Each combination represents an IDP
  return combinations
    .map((rpos) => {
      const sortedRpos = rpos.sortBy((rpo) => rpo.optionGroup.order);
      // Filter ITIVs looking for options that matches this IDP/RPO combination
      const combinationItis = itivs.filter((itiv) => rpos.every((rpo) => itiv.scope.options.includes(rpo)));
      const locationGroups = combinationItis.groupBy((iti) => iti.location?.id ?? "none");
      return {
        rpos: sortedRpos, // The combination of RPO that defines this IDP name
        order: sortedRpos.sum((rpo) => rpo.order),
        locations: Object.entries(locationGroups).map(([locationId, locationItivs]) => {
          return {
            location: locations.find((l) => l.id === locationId),
            locationItivs,
          };
        }),
      };
    })
    .sortByKey("order");
}
