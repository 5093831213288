import { Css, ScrollableParent, Step } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { Location } from "history";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ExitIconButton } from "src/components/ExitIconButton";
import { StepperProvider, useStepperContext } from "src/components/stepper";
import { ApplyToProjectsStep } from "src/routes/developments/templates/review-and-publish/components/ApplyToProjectsStep";
import { DevelopmentParams } from "src/routes/routesDef";
import { createDevelopmentScopeTemplatesUrl } from "src/RouteUrls";
import { ReviewStep } from "./components/ReviewStep";
import { RenderMode } from "./components/utils";
import { TemplateVersioningStep } from "./enums";
import { foldEnum } from "src/utils";

export function ReviewAndPublishPage() {
  const { state } = useLocation<{
    itemTemplatesIds?: string[];
    mode: RenderMode;
    fromEditAction?: true;
    currentStep?: TemplateVersioningStep;
    from?: Location<unknown>;
  }>();
  const { mode } = useMemo(
    () => ({ id: state?.itemTemplatesIds ?? [], mode: state?.mode ?? RenderMode.edit }),
    [state],
  );

  const { currentStep = TemplateVersioningStep.REVIEW, from } = useMemo(() => state ?? {}, [state]);

  const steps: Step[] = useMemo(() => {
    const COMPLETE = "complete" as const;
    const INCOMPLETE = "incomplete" as const;
    return [
      {
        label: `Review${mode === RenderMode.edit ? " & Publish" : ""}`,
        state: [TemplateVersioningStep.REVIEW].includes(currentStep) ? INCOMPLETE : COMPLETE,
        value: TemplateVersioningStep.REVIEW,
      },
      {
        label: "Apply to Projects",
        state: "incomplete",
        value: TemplateVersioningStep.PUBLISH,
      },
    ];
  }, [currentStep, mode]);

  return (
    <StepperProvider steps={steps} currentStep={steps[0]}>
      <ReviewAndPublishContent returnUrl={from && `${from.pathname}${from.search}`} />
    </StepperProvider>
  );
}

function ReviewAndPublishContent({ returnUrl }: { returnUrl?: string }) {
  const { developmentId } = useParams<DevelopmentParams>();

  const { currentStep } = useStepperContext();

  const formState = useFormState({ config: {} });

  return (
    <ScrollableParent xss={Css.px8.$}>
      <div css={Css.position("absolute").right0.mt4.mr8.$}>
        <ExitIconButton
          formState={formState}
          confirmUrl={returnUrl || createDevelopmentScopeTemplatesUrl(developmentId)}
        />
      </div>
      {foldEnum(currentStep.value, {
        [TemplateVersioningStep.REVIEW]: () => <ReviewStep />,
        [TemplateVersioningStep.PUBLISH]: () => <ApplyToProjectsStep />,
      })}
    </ScrollableParent>
  );
}
