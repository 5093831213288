import { Css, Tooltip, useTestIds } from "@homebound/beam";
import { Icon } from "src/components";
import { ApprovalStatus, ApproverUserDetailFragment } from "src/generated/graphql-types";
import { useApprovalBreakpoint } from "./approvalAtoms";
import { useApprovalContext } from "./ApprovalSuperDrawer";
import { ApproversDetail } from "./ApproverDetail";

type ApprovalApproversProps = {
  /** Render the subjects' predicted approvers if the approval is in `create` mode or approvalStatus is changesNeeded */
  subjects: { predictedApprovers: ApproverUserDetailFragment[] }[];
};

export function ApprovalApprovers({ subjects }: ApprovalApproversProps) {
  const { approvals = [] } = useApprovalContext();
  const [approval] = approvals;
  const approvers = approval?.approvers;
  const predictedUsers = subjects.first?.predictedApprovers ?? [];
  const tid = useTestIds({}, "approvers");
  const desktop = useApprovalBreakpoint();

  // TODO: use invert2DArray to compare approver/predictedApprover across Subjects and render
  // the TP + "Various approvers" or everyone who is the same across subjects

  return (
    <div css={Css.mt5.if(desktop).ml5.$}>
      <div css={Css.baseSb.mb2.df.fdr.aic.gap1.$} {...tid.subheader}>
        Approval Order
        <Tooltip
          placement="right"
          title="Approval moves on to the next person after the first person approves the request."
        >
          <Icon icon="infoCircle" pxSize={16} />
        </Tooltip>
      </div>
      <div css={Css.df.fdc.gap1.$}>
        {approvers?.isEmpty && (
          <div css={Css.xsMd.gray600.$}>
            {approval.isFinalized
              ? "Approval was automatically finalized without any Approvers"
              : "Approval is currently predicted to have no Approvers and will auto-accept."}
          </div>
        )}
        {approvers?.nonEmpty && approval.status.code !== ApprovalStatus.ChangesNeeded
          ? approvers.map((approver) => <ApproversDetail key={approver.id} approver={approver} />)
          : // In the case we are within `Approver.ChangesNeeded` status:
            // If an approver exists that has requested changes, or accepted
            // We're interested in seeing that approver status & updatedAt info
            predictedUsers.map((predictedApprover) => {
              const approver = approvers?.find((a) => a.id === predictedApprover.id) ?? { user: predictedApprover };
              return <ApproversDetail key={predictedApprover.id} approver={approver} />;
            })}
      </div>
    </div>
  );
}
