import { GridDataRow } from "@homebound/beam";
import { IObservableArray, observable } from "mobx";
import {
  ConfirmLineItemsHomeownerContractLineItemFragment,
  ConfirmLineItemsHomeownerContractsFragment,
  CostCode,
  InvoiceV2Fragment,
} from "src/generated/graphql-types";
import { observableAggregate, Selectable } from "src/models";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";
import { InvoiceLineItemsDataRow } from "../components/InvoiceLineItemsTable";
import { InvoiceLineItemsFilter } from "../invoice-steps/ConfirmLineItems";
import { InvoiceHomeownerContractLineItem } from "./InvoiceHomeownerContractLineItem";
import { calculateHCLIMarkup } from "../../utils";

export class InvoiceHomeownerContractLineItemsStore extends Selectable<ObservableLineItemGroup> {
  readonly items: IObservableArray<InvoiceHomeownerContractLineItem>;

  constructor(
    lineItems: ConfirmLineItemsHomeownerContractsFragment["lineItemsByProjectItem"],
    filter: InvoiceLineItemsFilter,
    invoice: InvoiceV2Fragment,
  ) {
    const allHomeownerContractLineItems = lineItems.flatMap((li) => li.lineItems);
    const clisAmountUsed = new Map();
    const items = observable(
      lineItems.flatMap(({ projectItem, lineItems }) =>
        lineItems.map((hcli) => {
          const lineItem = new InvoiceHomeownerContractLineItem({
            homeownerContractLineItem: hcli,
            allHomeownerContractLineItems,
            filter,
            invoice,
            clisAmountUsed,
            projectItem,
            projectItemHclis: lineItems,
          });

          lineItem
            .unallocatedCostLineItems()
            .forEach((cli) => clisAmountUsed.set(`${hcli.id}-${cli.id}`, cli.amountInCents));
          return lineItem;
        }),
      ),
    );
    const children = observableAggregate(items, (i) => i.projectItem.item.costCode.id, ObservableLineItemGroup);
    super("all", false, children);
    makeSimpleAutoObservable(this);
    this.items = items;
  }
}

export function convertHistoricAmountsToAllocations(
  hcs: ConfirmLineItemsHomeownerContractsFragment[],
  invoiceId: string,
) {
  const hclisByProjectItem = hcs.flatMap((hcli) => hcli.lineItemsByProjectItem);

  return hclisByProjectItem.map(({ projectItem, lineItems }) => {
    const hclis: ConfirmLineItemsHomeownerContractLineItemFragment[] = [...lineItems];

    // For each cost go through and create allocation up to the HCLI cap
    projectItem.costLineItems
      .filter((cli) => cli.historicAmountInvoiced)
      .forEach((cli) => {
        let historicAmount = cli.historicAmountInvoiced * (cli.isTradePartnerCredit ? -1 : 1);

        for (let i = 0; i < hclis.length; i++) {
          const hcli = hclis[i];
          const hcliMarkup = calculateHCLIMarkup(hcli);

          // Add up other allocations
          const otherAllocations = hcli.invoiceLineItems
            .filter((ili) => ili.invoice.id !== invoiceId)
            .flatMap((ili) => ili.costLineItemAllocations)
            .flatMap((clia) => clia.invoicedAmountInCents)
            .sum();

          // If there is room to allocate
          if (hcli.priceChangeInCents! > otherAllocations) {
            // determine how much to allocate
            const historicPrice = Math.min(hcli.priceChangeInCents! - otherAllocations, historicAmount * hcliMarkup);
            // historic cost in cents rounded to avoid decimal problems when subtract
            // historic price plus contract markup multiplier
            const historicCost = Math.round(historicPrice * (1 / hcliMarkup));
            // create a new cost line item allocation
            hclis[i] = {
              ...hcli,
              invoiceLineItems: [
                ...hclis[i].invoiceLineItems,
                {
                  id: `ili-cli:${cli.id}`,
                  homeownerContractLineItem: {
                    id: hcli.id,
                  },
                  invoice: {
                    id: `inv-cli:${cli.id}`,
                  },
                  costLineItemAllocations: [
                    {
                      id: `clia-cli:${cli.id}`,
                      costLineItemId: cli.id,
                      costAmountInCents: historicCost,
                      invoicedAmountInCents: historicPrice,
                    },
                  ],
                },
              ],
            };

            // update the historicAmount to where if we need to update more HCLIS
            historicAmount -= historicCost;
            if (historicAmount === 0) {
              break;
            }
          }
        }
      });
    return { projectItem, lineItems: hclis };
  });
}

class ObservableLineItemGroup extends Selectable<InvoiceHomeownerContractLineItem> {
  public costCode: Pick<CostCode, "id" | "displayName">;

  constructor(hclis: InvoiceHomeownerContractLineItem[]) {
    const cc = hclis.first?.projectItem.item.costCode!;
    super(cc.id, false, hclis);
    this.costCode = cc;
    makeSimpleAutoObservable(this);
  }

  get hasChildrenActuals() {
    return this.children.some((hcli) => hcli.hasActualsOnCurrentPeriod);
  }

  toRow(selectedRowsIds: string[]): GridDataRow<InvoiceLineItemsDataRow> {
    const children = this.children
      .filter((hcli) => (hcli.filter.showOnlyActuals ? hcli.hasActualsOnCurrentPeriod : true))
      .map((hcli) => hcli.toRow(selectedRowsIds));

    return {
      kind: "costCode",
      id: this.id,
      selectable: this.hasChildrenActuals ? undefined : false,
      data: {
        displayName: this.costCode.displayName,
        ...children.reduce(
          (acc, { data }) => {
            return {
              invoicedToDate: (data.invoicedToDate ?? 0) + acc.invoicedToDate,
              periodActuals: (data.periodActuals ?? 0) + acc.periodActuals,
              remainingFromPrior: (data.remainingFromPrior ?? 0) + acc.remainingFromPrior,
              subtotal: (data.subtotal ?? 0) + acc.subtotal,
              toInvoice: (data.toInvoice ?? 0) + acc.toInvoice,
              homeownerContractAmount: (data.homeownerContractAmount ?? 0) + acc.homeownerContractAmount,
            };
          },
          {
            invoicedToDate: 0,
            periodActuals: 0,
            remainingFromPrior: 0,
            subtotal: 0,
            toInvoice: 0,
            homeownerContractAmount: 0,
          },
        ),
      },
      children,
    };
  }
}
