import { IObservableArray, observable } from "mobx";
import { DevelopmentItemMapping, DevelopmentScheduleItemMappingFragment } from "src/generated/graphql-types";
import { observableAggregate, Selectable } from "src/models";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";
import { DevelopmentPotentialItemMapping } from "./DevelopmentPotentialItemMapping";

export class DevelopmentItemMappingsStore extends Selectable<ObservableItemMappingGroup> {
  readonly items: IObservableArray<DevelopmentPotentialItemMapping>;

  constructor(itemMappings: DevelopmentScheduleItemMappingFragment[], scheduleTemplateId: string) {
    const items = observable(itemMappings.map((im) => new DevelopmentPotentialItemMapping(im, scheduleTemplateId)));
    const children = observableAggregate(items, (i) => i.costCode.id, ObservableItemMappingGroup);
    super("all", false, children);
    makeSimpleAutoObservable(this);
    this.items = items;
  }
}

class ObservableItemMappingGroup extends Selectable<DevelopmentPotentialItemMapping> {
  public costCode: Pick<DevelopmentItemMapping["item"]["costCode"], "id" | "displayName">;

  constructor(items: DevelopmentPotentialItemMapping[]) {
    const cc = items.first?.costCode!;
    super(cc.id, false, items);
    this.costCode = cc;
    makeSimpleAutoObservable(this);
  }

  get scheduleTaskToDo() {
    return `${this.children.filter((im) => im.taskId).length}/${this.children.length}`;
  }

  get isAllMapped() {
    return this.children.filter((i) => !i.isUnmapped).length === this.children.length;
  }
}
