import { AuthenticatedRoute, AuthViewProps, GoogleCallback, UnauthenticatedRoute } from "@homebound/auth-components";
import { Redirect, Route, Switch } from "react-router-dom";
import { DevelopmentScheduleCostMappingPage } from "src/routes/developments/cost-mapping/DevelopmentScheduleCostMappingPage";
import { LotReleasePage as LotConfigPageV2 } from "src/routes/developments/lot-summary/lot-release/LotReleasePage";
import { PersonalDashboardPage } from "src/routes/personal-dashboard/PersonalDashboard";
import { ProjectLayout } from "src/routes/projects/ProjectLayout";
import { ProjectsDevelopmentsPage } from "src/routes/projects/ProjectsDevelopmentsPage";
import { ItemTemplatePage } from "src/routes/settings/itemTemplates/ItemTemplatePage";
import { Admin } from "./routes/admin/Admin";
import { SignIn } from "./routes/auth/SignIn";
import { DevelopmentCommitmentPage } from "./routes/development-commitments/DevelopmentCommitmentPage";
import { DevelopmentCommitmentsPage } from "./routes/development-commitments/DevelopmentCommitmentsPage";
import { DevelopmentLayout } from "./routes/developments/DevelopmentLayout";
import { ReassignPurchaseOrdersPage } from "./routes/developments/reassign-purchase-orders/ReassignPurchaseOrdersPage";
import { ReviewAndPublishPage } from "./routes/developments/templates/review-and-publish/ReviewAndPublishPage";
import { ExpensesAdmin } from "./routes/expenses/ExpensesAdmin";
import { LibrariesLayout } from "./routes/libraries/LibrariesLayout";
import { ScheduleTemplateDetailPage } from "./routes/libraries/schedules/ScheduleTemplateDetail";
import { ScheduleTemplatesPage } from "./routes/libraries/schedules/ScheduleTemplatesPage";
import { NotFound } from "./routes/NotFound";
import { UserProfile } from "./routes/profile/UserProfile";
import { ProjectsPage } from "./routes/projects/ProjectsPage";
import {
  adminPaths,
  bidPackageDetailsPath,
  bidPackagesEditPath,
  bidPackagesPath,
  billDetailsPath,
  billsPath,
  changeLogPaths,
  costMappingPath,
  createBidPackagePath,
  createBillPath,
  developmentCommitmentsPath,
  developmentCommitmentsPaths,
  developmentLotConfigProjectPath,
  developmentPaths,
  developmentsPath,
  expensesPath,
  inboxPaths,
  invoicesPath,
  invoiceTermPath,
  invoiceTermsPath,
  itemTemplatePath,
  itemTemplatesPath,
  librariesPath,
  paymentTermsPath,
  personalDashboardPaths,
  projectPaths,
  projectsPath,
  purchaseOrdersPath,
  reassignPurchaseOrdersPath,
  scheduleTemplatePath,
  scheduleTemplatesPath,
  tradeCategoryLeadTimesPath,
  tradePartnerPaths,
  tradePartnersPath,
  tradesPath,
  warrantyPaths,
} from "./routes/routesDef";
import { ItemTemplatesPage } from "./routes/settings/itemTemplates/ItemTemplatesPage";
import { TradeCategories } from "./routes/trade-categories/TradeCategories";
import { TradeCategoryLeadTimesPage } from "./routes/trade-partners/trade-category-lead-times/TradeCategoryLeadTimesPage";
import { TradePartnerPage } from "./routes/trade-partners/TradePartnerPage";
import { TradePartnersPage } from "./routes/trade-partners/TradePartnersPage";
import { ChangeLogPage } from "./routes/change-log/ChangeLogPage";
import { ReviewCostsPage } from "./routes/developments/templates/review-costs/ReviewCostsPage";
import { InboxPage } from "./routes/inbox/InboxPage";
import { PaymentTermsPage } from "./routes/trade-partners/payment-terms/PaymentTermsPage";
import { WarrantyPage } from "./routes/warranty/WarrantyPage";
import { BusinessFunctionsPage } from "src/components/change-log/BusinessFunctionsPage";
import { BusinesFunctionDetailPage } from "src/components/change-log/BusinessFunctionDetailsPage";
import { EditOptionsPage } from "./routes/developments/plan-and-options/options/EditOptionsPage";
import { WarrantyTicketPage } from "./routes/warranty/WarrantyTicketPage";
import { ChangeTypesPage } from "./components/change-log/ChangeTypesPage";
import { ExploreSyncPage } from "./routes/explore/ExploreSyncPage";
import { InvoiceTermsForm } from "./routes/billing/invoice-terms/components/InvoiceTermsForm";
import { FinancesPage } from "./routes/billing/FinancesPage";
import { AddBidPackagePage } from "./routes/bids/add-bid-package/AddBidPackagePage";
import { BillEditorV3 } from "./routes/bills/BillEditorV3";
import { TradesLayout } from "./routes/libraries/global-options/TradesLayout";
import { BidPackagesPage } from "./routes/bids/BidPackagesPage";
import { BillPageV2 } from "./routes/bills/BillPageV2";
import { BidPackageDetailPage } from "./routes/bids/BidPackageDetailPage";

export type ProjectRouteProps = { projectId: string };

export function Routes({ authProps }: { authProps: AuthViewProps }) {
  return (
    <Switch>
      <Redirect exact from="/" to={personalDashboardPaths.base} />
      <AuthenticatedRoute path="/profile" exact component={UserProfile} authProps={authProps} />
      <AuthenticatedRoute
        path={[projectsPath, developmentsPath]}
        exact
        component={ProjectsDevelopmentsPage}
        authProps={authProps}
      />
      <AuthenticatedRoute path={projectsPath} exact component={ProjectsPage} authProps={authProps} />
      <AuthenticatedRoute path={developmentLotConfigProjectPath} component={LotConfigPageV2} authProps={authProps} />
      <AuthenticatedRoute
        path={reassignPurchaseOrdersPath}
        component={ReassignPurchaseOrdersPage}
        authProps={authProps}
      />
      <AuthenticatedRoute
        path={developmentPaths.reviewTemplates}
        component={ReviewAndPublishPage}
        authProps={authProps}
      />
      <AuthenticatedRoute
        path={developmentPaths.reviewTemplateCosts}
        component={ReviewCostsPage}
        authProps={authProps}
      />
      <AuthenticatedRoute path={developmentPaths.editOptions} component={EditOptionsPage} authProps={authProps} />
      <AuthenticatedRoute path={developmentsPath} exact component={ProjectsDevelopmentsPage} authProps={authProps} />
      <AuthenticatedRoute path={developmentPaths.development} component={DevelopmentLayout} authProps={authProps} />
      <AuthenticatedRoute path={costMappingPath} component={DevelopmentScheduleCostMappingPage} authProps={authProps} />
      <AuthenticatedRoute path={projectPaths.project} component={ProjectLayout} authProps={authProps} />
      <AuthenticatedRoute path={inboxPaths.base} component={InboxPage} authProps={authProps} />
      <AuthenticatedRoute
        path={personalDashboardPaths.dashboard}
        component={PersonalDashboardPage}
        authProps={authProps}
      />
      {/* Support old `/my-bluprint` redirects for historical purposes */}
      <AuthenticatedRoute
        path="/my-blueprint"
        exact
        authProps={authProps}
        render={() => <Redirect to={personalDashboardPaths.actions} />}
      />
      <AuthenticatedRoute
        path="/my-blueprint/to-dos"
        exact
        authProps={authProps}
        render={() => <Redirect to={personalDashboardPaths.toDos} />}
      />
      <AuthenticatedRoute
        path="/my-blueprint/activity-feed"
        exact
        authProps={authProps}
        render={() => <Redirect to={personalDashboardPaths.activity} />}
      />
      <AuthenticatedRoute
        path={[billsPath, invoicesPath, purchaseOrdersPath, invoiceTermsPath]}
        exact
        component={FinancesPage}
        authProps={authProps}
      />
      <AuthenticatedRoute path={createBillPath} exact component={BillEditorV3} authProps={authProps} />
      <AuthenticatedRoute path={billDetailsPath} exact component={BillPageV2} authProps={authProps} />
      <AuthenticatedRoute path="/admin/tradeCategories" exact component={TradeCategories} authProps={authProps} />
      <AuthenticatedRoute path={invoiceTermPath} component={InvoiceTermsForm} authProps={authProps} />
      <AuthenticatedRoute path={adminPaths.admin} component={Admin} authProps={authProps} />
      <AuthenticatedRoute path={tradePartnersPath} exact component={TradePartnersPage} authProps={authProps} />
      <AuthenticatedRoute path={createBidPackagePath} component={AddBidPackagePage} authProps={authProps} />
      <AuthenticatedRoute path={bidPackagesEditPath} component={AddBidPackagePage} authProps={authProps} />
      <AuthenticatedRoute path={bidPackagesPath} exact component={BidPackagesPage} authProps={authProps} />
      <AuthenticatedRoute path={bidPackageDetailsPath} exact component={BidPackageDetailPage} authProps={authProps} />
      <AuthenticatedRoute path={tradePartnerPaths.tradePartner} component={TradePartnerPage} authProps={authProps} />
      <AuthenticatedRoute path={paymentTermsPath} component={PaymentTermsPage} authProps={authProps} />
      <AuthenticatedRoute
        path={tradeCategoryLeadTimesPath}
        exact
        component={TradeCategoryLeadTimesPage}
        authProps={authProps}
      />
      <AuthenticatedRoute path={tradesPath} component={TradesLayout} authProps={authProps} />
      <AuthenticatedRoute path={librariesPath} component={LibrariesLayout} authProps={authProps} />
      <AuthenticatedRoute path={scheduleTemplatesPath} authProps={authProps} exact component={ScheduleTemplatesPage} />
      <AuthenticatedRoute
        path={scheduleTemplatePath.scheduleTemplate}
        authProps={authProps}
        exact
        component={ScheduleTemplateDetailPage}
      />
      <AuthenticatedRoute path={itemTemplatesPath} exact component={ItemTemplatesPage} authProps={authProps} />
      <AuthenticatedRoute path={itemTemplatePath} component={ItemTemplatePage} authProps={authProps} />
      <AuthenticatedRoute
        path={developmentCommitmentsPath}
        exact
        component={DevelopmentCommitmentsPage}
        authProps={authProps}
      />
      <AuthenticatedRoute
        path={developmentCommitmentsPaths.developmentCommitment}
        exact
        component={DevelopmentCommitmentPage}
        authProps={authProps}
      />
      <AuthenticatedRoute
        path={changeLogPaths.businessFunctionDetails}
        component={BusinesFunctionDetailPage}
        authProps={authProps}
      />
      <AuthenticatedRoute
        path={changeLogPaths.businessFunctions}
        component={BusinessFunctionsPage}
        authProps={authProps}
      />
      <AuthenticatedRoute path={changeLogPaths.changeTypes} component={ChangeTypesPage} authProps={authProps} />
      <AuthenticatedRoute path={changeLogPaths.base} component={ChangeLogPage} authProps={authProps} />
      <AuthenticatedRoute path={expensesPath} exact component={ExpensesAdmin} authProps={authProps} />
      <AuthenticatedRoute path={warrantyPaths.base} exact component={WarrantyPage} authProps={authProps} />
      <AuthenticatedRoute path={warrantyPaths.details} component={WarrantyTicketPage} authProps={authProps} />
      <AuthenticatedRoute path="/explore/sync" component={ExploreSyncPage} authProps={authProps} />
      <UnauthenticatedRoute path="/signIn" exact component={SignIn} authProps={authProps} />
      <UnauthenticatedRoute path="/auth/gcallback" exact component={GoogleCallback} authProps={authProps} />
      <Route component={NotFound} />
    </Switch>
  );
}
