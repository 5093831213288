import { Css, useComputed, useSnackbar, useTestIds } from "@homebound/beam";
import { ObjectState, useFormState } from "@homebound/form-state";
import {
  AddProgramDetailsFragment,
  InputMaybe,
  PlanPackageProgramDataFragment,
  ProgramDataStepMetadataQuery,
  SaveProgramDataInput,
  useProgramDataStepMetadataQuery,
  useSavePlanPackageDetailsMutation,
} from "src/generated/graphql-types";
import {
  AddOptionsMetadata,
  AddProgramDetailsForm,
  OptionsEnumNames,
  readyPlanProgramDataConfig,
} from "../../../developments/plan-and-options/components/utils";
import {
  BasementCard,
  PlanDetailsCard,
  PlanDetailsCardLine,
} from "../../../developments/plan-and-options/components/PlanDetailsCard";
import { createPlanPackageUrl } from "src/RouteUrls";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";

import { queryResult } from "src/utils";
import { useEffect } from "react";
import { useNavigationCheck } from "src/components";
import { PlanPackageEditorHeader } from "src/routes/libraries/plan-package/stepper/components/PlanPackageEditorHeader";
import { StepLayout } from "src/components/stepper/StepLayout";

type ProgramDataStepProps = {
  id: string;
  setStepDirty: (dirty: boolean) => void;
};

export type ProgramDataMetadata = Pick<ProgramDataStepMetadataQuery, "enumDetails"> | undefined;

export function ProgramDataStep({ id, setStepDirty }: ProgramDataStepProps) {
  const query = useProgramDataStepMetadataQuery({ variables: { id } });

  return (
    <StepLayout
      header={
        <PlanPackageEditorHeader
          title="Program Data"
          tooltip="Choose the plan details that you would like to be applicable for this Readyplan"
        />
      }
      body={queryResult(query, {
        data: (data) =>
          data.planPackage ? (
            <DataView id={id} metadata={data} planPackage={data.planPackage} setStepDirty={setStepDirty} />
          ) : (
            <>"No Plan Package found"</>
          ),
      })}
    />
  );
}

export function DataView({
  id,
  metadata,
  planPackage,
  setStepDirty,
}: {
  id: string;
  metadata: ProgramDataMetadata;
  planPackage: PlanPackageProgramDataFragment;
  setStepDirty: (dirty: boolean) => void;
}) {
  const { triggerNotice } = useSnackbar();
  const formState = useFormState<AddProgramDetailsForm, AddProgramDetailsFragment>({
    config: readyPlanProgramDataConfig,
    init: {
      id: id,
      description: planPackage?.description,
      programData: {
        ...planPackage?.programData,
        basementConfig: planPackage?.programData?.basementConfig?.code,
        garageConfiguration: planPackage?.programData?.garageConfiguration?.code,
        primaryBedroom: planPackage?.programData?.primaryBedroom?.code,
      },
      minProgramData: {
        ...planPackage?.minProgramData,
        basementConfig: planPackage?.programData?.basementConfig?.code,
        garageConfiguration: planPackage?.programData?.garageConfiguration?.code,
        primaryBedroom: planPackage?.programData?.primaryBedroom?.code,
      },
      maxProgramData: {
        ...planPackage?.maxProgramData,
        basementConfig: planPackage?.programData?.basementConfig?.code,
        garageConfiguration: planPackage?.programData?.garageConfiguration?.code,
        primaryBedroom: planPackage?.programData?.primaryBedroom?.code,
      },
    },
  });

  const isDirty = useComputed(() => formState.dirty, [formState.dirty]);
  useEffect(() => {
    setStepDirty(isDirty);
  }, [setStepDirty, isDirty]);
  const { useRegisterNavigationCheck } = useNavigationCheck();
  useRegisterNavigationCheck(() => !formState.dirty, [formState]);

  const tid = useTestIds({}, "programDataStep");
  const generalSection = generalLinesForm(formState);
  const bedroomsSection = bedroomLinesForm(formState, metadata);
  const bathroomsSection = bathroomLinesForm(formState);
  const garageSection = garageLinesForm(formState, metadata);

  const [savePlanPackage] = useSavePlanPackageDetailsMutation();

  const onSave = async () => {
    // Save Program Data
    if ((formState.programData.dirty || formState.description.dirty) && planPackage) {
      await savePlanPackage({
        variables: {
          input: {
            id,
            description: formState.description.value,
            programData: formState.programData.value,
          },
        },
      });
      formState.commitChanges();
      triggerNotice({ message: `Your Plan Package Program Data has been updated!` });
    }
  };

  return (
    <>
      <div css={Css.py3.bgGray100.$}>
        <div css={Css.df.jcc.fdc.aic.gap2.$} {...tid.sections}>
          <PlanDetailsCard title="General" headerTooltips lines={generalSection} />
          <PlanDetailsCard title="Bedrooms" icon="bed" lines={bedroomsSection} />
          <PlanDetailsCard title="Bathrooms" icon="bath" lines={bathroomsSection} />
          <PlanDetailsCard title="Dining" icon="chair" lines={diningLinesForm(formState)} />
          <PlanDetailsCard title="Entertaining" icon="image" lines={entertainingLinesForm(formState)} />
          <PlanDetailsCard title="Work from Home" icon="todo" lines={workFromHomeLinesForm(formState)} />
          <PlanDetailsCard title="Garage" icon="car" lines={garageSection} />
          <BasementCard title="Basement" icon="basement" formState={formState} metadata={metadata} />
        </div>
      </div>
      <NextStepButton
        label="Continue"
        disabled={false}
        onClick={onSave}
        onCloseReturnUrl={createPlanPackageUrl(id)}
        exitButton={{
          variant: "secondary",
          label: "Save & Exit",
          onClick: onSave,
        }}
      />
    </>
  );
}

function generalLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [
    line(formState, "Stories", "stories"),
    { label: "Description", base: formState.description, type: "textArea" as const },
  ];
}

function bedroomLinesForm(
  formState: ObjectState<AddProgramDetailsForm>,
  metadata: ProgramDataMetadata,
): PlanDetailsCardLine[] {
  return [
    line(formState, "Bedrooms", "bedrooms"),
    line(formState, "Closets in Primary Suite", "closetsInPrimarySuite"),
    line(formState, "First Floor Bedrooms", "firstFloorBedrooms"),
    line(formState, "Primary Bedroom Location", "primaryBedroom", "programDataPrimaryBedroomConfig", metadata),
  ];
}

function bathroomLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [line(formState, "Full Baths", "fullBaths"), line(formState, "Half Baths", "halfBaths")];
}

function diningLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [line(formState, "Formal", "diningRoom"), line(formState, "Casual", "casualDining")];
}

function entertainingLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [
    line(formState, "Media Rooms", "mediaRooms"),
    line(formState, "Lofts/Game Rooms/Flex Rooms", "loftGameFlexRooms"),
  ];
}

function workFromHomeLinesForm(formState: ObjectState<AddProgramDetailsForm>): PlanDetailsCardLine[] {
  return [line(formState, "Offices", "offices"), line(formState, "Workspaces", "workspaces")];
}

function garageLinesForm(
  formState: ObjectState<AddProgramDetailsForm>,
  metadata: ProgramDataMetadata,
): PlanDetailsCardLine[] {
  return [
    line(formState, "Garage Load", "garageConfiguration", "programDataGarageConfig", metadata),
    line(formState, "Attached", "garageAttached"),
    line(formState, "Detached", "garageDetached"),
    line(formState, "Carport", "garagePort"),
  ];
}

// Only number fields can work here given we're setting up base/min/max
type ProgramDataNumberField = PickByType<SaveProgramDataInput, InputMaybe<number>>;

type ProgramDataEnumField = PickByType<SaveProgramDataInput, InputMaybe<string>>;

function line(
  formState: ObjectState<AddProgramDetailsForm>,
  label: string,
  field: keyof ProgramDataNumberField | keyof ProgramDataEnumField,
  enumName?: OptionsEnumNames,
  metadata?: AddOptionsMetadata,
): PlanDetailsCardLine {
  return {
    label,
    base: formState.programData[field] as any,
    min: formState.minProgramData[field] as any,
    max: formState.maxProgramData[field] as any,
    minMaxComputed: true,
    enumName,
    metadata,
  };
}

type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};
