import { BoundMultiSelectField, Css, useSnackbar } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import {
  IncrementalCollectionOp,
  Maybe,
  Scalars,
  useBulkGlobalPlanTagsModalQuery,
  useBulkGlobalPlanTagsMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { pluralize } from "src/utils";

type BulkGlobalPlanTagsModalProps = {
  taskIds: string[];
};

export function BulkGlobalPlanTagsModal({ taskIds }: BulkGlobalPlanTagsModalProps) {
  const { data } = useBulkGlobalPlanTagsModalQuery();
  const globalPlanTags = data?.globalPlanTags ?? [];
  const [bulkSaveTags] = useBulkGlobalPlanTagsMutation();

  const { triggerNotice } = useSnackbar();

  const formState = useFormState({
    config: formConfig,
    init: {
      tasks: taskIds.map((id) => ({ id, op: IncrementalCollectionOp.Include })),
      tagIds: [],
    },
  });

  return (
    <ConfirmationModal
      title="Add tasks to Existing Tag"
      label="Add to Tag"
      disabled={!formState.valid}
      onConfirmAction={async () => {
        await bulkSaveTags({
          variables: {
            input:
              formState.tagIds.value?.map((tagId) => ({
                id: tagId,
                tasks: taskIds.map((taskId) => ({ id: taskId, op: IncrementalCollectionOp.Include })),
              })) ?? [],
          },
        });

        triggerNotice({
          message: `${taskIds.length} tasks have been added to ${globalPlanTags
            .filter((tag) => formState.tagIds?.value?.includes(tag.id))
            .map((tag) => tag.name)
            .join(", ")}`,
        });
      }}
      confirmationMessage={
        <div css={Css.df.fdc.gap2.$}>
          <span>
            Add <b>[{taskIds.length}]</b> selected {pluralize(taskIds.length, "task", "tasks")} to an existing tag.
          </span>
          <BoundMultiSelectField
            label="Existing Groups"
            field={formState.tagIds}
            labelStyle="left"
            options={globalPlanTags}
            getOptionLabel={(tag) => tag.name}
            getOptionValue={(tag) => tag.id}
          />
        </div>
      }
    />
  );
}

type SaveBulkGlobalPlanTags = {
  tasks: Array<{ id: Scalars["ID"]; op: IncrementalCollectionOp }>;
  tagIds: Maybe<Array<Scalars["ID"]>>;
};

const formConfig: ObjectConfig<SaveBulkGlobalPlanTags> = {
  tasks: { type: "list", config: { id: { type: "value" }, op: { type: "value" } } },
  tagIds: { type: "value", rules: [required] },
};
