import { DependencyModelConfig } from "@homebound/schedules-v2-gantt";
import {
  GanttPredecessorDependenciesFragment,
  GanttSchedulePhaseFragment,
  GanttScheduleSubPhaseFragment,
  GanttScheduleTaskFragment,
  Maybe,
  ScheduleGanttQuery,
  TaskStatus,
} from "src/generated/graphql-types";
import {
  getStyleClassesForGanttPhase,
  getStyleClassesForGanttSubPhase,
  getStyleClassesForGanttTask,
} from "./ganttStyles";
import { dependencyTypeToBryntumNumber, GanttTask, OriginalTaskDataById } from "./ganttUtils";

// This function iterates through the hierarchy: SchedulePhases -> ScheduleSubPhases -> ScheduleTasks -> TaskDependency
// Producing a flat list of task dependencies and a nested structure where Schedule[Sub]Phases & Tasks are formatted for the expected Gantt input
export function prepareDataForGantt(
  data: Maybe<ScheduleGanttQuery>,
  showPhaseGroupings: boolean,
): {
  taskData: GanttTask[];
  dependencyData: Partial<DependencyModelConfig>[];
  originalTaskDataById: OriginalTaskDataById;
} {
  if (!data) return { taskData: [], dependencyData: [], originalTaskDataById: {} };

  const { scheduleTasks } = data;
  const originalTaskDataById = scheduleTasks.keyBy((t) => t.id);
  const dependencyData = scheduleTasks.flatMap((t) => prepareDependencies(t.id, t.predecessorDependencies));

  // When rendering the "flat" view without phase groupings, we can skip the logic below that composes the hierarchical structure
  if (!showPhaseGroupings) {
    return { taskData: scheduleTasks.map(scheduleTaskToGanttTask), originalTaskDataById, dependencyData };
  }

  type TaskBySchedulePhaseAndSubPhaseId = Record<
    string,
    { directTaskChildren: GanttScheduleTaskFragment[]; tasksBySubPhaseId: Record<string, GanttScheduleTaskFragment[]> }
  >;

  /**
   * Because the UI allows for tasks to be nested directly under the `phase` level,
   * but the backend still maintains the 3 tier hierarchy using a "DEFAULT" subPhase for those tasks,
   * we must merge those tasks without a subPhase into the same level as the subPhases to be displayed on the chart in the correct nesting order.
   * To achieve this, we generate a nested lookup map in the following format for easy access within `hierarchyAsGanttTasks`:
   *
   {
    "sp:100": {
      directTaskChildren: [{ id: "t:100" }],
      tasksBySubPhaseId: {
        "ssp:100": [{ id: "t:101" }],
      },
    },
  }
   */
  const taskBySchedulePhaseAndSubPhaseId = data.scheduleTasks.reduce((acc, task) => {
    const schedulePhaseId = task.schedulePhase!.id;
    const scheduleSubPhaseId = task.scheduleSubPhase?.id;

    // Use the lack of a subPhaseId as an indicator that this task belongs only to a parent phase
    const isConnectedToPhase = !scheduleSubPhaseId;

    if (!acc[schedulePhaseId]) {
      acc[schedulePhaseId] = { directTaskChildren: [], tasksBySubPhaseId: {} };
    }

    if (isConnectedToPhase) {
      // When a task is associated directly to a phase, we simply merge in the task to the `directTaskChildren` list
      acc[schedulePhaseId].directTaskChildren = [...acc[schedulePhaseId].directTaskChildren, task];
    } else {
      // If a task is associated to a subPhase, we must dig deeper into the map and merge the task
      // into the list of tasks for that subPhase (or initialize an empty list on the first pass for that subPhase)
      const existingTasksBySubPhase = acc[schedulePhaseId].tasksBySubPhaseId[scheduleSubPhaseId!] ?? [];
      acc[schedulePhaseId].tasksBySubPhaseId[scheduleSubPhaseId!] = [...existingTasksBySubPhase, task];
    }

    return acc;
  }, {} as TaskBySchedulePhaseAndSubPhaseId);

  const hierarchyAsGanttTasks = data.schedulePhases.map((sp) => {
    // Iterate through each subPhase, translating them to GanttTasks, then place scheduleTasks under a `children` key
    // Tasks can also nest directly under a phase so merge those "direct children" in
    // Undefined fallback is here in the event a phase has no task children to display
    const { directTaskChildren, tasksBySubPhaseId } = taskBySchedulePhaseAndSubPhaseId[sp.id] ?? {
      directTaskChildren: [],
      tasksBySubPhaseId: {},
    };
    const directTaskChildrenAsGanttTasks = directTaskChildren.map((t) => scheduleTaskToGanttTask(t));

    const childrenSubPhases = sp.scheduleSubPhases.map((ssp) => {
      const childrenTasksForSubPhase = (tasksBySubPhaseId[ssp.id] ?? []).map((t) => scheduleTaskToGanttTask(t));
      return { ...scheduleSubPhaseToGanttTask(ssp), children: childrenTasksForSubPhase };
    });

    return { ...schedulePhaseToGanttTask(sp), children: [...childrenSubPhases, ...directTaskChildrenAsGanttTasks] };
  });

  return { taskData: hierarchyAsGanttTasks, originalTaskDataById, dependencyData };
}

function scheduleTaskToGanttTask(scheduleTask: GanttScheduleTaskFragment): GanttTask {
  const { id, interval, name, status, baselineInterval2 } = scheduleTask;

  return {
    id,
    name,
    duration: interval.durationInDays,
    startDate: interval.startDate,
    endDate: interval.endDate,
    manuallyScheduled: true, // do not use Bryntum's scheduler, instead rely on our own date calculations
    type: "Task",
    percentDone: status === TaskStatus.Complete ? 100 : 0,
    cls: getStyleClassesForGanttTask(scheduleTask),
    baselines: [
      {
        startDate: baselineInterval2?.startDate,
        endDate: baselineInterval2?.endDate,
        durationInDays: baselineInterval2?.durationInDays,
      },
    ],
  };
}

function scheduleSubPhaseToGanttTask(scheduleSubPhase: GanttScheduleSubPhaseFragment): GanttTask {
  const {
    id,
    interval,
    globalSubPhase: { name },
    progress: {
      taskProgress: { completed, total },
    },
  } = scheduleSubPhase;

  const percentDone = completed / total;

  return {
    id,
    duration: interval?.durationInDays,
    name,
    startDate: interval?.startDate,
    endDate: interval?.endDate.date,
    manuallyScheduled: true,
    draggable: false,
    type: "ScheduleSubPhase",
    percentDone,
    expanded: true,
    cls: getStyleClassesForGanttSubPhase(scheduleSubPhase),
  };
}

function schedulePhaseToGanttTask(schedulePhase: GanttSchedulePhaseFragment): GanttTask {
  const {
    id,
    interval,
    globalPhase: { name },
    progress: {
      scheduleSubPhaseProgress: { completed, total },
    },
  } = schedulePhase;

  const percentDone = completed / total;

  return {
    id,
    name,
    duration: interval?.durationInDays,
    startDate: interval?.startDate,
    endDate: interval?.endDate,
    manuallyScheduled: true,
    draggable: false,
    type: "SchedulePhase",
    percentDone,
    expanded: true,
    cls: getStyleClassesForGanttPhase(schedulePhase),
  };
}

/**
 *  Maps our dependency format to Bryntum's
 *  https://bryntum.com/docs/gantt/api/Scheduler/model/DependencyBaseModel#property-Type-static
 */
function prepareDependencies(taskId: string, predecessorDependencies: GanttPredecessorDependenciesFragment[]) {
  return predecessorDependencies.map<Partial<DependencyModelConfig>>((dep) => {
    const {
      predecessor: { id: predecessorId },
      type: predecessorType,
      lagInDays,
    } = dep;
    return {
      fromTask: predecessorId,
      toTask: taskId,
      type: dependencyTypeToBryntumNumber[predecessorType],
      lag: lagInDays,
    };
  });
}
