import {
  Banner,
  Css,
  IconButton,
  ScrollableContent,
  ScrollableParent,
  TabContent,
  TabWithContent,
  Tabs,
  useRightPane,
  useTestIds,
} from "@homebound/beam";
import {
  useChangeLogRightPaneContentDetailsQuery,
  ToDoStatus,
  useCurrentInternalUserQuery,
  ChangeRequestStatus,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { ChangeRequestStatusPill } from "./ChangeRequestStatus";
import { DetailsTab } from "./components/DetailsTab";
import { useCallback, useEffect, useState } from "react";
import { ChangeLogView, ChangeLogViewFilterConfig } from "./ChangeLog";
import { useHistory } from "react-router";
import { useTabParam } from "src/hooks/useTabParam";
import { HistoryTab } from "./components/HistoryTab";
import { CommentFeed } from "src/components/comments/CommentFeed";
import { ChangeRequestToDosTab } from "./components/ChangeRequestToDosTab";
import { ChangeRequestApprovalsTab } from "./components/ChangeRequestApprovalsTab";
import { useToDoModal } from "../to-dos/ToDoModal";

enum ChangeLogRightPaneTabs {
  Details = "Details",
  Todos = "Todos",
  Approvals = "Approvals",
  Comments = "Comments",
  History = "History",
}

type ChangeLogRightPaneContentProps = {
  id: string;
  view: ChangeLogView;
  projectId?: string;
  developmentId?: string;
};

export function ChangeLogRightPageContent(props: ChangeLogRightPaneContentProps) {
  const { id, view, projectId, developmentId } = props;
  const { push } = useHistory();

  const query = useChangeLogRightPaneContentDetailsQuery({ variables: { id } });

  const { data: currentInternalUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });

  const [selectedTab, setSelectedTab] = useTabParam(ChangeLogRightPaneTabs.Details);

  const { closeRightPane } = useRightPane();

  const handleClose = useCallback(() => {
    closeRightPane();
    push(`${ChangeLogViewFilterConfig[view].baseUrl({ projectId, developmentId })}${window.location.search}`);
  }, [closeRightPane, developmentId, projectId, push, view]);

  const tid = useTestIds(props, "changeRequest");

  const [showToDoBanner, setShowToDoBanner] = useState(false);
  const [showApprovalsBlockedBanner, setShowApprovalsBlockedBanner] = useState(false);
  // we need to get all non-complete toDos assigned to the current logged in user
  const currentToDo = query.data?.changeRequest.toDos.find((todo) => {
    return (
      todo.assignees.some(
        (assignee) => assignee.internalUser?.id === currentInternalUserData?.currentInternalUser?.id,
      ) && todo.status.code !== ToDoStatus.Complete
    );
  });

  const changeRequest = query.data?.changeRequest;

  useEffect(() => {
    if (currentToDo) {
      setShowToDoBanner(true);
    }
  }, [currentToDo]);

  useEffect(() => {
    setShowApprovalsBlockedBanner(
      changeRequest?.status.code === ChangeRequestStatus.Blocked && !changeRequest.isUnderContract,
    );
  }, [changeRequest]);

  const { open } = useToDoModal({ refreshQueries: query.refetch });

  return queryResult(query, (result) => {
    const { changeRequest, enumDetails, developments, projectsPage, changeRequestAssets } = result;

    const tabs: TabWithContent<ChangeLogRightPaneTabs>[] = [
      {
        name: "Details",
        value: ChangeLogRightPaneTabs.Details,
        render: () => (
          <DetailsTab
            changeRequest={changeRequest}
            selectValues={{
              enums: enumDetails,
              developments,
              projects: projectsPage.entities,
              assets: changeRequestAssets.entities,
            }}
          />
        ),
      },
      {
        name: "To-Dos",
        value: ChangeLogRightPaneTabs.Todos,
        render: () => <ChangeRequestToDosTab toDos={query.data?.changeRequest.toDos ?? []} refetch={query.refetch} />,
      },
      {
        name: "Approvals",
        value: ChangeLogRightPaneTabs.Approvals,
        render: () => <ChangeRequestApprovalsTab changeRequest={changeRequest} />,
      },
      {
        name: "Comments",
        value: ChangeLogRightPaneTabs.Comments,
        render: () => <CommentFeed commentable={changeRequest} showFollowers={true} />,
      },
      {
        name: "History",
        value: ChangeLogRightPaneTabs.History,
        render: () => <HistoryTab changeRequestId={id} />,
      },
    ];

    return (
      <ScrollableParent
        xss={Css.df.fdc.maxh("calc(100% - 24px)").p3.br8.bgWhite.bshBasic.mr6.mb3.$}
        data-testid="changeLogRightPaneContent"
      >
        <div css={Css.df.fdc.pb2.maxw100.$}>
          <div css={Css.df.jcsb.w100.$}>
            <div css={Css.df.fdc.$}>
              <ChangeRequestStatusPill status={changeRequest.status} />
              <h1 css={Css.lgSb.mt1.$} {...tid.header}>
                {changeRequest.name}
              </h1>
            </div>
            <IconButton icon="x" onClick={handleClose} {...tid.closePane} />
          </div>
        </div>
        {showToDoBanner && currentToDo && (
          <div css={Css.mb3.$}>
            <Banner
              type="alert"
              message={
                <>
                  Don’t forget to mark{" "}
                  <span css={Css.blue700.cursorPointer.$} onClick={() => open(currentToDo.id)}>
                    the To-Do
                  </span>{" "}
                  as ‘Complete’ once you’re done!
                </>
              }
              onClose={() => setShowToDoBanner(false)}
            />
          </div>
        )}
        {showApprovalsBlockedBanner && (
          <div css={Css.mb3.$}>
            <Banner
              type="warning"
              message="This change request is blocked because the request is awaiting client confirmation."
              onClose={() => setShowApprovalsBlockedBanner(false)}
            />
          </div>
        )}
        <div css={Css.wsnw.$}>
          <Tabs tabs={tabs} selected={selectedTab} onChange={(v) => setSelectedTab(v)} />
        </div>
        <ScrollableContent>
          <TabContent tabs={tabs} selected={selectedTab} contentXss={Css.pt2.$} />
        </ScrollableContent>
      </ScrollableParent>
    );
  });
}
