import { Chip, Css } from "@homebound/beam";
import { ChangeRequestDrawer_ChangeRequestGroupFragment, InputMaybe } from "src/generated/graphql-types";
import { CreateChangeRequestForm } from "../CreateChangeRequestDrawer";
import { ObjectState } from "@homebound/form-state";

type ReviewGroupedChangeRequestsPageProps = {
  titles: InputMaybe<string>[];
  changeRequestGroups: ChangeRequestDrawer_ChangeRequestGroupFragment[];
  formState: ObjectState<CreateChangeRequestForm>;
};

type GroupCount = {
  name: string;
  count: number;
};

export function ReviewGroupedChangeRequestsPage({
  titles,
  changeRequestGroups,
  formState,
}: ReviewGroupedChangeRequestsPageProps) {
  const mappedGroupNames = mapChangeRequestGroupsToName(changeRequestGroups, formState);
  return (
    <>
      <div css={Css.df.fdc.gap2.$}>
        {mappedGroupNames.map((group) => (
          <div key={group.name} css={Css.df.gap1.aic.$}>
            <Chip text={group.name} />
            <span css={Css.wPx(16).hPx(16).fs0.br100.bgBlue700.white.tinySb.df.aic.jcc.$} data-testid="groupCountChip">
              {group.count}
            </span>
          </div>
        ))}
      </div>
      {titles.map((title) => (
        <div key={title} css={Css.wPx(388).bgGray100.br4.p2.mt2.xsMd.$} data-testid="reviewChangeRequestTitle">
          {title}
        </div>
      ))}
    </>
  );
}

// helper function to get the group name and count for review page
export function mapChangeRequestGroupsToName(
  changeRequestGroups: ChangeRequestDrawer_ChangeRequestGroupFragment[],
  formState: ObjectState<CreateChangeRequestForm>,
): GroupCount[] {
  // Create a map to count group occurrences
  const groupCountMap: Record<string, number> = {};

  // Helper function to add groups to the count map
  const addGroupsToCountMap = (groups: InputMaybe<string[]> | undefined) => {
    groups?.forEach((groupId) => {
      const group = changeRequestGroups.find((g) => g.id === groupId);
      if (group) {
        groupCountMap[group.name] = (groupCountMap[group.name] || 0) + 1;
      }
    });
  };

  // Count groups from the main form state
  addGroupsToCountMap(formState.value.groups);

  // Count groups from additional change requests
  formState.additionalChangeRequests.rows.forEach((changeRequest) => {
    addGroupsToCountMap(changeRequest.value?.groups);
    // Include the new group from additional change requests
    if (changeRequest.value?.newGroup) {
      groupCountMap[changeRequest.value?.newGroup] = (groupCountMap[changeRequest.value?.newGroup] || 0) + 1;
    }
  });

  // Include the new group from the main form state
  if (formState.value.newGroup) {
    groupCountMap[formState.value.newGroup] = (groupCountMap[formState.value.newGroup] || 0) + 1;
  }

  // Convert the count map to an array of objects
  return Object.entries(groupCountMap).map(([name, count]) => ({
    name,
    count,
  }));
}
