import { Avatar, Button, Css, Icon, Tag, useTestIds } from "@homebound/beam";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import {
  ActionItemAssigneeFragment,
  ActionItemProjectFragment,
  TaskParentPillFragment,
} from "src/generated/graphql-types";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { foldEnum } from "src/utils";
import { ActionItemTypes } from "../enums";
import { TaskParentTag } from "./TaskParentTag";

type ItemCardProps = {
  actionType: ActionItemTypes;
  onClick: () => void;
  title: string | ReactNode | undefined;
  description?: ReactNode;
  duration?: string;
  project: ActionItemProjectFragment | null | undefined;
  assignee?: ActionItemAssigneeFragment | null | undefined;
  disabledBtn?: boolean;
  parent?: TaskParentPillFragment;
  overrideCTA?: string;
};

export function ItemCard(props: ItemCardProps) {
  const { actionType, onClick, title, description, duration, project, assignee, disabledBtn, parent, overrideCTA } =
    props;
  const tids = useTestIds({}, actionType);
  const [itemIcon, buttonLabel] = actionItemMapper(
    actionType,
    <Avatar src={assignee?.avatar} name={assignee?.name} size="lg" {...tids.avatar} />, // Asignee avatar is the fallback icon, if no icon is provided for the actionType
  );

  return (
    <div css={Css.df.gap2.py2.bb.bw1.bcGray200.$} {...tids}>
      <div>{itemIcon}</div>
      <div>
        <span css={Css.smSb.mr1.$}>{title}</span>
        <div {...tids.description}>{description}</div>
        <div css={Css.mt1.df.gap1.xs.gray500.aic.$}>
          {parent?.id && <TaskParentTag taskParent={parent} actionType={actionType} />}
          {project && actionType === ActionItemTypes.DEFERRED_BILLS && (
            <Link to={createProjectScheduleUrl(project.id)}>
              <Tag text={project.buildAddress.street1} />
            </Link>
          )}
          <span {...tids.duration}>{duration}</span>
        </div>
      </div>
      <div css={Css.mla.df.aic.$}>
        <Button disabled={disabledBtn} label={overrideCTA ? overrideCTA : buttonLabel} onClick={onClick} />
      </div>
    </div>
  );
}

/**
 * @param actionType ActionItemTypes
 * @param icon Action type icon (fallBack icon is assigne avatar if no icon designated)
 * @returns [icon: ReactNode, buttonLabel: string] Array of the icon & button label, per each actionType.
 */
function actionItemMapper(actionItemType: ActionItemTypes, icon: ReactNode): [icon: ReactNode, buttonLabel: string] {
  return foldEnum(actionItemType, {
    [ActionItemTypes.SCHEDULING]: [icon, "View Task"],
    [ActionItemTypes.DEFERRED_BILLS]: [
      <div key={ActionItemTypes.DEFERRED_BILLS} css={Css.bgGray900.wPx(46).hPx(46).mwPx(46).br100.df.jcc.aic.white.$}>
        <Icon icon="bill" />
      </div>,
      "View Bill",
    ],
    [ActionItemTypes.BILLS_PENDING_REVIEW]: [
      <div
        key={ActionItemTypes.BILLS_PENDING_REVIEW}
        css={Css.bgOrange700.wPx(46).hPx(46).mwPx(46).br100.df.jcc.aic.white.$}
      >
        <Icon icon="bill" />
      </div>,
      "View Bill",
    ],
    [ActionItemTypes.APPROVALS]: [icon, "View Request"],
    else: [icon, "View Task"],
  });
}
