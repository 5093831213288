import { Css, Palette } from "@homebound/beam";
import { camelCase } from "change-case";
import { Icon, IconProps } from "src/components";

export function TaskColorLegend({ title, items }: TaskColorLegendProps) {
  const legendItems = items || colorItems;
  return (
    <div css={Css.hPx(20).df.aic.gap2.ml3.$}>
      <div css={Css.xsMd.gray900.$} data-testid="legendTitle">
        {title}
      </div>
      {legendItems.map((item) => {
        const key = camelCase(item.label);
        return (
          <div css={Css.df.aic.xs.gray700.gap1.$} data-testid={key} key={key}>
            <Icon icon={item.icon ?? "status"} color={item.color} inc={item.sizeOverride ?? 1.5} />
            {item.label}
          </div>
        );
      })}
    </div>
  );
}

export type TaskColorLegendItem = {
  color: Palette;
  label: string;
  icon?: IconProps<any>["icon"];
  sizeOverride?: number;
};

type TaskColorLegendProps = {
  title: string;
  items?: TaskColorLegendItem[];
};

const colorItems: TaskColorLegendItem[] = [
  {
    color: Palette.Gray500,
    label: "Task Not Started",
  },
  {
    color: Palette.Blue600,
    label: "In Progress",
  },
  {
    color: Palette.Green500,
    label: "Completed",
  },
  {
    color: Palette.Yellow600,
    label: "Upcoming Delay",
  },
  {
    color: Palette.Red600,
    label: "Delayed",
  },
  {
    color: Palette.Purple500,
    label: "Milestone",
  },
];
