import { useMemo } from "react";
import { Css, useSnackbar } from "@homebound/beam";
import { useParams } from "react-router";
import { createListViewScheduleUrl } from "src/RouteUrls";
import {
  TradePartnerTaskStatusDetail,
  SavePlanTaskInput,
  TaskDetailsPageWithConstraints_PlanTaskFragment,
  TaskDetailsPage_PlanTaskFragment,
  TaskStatus,
  TaskStatusDetail,
  usePlanTaskDetailsPageQuery,
  useSavePlanTaskMutation,
  DynamicSchedulesTaskSnapshot_TaskSnapshotFragment,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { projectsPath } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { SchedulingCard } from "src/routes/projects/dynamic-schedules/task-details/SchedulingCard";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { TaskStatusSelect } from "../../schedule-v2/components/TaskStatusSelect";
import { ReferenceCard } from "./ReferenceCard";
import { CommentsCard } from "./CommentsCard";
import { HistoryCard } from "./HistoryCard";
import { Observer } from "mobx-react";
import { CalendarCard } from "./CalendarCard";
import { useScheduleSnapshots } from "../components/useScheduleSnapshots";

export function TaskDetailsPage() {
  const { planTaskId } = useParams<{ planTaskId: string; projectId: string }>();
  const query = usePlanTaskDetailsPageQuery({ variables: { taskId: planTaskId } });
  return queryResult(query, ({ planTask, enumDetails }) => {
    return <TaskDetailPageView enumDetails={enumDetails} planTask={planTask} />;
  });
}

type TaskDetailsPageViewProps = {
  planTask: TaskDetailsPageWithConstraints_PlanTaskFragment;
  enumDetails: {
    taskStatus: TaskStatusDetail[];
    tradePartnerTaskStatus: TradePartnerTaskStatusDetail[];
  };
};

function TaskDetailPageView(props: TaskDetailsPageViewProps) {
  const { planTask, enumDetails } = props;
  const [savePlanTask] = useSavePlanTaskMutation();
  const { triggerNotice } = useSnackbar();

  const { getTaskSnapshot } = useScheduleSnapshots(planTask.schedule.parent.id);
  const taskSnapshot = useMemo(() => getTaskSnapshot(planTask.id), [planTask.id, getTaskSnapshot]);

  const formState = useFormState<SavePlanTaskInput, TaskDetailsPage_PlanTaskFragment>({
    config,
    init: {
      input: planTask,
      map: (task) => ({
        id: task.id,
        status: task.status.code,
        documents: task.documents.map((doc) => doc.id),
        tradePartnerId: task.committedTradePartners.first?.id ?? task.tradePartner?.id,
        tradePartnerStatus: task.tradePartnerStatus.code,
      }),
    },
  });
  const onStatusSelect = async (status?: TaskStatus) => {
    formState.status.set(status);
    await savePlanTask({ variables: { input: { id: formState.id.value, status: formState.status.value } } });
    triggerNotice({
      message: `Task status updated to ${enumDetails.taskStatus.find((ts) => ts.code === status)?.name}`,
    });
  };
  return (
    <Observer>
      {() => (
        <div data-testid="taskDetailsPage">
          <PageHeader
            title={planTask.name}
            right={
              <TaskStatusSelect
                dataTestId="taskStatusSelect"
                label="Status"
                hideLabel={true}
                options={enumDetails.taskStatus}
                statusField={formState.status}
                canComplete={planTask.canComplete}
                canStart={planTask.canStart}
                disabled={false}
                onSelect={onStatusSelect}
              />
            }
            breadcrumb={[
              { label: "Projects", href: projectsPath },
              { label: planTask.schedule.parent.name, href: createListViewScheduleUrl(planTask.schedule.parent.id) },
            ]}
          />
          <PlanTaskDetailsPageContent
            task={planTask}
            formState={formState}
            enumDetails={enumDetails}
            taskSnapshot={taskSnapshot}
          />
        </div>
      )}
    </Observer>
  );
}

export const config: ObjectConfig<SavePlanTaskInput> = {
  id: { type: "value" },
  status: { type: "value" },
  documents: { type: "value" },
  tradePartnerStatus: { type: "value" },
  tradePartnerId: { type: "value" },
};

type PlanTaskDetailsPageContentProps = {
  task: TaskDetailsPageWithConstraints_PlanTaskFragment;
  formState: ObjectState<SavePlanTaskInput>;
  enumDetails: TaskDetailsPageViewProps["enumDetails"];
  taskSnapshot: DynamicSchedulesTaskSnapshot_TaskSnapshotFragment | undefined;
};

function PlanTaskDetailsPageContent(props: PlanTaskDetailsPageContentProps) {
  const { task, formState, enumDetails, taskSnapshot } = props;
  return (
    <div css={Css.maxwPx(916).mwPx(706).ma.mt3.dg.gtc("1fr 1fr").gap2.$}>
      <CalendarCard task={task} taskSnapshot={taskSnapshot} />
      <SchedulingCard task={task} formState={formState} tradePartnerTaskStatus={enumDetails.tradePartnerTaskStatus} />
      <CommentsCard task={task} />
      <ReferenceCard task={task} formState={formState} />
      <HistoryCard task={task} />
    </div>
  );
}
