import omit from "lodash/omit";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { initPageSettings, PageSettings, toLimitAndOffset } from "src/components/Pagination";
import {
  MaterialCatalog_MaterialVariantFragment,
  useMaterialCatalogQuery,
  MaterialVariantsFilter,
} from "src/generated/graphql-types";
import useQueryString from "src/hooks/useQueryString";

type MaterialCatalogState = {
  variants: MaterialCatalog_MaterialVariantFragment[];
  settings: MaterialCatalogQueryParams;
  setSettings: Dispatch<SetStateAction<MaterialCatalogQueryParams>>;
  setSearch: Dispatch<SetStateAction<string>>;
  refetch: () => void;
  loading: boolean;
  hasNextPage: boolean;
};

export type MaterialCatalogQueryParams = MaterialVariantsFilter & PageSettings;

export function useMaterialCatalog(): MaterialCatalogState {
  const [search, setSearch] = useState<string>("");
  const [settings, setSettings] = useQueryString<MaterialCatalogQueryParams>({
    page: initPageSettings.page,
    perPage: initPageSettings.perPage,
  });
  const { page, perPage, ...filter } = settings;
  const { data, loading, refetch } = useMaterialCatalogQuery({
    variables: {
      filter: { ...omit(filter, "search"), search },
      page: toLimitAndOffset(page, perPage),
    },
  });

  const variants = useMemo(() => data?.materialVariants.entities ?? [], [data]);
  const hasNextPage = useMemo(() => data?.materialVariants.pageInfo.hasNextPage ?? false, [data]);

  return {
    variants,
    settings,
    setSettings,
    setSearch,
    refetch,
    loading,
    hasNextPage,
  };
}
