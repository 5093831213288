import {
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridTable,
  RowStyles,
  simpleHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { chipCell } from "src/components";
import { MaterialCatalog_MaterialVariantFragment } from "src/generated/graphql-types";
import { createMaterialCatalogUrl, createMaterialDetailsUrl } from "src/RouteUrls";
import { useHistory, useParams } from "react-router";
import { MaterialParams } from "src/routes/routesDef";
import { MaterialSuperDrawer } from "./material-super-drawer/MaterialSuperDrawer";

export type MaterialCatalogListViewsProps = {
  variants: MaterialCatalog_MaterialVariantFragment[];
};

export function MaterialCatalogListView({ variants }: MaterialCatalogListViewsProps) {
  const { openInDrawer } = useSuperDrawer();
  const { push } = useHistory();
  const rows = useMemo(() => createRows(variants), [variants]);
  const columns = useMemo(() => createColumns(), []);
  const rowLookup = useRef<GridRowLookup<Row>>();
  const { variantId } = useParams<MaterialParams>();
  const rowStyles = useMemo(() => createRowStyles(push), [push]);

  const openRow = useCallback(
    (variantId: string) => {
      if (!rowLookup.current) return;

      const list = rowLookup.current.currentList();
      const rows = list.filter((l) => l.kind === "variant");
      const variantRow = rows.find((row) => row.id === variantId);
      if (!variantRow) return;

      /**
       * Update URL with the prev/next listing id. We do not need to
       * explicity call openRow since the useEffect below watches for URL
       * changes and triggers a SuperDrawer update.
       */
      const { prev, next } = rowLookup.current!.lookup(variantRow)["variant"];
      openInDrawer({
        content: <MaterialSuperDrawer variantId={variantRow.id} />,
        onClose: () => {
          // When closing the SuperDrawer, remove /:variantId and retain query parameters
          push(createMaterialCatalogUrl());
        },
        onPrevClick: prev && (() => push(createMaterialDetailsUrl(prev.id))),
        onNextClick: next && (() => push(createMaterialDetailsUrl(next.id))),
      });
    },
    [openInDrawer, push],
  );

  // Attempt to open the SuperDrawer based on the queryParam variantId - i.e. libraries/material-catalog/ml:1
  useEffect(() => {
    if (variantId) {
      openRow(variantId);
    }
  }, [variantId, openRow]);

  return (
    <div css={Css.maxw100.add("flexWrap", "wrap").oa.$}>
      <GridTable
        columns={columns}
        rows={rows}
        rowStyles={rowStyles}
        rowLookup={rowLookup}
        stickyHeader
        sorting={{ on: "client" }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header"; id: string };
type VariantRow = { kind: "variant"; data: MaterialCatalog_MaterialVariantFragment };
type Row = HeaderRow | VariantRow;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Item Code / SKU",
      variant: ({ code }) => chipCell([code]),
    }),
    column<Row>({
      header: "Name",
      variant: ({ displayName }) => displayName,
    }),
    column<Row>({
      header: "Brand",
      variant: ({ listing: { brand } }) => chipCell([brand?.name ?? ""]),
    }),
    column<Row>({
      header: "Model Number",
      variant: ({ modelNumber }) => modelNumber,
    }),
  ];
}

function createRows(variants: MaterialCatalog_MaterialVariantFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...variants.map((v) => ({
      id: v.id,
      kind: "variant" as const,
      data: v,
    })),
  ];
}

function createRowStyles(push: (url: string) => void): RowStyles<Row> {
  function openMaterialDrawer(row: GridDataRow<Row>): void {
    if (row.kind === "variant") push(createMaterialDetailsUrl(row.id));
  }
  return {
    variant: { onClick: openMaterialDrawer },
  };
}
