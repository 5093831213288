import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { Button } from "@homebound/beam";
import { PotentialOperation2, useCreateNewTakeoffVersionsMutation } from "src/generated/graphql-types";
import { useCallback } from "react";
import { createPlanPackageTakeoffUrl } from "src/RouteUrls";
import { useHistory } from "react-router-dom";

type EditTakeoffsButtonProps = {
  itemTemplateId: string;
  canCreateNewVersion: PotentialOperation2;
  planPackageId: string;
};

export function EditTakeoffsButton({ itemTemplateId, canCreateNewVersion, planPackageId }: EditTakeoffsButtonProps) {
  const [createNewVersion] = useCreateNewTakeoffVersionsMutation();
  const { push } = useHistory();

  const onCreateNewVersion = useCallback(async () => {
    const response = await createNewVersion({ variables: { templateIds: [itemTemplateId] } });
    const newTemplate = response.data?.createNewItemTemplateVersions.newTemplates.first;
    if (newTemplate) {
      push(createPlanPackageTakeoffUrl(planPackageId, newTemplate.id));
    }
  }, [createNewVersion, itemTemplateId, push, planPackageId]);

  return (
    <Button
      variant="secondary"
      icon="pencil"
      label="Edit Takeoffs"
      onClick={onCreateNewVersion}
      disabled={disableBasedOnPotentialOperation(canCreateNewVersion)}
    />
  );
}
