import { ObjectConfig, ObjectState, required } from "@homebound/form-state";
import {
  AddOptionsMetadataQuery,
  AddReadyPlanOptionFragment,
  GlobalOption,
  InputMaybe,
  Location,
  Maybe,
  ProgramDataForLotSequenceFragment,
  ReadyPlanOptionDetailFragment,
  ReadyPlanOptionGroupFragment,
  SavePlanPackageInput,
  SaveProductTypeInput,
  SaveReadyPlanAssetInput,
  SaveReadyPlanInput,
  SaveReadyPlanOptionGroupInput,
  SaveReadyPlanOptionInput,
  SaveReadyPlanOptionProgramDataModificationInput,
  Scalars,
  usePlanPackage_SaveReadyPlanOptionGroupsMutation,
  usePlanPackage_SaveReadyPlanOptionsMutation,
  useSaveReadyPlanOptionProgramDataModificationMutation,
} from "src/generated/graphql-types";
import { DefaultedReadyPlanOption } from "../stepper/2.ElevationsStep";
import { HasIdAndName } from "src/utils";
import { ProgramDataKey } from "src/routes/developments/lot-summary/sequence-sheet/components/utils";

export const productTypeConfig: ObjectConfig<SaveProductTypeInput> = {
  id: { type: "value" },
  name: { type: "value" },
};

export type FileData = { previewUrl?: string | null; fileName?: string | null | undefined };

type LocalFilesForm = {
  assets: (SaveReadyPlanAssetInput & { asset: FileData })[];
};

export type PlanPackageDetailsForm = Omit<SavePlanPackageInput, "code"> &
  LocalFilesForm & {
    code: Maybe<Scalars["Int"]>;
  };

export const planPackageDetailsConfig: ObjectConfig<PlanPackageDetailsForm> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  description: { type: "value" },
  code: { type: "value", rules: [required] },
  type: { type: "value", rules: [required] },
  lotTypes: { type: "value" },
  assets: {
    type: "list",
    config: {
      id: { type: "value" },
      asset: { type: "value" },
      parentId: { type: "value" },
      type: { type: "value" },
    },
  },
};

export const saveReadyPlanOptionConfig: ObjectConfig<DefaultedReadyPlanOption> = {
  id: { type: "value" },
  readyPlanId: { type: "value", rules: [required] },
  globalOptionId: { type: "value", rules: [required] },
  active: { type: "value", rules: [required] },
  displayName: { type: "value" },
  order: { type: "value" },
  assets: {
    type: "list",
    config: {
      id: { type: "value" },
      asset: { type: "value" },
      parentId: { type: "value" },
      type: { type: "value" },
    },
  },
};

export const planPackageElevationsConfig: ObjectConfig<{
  readyPlanOptions: DefaultedReadyPlanOption[];
}> = {
  readyPlanOptions: { type: "list", config: saveReadyPlanOptionConfig },
};

//  //////////////////////////////////////////////////////////////////////////

type ChildOptions = Maybe<SaveReadyPlanOptionInput> & { globalOptionName?: string };

export type ProgramDataModifications = Maybe<
  Omit<SaveReadyPlanOptionProgramDataModificationInput, "modifiedOptionId" | "matchesOptionId">
> & {
  globalOptionCode?: string;
  modifiedOption: ReadyPlanOptionDetailFragment["programDataModifiedBy"][0]["modifiedOption"];
  matchesOption?: ReadyPlanOptionDetailFragment["programDataModifiedBy"][0]["matchesOption"];
  // Add derived fields that are not in the inputs, so the UI can render them
  programData?: Omit<ProgramDataForLotSequenceFragment, ProgramDataKey | "__typename" | "updatedAt">;
};

// Extending the `SaveReadyPlanOptionInput` to help with rendering the UI
export type AddReadyPlanOption = Omit<SaveReadyPlanOptionInput, "childOptions"> & {
  fragment?: AddReadyPlanOptionFragment;
  optionTypeId: Maybe<string>;
  globalTypeName?: Maybe<string>;
  globalOptionCode?: Maybe<string>;
  globalOptionDescription?: Maybe<string>;
  isElevation?: Maybe<boolean>;
  globalOptionLocation?: Maybe<Pick<Location, "id" | "name">>;
  childOptions?: ChildOptions[];
  programDataModifiedBy?: ProgramDataModifications[];
  // Add derived fields that are not in the inputs, so the UI can render them
  programData?: Omit<ProgramDataForLotSequenceFragment, ProgramDataKey | "__typename" | "updatedAt">;
  optionGroup?: ReadyPlanOptionGroupFragment;
};

export type AddReadyPlanOptionsForm = {
  readyPlanOptions: AddReadyPlanOption[];
};

export const addRpoConfig: ObjectConfig<AddReadyPlanOptionsForm> = {
  readyPlanOptions: {
    type: "list",
    config: {
      fragment: { type: "value" },
      id: { type: "value" },
      name: { type: "value" },
      active: { type: "value", rules: [required] },
      globalOptionId: { type: "value", rules: [required] },
      readyPlanId: { type: "value", rules: [required] },
      locationId: { type: "value" },
      optionTypeId: { type: "value" },
      globalTypeName: { type: "value" },
      globalOptionCode: { type: "value" },
      globalOptionDescription: { type: "value" },
      globalOptionLocation: { type: "value" },
      isElevation: { type: "value" },
      programData: {
        type: "object",
        config: completePDConfig(),
      },
      programDataModifiedBy: {
        type: "list",
        config: {
          id: { type: "value" },
          globalOptionCode: { type: "value" },
          modifiedOption: { type: "value" },
          matchesOption: { type: "value" },
          programData: {
            type: "object",
            config: completePDConfig(),
          },
        },
      },
      childOptions: {
        type: "list",
        config: {
          id: { type: "value" },
          active: { type: "value", rules: [required] },
          globalOptionId: { type: "value", rules: [required] },
          globalOptionName: { type: "value" },
        },
      },
      optionGroup: { type: "value" },
    },
  },
};

// Extending the `SaveReadyPlanOptionInput` to help with rendering the UI
export type ConfigureReadyPlanOption = Omit<SaveReadyPlanOptionInput, "childOptions"> & {
  optionTypeId: Maybe<string>;
  globalTypeName?: Maybe<string>;
  globalOptionCode?: Maybe<string>;
  location?: Maybe<Pick<Location, "id" | "name">>;
  optionGroup?: Omit<ReadyPlanOptionGroupFragment, "required">;
  optionPrerequisiteIds: InputMaybe<string[]>;
  optionConflictIds: InputMaybe<string[]>;
  optionConflictChildIds: InputMaybe<string[]>;
  order: InputMaybe<number>;
};

export type ConfigureReadyPlanOptionsForm = {
  readyPlanOptions: ConfigureReadyPlanOption[];
};

export const configureRpoConfig: ObjectConfig<ConfigureReadyPlanOptionsForm> = {
  readyPlanOptions: {
    type: "list",
    config: {
      id: { type: "value" },
      name: { type: "value" },
      active: { type: "value", rules: [required] },
      readyPlanId: { type: "value", rules: [required] },
      optionTypeId: { type: "value" },
      globalTypeName: { type: "value" },
      globalOptionCode: { type: "value" },
      location: { type: "value" },
      optionGroup: {
        type: "object",
        config: {
          id: { type: "value" },
          name: { type: "value" },
          isMultiOptionGroup: { type: "value" },
          isSingleOptionGroup: { type: "value" },
          order: { type: "value" },
        },
      },
      optionPrerequisiteIds: { type: "value" },
      optionConflictIds: { type: "value" },
      optionConflictChildIds: { type: "value" },
      optionPrereqChildIds: { type: "value" },
      order: { type: "value" },
    },
  },
};

export type AddProgramDetailsForm = {
  id?: string;
  description: Maybe<string>;
  programData: Maybe<SaveReadyPlanInput["programData"]>;
  minProgramData: Maybe<SaveReadyPlanInput["minProgramData"]>;
  maxProgramData: Maybe<SaveReadyPlanInput["maxProgramData"]>;
};

export const readyPlanProgramDataConfig: ObjectConfig<AddProgramDetailsForm> = {
  id: { type: "value" },
  description: { type: "value" },
  programData: {
    type: "object",
    config: readyPlanProgramDataConfigConfig(),
  },
  minProgramData: {
    type: "object",
    config: readyPlanProgramDataConfigConfig(),
  },
  maxProgramData: {
    type: "object",
    config: readyPlanProgramDataConfigConfig(),
  },
};

export function getExistingAndActiveOptions(
  globalSchemes: Pick<GlobalOption, "id" | "code" | "name">[],
  currentOptions: { globalOption: Pick<GlobalOption, "id" | "name"> }[],
): HasIdAndName[] {
  // Modify activeGlobalSchemes to include the code of the global option in the name
  const globalSchemesWithCode = globalSchemes.map(({ id, code, name }) => ({ id, name: `${code} - ${name}` }));
  return currentOptions
    .map((rpo) => rpo.globalOption as HasIdAndName)
    .concat(globalSchemesWithCode)
    .uniqueByKey("id")
    .sortByKey("name");
}

export function completePDConfig() {
  return {
    ...readyPlanProgramDataConfigConfig(),
    sellableSqft: { type: "value" },
    permittableSqft: { type: "value" },
    grossBuildableSqft: { type: "value" },
    netBuildableSqft: { type: "value" },
    sellableAboveGroundSqft: { type: "value" },
    sellableBelowGroundSqft: { type: "value" },
    grossBelowGroundSqft: { type: "value" },
    unfinishedBelowGroundSqft: { type: "value" },
    imperviousSqft: { type: "value" },
  };
}

export type AddOptionsMetadata = Pick<AddOptionsMetadataQuery, "enumDetails"> | undefined;
export type OptionsEnumNames = Exclude<keyof AddOptionsMetadataQuery["enumDetails"], "__typename">;

function readyPlanProgramDataConfigConfig() {
  return {
    stories: { type: "value" },
    widthInFeet: { type: "value" },
    minLotSizeInSqft: { type: "value" },
    minLotDepthInFeet: { type: "value" },
    minLotWidthInFeet: { type: "value" },
    buildingHeightInFeet: { type: "value" },
    depthInFeet: { type: "value" },
    bedrooms: { type: "value" },
    closetsInPrimarySuite: { type: "value" },
    fullBaths: { type: "value" },
    halfBaths: { type: "value" },
    garageAttached: { type: "value" },
    garageDetached: { type: "value" },
    garagePort: { type: "value" },
    garageConfiguration: { type: "value" },
    basementConfig: { type: "value" },
    firstFloorBedrooms: { type: "value" },
    diningRoom: { type: "value" },
    casualDining: { type: "value" },
    mediaRooms: { type: "value" },
    loftGameFlexRooms: { type: "value" },
    offices: { type: "value" },
    workspaces: { type: "value" },
    primaryBedroom: { type: "value" },
  };
}

export async function toSaveReadyPlanOptionInput(
  formState: ObjectState<AddReadyPlanOptionsForm>,
  readyPlan: { id: string },
  saveMutation: ReturnType<typeof usePlanPackage_SaveReadyPlanOptionsMutation>[0],
) {
  const readyPlanOptions: SaveReadyPlanOptionInput[] = formState.readyPlanOptions.rows
    .filter((rpo) => {
      // Filter only options that have changed and need to be saved
      const { optionTypeId, id, ...parentSaveValues } = rpo.changedValue;
      return Object.keys(parentSaveValues).length > 0;
    })
    .map((rpo) => {
      const {
        optionTypeId,
        id,
        globalTypeName,
        globalOptionCode,
        globalOptionDescription,
        globalOptionLocation,
        isElevation,
        programDataModifiedBy,
        ...parentSaveValues
      } = rpo.changedValue;

      // If `id` is set, then we are modifying an existing RPO, so define `id` as part of the save input.
      // Otherwise this is a newly defined `option` and all required values (except readyPlanId) will be in `parentSaveValues`.
      const saveInput: SaveReadyPlanOptionInput = {
        ...(id ? { id } : { readyPlanId: readyPlan.id }),
        ...parentSaveValues,
      };
      // Check for `childOptions` to save
      if (rpo.childOptions.rows.length) {
        saveInput.childOptions = rpo.childOptions.rows.map(({ changedValue: { globalOptionName, ...saveValues } }) => ({
          ...saveValues,
        }));
      }
      return saveInput;
    })
    .filter(({ id, ...rest }) => Object.keys(rest).length);

  if (readyPlanOptions.length) {
    await saveMutation({ variables: { input: { readyPlanOptions } } });
  }
}

export async function toSaveReadyPlanOptionProgramDataModification(
  formState: ObjectState<AddReadyPlanOptionsForm>,
  saveReadyPlanOptionProgramDataModification: ReturnType<
    typeof useSaveReadyPlanOptionProgramDataModificationMutation
  >[0],
) {
  const changedModifications: SaveReadyPlanOptionProgramDataModificationInput[] = formState.readyPlanOptions.rows
    .flatMap((rpo) =>
      rpo.programDataModifiedBy.rows.map((rpopdm) => {
        const { value, changedValue: changes } = rpopdm;
        // case when we are creating a new modification for an rpo, no id yet
        if (value.id?.startsWith("temp_")) {
          return {
            id: undefined,
            modifiedOptionId: value.modifiedOption.id,
            matchesOptionId: value.matchesOption?.id,
          };
        }

        return {
          id: value.id,
          ...(changes.modifiedOption?.id && { modifiedOptionId: changes.modifiedOption?.id }),
          ...(changes.matchesOption?.id && { matchesOptionId: changes.matchesOption.id }),
          ...(changes.programData && { programData: changes.programData }),
        };
      }),
    )
    .filter(({ id, ...rest }) => Object.keys(rest).length);

  if (changedModifications.length) {
    const { data } = await saveReadyPlanOptionProgramDataModification({ variables: { input: changedModifications } });
    // now we need to grab the newly created rpopdm and update their id for future updates
    data?.saveReadyPlanOptionProgramDataModifications?.entities.forEach((rpopdm) => {
      formState.readyPlanOptions.rows.forEach((rpo) => {
        if (rpo.id.value === rpopdm.modifiedOption.id) {
          rpo.programDataModifiedBy.rows.forEach((pdm) => {
            if (pdm.value.id?.startsWith("temp_") && pdm.value.matchesOption?.id === rpopdm.matchesOption.id) {
              pdm.id.set(rpopdm.id);
            }
          });
        }
      });
    });
  }
}

export async function toSaveOptionsConfiguration(
  formState: ObjectState<ConfigureReadyPlanOptionsForm>,
  saveReadyPlanOptionsConfiguration: ReturnType<typeof usePlanPackage_SaveReadyPlanOptionsMutation>[0],
  saveReadyPlanOptionGroups: ReturnType<typeof usePlanPackage_SaveReadyPlanOptionGroupsMutation>[0],
) {
  const changes = [];

  // these are dirty when the group order gets changed, not sure why
  const changedOptions: SaveReadyPlanOptionInput[] = formState.readyPlanOptions.rows
    .filter((rpo) => rpo.dirty && Object.getOwnPropertyNames(rpo.changedValue).length > 1)
    .map((rpo) => ({ ...rpo.changedValue, optionGroup: undefined }))
    // since we're ignoring the optionGroup here, rpos may be dirty but technically not changed
    // so let's make sure there is something other than the id and group here
    .filter(({ id, optionGroup, ...rest }) => Object.keys(rest).length);

  if (changedOptions.length > 0) {
    changes.push(
      await saveReadyPlanOptionsConfiguration({ variables: { input: { readyPlanOptions: changedOptions } } }),
    );
  }

  const changedGroups: SaveReadyPlanOptionGroupInput[] = formState.readyPlanOptions.rows
    .filter((rpo) => Object.keys(rpo.optionGroup.changedValue ?? {}).length > 1)
    .flatMap((rpo) => {
      return rpo.optionGroup.changedValue ?? [];
    });

  if (changedGroups.length > 0) {
    changes.push(await saveReadyPlanOptionGroups({ variables: { inputs: changedGroups } }));
  }

  return changes;
}
