import {
  AutoSaveIndicator,
  Button,
  ButtonMenu,
  ButtonModal,
  Css,
  FullBleed,
  SelectField,
  useModal,
} from "@homebound/beam";
import { useHistory } from "react-router-dom";
import { StaticHeaderField } from "src/components";
import { StatusIndicator } from "src/components/StatusIndicator";
import {
  GlobalTasksHeaderDataQuery,
  ScheduleDetailsFragment,
  Stage,
  TaskTradePartnerFragment,
  useGlobalTasksHeaderDataQuery,
} from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { ScheduleExportModal } from "src/routes/pdfs";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { createProjectScheduleUrl, createScheduleTemplateUrl } from "src/RouteUrls";
import { stageCodeToNameMapper } from "src/utils";
import { DateOnly, formatWithShortYear } from "src/utils/dates";
import { EmptyScheduleStates } from "../scheduleUtils";
import { StartOrCompleteStageButton } from "./StartOrCompleteStageButton";

export type CondensedScheduleHeaderDataProps = {
  schedule: ScheduleDetailsFragment;
  projectId: string;
  tradePartners: TaskTradePartnerFragment[];
  projectAddress: string;
};

export function CondensedScheduleHeaderData({
  schedule,
  projectId,
  tradePartners,
  projectAddress,
}: CondensedScheduleHeaderDataProps) {
  const { interval, statusDetail, parent, stageDetail, id: scheduleId } = schedule;
  const query = useGlobalTasksHeaderDataQuery({ variables: { parentId: projectId, scheduleId } });
  const globalMilestoneTasks = query.data?.scheduleTasks ?? [];
  const stagesOnProject = query.data?.schedule.parent.schedules.map((s) => s.stageDetail.code) ?? [];

  const deliveryDate = getScheduleHeaderDates("Vertical Complete", "endDate");
  const closeDate = getScheduleHeaderDates("Delivery Complete", "endDate");
  const startDate = getScheduleHeaderDates(`Customer/Lot Acquisition`, "endDate");
  const estConstructionStart = getScheduleHeaderDates("Mobilization Start", "startDate");
  type IntervalDates = "startDate" | "endDate";
  // helper to find dates that are specific globalTaskMilestones dates. Ticket #SC-20577 on the BE will provide endpoints since matching on strings is not ideal
  function getScheduleHeaderDates(globalTaskMilestoneName: string, date: IntervalDates): DateOnly | undefined {
    return globalMilestoneTasks.find((task) => task.name.includes(globalTaskMilestoneName))?.interval[
      date as IntervalDates
    ];
  }

  const { openModal } = useModal();
  const history = useHistory();

  const {
    scheduleState: { emptyScheduleState },
  } = useScheduleStore();

  const showNullState = emptyScheduleState === EmptyScheduleStates.ApplyTemplateNullState;

  useDocumentTitle(`Schedule | ${projectAddress}`);

  function populatedScheduleFields() {
    const isConstructionStage = stageDetail.code === Stage.Construction;
    if (showNullState) return null;

    return (
      <>
        {isConstructionStage ? (
          <>
            <StaticHeaderField
              value={
                <div css={Css.mtPx(4).$}>
                  {interval ? formatWithShortYear(interval.startDate) : "--"}-
                  {deliveryDate ? formatWithShortYear(deliveryDate) : " --"}
                </div>
              }
              label="Start to Delivery"
            />
            <StaticHeaderField
              label="Close"
              value={<div css={Css.mtPx(4).$}>{closeDate ? formatWithShortYear(closeDate) : "--"}</div>}
            />
          </>
        ) : (
          <>
            <StaticHeaderField
              label={"Start"}
              value={<div css={Css.mtPx(4).$}>{startDate ? formatWithShortYear(startDate) : "--"}</div>}
            />
            <StaticHeaderField
              label={"Est Construction Start"}
              value={
                <div css={Css.mtPx(4).$}>{estConstructionStart ? formatWithShortYear(estConstructionStart) : "--"}</div>
              }
            />
          </>
        )}
        <ButtonModal
          trigger={{ icon: "triangleDown" }}
          variant="textSecondary"
          hideEndAdornment
          content={<ScheduleDetailsPopover schedule={query.data?.schedule} />}
          tooltip="Schedule Details"
        />
      </>
    );
  }

  function exportAndStartStageButtons() {
    // rendering this div to make sure flex alignment stays in place when switching between states
    return (
      <div css={Css.df.gap1.aic.$}>
        {!showNullState && (
          <>
            <StartOrCompleteStageButton schedule={schedule} refetchQueries={["ProjectSchedulePageV2"]} />
            <ButtonMenu
              trigger={{ icon: "verticalDots" }}
              items={[
                {
                  label: "Export",
                  onClick: () =>
                    openModal({
                      content: <ScheduleExportModal schedule={schedule} tradePartners={tradePartners} />,
                    }),
                  icon: "linkExternal",
                },
              ]}
              tooltip="Schedule Actions"
              data-testid="scheduleActions"
            />
          </>
        )}
      </div>
    );
  }

  return (
    <FullBleed>
      <div css={Css.pt4.pb2.df.jcsb.bgWhite.px3.ifLg.gap2.ifMdAndDown.fdc.pt3.$}>
        <div css={Css.ifMdAndDown.df.jcsb.$}>
          <div css={Css.df.fdc.$}>
            <div css={Css.sm.gray700.wsnw.$} data-testid="projectAddress">
              {projectAddress}
            </div>
            <div css={Css.df.$}>
              <div css={Css.xlSb.gray900.mrPx(14).$}>Schedule</div>
              <AutoSaveIndicator />
            </div>
          </div>
          {/* Tablet and Down positioning of export and stage buttons */}
          <div css={Css.ifLg.dn.$}>{exportAndStartStageButtons()}</div>
        </div>
        <div css={Css.df.gap3.if(showNullState).add("flexGrow", 0.5).ifMdAndDown.mt2.gap6.$}>
          <StaticHeaderField
            label="Status"
            value={
              <div css={Css.df.aic.mtPx(6).$}>
                <StatusIndicator status={statusDetail.code} />
                <div css={Css.ml1.smMd.$}>{statusDetail.name}</div>
              </div>
            }
          />
          <StaticHeaderField
            label="Stage"
            value={
              <div css={Css.mb1.$}>
                <SelectField
                  labelStyle="hidden"
                  borderless
                  compact
                  sizeToContent
                  label="Stage"
                  options={stagesOnProject.map((s) => ({
                    id: s,
                    name: stageCodeToNameMapper[s],
                  }))}
                  value={stageDetail.code}
                  onSelect={(val) => history.push(createProjectScheduleUrl(parent.id, val))}
                />
              </div>
            }
          />
          {populatedScheduleFields()}
        </div>
        {/* Desktop positioning of export and stage buttons */}
        <div css={Css.ifMdAndDown.dn.$}>{exportAndStartStageButtons()}</div>
      </div>
    </FullBleed>
  );
}

function ScheduleDetailsPopover({ schedule }: { schedule?: GlobalTasksHeaderDataQuery["schedule"] }) {
  // We expect `Schedule.templateSchedule.parent` to always be a `ScheduleTemplate`, but because of the polymorphic `parent` reference,
  // we need this extra check. We also expect the `Schedule.templateSchedule` to be undefined if a template has not been applied.
  const maybeScheduleTemplate = schedule?.templateSchedule?.parent;
  const isScheduleTemplate = maybeScheduleTemplate?.__typename === "ScheduleTemplate";

  const maybeTemplateLink = isScheduleTemplate ? (
    <Button
      onClick={createScheduleTemplateUrl(maybeScheduleTemplate.id)}
      label={maybeScheduleTemplate.name}
      variant="text"
    />
  ) : (
    "No template applied"
  );

  return (
    <div>
      <StaticHeaderField label="Schedule Template" value={maybeTemplateLink} />
    </div>
  );
}
