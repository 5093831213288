import { useApolloClient } from "@apollo/client";
import { Banner, BoundTextAreaField, ButtonGroup, Css, IconButton, useTestIds } from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { ReactNode, useEffect } from "react";
import {
  DocumentDetailDocumentTypeFragment,
  ProjectScheduleDocumentDetailFragment,
  ProjectStageProjectItemDetailFragment,
  SaveScheduleTaskInput,
  ScheduleSettingDetailsFragment,
  ScheduleStatus,
  TaskDetailPaneFragment,
  TaskFilterOptionsFragment,
  TaskStatus,
  TaskStatusesFragment,
  TaskTradePartnerFragment,
  TradePartnerTaskStatus,
  useTaskDetailPaneDropdownOptionsQuery,
  useTaskDetailPaneQuery,
} from "src/generated/graphql-types";
import { TaskStatusSelect } from "src/routes/projects/schedule-v2/components/TaskStatusSelect";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { nonEmpty, queryResult, sortBy } from "src/utils";
import { ScheduleViewType } from "../scheduleUtils";
import {
  dependencyConfig,
  determineTaskDateEditabilityById,
  getDateValueToUpdate,
  mapPredecessorsToFormState,
  mapSuccessorsToFormState,
  mapToInput,
  TaskDetailsFormInput,
} from "../table/utils";
import { TaskDetailPaneTabs } from "./TaskDetailPaneTabs";
import { useLocation } from "react-router-dom";
import { personalDashboardPaths } from "src/routes/routesDef";
import { useTaskBillContext } from "../contexts/TaskBillModalContext";

export type TaskDetailPaneProps = {
  scheduleParentId: string;
  projectItems: ProjectStageProjectItemDetailFragment[];
  taskId: string;
  onClose: VoidFunction;
  onSave: (input: SaveScheduleTaskInput) => void;
  onSwitch?: (taskId: string, direction: "prev" | "next") => void;
  onJump: (id: string) => void;
  tasks: TaskFilterOptionsFragment[];
  scheduleIsLocked: boolean;
  scheduleSetting?: ScheduleSettingDetailsFragment | null;
  scheduleStatus?: ScheduleStatus;
  isPersonalDashboardBasePage?: boolean;
  headerActions?: ReactNode;
};

export function TaskDetailPane(props: TaskDetailPaneProps) {
  const {
    taskId,
    onClose,
    onSave,
    onSwitch,
    onJump,
    tasks,
    projectItems,
    scheduleIsLocked,
    scheduleSetting,
    scheduleParentId,
    scheduleStatus,
    headerActions,
  } = props;
  const {
    scheduleState: { scheduleType },
  } = useScheduleStore();
  const isTemplate = scheduleType === ScheduleType.Template;
  const parentsWithTradePartners = isTemplate ? [] : [scheduleParentId];
  const query = useTaskDetailPaneQuery({
    variables: { taskId, parentsWithTradePartners, parentId: scheduleParentId, isTemplate },
  });

  // Split the somewhat-static dropdown options into their own query to avoid refetching this data on every task change
  const dropdownOptionsQuery = useTaskDetailPaneDropdownOptionsQuery();
  const { taskStatuses, documentTypes } = dropdownOptionsQuery.data ?? {
    taskStatuses: [],
    documentTypes: [],
  };

  return queryResult(query, {
    data: ({ scheduleTask, tradePartners, documents }) => {
      return (
        <TaskDetailPaneView
          scheduleParentId={scheduleParentId}
          task={scheduleTask}
          tasks={tasks}
          taskStatuses={taskStatuses}
          projectItems={projectItems}
          documents={documents || []}
          documentTypes={documentTypes}
          tradePartners={tradePartners}
          scheduleSetting={scheduleSetting}
          scheduleIsLocked={scheduleIsLocked}
          isTemplate={isTemplate}
          onJump={onJump}
          onSwitch={onSwitch}
          onClose={onClose}
          onSave={onSave}
          scheduleStatus={scheduleStatus}
          headerActions={headerActions}
        />
      );
    },
  });
}

type TaskDetailPaneViewProps = Omit<TaskDetailPaneProps, "taskId"> & {
  task: TaskDetailPaneFragment;
  tradePartners: TaskTradePartnerFragment[];
  isTemplate: boolean;
  taskStatuses: TaskStatusesFragment[];
  documents: ProjectScheduleDocumentDetailFragment[];
  documentTypes: DocumentDetailDocumentTypeFragment[];
};

function TaskDetailPaneView(props: TaskDetailPaneViewProps) {
  const {
    task,
    isTemplate,
    taskStatuses,
    tradePartners,
    documents,
    documentTypes,
    onClose,
    onSave,
    onSwitch,
    onJump,
    tasks,
    projectItems,
    scheduleIsLocked,
    scheduleSetting,
    scheduleParentId,
    scheduleStatus,
    headerActions,
  } = props;
  const {
    scheduleState: {
      scheduleViewType,
      taskPaneState: { tab },
    },
  } = useScheduleStore();
  const { openTaskBillModal } = useTaskBillContext();
  const location = useLocation();
  // Tasks statuses may not be updated when the schedule is in draft
  const readOnlyInDraft = scheduleStatus === ScheduleStatus.Draft;
  const isPersonalDashboardPage = location.pathname.includes(personalDashboardPaths.base);

  const client = useApolloClient();
  const formState = useFormState({
    config: taskDetailFormConfig,
    init: {
      input: task,
      map: mapToFormState,
      ifUndefined: {
        projectItems: [],
        documents: [],
        predecessorDependencies: [],
        successorDependencies: [],
      },
    },
    autoSave: async (t) => {
      if (formState.canSave()) {
        const canEdit = determineTaskDateEditabilityById(t.id.value!, client);
        const { input } = getDateValueToUpdate(t, t.changedValue, canEdit, scheduleSetting);
        const requiredTaskDocumentDocuments = task.requiredTaskDocuments.map(({ document }) => document?.id).compact();
        if (t.changedValue.status === TaskStatus.Complete && isPersonalDashboardPage) {
          // we need to manually open the c2p modal for instances of the taskDetailPane on the personal dashboard page
          // since we don't have access to the schedule mutation manager which normally provides us with the openTaskBillModal function
          openTaskBillModal({
            scheduleTaskId: task.id,
            // `onComplete` is called if the modal is skipped or if the task has no unbilled items
            // otherwise its called after the modal is submitted
            onComplete: async () => await onSave(mapToInput(input, requiredTaskDocumentDocuments)),
          });
        } else {
          await onSave(mapToInput(input, requiredTaskDocumentDocuments));
        }
        t.commitChanges();
      }
    },
  });

  useEffect(
    () => {
      if (task) {
        formState.tradePartnerId.set(task.tradePartner?.id);
        formState.internalUserId.set(task.assignee?.id);
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [task],
  );

  useEffect(() => {
    function onArrow(event: KeyboardEvent, direction: "prev" | "next") {
      event.preventDefault();
      event.stopPropagation();
      onSwitch && onSwitch(task.id, direction);
    }

    function onKeyPress(event: KeyboardEvent) {
      const { key } = event;
      switch (key) {
        case "ArrowUp":
          onArrow(event, "prev");
          break;
        case "ArrowDown":
          onArrow(event, "next");
          break;
      }
    }

    window.addEventListener("keydown", onKeyPress);
    return () => {
      window.removeEventListener("keydown", onKeyPress);
    };
  }, [onSwitch, task]);

  const tid = useTestIds({}, `taskDetailPaneView-${tab}`);

  const sortedDocumentTypes = sortBy(documentTypes, ({ name }) => name);

  return (
    <div css={Css.df.fdc.gap2.py3.bgWhite.h100.oa.px4.$} {...tid}>
      <div css={Css.df.jcsb.$}>
        <div css={Css.wPx(150).addIn("div:nth-of-type(2)", Css.bn.$).$}>
          {tab === "details" && !isTemplate && taskStatuses && nonEmpty(taskStatuses) && (
            <TaskStatusSelect
              hideLabel
              options={taskStatuses}
              statusField={formState.status}
              canComplete={task.canComplete}
              canStart={task.canStart}
              disabled={scheduleIsLocked || readOnlyInDraft}
            />
          )}
        </div>
        <div css={Css.df.jcsb.aic.gap1.$}>
          <div css={Css.df.fdr.jcsb.$}>
            {onSwitch && scheduleViewType === ScheduleViewType.List && (
              <ButtonGroup
                size="xs"
                buttons={[
                  { icon: "chevronUp", onClick: () => onSwitch(task.id, "prev") },
                  { icon: "chevronDown", onClick: () => onSwitch(task.id, "next") },
                ]}
              />
            )}
          </div>
          {headerActions && <div>{headerActions}</div>}
          <IconButton data-testid="closePane" icon="x" onClick={onClose} />
        </div>
      </div>
      <div css={Css.df.jcsb.$}>
        <BoundTextAreaField
          xss={Css.lgSb.$}
          field={formState.name}
          labelStyle="hidden"
          preventNewLines
          borderless
          disabled={scheduleIsLocked || task.isGlobalMilestoneTask}
        />
      </div>
      {isPersonalDashboardPage && task.tradePartnerStatus.code === TradePartnerTaskStatus.Unavailable && (
        <>
          <Banner
            message="Move the task date to the date the trade is available, or a new date that you prefer."
            type="alert"
          />
        </>
      )}
      <TaskDetailPaneTabs
        scheduleParentId={scheduleParentId}
        task={task}
        formState={formState}
        projectItems={projectItems}
        documents={documents}
        documentTypes={sortedDocumentTypes}
        tasks={tasks}
        taskId={task.id}
        onSave={onSave}
        onJump={(id) => onJump && onJump(id)}
        onClose={onClose}
        scheduleIsLocked={scheduleIsLocked}
        isTemplate={isTemplate}
        tradePartners={tradePartners}
        scheduleSetting={scheduleSetting}
        scheduleStatus={scheduleStatus}
      />
    </div>
  );
}

export type FormValue = ObjectState<Partial<TaskDetailsFormInput>>;
export const taskDetailFormConfig: ObjectConfig<Partial<TaskDetailsFormInput>> = {
  id: { type: "value" },
  name: { type: "value" },
  startDate: { type: "value" },
  endDate: { type: "value" },
  schedulePhase: { type: "value" },
  scheduleSubPhase: { type: "value" },
  durationInDays: { type: "value" },
  internalUserId: { type: "value" },
  tradePartnerId: { type: "value" },
  homeownerVisible: { type: "value" },
  homeownerDescription: { type: "value" },
  projectItems: { type: "value" },
  documents: { type: "value" },
  status: { type: "value" },
  successorDependencies: {
    type: "list",
    config: dependencyConfig,
  },
  predecessorDependencies: {
    type: "list",
    config: dependencyConfig,
  },
  templateProjectRole: { type: "value" },
  tradePartnerStatus: { type: "value" },
};

function mapToFormState(task: TaskDetailPaneFragment): Partial<TaskDetailsFormInput> {
  if (!task) return {};

  const {
    id,
    interval,
    assignee,
    tradePartner,
    homeownerVisible,
    homeownerDescription,
    projectItems,
    documents,
    templateProjectRole,
    status,
  } = task;

  return {
    id,
    status,
    name: task.name,
    startDate: interval.startDate,
    durationInDays: interval.durationInDays,
    endDate: interval.endDate,
    internalUserId: assignee?.id,
    templateProjectRole,
    tradePartnerId: tradePartner?.id,
    homeownerVisible,
    homeownerDescription: homeownerDescription || task.templateTask?.homeownerDescription,
    projectItems: projectItems.map(({ id }) => id),
    documents: documents.map(({ id }) => id),
    predecessorDependencies: mapPredecessorsToFormState(task),
    successorDependencies: mapSuccessorsToFormState(task),
    tradePartnerStatus: task.tradePartnerStatus.code,
  };
}
