import { Accordion, Css, useTestIds } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { OverviewTab_ProjectItemFragment, OverviewTab_ProductFragment } from "src/generated/graphql-types";
import {
  ProductDesignPackageDetailsSection,
  ProductManufacturerInformationSection,
  ProductOverviewSection,
} from "src/routes/libraries/product-catalog/components/product-details-panel/components";
import {
  ProductDefaultImage,
  ProductImageViewer,
} from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { OverviewTabFormValue } from "src/routes/projects/selections/OverviewTab";

export type ProductDetailsPanelProps = {
  projectItem: OverviewTab_ProjectItemFragment;
  product: OverviewTab_ProductFragment | undefined;
  formState: ObjectState<OverviewTabFormValue>;
};

export function OverviewTabProductDetailsPanel(props: ProductDetailsPanelProps) {
  const { product, projectItem } = props;
  const { isSelection } = projectItem;
  return (
    <div>
      {isSelection && <ProductOverview product={product} projectItem={projectItem} />}
      <div css={Css.my2.$}>
        <div>
          {product && (
            <>
              <Accordion title="Product Overview">
                <ProductOverviewSection product={product} />
              </Accordion>
              <Accordion title="Design Package Details">
                <ProductDesignPackageDetailsSection product={product} />
              </Accordion>
              <Accordion title="Manufacturer Information">
                <ProductManufacturerInformationSection product={product} />
              </Accordion>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function ProductOverview({ product, projectItem }: Omit<ProductDetailsPanelProps, "formState">) {
  const testIds = useTestIds({}, "productOverviewTab");
  return (
    <>
      <header css={Css.xl2Bd.py1.$} {...testIds.header}>
        {projectItem.displayName}
      </header>
      <div css={Css.mb3.$} {...testIds.description}>
        {product?.name ?? "No product selected."}
      </div>
      <div css={Css.f2.$}>{product ? <ProductImageViewer product={product} /> : <ProductDefaultImage />}</div>
    </>
  );
}
