import { BeamButtonProps, Css, IconButton, IconProps, Palette, Tooltip, useHover } from "@homebound/beam";
import React, { PropsWithChildren } from "react";

type ActionProps = {
  icon: IconProps["icon"];
  tooltip?: string;
  action: BeamButtonProps["onClick"];
};
export type WithActionsProps = {
  left?: ActionProps;
  right?: ActionProps;
  onClick: () => void;
  footer?: {
    text: string;
  };
};

export function WithActions({ children, left, right, footer, onClick }: PropsWithChildren<WithActionsProps>) {
  const { isHovered, hoverProps } = useHover({});
  const transparentGray = "rgba(36,36,36,0.5)";
  return (
    <div data-testid={"withActions"} {...hoverProps} css={Css.df.relative.mr1.cursorPointer.$}>
      <div css={Css.z1.$}>{children}</div>
      {isHovered && (
        <div css={Css.absolute.z2.w100.h100.bgColor(transparentGray).$} onClick={onClick}>
          {left && (
            <div css={Css.absolute.topPx(2).leftPx(5).$}>
              <Tooltip title={left.tooltip} disabled={!left.tooltip}>
                <IconButton onClick={left.action} icon={left.icon} color={Palette.White} />
              </Tooltip>
            </div>
          )}
          {right && (
            <div css={Css.absolute.topPx(2).rightPx(5).$}>
              <Tooltip title={right.tooltip} disabled={!right.tooltip} placement="bottom">
                <IconButton onClick={right.action} icon={right.icon} color={Palette.White} />
              </Tooltip>
            </div>
          )}
        </div>
      )}
      {footer && (
        <div css={Css.absolute.truncate.px1.hPx(25).bottom0.w100.bgColor(transparentGray).white.z3.$}>
          {footer.text}
        </div>
      )}
    </div>
  );
}
