import { useParams } from "react-router-dom";
import { DevelopmentParams } from "src/routes/routesDef";
import { useDevelopmentBidPackagesPageMetadataQuery } from "src/generated/graphql-types";
import { BidPackagesPageView } from "src/routes/bids/BidPackagesPage";
import { queryResult } from "src/utils";

export function BidPackagesPage() {
  const { developmentId } = useParams<DevelopmentParams>();
  const query = useDevelopmentBidPackagesPageMetadataQuery();
  return queryResult(query, {
    data: ({ costCodes }) => <BidPackagesPageView costCodes={costCodes} marketsOrDevelopment={developmentId} />,
  });
}
