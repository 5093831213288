import { Chip, Css, IconButton, Palette, useTestIds } from "@homebound/beam";
import { Price } from "src/components";
import { FormGap } from "src/components/FormGap";
import { BillPdf_BillFragment, useBillPdfQuery, useBillPdfTradePartnerContactQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { queryResult } from "src/utils";
import { StringParam, useQueryParam } from "use-query-params";
import { IconListStyle, IconsList } from "../components/IconsList";
import { PdfFooter } from "../components/PdfFooter";
import { BillPdfTable } from "./BillPdfTable";
import { getBillParentsLabel } from "src/components/detailPane/bill/utils";

export function BillPdf() {
  const [billId] = useQueryParam("billId", StringParam);
  const query = useBillPdfQuery({ variables: { billId: billId! } });
  return queryResult(query, ({ bill }) => <BillPdfView bill={bill} />);
}

type BillPdfViewProps = {
  bill: BillPdf_BillFragment;
};

function BillPdfView({ bill }: BillPdfViewProps) {
  const tid = useTestIds({});
  const query = useBillPdfTradePartnerContactQuery({
    variables: { projectId: bill.project.id, tradePartnerId: bill.tradePartner.id },
  });

  return queryResult(query, ({ tradePartner }) => {
    const [billApprover] = tradePartner.contactsForProjectAndRoles;
    const scheduleTasks = bill.lineItems
      .flatMap((li) => li.scheduleTask)
      .compact()
      .uniqueByKey("id");

    return (
      <div css={Css.mx4.p6.mt3.bgWhite.fg1.h100.$}>
        <PageHeader
          left={
            <div css={Css.df.fdr.gap2.aic.$} {...tid.tradePartnerNumber}>
              <div css={Css.xl2Sb.$}>Invoice #{bill.tradePartnerNumber}</div>
            </div>
          }
          right={
            <>
              {bill.document?.asset.downloadUrl && (
                <IconButton
                  color={Palette.Blue500}
                  inc={3}
                  icon="download"
                  onClick={bill.document?.asset.downloadUrl}
                />
              )}
            </>
          }
          xss={Css.bn.$}
        />

        <div css={Css.df.fdr.jcsb.$}>
          <IconsList
            list={[
              {
                icon: "commitment",
                value: getBillParentsLabel(bill.parents),
              },
              { icon: "house", value: bill.project.buildAddress.street1 },
              { icon: "floorPlan", value: bill.project.readyPlanConfig?.readyPlan?.displayName },
              {
                icon: "calendar",
                date: { label: "Issue date:", value: bill.sentDate },
              },
            ]}
            listStyle={IconListStyle.SimpleList}
          />

          <div css={Css.df.fdc.aife.gap1.$}>
            <div css={Css.tinySb.gray600.$}>Total amount due</div>
            <div css={Css.xl3Md.$} {...tid.total}>
              <Price valueInCents={bill.billedInCents} />
            </div>
          </div>
        </div>

        <BillPdfTable lineItems={bill.lineItems} enableProductConfigPlan={bill.project.enableProductConfigPlan} />

        {scheduleTasks.nonEmpty && (
          <div css={Css.dg.gtc("3fr 3fr").py6.$}>
            <FormGap />
            <div>
              <div css={Css.tiny.gray600.$}>Related Schedule Tasks</div>
              {scheduleTasks.map((tsk) => (
                <div css={Css.mt1.$} key={tsk?.id} {...tid.task}>
                  <div css={Css.xs.$}>{tsk?.name}</div>
                </div>
              ))}
            </div>
          </div>
        )}

        <PdfFooter tradeContact={billApprover} />
      </div>
    );
  });
}
