import {
  BoundSelectField,
  BoundTextField,
  Button,
  column,
  Css,
  emptyCell,
  FormLines,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  selectColumn,
  SelectToggle,
  simpleHeader,
  useComputed,
  useGridTableApi,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useState } from "react";
import { FileUploadProgress, FormActions, FormMode, priceCell, SearchBox, UppyUploader } from "src/components";
import { UploaderProvider } from "src/contexts/UploaderContext";
import {
  BulkAddProjectItem_ProjectItemFragment,
  DisplayNamedFragment,
  DocumentType,
  SaveDocumentInput,
  SaveWorkAuthorizationInput,
  useSaveWorkAuthorizationDocumentMutation,
  WorkAuthorizationDetailsFragment,
  WorkAuthorizationDocumentFragment,
  WorkAuthorizationProjectStageFragment,
} from "src/generated/graphql-types";
import { BulkAddProjectItemsModal, CeliGroupByValue } from "src/routes/components/BulkAddProjectItemsModal";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { PageHeaderActions } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { costTypeToNameMapper, empty } from "src/utils";
import { WorkAuthorizationDocumentsTable } from "./WorkAuthorizationDocumentsTable";

export type WorkAuthorizationEditorProps = {
  mode: FormMode;
  projectId: string;
  onCancel: () => void;
  onEdit: () => void;
  onSave: (input: SaveWorkAuthorizationInput | undefined) => Promise<void>;
  onDelete: () => Promise<void>;
  projectStages: WorkAuthorizationProjectStageFragment[];
  workAuthorization: WorkAuthorizationDetailsFragment | undefined;
};

export function WorkAuthorizationEditor({
  mode,
  onEdit,
  onSave,
  onCancel,
  onDelete,
  projectId,
  projectStages,
  workAuthorization,
}: WorkAuthorizationEditorProps) {
  const { openModal } = useModal();
  const [saveDocument] = useSaveWorkAuthorizationDocumentMutation();
  const formState = useFormState({
    config: formConfig,
    init: {
      input: workAuthorization,
      map: (wa) => ({
        ...wa,
        projectStageId: wa.projectStage.id,
      }),
      ifUndefined: {
        ...empty<WorkAuthorizationFormInput>(),
        projectItems: [],
        documents: [],
      },
    },
    readOnly: mode === "read",
  });

  const { hasProjectItems } = useComputed(
    () => ({
      hasProjectItems: formState.projectItems.value.length > 0,
    }),
    [formState],
  );

  return (
    <>
      <PageHeaderActions>
        <FormActions
          {...{ mode, onCancel, onDelete, onEdit, formState }}
          onSave={async () => {
            await onSave(mapToInput(formState.value));
            formState.commitChanges();
          }}
        />
      </PageHeaderActions>
      <FormLines width="full">
        <div css={Css.w25.$}>
          <BoundTextField label="Name" field={formState.name} />
        </div>
        <div css={Css.w25.$}>
          <BoundSelectField
            label={"Project Stage"}
            field={formState.projectStageId}
            options={projectStages}
            onSelect={(newValue) => {
              const isDifferent = formState.projectStageId.value !== newValue;
              if (isDifferent && hasProjectItems) {
                return openModal({
                  content: (
                    <ConfirmationModal
                      confirmationMessage="Cost Items will be removed if proceed with the stage change"
                      onConfirmAction={() => {
                        formState.projectStageId.set(newValue);
                        formState.projectItems.set([]);
                      }}
                      title="Change project stage?"
                      label="Change project stage"
                      danger
                    />
                  ),
                });
              }

              formState.projectStageId.set(newValue);
            }}
            getOptionLabel={({ stage }) => stage.name}
            getOptionValue={({ id }) => id}
          />
        </div>
        {mode !== "read" && (
          <UploaderProvider>
            <div css={Css.df.fdc.mb1.$}>
              <label css={Css.gray700.$}>Work Authorization Document</label>
              <label css={Css.gray700.$}>Please upload a Work Authorization document.</label>
            </div>
            <div css={Css.jcc.$}>
              <UppyUploader
                allowedFileTypes={["application/pdf"]}
                onFinish={async (file) => {
                  // create entries in Asset table and entries in Document table and associate asset to document
                  const saveDocumentsInput: SaveDocumentInput = {
                    documentType: DocumentType.WorkAuthorization,
                    name: file.name,
                    sizeInBytes: file.size,
                    parentId: projectId,
                    asset: {
                      contentType: file.type,
                      fileName: file.name,
                      s3Key: file.meta.s3Key as string,
                      sizeInBytes: file.size,
                    },
                  };

                  const { data } = await saveDocument({ variables: { input: saveDocumentsInput } });
                  const newDocument = data?.saveDocuments.documents.first!;
                  formState.documents.add(newDocument);
                }}
                dragDropText={"Upload Files"}
                dragDropWidth={700}
                dragDropHeight={70}
              />
              <div css={Css.w50.$}>
                <FileUploadProgress hideOnComplete />
              </div>
            </div>
          </UploaderProvider>
        )}
        <WorkAuthorizationDocumentsTable
          mode={mode}
          documents={formState.documents}
          onDelete={(document) => formState.documents.remove(document)}
        />
      </FormLines>
      <WorkAuthorizationItemsTable mode={mode} projectId={projectId} formState={formState} stages={projectStages} />
    </>
  );
}

type WorkAuthorizationItem = { costCode: DisplayNamedFragment };
type WorkAuthorizationLineItem = Pick<
  BulkAddProjectItem_ProjectItemFragment,
  "id" | "internalNote" | "location" | "costType" | "quantity" | "unitCostInCents"
> & { item: WorkAuthorizationItem };

type WorkAuthorizationItemsTableProps = {
  mode: FormMode;
  formState: ObjectState<WorkAuthorizationFormInput>;
  projectId: string;
  stages: WorkAuthorizationProjectStageFragment[];
};

function WorkAuthorizationItemsTable({ mode, formState, stages, projectId }: WorkAuthorizationItemsTableProps) {
  const { openModal } = useModal();
  const [searchFilter, setSearchFilter] = useState<string | undefined>("");

  const tableApi = useGridTableApi<Row>();
  const selectedProjectItemsIds = useComputed(() => tableApi.getSelectedRowIds("item"), [tableApi]);

  const addLineItems = (toAdd: BulkAddProjectItem_ProjectItemFragment[]) => {
    const currentItems = formState.projectItems.value;
    formState.projectItems.set([...currentItems, ...toAdd]);
  };

  const { projectItems, selectedStage } = useComputed(
    () => ({
      projectItems: formState.projectItems.value,
      selectedStage: stages.find(({ id: projectStageId }) => formState.projectStageId.value === projectStageId),
    }),
    [formState, stages],
  );

  return (
    <div css={Css.w75.$}>
      <div css={Css.lgSb.mt4.$}>Cost Items</div>
      <TableActions>
        <div css={Css.w100.h("40px").df.jcsb.aic.bgGray100.$}>
          <div css={Css.df.w("260px").jcsa.aic.$}>
            {selectedProjectItemsIds.length > 0 && (
              <>
                <div>
                  {selectedProjectItemsIds.length} Item{selectedProjectItemsIds.length > 1 ? "s" : ""} Selected
                </div>
                <Button
                  label="Remove Lines"
                  variant="secondary"
                  onClick={() => {
                    // Remove all selected project items
                    formState.projectItems.set([
                      ...projectItems.filter((pi) => !selectedProjectItemsIds.includes(pi.id)),
                    ]);
                  }}
                />
              </>
            )}
          </div>
          <div css={Css.mr1.if(mode !== "read").df.$}>
            <SearchBox onSearch={setSearchFilter} />
            {mode !== "read" && (
              <div css={Css.ml1.$}>
                <Button
                  label="Add Line Item"
                  disabled={selectedStage === undefined}
                  onClick={() =>
                    openModal({
                      content: (
                        <BulkAddProjectItemsModal
                          projectId={projectId}
                          submitButtonText="Add Items"
                          stage={selectedStage!.stage.code}
                          onAdd={(_, newProjectItems: BulkAddProjectItem_ProjectItemFragment[]) =>
                            addLineItems(newProjectItems)
                          }
                          hiddenProjectItemIds={projectItems.map(({ id }) => id)}
                          groupBy={CeliGroupByValue.CostCode}
                        />
                      ),
                      size: "xl",
                    })
                  }
                />
              </div>
            )}
          </div>
        </div>
      </TableActions>
      <GridTable
        columns={createColumns(mode)}
        rows={createRows(projectItems)}
        style={{ rowHeight: "fixed" }}
        fallbackMessage="There are no project items selected"
        stickyHeader
        filter={searchFilter}
        sorting={{ on: "client" }}
        api={tableApi}
        id="projectItemsTable"
      />
    </div>
  );
}

type ItemRow = {
  kind: "item";
  data: WorkAuthorizationLineItem;
};
type HeaderRow = { kind: "header" };
type Row = HeaderRow | ItemRow;

function createRows(projectItems: WorkAuthorizationLineItem[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...projectItems.map((pi) => ({
      kind: "item" as const,
      id: pi.id,
      data: pi,
    })),
  ];
}

function createColumns(mode: FormMode): GridColumn<Row>[] {
  const isReadMode = mode === "read";
  return [
    selectColumn<Row>({
      header: (_, { row }) => (isReadMode ? emptyCell : <SelectToggle id={row.id} />),
      item: (_, { row }) => (isReadMode ? emptyCell : <SelectToggle id={row.id} />),
    }),
    column<Row>({
      header: "Line Item",
      item: ({ item }) => item.costCode.displayName,
    }),
    column<Row>({
      header: "Internal Note",
      item: ({ internalNote }) => internalNote,
    }),
    column<Row>({
      header: "Location",
      item: ({ location }) => location?.name,
    }),
    column<Row>({
      header: "Cost Type",
      item: ({ costType }) => costTypeToNameMapper[costType],
    }),
    column<Row>({
      header: "Quantity",
      item: ({ quantity }) => quantity,
    }),
    numericColumn<Row>({
      header: "Unit",
      item: ({ unitCostInCents }) => priceCell({ valueInCents: unitCostInCents }),
    }),
  ];
}

type WorkAuthorizationFormInput = Omit<SaveWorkAuthorizationInput, "projectItems" | "documents"> & {
  projectItems: WorkAuthorizationLineItem[];
  documents: WorkAuthorizationDocumentFragment[];
};

const formConfig: ObjectConfig<WorkAuthorizationFormInput> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  projectStageId: { type: "value", rules: [required] },
  documents: {
    type: "list",
    rules: [({ value }) => (value.length > 0 ? undefined : "At least one document required")],
    config: {
      id: { type: "value" },
      name: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          downloadUrl: { type: "value" },
        },
      },
    },
  },
  projectItems: {
    type: "list",
    rules: [({ value }) => (value.length > 0 ? undefined : "At least one line item required")],
    config: {
      id: { type: "value" },
      costType: { type: "value" },
      quantity: { type: "value" },
      internalNote: { type: "value" },
      location: { type: "value" },
      item: { type: "value" },
      unitCostInCents: { type: "value" },
    },
  },
};

function mapToInput(formStateValue: WorkAuthorizationFormInput): SaveWorkAuthorizationInput {
  return {
    ...formStateValue,
    documents: formStateValue.documents.map(({ id }) => id),
    projectItems: formStateValue.projectItems?.map(({ id }) => id) ?? [],
  };
}
