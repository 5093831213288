import {
  Button,
  ButtonGroup,
  checkboxFilter,
  Css,
  dateFilter,
  DateFilterValue,
  FilterDefs,
  Filters,
  multiFilter,
  ScrollableContent,
  Tag,
  usePersistedFilter,
} from "@homebound/beam";
import uniqBy from "lodash/uniqBy";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import { SearchBox } from "src/components";
import { KanbanBoard, KanbanColumn, OnItemMoveFn } from "src/components/kanban/KanbanBoard";
import { ToDoCard } from "src/components/to-dos/ToDoCard";
import { useToDoModal } from "src/components/to-dos/ToDoModal";
import { ToDoQuickAddCard } from "src/components/to-dos/ToDoQuickAddCard";
import { ToDosTable } from "src/components/to-dos/ToDosTable";
import {
  DateFilter2,
  ToDoCardFragment,
  ToDoFilter,
  ToDoModal_CohortFragment,
  ToDoModal_DevelopmentFragment,
  ToDoModal_MarketFragment,
  ToDoModal_ProjectFragment,
  ToDoStatus,
  ToDoStatusDetail,
  useCurrentInternalUserQuery,
  useMoveToDoMutation,
  useToDosQuery,
  InternalUserDetailFragment,
  AssignedToDosDocument,
} from "src/generated/graphql-types";
import { usePersistedToggle } from "src/hooks";
import { useToDoStatuses } from "src/hooks/enums/useToDoStatuses";
import { useToDoTypes } from "src/hooks/enums/useToDoTypes";
import { PageHeader } from "src/routes/layout/PageHeader";
import { PersonalDashboardTitle } from "src/routes/personal-dashboard/components/PersonalDashboardTitle";
import { useDashboardFilterContext } from "src/routes/personal-dashboard/DashboardFilterContext";
import { PersonalDashboardFilter } from "src/routes/personal-dashboard/useHeaderFilterDefs";
import { personalDashboardPaths, projectPaths } from "src/routes/routesDef";
import {
  createDashboardTodoModalUrl,
  createProjectToDoModalUrl,
  createProjectToDosUrl,
  createToDosPdfUrl,
} from "src/RouteUrls";
import { dateFilterOperations, isEmpty, nonEmpty } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { LexoRank } from "src/utils/lexoRank";
import { openNewTab } from "src/utils/window";

export type ToDoFilterWithDateFilterValue = Omit<ToDoFilter, "dueDate" | "assignee"> & {
  dueDate: DateFilterValue<string>;
  internalUser: string[];
};

export type ToDoBoardProps = {
  projectId?: string;
  projects?: ToDoModal_ProjectFragment[];
  cohorts?: ToDoModal_CohortFragment[];
  developments?: ToDoModal_DevelopmentFragment[];
  markets?: ToDoModal_MarketFragment[];
  teamMembers: InternalUserDetailFragment[];
};

export function ToDosBoard(props: ToDoBoardProps) {
  const { projectId, projects, cohorts, developments, markets, teamMembers } = props;
  const toDoStatuses = useToDoStatuses();
  const toDoTypes = useToDoTypes();

  const projectOptions = useMemo(() => projects ?? [], [projects]);
  const cohortOptions = useMemo(() => cohorts ?? [], [cohorts]);
  const developmentOptions = useMemo(() => developments ?? [], [developments]);
  const marketOptions = useMemo(() => markets ?? [], [markets]);
  const parentOptions = [...projectOptions, ...cohortOptions, ...developmentOptions];

  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);
  const [onKanbanView, toggleView] = usePersistedToggle("toDosKanbanView", true);
  const [moveToDo] = useMoveToDoMutation();

  const { data: currentInternalUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });
  const currentUser = currentInternalUserData?.currentInternalUser;
  const isProjectPage = !!projectId;
  const defaultUserValue = useMemo(() => {
    return currentUser && !isProjectPage ? [currentUser.id] : undefined;
  }, [currentUser, isProjectPage]);

  // if route match = todos tab, use for conditional rendering in the todos board
  const isDashboardTodosPage = !!useRouteMatch([personalDashboardPaths.toDos]);

  const filterDefs: FilterDefs<ToDoFilterWithDateFilterValue> = useMemo(() => {
    const currentTeamMember = teamMembers.find(({ id }) => id === currentUser?.id);
    const otherTeamMembers = teamMembers.filter(({ id }) => id !== currentUser?.id);
    // Put (Me) option first
    const teamMemberOptions = currentTeamMember ? [currentTeamMember, ...otherTeamMembers] : teamMembers;
    return {
      internalUser: multiFilter({
        label: "Assigned to",
        options: teamMemberOptions,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name, id }) => (id === currentUser?.id ? `${name} (Me)` : name),
        defaultValue: defaultUserValue,
      }),
      ...(!isProjectPage
        ? {
            project: multiFilter({
              options: projectOptions,
              getOptionValue: ({ id }) => id,
              getOptionLabel: ({ name }) => name,
            }),
            development: multiFilter({
              options: developmentOptions,
              getOptionValue: ({ id }) => id,
              getOptionLabel: ({ name }) => name,
            }),
            cohort: multiFilter({
              options: cohortOptions,
              getOptionValue: ({ id }) => id,
              getOptionLabel: ({ name }) => name,
            }),
            market: multiFilter({
              options: marketOptions,
              getOptionValue: ({ id }) => id,
              getOptionLabel: ({ name }) => name,
            }),
          }
        : {}),
      type: multiFilter({
        options: toDoTypes ?? [],
        getOptionValue: ({ code }) => code,
        getOptionLabel: ({ name }) => name,
      }),
      dueDate: dateFilter({
        label: "Due Date",
        operations: dateFilterOperations,
        getOperationLabel: ({ label }) => label,
        getOperationValue: ({ value }) => value,
      }),
      createdBy: multiFilter({
        label: "Created by",
        options: teamMemberOptions,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name, id }) => (id === currentUser?.id ? `${name} (Me)` : name),
        defaultValue: defaultUserValue,
      }),
      watching: checkboxFilter({
        label: "Watching",
      }),
      urgent: checkboxFilter({
        label: "Urgent",
      }),
    };
  }, [
    defaultUserValue,
    isProjectPage,
    teamMembers,
    marketOptions,
    projectOptions,
    cohortOptions,
    developmentOptions,
    toDoTypes,
    currentUser,
  ]);

  const { setFilter, filter: projectToDoFilter } = usePersistedFilter<ToDoFilterWithDateFilterValue>({
    storageKey: "projectToDoFilter",
    filterDefs,
  });
  const { filter } = useDashboardFilterContext();
  // if we are on dashboards page, then use the filter from dashboards context, else use the regular Todo filter
  const todosFilter = isDashboardTodosPage ? filter : projectToDoFilter;

  const projectsForCohorts = projects
    ?.filter((project) => {
      return todosFilter.cohort?.some((cohortId) => {
        return cohortId === project.cohort?.id;
      });
    })
    .map((project) => project.id);
  const cohortsForDevelopments = cohorts
    ?.filter((cohort) => {
      return todosFilter.development?.some((developmentId) => {
        return developmentId === cohort.development?.id;
      });
    })
    .map((cohort) => cohort.id);
  const projectsForDevelopments = projects
    ?.filter((project) => {
      return cohortsForDevelopments?.some((cohortId) => {
        return project?.cohort?.id === cohortId;
      });
    })
    .map((project) => project.id);

  const queryFilter = mapToFilter(
    todosFilter,
    showAllStatuses,
    projectId,
    projectsForCohorts || [],
    projectsForDevelopments || [],
    cohortsForDevelopments || [],
  );
  const completedQueryFilter = mapToFilter(
    todosFilter,
    [ToDoStatus.Complete],
    projectId,
    projectsForCohorts || [],
    projectsForDevelopments || [],
    cohortsForDevelopments || [],
  );
  const query = useToDosQuery({
    variables: { filter: queryFilter },
    fetchPolicy: "cache-and-network",
  });

  const completedToDosQuery = useToDosQuery({
    variables: {
      filter: completedQueryFilter,
      first: 0,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data } = query;
  const { data: completedData, fetchMore } = completedToDosQuery;
  const toDos = useMemo(() => data?.toDos || [], [data]);
  const completedToDos = useMemo(() => completedData?.toDos || [], [completedData]);
  const filteredToDos = searchToDos(uniqBy([...completedToDos, ...toDos], "id"), searchFilter);
  const completedLength = completedToDos?.length;
  const showLoadMore = completedLength % loadMoreIncrement === 0 && completedLength !== 0;
  const toDoModalMatch = useRouteMatch<{ toDoId: string }>([personalDashboardPaths.toDoModal, projectPaths.toDoModal]);
  const toDoId = toDoModalMatch?.params?.toDoId;
  const history = useHistory();

  const { open } = useToDoModal({
    isProjectPage,
    defaultParentId: projectId,
    returnUrl: isDashboardTodosPage
      ? personalDashboardPaths.toDos
      : projectId
        ? createProjectToDosUrl(projectId)
        : undefined,
    refreshQueries: () => {
      void query.refetch();
      void completedToDosQuery.refetch();
    },
  });

  // Open toDoModal from route param
  useEffect(() => {
    if (toDoId) {
      open(toDoId, projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDoId]);

  const columns = toDosToKanbanColumns(toDoStatuses, filteredToDos);

  const onItemMoved: OnItemMoveFn<ToDoCardFragment> = async (toDo, opts) => {
    const { columnId: status, above, below } = opts as typeof opts & { columnId?: ToDoStatus };
    let { id, order } = toDo;
    const code = status ?? toDo.status.code;
    const displaced = below ?? above;
    if (displaced) {
      // If we're moving position, then we need to calculate a new order so the list doesn't snap around during the
      // network call.  The value we calculate here doesn't need to be the final value calculated by the server, just a
      // value good enough to preserve the order client side until the network call completes.
      const lr = LexoRank.from(displaced.order);
      // Find the toDos for the column we are moving into
      const { items: toDos } = columns.find((c) => c.id === displaced.status.code)!;
      const index = toDos.indexOf(displaced);
      // Find the sibling of our displaced toDo.  If we're inserting below, then it's the next index, otherwise the
      // previous index.
      const sibling = toDos[index + (below ? 1 : -1)];
      // If there is a sibling, calculate a rank between it and the displaced toDo.  If there isn't a sibling, then
      // increment the order by one step if we're at the top of the list (since we sort descending) and decrement
      // otherwise (ie, we're at the bottom)
      order = (sibling ? lr.between(sibling.order) : index === 0 ? lr.increment : lr.decrement).toString();
    }
    await moveToDo({
      // We sort descending, so a move above is actually after and a move below is before
      variables: { toDoId: id, status: code, moveAfterId: above?.id, moveBeforeId: below?.id },
      optimisticResponse: {
        saveToDos: {
          toDos: [{ id, order, status: { code, __typename: "ToDoStatusDetail" }, __typename: "ToDo" }],
          __typename: "SaveToDosResult",
        },
      },
      refetchQueries: [AssignedToDosDocument],
    });
  };

  function renderStatusColumnHeader(column: Column): ReactNode {
    const { name, items, code } = column;
    return (
      <>
        <h3 css={Css.sm.ttu.tac.mb3.mt2.fwn.df.fdr.jcc.$}>
          <span css={Css.mr1.$}>{name}</span>
          <Tag text={items.length.toString()} />
        </h3>
        {code !== ToDoStatus.Complete && (
          <div css={Css.mb1.$}>
            <ToDoQuickAddCard column={column} filter={todosFilter} projectId={projectId} />
          </div>
        )}
      </>
    );
  }

  const fetchMoreCompletedTasks = useMemo(
    () => () =>
      fetchMore({
        variables: {
          first: completedToDos.length,
        },
      }),
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [completedToDos],
  );

  function renderFooterComponent(column: Column): ReactNode {
    const { code } = column;
    return (
      <>
        {code === ToDoStatus.Complete && showLoadMore && (
          <div css={Css.df.mt1.mb1.jcc.$}>
            <Button
              variant="text"
              label="Load More"
              data-testid="completedLoadMore"
              onClick={() => fetchMoreCompletedTasks()}
            />
          </div>
        )}
      </>
    );
  }

  function handleExportClick() {
    if (filteredToDos) {
      openNewTab(createToDosPdfUrl(filteredToDos.map((toDo) => toDo.id)));
    }
  }

  return (
    <>
      <PageHeader
        title="To-Dos"
        hideTitle
        left={
          !isDashboardTodosPage ? (
            <Filters<ToDoFilterWithDateFilterValue>
              filter={projectToDoFilter}
              onChange={setFilter}
              filterDefs={filterDefs}
            />
          ) : (
            <PersonalDashboardTitle title="To-Dos" />
          )
        }
        right={
          <div css={Css.df.jcsb.gap1.$}>
            <div css={Css.df.aic.jcc.smSb.$}>View</div>
            <ButtonGroup
              buttons={[
                {
                  icon: "projects",
                  tooltip: "List View",
                  active: !onKanbanView,
                  onClick: toggleView,
                },
                {
                  icon: "kanban",
                  tooltip: "Kanban View",
                  active: onKanbanView,
                  onClick: toggleView,
                },
              ]}
            />
            <Button
              variant="secondary"
              disabled={isEmpty(filteredToDos)}
              label="Export"
              icon="upload"
              onClick={handleExportClick}
              data-testid="exportPdf"
            />
            <SearchBox onSearch={setSearchFilter} fullWidth={false} />
            <Button label="Add To-Do" onClick={() => open("new")} />
          </div>
        }
      />
      {/* Still using ScrollableContent here. We want the boards to extend the length of the page,
       and the easiest way to ensure we do that at the moment is by wrapping in ScrollableContent.
       Then because we really don't want it to scroll, but instead each individual board, we use overflowHidden on the immediate child */}
      <ScrollableContent omitBottomPadding virtualized>
        {onKanbanView ? (
          // Temp fix to support both project and dashboard todos. Will need to revisit to make page more module and remove this `isDashboardToDoPage` param.
          <div css={Css.h100.if(!isDashboardTodosPage).bshBasic.bgWhite.p2.br4.$}>
            <KanbanBoard
              columns={columns}
              onItemMove={onItemMoved}
              onItemClick={({ id }) =>
                history.push(isProjectPage ? createProjectToDoModalUrl(projectId, id) : createDashboardTodoModalUrl(id))
              }
              renderColumnHeader={renderStatusColumnHeader}
              renderCard={renderToDoCard}
              renderFooterComponent={renderFooterComponent}
            />
          </div>
        ) : (
          <ToDosTable
            projectId={projectId}
            toDos={filteredToDos}
            parentOptions={parentOptions}
            statuses={toDoStatuses}
            teamMembers={teamMembers}
            types={toDoTypes}
            fetchMoreCompletedTasks={fetchMoreCompletedTasks}
            showLoadMore={showLoadMore}
          />
        )}
      </ScrollableContent>
    </>
  );
}

export type Column = ToDoStatusDetail & KanbanColumn<ToDoCardFragment>;

function renderToDoCard(toDoCardProps: ToDoCardFragment) {
  return <ToDoCard toDo={toDoCardProps} />;
}

export function searchToDos(toDos: ToDoCardFragment[], searchFilter?: string) {
  return searchFilter
    ? toDos.filter((t) =>
        [t.name, t.description, t.parent?.name, t.type.name, ...t.assignees.map(({ name }) => name)]
          .map((s) => s?.toLowerCase())
          .some((s) => s?.includes(searchFilter!.toLowerCase())),
      )
    : toDos;
}

function toDosToKanbanColumns(toDoStatuses: ToDoStatusDetail[], toDos?: ToDoCardFragment[]): Column[] {
  return toDoStatuses
    .slice()
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((s) => ({
      ...s,
      id: s.code,
      items: (toDos ?? [])
        .filter((t) => t.status.code === s.code)
        // sort descending so that new toDos are first
        .sort((a, b) => b.order.localeCompare(a.order)),
    }));
}

function mapToFilter(
  filter: ToDoFilterWithDateFilterValue | PersonalDashboardFilter,
  statuses: ToDoStatus[],
  projectId: string | undefined,
  projectsForCohorts: string[],
  projectsForDevelopments: string[],
  cohortsForDevelopments: string[],
): ToDoFilter {
  const { dueDate, cohort, project, development, internalUser, ...others } = filter;

  let projectIds: string[] = [];
  let cohortIds: string[] = [];
  let developmentIds: string[] = [];

  if (projectId) {
    projectIds = [projectId];
  }
  if (project) {
    projectIds = [...project];
  }
  if (cohort) {
    projectIds = [...projectIds, ...projectsForCohorts];
    cohortIds = [...cohort];
  }
  if (development) {
    projectIds = [...projectIds, ...projectsForDevelopments];
    cohortIds = [...cohortIds, ...cohortsForDevelopments];
    developmentIds = [...development];
  }

  return {
    status: statuses,
    ...others,
    project: nonEmpty(projectIds) ? projectIds.unique() : undefined,
    cohort: nonEmpty(cohortIds) ? cohortIds.unique() : undefined,
    development: nonEmpty(developmentIds) ? developmentIds : undefined,
    ...(dueDate ? { dueDate: { op: dueDate.op, value: new DateOnly(new Date(dueDate.value)) } as DateFilter2 } : {}),
    assignee: internalUser,
  };
}

const loadMoreIncrement = 10;
export const showAllStatuses = [ToDoStatus.Backlog, ToDoStatus.UpNext, ToDoStatus.InProgress];
