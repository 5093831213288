import { Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { createContext, DependencyList, PropsWithChildren, useContext, useEffect, useRef } from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import { useUnload } from "src/hooks";

export const NavigationContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const navigationCheckCallbacks = useRef<(() => boolean)[]>([]);

  const canNavigate = () => {
    const result = navigationCheckCallbacks.current.every((callback) => callback());
    return result;
  };

  const getNavigationCheckCallbacks = () => [...navigationCheckCallbacks.current];

  useUnload((e: BeforeUnloadEvent) => {
    if (!canNavigate()) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  const useRegisterNavigationCheck = (allowNavigationFn: () => boolean, deps?: DependencyList | undefined) => {
    useEffect(
      () => {
        navigationCheckCallbacks.current.push(allowNavigationFn);
        return () => {
          navigationCheckCallbacks.current = navigationCheckCallbacks.current.filter(
            (callback) => callback !== allowNavigationFn,
          );
        };
      },
      // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
      // eslint-disable-next-line react-hooks/exhaustive-deps
      deps,
    );
  };

  return (
    <NavigationContext.Provider value={{ canNavigate, useRegisterNavigationCheck, getNavigationCheckCallbacks }}>
      {children}
      <NavigationPrompt when={() => !canNavigate()}>
        {({ onConfirm, onCancel }) => <ConfirmLeaveModal onConfirm={onConfirm} onCancel={onCancel} />}
      </NavigationPrompt>
    </NavigationContext.Provider>
  );
};

export const useNavigationCheck = () => useContext(NavigationContext);

type NavigationContextProps = {
  canNavigate: () => boolean;
  useRegisterNavigationCheck: (allowNavigationFn: () => boolean, deps?: DependencyList) => void;
  getNavigationCheckCallbacks: () => (() => boolean)[];
};

const NavigationContext = createContext<NavigationContextProps>({
  canNavigate: () => true,
  useRegisterNavigationCheck: (_, __) => {},
  getNavigationCheckCallbacks: () => [],
});

type ConfirmLeaveModalProps = {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
};

function ConfirmLeaveModal({ onConfirm, onCancel }: ConfirmLeaveModalProps) {
  const { openModal, closeModal } = useModal();
  // useEffect to immediately open the modal.
  useEffect(
    () => {
      openModal({
        content: (
          <>
            <ModalHeader>Leave page?</ModalHeader>
            <ModalBody>
              <p>All changes you've made will be lost.</p>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="tertiary"
                label="Continue Editing"
                onClick={() => {
                  closeModal();
                  onCancel();
                }}
              />
              <Button
                label="Leave"
                variant="danger"
                onClick={() => {
                  onConfirm();
                  closeModal();
                }}
              />
            </ModalFooter>
          </>
        ),
      });

      return () => closeModal();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return <></>;
}
