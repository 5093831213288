import { StoreApi } from "zustand";
import { TakeoffsStoreState } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { BoundSelectField, Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Maybe, useBulkTaskAllocationModal_GlobalPlanTasksQuery } from "src/generated/graphql-types";
import { Observer } from "mobx-react";

type BulkTaskAllocationModalProps = {
  store: StoreApi<TakeoffsStoreState>;
  itemsToEdit: string[];
};

export function BulkTaskAllocationModal({ store, itemsToEdit }: BulkTaskAllocationModalProps) {
  const { closeModal } = useModal();
  const { data } = useBulkTaskAllocationModal_GlobalPlanTasksQuery({ variables: { filter: {} } });
  const formState = useFormState({ config: formConfig });
  async function onSave() {
    const taskId = formState.taskId.value;
    await store.getState().saveItiv(itemsToEdit.map((id) => ({ id, taskId })));
    closeModal();
  }

  return (
    <>
      <ModalHeader>Task Allocation</ModalHeader>
      <ModalBody>
        <BoundSelectField
          getOptionValue={(o) => o.id}
          getOptionLabel={(o) => o.name}
          options={data?.globalPlanTasks.entities ?? []}
          field={formState.taskId}
        />
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Observer>
          {() => <Button label="Save" variant="primary" disabled={!formState.valid} onClick={onSave} />}
        </Observer>
      </ModalFooter>
    </>
  );
}

type TaskAllocationFormState = {
  taskId: Maybe<string>;
};

const formConfig: ObjectConfig<TaskAllocationFormState> = {
  taskId: { type: "value", rules: [required] },
};
