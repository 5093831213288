import { FetchResult } from "@apollo/client";
import { BoundTextField, Css, useSnackbar } from "@homebound/beam";
import { FieldState, ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  GlobalPlanTag,
  IncrementalCollectionOp,
  Maybe,
  SaveGlobalPlanTagInput,
  SaveGlobalPlanTagMutation,
  TaskCatalogFormDropdownDocument,
  TaskCatalogTableDocument,
  TaskCatalogTableMetadataDocument,
  useSaveGlobalPlanTagMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { pluralize } from "src/utils";

type AddGlobalPlanTagModalProps = {
  taskIds?: string[];
  onTagAdded?: (tag: Pick<GlobalPlanTag, "id">) => void;
};

export function AddGlobalPlanTagModal({ taskIds, onTagAdded }: AddGlobalPlanTagModalProps) {
  const [saveGlobalPlanTag] = useSaveGlobalPlanTagMutation();

  const isAssigning = taskIds?.nonEmpty;

  const { triggerNotice } = useSnackbar();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: {
        name: "",
        taskIds,
      },
      map: (value) => ({
        name: value.name,
        tasks: value.taskIds?.map((id) => ({ id, op: IncrementalCollectionOp.Include })) ?? [],
      }),
    },
  });

  return (
    <Observer>
      {() => (
        <ConfirmationModal
          title={isAssigning ? "Add tasks to a New Tag" : "Add a Global Plan Tag"}
          label={isAssigning ? "Add to Tag" : "Add Tag"}
          disabled={!formState.valid}
          onConfirmAction={async () => {
            const newTag = await saveGlobalPlanTag({
              variables: { input: formState.value },
              refetchQueries: [
                TaskCatalogFormDropdownDocument,
                TaskCatalogTableDocument,
                TaskCatalogTableMetadataDocument,
              ],
            });

            if (onTagAdded) {
              onTagAdded(newTag.data?.saveGlobalPlanTag.globalPlanTag!);
            }

            triggerNotice({
              message: isAssigning
                ? `${taskIds.length} ${pluralize(taskIds.length, "task", "tasks")} have been added to '${
                    formState.name.value
                  }'`
                : `Added tag '${formState.name.value}'`,
            });
          }}
          confirmationMessage={
            <div css={Css.df.fdc.gap2.$}>
              {isAssigning ? (
                <span>
                  Define a new tag to add <b>[{taskIds.length}]</b> selected{" "}
                  {pluralize(taskIds.length, "task", "tasks")} to.
                </span>
              ) : null}
              <BoundTextField
                field={formState.name}
                labelStyle="left"
                label={isAssigning ? "Custom Grouping" : "Name"}
              />
            </div>
          }
        />
      )}
    </Observer>
  );
}

const formConfig: ObjectConfig<SaveGlobalPlanTagInput> = {
  name: { type: "value", rules: [required] },
  tasks: {
    type: "list",
    config: {
      id: { type: "value" },
      op: { type: "value" },
    },
  },
};
