import { BoundTextAreaField, BoundCheckboxField, useComputed } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { EditChangeRequestForm } from "./DetailsTab";
import { isPersonalizationChangeSource } from "../utils";
import { CreateAdditionalChangeRequestForm, CreateChangeRequestForm } from "../CreateChangeRequestDrawer";

type CustomerRequestPersonalizationInputsProps = {
  formState:
    | ObjectState<EditChangeRequestForm>
    | ObjectState<CreateChangeRequestForm>
    | ObjectState<CreateAdditionalChangeRequestForm>;
};
export function CustomerRequestPersonalizationInputs({ formState }: CustomerRequestPersonalizationInputsProps) {
  const isCustomerRequest = useComputed(
    () => isPersonalizationChangeSource(formState.source.value),
    [formState.source, isPersonalizationChangeSource],
  );

  return (
    <>
      {isCustomerRequest && (
        <>
          <BoundTextAreaField
            field={formState.salesforceOpportunityUrl}
            label="Salesforce Opportunity Link"
            preventNewLines
          />
          <BoundCheckboxField field={formState.isUnderContract} label="Client Confirmation" />
          <BoundCheckboxField field={formState.isStandardOffering} label="Custom" />
          <BoundTextAreaField field={formState.productSelection} label="Product Selection" />
        </>
      )}
    </>
  );
}
