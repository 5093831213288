import { useParams } from "react-router";
import {
  InputMaybe,
  PlanScheduleTabQuery,
  SavePlanScheduleInput,
  usePlanScheduleTabQuery,
  useSavePlanScheduleSettingsMutation,
} from "src/generated/graphql-types";
import { noop, pluralize, queryResult } from "src/utils";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { BoundDateField, BoundNumberField, Css, DateField, FormLines, Tooltip, useComputed } from "@homebound/beam";
import { Observer } from "mobx-react";
import { DateOnly } from "src/utils/dates";

export function PlanScheduleTab() {
  const { projectId } = useParams<{ projectId: string }>();
  const query = usePlanScheduleTabQuery({ variables: { projectId } });
  return queryResult(query, {
    data: (data) => <PlanScheduleTabView data={data} />,
  });
}

type PlanScheduleTabViewProps = {
  data: PlanScheduleTabQuery;
};

export function PlanScheduleTabView({ data }: PlanScheduleTabViewProps) {
  const { planSchedule } = data.project;
  const [savePlanSchedule] = useSavePlanScheduleSettingsMutation();

  const formState = useFormState({
    config,
    init: {
      input: planSchedule,
      map: (input) => ({
        id: input.id,
        targetStartDate: input.targetStartDate.date,
        targetCycleTimeInDays: input.targetCycleTimeInDays ?? 0,
        currentBaselineDate: input.currentBaselineDate.date,
      }),
    },
    autoSave: async (fs) => {
      const input: SavePlanScheduleInput = {
        id: fs.id.value,
        targetStartDate: new DateOnly(fs.targetStartDate.value!),
        targetCycleTimeInDays: fs.targetCycleTimeInDays.value,
        currentBaselineDate: new DateOnly(fs.currentBaselineDate.value!),
      };
      await savePlanSchedule({
        variables: { input },
        // Clear cache on `planScheduleSnapshots` so we don't keep stale values when `currentBaselineDate` changes
        update: (cache) => cache.evict({ fieldName: "planScheduleSnapshots" }),
      });
    },
  });
  const months = useComputed(() => {
    if (!formState.targetCycleTimeInDays.value) return 0;
    return Math.floor(formState.targetCycleTimeInDays.value / 20);
  }, [formState.targetCycleTimeInDays.value]);

  return (
    <Observer>
      {() => (
        <div data-testid="planScheduleSettings" css={Css.wPx(680).$}>
          <div css={Css.bgWhite.br8.bshBasic.p2.pr3.$}>
            <FormLines width="full" labelStyle="left">
              <div css={Css.df.fdc.$}>
                <div css={Css.w100.mb3.df.$}>
                  <BoundNumberField label="Target Cycle Time" field={formState.targetCycleTimeInDays} />
                  <Tooltip title="month is estimated at 20 days">
                    <div css={Css.wPx(220).asc.pl1.smMd.$}>
                      work days ({months} {pluralize(months, "month")})
                    </div>
                  </Tooltip>
                </div>
                <div css={Css.mb3.df.$}>
                  <BoundDateField
                    label="Start Date"
                    field={formState.targetStartDate}
                    disabledDays={{ dayOfWeek: [0, 6] }}
                  />
                  <div css={Css.wPx(220).$}></div>
                </div>
                <div css={Css.mb3.df.$}>
                  <BoundDateField
                    label="Current Baseline"
                    field={formState.currentBaselineDate}
                    disabledDays={{ dayOfWeek: [0, 6] }}
                  />
                  <div css={Css.wPx(220).$}></div>
                </div>
                <div css={Css.mb3.df.$}>
                  <DateField
                    label="Target End Date"
                    value={planSchedule?.targetEndDate}
                    readOnly
                    onChange={noop}
                    disabledDays={{ dayOfWeek: [0, 6] }}
                  />
                  <div css={Css.wPx(220).$}></div>
                </div>
              </div>
            </FormLines>
          </div>
        </div>
      )}
    </Observer>
  );
}

const config: ObjectConfig<
  Pick<SavePlanScheduleInput, "id" | "targetCycleTimeInDays"> & {
    targetStartDate: InputMaybe<Date>;
    currentBaselineDate: InputMaybe<Date>;
  }
> = {
  id: { type: "value", rules: [required] },
  targetStartDate: { type: "value", rules: [required] },
  targetCycleTimeInDays: { type: "value", rules: [required] },
  currentBaselineDate: { type: "value", rules: [required] },
};
