import { ButtonMenu } from "@homebound/beam";
import { useRouteMatch } from "react-router-dom";
import {
  createDesignCatalogUrl,
  createGlobalOptionsUrl,
  createItemCatalogUrl,
  createItemTemplatesUrl,
  createMaterialCatalogUrl,
  createMilestoneCatalogUrl,
  createPlanPackageUrl,
  createProductCatalogUrl,
  createScheduleTemplatesUrl,
  createTaskCatalogUrl,
} from "src/RouteUrls";
import { librariesPath } from "../routesDef";
import { getStage } from "src/context";
import { useFeatureFlag } from "src/contexts/FeatureFlags/FeatureFlagContext";
import { FeatureFlagType } from "src/generated/graphql-types";

export function LibrariesNavMenu() {
  const isLibraryPath = !!useRouteMatch([librariesPath]);
  const isProductConfigEnabled = useFeatureFlag(FeatureFlagType.ProductConfig);
  const isMaterialsCatalogEnabled = useFeatureFlag(FeatureFlagType.MaterialCatalog);
  const isTaskCatalogEnabled = useFeatureFlag(FeatureFlagType.DynamicSchedules);

  return (
    <ButtonMenu
      key="librariesNavMenu"
      trigger={{ navLabel: "Libraries", variant: "global", active: isLibraryPath }}
      contrast
      items={[
        ...(isProductConfigEnabled
          ? [
              { label: "Plans", onClick: createPlanPackageUrl() },
              { label: "Design Package", onClick: createDesignCatalogUrl() },
              // Hiding this until we have a global product offerings page
              // { label: "Product Offerings", onClick: createProductCatalogUrl(), hasDivider: true },
            ]
          : []),
        { label: "Options", onClick: createGlobalOptionsUrl() },
        ...(isMaterialsCatalogEnabled ? [{ label: "Materials", onClick: createMaterialCatalogUrl() }] : []),
        ...(isTaskCatalogEnabled ? [{ label: "Tasks", onClick: createTaskCatalogUrl() }] : []),
        ...(isTaskCatalogEnabled
          ? [{ label: "Milestones", onClick: createMilestoneCatalogUrl(), hasDivider: true }]
          : []),
        { label: "Bid Items", onClick: createItemCatalogUrl() },
        { label: "Scope Template", onClick: createItemTemplatesUrl() },
        { label: "Schedule Template", onClick: createScheduleTemplatesUrl() },
      ].compact()}
      showActiveBorder
    />
  );
}
