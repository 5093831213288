import { useParams } from "react-router-dom";
import { ProductOfferingParams } from "src/routes/routesDef";
import { ProductOfferingConfigsPageView } from "src/routes/libraries/product-offering-configs/ProductOfferingConfigsPage";
import { useProductOfferingSpecificConfigsPageMetadataQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

export function ProductOfferingConfigsPage() {
  const { developmentId, productOfferingId } = useParams<ProductOfferingParams>();
  const query = useProductOfferingSpecificConfigsPageMetadataQuery({ variables: { productOfferingId } });
  return queryResult(query, {
    data: ({ productOffering: { itemTemplates } }) => {
      // TODO: Use ProductOffering.latestTemplate once available
      const templateId = (itemTemplates.find((it) => it.isLatestActive) ?? itemTemplates.last)?.id;
      return (
        <ProductOfferingConfigsPageView
          marketsOrIds={{ developmentId, productOffering: { id: productOfferingId, templateId } }}
        />
      );
    },
  });
}
