import { createPlanPackageUrl } from "src/RouteUrls";
import { ChooseOptionsStepBody } from "src/routes/components/PlanConfiguration/ChooseOptionsStepBody";
import { PlanPackageEditorHeader } from "src/routes/libraries/plan-package/stepper/components/PlanPackageEditorHeader";
import { StepLayout } from "src/components/stepper/StepLayout";
import { ChooseOptionsRow } from "src/routes/components/PlanConfiguration/utils";
import { SelectedOptionsTag } from "src/routes/components/PlanConfiguration/SelectedOptionsTag";
import { useComputed, useGridTableApi } from "@homebound/beam";

type ChooseOptionsStepProps = {
  id: string;
  setStepDirty: (dirty: boolean) => void;
};

export function ChooseOptionsStep({ id, setStepDirty }: ChooseOptionsStepProps) {
  const api = useGridTableApi<ChooseOptionsRow>();
  const selectedRows = useComputed(() => api.getSelectedRows("globalOption"), [api]);

  return (
    <StepLayout
      header={
        <PlanPackageEditorHeader
          title="Options"
          subtitle={`Select all possible options to go with this plan. \nYou will be able to edit/add these later.`}
          right={<SelectedOptionsTag selectedRows={selectedRows} />}
        />
      }
      body={
        <ChooseOptionsStepBody
          planId={id}
          returnUrl={createPlanPackageUrl(id)}
          setStepDirty={setStepDirty}
          tableApi={api}
          useV2Locations
        />
      }
    />
  );
}
