import { Css, IconButton, Palette, useModal } from "@homebound/beam";
import { ItemTemplateAnomaliesModal } from "./ItemTemplateAnomaliesModal";
import { useItemTemplateAnomaliesContext } from "./ItemTemplateAnomaliesContext";

type ItemTemplateAnomaliesIconButtonProps = {
  parentId: string | undefined;
};

/** Provides "From Import" for both v1 and v2 templates. */
export function ItemTemplateAnomaliesIconButton(props: ItemTemplateAnomaliesIconButtonProps) {
  const { parentId } = props;

  const { openModal } = useModal();
  const { anomaliesFor } = useItemTemplateAnomaliesContext();

  const anomalies = anomaliesFor(parentId);

  if (anomalies.isEmpty) return;

  const [a1, a2, a3, ...others] = anomalies;

  const tooltip = (
    <>
      <div css={Css.mb1.$}>Anomalies 🧪</div>
      <ul>
        {[a1, a2, a3].compact().map((a) => (
          <li key={a.id}>{a.description}</li>
        ))}
        {others?.nonEmpty && <li key="more">...and {others.length} more</li>}
      </ul>
    </>
  );

  return (
    <IconButton
      onClick={() => openModal({ content: <ItemTemplateAnomaliesModal anomalies={anomalies} /> })}
      tooltip={tooltip}
      icon={"error"}
      color={Palette.Orange800}
    />
  );
}
