import { Button, Css, GridDataRow, Switch, useComputed, useGridTableApi } from "@homebound/beam";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  ConfirmLineItemsHomeownerContractsFragment,
  ContractType,
  InvoiceV2Fragment,
  SaveInvoiceCostLineItemAllocationInput,
  SaveInvoiceLineItemInput,
  useConfirmLineItemsDataQuery,
  useSaveInvoiceV2Mutation,
} from "src/generated/graphql-types";
import useSetState from "src/hooks/useSetState";
import { ExitIconButton } from "src/routes/developments/cost-mapping/components/ExitIconButton";
import { PageHeader } from "src/routes/layout/PageHeader";
import { InvoiceDateFilter } from "src/routes/projects/invoices/invoice-v2/components/InvoiceDateFilter";
import { queryResult } from "src/utils";
import { DateOnly, ensureDateOnly } from "src/utils/dates";
import { ownerContractType, isCostPlusWithoutCost } from "../../utils";
import {
  CostLineItemRow,
  InvoiceLineItemsRow,
  InvoiceLineItemsTable,
  toInvoiceCostLineItemAllocationInput,
} from "../components/InvoiceLineItemsTable";
import { InvoiceStepEnum } from "../enums/index";
import {
  convertHistoricAmountsToAllocations,
  InvoiceHomeownerContractLineItemsStore,
} from "../models/InvoiceHomeownerContractLineItemsStore";

type ConfirmLineItemsProps = {
  onExit: () => void;
  setDraftInvoice: Dispatch<SetStateAction<InvoiceV2Fragment | undefined>>;
  invoice: InvoiceV2Fragment;
  startDate: DateOnly;
};

export function ConfirmLineItems({ onExit, invoice, startDate, setDraftInvoice }: ConfirmLineItemsProps) {
  const query = useConfirmLineItemsDataQuery({
    variables: { projectStageId: invoice.projectStage.id },
  });

  return queryResult(query, {
    data: ({ projectStage }) => {
      return (
        <ConfirmLineItemsView
          onExit={onExit}
          invoice={invoice}
          setDraftInvoice={setDraftInvoice}
          homeownerContracts={projectStage.homeownerContracts}
          startDate={startDate}
        />
      );
    },
  });
}

export type InvoiceLineItemsFilter = {
  startDate: DateOnly;
  endDate: DateOnly;
  showOnlyActuals: boolean;
};

type ConfirmLineItemsViewProps = ConfirmLineItemsProps & {
  homeownerContracts: ConfirmLineItemsHomeownerContractsFragment[];
};

function ConfirmLineItemsView(props: ConfirmLineItemsViewProps) {
  const { homeownerContracts, invoice, onExit, startDate, setDraftInvoice } = props;
  const [filter, setFilter] = useSetState<InvoiceLineItemsFilter>({
    startDate,
    endDate: ensureDateOnly(invoice.lineItemCutoffDate!),
    showOnlyActuals: true,
  });

  const { nextStepEvenIfDisabled, setSteps } = useStepperContext();
  const [saveInvoice] = useSaveInvoiceV2Mutation();

  const tableApi = useGridTableApi<InvoiceLineItemsRow>();

  // convert project item cost historic amounts into cost allocations
  const convertedHCLIs = useMemo(
    () => convertHistoricAmountsToAllocations(homeownerContracts, invoice.id),
    [homeownerContracts, invoice],
  );

  const store = useMemo(
    () => new InvoiceHomeownerContractLineItemsStore(convertedHCLIs, filter, invoice),
    [convertedHCLIs, filter, invoice],
  );

  // select rows
  useEffect(() => {
    if (invoice.lineItems.nonEmpty) {
      invoice.lineItems
        .filter((li) =>
          [ContractType.BudgetReallocation, ContractType.CostPlus].includes(
            li.homeownerContractLineItem.owner.contractType,
          ),
        )
        .forEach((li) => {
          const hcliRow = store.items.find((hcli) => hcli.id === li.homeownerContractLineItem.id)!;
          if (hcliRow.hasSingleCost) {
            // if has single cost, then select the HCLI line
            tableApi.selectRow(li.homeownerContractLineItem.id, true);
          } else {
            // otherwise select the invoice allocation lines
            li.costLineItemAllocations.forEach((clia) =>
              tableApi.selectRow(`${hcliRow.id}-${clia.costLineItemId}`, true),
            );
          }
        });
    } else {
      // select all HCLI lines that has actuals
      store.items.forEach((item) => item.hasActualsOnCurrentPeriod && tableApi.selectRow(item.id, true));
    }
  }, [filter.showOnlyActuals, invoice.lineItems, store.items, tableApi]);

  const selectedRows = useComputed(() => tableApi.getSelectedRows(), [tableApi]);

  const save = async () => {
    const lineItemsInput = mapLineItemsInput(store, selectedRows, invoice);

    // unselected input must be deleted
    const unselectInput: SaveInvoiceLineItemInput[] = invoice.lineItems
      .filter(
        (ili) =>
          !lineItemsInput.some((input) => input.homeownerContractLineItemId === ili.homeownerContractLineItem.id) &&
          ownerContractType(ili.homeownerContractLineItem) === ContractType.CostPlus &&
          !isCostPlusWithoutCost(ili.homeownerContractLineItem),
      )
      .map(({ id }) => ({ id, delete: true }));

    const fixedInput = invoice.lineItems
      .filter(
        (ili) =>
          ownerContractType(ili.homeownerContractLineItem) === ContractType.Fixed ||
          isCostPlusWithoutCost(ili.homeownerContractLineItem),
      )
      .map(({ id, amountInCents, costLineItemAllocations }) => ({
        id,
        amountInCents,
        costLineItemAllocations: costLineItemAllocations.map(
          ({ id, costAmountInCents, invoicedAmountInCents, costLineItemId }) => ({
            id,
            costAmountInCents,
            invoicedAmountInCents,
            costLineItemId,
          }),
        ),
      }));

    const invoiceResult = await saveInvoice({
      variables: {
        input: {
          id: invoice.id,
          lineItems: [...lineItemsInput, ...unselectInput, ...fixedInput],
          lineItemCutoffDate: filter.endDate,
        },
      },
    });
    const newInvoice = invoiceResult.data?.saveInvoice?.invoice;
    setDraftInvoice(newInvoice);
  };

  return (
    <>
      <PageHeader
        title={"Confirm line items"}
        breadcrumb={{ label: "NEW INVOICE", href: "#" }}
        right={
          <div css={Css.df.jcsb.gap4.$}>
            <ExitIconButton showModal={false} onCloseConfirmed={onExit} />
          </div>
        }
      />

      <div css={Css.pr3.w("55%").$}>
        <h2 css={Css.lgSb.mb2.$}>
          Invoice "{invoice.invoiceNumber} - {invoice.title}"
        </h2>
        <span css={Css.sm.$}>
          All existing actuals for this period have been prepopulated and selected for you. <b>Deselect items</b> you do
          not want to invoice at this time. When finished, click Continue.
        </span>
      </div>

      <TableFilter filter={filter} setFilter={setFilter} />
      <div css={Css.mt2.$}>
        <InvoiceLineItemsTable filter={filter} store={store} api={tableApi} projectId={invoice.project.id} />
      </div>

      <StepActions>
        <div css={Css.df.gap2.$}>
          <Button
            label="Save & Exit"
            variant="secondary"
            onClick={async () => {
              await save();
              onExit();
            }}
          />
          <Button
            label="Continue"
            disabled={
              !(selectedRows.nonEmpty || invoice.drawLineItems.nonEmpty || invoice.projectStage.hasFixedToInvoice)
            }
            onClick={async () => {
              await save();
              setSteps((prevState) => {
                const currentIndex = prevState.findIndex((s) => s.value === InvoiceStepEnum.SELECT_COST_PLUS_ITEM);
                return [
                  ...prevState.slice(0, currentIndex),
                  { ...prevState[currentIndex], state: "complete" },
                  { ...prevState[currentIndex + 1], disabled: false },
                  ...(currentIndex + 2 === prevState.length ? [] : prevState.slice(currentIndex + 2)),
                ];
              });
              nextStepEvenIfDisabled();
            }}
          />
        </div>
      </StepActions>
    </>
  );
}

type InputLine = {
  toInvoice: number;
  allocations: SaveInvoiceCostLineItemAllocationInput[];
};

function mapLineItemsInput(
  store: InvoiceHomeownerContractLineItemsStore,
  selectedRows: GridDataRow<InvoiceLineItemsRow>[],
  invoice: InvoiceV2Fragment,
): SaveInvoiceLineItemInput[] {
  const selectedRowsIds = selectedRows.map((i) => i.id);
  const selectedLinesMap = new Map<string, InputLine>();

  for (const row of selectedRows) {
    switch (row.kind) {
      case "costLineItem":
        const contractLineItem = store.items.find((item) => item.id === row.data.parentHCLIId)!;
        const parentRow = contractLineItem.toRow(selectedRowsIds);

        // if it doesn't exist, add to input Map
        if (!selectedLinesMap.has(parentRow.id)) {
          selectedLinesMap.set(parentRow.id, {
            toInvoice: parentRow.data.toInvoice as number,
            allocations: [toInvoiceCostLineItemAllocationInput(row, contractLineItem.markupMultiplier)],
          });
        } else {
          const currentInput = selectedLinesMap.get(parentRow.id)!;

          // if CLI has not already included on current input, then add this
          if (!currentInput.allocations.some((clia) => clia.costLineItemId === row.data.costLineItemId)) {
            selectedLinesMap.set(parentRow.id, {
              toInvoice: parentRow.data.toInvoice as number,
              allocations: [
                ...currentInput.allocations,
                toInvoiceCostLineItemAllocationInput(row, contractLineItem.markupMultiplier),
              ],
            });
          }
        }
        break;
      case "item":
        const isUniqueCost = Boolean(row.data.entity?.id);
        if (row.children?.nonEmpty || isUniqueCost) {
          const markupMultiplier = row.data.isFixedMarkup ? 1 : (100 + (row.data.markup as number)) / 100;
          // create/update the input item
          let allocations = ((row.children ?? []) as GridDataRow<CostLineItemRow>[]).map((r) =>
            toInvoiceCostLineItemAllocationInput(r, markupMultiplier),
          );
          if (isUniqueCost) {
            const data = { ...row.data, parentHCLIId: row.id };
            const costRow = { ...row, id: row.data.costLineItemId!, kind: "costLineItem" as const, data };
            allocations = [toInvoiceCostLineItemAllocationInput(costRow, markupMultiplier)];
          }

          selectedLinesMap.set(row.id, { toInvoice: row.data.toInvoice as number, allocations });
        }
        break;
    }
  }

  return Array.from(selectedLinesMap, ([homeownerContractLineItemId, inputLineItem]) =>
    mapInputLine(inputLineItem, homeownerContractLineItemId, invoice),
  );
}

function mapInputLine(
  input: InputLine,
  homeownerContractLineItemId: string,
  invoice: InvoiceV2Fragment,
): SaveInvoiceLineItemInput {
  const lineItem = invoice.lineItems.find((li) => li.homeownerContractLineItem.id === homeownerContractLineItemId);

  const { toInvoice, allocations } = input;
  const invoiceLineItemInput = {
    id: lineItem?.id,
    homeownerContractLineItemId,
    amountInCents: toInvoice,
    costLineItemAllocations: allocations,
  };

  // exists line item with allocation, then it should be updated
  if (lineItem && lineItem.costLineItemAllocations.nonEmpty) {
    const { id, costLineItemAllocations: itemAllocations } = lineItem;
    invoiceLineItemInput.id = id;

    const toCreate = allocations.filter(
      (a) => !itemAllocations.some((clia) => clia.costLineItemId === a.costLineItemId),
    );

    // map allocations and check it should be deleted
    const toUpdate = itemAllocations.map((ia) => {
      const allocation = allocations.find((a) => a.costLineItemId === ia.costLineItemId);
      return {
        id: ia.id,
        costAmountInCents: allocation?.costAmountInCents ?? ia.costAmountInCents,
        invoicedAmountInCents: allocation?.invoicedAmountInCents ?? ia.invoicedAmountInCents,
        costLineItemId: ia.costLineItemId,
        delete: allocation === undefined,
      };
    });

    invoiceLineItemInput.costLineItemAllocations = [...toUpdate, ...toCreate];

    // sum amountInCents by non deleted allocations
    invoiceLineItemInput.amountInCents = invoiceLineItemInput.costLineItemAllocations.reduce(
      (acc, clia) => (!clia.delete ? (acc += clia.invoicedAmountInCents ?? 0) : acc),
      0,
    );
  }

  return {
    ...invoiceLineItemInput,
    amountInCents: Math.round(invoiceLineItemInput.amountInCents), // round to an integer cents
  };
}

type TableFilterProps = {
  filter: InvoiceLineItemsFilter;
  setFilter: Dispatch<Partial<InvoiceLineItemsFilter>>;
};

function TableFilter({ setFilter, filter }: TableFilterProps) {
  return (
    <div css={Css.mt3.df.pr3.jcsb.$}>
      <div css={Css.df.f(0.3).$}>
        <InvoiceDateFilter
          endDate={new Date(filter.endDate)}
          startDate={new Date(filter.startDate)}
          onChange={(endDate: Date) => setFilter({ endDate: new DateOnly(endDate) })}
        />
      </div>
      <Switch
        label="Show only cost codes with outstanding actuals to be billed"
        selected={filter.showOnlyActuals}
        data-testid="actualsToggle"
        onChange={() => setFilter({ showOnlyActuals: !filter.showOnlyActuals })}
        labelStyle="inline"
      />
    </div>
  );
}
