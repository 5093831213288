import { Css, Tooltip } from "@homebound/beam";
import { Maybe } from "src/generated/graphql-types";

export function PlanScheduleConfidence({
  probabilityBasisPoints,
  isComplete,
  entityType,
}: {
  probabilityBasisPoints: Maybe<number>;
  isComplete: boolean;
  entityType: string;
}) {
  if (isComplete)
    return (
      <Tooltip
        title={`Confidence for this ${entityType} is no longer estimated because the ${entityType} is complete.`}
      >
        <span css={Css.gray600.xsMd.$}>{"--"}</span>
      </Tooltip>
    );

  if (!probabilityBasisPoints) return null;

  if (probabilityBasisPoints >= 80_00) return <span css={Css.xsMd.$}>High</span>;
  if (probabilityBasisPoints >= 50_00) return <span css={Css.xsMd.yellow500.$}>Medium</span>;
  return <span css={Css.xsMd.red600.$}>Low</span>;
}
