import {
  BoundMultiSelectField,
  Button,
  ButtonMenu,
  Chip,
  Css,
  DateRangeFilterValue,
  FilterDefs,
  Filters,
  GridColumn,
  GridTable,
  IconButton,
  Palette,
  ScrollableContent,
  Tooltip,
  collapseColumn,
  column,
  dateColumn,
  dateRangeFilter,
  emptyCell,
  multiFilter,
  selectColumn,
  simpleHeader,
  singleFilter,
  useComputed,
  useGridTableApi,
  useModal,
  usePersistedFilter,
} from "@homebound/beam";
import { PageHeader } from "../layout/PageHeader";
import { groupBy, queryResult, safeEntries } from "src/utils";
import {
  Address,
  DateOperation,
  InputMaybe,
  InternalUserDetailFragment,
  LotType,
  NamedFragment,
  SaveWarrantyTicketInput,
  SaveWarrantyTicketItemInput,
  Scalars,
  WarrantyTicketDetailsFragment,
  WarrantyTicketFilter,
  WarrantyTicketItemDetailsFragment,
  WarrantyTicketItemStatus,
  WarrantyTicketItemStatusesFragment,
  WarrantyTicketPageDetailsFragment,
  WarrantyTicketStatus,
  WarrantyTicketStatusesFragment,
  useCurrentInternalUserQuery,
  useSaveWarrantyTicketItemsMutation,
  useSaveWarrantyTicketMutation,
  useWarrantyTicketFiltersQuery,
  useWarrantyTicketsQuery,
} from "src/generated/graphql-types";
import { WarrantyTicketItemStatusSelect } from "./components/WarrantyTicketItemStatusSelect";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { CommentCountBubble, SearchBox, dateCell } from "src/components";
import { createWarrantyTicketDetailsUrl } from "src/RouteUrls";
import { Link, useHistory } from "react-router-dom";
import { useMemo, useState } from "react";
import { CreateWarrantyTicketModal } from "./components/CreateWarrantyTicketModal";
import { differenceInCalendarDays } from "date-fns";
import { TableActions } from "../layout/TableActions";
import { DateOnly } from "src/utils/dates";
import { ChangeStatusModal } from "./components/ChangeStatusModal";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";

export function WarrantyPage() {
  const result = useWarrantyTicketFiltersQuery();
  return queryResult(
    result,
    ({ warrantyTicketItemStatusesDetail, warrantyTicketStatusesDetail, markets, internalUsers }) => (
      <WarrantyDataView
        warrantyTicketItemStatusesDetail={warrantyTicketItemStatusesDetail}
        warrantyTicketStatusesDetail={warrantyTicketStatusesDetail}
        markets={markets}
        teamMembers={internalUsers}
      />
    ),
  );
}

type WarrantyDataViewProps = {
  warrantyTicketItemStatusesDetail: WarrantyTicketItemStatusesFragment[];
  warrantyTicketStatusesDetail: WarrantyTicketStatusesFragment[];
  markets: NamedFragment[];
  teamMembers: InternalUserDetailFragment[];
};

function WarrantyDataView({
  warrantyTicketItemStatusesDetail,
  warrantyTicketStatusesDetail,
  markets,
  teamMembers,
}: WarrantyDataViewProps) {
  const { openModal } = useModal();
  const [saveWarrantyTicket] = useSaveWarrantyTicketMutation();
  const [saveWarrantyTicketItems] = useSaveWarrantyTicketItemsMutation();
  const history = useHistory();
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const tableApi = useGridTableApi<Row>();
  const selectedWarrantyTicketItemIds = useComputed(
    () => tableApi.getSelectedRows("warrantyTicketItem").map((r) => r.data.id),
    [tableApi],
  );

  const { data: currentInternalUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });
  const currentUser = currentInternalUserData?.currentInternalUser;

  const filterDefs: FilterDefs<WarrantyFilter> = useMemo(
    () => {
      const itemStatuses = multiFilter({
        label: "Item Status",
        options: warrantyTicketItemStatusesDetail,
        getOptionValue: (o) => o.code,
        getOptionLabel: (o) => o.name,
      });
      const marketIds = multiFilter({
        label: "Market",
        options: markets,
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => o.name,
      });

      const urgent = singleFilter({
        label: "Urgency",
        options: [
          { label: "Urgent", value: "true" },
          { label: "Not Urgent", value: "false" },
        ],
        getOptionValue: (o) => o.value,
        getOptionLabel: (o) => o.label,
      });

      const projectLotType = multiFilter({
        options: Object.values(LotType),
        getOptionValue: (v) => v,
        getOptionLabel: (v) => v,
      });

      const statuses = multiFilter({
        label: "Ticket Status",
        options: warrantyTicketStatusesDetail,
        getOptionValue: (o) => o.code,
        getOptionLabel: (o) => o.name,
        defaultValue: [WarrantyTicketStatus.NotStarted, WarrantyTicketStatus.InProgress],
      });

      const currentTeamMember = teamMembers.find((tm) => tm.id === currentUser?.id);
      const otherTeamMembers = teamMembers.filter((tm) => tm.id !== currentUser?.id);
      const teamMembersOptions = currentTeamMember ? [currentTeamMember, ...otherTeamMembers] : teamMembers;

      const assigneeIds = multiFilter({
        label: "Assigned to",
        options: teamMembersOptions,
        getOptionValue: (o) => o.id,
        getOptionLabel: ({ name, id }) => (id === currentUser?.id ? `${name} (Me)` : name),
        defaultValue: currentTeamMember ? [currentTeamMember.id] : undefined,
      });

      const itemCreatedDateRange = dateRangeFilter({
        label: "Date submitted",
      });

      return { itemStatuses, marketIds, urgent, itemCreatedDateRange, projectLotType, statuses, assigneeIds };
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [warrantyTicketItemStatusesDetail, markets, warrantyTicketStatusesDetail],
  );

  const { filter, setFilter } = usePersistedFilter<WarrantyFilter>({
    storageKey: "warranty-filter",
    filterDefs,
  });

  const query = useWarrantyTicketsQuery({
    variables: {
      filter: {
        ...filter,
        itemCreatedDateRange: filter.itemCreatedDateRange?.value
          ? {
              op: DateOperation.Between,
              value: new DateOnly(new Date(filter.itemCreatedDateRange.value.from!)),
              value2: new DateOnly(new Date(filter.itemCreatedDateRange.value.to!)),
            }
          : undefined,
        urgent: filter.urgent ? filter.urgent === "true" : undefined,
      },
      statuses: filter.itemStatuses,
      itemCreatedDateRange: filter.itemCreatedDateRange?.value
        ? {
            op: DateOperation.Between,
            value: new DateOnly(new Date(filter.itemCreatedDateRange.value.from!)),
            value2: new DateOnly(new Date(filter.itemCreatedDateRange.value.to!)),
          }
        : undefined,
    },
  });

  const { getFormState } = useFormStates<Partial<TicketFormInput>, WarrantyTicketDetailsFragment>({
    config: formConfig,
    map: (wt) => ({
      id: wt.id,
      assigneeIds: wt.assignees.map((a) => a.id),
      items: wt.items.map((wti) => ({
        id: wti.id,
        status: wti.status.code,
      })),
      urgent: wt.urgent,
    }),
    autoSave: async (os) => {
      await saveWarrantyTicket({
        variables: { input: os.changedValue },
      });
      os.commitChanges();
    },
    getId: (t) => t.id,
  });

  const columns = useMemo(
    () => createColumns(warrantyTicketItemStatusesDetail, getFormState, history, teamMembers),
    [getFormState, warrantyTicketItemStatusesDetail, history, teamMembers],
  );

  function openCreateWarrantyTicketModal() {
    openModal({
      content: <CreateWarrantyTicketModal />,
      size: "md",
    });
  }

  return queryResult(query, {
    data: ({ warrantyTickets }) => {
      return (
        <>
          <PageHeader title="Warranty" right={<Button label="Create New" onClick={openCreateWarrantyTicketModal} />} />
          <TableActions>
            <Filters filter={filter} onChange={setFilter} filterDefs={filterDefs} numberOfInlineFilters={2} />
            <div css={Css.mla.df.gap1.$}>
              <SearchBox onSearch={setSearchFilter} />
              <ButtonMenu
                items={[
                  {
                    label: "Change Status",
                    disabled: selectedWarrantyTicketItemIds.length === 0,
                    onClick: async () =>
                      openModal({
                        content: (
                          <ChangeStatusModal
                            warrantyTicketItemStatusesDetail={warrantyTicketItemStatusesDetail}
                            onConfirmAction={async (status) => {
                              await saveWarrantyTicketItems({
                                variables: {
                                  input: {
                                    warrantyTicketItems: selectedWarrantyTicketItemIds.map((id) => ({
                                      id,
                                      status,
                                    })),
                                  },
                                },
                              });

                              tableApi.clearSelections();
                            }}
                          />
                        ),
                      }),
                  },
                ]}
                trigger={{ label: "Actions All" }}
              />
            </div>
          </TableActions>
          <ScrollableContent virtualized>
            <GridTable
              columns={columns}
              rows={createRows(warrantyTickets)}
              sorting={{ on: "client", initial: ["actions", "DESC"] }}
              filter={searchFilter}
              api={tableApi}
            />
          </ScrollableContent>
        </>
      );
    },
  });
}

type HeaderRow = { kind: "header" };
type DevelopmentRow = {
  kind: "development";
  data: string;
};
type WarrantyTicketRow = {
  kind: "warrantyTicket";
  data: WarrantyTicketDetailsFragment;
};
type WarrantyTicketItemRow = {
  kind: "warrantyTicketItem";
  data: WarrantyTicketItemDetailsFragment & { ticket: WarrantyTicketDetailsFragment };
};
type Row = HeaderRow | DevelopmentRow | WarrantyTicketRow | WarrantyTicketItemRow;

function createRows(warrantyTickets: WarrantyTicketDetailsFragment[]) {
  const developmentGroups = groupBy(warrantyTickets, (wt) => wt.project.cohort?.development?.name!);

  return [
    simpleHeader,
    ...safeEntries(developmentGroups).map(([development, warrantyTickets]) => ({
      kind: "development" as const,
      data: development,
      id: development,
      children: warrantyTickets.map((wt) => ({
        kind: "warrantyTicket" as const,
        data: wt,
        id: wt.id,
        children:
          wt.items.length === 0
            ? undefined
            : wt.items.map((wti) => ({
                kind: "warrantyTicketItem" as const,
                data: { ...wti, ticket: wt },
                id: wti.id,
              })),
      })),
    })),
  ];
}

function getFormattedAddress(address: Pick<Address, "street1" | "street2">) {
  return address.street2 ? `${address.street1}, ${address.street2}` : address.street1;
}

const createColumns = (
  warrantyTicketItemStatusesDetail: WarrantyTicketItemStatusesFragment[],
  getFormState: (wt: WarrantyTicketDetailsFragment) => ObjectState<Partial<TicketFormInput>>,
  history: ReturnType<typeof useHistory>,
  teamMembers: InternalUserDetailFragment[],
): GridColumn<Row>[] => [
  collapseColumn<Row>(),
  selectColumn<Row>({ w: "24px" }),
  column<Row>({
    header: "Project",
    development: (development) => ({
      content: development,
      css: Css.baseSb.$,
    }),
    warrantyTicket: (wt) => ({
      content: wt.project.buildAddress && getFormattedAddress(wt.project.buildAddress),
      css: Css.smMd.$,
    }),
    warrantyTicketItem: (wti) => ({
      content: wti.ticket.project.buildAddress && getFormattedAddress(wti.ticket.project.buildAddress!),
      css: Css.xs.plPx(24).$,
    }),
  }),
  column<Row>({
    header: "Name",
    development: emptyCell,
    warrantyTicket: (wt) => ({
      typeScale: "smSb",
      content: <Link to={{ pathname: createWarrantyTicketDetailsUrl(wt.id) }}>{wt.title}</Link>,
      css: Css.smMd.$,
      value: wt.title,
    }),
    warrantyTicketItem: emptyCell,
  }),
  column<Row>({
    header: "Ticket Type",
    development: emptyCell,
    clientSideSort: false,
    warrantyTicket: (wt) => ({ content: wt.type?.name, css: Css.sm.$ }),
    warrantyTicketItem: (wti) => ({ content: wti.type.name, css: Css.sm.$ }),
  }),
  column<Row>({
    mw: "140px",
    header: "Status",
    development: emptyCell,
    clientSideSort: false,
    warrantyTicket: (wt) => ({
      content: `${wt.totalCompletedItems} of ${wt.totalAvailableItems} Completed`,
      css: Css.sm.$,
    }),
    warrantyTicketItem: (wti) => {
      const os = getFormState(wti.ticket);
      const itemState = os.items.rows.find((row) => row.id.value === wti.id)!;
      return (
        <WarrantyTicketItemStatusSelect options={warrantyTicketItemStatusesDetail} statusField={itemState.status} />
      );
    },
  }),
  column<Row>({
    header: "Duration",
    development: emptyCell,
    align: "center",
    warrantyTicket: (wt) => {
      const chip = getWarrantyTicketDurationChip(wt);
      return chip ? chip : emptyCell;
    },
    warrantyTicketItem: (wti) => {
      const chip = getWarrantyItemDurationChip(wti);
      return chip ? chip : emptyCell;
    },
  }),
  column<Row>({
    header: "Description",
    development: emptyCell,
    warrantyTicket: emptyCell,
    clientSideSort: false,
    warrantyTicketItem: (wti) => ({
      content: wti.description,
      css: Css.sm.truncate.$,
    }),
  }),
  dateColumn<Row>({
    header: "Date Submitted",
    development: emptyCell,
    warrantyTicket: (wt) => dateCell(wt.createdAt),
    warrantyTicketItem: (wti) => dateCell(wti.createdAt),
  }),
  dateColumn<Row>({
    header: "Last Updated",
    development: emptyCell,
    warrantyTicket: (wt) => dateCell(wt.updatedAt),
    warrantyTicketItem: (wti) => dateCell(wti.updatedAt),
  }),
  column<Row>({
    mw: "180px",
    header: "Assignees",
    clientSideSort: false,
    development: emptyCell,
    warrantyTicket: (wt) => {
      const os = getFormState(wt);
      return (
        <BoundMultiSelectField
          label="Assignees"
          labelStyle="hidden"
          field={os.assigneeIds}
          options={teamMembers}
          fieldDecoration={internalUserAvatar}
          getOptionMenuLabel={internalUserMenuLabel}
        />
      );
    },
    warrantyTicketItem: emptyCell,
  }),
  column<Row>({
    id: "actions",
    header: "Actions",
    w: "140px",
    sticky: "right",
    development: emptyCell,
    warrantyTicket: (wt) => {
      const { streams } = wt;
      const os = getFormState(wt);
      return {
        content: (
          <div css={Css.df.aic.gap1.jcfe.$}>
            <Tooltip title={os.urgent.value ? "Urgent" : "Not Urgent"}>
              <div>
                <IconButton
                  icon={os.urgent.value ? "flag" : "outlineFlag"}
                  color={os.urgent.value ? Palette.Red600 : undefined}
                  onClick={() => os.urgent.set(!os.urgent.value)}
                />
              </div>
            </Tooltip>
            <CommentCountBubble streams={streams} onClick={() => history.push(createWarrantyTicketDetailsUrl(wt.id))} />
          </div>
        ),
        value: os.urgent.value,
      };
    },
    warrantyTicketItem: emptyCell,
  }),
];

type WarrantyFilter = Omit<
  WarrantyTicketFilter,
  "projectId" | "homeownerVisible" | "urgent" | "itemCreatedDateRange"
> & {
  urgent: InputMaybe<Scalars["String"]>;
  itemCreatedDateRange: DateRangeFilterValue<string>;
};

type ItemFormInput = Pick<SaveWarrantyTicketItemInput, "id" | "status">;
type TicketFormInput = {
  id: SaveWarrantyTicketInput["id"];
  items: ItemFormInput[];
  urgent: SaveWarrantyTicketInput["urgent"];
  assigneeIds: SaveWarrantyTicketInput["assigneeIds"];
};

const formConfig: ObjectConfig<Partial<TicketFormInput>> = {
  id: { type: "value" },
  items: {
    type: "list",
    config: {
      id: { type: "value" },
      status: { type: "value" },
    },
  },
  urgent: { type: "value" },
  assigneeIds: { type: "value" },
};

export function getWarrantyItemDurationChip(wti: WarrantyTicketItemDetailsFragment) {
  // check wti item exist
  if (!wti) {
    return undefined;
  }
  // if completed or reject return undefined
  if (wti.status.code === WarrantyTicketItemStatus.Completed || wti.status.code === WarrantyTicketItemStatus.Rejected) {
    return undefined;
  }
  // find the diff time last updated and current date
  const diffDays = differenceInCalendarDays(new Date(), wti.statusUpdatedAt!);
  // if current status Submitted, and accepted have the same colors
  if (wti.status.code === WarrantyTicketItemStatus.Submitted || wti.status.code === WarrantyTicketItemStatus.Accepted) {
    const chipType = () => {
      if (diffDays <= 1) {
        return "success";
      } else if (diffDays <= 3) {
        return "caution";
      } else {
        return "warning";
      }
    };

    return {
      content: <Chip text={`${diffDays}d`} type={chipType()} />,
      sortValue: diffDays,
    };
  } else if (
    wti.status.code === WarrantyTicketItemStatus.OnHold ||
    wti.status.code === WarrantyTicketItemStatus.Scheduled
  ) {
    const chipType = () => {
      if (diffDays <= 7) {
        return "success";
      } else if (diffDays <= 14) {
        return "caution";
      } else {
        return "warning";
      }
    };
    return {
      content: <Chip text={`${diffDays}d`} type={chipType()} />,
      sortValue: diffDays,
    };
  }
  return undefined;
}

export function getWarrantyTicketDurationChip(wt: WarrantyTicketDetailsFragment | WarrantyTicketPageDetailsFragment) {
  // if no items return undefined
  if (wt.items.length === 0) {
    return undefined;
  }
  // check if all items are completed or rejected then the duration will be difference between created and last updated
  if (
    wt.items.every(
      (item) =>
        item.status.code === WarrantyTicketItemStatus.Completed ||
        item.status.code === WarrantyTicketItemStatus.Rejected,
    )
  ) {
    // find the last updated item
    const lastUpdatedItem = wt.items.reduce((prev, current) =>
      prev.updatedAt > current.statusUpdatedAt! ? prev : current,
    );
    const diffDays = differenceInCalendarDays(lastUpdatedItem.statusUpdatedAt, wt.createdAt);
    return {
      content: <Chip text={`${diffDays}d`} type="success" />,
      sortValue: diffDays,
    };
  }
  // else the duration will be difference between created and current date
  const diffDays = differenceInCalendarDays(new Date(), wt.createdAt);
  return {
    content: <Chip text={`${diffDays}d`} type="caution" />,
    sortValue: diffDays,
  };
}
