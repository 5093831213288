import {
  BoundTextAreaField,
  BoundTextField,
  Button,
  Chips,
  Css,
  SuperDrawerHeader,
  TabsWithContent,
  useSnackbar,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { addBusinessDays } from "date-fns";
import { useState } from "react";
import { ChangeEventReasonsBoundSelectField } from "src/components/autoPopulateSelects/ChangeEventReasonsBoundSelectField";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import {
  ApprovalSubject_ChangeEventFragment,
  SaveApprovalInput,
  SaveChangeEventInput,
  useApprovalChangeEventQuery,
  useApprovalSuperDrawer_SaveApprovalMutation,
  useSaveApprovalChangeEventMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { ApprovalApprovers } from "../ApprovalApprovers";
import {
  ApprovalOverviewTradesList,
  approvalTabXss,
  OverviewForm,
  OverviewFormGap,
  OverviewFormLabel,
  OverviewLeft,
  OverviewRight,
  OverviewWrapper,
  SubjectCreateApprovalProps,
} from "../approvalAtoms";
import { ApprovalSummary } from "../ApprovalSummary";
import { ApprovalDrawerViews, useApprovalContext } from "../ApprovalSuperDrawer";
import { ApprovalChangeEventBreakDown } from "./ApprovalChangeEventBreakDown";
import { ApprovalChangeEventImpactedPosTable } from "./ApprovalChangeEventImpactedPosTable";

export function CreateChangeEventApproval({ subjectId }: SubjectCreateApprovalProps) {
  const { setApprovalIds, setView } = useApprovalContext();
  const { closeDrawer } = useSuperDrawer();
  const { triggerNotice } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState("overview");
  // Project CE page might give us enough data to not require this query
  const query = useApprovalChangeEventQuery({ variables: { changeEventIds: [subjectId] } });
  const formState = useFormState({
    config: formConfig,
    init: {
      query,
      map: ({ changeEvents: [changeEvent] }) => ({
        // Just a default, but: 1 approver should take 1 day, 2 approvers should take 2 days, etc.
        dueOn: new DateOnly(addBusinessDays(new Date(), changeEvent.predictedApprovers.length || 1)),
        internalNote: changeEvent.internalNote,
        reasonId: changeEvent.reason.id,
        title: changeEvent.title,
      }),
    },
  });
  const [create] = useApprovalSuperDrawer_SaveApprovalMutation();
  const [updateChangeEvent] = useSaveApprovalChangeEventMutation();

  const onClick = async () => {
    const { dueOn, internalNote, reasonId, title } = formState.value;
    const changeEventId = query.data?.changeEvents.first?.id;

    await updateChangeEvent({
      variables: {
        input: {
          id: changeEventId,
          internalNote,
          reasonId,
          title,
        },
      },
    });

    const newApproval = await create({
      variables: {
        input: {
          dueOn,
          subjectId: changeEventId,
        },
      },
    });

    triggerNotice({
      message: `Approval Request Submitted For ${title}`,
      icon: "success",
    });

    // UX optimization - `Mark As` drawer will unmount and `View Approval Request` drawer will
    // take its place. We could close this drawer and open that one, or we can just trickle-up
    // the Approval ID and switch over to the Review view
    setApprovalIds([newApproval.data?.saveApproval.approval.id!].compact());
    setView(ApprovalDrawerViews.Review);
  };

  return queryResult(query, (data) => (
    <>
      <SuperDrawerHeader title={data.changeEvents.first?.displayName ?? ""} hideControls />
      <TabsWithContent
        tabs={[
          {
            name: "Overview",
            value: "overview",
            render: () => <CreateChangeEventApprovalForm changeEvents={data.changeEvents} formState={formState} />,
          },
          {
            name: "Change Breakdown",
            value: "changebreakdown",
            render: () => <ApprovalChangeEventBreakDown changeEventId={subjectId} />,
          },
          {
            name: "Impacted Trades",
            value: "impactedtrades",
            render: () => <ApprovalChangeEventImpactedPosTable changeEventId={subjectId} />,
          },
        ]}
        selected={selectedTab}
        onChange={setSelectedTab}
        includeBottomBorder
        contentXss={approvalTabXss(selectedTab)}
      />
      <div css={Css.absolute.bottom0.p2.pr5.bt.bcGray200.df.fdr.jcfe.w100.bgWhite.$}>
        <div css={Css.df.fdr.jcfe.gap1.$}>
          <Button label="Cancel" variant="tertiary" onClick={closeDrawer} />
          <Button label="Submit" onClick={onClick} />
        </div>
      </div>
    </>
  ));
}

type CreateChangeEventApprovalFormProps = {
  changeEvents: ApprovalSubject_ChangeEventFragment[];
  formState: ObjectState<FormValue>;
};

function CreateChangeEventApprovalForm({ changeEvents, formState }: CreateChangeEventApprovalFormProps) {
  return (
    <OverviewWrapper>
      <OverviewLeft>
        <ApprovalSummary subjects={changeEvents} />
        <OverviewForm>
          <OverviewFormLabel>Request Name</OverviewFormLabel>
          <BoundTextField field={formState.title} labelStyle="hidden" />
          <OverviewFormLabel>Due on</OverviewFormLabel>
          <BoundBeamDateField field={formState.dueOn} labelStyle="hidden" disabledDays={{ before: new Date() }} />
          <OverviewFormLabel>Reason code </OverviewFormLabel>
          <ChangeEventReasonsBoundSelectField field={formState.reasonId} labelStyle="hidden" />
          <OverviewFormLabel>Note</OverviewFormLabel>
          <BoundTextAreaField label="Note" field={formState.internalNote} labelStyle="hidden" />
          <span />
          <OverviewFormGap />
          <ApprovalOverviewTradesList changeEvents={changeEvents} />
          <OverviewFormLabel>Impacted CCs</OverviewFormLabel>
          <Chips
            values={changeEvents
              .flatMap((ce) => ce.lineItems)
              .map((li) => li.projectItem.item.fullCode)
              .unique()}
            compact
          />
        </OverviewForm>
      </OverviewLeft>
      <OverviewRight>
        <ApprovalApprovers subjects={changeEvents} />
      </OverviewRight>
    </OverviewWrapper>
  );
}

type FormValue = Pick<SaveApprovalInput, "dueOn"> & Pick<SaveChangeEventInput, "reasonId" | "internalNote" | "title">;

const formConfig: ObjectConfig<FormValue> = {
  dueOn: { type: "value" },
  internalNote: { type: "value" },
  reasonId: { type: "value" },
  title: { type: "value" },
};
