import { ReactElement } from "react";
import { Price } from "src/components";
import { LienWaiverPdfQuery, PandaDocRole, TradePartnerMarketRole } from "src/generated/graphql-types";
import { TextField } from "../components/PandaDocField";

type LienWaiverPdf = LienWaiverPdfQuery["lienWaiver"];

export const invoiceNumber = ({ parent }: LienWaiverPdf): ReactElement => {
  return <span data-testid="invoiceNumber">{parent?.invoiceNumber ?? ""}</span>;
};

export const throughDate = ({ throughDate }: LienWaiverPdf): ReactElement => {
  return <span>{throughDate?.toLocaleDateString() ?? ""}</span>;
};

export const amountInCents = ({ amountInCents }: LienWaiverPdf): ReactElement => {
  return (
    <span>
      <Price valueInCents={amountInCents ?? 0} />
    </span>
  );
};

export const payerName = ({ payer }: LienWaiverPdf, dataId: string): ReactElement => {
  return <span data-testid={dataId}>{payer.name}</span>;
};

export const claimantName = ({ claimant }: LienWaiverPdf, dataId: string): ReactElement => {
  return <span data-testid={dataId}>{claimant.name}</span>;
};

export const buildAddress = (lienWaiver: LienWaiverPdf): ReactElement => {
  const buildAddress = lienWaiver.project.buildAddress;
  return (
    <span data-testid="buildAddress">{`${buildAddress.street1}, ${buildAddress.city}, ${buildAddress.state} ${buildAddress.postalCode}`}</span>
  );
};

export const owner = (lienWaiver: LienWaiverPdf, defaultName: string) => {
  const name =
    lienWaiver.project.primaryHomeowner?.fullName ?? lienWaiver.project.customerOwnerEntity?.name ?? defaultName;
  return <span data-testid="owner">{name}</span>;
};

export const getSignatoryName = (lienWaiver: LienWaiverPdf) => (
  <span data-testid="tpSignatoryName">{lienWaiver.signatory?.name}</span>
);

export const getTitleField = (prefix?: string) => <TextField prefix={prefix} role={PandaDocRole.Signatory} />;

export const getClaimantName = (prefix?: string) => <TextField prefix={prefix} role={PandaDocRole.Signatory} />;
