import {
  BoundNumberField,
  Button,
  Css,
  Icon,
  IconButton,
  StaticField,
  TabsWithContent,
  useRightPane,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useState } from "react";
import {
  BidPackageDetailPage_BidContractLineItemFragment,
  BidPackageItemTemplateItemFragment,
  Maybe,
  SaveBidContractLineItemInput,
  useEditBidLineItemMutation,
} from "src/generated/graphql-types";
import { setupPriceFields } from "src/routes/projects/selections/recalc";
import { Observer } from "mobx-react";

type EditBidRightPaneProps = {
  itemTemplateItem: BidPackageItemTemplateItemFragment;
  bidContractLineItem: BidPackageDetailPage_BidContractLineItemFragment;
  tradePartnerName: string;
  totalLinesImpacted: number;
};

type TabValue = "details" | "history";

export function EditBidRightPane({
  itemTemplateItem,
  bidContractLineItem,
  tradePartnerName,
  totalLinesImpacted,
}: EditBidRightPaneProps) {
  const { closeRightPane } = useRightPane();
  const [selectedTab, setSelectedTab] = useState<TabValue>("details");

  return (
    <div css={Css.p4.$}>
      <div css={Css.lgSb.mb1.$}>
        Edit Bid <span css={Css.gray700.$}>— {tradePartnerName}</span>
      </div>
      <div css={Css.absolute.right3.top3.$}>
        <IconButton data-testid="closePane" icon="x" onClick={closeRightPane} />
      </div>
      <TabsWithContent
        tabs={[
          {
            name: "Details",
            value: "details",
            render: () => (
              <DetailsTab
                itemTemplateItem={itemTemplateItem}
                bidContractLineItem={bidContractLineItem}
                totalLinesImpacted={totalLinesImpacted}
              />
            ),
          },
          { name: "History", value: "history", render: () => <HistoryTab /> },
        ]}
        selected={selectedTab}
        onChange={setSelectedTab}
      />
    </div>
  );
}

function DetailsTab({
  bidContractLineItem,
  itemTemplateItem,
  totalLinesImpacted,
}: Pick<EditBidRightPaneProps, "itemTemplateItem" | "bidContractLineItem" | "totalLinesImpacted">) {
  const { closeRightPane } = useRightPane();
  const [editBidLineItem] = useEditBidLineItemMutation();
  const formState = useFormState({
    config: formConfig,
    init: {
      input: { bidContractLineItem, itemTemplateItem },
      map: (data) => {
        return {
          id: data.bidContractLineItem.id,
          totalCostInCents: data.bidContractLineItem.totalCostInCents,
          calculatedTotalCost: data.bidContractLineItem.totalCostInCents * (data.itemTemplateItem.quantity || 1),
          quantity: data.itemTemplateItem.quantity || 1,
        };
      },
    },
    addRules(state) {
      // Recalc cost
      setupPriceFields(
        {
          totalCostInCents: state.calculatedTotalCost,
          unitCostInCents: state.totalCostInCents,
          quantity: state.quantity,
        },
        { canEditPrice: false },
      );
    },
  });

  return (
    <div>
      <div css={Css.xsSb.mb2.$}>Item Details:</div>
      <div css={Css.df.gap4.$}>
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.xsMd.$}>Code</div>
          <div css={Css.xs.$}>{itemTemplateItem.developmentDrop?.code || itemTemplateItem.item.costCode.number}</div>
        </div>
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.xsMd.$}>Name</div>
          <div css={Css.xs.$}>{itemTemplateItem.name}</div>
        </div>
      </div>
      <div css={Css.my4.w100.hPx(1).bgGray200.$} />
      <div css={Css.xsSb.mb2.$}>Change Details:</div>
      <div css={Css.df.gap1.$}>
        <div css={Css.df.fdc.gap1.w100.$}>
          <div css={Css.xsMd.gray700.$}>QTY</div>
          <StaticField labelStyle="hidden" label="" value={`${itemTemplateItem.quantity || "-"}`} />
        </div>
        <div css={Css.df.fdc.gap1.w100.$}>
          <div css={Css.xsMd.gray700.$}>UoM</div>
          <StaticField labelStyle="hidden" label="" value={itemTemplateItem.unitOfMeasure.shortName} />
        </div>
        <div css={Css.df.fdc.gap1.w100.$}>
          <div css={Css.xsMd.$}>Unit Cost</div>
          <BoundNumberField type="cents" compact field={formState.totalCostInCents} labelStyle="hidden" />
        </div>
        <div css={Css.df.fdc.gap1.w100.$}>
          <div css={Css.xsMd.$}>Total Cost</div>
          <BoundNumberField type="cents" compact field={formState.calculatedTotalCost} labelStyle="hidden" />
        </div>
      </div>

      {totalLinesImpacted > 1 && (
        <div css={Css.df.bgBlue100.p2.gap1.mt3.$}>
          <Icon icon="errorCircle" />
          <div>Updating this item will impact {totalLinesImpacted} other uses of the material code in this bid.</div>
        </div>
      )}

      <div css={Css.absolute.bottom0.left0.right0.bt.bcGray200.df.$}>
        <div css={Css.ma.py("18px").$}>
          <Observer>
            {() => (
              <Button
                label="Save Updates"
                disabled={!formState.canSave()}
                variant="text"
                onClick={async () => {
                  const { id, totalCostInCents } = formState.value;
                  await editBidLineItem({ variables: { input: { id, totalCostInCents } } });
                  closeRightPane();
                }}
              />
            )}
          </Observer>
        </div>
      </div>
    </div>
  );
}

type FormValues = Pick<SaveBidContractLineItemInput, "id" | "totalCostInCents"> & {
  calculatedTotalCost?: Maybe<number>;
  quantity?: Maybe<number>;
};

const formConfig: ObjectConfig<FormValues> = {
  id: { type: "value", rules: [required] },
  totalCostInCents: { type: "value", rules: [required] },
  calculatedTotalCost: { type: "value", rules: [required] },
  quantity: { type: "value" },
};

function HistoryTab() {
  return <div>History</div>;
}
