import { Autocomplete } from "@homebound/beam";
import { PresentationFieldProps } from "@homebound/beam/dist/components/PresentationContext";
import { useState } from "react";
import { ProjectAutocomplete_ProjectFragment, useProjectAutocompleteQuery } from "src/generated/graphql-types";
import { useDebounce } from "src/hooks";

export type ProjectAutocompleteFieldProps = PresentationFieldProps & {
  label?: string;
  onSelect: (selected: ProjectAutocomplete_ProjectFragment) => void;
  disabled?: boolean;
  autofillValue?: string;
  required?: boolean;
};

export function ProjectAutocompleteField(props: ProjectAutocompleteFieldProps) {
  const { onSelect, disabled, label = "Project", autofillValue, ...others } = props;
  const [search, setSearch] = useState<string>(autofillValue ?? "");
  const debouncedSearch = useDebounce(search);
  const { data: searchData, previousData: previousSearchData } = useProjectAutocompleteQuery({
    variables: { filter: { search: debouncedSearch }, page: { limit: 100 } },
    skip: !debouncedSearch,
    nextFetchPolicy: "cache-first",
  });

  return (
    <Autocomplete<ProjectAutocomplete_ProjectFragment>
      {...others}
      label={label}
      disabled={disabled}
      getOptionValue={(o) => o.id}
      getOptionLabel={(o) => o.name}
      onSelect={onSelect}
      onInputChange={(t) => setSearch(t ?? "")}
      value={search}
      options={searchData?.projectsPage.entities ?? previousSearchData?.projectsPage.entities ?? []}
    />
  );
}
