import {
  BoundCheckboxField,
  BoundRichTextField,
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  SubmitButton,
  useComputed,
  useSuperDrawer,
  useToast,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { AdminItemFragment, SaveItemInput, useSaveItemMutation } from "src/generated/graphql-types";
import { ObjectConfig, required, useFormState } from "src/utils/formState";

export type ItemEditorProps = {
  costCodes: { id: string; displayName: string; version: number }[];
  selectedItem: AdminItemFragment | undefined;
  unitsOfMeasure: { id: string; name: string }[];
  cutoffs: { id: string; name: string }[];
  // TODO: Remove once Material Catalog is released.
  // Adding "isMaterialCatalogEnabled" because the drawer opens outside of the Feature Flag Context Provider.
  isMaterialCatalogEnabled: boolean | undefined;
};

export function ItemEditorTab(props: ItemEditorProps) {
  const { costCodes, selectedItem, unitsOfMeasure, cutoffs } = props;
  const isNew = selectedItem === undefined;
  const { closeDrawer } = useSuperDrawer();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: selectedItem,
      map: (selectedItem) => {
        const { costCode, unitOfMeasure, cutoff, materialAttributeDimensions, ...others } = selectedItem;
        // We don't need to preserve the id attribute, the backend will handle it as an update
        return {
          ...others,
          costCodeId: costCode.id,
          unitOfMeasureId: unitOfMeasure.id,
          cutoffId: cutoff?.id,
        };
      },
    },
  });
  const [saveItem] = useSaveItemMutation();
  const { showToast } = useToast();

  const isSelection = useComputed(() => formState.isSelection.value, [formState]);
  const isItemsV2 = useComputed(() => {
    const ccId = formState.costCodeId.value;
    return costCodes.find((cc) => cc.id === ccId)?.version === 2;
  }, [formState]);

  return (
    <>
      <div css={Css.df.fdc.gap2.$}>
        <BoundTextField field={formState.name} />
        <BoundSelectField
          field={formState.costCodeId}
          options={costCodes}
          getOptionLabel={(o) => `${o.displayName} V${o.version}`}
          getOptionValue={(o) => o.id}
          // We don't currently support moving items across cost codes, although
          // it might actually work, and is something retool allows already? Not sure.
          readOnly={!isNew}
        />
        {isItemsV2 && <BoundTextField field={formState.materialCodePrefix} />}
        <BoundSelectField field={formState.unitOfMeasureId} options={unitsOfMeasure} readOnly={!isNew} />
        {!isItemsV2 && (
          <BoundCheckboxField
            field={formState.isSelection}
            onChange={(val) => {
              formState.isSelection.set(val);
              if (!val) formState.cutoffId.set(null);
            }}
          />
        )}
        {isSelection && (
          <BoundSelectField field={formState.cutoffId} options={[{ id: "", name: "Unselect" }, ...cutoffs]} />
        )}
        <BoundRichTextField field={formState.specifications} />

        <div css={Css.df.jcfe.wPx(550).gap1.$}>
          <Button label="Cancel" variant="tertiary" onClick={closeDrawer} />
          <SubmitButton
            form={formState}
            label="Save"
            onClick={async () => {
              if (formState.canSave()) {
                const result = await saveItem({ variables: { input: formState.value } });
                const userErrors = result.data?.saveItems?.userErrors;
                if (userErrors && userErrors.length > 0) {
                  showToast({ type: "error", message: userErrors.map((m) => m.message).join(", ") });
                } else {
                  closeDrawer();
                }
              }
            }}
          />
        </div>
      </div>
    </>
  );
}

type FormValue = SaveItemInput;

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  costCodeId: { type: "value", rules: [required] },
  materialCodePrefix: { type: "value", rules: [] },
  specifications: { type: "value" },
  unitOfMeasureId: { type: "value", rules: [required] },
  isSelection: { type: "value" },
  cutoffId: { type: "value" },
};
