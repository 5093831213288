import { ObjectState } from "@homebound/form-state";
import { BidPackageFormState } from "../AddBidPackagePage";
import {
  Accordion,
  BoundDateField,
  BoundMultiSelectField,
  BoundSelectField,
  BoundTextAreaField,
  Css,
  FormLines,
  StaticField,
} from "@homebound/beam";
import {
  NamedFragment,
  ProjectRole,
  ReviewBidPackageFragment,
  ReviewBidPackageGroupFragment,
  useReviewAndSendStepQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { Card } from "src/components/Card";

export function ReviewAndSendStep({ formState }: { formState: ObjectState<BidPackageFormState> }) {
  const query = useReviewAndSendStepQuery({
    variables: { bidPackageGroupId: formState.bidPackageGroupId.value! },
  });
  return queryResult(query, ({ developments, productOfferings, bidPackageGroup }) => (
    <ReviewAndSendStepView
      bidPackageGroup={bidPackageGroup}
      developments={developments}
      productOfferings={productOfferings.entities}
      formState={formState}
    />
  ));
}

type SelectBiddersStepViewProps = {
  developments: NamedFragment[];
  productOfferings: NamedFragment[];
  bidPackageGroup: ReviewBidPackageGroupFragment;
  formState: ObjectState<BidPackageFormState>;
};

export function ReviewAndSendStepView({
  bidPackageGroup,
  developments,
  productOfferings,
  formState,
}: SelectBiddersStepViewProps) {
  const firstDevelopment = bidPackageGroup.bidPackages[0]?.developments[0];
  const isHistoricalBid = bidPackageGroup.bidPackages[0]?.isHistoricalBid ?? false;
  const primaryContact = firstDevelopment?.teamMembers.find(
    (tm) => tm.projectRole.code === ProjectRole.PurchasingManager,
  )?.internalUser.name;
  const secondaryContact = firstDevelopment?.teamMembers
    .filter((tm) => [ProjectRole.PurchasingOne, ProjectRole.PurchasingTwo].includes(tm.projectRole.code))
    .map((tm) => tm.internalUser.name)
    .join(", ");

  return (
    <div css={Css.df.fdc.aic.$}>
      <div css={Css.df.fdc.aic.my6.$}>
        {isHistoricalBid ? (
          <div css={Css.xl3Sb.$}>Review and Save Historical Bid</div>
        ) : (
          <>
            <div css={Css.xl3Sb.$}>Review and Send to Trades</div>
            <div css={Css.base.pt2.$}>
              Be sure to review the information below is correct before sending to trades. We will generate an email
              from this content.
            </div>
          </>
        )}
      </div>

      <Card xss={Css.w50.mb3.$}>
        <Accordion defaultExpanded topBorder={false} title={<div css={Css.lgSb.$}>Package Overview</div>}>
          <FormLines labelStyle="left" compact width="full">
            <BoundSelectField
              label="Development"
              options={developments}
              field={formState.development}
              getOptionLabel={(d) => d.name ?? ""}
              getOptionValue={(d) => d.id}
            />
            <BoundMultiSelectField
              label="Product Offerings"
              field={formState.productOfferings}
              options={productOfferings}
            />
            {!isHistoricalBid && <BoundTextAreaField label="Invitation Text" field={formState.invitationText} />}
            {!isHistoricalBid && <BoundTextAreaField label="Bid Instruction" field={formState.bidInstructionsText} />}
          </FormLines>
        </Accordion>
      </Card>

      {!isHistoricalBid && (
        <Card xss={Css.w50.mb3.$}>
          <Accordion defaultExpanded topBorder={false} title={<div css={Css.lgSb.$}>Bid Timeline</div>}>
            <FormLines labelStyle="left" compact width="full">
              <BoundDateField field={formState.dueDate} label="Bids Due" labelStyle="left" compact />
              <BoundDateField field={formState.estimatedAwardDate} label="Bids Awarded" labelStyle="left" compact />
              <BoundDateField
                field={formState.estimatedConstructionDate}
                label="Est. Construction Start"
                labelStyle="left"
                compact
              />
              <BoundDateField field={formState.sendReminderDate} label="Send reminder" labelStyle="left" compact />
            </FormLines>
          </Accordion>
        </Card>
      )}

      {!isHistoricalBid && (
        <Card xss={Css.w50.mb3.$}>
          <Accordion defaultExpanded topBorder={false} title={<div css={Css.lgSb.$}>Package Contacts</div>}>
            <FormLines labelStyle="left" compact width="full">
              <StaticField label="Primary Contact" value={primaryContact} />
              <StaticField label="Secondary Contact" value={secondaryContact} />
            </FormLines>
          </Accordion>
        </Card>
      )}

      {bidPackageGroup.bidPackages.length > 0 && (
        <Card xss={Css.w50.mb3.$}>
          {bidPackageGroup.bidPackages.map((bp, i) => (
            <BidPackageSection bidPackage={bp} first={i === 0} key={bp.id} />
          ))}
        </Card>
      )}
    </div>
  );
}

type BidPackageSectionProps = {
  first: boolean;
  bidPackage: ReviewBidPackageFragment;
};

function BidPackageSection({ bidPackage, first }: BidPackageSectionProps) {
  return (
    <Accordion defaultExpanded={first} topBorder={false} title={<div css={Css.lgSb.$}>{bidPackage.name}</div>}>
      <div css={Css.baseSb.mb1.$}>Bidders</div>
      <div css={Css.df.fdc.gap2.mb3.$}>
        {bidPackage.requests.map((request, i) => (
          <div key={request.id} css={Css.bgGray100.br8.p2.df.$}>
            <div css={Css.w("40%").$}>
              <div css={Css.smBd.mb2.$}>Bidder #{i + 1}</div>
              <div css={Css.baseSb.$}>{request.tradePartner.name}</div>
            </div>
            <div>
              <div css={Css.smMd.mbPx(4).$}>{request.primaryContact?.name}</div>
              <div css={Css.xsMd.mbPx(4).$}>
                {request.primaryContact?.officePhone || request.primaryContact?.mobilePhone}
              </div>
              <div css={Css.xsSb.$}>{request.primaryContact?.email}</div>
            </div>
          </div>
        ))}
      </div>
      {/* <div css={Css.baseSb.mb1.$}>Attached Bid Request</div> */}
      {/* TODO: Add bid request csv here */}
    </Accordion>
  );
}
