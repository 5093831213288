import omit from "lodash/omit";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { initPageSettings, PageSettings, toFirstAndOffset } from "src/components/Pagination";
import { ProductCatalogDetailsFragment, ProductFilter, useProductCatalogQuery } from "src/generated/graphql-types";
import useQueryString from "src/hooks/useQueryString";

type ProductCatalogState = {
  products: ProductCatalogDetailsFragment[];
  settings: ProductCatalogQueryParams;
  setSettings: Dispatch<SetStateAction<ProductCatalogQueryParams>>;
  setSearch: Dispatch<SetStateAction<string>>;
  loading: boolean;
  hasNextPage: boolean;
};

type ProductCatalogFilters = Omit<ProductFilter, "id" | "custom" | "item" | "promotionStatus">;

export type ProductCatalogQueryParams = Omit<ProductCatalogFilters, "name"> & PageSettings;

export function useProductCatalog(): ProductCatalogState {
  const [search, setSearch] = useState<string>("");
  const [settings, setSettings] = useQueryString<ProductCatalogQueryParams>({
    page: initPageSettings.page,
    perPage: initPageSettings.perPage,
  });
  const { page, perPage, ...filterInput } = settings;
  const { data, loading } = useProductCatalogQuery({
    variables: {
      filter: { ...omit(filterInput, "search"), name: search },
      ...toFirstAndOffset(page, perPage),
    },
  });

  const products = useMemo(() => data?.productsPage.products ?? [], [data]);
  const hasNextPage = useMemo(() => data?.productsPage.pageInfo.hasNextPage ?? false, [data]);

  return {
    products,
    settings,
    setSettings,
    setSearch,
    loading,
    hasNextPage,
  };
}
