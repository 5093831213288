import {
  actionColumn,
  Button,
  column,
  Css,
  emptyCell,
  GridDataRow,
  GridTable,
  IconButton,
  SelectField,
  simpleHeader,
  useModal,
} from "@homebound/beam";
import { useCallback, useMemo } from "react";
import {
  MaterialCatalog_ItemPlanTaskMappingFragment,
  useItemPlanTaskMappingsQuery,
  useInstallTasksPageQuery,
  InstallTasksPageQuery,
  useSaveItemPlanTaskMappingMutation,
  useDeleteItemPlanTaskMappingMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { createMaterialCatalogUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";
import { AddInstallTaskModal } from "./AddInstallTaskModal";

export function InstallTasksPage() {
  const query = useInstallTasksPageQuery();

  return queryResult(query, (data) => <InstallTasksView data={data} />);
}

function InstallTasksView({ data }: { data: InstallTasksPageQuery }) {
  const { openModal } = useModal();
  const [saveIPTM] = useSaveItemPlanTaskMappingMutation();
  const [deleteIPTM] = useDeleteItemPlanTaskMappingMutation();
  const { data: mappings, refetch } = useItemPlanTaskMappingsQuery({ variables: { page: { limit: 1000 } } });

  const saveInstallTask = useCallback(
    (id: string, taskId: string) => saveIPTM({ variables: { input: { id, taskId } } }),
    [saveIPTM],
  );

  const deleteInstallTask = useCallback(
    async (id: string) => {
      await deleteIPTM({ variables: { input: { id } } });
      await refetch();
    },
    [deleteIPTM, refetch],
  );

  const rows = useMemo(
    () => createRows(mappings?.itemPlanTaskMappingPage.entities ?? []),
    [mappings?.itemPlanTaskMappingPage.entities],
  );

  const columns = useMemo(() => {
    return [
      column<Row>({
        header: "Item Code",
        mapping: ({ item }) => item.displayName,
      }),
      column<Row>({
        header: "Location",
        mapping: ({ location }) => location?.name ?? "Any",
      }),
      column<Row>({
        header: "Install Task",
        mapping: ({ id, task }) => (
          <SelectField
            label="task"
            options={data.globalPlanTasks.entities}
            value={task?.id}
            onSelect={(v) => v && saveInstallTask(id, v)}
          />
        ),
      }),
      actionColumn<Row>({
        header: emptyCell,
        mapping: ({ id }) => <IconButton icon="trash" onClick={() => deleteInstallTask(id)} />,
      }),
    ];
  }, [data.globalPlanTasks.entities, saveInstallTask, deleteInstallTask]);

  return (
    <div>
      <PageHeader
        title="Install Tasks"
        backButton={createMaterialCatalogUrl()}
        right={
          <Button
            label="Add"
            variant="secondary"
            onClick={() => openModal({ content: <AddInstallTaskModal data={data} /> })}
          />
        }
      />

      <div css={Css.wPx(1000).$}>
        <GridTable columns={columns} rows={rows} />
      </div>
    </div>
  );
}

type HeaderRow = { kind: "header"; id: string };
type MappingRow = { kind: "mapping"; data: MaterialCatalog_ItemPlanTaskMappingFragment };
type Row = HeaderRow | MappingRow;

function createRows(mappings: MaterialCatalog_ItemPlanTaskMappingFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...mappings.map((m) => ({
      id: m.id,
      kind: "mapping" as const,
      data: m,
    })),
  ];
}
