import { createPlanPackageEditUrl } from "src/RouteUrls";
import { ArchivePlanPackageModal } from "src/routes/libraries/plan-package/details/components/ArchivePlanPackageModal";
import { CopyPlanPackageModal } from "src/routes/libraries/plan-package/details/components/CopyPlanPackageModal";
import { PlanPackageDetailsFragment, PlanPackageFragment, ReadyPlanStatus } from "src/generated/graphql-types";
import { ButtonMenu, useModal } from "@homebound/beam";

type PlanPackageButtonMenuProps = {
  planPackage: PlanPackageFragment | PlanPackageDetailsFragment;
  // Renders trigger as vertical dots icon
  iconTrigger?: boolean;
};

export function PlanPackageButtonMenu({ iconTrigger, planPackage }: PlanPackageButtonMenuProps) {
  const { openModal } = useModal();
  return (
    <ButtonMenu
      trigger={iconTrigger ? { icon: "verticalDots" } : { label: "Actions", variant: "secondary", size: "md" }}
      // TODO: Work with Product & Design to determine if this set of actions is correct.
      // Thoughts: There is no way to move back to "Draft" if plan is in another status.
      // Does it even make sense to have separate actions for changing status? Design should really take another look.
      items={[
        // TODO: Should this be disabled for archived plans?
        { label: "Edit", onClick: createPlanPackageEditUrl(planPackage.id) },
        {
          label: "Archive",
          onClick: () => openModal({ content: <ArchivePlanPackageModal planPackageId={planPackage.id} /> }),
          disabled: planPackage.status.code === ReadyPlanStatus.Archived ? "Plan is already Archived" : false,
        },
        {
          label: "Make a copy",
          onClick: () =>
            openModal({
              content: <CopyPlanPackageModal planPackage={planPackage} />,
              size: "lg",
            }),
        },
      ]}
    />
  );
}
