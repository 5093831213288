import {
  Button,
  Chips,
  column,
  Css,
  dateColumn,
  dateFilter,
  checkboxFilter,
  DateFilterValue,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  multiFilter,
  numericColumn,
  Pagination,
  RowStyles,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  Tag,
  usePersistedFilter,
} from "@homebound/beam";
import { useState } from "react";
import { dateCell, priceCell, SearchBox } from "src/components";
import { billStatusTagMapper } from "src/components/detailPane/bill/utils";
import {
  BillFilter,
  BillOrder,
  BillsQueryBillFragment,
  BillType,
  DateFilter2,
  FinancesPageSharedQuery,
  InputMaybe,
  Order,
  useBillsQuery,
} from "src/generated/graphql-types";
import { createBillPageUrl, createBillUrl } from "src/RouteUrls";
import { dateFilterOperations, pageSchema, queryResult } from "src/utils";
import { parseOrder, toOrder } from "src/utils/ordering";
import { sentenceCase } from "change-case";
import { TableActions } from "../layout/TableActions";
import { DateOnly } from "src/utils/dates";
import { financesDefaultFilter } from "../billing/FinancesPage";
import useZodQueryString from "src/hooks/useZodQueryString";
import { BillsCharts } from "./BillsCharts";

type BillsPageProps = {
  shared: FinancesPageSharedQuery;
};

export type BillsPageFilter = Omit<BillFilter, "dueDate" | "billDate"> & {
  dueDate: DateFilterValue<string>;
  billDate: DateFilterValue<string>;
};

export function BillsPage({ shared }: BillsPageProps) {
  const { markets, projects, internalUsers, tradePartners, currentInternalUser, enumDetails, customerOwnerEntities } =
    shared;
  const currentUserId = currentInternalUser?.id ?? "";
  const [order, setOrder] = useState<BillOrder>({ billDate: Order.Desc });
  const [pageSettings, setPageSettings] = useZodQueryString(pageSchema);
  const [search, setSearch] = useState("");
  const userOptions = [{ id: currentUserId, name: "Me" }, ...internalUsers.filter(({ id }) => id !== currentUserId)];

  const filterDefs: FilterDefs<BillsPageFilter> = {
    marketId: multiFilter({
      label: "Market",
      options: markets,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    projectId: multiFilter({
      label: "Project",
      options: projects,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    tradePartnerId: multiFilter({
      label: "Trade Partner",
      options: tradePartners,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    status: multiFilter({
      label: "Status",
      options: enumDetails.billStatus.map((status) => ({ value: status.code, name: status.name })),
      getOptionValue: (o) => o.value,
      getOptionLabel: (o) => o.name,
    }),

    pendingReview: checkboxFilter({
      label: "Pending Review",
    }),

    type: multiFilter({
      label: "Type",
      options: Object.values(BillType).map((type) => ({ value: type, name: sentenceCase(type) })),
      getOptionValue: (o) => o.value,
      getOptionLabel: (o) => o.name,
    }),

    customerOwnerEntityId: multiFilter({
      label: "Project Owner",
      options: customerOwnerEntities,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    createdBy: multiFilter({
      label: "Created By",
      options: internalUsers,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    assigneeId: multiFilter({
      label: "Approver",
      options: userOptions,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    dueDate: dateFilter({
      label: "Due",
      operations: dateFilterOperations,
      getOperationLabel: (o) => o.label,
      getOperationValue: (o) => o.value,
      defaultValue: financesDefaultFilter.dueDate,
    }),

    billDate: dateFilter({
      label: "Bill Date",
      operations: dateFilterOperations,
      getOperationLabel: (o) => o.label,
      getOperationValue: (o) => o.value,
    }),

    hasBalanceDue: checkboxFilter({
      label: "Balance Due",
    }),
  };

  const { setFilter, filter } = usePersistedFilter<BillsPageFilter>({
    storageKey: "financesBillFilter",
    filterDefs,
  });

  const query = useBillsQuery({ variables: { filter: { ...mapToFilter(filter), search }, order, page: pageSettings } });

  return queryResult(query, (data) => (
    <div>
      <BillsCharts filter={filter} search={search} />
      <div css={Css.df.mb2.$}>
        <div css={Css.lg.mya.$}>Bills</div>
        <div css={Css.mla.$}>
          <Button label="Create Bill" onClick={createBillPageUrl()} />
        </div>
      </div>
      <TableActions>
        <Filters<BillsPageFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <SearchBox onSearch={setSearch} />
      </TableActions>
      <ScrollableContent>
        <GridTable
          columns={columns}
          rows={createRows(data.billsPage.entities)}
          rowStyles={createRowStyles()}
          sorting={{
            on: "server",
            onSort: (key, direction) => setOrder(toOrder(key, direction)),
            value: parseOrder(order),
          }}
          style={{ rowHeight: "fixed", allWhite: true, bordered: true }}
          fallbackMessage="No bills found that matched given filters."
        />
        <Pagination page={[pageSettings, setPageSettings]} totalCount={data.billsPage.pageInfo.totalCount} />
      </ScrollableContent>
    </div>
  ));
}

type Row = SimpleHeaderAndData<BillsQueryBillFragment>;

function createRows(data: BillsQueryBillFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(data);
}

function createRowStyles(): RowStyles<Row> {
  return {
    header: {},
    data: { rowLink: ({ data }) => createBillUrl(data.id) },
  };
}

const columns: GridColumn<Row>[] = getColumns();

function getColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Project",
      data: ({ projectStage }) => projectStage.project.projectName,
      serverSideSortKey: "project",
      w: 1.8,
    }),
    column<Row>({
      header: "Trade Partner",
      data: ({ tradePartner }) => tradePartner.name,
      serverSideSortKey: "tradePartner",
      w: 1.8,
    }),
    column<Row>({
      header: "Bill Number",
      data: ({ tradePartnerNumber, isTradePartnerCredit }) =>
        isTradePartnerCredit ? `Credit #${tradePartnerNumber}` : `Bill #${tradePartnerNumber}`,
      w: 0.9,
    }),
    column<Row>({ header: "Bill Type", data: ({ type }) => type.name, w: 0.9 }),
    column<Row>({
      header: "Commitments",
      data: ({ parents }) => <Chips values={parents.map((p) => `#${p.accountingNumber}`)} />,
      serverSideSortKey: "commitment",
      w: 1.5,
    }),
    dateColumn<Row>({
      header: "Bill Date",
      data: ({ billDate }) => dateCell(billDate),
      serverSideSortKey: "billDate",
      w: 0.7,
    }),
    dateColumn<Row>({
      header: "Due Date",
      data: ({ dueDate }) => dateCell(dueDate),
      serverSideSortKey: "dueDate",
      w: 0.7,
    }),
    numericColumn<Row>({
      header: "Bill Amount",
      data: ({ billedInCents, isTradePartnerCredit }) =>
        priceCell({ valueInCents: isTradePartnerCredit ? -billedInCents : billedInCents }),
      serverSideSortKey: "billedInCents",
      w: 0.75,
    }),
    numericColumn<Row>({
      header: "Paid to Date",
      data: ({ paidInCents, isTradePartnerCredit }) =>
        priceCell({ valueInCents: isTradePartnerCredit ? -paidInCents : paidInCents }),
      serverSideSortKey: "paidInCents",
      w: 0.8,
    }),
    numericColumn<Row>({
      header: "Balance Due",
      data: ({ balanceInCents, isTradePartnerCredit }) =>
        priceCell({ valueInCents: isTradePartnerCredit ? -balanceInCents : balanceInCents }),
      serverSideSortKey: "balanceInCents",
      w: 0.75,
    }),
    dateColumn<Row>({
      header: "Paid Date",
      data: ({ paidDate }) => dateCell(paidDate),
      serverSideSortKey: "paidDate",
      w: 0.7,
    }),
    column<Row>({
      header: "Status",
      data: (row) => {
        const [billType, text] = billStatusTagMapper(row);
        return <Tag type={billType} text={text} />;
      },
      w: 1.2,
    }),
  ];
}

export function mapToFilter(filter: BillsPageFilter) {
  const { dueDate, billDate, ...others } = filter;
  return {
    ...others,
    ...(dueDate
      ? { dueDate: { op: dueDate.op, value: new DateOnly(new Date(dueDate.value)) } as InputMaybe<DateFilter2> }
      : {}),
    ...(billDate
      ? { billDate: { op: billDate.op, value: new DateOnly(new Date(billDate.value)) } as InputMaybe<DateFilter2> }
      : {}),
  };
}
