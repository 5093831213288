import React, { useCallback, useContext, useMemo, useState } from "react";
import { ProjectItemStore } from "../models/ProjectItemStore";
import { SpecsAndSelections_ProjectItemFragment } from "src/generated/graphql-types";

type ProjectItemsContextType = {
  initialize(
    projectItems: SpecsAndSelections_ProjectItemFragment[],
    groupBy: "costCode" | "tradeCategory" | "cutoff",
  ): void;
  store: ProjectItemStore;
};

const initialEmptyStore = new ProjectItemStore([], "costCode");

export const ProjectItemsContext = React.createContext<ProjectItemsContextType>({
  initialize: () => {},
  store: initialEmptyStore,
});

export function ProjectItemsContextProvider({ children }: React.PropsWithChildren<unknown>) {
  const [store, setStore] = useState<ProjectItemStore>(initialEmptyStore);

  const initialize = useCallback(
    (projectItems: SpecsAndSelections_ProjectItemFragment[], groupBy: "costCode" | "tradeCategory" | "cutoff") => {
      setStore(new ProjectItemStore(projectItems, groupBy));
    },
    [],
  );

  const contextValue: ProjectItemsContextType = useMemo(() => ({ initialize, store }), [initialize, store]);

  return <ProjectItemsContext.Provider value={contextValue}>{children}</ProjectItemsContext.Provider>;
}

export function useProjectItemsStore(): ProjectItemsContextType {
  return useContext(ProjectItemsContext);
}
