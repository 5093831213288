import { TextField } from "@homebound/beam";
import { useCallback, useState } from "react";
import { TaskCatalogFormSchedulingDropdownDocument, useAddStageModalMutation } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { SaveGlobalPlanTaskFormValue } from "./TaskCatalogForm";
import { ObjectState } from "@homebound/form-state";

type AddStageModalProps = {
  formState: ObjectState<SaveGlobalPlanTaskFormValue>;
};

export function AddStageModal({ formState }: AddStageModalProps) {
  const [saveGlobalPlanStage] = useAddStageModalMutation({});
  const [name, setName] = useState<string | undefined>("");

  const onSubmit = useCallback(async () => {
    if (!name) {
      return;
    }

    const { data } = await saveGlobalPlanStage({
      variables: {
        input: {
          name,
        },
      },
      refetchQueries: [TaskCatalogFormSchedulingDropdownDocument],
    });

    formState.stageId.set(data?.saveGlobalPlanStage?.globalPlanStage.id);
  }, [formState.stageId, name, saveGlobalPlanStage]);

  return (
    <ConfirmationModal
      title="Add a Stage"
      onConfirmAction={onSubmit}
      confirmationMessage={<TextField label="Add a new Stage" value={name} onChange={(val) => setName(val)} />}
      label="Add Stage"
    />
  );
}
