import {
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  Maybe,
  ProjectCollaboratorRole,
  SaveProjectCollaboratorInput,
  useSaveProjectCollaboratorMutation,
} from "src/generated/graphql-types";

type CreateHomeownerModalProps = {
  projectId: string;
  refetch?: () => void;
};

export function CreateHomeownerModal({ projectId, refetch }: CreateHomeownerModalProps) {
  const formState = useFormState({
    config: formConfig,
    init: {
      email: "",
      role: ProjectCollaboratorRole.Other,
    },
  });
  const [saveProjectCollaborator] = useSaveProjectCollaboratorMutation();
  const { closeModal } = useModal();

  return (
    <>
      <ModalHeader>Add homeowner</ModalHeader>
      <ModalBody>
        <div css={Css.mt3.df.fdc.gap2.$}>
          <div css={Css.dif.gap2.$}>
            <div css={Css.w100.$}>
              <BoundTextField field={formState.firstName} />
            </div>
            <div css={Css.w100.$}>
              <BoundTextField field={formState.lastName} />
            </div>
          </div>
          <BoundTextField field={formState.email} />
          <BoundSelectField
            options={[
              { code: ProjectCollaboratorRole.Homeowner, name: "Primary Homeowner" },
              { code: ProjectCollaboratorRole.Family, name: "Family Member" },
              { code: ProjectCollaboratorRole.Designer, name: "Designer" },
              { code: ProjectCollaboratorRole.Legal, name: "Legal" },
              { code: ProjectCollaboratorRole.Other, name: "Other" },
            ]}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.code}
            field={formState.role}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              disabled={!formState.valid}
              variant="primary"
              label="Add"
              onClick={async () => {
                const { role, firstName, lastName, email } = formState.value;
                await saveProjectCollaborator({
                  variables: {
                    input: {
                      projectId,
                      role: role!,
                      client: {
                        firstName,
                        lastName,
                        email: email!,
                      },
                    },
                  },
                });
                closeModal();
                refetch && refetch();
              }}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValue = Pick<SaveProjectCollaboratorInput["client"], "firstName" | "lastName"> & {
  role: Maybe<ProjectCollaboratorRole>;
  email: Maybe<string>;
};

const formConfig: ObjectConfig<FormValue> = {
  email: { type: "value", rules: [required] },
  firstName: { type: "value" },
  lastName: { type: "value" },
  role: { type: "value", rules: [required] },
};
