import { AddItemsFromExistingPlanModal } from "./AddItemsFromExistingPlanModal";
import { useMemo } from "react";
import { Button, ButtonMenu, Css, Icon, useComputed, useModal } from "@homebound/beam";
import {
  useTakeoffsManagerContext,
  useTakeoffsStore,
} from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { getDisabledReasons, pluralize } from "src/utils";
import { baseDownloadUrl } from "src/context";
import { DeleteItemsConfirmationModal } from "src/routes/libraries/plan-package/takeoffs/components/DeleteItemsConfirmationModal";
import { AddEditLaborItemModal, AddEditMaterialItemModal, AddEditPlaceholderItemModal } from "./AddItems";
import { BulkTaskAllocationModal } from "src/routes/libraries/plan-package/takeoffs/components/BulkTaskAllocationModal/BulkTaskAllocationModal";
import { DuplicateItemsConfirmationModal } from "./DuplicateItemsConfirmationModal";

export function TakeoffsItemsTableBulkActionsMenu() {
  const getSelectedItivIds = useTakeoffsStore((state) => state.getSelectedItivIds);
  const itemTableApi = useTakeoffsStore((state) => state.itemTableApi);

  const selectedItivIds = useComputed(() => getSelectedItivIds(), [getSelectedItivIds]);

  return (
    <div css={Css.df.aic.gap1.$}>
      {/* Only show if multiple items are selected per spec */}
      {selectedItivIds.length > 1 && (
        <Button
          data-testid="clearSelections"
          variant="tertiary"
          label={`${selectedItivIds.length} ${pluralize(selectedItivIds.length, "item")} selected`}
          endAdornment={<Icon icon="x" />}
          onClick={() => itemTableApi && itemTableApi.clearSelections()}
        />
      )}
      <ActionsMenuButton />
    </div>
  );
}

function ActionsMenuButton() {
  const store = useTakeoffsManagerContext();
  const { itemTemplate, getSelectedItivIds, copyFromTemplate, saveItiv } = store.getState();
  const { id: itemTemplateId, canEditLineItems } = itemTemplate;
  const selectedItivIds = getSelectedItivIds();
  const { openModal } = useModal();

  const items = useMemo(() => {
    const encodedItemTemplateId = encodeURIComponent(itemTemplateId);
    const filterString = selectedItivIds.nonEmpty
      ? `&filter=${encodeURIComponent(JSON.stringify({ id: selectedItivIds }))}`
      : "";

    return [
      {
        label: "Add Material",
        icon: "plus",
        onClick: () => {
          openModal({
            content: <AddEditMaterialItemModal takeoffsManagerStore={store} itemTemplateId={itemTemplateId} />,
          });
        },
        disabled: canEditLineItems.allowed === false ? getDisabledReasons(canEditLineItems) : false,
      },
      {
        label: "Add Placeholder",
        icon: "plus",
        onClick: () => {
          openModal({
            content: <AddEditPlaceholderItemModal takeoffsManagerStore={store} itemTemplateId={itemTemplateId} />,
          });
        },
        disabled: canEditLineItems.allowed === false ? getDisabledReasons(canEditLineItems) : false,
      },
      {
        label: "Add Labor",
        icon: "plus",
        onClick: () => {
          openModal({
            content: <AddEditLaborItemModal takeoffsManagerStore={store} itemTemplateId={itemTemplateId} />,
          });
        },
        disabled: canEditLineItems.allowed === false ? getDisabledReasons(canEditLineItems) : false,
      },
      {
        label: "Import items",
        icon: "upload",
        onClick: () => {
          openModal({
            content: (
              <AddItemsFromExistingPlanModal
                templateId={itemTemplateId}
                copyFromTemplate={copyFromTemplate}
                addItiv={saveItiv}
              />
            ),
          });
        },
        disabled: canEditLineItems.allowed === false ? getDisabledReasons(canEditLineItems) : false,
      },
      {
        label: "Delete",
        icon: "trash",
        onClick: () =>
          openModal({ content: <DeleteItemsConfirmationModal store={store} itemIdsToDelete={selectedItivIds} /> }),
        disabled:
          canEditLineItems.allowed === false
            ? getDisabledReasons(canEditLineItems)
            : selectedItivIds.isEmpty
              ? "Select items to delete"
              : false,
      },
      {
        label: "Download to .csv",
        icon: "download",
        onClick: `${baseDownloadUrl()}/csv?type=takeoff&id=${encodedItemTemplateId}${filterString}`,
      },
      {
        label: "Duplicate",
        icon: "duplicate",
        onClick: () => openModal({ content: <DuplicateItemsConfirmationModal store={store} /> }),
        disabled:
          canEditLineItems.allowed === false
            ? getDisabledReasons(canEditLineItems)
            : selectedItivIds.isEmpty
              ? "Select items to duplicate"
              : false,
      },
      {
        label: "Allocate Task",
        icon: "task",
        onClick: () => openModal({ content: <BulkTaskAllocationModal store={store} itemsToEdit={selectedItivIds} /> }),
        disabled:
          canEditLineItems.allowed === false
            ? getDisabledReasons(canEditLineItems)
            : selectedItivIds.isEmpty
              ? "No items selected"
              : false,
      },
    ];
  }, [canEditLineItems, copyFromTemplate, itemTemplateId, openModal, selectedItivIds, store, saveItiv]);

  return <ButtonMenu trigger={{ icon: "verticalDots" }} items={items} />;
}
