import {
  BoundNumberField,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  numericColumn,
  simpleHeader,
  TagType,
} from "@homebound/beam";
import { IComputedValue } from "mobx";
import { Price, priceCell, priceTotal } from "src/components";
import {
  Bill,
  BillDetailPane_BillFragment,
  BillDetailPane_CommitmentLineItemsFragment,
  BillDetailPane_DocumentFragment,
  BillStatus,
  BillType,
  InvoiceStatus,
  LienWaiverStatus,
  SaveBillInput,
  SaveBillLineItemInput,
} from "src/generated/graphql-types";
import { empty, foldEnum, isNumber, DeepPartial, formatList, TagTypeMapper } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { ObjectState } from "src/utils/formState";
import { BillPaneViews } from "./BillDetailPane";

/** Utils and Types for BillDetailsForm.tsx `Project Items` table */
export type ComputedTotals = IComputedValue<{
  thisBilledInCents: number;
  otherBilledInCents: number;
  unBilledInCents: number;
  costChangeInCents: number;
}>;

export type FormInput = Omit<
  SaveBillInput,
  "tradePartnerId" | "balanceInCents" | "documents" | "quickbooksId" | "lineItems" | "dueDate" | "billDate"
> & {
  documents: BillDetailPane_DocumentFragment[];
  lineItems: FormLineItem[];
  dueDate?: DateOnly | null;
  billDate?: DateOnly | null;
  paidInCents?: number | null;
};

type FormLineItem = Omit<SaveBillLineItemInput, "costCode"> &
  Pick<BillDetailPane_CommitmentLineItemsFragment, "billedInCents" | "costChangeInCents" | "owner"> & {
    displayName: string;
  };

type FormState = ObjectState<FormInput>;
type HeaderRow = { kind: "header" };
type LineItemRow = { kind: "lineItem"; data: FormState["lineItems"]["rows"][number] };
type ParentRow = { kind: "parent"; data: string };
type TotalRow = {
  kind: "totals";
  data: {
    thisBilledInCents: number;
    otherBilledInCents: number;
    unBilledInCents: number;
    costChangeInCents: number;
  };
};
export type Row = HeaderRow | LineItemRow | TotalRow | ParentRow;

type LienWaiverProps = {
  status: LienWaiverStatus | undefined;
  isLienWaiverReceiver: boolean;
  condition: string | undefined;
};

/** Calculate `otherBilled` and `unbilled`, `billed` based on formstate bill inputs */
export function calcLineItemValues(li: ObjectState<FormLineItem>, isCredit?: boolean) {
  const {
    billedInCents: { value: billedInCents = 0 },
    amountInCents: { value: amountInCents = 0 },
    costChangeInCents: { value: costChangeInCents = 0 },
    amountInCents: { originalValue: amountInCentsOriginal = 0 },
  } = li;

  const sign = isCredit ? -1 : 1;
  const otherBilledInCents = Number(billedInCents) - Number(amountInCentsOriginal) * sign;
  return {
    otherBilledInCents,
    thisBilledInCents: Number(amountInCents),
    unBilledInCents: Number(costChangeInCents) - (otherBilledInCents + Number(amountInCents) * sign),
  };
}

export function createLineItemColumns(formState: FormState): GridColumn<Row>[] {
  const isCredit = !!formState.isTradePartnerCredit.value;
  const label = isCredit ? "Credit" : "Bill";

  const costCodeColumn = column<Row>({
    header: () => emptyCell,
    parent: (data) => data,
    lineItem: ({ displayName }) => displayName.value,
    totals: () => ({ content: <div css={Css.tinyBd.$}>Totals</div> }),
    w: 1.8,
  });

  const costColumn = numericColumn<Row>({
    header: () => "Committed",
    parent: emptyCell,
    lineItem: ({ costChangeInCents }) => priceCell({ id: "cost", valueInCents: costChangeInCents.value }),
    totals: (row) => priceTotal({ valueInCents: row.costChangeInCents, id: "costTotal" }),
    w: 1.2,
  });

  const otherBillsColumn = numericColumn<Row>({
    header: () => "Other Bills",
    parent: emptyCell,
    lineItem: (data) =>
      priceCell({ id: "otherBills", valueInCents: calcLineItemValues(data, isCredit).otherBilledInCents }),
    totals: (row) => priceTotal({ valueInCents: row.otherBilledInCents, id: "otherBillsTotal" }),
    w: 1.2,
  });

  const unbilledColumn = numericColumn<Row>({
    header: () => "Unbilled",
    parent: emptyCell,
    lineItem: (data) =>
      priceCell({
        id: "unbilled",
        valueInCents: calcLineItemValues(data, isCredit).unBilledInCents,
      }),
    totals: (row) => priceTotal({ valueInCents: row.unBilledInCents, id: "unbilledTotal" }),
    w: 1.2,
  });

  const billAmountColumn = numericColumn<Row>({
    header: () => ({ alignment: "center", content: `${label} Amount` }),
    parent: emptyCell,
    lineItem: ({ amountInCents }) => (
      <BoundNumberField
        data-testid="amount"
        field={amountInCents}
        type="cents"
        errorMsg={formState.lineItems.touched ? formState.lineItems.errors.join(" ") : undefined}
      />
    ),
    totals: (row) => (
      <div css={Css.tinyBd.$}>
        <Price valueInCents={row.thisBilledInCents} />
      </div>
    ),
    w: 1.2,
  });

  return [costCodeColumn, costColumn, otherBillsColumn, unbilledColumn, billAmountColumn];
}

export function createLineItemRows(
  formState: FormState,
  totalsRowData: ComputedTotals,
  isChangeRequestedAutoBill: boolean = false,
): GridDataRow<Row>[] {
  const groupedParentsPOs = formState.lineItems.rows.groupBy((r) => String(r.owner.accountingNumber.value));
  const groupedParentRows = Object.keys(groupedParentsPOs).map((acctNumber) => {
    const childItemRows = groupedParentsPOs[acctNumber];
    return {
      kind: "parent" as const,
      id: String(acctNumber),
      data: `PO# ${acctNumber}`,
      children: childItemRows.uniqueByKey("commitmentLineItemId").map((r) => ({
        kind: "lineItem" as const,
        id: r.commitmentLineItemId.value!.toString(),
        data: r,
      })),
    };
  });
  const ungroupedRows = formState.lineItems.rows.map((row, i) => ({
    kind: "lineItem" as const,
    id: String(i),
    data: row,
  }));

  return [
    simpleHeader,
    // Only group by parent if we're displaying all active parents po/cco
    ...(isChangeRequestedAutoBill ? [...groupedParentRows] : [...ungroupedRows]),
    {
      kind: "totals" as const,
      id: "total",
      data: totalsRowData.get(),
    },
  ];
}

/** For new bills, hydrate the bill `Project items` tables with billable items from the parent commitment */
export function emptyInput(billParentLineItems: BillDetailPane_CommitmentLineItemsFragment[]): FormInput {
  return {
    ...empty<FormInput>(),
    documents: [],
    lineItems: billParentLineItems.map((li) => {
      return {
        id: undefined,
        amountInCents: undefined,
        displayName: li.projectItem.displayName,
        commitmentLineItemId: li.id,
        costChangeInCents: li.costChangeInCents,
        billedInCents: li.billedInCents,
        owner: li.owner,
      };
    }),
  };
}

export function mapToInput(
  formStateValue: FormInput,
  tradePartnerId: string,
  projectStageId: string,
  isNew?: boolean,
): SaveBillInput {
  // Drop postedDate, paidDate, paidInCents b/c they're all read only
  const { documents, dueDate, billDate, postedDate, paidDate, paidInCents, ...others } = formStateValue;
  return {
    ...others,
    projectStageId,
    tradePartnerId,
    documents: documents.map(({ id }) => id),
    dueDate: dueDate && new DateOnly(dueDate),
    billDate: billDate && new DateOnly(billDate),
    // Auto bills won't be created via a form, so safely assume bills _created_ via form are `Manual` types
    ...(isNew && { type: BillType.Standard }),
    lineItems: formStateValue.lineItems
      // Filter by lineItems that have a value entered for the bill
      .filter((li) => isNumber(li.amountInCents))
      .map((li) => ({
        id: li.id,
        commitmentLineItemId: li.commitmentLineItemId,
        amountInCents: li.amountInCents,
      })),
  };
}

export function mapToForm(
  bill: BillDetailPane_BillFragment,
  billParentLineItems: BillDetailPane_CommitmentLineItemsFragment[],
): FormInput {
  return {
    ...bill,
    type: bill.type.code,
    status: bill.status.code,
    lineItems: billParentLineItems.map((li) => {
      const billLineItem = bill.lineItems.find((bli) => bli.commitmentLineItem?.id === li.id);
      return {
        id: billLineItem?.id,
        amountInCents: billLineItem?.amountInCents,
        displayName: li.projectItem.displayName,
        billedInCents: li.billedInCents,
        commitmentLineItemId: li.id,
        costChangeInCents: li.costChangeInCents,
        owner: li.owner,
      };
    }),
  };
}

/** Bill pane action cta labels based on pane view */
export function getCtalabel(view: BillPaneViews, status?: BillStatus) {
  const changeRequestedBill = status === BillStatus.ChangesRequested;
  return foldEnum(view, {
    [BillPaneViews.Create]: "Create Draft Bill",
    [BillPaneViews.Edit]: changeRequestedBill ? "Resubmit For Approval" : "Submit For Approval",
    [BillPaneViews.View]: "Release Bill",
  });
}

export function billStatusTagMapper(
  params: Pick<BillDetailPane_BillFragment, "status" | "isProcessingReversal">,
): TagTypeMapper {
  const {
    status: { code: status },
    isProcessingReversal,
  } = params;

  // Show a custom status for bills that are in the process of being reversed
  if (isProcessingReversal) return ["caution", "PROCESSING REVERSAL"];

  return foldEnum(status, {
    [BillStatus.Draft]: ["info", BillStatus.Draft],
    [BillStatus.Unreleased]: ["warning", "UNRELEASED"],
    [BillStatus.ReleaseScheduled]: ["info", "RELEASE SCHEDULED"],
    [BillStatus.Cancelled]: ["neutral", "CANCELLED"],
    [BillStatus.PendingApproval]: ["caution", "PENDING APPROVAL"],
    [BillStatus.ChangesRequested]: ["warning", "CHANGE NEEDED"],
    [BillStatus.ReadyToPay]: ["neutral", "READY TO PAY"],
    [BillStatus.Paid]: ["success", "PAID"],
    [BillStatus.Reversed]: ["neutral", "REVERSED"],
    [BillStatus.Rejected]: ["neutral", "REJECTED"],
    [BillStatus.PendingConditionalLienWaiver]: ["caution", "PENDING COND. LW"],
    [BillStatus.PendingUnconditionalLienWaiver]: ["caution", "PAID - PENDING UNCOND. LW"],
    [BillStatus.PartiallyPaid]: ["neutral", "PARTIALLY PAID"],
  });
}

export function invoiceStatusTagMapper(status: InvoiceStatus): [type: TagType, label: string] {
  return foldEnum(status, {
    [InvoiceStatus.Draft]: ["info", InvoiceStatus.Draft],
    [InvoiceStatus.Requested]: ["caution", "PENDING APPROVAL"],
    [InvoiceStatus.ChangesRequested]: ["warning", "CHANGE NEEDED"],
    [InvoiceStatus.Approved]: ["neutral", "PROCESSING"],
    [InvoiceStatus.Paid]: ["success", "PAID"],
    [InvoiceStatus.Void]: ["neutral", "VOIDED"],
    [InvoiceStatus.Rejected]: ["warning", "REJECTED"],
  });
}

export function lienWaiverStatusTagMapper({
  status,
  condition,
  isLienWaiverReceiver,
}: LienWaiverProps): [type: TagType, label: string] {
  if (!status && !isLienWaiverReceiver) return ["neutral", "N/A"];
  if (!status) return ["neutral", "N/A"];
  const type: TagType = foldEnum(status, {
    [LienWaiverStatus.Draft]: "caution",
    [LienWaiverStatus.Uploaded]: "caution",
    [LienWaiverStatus.Sent]: "info",
    [LienWaiverStatus.PartiallySigned]: "neutral",
    [LienWaiverStatus.Signed]: "success",
    [LienWaiverStatus.Pending]: "caution",
  });
  return [type, `${condition} ${status}`];
}
export function getBillPrefix(bill: DeepPartial<Bill> | undefined, isCredit?: boolean) {
  // The bill or credit may not exist yet on a new bill page
  // we take an `isCredit` arg that comes from query params (based on user action to create a credit)
  if (!bill) return isCredit ? "Credit" : "Bill";
  return bill.isTradePartnerCredit || isCredit ? "Credit" : bill.type?.code === BillType.Deposit ? "Deposit" : "Bill";
} // -> "Credit" / "Deposit" / "Bill"

export function getBillLabel(bill: DeepPartial<Bill>, hideBillPrefix: boolean = true) {
  const isBill = getBillPrefix(bill) === "Bill";
  return isBill && hideBillPrefix
    ? `#${bill.tradePartnerNumber}`
    : `${getBillPrefix(bill)} #${bill.tradePartnerNumber}`;
} // -> "Credit #123" / "Deposit #123" / "Bill #123"

type BillParent = { __typename?: "Commitment" | "CommitmentChangeOrder"; accountingNumber: number };

export function getBillParentsLabel(billParents: BillParent[]) {
  return formatList(
    billParents.map(
      (parent) => `${parent.__typename === "CommitmentChangeOrder" ? `CO` : `PO`}#${parent.accountingNumber}`,
    ),
  );
} // -> "PO#123, PO#124 and CO#125"
