import { ButtonMenu, Css, ToggleButton, useModal } from "@homebound/beam";
import { Link } from "react-router-dom";
import { LotSummaryMetaDataQuery, useSubscribeToSequenceSheetMutation } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { createDevelopmentTeamMembersUrl, createLotSequenceSheetPdfUrl } from "src/RouteUrls";
import { openNewTab } from "src/utils/window";
import { DevelopmentForm } from "src/routes/developments/components/CreateOrUpdateDevelopmentModal";
import { developmentsPath } from "src/routes/routesDef";
import { CohortForm } from "src/routes/developments/components/cohorts/CreateOrUpdateCohortModal";

type LotSummaryPageHeaderProps = {
  isTeamMember: boolean | undefined;
  subscribedToUpdates: boolean;
  internalUserId: string;
  developmentId: string;
  development?: LotSummaryMetaDataQuery["development"];
  isReleaseToTradesPath: boolean;
};

export function LotSummaryPageHeader(props: LotSummaryPageHeaderProps) {
  const { isTeamMember, subscribedToUpdates, internalUserId, developmentId, development, isReleaseToTradesPath } =
    props;
  const { openModal } = useModal();

  function handleExport() {
    const filter = sessionStorage.getItem(`lotSequenceSheetTable_${developmentId}`);
    openNewTab(createLotSequenceSheetPdfUrl(developmentId, filter));
  }

  const [followSequenceSheet] = useSubscribeToSequenceSheetMutation();

  return (
    <PageHeader
      title={development?.name ?? "..."}
      xss={Css.mb0.$}
      breadcrumb={{ label: "All Developments", href: developmentsPath }}
      right={
        <>
          {(isTeamMember && (
            <span css={Css.tiny.add("fontStyle", "italic").$} data-testid={"subscribedThroughTeamMembers"}>
              Subscribed to updates through{" "}
              <Link to={createDevelopmentTeamMembersUrl(developmentId)}>Team Members</Link>
            </span>
          )) ||
            (internalUserId && (
              <ToggleButton
                icon="bell"
                label={subscribedToUpdates ? "Subscribed to updates" : "Subscribe to updates"}
                selected={subscribedToUpdates}
                data-testid="subscribeToUpdates"
                onChange={async () =>
                  await followSequenceSheet({
                    variables: {
                      developmentId,
                      internalUserId,
                      follow: !subscribedToUpdates,
                    },
                  })
                }
              />
            ))}
          <ButtonMenu
            items={[
              {
                label: "Export",
                disabled: isReleaseToTradesPath,
                onClick: handleExport,
              },
              {
                label: "Edit development",
                onClick: () => openModal({ content: <DevelopmentForm developmentId={developmentId} /> }),
              },
              {
                label: "Create new cohort",
                onClick: () =>
                  openModal({
                    content: <CohortForm developmentId={developmentId} marketId={development?.market.id} />,
                  }),
              },
            ]}
            trigger={{ label: "Actions" }}
          />
        </>
      }
    />
  );
}
