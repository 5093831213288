import { Button, Css, HbLoadingSpinner, Tag, useTestIds } from "@homebound/beam";
import { createProductOfferingConfigEditUrl } from "src/RouteUrls";
import { Price } from "src/components";
import {
  ConfigsComparePage_ConfigCostsFragment,
  useProductOfferingCardQuery,
  useSaveProductOfferingConfigMutation,
} from "src/generated/graphql-types";
import { PLAN_FALLBACK_IMG } from "src/routes/libraries/plan-package/takeoffs/utils";
import { useParams } from "react-router-dom";
import { ProductOfferingParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { openNewTab } from "src/utils/window";
import { StringParam, useQueryParams } from "use-query-params";

export function ProductOfferingCard({
  config,
}: {
  config: ConfigsComparePage_ConfigCostsFragment["productOfferingConfig"];
}) {
  // Use a seperate query for the offering card to avoid refetching the entire page when saving a base config,
  // this allows us to still rehydrate the card with the latest saved config results
  const query = useProductOfferingCardQuery({ variables: { id: config.id } });
  return queryResult(query, ({ productOfferingConfig }) => <ProductOfferingCardView config={productOfferingConfig} />);
}

function ProductOfferingCardView({
  config,
}: {
  config: ConfigsComparePage_ConfigCostsFragment["productOfferingConfig"];
}) {
  const { developmentId } = useParams<ProductOfferingParams>();
  const [{ configIds }] = useQueryParams({
    configIds: StringParam,
  });
  const [saveOfferingConfig, { loading }] = useSaveProductOfferingConfigMutation();
  const tid = useTestIds({});

  return !loading ? (
    <div css={Css.bgWhite.bshBasic.br8.df.fdc.p3.aic.jcc.my2.$} {...tid.offeringCard}>
      <div>
        <div css={Css.relative.$}>
          <img
            src={config.productOffering.coverAsset?.previewUrl ?? PLAN_FALLBACK_IMG}
            alt="offering asset"
            css={Css.hPx(184).wPx(316).ma.$}
          />
          <div css={Css.absolute.top1.left1.$}>
            {config.isBaseConfig && <Tag text={`${config.productOffering.name} base`} type="success" />}
          </div>
        </div>
      </div>

      <div css={Css.fdc.gap1.jcc.aic.mt2.add("textAlign", "center").rg1.$}>
        <div css={Css.xlSb.$}>{config.productOffering.name}</div>
        <div css={Css.lg.$}>{config.name}</div>
        <div css={Css.df.fdr.gap1.base.gray700.$}>
          Total Est Cost:
          <Price valueInCents={config?.totalCostInCents} />
        </div>
        <div css={Css.df.fdr.gap3.mt1.$}>
          <Button
            label="Edit"
            variant="text"
            onClick={createProductOfferingConfigEditUrl({
              productOfferingConfigId: config.id,
              productOfferingId: config.productOffering.id,
              developmentId,
              itemTemplateId: config.productOffering.latestTemplate.id,
              // return to the compare page with the same configs selected
              // after editing a config
              ...(configIds && { configIds: JSON.parse(configIds) }),
            })}
          />

          <div css={Css.bl.br.gray200.$} />

          <Button
            label="Set as Base"
            variant="text"
            onClick={async () => {
              await saveOfferingConfig({
                variables: {
                  input: { id: config.id, isBaseConfig: true },
                },
                refetchQueries: ["ProductOfferingCard"],
              });
            }}
            disabled={config.isBaseConfig}
          />
        </div>
      </div>
    </div>
  ) : (
    <HbLoadingSpinner />
  );
}
