import { StepperProvider, stepperBarHeight, useStepperContext } from "src/components/stepper";
import { Css, ScrollableParent, Step, useComputed } from "@homebound/beam";
import { OfferingDetailsStep } from "./components/1.OfferingDetailsStep";
import { PlanPackageStep } from "./components/2.PlanPackageStep";
import { OptionsStep } from "./components/4.OptionsStep";
import { ReviewStep } from "./components/5.ReviewStep";
import { DesignPackagesStep } from "./components/3.DesignPackagesStep";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ProductOfferingParams } from "src/routes/routesDef";
import { StringParam, useQueryParams } from "use-query-params";
import { createDevelopmentProductOfferingUrl, createProductOfferingsUrl } from "src/RouteUrls";
import { ExitIconButton } from "src/routes/developments/cost-mapping/components/ExitIconButton";
import { EditProductOfferingStep } from "./utils";
import { SaveProductOfferingInput } from "src/generated/graphql-types";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { isEmptyObject } from "src/utils";

export function AddProductOfferingPage() {
  return (
    <StepperProvider steps={initialSteps}>
      <EditProductOfferingView />
    </StepperProvider>
  );
}

export function EditProductOfferingView() {
  const { productOfferingId } = useParams<ProductOfferingParams>();
  const [{ devId }] = useQueryParams({
    devId: StringParam,
  });
  const history = useHistory();
  const { currentStep, setSteps } = useStepperContext();
  const [stepIndex, setStepIndex] = useState(0);

  const formState = useFormState({
    config: formConfig,
    init: {
      developmentId: devId,
      externalReadyPlanOptions: [],
    },
  });

  // Future steps being disabled is fully dependent on the form state
  useComputed(() => {
    if (formState.dirty && !isEmptyObject(formState.changedValue)) {
      setSteps((prevState) => [
        { ...prevState[0], disabled: false },
        {
          ...prevState[1],
          disabled: !formState.name.value || formState.name.value === "" || !formState.developmentId.value,
        },
        {
          ...prevState[2],
          disabled: !formState.planPackageId.changedValue,
        },
        {
          ...prevState[3],
          disabled: !formState.interiorDesignPackageId.changedValue || !formState.exteriorDesignPackageId.changedValue,
        },
        {
          ...prevState[4],
          disabled: formState.externalReadyPlanOptions.value.length === 0,
        },
      ]);
    }
  }, [formState]);

  // Once you have clicked next step and gone to the next step is when we mark the previous step as complete
  useEffect(() => {
    const currentStepIndex = initialSteps.findIndex((step) => step.value === currentStep.value);
    // Cache the current step to prevent re-rendering
    if (stepIndex === currentStepIndex) return;
    setStepIndex(currentStepIndex);
    setSteps((prevState) => [
      { ...prevState[0], state: currentStepIndex > 0 ? "complete" : prevState[0].state },
      { ...prevState[1], state: currentStepIndex > 1 ? "complete" : prevState[1].state },
      { ...prevState[2], state: currentStepIndex > 2 ? "complete" : prevState[2].state },
      { ...prevState[3], state: currentStepIndex > 3 ? "complete" : prevState[3].state },
      { ...prevState[4], state: currentStepIndex > 4 ? "complete" : prevState[4].state },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const step = useMemo(() => {
    switch (currentStep.value) {
      case EditProductOfferingStep.OFFERING_DETAILS:
        return <OfferingDetailsStep formState={formState} />;
      case EditProductOfferingStep.PLAN_PACKAGE:
        return <PlanPackageStep formState={formState} />;
      case EditProductOfferingStep.DESIGN_PACKAGES:
        return <DesignPackagesStep formState={formState} />;
      case EditProductOfferingStep.OPTIONS:
        return <OptionsStep formState={formState} />;
      case EditProductOfferingStep.REVIEW:
        return <ReviewStep formState={formState} />;
      default:
        return <OfferingDetailsStep formState={formState} />;
    }
  }, [currentStep.value, formState]);

  const isReviewStep = currentStep.value === EditProductOfferingStep.REVIEW;

  return (
    <ScrollableParent xss={Css.mbPx(stepperBarHeight).pxPx(60).$}>
      <div css={isReviewStep ? Css.dn.$ : Css.df.jcfe.gap4.pt4.pbPx(2).$}>
        <ExitIconButton
          showModal={false}
          onCloseConfirmed={() => {
            if (devId) {
              return productOfferingId
                ? history.push(createDevelopmentProductOfferingUrl(devId, productOfferingId))
                : history.push(createProductOfferingsUrl(devId));
            }
            return history.push(createProductOfferingsUrl());
          }}
        />
      </div>
      {step}
    </ScrollableParent>
  );
}

const INCOMPLETE = "incomplete" as const;
const initialSteps: Step[] = [
  { value: EditProductOfferingStep.OFFERING_DETAILS, label: "Offering Details", state: INCOMPLETE },
  { value: EditProductOfferingStep.PLAN_PACKAGE, label: "Plan Package", state: INCOMPLETE, disabled: true },
  { value: EditProductOfferingStep.DESIGN_PACKAGES, label: "Design Packages", state: INCOMPLETE, disabled: true },
  { value: EditProductOfferingStep.OPTIONS, label: "Options", state: INCOMPLETE, disabled: true },
  { value: EditProductOfferingStep.REVIEW, label: "Review", state: INCOMPLETE, disabled: true },
];

export const formConfig: ObjectConfig<SaveProductOfferingInput> = {
  name: { type: "value", rules: [required] },
  developmentId: { type: "value", rules: [required] },
  description: { type: "value" },
  planPackageId: { type: "value", rules: [required] },
  interiorDesignPackageId: { type: "value", rules: [required] },
  exteriorDesignPackageId: { type: "value", rules: [required] },
  externalReadyPlanOptions: {
    type: "list",
    rules: [required],
    config: {
      externalReadyPlanOptionId: { type: "value", rules: [required] },
      externalOptionDefaultsIfIds: { type: "value" },
    },
  },
};
