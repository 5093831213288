import { Css, Palette, Tooltip } from "@homebound/beam";
import { Maybe } from "src/generated/graphql-types";
import { differenceInBusinessDays } from "date-fns";
import { DateOnly } from "src/utils/dates";
import { numToStringWithSign } from "src/utils";
import { formatDate } from "src/components";

export function ScheduleDateVariance({ baseline, current }: { baseline: Maybe<DateOnly>; current: Maybe<DateOnly> }) {
  if (!baseline || !current) return null;

  const variance = differenceInBusinessDays(current, baseline);
  if (variance === 0) return null;

  const color = variance > 4 ? Palette.Red600 : variance > 0 ? Palette.Yellow500 : Palette.Green600;

  return (
    <div data-testid="scheduleDateVariance">
      <Tooltip title={`Baseline: ${formatDate(baseline, "long")}`}>
        <div css={Css.add("color", color).pl1.fw6.$}>{numToStringWithSign(variance)}</div>
      </Tooltip>
    </div>
  );
}
