import { SelectField } from "@homebound/beam";
import { useProductOfferingContext, useProductOfferingStore } from "../ProductOfferingScopeContext";
import { isItivOrderField } from "src/routes/libraries/plan-package/takeoffs/utils";
import { offeringItemTableSortByOptions } from "../utils";

// Copied from src/routes/libraries/plan-package/takeoffs/components/SortBySelectField.tsx
export function SortBySelectField() {
  const itemTableSortBy = useProductOfferingStore((state) => state.itemTableSortBy);
  const state = useProductOfferingContext();
  const setItemTableSortBy = state.getState().setItemTableSortBy;

  return (
    <SelectField
      compact
      label="Sort By"
      labelStyle="inline"
      getOptionLabel={(o) => o.name}
      getOptionValue={(o) => o.id}
      onSelect={(v) => v && isItivOrderField(v) && setItemTableSortBy(v)}
      options={offeringItemTableSortByOptions()}
      value={itemTableSortBy}
      sizeToContent
    />
  );
}
