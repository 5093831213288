import { Button, Css, FullBleed, RouteTabWithContent, Tabs, Tag, useModal } from "@homebound/beam";
import { createPlanPackageUrl } from "src/RouteUrls";
import { PageHeader } from "src/routes/layout/PageHeader";
import { PlanPackageDetailsFragment, ReadyPlanStatus } from "src/generated/graphql-types";
import { PublishPlanPackageModal } from "src/routes/libraries/plan-package/details/components/PublishPlanPackageModal";
import { PlanPackageButtonMenu } from "src/routes/libraries/plan-package/details/components/PlanPackageButtonMenu";

type PlanPackageHeaderProps = {
  planPackage: PlanPackageDetailsFragment;
  tabs: RouteTabWithContent[];
};

export function PlanPackageHeader({ planPackage, tabs }: PlanPackageHeaderProps) {
  const { openModal } = useModal();
  const { id, name, constructionDocumentCompletePercentage, status } = planPackage;

  return (
    <FullBleed>
      <div css={Css.bgWhite.bb.bw1.bcGray200.$}>
        {/* Max-width based on Figma designs */}
        <div css={Css.maxwPx(1156).mxa.$}>
          <PageHeader
            title={name}
            titleSize={"xl3Sb"}
            xss={Css.bn.mb0.$}
            left={
              <>
                {status.code === ReadyPlanStatus.Archived && <Tag text="Archived" type="warning" />}
                <span css={Css.smSb.gray800.$}>{constructionDocumentCompletePercentage}% CD</span>
              </>
            }
            right={
              <>
                <PlanPackageButtonMenu planPackage={planPackage} />
                {/* TODO: Work with Product & Design to determine when/how this can be Published */}
                {status.code !== ReadyPlanStatus.Active && (
                  <Button
                    size="md"
                    label="Publish"
                    onClick={() => openModal({ content: <PublishPlanPackageModal planPackageId={id} /> })}
                  />
                )}
              </>
            }
            breadcrumb={[{ label: "Plans", href: createPlanPackageUrl() }]}
          />
          <Tabs tabs={tabs} />
        </div>
      </div>
    </FullBleed>
  );
}
