import { BoundMultiSelectField, Css, IconButton, Icon, Palette, Chip, AvatarGroup } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { sentenceCase } from "change-case";
import { Observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { CommentEditor, CommentSaveFunction } from "src/components";
import { formatAttachments } from "src/components/boundAttachments/BoundAttachments";
import {
  CommentFeedTradePartnerDetailsFragment,
  CommentStreamVisibility,
  Inbox_CommentFragment,
  Inbox_CommentStreamFragment,
  InternalUser,
  Maybe,
  Scalars,
  useCommentStreamCommentsQuery,
  useDeleteCommentMutation,
  useMarkCommentStreamAsReadMutation,
  useSaveCommentMutation,
} from "src/generated/graphql-types";
import { inboxPaths } from "src/routes/routesDef";
import { sortBy } from "src/utils";
import { useIntersectionObserver } from "usehooks-ts";
import { CommentCard } from "./CommentCard";

export type InboxCommentStreamProps = {
  commentableId?: string;
  visibility: CommentStreamVisibility | undefined;
  tradePartners: CommentFeedTradePartnerDetailsFragment[];
  stream: Inbox_CommentStreamFragment | null | undefined;
  currentUser: InternalUser | null | undefined;
  goBack: () => void;
  onNewStream: (id: string) => void;
  displayCommentableNames?: boolean;
};

export function InboxCommentStream(props: InboxCommentStreamProps) {
  const {
    commentableId,
    stream,
    visibility,
    tradePartners,
    currentUser,
    goBack,
    onNewStream,
    displayCommentableNames,
  } = props;
  const streamVisibility = stream?.streamVisibility?.code ?? visibility;
  const streamId = stream?.id;
  const { data } = useCommentStreamCommentsQuery({ variables: { streamId: streamId! }, skip: !streamId });
  const [saveCommentMutation] = useSaveCommentMutation();
  const [deleteCommentMutation] = useDeleteCommentMutation();
  const [editingComment, setEditingComment] = useState<Inbox_CommentFragment | null>(null);
  const isInboxPage = !!useRouteMatch(inboxPaths.base);

  const lastCommentRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(lastCommentRef, { freezeOnceVisible: true });
  const isIntersecting = entry?.isIntersecting ?? false;
  const [markCommentStreamAsRead, { called: mutationFired }] = useMarkCommentStreamAsReadMutation();

  useEffect(() => {
    if (stream && stream?.unreadCount > 0 && isIntersecting && !mutationFired) {
      void markCommentStreamAsRead({ variables: { input: { id: stream.id } } });
    }
  }, [stream, isIntersecting, mutationFired, markCommentStreamAsRead]);

  useEffect(() => {
    // Wait for the right to left animation and then scroll into the last comment
    // need to add the typeof check for a test error
    const timeout = setTimeout(
      () =>
        typeof lastCommentRef.current?.scrollIntoView === "function" &&
        lastCommentRef.current?.scrollIntoView({ behavior: "smooth" }),
      800,
    );
    return () => clearTimeout(timeout);
  }, [data]);

  const formConfig: ObjectConfig<FormValue> = {
    tradePartnerContactIds: { type: "value" },
  };
  const form = useFormState({
    config: formConfig,
    addRules(state) {
      if (visibility === CommentStreamVisibility.Trades) {
        state.tradePartnerContactIds.rules.push(required);
      }
    },
  });

  const avatars = useMemo(() => {
    const users = [
      ...(stream?.commentable.followers.map(({ id, name, avatar }) => ({ id, name, src: avatar })) ?? []),
      ...(stream?.tradePartnerUsers.map(({ name, user }) => ({ id: user.id, name, src: user.avatarUrl })) ?? []),
    ].uniqueByKey("src");

    const [you, others] = users.partition((u) => u.id === currentUser?.id);
    return [...(you.nonEmpty ? [{ ...you.first!, name: "You" }] : []), ...others];
  }, [currentUser?.id, stream?.commentable.followers, stream?.tradePartnerUsers]);

  const tradePartnerContacts = useMemo(
    () =>
      tradePartners.flatMap((tp) => {
        const tradePartnerName = tp.name;
        const contacts = sortBy(tp.contacts, (c) => c.name);
        const contactOptions = contacts.map((c) => {
          const { id, name, email } = c;
          const emailText = email ? `<${email}>` : "(add email to contact)";
          const label = `${tradePartnerName} - ${name} ${emailText}`;
          return { id, email, name, label };
        });
        return contactOptions;
      }),
    [tradePartners],
  );

  // Write some small helpers around the actual mutations
  const saveComment: CommentSaveFunction = useCallback(
    async (text, html, attachments = [], opts, tags) => {
      const { commentId, commentStreamId, streamVisibility, tradePartnerContactIds } = opts;

      const { data } = await saveCommentMutation({
        variables: {
          input: {
            text,
            html,
            tradePartnerContactIds,
            commentStreamId,
            streamVisibility,
            id: commentId,
            commentableId,
            attachments: formatAttachments(attachments),
            internalUserTags: tags,
          },
        },
      });
      const newStreamId = data?.saveComment.comment?.stream.id;
      if (newStreamId) {
        onNewStream(newStreamId);
      }
      setEditingComment(null);
    },
    [saveCommentMutation, commentableId, onNewStream],
  );

  const deleteComment = useCallback(
    async (comment: Inbox_CommentFragment) => {
      await deleteCommentMutation({ variables: { input: { id: comment.id } } });
      if (data?.commentStream?.comments.length === 1) {
        goBack();
      }
    },
    [deleteCommentMutation, data?.commentStream?.comments.length, goBack],
  );

  const [isTPAR, projectName, entityName, entityUrl] = useMemo(() => {
    if (stream?.commentable.__typename === "TradePartnerAvailabilityRequest") {
      return [
        true,
        stream?.commentable.task.project?.name,
        stream.commentable.task.name,
        stream.commentable.task.blueprintUrl,
      ];
    }
    return [
      false,
      (stream?.commentable as any)?.project?.name,
      stream?.commentable.name,
      stream?.commentable.blueprintUrl,
    ];
    // Disabled the following rules bc stream.commentable.task could be undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream?.commentable.__typename]);

  const viewLink = useMemo(() => {
    if (!stream || isInboxPage || commentableId === stream.commentable.id) return;

    return (
      <Link
        css={Css.df.aic.gap1.$}
        to={isTPAR ? `${inboxPaths.base}?streamId=${stream.id}` : entityUrl!.path}
        target="_blank"
      >
        View {isTPAR ? " in Inbox" : ` ${sentenceCase(stream.commentable.__typename)}`}
        <Icon color={Palette.Blue700} icon="arrowFromLeft" />
      </Link>
    );
  }, [commentableId, entityUrl, isInboxPage, isTPAR, stream]);

  return (
    <div css={Css.bgWhite.br8.px2.pt1.df.fdc.gap1.h100.$}>
      <div css={Css.df.aic.jcsb.if(isInboxPage).bb.bcGray300.pb1.$}>
        <div css={Css.df.aic.jcc.gap1.fg1.$}>
          <div css={Css.df.aic.gap1.absolute.left0.$}>
            {isInboxPage ? (
              <>
                <IconButton inc={2} icon="arrowBack" onClick={goBack} />
                <div css={Css.df.fdc.gap1.$}>
                  <Link css={Css.lgSb.blue600.$} to={entityUrl!.path} target="_blank">
                    {entityName}
                  </Link>
                  <div css={Css.df.aic.gap1.smMd.$}>
                    <Icon icon="house" />
                    <span>{projectName}</span>
                  </div>
                </div>
              </>
            ) : (
              <button css={Css.df.aic.gap1.$} onClick={goBack}>
                <Icon inc={2} icon="arrowBack" />
                Back
              </button>
            )}
          </div>
          {isInboxPage && (
            <div css={Css.df.fdc.asc.$}>
              <div css={Css.df.fdc.aic.jcc.$}>
                <AvatarGroup avatars={avatars} />
                <span>{avatars.map((a) => a.name).join(avatars.length > 2 ? ", " : " and ")}</span>
              </div>
            </div>
          )}
        </div>
        {viewLink}
      </div>
      {!stream && visibility === CommentStreamVisibility.Trades && (
        <BoundMultiSelectField
          data-testid="tradePartnerContacts"
          label="Trade Partner"
          field={form.tradePartnerContactIds}
          options={tradePartnerContacts ?? []}
          getOptionValue={({ id }) => id}
          getOptionLabel={({ label }) => label}
          disabledOptions={tradePartnerContacts?.filter((tpc) => !tpc.email).map(({ id }) => id)}
        />
      )}
      {displayCommentableNames && (
        <div css={Css.df.gap1.$}>
          {projectName && <Chip text={projectName} title={projectName} />}
          {entityName && <Chip text={entityName} title={entityName} />}
        </div>
      )}
      {data?.commentStream?.comments.sortByKey("createdAt").map((comment, i) => (
        <div
          ref={i === (data?.commentStream?.comments.length ?? 0) - 1 ? lastCommentRef : undefined}
          key={comment.id}
          css={Css.bb.bcGray300.$}
        >
          <CommentCard
            comment={comment}
            isEditing={editingComment?.id === comment.id}
            editComment={() => setEditingComment(comment)}
            deleteComment={deleteComment}
            visibility={streamVisibility}
            currentUser={currentUser}
          />
        </div>
      ))}
      <Observer>
        {() => (
          <CommentEditor
            key={editingComment?.id}
            text={editingComment?.text}
            html={editingComment?.html}
            tags={editingComment?.tags.map((t) => t.internalUser.id)}
            attachments={editingComment?.attachments}
            commentableId={commentableId}
            streamVisibility={streamVisibility}
            forceDisable={!stream && !form.valid}
            onSave={(text, html, attachments, mentions) =>
              saveComment(
                text,
                html,
                attachments,
                {
                  commentId: editingComment?.id,
                  commentStreamId: stream?.id,
                  streamVisibility: streamVisibility,
                  tradePartnerContactIds: form.tradePartnerContactIds.value ?? [],
                },
                mentions,
              )
            }
            onCancel={() => setEditingComment(null)}
            submitText={editingComment !== null ? "Save" : "Add"}
            fullWidth
          />
        )}
      </Observer>
    </div>
  );
}

type FormValue = { tradePartnerContactIds?: Maybe<Array<Scalars["ID"]>> };
