import {
  BoundSelectField,
  Button,
  Css,
  HelperText,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  CostType,
  useAddEditItemModal_CreateNewLocationMutation,
  useAddEditPlaceholderItemModal_GetItemsLazyQuery,
} from "src/generated/graphql-types";
import { useCallback, useEffect, useState } from "react";
import {
  AddEditItemModalDataViewProps,
  AddEditItemObjectState,
  BoundTaskSelectField,
  CommonFields,
  FormItem,
  ItivData,
  PublishedTakeoffBanner,
  saveAddEditItem,
  useAddEditItemModalFormState,
  withGetItivQuery,
} from "./utils";
import { AddEditItemLocationsField } from "src/routes/libraries/plan-package/takeoffs/components/AddItems/AddEditItemLocationsField";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { SelectMaybeNewField } from "src/components/selectMaybeNew/SelectMaybeNewField";

export const AddEditPlaceholderItemModal = withGetItivQuery(AddEditPlaceholderItemModalDataView);

function AddEditPlaceholderItemModalDataView(props: AddEditItemModalDataViewProps) {
  const { itemTemplateId, initialItivData, takeoffsManagerStore } = props;
  const [saveLocation] = useAddEditItemModal_CreateNewLocationMutation();

  const { saveItiv, itemTemplate } = takeoffsManagerStore.getState();
  const { closeModal, addCanClose } = useModal();
  const isEditing = !!initialItivData?.id;
  const readOnly = itemTemplate.canEditLineItems.allowed === false;

  const formState = useAddEditItemModalFormState({
    initialItivData,
    readOnly,
    costType: CostType.Materials,
  });

  addCanClose(() => {
    // eslint-disable-next-line no-restricted-globals
    return formState.dirty ? confirm("You have unsaved changes. Are you sure you want to close?") : true;
  });

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>
            <span css={Css.lgSb.$}>
              {readOnly ? "Placeholder Details" : isEditing ? "Edit Placeholder" : "Add Placeholder"}
            </span>
          </ModalHeader>
          <ModalBody>
            {readOnly && <PublishedTakeoffBanner />}
            <div css={Css.df.fdc.gap2.pb3.$}>
              <PlaceholderFields
                itemTemplateId={itemTemplateId}
                initialItivData={initialItivData}
                readOnly={readOnly}
                formState={formState}
                isEditing={isEditing}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            {readOnly ? (
              <Button label="Close" onClick={closeModal} />
            ) : (
              <>
                <Button variant="tertiary" label="Cancel" onClick={closeModal} size="lg" />
                <Button
                  label="Save"
                  data-testid="saveBtn"
                  disabled={!formState.valid}
                  onClick={async () => {
                    await saveAddEditItem({
                      formState,
                      itemTemplateId,
                      saveItiv,
                      saveLocation,
                      isPlaceholderItem: true,
                    });
                    closeModal();
                  }}
                  size="lg"
                />
              </>
            )}
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type PlaceholderFieldsProps = {
  itemTemplateId: string;
  initialItivData?: ItivData;
  readOnly: boolean;
  formState: ObjectState<AddEditItemObjectState>;
  isEditing: boolean;
};

function PlaceholderFields(props: PlaceholderFieldsProps) {
  const { itemTemplateId, initialItivData, readOnly, formState, isEditing } = props;
  const setDefaults = useCallback(
    (selectedItem: AddEditItemObjectState["item"] | undefined) => {
      // Default the item UOM, even if a value had been specified
      if (selectedItem?.unitOfMeasure?.id) {
        formState.unitOfMeasureId.set(selectedItem?.unitOfMeasure?.id);
        formState.unitOfMeasure.set(selectedItem?.unitOfMeasure);
      }

      // Default the item task
      if (formState.locationId.value && !formState.taskId.value && selectedItem?.planTaskMappings?.nonEmpty) {
        const taskMappings = selectedItem?.planTaskMappings;
        const exactTaskMatch = taskMappings.find((ptm) => ptm.location?.id === formState.locationId.value);
        const wildCardLocationMatch = taskMappings.find((ptm) => !ptm.location);
        const defaultTask = exactTaskMatch ?? wildCardLocationMatch;

        if (defaultTask) {
          formState.taskId.set(defaultTask?.task.id);
          formState.task.set(defaultTask?.task);
        }
      }
    },
    [formState],
  );

  const onItemSelect = useCallback(
    (id: string | undefined, selectedItem: AddEditItemObjectState["item"] | undefined) => {
      // Set the material variant on the line
      formState.itemId.set(id);
      formState.item.set(selectedItem);

      // We set the defaults
      setDefaults(selectedItem);
    },
    [formState, setDefaults],
  );

  const itemOptions = useLazyItems(readOnly);
  const { materialAttributeDimensions, currentMaterialAttributeValues } = useComputed(() => {
    const result = {
      materialAttributeDimensions: formState.item.materialAttributeDimensions?.rows?.filter((mad) =>
        // use effectiveMADs when editing placeholder
        isEditing
          ? formState.materialVariant.value?.effectiveMADs?.some((effMad) => effMad.id === mad.id.value) ?? false
          : mad.useInTakeoff.value,
      ),
      currentMaterialAttributeValues: formState.materialVariant.value?.materialAttributeValues ?? [],
    };
    return result;
  }, [formState]);

  return (
    <>
      <BoundSelectField
        label="Placeholder*"
        field={formState.itemId}
        nothingSelectedText="Please select a material"
        options={itemOptions}
        getOptionLabel={(o) => o.displayName}
        getOptionValue={(o) => o.id}
        placeholder="Select material"
        onSelect={onItemSelect}
        disabled={disableBasedOnPotentialOperation(initialItivData?.canEditMaterial)}
      />
      {formState.itemId.value && materialAttributeDimensions.nonEmpty && (
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.dg.gtc("30% 30% 30%").gap2.aie.$}>
            {materialAttributeDimensions.map((materialAttributeDimension) => {
              const mad = materialAttributeDimension.value;
              const unitLabel = mad.unitOfMeasure
                ? ` (${mad.unitOfMeasure?.abbreviation || mad.unitOfMeasure?.name})`
                : "";
              const valueOption = currentMaterialAttributeValues?.find(({ dimension }) => dimension.id === mad.id);
              const value = valueOption?.id;

              return (
                <div css={Css.df.$} key={mad.id}>
                  <SelectMaybeNewField
                    compact
                    label={`${mad.name}${unitLabel}`}
                    // We may have historical MAVs/MADs no longer used in takeoff,
                    // but we still want to display them as read-only during editing.
                    readOnly={!mad.useInTakeoff}
                    onAdd={(newValue) => {
                      const newEntry = {
                        dimension: mad,
                        _value: newValue,
                        id: "",
                      };
                      materialAttributeDimension.set({
                        ...mad,
                        values: [...materialAttributeDimension.values.value, newEntry],
                      });
                      formState.materialVariant.materialAttributeValues.set([
                        ...currentMaterialAttributeValues.filter(({ dimension }) => dimension.id !== mad.id),
                        newEntry,
                      ]);
                    }}
                    onSelect={(opt?: string) => {
                      const newValue = mad.values?.find((v) => v.id === opt);
                      if (newValue) {
                        formState.materialVariant.materialAttributeValues.set([
                          ...currentMaterialAttributeValues.filter(({ dimension }) => dimension.id !== mad.id),
                          newValue,
                        ]);
                      }
                    }}
                    options={mad.values?.map((mav) => ({ id: mav.id, name: mav._value! })) ?? []}
                    // NOTE: This field might need to be updated to handle range values
                    //    SC-55230
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.id}
                    value={value}
                  />
                </div>
              );
            })}
          </div>
          <HelperText
            helperText={<div>Note: You can specify numeric ranges like '&lt; 10', '&gt; 10' or '10-20'</div>}
          />
        </div>
      )}
      <AddEditItemLocationsField
        newLocationNameField={formState.newLocationName}
        field={formState.locationId}
        onSelect={(locationId: string | undefined, location) => {
          formState.locationId.set(locationId);
          formState.location.set(location);
          formState.item.value && setDefaults(formState.item.value);
        }}
        disabled={disableBasedOnPotentialOperation(initialItivData?.canEditLocation)}
      />
      <BoundTaskSelectField
        selectedTask={initialItivData?.task}
        readOnly={readOnly}
        formState={formState}
        onSelect={(taskId, task) => {
          formState.taskId.set(taskId);
          formState.task.set(task);
        }}
      />
      <CommonFields
        itemTemplateId={itemTemplateId}
        readOnly={readOnly}
        formState={formState}
        disabled={{
          uom: "Determined automatically by material selection",
          options: disableBasedOnPotentialOperation(initialItivData?.canEditOptions),
          quantity: disableBasedOnPotentialOperation(initialItivData?.canEditQuantity),
        }}
      />
    </>
  );
}

function useLazyItems(readOnly: boolean) {
  const [loadItems] = useAddEditPlaceholderItemModal_GetItemsLazyQuery({
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
  });

  const [itemOptions, setItemOptions] = useState<FormItem[]>([]);

  // load all material variants, page by page
  useEffect(() => {
    if (readOnly) return;

    void (async () => {
      const response = await loadItems();

      if (response.data?.items) {
        setItemOptions(
          response.data!.items.map(({ materialAttributeDimensions, ...rest }) => ({
            ...rest,
            materialAttributeDimensions: materialAttributeDimensions?.map(({ values, ...rest }) => ({
              ...rest,
              values: values?.map(({ value: _value, ...other }) => ({ ...other, _value })),
            })),
          })),
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return itemOptions;
}
