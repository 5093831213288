import { Css, IconButton, TabsWithContent, useRightPane } from "@homebound/beam";
import { useHistory } from "react-router";
import { CommentFeed, HistoryFeed } from "src/components";
import {
  InternalUser,
  JobLogDetailFragment,
  JobLogNoteDetailFragment,
  TaskDetailPaneFragment,
  useJobLogNoteHistoryTabQuery,
  useJobLogQuery,
} from "src/generated/graphql-types";
import { useTabParam } from "src/hooks/useTabParam";
import { formatDate } from "src/routes/projects/job-logs/JobLogsPage";
import { createDevelopmentJobLogsUrl, createProjectJobLogsUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";
import { JobLogNoteForm } from "./JobLogNoteForm";

type JobLogNoteRightPaneContentProps = {
  jobLogId: string;
  currentUser: InternalUser;
  projectId?: string;
  jobLogNote?: JobLogNoteDetailFragment;
  developmentId?: string;
  sourceTask?: TaskDetailPaneFragment;
  refetchJobLogs?: () => void;
  customOnClose?: VoidFunction;
};

export const jobLogNoteFormWidth = 383;

export function JobLogNoteRightPaneContent(props: JobLogNoteRightPaneContentProps) {
  const { jobLogId, currentUser, jobLogNote, projectId, developmentId, sourceTask, refetchJobLogs, customOnClose } =
    props;
  const query = useJobLogQuery({ variables: { id: jobLogId }, nextFetchPolicy: "cache-first" });
  const { closeRightPane } = useRightPane();
  const { push } = useHistory();

  const onClose = () => {
    closeRightPane();
    if (developmentId) {
      push(createDevelopmentJobLogsUrl(developmentId));
    } else if (projectId) {
      push(createProjectJobLogsUrl(projectId));
    }
    if (customOnClose) {
      customOnClose();
    }
  };

  return queryResult(query, {
    data: ({ jobLog }) => {
      return (
        <>
          <div css={Css.df.h100.oya.p3.oxh.$} data-testid="jobLogNoteRightPaneContent">
            <div css={Css.df.fdc.fg1.aic.maxw100.$}>
              <div css={Css.df.fdr.jcsb.mb2.w100.$}>
                <h1 css={Css.lgSb.$}>{formatDate(jobLog.logDate)}</h1>
                {/**
                 * This functionality broke when the change allowing all job log notes to be readable in the side pane was introduced.
                 * There is a ticket to investigate / fix this: https://app.shortcut.com/homebound-team/story/33698/fe-job-log-note-navigation-buttons-are-always-disabled
                 * <ButtonGroup
                    size="xs"
                    buttons={[
                      { icon: "chevronUp", onClick: onPrevClick, disabled: onPrevClick === undefined },
                      { icon: "chevronDown", onClick: onNextClick, disabled: onNextClick === undefined },
                    ]}
                  /> 
                 */}
                <IconButton data-testid="closePane" icon="x" onClick={onClose} />
              </div>
              <JobLogNoteTabs
                jobLog={jobLog}
                jobLogId={jobLogId}
                currentUser={currentUser}
                jobLogNote={jobLogNote}
                developmentId={developmentId}
                sourceTask={sourceTask}
                refetchJobLogs={refetchJobLogs}
              />
            </div>
          </div>
        </>
      );
    },
  });
}

type JobLogNoteTabsProps = Omit<
  JobLogNoteRightPaneContentProps,
  "onPrevClick" | "onNextClick" | "projectId" | "readOnly"
> & {
  jobLog: JobLogDetailFragment;
};

function JobLogNoteTabs({
  jobLogNote,
  jobLog,
  developmentId,
  currentUser,
  sourceTask,
  refetchJobLogs,
}: JobLogNoteTabsProps) {
  const [tab, setTab] = useTabParam("details");
  const tabs = [
    {
      name: "Details",
      value: "details",
      render: () => (
        <JobLogNoteForm
          key={jobLogNote?.id}
          jobLog={jobLog}
          currentUser={currentUser}
          jobLogNote={jobLogNote}
          developmentId={developmentId}
          refetchJobLogs={refetchJobLogs}
          sourceTaskId={sourceTask?.id}
        />
      ),
    },
  ];
  if (jobLogNote) {
    tabs.push(
      {
        name: "Comments",
        value: "comments",
        render: () => (
          <div css={Css.mb1.$}>
            <CommentFeed commentable={jobLogNote} />
          </div>
        ),
      },
      {
        name: "History",
        value: "history",
        render: () => (
          <div css={Css.mb1.$}>
            <HistoryTab jobLogNoteId={jobLogNote.id} />
          </div>
        ),
      },
    );
  }

  return (
    <div css={Css.w100.mx3.$}>
      <TabsWithContent tabs={tabs} onChange={setTab} selected={tab} includeBottomBorder={true} />
    </div>
  );
}

type HistoryTabProps = {
  jobLogNoteId: string;
};

const HistoryTab = ({ jobLogNoteId }: HistoryTabProps) => {
  const { data: jobLogNoteHistory } = useJobLogNoteHistoryTabQuery({
    variables: {
      jobLogNoteId: jobLogNoteId,
    },
    skip: !jobLogNoteId,
  });

  return (
    <HistoryFeed historyItems={[...(jobLogNoteHistory?.jobLogNote.history || [])].reverse()} renderAsCard={false} />
  );
};
