import { Button, Css, TabContent, Tabs } from "@homebound/beam";
import { useEffect, useState } from "react";
import {
  CommentFeedTradePartnerDetailsFragment,
  CommentStreamDetailFragment,
  CommentStreamVisibility,
  InternalUser,
} from "src/generated/graphql-types";
import { groupBy } from "src/utils";
import { CommentDeleteFunction, CommentSaveFunction } from "./CommentFeed";
import { CommentStream } from "./CommentStream";
import { CommentStreamTradePartnerForm } from "./CommentStreamTradePartnerForm";

export type CommentStreamsProps = {
  streams: CommentStreamDetailFragment[];
  streamVisibility: CommentStreamVisibility;
  saveComment: CommentSaveFunction;
  deleteComment: CommentDeleteFunction;
  currentUser: InternalUser;
  tradePartners?: CommentFeedTradePartnerDetailsFragment[];
  commentableId: string;
};
const maxTabs = 5;

export function CommentStreams(props: CommentStreamsProps) {
  const { streamVisibility, saveComment, deleteComment, currentUser, tradePartners, commentableId } = props;
  const streams = props.streams?.slice().reverse().slice(0, maxTabs);
  const firstStream = streams[0];
  const [currentTab, setCurrentTab] = useState(firstStream?.id);
  const [showStreamForm, setShowStreamForm] = useState(!firstStream);
  const hideStreamForm = streams.length >= maxTabs;

  useEffect(() => {
    const id = firstStream?.id;
    if (id) {
      setCurrentTab(id);
      setShowStreamForm(false);
    }
  }, [firstStream]);

  const tabs = streams.map((stream) => {
    const { id, tradePartnerUsers } = stream;
    const namesPhrase = tradePartnerUsers.map(({ name }) => name.split(" ")[0]).join(", ");
    const numberPhrase = tradePartnerUsers.length > 2 ? `(${tradePartnerUsers.length})` : "";
    const tabName = `${numberPhrase} ${namesPhrase}`;
    return {
      name: tabName,
      value: id,
      render: () => {
        const grouped = groupBy(tradePartnerUsers, ({ tradePartner }) => tradePartner.id);
        const phrases = Object.entries(grouped).map(([tradePartnerId, tpus]) => {
          const usersPhrase = tpus.map(({ name }) => name).join(", ");
          const tradePartner = tradePartners?.filter(({ id }) => id === tradePartnerId)[0];
          return `${usersPhrase} (${tradePartner?.name})`;
        });
        const toPhrase = `To: ${phrases?.join(", ")}`;
        return (
          <div css={Css.mt1.$}>
            <span css={Css.gray600.$}>{toPhrase}</span>
            <CommentStream
              stream={stream}
              streamVisibility={streamVisibility}
              saveComment={saveComment}
              deleteComment={deleteComment}
              currentUser={currentUser}
              commentableId={commentableId}
            />
          </div>
        );
      },
    };
  });

  return (
    <>
      <div>
        <Tabs
          alwaysShowAllTabs={true}
          tabs={tabs}
          selected={currentTab}
          onChange={(value) => {
            setCurrentTab(value);
            setShowStreamForm(false);
          }}
        />
      </div>
      {!hideStreamForm && (
        <div css={Css.if(tabs.length > 0).mt3.$}>
          <Button
            disabled={showStreamForm}
            icon="plus"
            label="Add new"
            variant="secondary"
            onClick={() => {
              setCurrentTab("");
              setShowStreamForm(true);
            }}
          ></Button>
        </div>
      )}
      {!showStreamForm && <TabContent tabs={tabs} selected={currentTab} />}
      {showStreamForm && (
        <div>
          <CommentStreamTradePartnerForm
            streamVisibility={streamVisibility}
            saveComment={saveComment}
            currentUser={currentUser}
            tradePartners={tradePartners}
            commentableId={commentableId}
          />
        </div>
      )}
    </>
  );
}
