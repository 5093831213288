import {
  TaskDetailsPage_PlanTaskFragment,
  DynamicSchedulesTaskSnapshot_TaskSnapshotFragment,
} from "src/generated/graphql-types";
import FullCalendar, { createRef, EventSourceInput } from "@fullcalendar/react";
import { Css, Palette } from "@homebound/beam";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useEffect, useMemo } from "react";
import { TaskColorLegend } from "../../schedule-v2/components/TaskColorLegend";
import { DateOnly } from "src/utils/dates";
import { addBusinessDays, endOfDay, isEqual, isWithinInterval, startOfDay } from "date-fns";
import { DateIcons } from "../calendar/dynamicSchedulesCalendarUtils";

type CalendarCardProps = {
  task: TaskDetailsPage_PlanTaskFragment;
  taskSnapshot: DynamicSchedulesTaskSnapshot_TaskSnapshotFragment | undefined;
};

export function CalendarCard(props: CalendarCardProps) {
  const { task, taskSnapshot } = props;
  const { workDays } = task;
  const calendarRef = createRef<FullCalendar>();

  // Iterate over the startDate+durationInDays and create an event for each day
  const taskWorkInterval = useMemo(() => {
    const taskWorkInterval: Date[] = [];
    if (task.startDate && task.durationInDays) {
      for (let i = 0; i < task.durationInDays; i++) {
        const date = new DateOnly(addBusinessDays(task.startDate, i));
        taskWorkInterval.push(date);
      }
    }
    return taskWorkInterval;
  }, [task.startDate, task.durationInDays]);

  const events: EventSourceInput = useMemo(
    () =>
      [
        // Estimated work days, iterate over the work days and create an event for each
        ...taskWorkInterval
          .map((date) => ({
            id: date.toISOString(),
            date,
            allDay: true,
            title: date.getDate().toString(),
            backgroundColor: Palette.Gray300,
          }))
          .filter((event) => !workDays.some((workDay) => workDay.workDay.date.toISOString() === event.id)),
        // Actual work days
        ...workDays.map((workDay) => ({
          id: workDay.id,
          date: workDay.workDay.date,
          allDay: true,
          // The title will be the day of the month
          title: workDay.workDay.date.getDate().toString(),
          backgroundColor: Palette.Blue200,
        })),
        // Add the original start date last so it will be removed in the `uniqueBy` call if it overlaps with the current range
        taskSnapshot?.startDate && {
          id: "originalStartDate",
          date: taskSnapshot.startDate,
          allDay: true,
          title: taskSnapshot.startDate.getDate().toString(),
          backgroundColor: Palette.Yellow200,
        },
      ]
        .compact()
        .uniqueBy((event) => event.date.toISOString()),
    [taskWorkInterval, workDays, taskSnapshot],
  );

  const delayFlags = useMemo(() => task.scheduleFlags, [task.scheduleFlags]);

  const isFlagWithinRange = (createdAtDate: Date, date: Date) => {
    return isEqual(endOfDay(date), endOfDay(createdAtDate));
  };

  // Check if the task is planned for the current month
  const isInCurrentMonth = useMemo(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const calendarDate = calendarApi.getDate();
      return isWithinInterval(calendarDate, {
        start: startOfDay(new DateOnly(task.startDate)),
        end: endOfDay(new DateOnly(addBusinessDays(task.startDate, task.durationInDays))),
      });
    }
    return false;
  }, [calendarRef, task.durationInDays, task.startDate]);

  // If the task is not in the current month we will render the calendar in the month of the task
  useEffect(() => {
    if (calendarRef.current && !isInCurrentMonth) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(task.startDate);
    }
  }, [calendarRef, isInCurrentMonth, task.startDate]);

  return (
    <div css={Css.gc("1 / span 2").gr(1).df.fdc.bgWhite.br8.hPx(600).$}>
      <div css={baseStyles}>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin]}
          height={"calc(100% - 30px)"}
          customButtons={{
            // This is a hack to have a header that is not a button
            header: {
              text: "Task Status",
              click: () => {},
            },
            empty: {
              text: "",
              click: () => {},
            },
          }}
          events={events}
          editable={false}
          headerToolbar={{ start: "header", center: "prev title next", end: "empty" }}
          dayHeaderContent={({ text }) => {
            return <div css={Css.lgSb.gray500.mb1.$}>{text[0]}</div>;
          }}
          dayCellContent={({ dayNumberText, isOther, date }) => {
            const currentDayDelayFlags = delayFlags?.filter((f) => isFlagWithinRange(f.createdAt, date));
            return (
              <div css={Css.relative.df.aic.jcc.w100.h100.$}>
                {currentDayDelayFlags.nonEmpty && (
                  <DateIcons
                    delayFlag={currentDayDelayFlags?.map((d) => d.reason?.title).join(", ")}
                    // TODO: Add the logic for materialDrop and tradePartnerAssigned
                  />
                )}
                <div css={Css.lgSb.if(isOther).dn.else.gray800.$}>{dayNumberText}</div>
              </div>
            );
          }}
          eventContent={({ event }) => {
            const currentDayDelayFlags = delayFlags?.filter((f) => isFlagWithinRange(f.createdAt, event.start!));
            return (
              <div css={Css.relative.df.aic.jcc.w100.h100.$}>
                {currentDayDelayFlags.nonEmpty && (
                  <DateIcons
                    delayFlag={currentDayDelayFlags?.map((d) => d.reason?.title).join(", ")}
                    // TODO: Add the logic for materialDrop and tradePartnerAssigned
                  />
                )}
                <div css={Css.lgBd.gray800.$}>{event.title}</div>
              </div>
            );
          }}
        />
        <TaskColorLegend
          title={""}
          items={[
            { color: Palette.Blue200, label: "A day that this task was worked on" },
            { color: Palette.Gray300, label: "Estimated work days / No work done" },
            { color: Palette.Yellow200, label: "Original Start Date" },
          ]}
        />
      </div>
    </div>
  );
}

const baseStyles = Css.br8.h100
  .addIn(".fc", Css.p3.br8.$)
  .addIn("div.fc-header-toolbar", Css.dg.gtc("repeat(3, 1fr)").$)
  .addIn("div.fc-header-toolbar > div.fc-toolbar-chunk", Css.df.aic.gap1.$)
  .addIn("div.fc-header-toolbar > div.fc-toolbar-chunk:nth-child(2)", Css.jcc.$)
  .addIn("button.fc-button, button.fc-button:hover", Css.bgTransparent.gray600.bn.$)
  .addIn("button.fc-header-button", Css.xlBd.gray900.add("pointerEvents", "none").$)
  .addIn("h2.fc-toolbar-title", Css.baseMd.gray700.m0.important.$)
  .addIn(".fc-theme-standard th, .fc-theme-standard td, .fc-daygrid-day, .fc-theme-standard .fc-scrollgrid", Css.bn.$)
  .addIn(".fc-theme-standard .fc-scrollgrid", Css.bn.$)
  .addIn(
    ".fc-daygrid-day-events, .fc-daygrid-day-top",
    Css.df.absolute.top0.left0.important.w("calc(100% - 16px)").hPx(64).m1.br4.$,
  )
  .addIn(".fc-daygrid-event-harness", Css.w100.$)
  .addIn(".fc-daygrid-day-number", Css.df.jcc.aic.w100.p0.$)
  .addIn(".fc-daygrid-day-bottom", Css.dn.$)
  .addIn(".fc-next-button", Css.m0.important.$)
  .addIn(".fc-daygrid-event, .fc-event-main,", Css.h100.m0.important.$)
  .addIn(".fc-daygrid-day, .fc-day-today", Css.bgTransparent.hPx(80).important.$)
  .addIn(".fc-daygrid-event", Css.bgGray300.bn.$)
  .addIn(".fc-daygrid-day:not(.fc-day-other) .fc-daygrid-day-top", Css.bgGray100.$)
  .addIn(".fc-scrollgrid-sync-table tbody > tr:last-child", Css.dn.$)
  .addIn(".fc-day-other", Css.add("opacity", 0).$)
  .addIn(
    ".fc-day-today .fc-daygrid-day-events, .fc-day-today .fc-daygrid-day-top",
    Css.outline("2px solid").blue600.$,
  ).$;
