import { Button, ButtonMenu, Css, HbLoadingSpinner, useModal } from "@homebound/beam";
import { SearchBox } from "src/components";
import { Pagination } from "src/components/Pagination";
import { PageHeader } from "src/routes/layout/PageHeader";
import { createBidItemPageUrl } from "src/RouteUrls";
import { BulkUploadBidItemModal } from "./components/BulkUploadBidItemModal";
import { ItemCatalogFilter } from "./components/ItemCatalogFilter";
import { ItemCatalogTable } from "./components/ItemCatalogTable";
import { useItemCatalog } from "./hooks/useItemCatalog";

export function ItemCatalogPage() {
  const { openModal } = useModal();
  const { bidItems, settings, setSettings, setSearch, refetch, loading, hasNextPage } = useItemCatalog();

  return (
    <div css={Css.df.fdc.$}>
      <PageHeader
        title="Item Catalog"
        hideTitle
        left={
          <div>
            <div css={Css.xl2Bd.$}>Item Catalog</div>
            <div css={Css.gray700.$}>
              Here you can search for bid items, add or update items details and costs, or compare market pricing
            </div>
          </div>
        }
        right={
          <div css={Css.mla.mya.df.$}>
            <div css={Css.mr1.$}>
              <ButtonMenu
                trigger={{ label: "Actions" }}
                items={[
                  {
                    label: "Bulk upload",
                    onClick: () =>
                      openModal({
                        content: (
                          <BulkUploadBidItemModal
                            onCompleteUpload={async () => {
                              await refetch();
                            }}
                          />
                        ),
                      }),
                  },
                ]}
              />
            </div>
            <Button label="Add New" onClick={createBidItemPageUrl()} />
          </div>
        }
      />
      {/* Put the Filter & Table side-by-side. */}
      <section css={Css.df.$}>
        <div css={Css.fg0.$}>
          <ItemCatalogFilter settings={settings} setSettings={setSettings} />
        </div>
        {/* Within the Table, stack the Search + Table + Pagination. */}
        <div css={Css.fg1.df.fdc.$}>
          <div css={Css.df.py2.jcfe.$}>
            <SearchBox debounceDelayInMs={500} data-testid="searchTerm" onSearch={setSearch} />
          </div>
          {loading ? <HbLoadingSpinner /> : <ItemCatalogTable filteredBidItems={bidItems} />}
          <Pagination
            label="Items"
            hasNextPage={hasNextPage}
            settings={settings}
            setSettings={setSettings}
            hideIfSinglePage
          />
        </div>
      </section>
    </div>
  );
}
