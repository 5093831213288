import { Button, ButtonMenu, Css, HbLoadingSpinner, Icon, useModal, useSuperDrawer } from "@homebound/beam";
import { useEffect, useMemo, useState } from "react";
import { SearchBox } from "src/components";
import { Pagination } from "src/components/Pagination";
import { PageHeader } from "src/routes/layout/PageHeader";
import { foldEnum, tableHeightWithPagination } from "src/utils";
import { ProductCatalogViewType } from "../product-catalog/enums";
import { useMaterialCatalog } from "./useMaterialCatalog";
import { createInstallTasksUrl, createMaterialCatalogUrl, createMaterialPageUrl } from "src/RouteUrls";
import { MaterialCatalogTileView } from "./components/MaterialCatalogTileView";
import { MaterialCatalogFilter } from "./MaterialCatalogFilter";
import { MaterialCatalogListView } from "./components/MaterialCatalogListView";
import { UploadMaterialListingsCsvModal } from "./UploadMaterialListingsCsvModal";
import { DownloadMaterialListingsCsvModal } from "./DownloadMaterialListingsCsvModal";
import { useRouteMatch } from "react-router";
import { materialDetailsPath } from "src/routes/routesDef";
import { MaterialSuperDrawer } from "./components/material-super-drawer/MaterialSuperDrawer";
import { useHistory } from "react-router-dom";
import { UploadMaterialAttributesCsvModal } from "./UploadMaterialAttributesCsvModal";

export function MaterialCatalogPage() {
  const { loading, variants, setSettings, setSearch, settings, hasNextPage } = useMaterialCatalog();
  const [viewType, setViewType] = useState(ProductCatalogViewType.TILE);
  const superDrawerMatch = useRouteMatch<{ variantId: string }>([materialDetailsPath]);
  const variantId = superDrawerMatch?.params?.variantId;
  const { isDrawerOpen, openInDrawer, closeDrawer } = useSuperDrawer();
  const { openModal } = useModal();
  const history = useHistory();

  useEffect(() => {
    if (variantId && !isDrawerOpen) {
      openInDrawer({
        content: <MaterialSuperDrawer variantId={variantId} />,
        onClose: () => history.push(createMaterialCatalogUrl()),
      });
    }
    if (!variantId && isDrawerOpen) {
      closeDrawer();
    }
  }, [isDrawerOpen, variantId, openInDrawer, closeDrawer, history]);

  const actionMenuItems = useMemo(
    () => [
      {
        label: "Download Material Listings",
        onClick: () => openModal({ content: <DownloadMaterialListingsCsvModal /> }),
        src: "",
        icon: "download",
      },
      {
        label: "Bulk Upload Materials",
        onClick: () => openModal({ content: <UploadMaterialListingsCsvModal /> }),
        src: "",
        icon: "upload",
      },
      {
        label: "Bulk Upload Material Attributes",
        onClick: () => openModal({ content: <UploadMaterialAttributesCsvModal /> }),
        src: "",
        icon: "upload",
      },
    ],
    [openModal],
  );

  return (
    <div css={Css.w100.h100.$}>
      <PageHeader
        title="Materials Catalog"
        hideTitle
        left={
          <div>
            <div css={Css.xl2Bd.$}>Materials Catalog</div>
            <div css={Css.gray700.$}>
              Here you can search for materials, add or update material details and costs, or compare market pricing
            </div>
          </div>
        }
        right={
          <div css={Css.mla.mya.gap2.df.$}>
            <Button label="Install Tasks" icon="linkExternal" variant="tertiary" onClick={createInstallTasksUrl()} />
            <ButtonMenu trigger={{ label: "Actions" }} items={actionMenuItems} />
            <Button icon="plus" label="Add New" onClick={createMaterialPageUrl()} />
          </div>
        }
      />

      <section css={Css.df.$}>
        <MaterialCatalogFilter settings={settings} setSettings={setSettings} />
        <div css={Css.fg1.$}>
          <div css={Css.df.pb2.pt3.$}>
            <div css={Css.df.mla.$}>
              <label css={Css.smBd.mya.pr1.$}>View</label>
              <div css={Css.br4.ba.bcGray300.df.$}>
                <button
                  css={Css.br.pxPx(2).bcGray300.if(viewType === ProductCatalogViewType.TILE).bgGray200.$}
                  onClick={() => setViewType(ProductCatalogViewType.TILE)}
                >
                  <Icon icon="tile" />
                </button>
                <button
                  css={Css.pxPx(2).if(viewType === ProductCatalogViewType.LIST).bgGray200.$}
                  onClick={() => setViewType(ProductCatalogViewType.LIST)}
                >
                  <Icon icon="list" />
                </button>
              </div>
            </div>
            <div css={Css.ml2.$}>
              <SearchBox data-testid="searchTerm" debounceDelayInMs={500} onSearch={setSearch} />
            </div>
          </div>
          <div css={Css.pl2.pb2.h(tableHeightWithPagination).oa.$}>
            {loading ? (
              <HbLoadingSpinner />
            ) : (
              foldEnum(viewType, {
                [ProductCatalogViewType.LIST]: () => <MaterialCatalogListView variants={variants} />,
                [ProductCatalogViewType.TILE]: () => <MaterialCatalogTileView variants={variants} />,
              })
            )}
          </div>
          <Pagination label="Products" hasNextPage={hasNextPage} settings={settings} setSettings={setSettings} />
        </div>
      </section>
    </div>
  );
}
