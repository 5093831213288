import {
  AutoSaveIndicator,
  BoundNumberField,
  BoundTextAreaField,
  Button,
  Css,
  FullBleed,
  IconButton,
  SelectField,
  StaticField,
  Switch,
  Tag,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { StatusIndicator } from "src/components/StatusIndicator";
import {
  FeatureFlagType,
  ItemTemplateStatus,
  ScopeTemplateHeader_ItemTemplateFragment,
  useCreateNewItemTemplateVersionsMutation,
  useDeleteItemTemplatesMutation,
  useSaveItemTemplateHeaderMutation,
  useSaveScopeTemplateHeader_ReadyPlanMutation,
} from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import {
  createDevelopmentReviewTemplatesUrl,
  createDevelopmentScopeTemplateCostReportUrl,
  createDevelopmentScopeTemplatesUrl,
  createDevelopmentScopeTemplateUrl,
} from "src/RouteUrls";
import { itemTemplateStatusToTagType } from "src/utils";
import { RenderMode } from "../review-and-publish/components/utils";
import { AddItemTemplatesReasonCodesModal } from "./AddItemTemplatesReasonCodesModal";
import { ItemTemplateAnomaliesIconButton } from "src/routes/settings/itemTemplates/anomalies/ItemTemplateAnomaliesIconButton";
import { useRouteMatch } from "react-router-dom";
import { developmentPaths } from "src/routes/routesDef";
import { useFeatureFlag, useFeatureFlags } from "src/contexts/FeatureFlags/FeatureFlagContext";
import { useFormState } from "@homebound/form-state";
import { useIsProcurementUWUser } from "src/hooks/useIsProcurementUWUser";
import { getStage } from "src/context";

export type ScopeTemplateHeaderProps = {
  itemTemplate: ScopeTemplateHeader_ItemTemplateFragment;
};

export function ScopeTemplateHeader(props: ScopeTemplateHeaderProps) {
  const { itemTemplate } = props;
  const { id, statusDetail, displayName, collection, appliedToCount, canCreateNewVersion, version } = itemTemplate;
  const { developmentId } = useParams<{ developmentId: string }>();
  const tid = useTestIds(props, "scopeTemplateHeader");
  const { push } = useHistory();
  const { openModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [editingNote, setEditNote] = useState(false);
  const [createNewVersion] = useCreateNewItemTemplateVersionsMutation();
  const [saveItemTemplate] = useSaveItemTemplateHeaderMutation();
  const [saveReadyPlan] = useSaveScopeTemplateHeader_ReadyPlanMutation();

  const formState = useFormState({
    config: { contingencyMarkupPercentage: { type: "value" }, scopeChangeReasonNote: { type: "value" } },
    init: {
      input: itemTemplate,
      map: (it) => ({
        contingencyMarkupPercentage: it.contingencyMarkupPercentage,
        scopeChangeReasonNote: it.scopeChangeReasonNote,
      }),
    },
    autoSave: async ({ changedValue: { contingencyMarkupPercentage } }) => {
      contingencyMarkupPercentage !== undefined &&
        (await saveItemTemplate({ variables: { input: { id, contingencyMarkupPercentage } } }));
    },
  });

  // Being a little cute and reusing the header across both pages instead of doing a nested layout
  const isCostReportPage = !!useRouteMatch(developmentPaths.scopeTemplateCostReport);

  const { versions, latestVersion, currentVersion, isLatestVersion } = useMemo(() => {
    const versions = collection?.templates ?? [];
    const currentVersion = version;
    const latestVersion = versions.last?.version || currentVersion;
    return {
      versions,
      latestVersion,
      currentVersion,
      isLatestVersion: id === versions.last?.id || currentVersion === latestVersion,
    };
  }, [collection, id, version]);

  const navigateToTemplate = useCallback(
    (itId: string) => {
      if (isCostReportPage) {
        push(createDevelopmentScopeTemplateCostReportUrl(developmentId, itId));
      } else {
        push(`${createDevelopmentScopeTemplateUrl(developmentId, itId)}${window.location.search.split("&tab").first}`);
      }
    },
    [developmentId, push, isCostReportPage],
  );

  const onCreateNewVersion = useCallback(async () => {
    const response = await createNewVersion({ variables: { templateIds: [id] } });
    const newTemplate = response.data?.createNewItemTemplateVersions.newTemplates.first;
    if (newTemplate) navigateToTemplate(newTemplate.id);
  }, [navigateToTemplate, createNewVersion, id]);

  const setDocumentTitle = useDocumentTitle();
  useEffect(() => {
    setDocumentTitle(displayName);
  }, [displayName, setDocumentTitle]);

  const postDelete = useCallback(() => {
    // If the template was basedOn another version of the template, navigate to it
    if (itemTemplate.basedOn?.id) {
      navigateToTemplate(itemTemplate.basedOn.id);
    } else {
      // Otherwise, navigate to the templates list view
      push(createDevelopmentScopeTemplatesUrl(developmentId));
    }
    triggerNotice({ icon: "success", message: "Draft has been deleted" });
  }, [navigateToTemplate, push, developmentId, itemTemplate, triggerNotice]);

  const onEditNote = useCallback(async () => {
    const result = await saveItemTemplate({
      variables: { input: { id, scopeChangeReasonNote: formState.scopeChangeReasonNote.value } },
    });
    setEditNote(false);
    return result;
  }, [saveItemTemplate, id, formState]);

  const digitalBuildingExperimentsEnabled = useFeatureFlag(FeatureFlagType.DigitalBuildingExperiments);
  const isProcurementUWUser = useIsProcurementUWUser();

  return (
    <FullBleed>
      <header
        css={
          Css.df.fdr.add("flexWrap", "wrap").gap("8px 16px").aic.py2.mhPx(64).mb3.bb.bw1.bcGray200.bgWhite.jcsb.aib.$
        }
        {...tid}
      >
        <div css={Css.df.fg1.asfs.jcsb.mw("60%").if("md").mw("72%").if("lg").mw("75%").$}>
          <div css={Css.df.$}>
            <div css={Css.df.asfs.mr2.fs0.$}>
              <IconButton icon="arrowBack" onClick={createDevelopmentScopeTemplatesUrl(developmentId)} />
            </div>
            <div css={Css.df.fdc.$}>
              <div css={Css.df.aic.$}>
                <h1 data-testid="headerTitle" css={Css.df.aic.xlSb.gray900.mr2.$}>
                  {displayName} <ItemTemplateAnomaliesIconButton parentId={id} />
                </h1>
              </div>
            </div>
            {statusDetail.code === ItemTemplateStatus.Draft && (
              <div css={Css.asfs.$}>
                {" "}
                <AutoSaveIndicator />
              </div>
            )}
          </div>
          <div css={Css.df.gap2.pr2.$}>
            <StaticField label="Status">
              <div css={Css.df.aic.gap1.$}>
                <StatusIndicator status={statusDetail.code} type="ItemTemplate" />
                <div css={Css.hPx(32).df.aic.$}>{statusDetail.name}</div>
              </div>
            </StaticField>
            {statusDetail.code !== ItemTemplateStatus.Draft && (
              <StaticField label="Applied to">
                <div css={Css.hPx(32).df.aic.$}>{`${appliedToCount} project${appliedToCount > 1 ? "s" : ""}`}</div>
              </StaticField>
            )}
          </div>
        </div>
        <div css={Css.df.aic.gap2.$}>
          <StaticField label="Created">
            <div css={Css.hPx(32).df.aic.$}>{itemTemplate.createdAt.toLocaleDateString()}</div>
          </StaticField>
          <StaticField label="Published">
            <div css={Css.hPx(32).df.aic.if(!itemTemplate.activatedAt).sm.gray400.$}>
              {itemTemplate.activatedAt?.toLocaleDateString() ?? "None"}
            </div>
          </StaticField>
        </div>
        <div css={Css.df.aic.asfs.w("18%").if("mdAndDown").mw("40%").$}>
          <div css={Css.w100.$}>
            <StaticField label="Reason">
              <div css={Css.hPx(32).df.aic.if(!itemTemplate.scopeChangeReason).sm.gray400.$}>
                {itemTemplate.scopeChangeReason?.name ?? "None"}
              </div>
            </StaticField>
          </div>
        </div>
        <div css={Css.df.aic.asfs.fg1.w("19%").$}>
          <div css={Css.w100.$}>
            <StaticField
              label={
                <p>Note {!editingNote && <Button variant="text" onClick={() => setEditNote(true)} label="Edit" />}</p>
              }
            >
              <div css={Css.df.aic.w100.$}>
                <div css={Css.smMd.w100.pr1.$}>
                  {editingNote ? (
                    <BoundTextAreaField
                      label="Note"
                      labelStyle="hidden"
                      placeholder="None"
                      readOnly={!editingNote}
                      compact
                      field={formState.scopeChangeReasonNote}
                    />
                  ) : (
                    <div css={Css.lineClamp2.if(!formState.scopeChangeReasonNote.value).sm.gray400.$} {...tid.note}>
                      {formState.scopeChangeReasonNote.value ?? "None"}
                    </div>
                  )}
                  {editingNote && <Button variant="text" label="Save Note" onClick={onEditNote} />}
                </div>
              </div>
            </StaticField>
          </div>
        </div>
        <div css={Css.df.aic.asfs.fg1.gap2.jcsb.aie.$}>
          <div css={Css.df.gap3.$}>
            {itemTemplate.statusDetail.code === ItemTemplateStatus.Active && (
              <Switch
                label="UW Version"
                compact
                disabled={
                  getStage() === "prod" && !isProcurementUWUser && "You do not have permission to edit this field"
                }
                selected={itemTemplate.isUnderwritingTemplate}
                onChange={async (value) => {
                  await saveReadyPlan({
                    variables: {
                      input: {
                        id: itemTemplate.readyPlan?.id,
                        underwritingTemplateId: value ? itemTemplate.id : undefined,
                      },
                    },
                  });
                }}
              />
            )}
            <div css={Css.wPx(85).$}>
              <BoundNumberField
                type="percent"
                label="Contingency"
                placeholder="None"
                field={formState.contingencyMarkupPercentage}
                compact
                disabled={
                  (itemTemplate.statusDetail.code !== ItemTemplateStatus.Draft &&
                    "Can only be edited on a Draft version") ||
                  (itemTemplate.hasContingencyLineItem &&
                    "This template has a contingency line item, update it to update the template contingency")
                }
              />
            </div>
            <div css={Css.mwPx(200).mwPx(368).$}>
              <SelectField
                {...tid.version}
                label="Version"
                borderless
                sizeToContent
                compact
                value={id}
                options={versions}
                getOptionMenuLabel={({
                  id,
                  statusDetail,
                  displayVersion,
                  scopeChangeReason,
                  createdAt,
                  isUnderwritingTemplate,
                }) => (
                  <div css={Css.w100.df.jcsb.$} key={id}>
                    <div css={Css.mwPx(115).df.fdc.$}>
                      <span css={Css.sm.$}>{displayVersion}</span>
                      <span css={Css.xsSb.$}>
                        {createdAt.toLocaleDateString()} {scopeChangeReason ? "-" : ""} {scopeChangeReason?.name}
                      </span>
                    </div>
                    <div css={Css.df.aic.$}>
                      {isUnderwritingTemplate && "UW"}
                      <Tag
                        type={itemTemplateStatusToTagType(statusDetail.code)}
                        text={statusDetail.name}
                        data-testid="statusTag"
                      />
                    </div>
                  </div>
                )}
                getOptionLabel={({ displayVersion }) => `${displayVersion}`}
                getOptionValue={({ id }) => id}
                onSelect={(itId) => navigateToTemplate(itId!)}
              />
            </div>
          </div>
          <div css={Css.df.gap2.$}>
            {digitalBuildingExperimentsEnabled && !isCostReportPage && (
              <Button
                label="Calculate Costs 🧪"
                onClick={createDevelopmentScopeTemplateCostReportUrl(developmentId, id)}
                variant="tertiary"
              />
            )}
            {!isCostReportPage && statusDetail.code !== ItemTemplateStatus.Draft && (
              <>
                <ApplyToProjectsButton
                  id={id}
                  developmentId={developmentId}
                  currentVersion={currentVersion}
                  latestVersion={latestVersion}
                  isLatestVersion={isLatestVersion}
                />
                <Button
                  {...tid.createNewVersion}
                  variant="secondary"
                  label="Create New Version"
                  onClick={onCreateNewVersion}
                  disabled={disableBasedOnPotentialOperation(canCreateNewVersion)}
                />
              </>
            )}
            {!isCostReportPage && statusDetail.code === ItemTemplateStatus.Draft && (
              <>
                <Button
                  variant="secondary"
                  label="Review and Publish"
                  onClick={() =>
                    openModal({
                      content: (
                        <AddItemTemplatesReasonCodesModal
                          itemTemplatesIds={[id]}
                          reviewUrl={createDevelopmentReviewTemplatesUrl(developmentId)}
                        />
                      ),
                    })
                  }
                />
                <DeleteButton itemTemplate={itemTemplate} postDelete={postDelete} />
              </>
            )}
          </div>
        </div>
      </header>
    </FullBleed>
  );
}

function DeleteButton(props: Pick<ScopeTemplateHeaderProps, "itemTemplate"> & { postDelete: () => void }) {
  const tid = useTestIds(props, "DeleteButton");
  const { itemTemplate, postDelete } = props;
  const { openModal } = useModal();
  const [deleteItemTemplates, { loading: isDeleting }] = useDeleteItemTemplatesMutation();

  const onDelete = useCallback(async () => {
    openModal({
      content: (
        <ConfirmationModal
          confirmationMessage={<></>}
          title="Are you sure you want to delete this draft?"
          onConfirmAction={async () => {
            await deleteItemTemplates({ variables: { id: itemTemplate.id } });
            postDelete();
          }}
          label={"Delete Draft"}
        />
      ),
    });
  }, [deleteItemTemplates, itemTemplate.id, postDelete, openModal]);

  return (
    <Button
      {...tid.createNewVersion}
      variant="secondary"
      label="Delete Draft"
      onClick={onDelete}
      // deleting a template can take a long time and modal immediately closes so disable button while deleting is in progress
      disabled={disableBasedOnPotentialOperation(itemTemplate.canDelete) || isDeleting}
    />
  );
}

type ApplyToProjectsButtonProps = {
  developmentId: string;
  id: string;
  currentVersion: string;
  latestVersion: string;
  isLatestVersion: boolean;
};

function ApplyToProjectsButton(props: ApplyToProjectsButtonProps) {
  const { developmentId, id, currentVersion, latestVersion, isLatestVersion } = props;
  const { openModal } = useModal();
  const history = useHistory();
  const goToApplyStep = () => {
    history.push({
      pathname: createDevelopmentReviewTemplatesUrl(developmentId),
      state: { itemTemplatesIds: [id], mode: RenderMode.read, from: history.location },
    });
  };

  return (
    <Button
      variant="secondary"
      label="Apply to Projects"
      onClick={() => {
        if (isLatestVersion) {
          goToApplyStep();
        } else {
          openModal({
            content: (
              <ConfirmationModal
                confirmationMessage={
                  <div css={Css.df.fdc.gap2.$}>
                    <span>
                      Version <b>{currentVersion}</b> will be applied to projects.
                    </span>
                    <span>
                      Latest version is <b>{latestVersion}</b>
                    </span>
                  </div>
                }
                onConfirmAction={goToApplyStep}
                title="Are you sure you want to apply this version?"
                label="Confirm"
              />
            ),
          });
        }
      }}
    />
  );
}
