import {
  Accordion,
  BoundSelectField,
  BoundTextField,
  BoundMultiSelectField,
  Button,
  Css,
  FormLines,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import { ExitIconButton } from "src/components/ExitIconButton";
import {
  BidItemCostTypeFragment,
  BidItemItemsFragment,
  BidItemUnitsOfMeasureFragment,
  SaveBidItemInput,
  useBidItemPageOptionsQuery,
  useSaveBidItemMutation,
} from "src/generated/graphql-types";
import { createItemCatalogUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";

export function BidItemPage() {
  const bidItemOptionsQuery = useBidItemPageOptionsQuery();

  return queryResult(bidItemOptionsQuery, {
    data: ({ items, unitsOfMeasure, costTypes }) => (
      <BidItemEditor unitsOfMeasure={unitsOfMeasure} items={items} costTypes={costTypes} />
    ),
  });
}

type BidItemEditorProps = {
  items: BidItemItemsFragment[];
  unitsOfMeasure: BidItemUnitsOfMeasureFragment[];
  costTypes: BidItemCostTypeFragment[];
};

function BidItemEditor({ items, unitsOfMeasure, costTypes }: BidItemEditorProps) {
  const [saveBidItem, { loading }] = useSaveBidItemMutation();
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const tid = useTestIds({}, "bidItemPage");

  const formState = useFormState({ config: formConfig });

  const handleSave = async () => {
    const { data } = await saveBidItem({
      variables: { input: formState.value },
    });

    const bidItem = data?.saveBidItem.bidItem;

    if (bidItem) {
      triggerNotice({
        message: `Bid item: ${bidItem.name} created successfully`,
        icon: "success",
      });
      history.push(createItemCatalogUrl());
    }
  };

  return (
    <div css={Css.mb4.$}>
      <header {...tid.title} css={Css.df.jcsb.xl2Bd.py3.$}>
        Add a new bid item
        <ExitIconButton confirmUrl={createItemCatalogUrl()} formState={formState} />
      </header>
      <div css={Css.df.$}>
        <div css={Css.pb8.$}>
          <Accordion defaultExpanded title="Bid Item Overview">
            <FormLines labelSuffix={{ required: "*" }} labelStyle="left" width="lg">
              <BoundTextField field={formState.code} label="Bid item code" />
              <BoundTextField field={formState.name} label="Bid item description" />
              <BoundMultiSelectField
                field={formState.items}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ displayName }) => displayName}
                options={items}
                label="Associated item codes"
              />
              <BoundSelectField
                field={formState.costType}
                getOptionValue={({ code }) => code}
                getOptionLabel={({ name }) => name}
                options={costTypes}
                label="Cost type"
              />
              <BoundSelectField field={formState.unitOfMeasureId} options={unitsOfMeasure} label="Unit of measure" />
            </FormLines>
          </Accordion>
        </div>
      </div>
      <footer
        css={Css.df.fixed.z999.bottom0.left0.right0.bgGray100.p4.boxShadow("0px 0px 32px rgba(201, 201, 201, 0.75)").$}
      >
        <div css={Css.mla.$}>
          <Observer>
            {() => (
              <Button
                disabled={loading || !formState.dirty || !formState.valid}
                label="Add Bid Item"
                onClick={handleSave}
              />
            )}
          </Observer>
        </div>
      </footer>
    </div>
  );
}

type FormValue = SaveBidItemInput;

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  code: { type: "value", rules: [required] },
  costType: { type: "value", rules: [required] },
  name: { type: "value", rules: [required] },
  unitOfMeasureId: { type: "value", rules: [required] },
  items: { type: "value" },
};
