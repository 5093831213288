import { DynamicSchedulesHeader } from "./components/DynamicSchedulesHeader";
import { Button, ButtonMenu, ButtonModal, Css, MenuItem } from "@homebound/beam";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router";
import { ProjectParams, dynamicSchedulesPath } from "src/routes/routesDef";
import {
  createCalendarViewScheduleUrl,
  // createGanttViewScheduleUrl,
  createListViewScheduleUrl,
  createMilestoneViewScheduleUrl,
} from "src/RouteUrls";
import { DynamicSchedulesGantt } from "./gantt/DynamicSchedulesGantt";
import { DynamicSchedulesCalendar } from "./calendar/DynamicSchedulesCalendar";
import { DynamicSchedulesList } from "./list-view/DynamicSchedulesList";
import { TableActions } from "src/routes/layout/TableActions";
import { useCallback, useMemo, useState } from "react";
import {
  CustomDynamicSchedulesFilter,
  DynamicSchedulesFilterModal,
  customDynamicSchedulesFilterDefault,
} from "./components/DynamicSchedulesFilterModal";
import { useQueryStorage } from "../schedule-v2/table/filterUtils";
import { SearchBox } from "src/components";
import { DynamicSchedulesMilestone } from "./milestone-view/DynamicSchedulesMilestone";
import { ScheduleGridWrapper } from "./draft-mode/ScheduleDraftMode";

enum DynamicScheduleView {
  Calendar = "Calendar",
  Gantt = "Gantt",
  List = "List",
  Milestone = "Milestone",
}

export function DynamicSchedulesPage() {
  const { projectId } = useParams<ProjectParams>();
  const [search, setSearch] = useState("");
  const history = useHistory();
  const scheduleView = useScheduleRouteMatch();

  const { queryStorage, setQueryStorage } = useQueryStorage({
    storageKey: "dynamicSchedulesFilter",
    initialQueryStorage: customDynamicSchedulesFilterDefault,
  });

  const showFilterModal = useCallback(
    (close: VoidFunction) => {
      return <DynamicSchedulesFilterModal filter={queryStorage} setFilter={setQueryStorage} onClose={close} />;
    },
    [queryStorage, setQueryStorage],
  );

  const handleClear = useCallback(() => {
    setQueryStorage({} as CustomDynamicSchedulesFilter);
  }, [setQueryStorage]);

  const hasFilter = useMemo(() => Object.values(queryStorage).some(Boolean), [queryStorage]);

  const menuItems: MenuItem[] = [
    {
      label: "List View",
      onClick: createListViewScheduleUrl(projectId),
    },
    // [SC-55189] Hiding the Gantt and Calendar views until we come back to implementing them
    // {
    //   label: "Gantt View",
    //   onClick: createGanttViewScheduleUrl(projectId),
    // },
    {
      label: "Calendar View",
      onClick: createCalendarViewScheduleUrl(projectId),
    },
    {
      label: "Milestone View",
      onClick: () => {
        // remove query param filters for milestone view
        history.push(createMilestoneViewScheduleUrl(projectId));
        setQueryStorage({} as CustomDynamicSchedulesFilter);
      },
    },
  ];

  return (
    <>
      <DynamicSchedulesHeader scheduleParentId={projectId} />
      {/* Milestones has its own view with filters and search */}
      {scheduleView === DynamicScheduleView.List && (
        <ScheduleGridWrapper>
          <TableActions>
            <div css={Css.df.gap1.$}>
              <ButtonModal content={showFilterModal} trigger={{ label: "Filter" }} />
              <SearchBox clearable onSearch={setSearch} />
              {hasFilter && <Button variant="text" label="Clear" onClick={handleClear} />}
            </div>
            <ButtonMenu items={menuItems} trigger={{ label: `View: ${scheduleView}` }} />
          </TableActions>
        </ScheduleGridWrapper>
      )}
      {/* Calendar view should not have the schedule grid wrapper as it messes with styling */}
      {scheduleView === DynamicScheduleView.Calendar && (
        <TableActions>
          <div css={Css.df.gap1.$}>
            <ButtonModal content={showFilterModal} trigger={{ label: "Filter" }} />
            <SearchBox clearable onSearch={setSearch} />
            {hasFilter && <Button variant="text" label="Clear" onClick={handleClear} />}
          </div>
          <ButtonMenu items={menuItems} trigger={{ label: `View: ${scheduleView}` }} />
        </TableActions>
      )}
      <Switch>
        <Route component={DynamicSchedulesList} path={dynamicSchedulesPath.listView} />
        <Route component={DynamicSchedulesGantt} path={dynamicSchedulesPath.ganttView} />
        <Route component={DynamicSchedulesCalendar} path={dynamicSchedulesPath.calendarView} />
        <Route component={DynamicSchedulesMilestone} path={dynamicSchedulesPath.milestoneView} />
      </Switch>
    </>
  );
}

function useScheduleRouteMatch() {
  const isGanttView = !!useRouteMatch(dynamicSchedulesPath.ganttView);
  const isCalendarView = !!useRouteMatch(dynamicSchedulesPath.calendarView);
  const isMilestoneView = !!useRouteMatch(dynamicSchedulesPath.milestoneView);
  const isListView = !!useRouteMatch(dynamicSchedulesPath.listView);

  if (isGanttView) return DynamicScheduleView.Gantt;
  if (isCalendarView) return DynamicScheduleView.Calendar;
  if (isMilestoneView) return DynamicScheduleView.Milestone;
  if (isListView) return DynamicScheduleView.List;

  throw new Error("Unknown schedule view");
}
