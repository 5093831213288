import { useSnackbar } from "@homebound/beam";
import { useCallback, useEffect, useState } from "react";
import { CsvRow } from "src/components/CsvUploader";
import { isCsv } from "src/components/CsvUtils";
import {
  CostType,
  ItemTemplateImportModalQuery,
  SaveItemTemplateItemVersionInput,
  useItemTemplateImportModalLazyQuery,
  useItemTemplateImportModalForPlanPackageLazyQuery,
  ItemTemplateImportModalForPlanPackageQuery,
} from "src/generated/graphql-types";
import { ItemTemplateApi } from "./api/ItemTemplateApi";

/** Helper to map CSV field values (names) to ids/enums. */
export type ImportLookups = {
  itemCodeToId: Record<string, string>;
  itemIdToVersion: Record<string, number>;
  costTypeToEnum: Record<string, CostType>;
  locationNameToId: Record<string, string>;
  locationPathToLeafId: Record<string, string>;
  locationIdToVersion: Record<string, number>;
  uomNameToId: Record<string, string>;
  bidItemNameToId: Record<string, string>;
  taskNameToId: Record<string, string>;
  materialVariantCodeToId: Record<string, string>;
  defaultLocationId: string;
  defaultUomId: string;
  options: Array<{ id: string; name: string; code: string }>;
};

type MappedInputErrors = {
  kind: "errors";
  errors: string[];
};

type MappedInputSuccess = {
  kind: "success";
  inputs: SaveItemTemplateItemVersionInput[];
};

type MappedInputResult = MappedInputErrors | MappedInputSuccess;

/** Maps CSV rows to GQL inputs for item template items. */
export function mapToInputs(lookups: ImportLookups, rows: CsvRow[], planPackage?: boolean): MappedInputResult {
  const { header, columnIndexes } = findHeaders(rows);
  if (header === undefined) {
    return { kind: "errors", errors: ["No headers founds; please check format of CSV and upload again"] };
  }

  // Get the raw string[] data into an array of JSON objects
  const importRows = rows
    .slice(1, rows.length) // slice drops the header
    .filter((row) => row.data.length > 1) // drop any empty rows / new lines, i.e. `row=[""]`
    .map((row, i) => {
      // Create a JSON version of the data[]; surely react-papaparse could do this for us...
      const importRow = { index: i } as ImportRow;
      header.data.forEach((header, j) => {
        const inputKey = csvHeaderToInputKey[header];
        if (inputKey) importRow[inputKey] = row.data[columnIndexes[j]]?.trim();
      });
      return importRow;
    });

  // Keep a list of errors, granted all we can do client-side is if the lookup failed
  const errors: string[] = [];

  const inputs = importRows.map((row, i) => {
    const input: SaveItemTemplateItemVersionInput = {};
    const isRemove = row.quantity?.toLowerCase() === "remove";
    // Format itemCode with 3 decimals, e.g. 5101.01 => 5101.010
    const itemCode = formatItemCode(row.itemCode);
    input.itemId = itemCode && lookups.itemCodeToId[itemCode];
    input.name = row.name;
    input.costType = row.costType ? lookups.costTypeToEnum[row.costType.toLowerCase()] : undefined;
    input.locationId = row.path
      ? lookups.locationPathToLeafId[row.path.toLocaleLowerCase().replaceAll(" ", "_")]
      : row.location
        ? lookups.locationNameToId[row.location.toLowerCase()]
        : lookups.defaultLocationId;
    if (!input.locationId && row.path) {
      input.locationPath = row.path;
    }
    input.unitOfMeasureId = (row.uom && lookups.uomNameToId[row.uom.toLowerCase()]) || lookups.defaultUomId;
    input.specifications = row.specifications || undefined;
    input.tradePartnerNote = row.tradePartnerNote || undefined;
    input.internalNote = row.internalNote || undefined;
    input.taskId = row.taskId ? lookups.taskNameToId[row.taskId.toLowerCase()] : undefined;
    input.quantity = isRemove ? 0 : (row.quantity && quantity(row.quantity)) || 0;
    input.totalCostInCents = (row.totalCostInDollars && dollarsToCents(row.totalCostInDollars)) || 0;
    if (!input.totalCostInCents && input.quantity && row.unitCostInDollars) {
      const unitCostInCents = dollarsToCents(row.unitCostInDollars);
      input.totalCostInCents = Math.round(unitCostInCents * input.quantity);
    }
    if (planPackage) input.totalCostInCents = 0;
    input.baseProductId = row.blueprintProductId || undefined;
    input.bidItemId = row.bidItemId ? lookups.bidItemNameToId[row.bidItemId.toLowerCase()] : undefined;
    input.bidItemCode = row.bidItemCode || undefined;
    input.materialVariantId = row.materialVariantId
      ? lookups.materialVariantCodeToId[row.materialVariantId.toLowerCase()]
      : undefined;
    input.isSelection = !!row.blueprintProductId;
    // Spec Option is a name column, while the others are id columns
    const specOptionId = lookups.options.find(
      (rpo) => rpo.name.toLowerCase() === row.specOptionName?.toLowerCase(),
    )?.id;
    // Map the legacy csv "separate columns" into the new `optionIds`
    input.optionIds = [
      ...(row.optionIds?.split(",") ?? []), // Allow multiple options
      row.elevationId,
      specOptionId,
    ]
      .map(stripEmptyString)
      .unique()
      .compact();
    if (isRemove) {
      input.remove = isRemove;
    }

    const [invalidFields, unsetFields] = (
      [
        ["itemId", "itemCode"],
        ["costType", "costType"],
        // if path is set, location is optional because we will be sending the path only to the BE resolver
        ...(row.path ? [] : ([["locationId", "location"]] as const)),
        ...(planPackage
          ? ([
              ["taskId", "taskId"],
              ["bidItemId", "bidItemId"],
            ] as const)
          : []),
      ] as const
    )
      .filter(([inputField]) => !input[inputField])
      .map(([_, csvField]) => {
        return {
          csvField,
          specifiedValue: row[csvField],
        };
      })
      .partition(({ csvField }) => !!row[csvField]);

    // if we are importing from the plan package context
    if (planPackage) {
      // we set the maybe optional fields, so we can do, if task is set, bidItemId is optional and the other way around
      const maybeOptionalFields: { field: keyof ImportRow; optionalIfField: (keyof ImportRow)[] }[] = [
        { field: "taskId", optionalIfField: ["bidItemId"] },
        { field: "bidItemId", optionalIfField: ["taskId"] },
      ];
      maybeOptionalFields.forEach(({ field, optionalIfField }) => {
        const unsetField = unsetFields.find(({ csvField }) => csvField === field);
        const isFieldOptional = optionalIfField.some((otherRequiredField) => !!row[otherRequiredField]);
        if (unsetField && isFieldOptional) {
          // we remove the field from the unsetFields to avoid the error message and allow the upload
          unsetFields.remove(unsetField);
        }
      });
      // And we also throw errors if the location is not v2
      if (
        input.locationId &&
        input.locationId !== lookups.defaultLocationId &&
        lookups.locationIdToVersion[input.locationId] !== 2
      ) {
        errors.push(`Row ${i + 2} specified a location that is not a v2 location`);
      }

      // And we also throw errors if the item is not v2
      if (input.itemId && lookups.itemIdToVersion[input.itemId] !== 2) {
        errors.push(`Row ${i + 2} specified an item that is not a v2 item`);
      }
    }

    invalidFields.forEach(({ csvField, specifiedValue }) =>
      errors.push(`Row ${i + 2} specified a ${csvField} '${specifiedValue}' that does not exist in Blueprint`),
    );
    if (unsetFields.nonEmpty) {
      // Use +1 for the header and +1 to be 1-based, so +2
      errors.push(`Row ${i + 2} is missing required fields ${unsetFields.map(({ csvField }) => csvField).join(", ")}`);
    }

    return input;
  });

  if (errors.length > 0) {
    return { kind: "errors", errors };
  }

  return { kind: "success", inputs };
}

function findHeaders(rowData: CsvRow[]): { header: CsvRow | undefined; columnIndexes: number[] } {
  const requiredHeaders = ["Item Code", "Cost Type"];
  // Does the first row have all our required headers?
  const headerRow = rowData[0];
  if (!headerRow || !requiredHeaders.every((name) => headerRow.data.includes(name))) {
    return { header: undefined, columnIndexes: [] };
  }
  // Support the headers being at random indexes
  const columnIndexes: number[] = [];
  const validHeaders: string[] = [];
  headerRow.data.forEach((header) => {
    const inputKey = csvHeaderToInputKey[header];
    if (inputKey) {
      validHeaders.push(header);
      columnIndexes.push(headerRow.data.indexOf(header));
    }
  });
  headerRow.data = validHeaders;
  return { header: headerRow, columnIndexes };
}

const csvHeaderToInputKey: Record<string, Exclude<keyof ImportRow, "index">> = {
  "Item Code": "itemCode",
  "Cost Type": "costType",
  "Item Name": "name",
  Location: "location",
  Specifications: "specifications",
  "Trade Note": "tradePartnerNote",
  "Internal Note": "internalNote",
  Cost: "totalCostInDollars",
  "Unit Cost": "unitCostInDollars",
  Units: "quantity",
  "Unit of Measure": "uom",
  "Blueprint Product Id": "blueprintProductId",
  "Bid Item": "bidItemId",
  "Bid Item Code": "bidItemCode",
  "Spec Level": "specOptionName",
  "Elevation Id": "elevationId",
  "Option Id": "optionId",
  "Option Ids": "optionIds",
  Task: "taskId",
  "Material Code": "materialVariantId",
  Path: "path",
};

/** A step between CsvRow `string[]` and SaveItemTemplateItemVersionInput. */
export type ImportRow = {
  index: number;
  itemCode: string | undefined; // look up entity by cost
  costType: string | undefined; // look up enum by name
  name: string;
  location: string | undefined; // look up enum by name
  specifications: string | undefined;
  tradePartnerNote: string | undefined;
  internalNote: string | undefined;
  totalCostInDollars: string | undefined;
  unitCostInDollars: string | undefined;
  quantity: string | undefined; // can be empty, will be set to 0 if lump sum uom
  uom: string | undefined; // look up entity by name
  blueprintProductId: string | undefined;
  bidItemId: string | undefined;
  bidItemCode: string | undefined;
  elevationId: string | undefined;
  specOptionName: string | undefined;
  optionId: string | undefined;
  optionIds: string | undefined;
  taskId: string | undefined; // look up entity by name
  materialVariantId: string | undefined; // look up entity by code
  path: string | undefined; // the path to the item in blueprint
};

function dollarsToCents(toParse: string): number {
  return Math.round(parseFloat(toParse.replace(/[$,]/gm, "")) * 100);
}

function quantity(toParse: string): number {
  return Math.round(parseFloat(toParse.replace(/,/g, "")));
}

// Item Code needs to be 3 decimals, so 1.1 -> 1.100, 1.11 -> 1.110
// exported for testing
export function formatItemCode(itemCode: string | undefined) {
  if (itemCode) {
    const itemCodeParts = itemCode.split(".");
    if (itemCodeParts.length === 2) {
      const decimalPart = itemCodeParts[1];
      if (decimalPart.length === 1) {
        return `${itemCode}00`;
      } else if (decimalPart.length === 2) {
        return `${itemCode}0`;
      }
    }
  }
  return itemCode;
}

function createItemTemplateLineItemLookupMaps(
  data: ItemTemplateImportModalQuery & Partial<ItemTemplateImportModalForPlanPackageQuery>,
): ImportLookups {
  return {
    itemCodeToId: data.items.keyBy(
      (i) => i.fullCode,
      (i) => i.id,
    ),
    locationNameToId: data.locations
      .uniqueBy((l) => l.name)
      .keyBy(
        (l) => l.name.toLowerCase(),
        (l) => l.id,
      ),
    locationPathToLeafId: data.locations
      .filter((l) => !!l.path)
      .keyBy(
        (l) => l.path!.toLocaleLowerCase(),
        (l) => l.id,
      ),
    itemIdToVersion: data.items.keyBy(
      (i) => i.id,
      (i) => i.version,
    ),
    locationIdToVersion: data.locations.keyBy(
      (l) => l.id,
      (l) => l.version,
    ),
    uomNameToId: data.unitsOfMeasure.keyBy(
      (uom) => uom.name.toLowerCase(),
      (uom) => uom.id,
    ),
    costTypeToEnum: data.costTypes.keyBy(
      (ct) => ct.name.toLowerCase(),
      (ct) => ct.code,
    ),
    taskNameToId:
      data.tasks?.entities.keyBy(
        (gpt) => gpt.name.toLowerCase(),
        (gpt) => gpt.id,
      ) ?? {},
    bidItemNameToId:
      data.bidItems?.keyBy(
        (bi) => bi.name.toLowerCase(),
        (bi) => bi.id,
      ) ?? {},
    materialVariantCodeToId:
      data.materialVariants?.entities.keyBy(
        (mv) => mv.code.toLowerCase(),
        (mv) => mv.id,
      ) ?? {},
    defaultUomId: data.unitsOfMeasure.filter((uom) => !uom.useQuantity)[0]?.id,
    defaultLocationId: data.locations.filter((l) => l.isWholeHouse)[0]?.id,
    // Global v1 templates don't have a ReadyPlan/options
    options: data.itemTemplate.readyPlan?.options ?? [],
  };
}

function mapInputToLookups(
  data: ItemTemplateImportModalQuery & Partial<ItemTemplateImportModalForPlanPackageQuery>,
  csvRows: CsvRow[],
  planPackage?: boolean,
): MappedInputResult {
  const lookups = createItemTemplateLineItemLookupMaps(data);
  return mapToInputs(lookups, csvRows, planPackage);
}

export function useItemTemplateItemsUploader(
  itApi: ItemTemplateApi,
  opts?: { autoImport?: boolean; planPackage?: boolean },
) {
  const { autoImport, planPackage } = opts ?? {};
  const [errors, setErrors] = useState<string[]>([]);
  const [getImportData, query] = useItemTemplateImportModalLazyQuery();
  const [getImportDataForPlanPackage, planPackageQuery] = useItemTemplateImportModalForPlanPackageLazyQuery();
  const [csvRows, setCsvRows] = useState<CsvRow[]>([]);
  const [importDataReady, setImportDataReady] = useState(false);
  const [mappedInputResult, setMappedInputResult] = useState<MappedInputResult | undefined>(undefined);
  const { triggerNotice } = useSnackbar();

  const saveItemTemplateMutation = useCallback(
    async (result: MappedInputSuccess) => {
      const { data } = await itApi.addItiv(result.inputs);
      triggerNotice({
        message: `${data?.saveItemTemplateItemVersions.itemTemplateItemVersions.length} items were successfully uploaded`,
        icon: "success",
      });
      if (data?.saveItemTemplateItemVersions.deleted.nonEmpty)
        triggerNotice({
          message: `${data?.saveItemTemplateItemVersions.deleted.length} items could not be uploaded due to mismatching Ready Plan Options.`,
          icon: "warning",
          persistent: true,
        });
    },
    [itApi, triggerNotice],
  );

  useEffect(() => {
    const planPackageReady = planPackage ? planPackageQuery.data && !planPackageQuery.loading : true;
    // Once our lazy query has resolved we begin the import process(lookups and mapping our csv rows)
    if (query.data && !query.loading && planPackageReady) {
      // Used as a switch to prevent the user importing before the importData is ready
      setImportDataReady(true);
      // We reset the errors array every time we upload a new file
      setErrors([]);
      const result = mapInputToLookups(
        { ...query.data, ...(planPackage ? planPackageQuery.data : {}) },
        csvRows,
        planPackage,
      );
      if (result.kind === "errors") {
        setErrors(result.errors);
      }
      // If we're auto importing and no errors have been found, then we can 'autoImport' the items, otherwise we wait for the user to click the import button
      void (autoImport && result.kind === "success" ? saveItemTemplateMutation(result) : setMappedInputResult(result));
    }
  }, [query, planPackageQuery, saveItemTemplateMutation, csvRows, autoImport, planPackage]);

  const addError = useCallback(
    (err: unknown) => {
      setErrors((existingErrors) => existingErrors.concat(String(err)));
    },
    [setErrors],
  );

  const handleOnDrop = useCallback(
    async (rows: CsvRow[], file?: { type: string; name: string }) => {
      if (!isCsv(file)) {
        return setErrors(["Incorrect file type. Please make sure the file is a .csv file"]);
      }
      // lets the user drop the same file again, otherwise nothing happens, with no feedback
      setImportDataReady(false);
      setCsvRows([]);
      // Conditional filter when we are importing from the plan package context
      if (planPackage) {
        const bidItemColumnIndex = rows[0]?.data.findIndex((header) => header === "Bid Item Code");
        const bidItemCodes =
          bidItemColumnIndex >= 0
            ? rows
                .slice(1)
                .map((row) => row.data[bidItemColumnIndex])
                .compact()
                .unique()
                .filter(Boolean)
            : undefined;
        const materialVariantColumnIndex = rows[0]?.data.findIndex((header) => header === "Material Code");
        const materialVariantCodes =
          bidItemColumnIndex >= 0
            ? rows
                .slice(1)
                .map((row) => row.data[materialVariantColumnIndex])
                .compact()
                .unique()
                .filter(Boolean)
            : undefined;
        await getImportDataForPlanPackage({
          variables: {
            bidItemFilter: {
              costType: [CostType.Materials, CostType.Labor],
              code: bidItemCodes?.nonEmpty ? bidItemCodes : undefined,
            },
            materialVariantsFilter: { code: materialVariantCodes?.nonEmpty ? materialVariantCodes : undefined },
          },
        });
      }

      await getImportData({
        variables: {
          itemTemplateId: itApi.templateId,
          ...(planPackage ? { locationFilter: { version: [2] }, itemFilter: { version: [2] } } : {}),
        },
      });
      setCsvRows(rows);
    },
    [getImportData, getImportDataForPlanPackage, setCsvRows, setImportDataReady, itApi, planPackage],
  );

  const handleOnClick = useCallback(async () => {
    if (mappedInputResult?.kind === "success") {
      await saveItemTemplateMutation(mappedInputResult);
    }
  }, [saveItemTemplateMutation, mappedInputResult]);

  return { errors, addError, handleOnDrop, handleOnClick, importDataReady };
}

function stripEmptyString(value: any): any {
  if (typeof value === "string") {
    value = value.trim();
    return value === "" ? undefined : value;
  }
  return value;
}
