import { Button, Css, Tag, useTestIds } from "@homebound/beam";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { AssetInfoFragment, TaskDetailPaneProjectItemDetailFragment } from "src/generated/graphql-types";
import { useMemo } from "react";
import { FormGap } from "src/components/FormGap";
import { Price } from "src/components";
import { commitmentStatusTagTypeMapper } from "src/utils/mappers";

type TaskPurchaseOrdersProps = {
  taskProjectItems: TaskDetailPaneProjectItemDetailFragment[];
};

export function TaskPurchaseOrders({ taskProjectItems }: TaskPurchaseOrdersProps) {
  const tid = useTestIds({}, "taskPurchaseOrders");
  const { assets, purchaseOrders } = useMemo(() => {
    const commitments = taskProjectItems
      .map((pi) => pi.currentCommitment)
      .unique()
      .compact();
    return { purchaseOrders: commitments, assets: commitments.map((c) => c.document?.asset as AssetInfoFragment) };
  }, [taskProjectItems]);

  return (
    <>
      <div css={Css.smMd.my2.$}>
        <span css={Css.df.aic.jcsb.sm.gray700.$}>Purchase Orders</span>
      </div>
      {purchaseOrders.nonEmpty ? (
        <div css={Css.df.fdc.gap1.mb1.$}>
          <AssetGallery assets={assets} showThumbnails={false}>
            {(openGallery) => (
              <>
                {purchaseOrders.map(
                  ({ id, tradePartner, document, accountingNumber, committedInCents, status, statusText }) => (
                    <div css={Css.df.fdc.w100.mb1.br8.bgGray100.$} key={id}>
                      <div css={Css.dg.gtc("55px 65px 13px 1fr 1fr").cg1.rg1.tiny.bgGray100.p2.aic.br8.$}>
                        <span {...tid.accountNumber}>
                          <Button
                            onClick={() => openGallery(document?.asset as AssetInfoFragment)}
                            label={`#${accountingNumber}`}
                            variant="text"
                          />
                        </span>
                        <span css={Css.wbbw.$} {...tid.tradePartner}>
                          {tradePartner?.name}
                        </span>
                        <FormGap />
                        <span css={Css.tinyBd.$} {...tid.amount}>
                          <Price valueInCents={committedInCents} />
                        </span>
                        <div css={Css.maxwPx(120).tac.$} {...tid.status}>
                          <Tag type={commitmentStatusTagTypeMapper(status!, statusText)} text={statusText!} />
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </>
            )}
          </AssetGallery>
        </div>
      ) : (
        <div {...tid.noPurchaseOrders} css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no linked POs
        </div>
      )}
    </>
  );
}
