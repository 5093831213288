import { Css, IconButton, Palette } from "@homebound/beam";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useInboxBellQuery } from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { usePollIfVisible } from "src/hooks/usePollIfVisible";
import { inboxPaths } from "src/routes/routesDef";

export function InboxBell() {
  const history = useHistory();
  const { id } = useCurrentUser();
  const query = useInboxBellQuery({ skip: !id, variables: { internalUser: id }, nextFetchPolicy: "network-only" });

  usePollIfVisible(query, 120000);

  const hasUnreads = useMemo(
    () => (query.data?.userRelevantCommentStreams.map((s) => s.unreadCount).sum() ?? 0) > 0,
    [query.data?.userRelevantCommentStreams],
  );

  return (
    <div css={Css.df.gap1.relative.$}>
      {hasUnreads && <div css={Css.borderRadius("50%").bgRed500.absolute.top(0.5).left(0.4).hPx(6).wPx(6).$} />}
      <IconButton
        icon="inbox"
        tooltip={hasUnreads ? "View new comments" : "Inbox"}
        color={Palette.Gray500}
        contrast
        onClick={() => history.push(inboxPaths.base)}
      />
    </div>
  );
}
