import { column, dateColumn, GridColumn, GridDataRow, GridTable, RowStyles, simpleHeader } from "@homebound/beam";
import { dateCell } from "src/components";
import {
  useWorkAuthorizationsTableSummariesQuery,
  WorkAuthorizationsSummaryFragment,
} from "src/generated/graphql-types";
import { mapToProjectStagesFilter, SingleStageFilter } from "src/hooks/useProjectStageSingleFilter";
import { createWorkAuthorizationUrl } from "src/RouteUrls";
import { hasData, renderLoadingOrError } from "src/utils";

type WorkAuthorizationsTableProps = {
  projectId: string;
  filter: SingleStageFilter;
};

export function WorkAuthorizationsTable({ projectId, filter }: WorkAuthorizationsTableProps) {
  const query = useWorkAuthorizationsTableSummariesQuery({
    variables: { projectId, stagesFilter: mapToProjectStagesFilter(filter) },
  });

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const workAuths = query.data.project.stages.flatMap((s) => s.workAuthorizations);

  return (
    <GridTable
      columns={createColumns()}
      rows={buildRows(workAuths)}
      sorting={{ on: "client" }}
      style={{ rowHeight: "fixed" }}
      stickyHeader
      fallbackMessage="There are no signed contracts for the selected stage(s)."
      rowStyles={createRowStyles(projectId)}
    />
  );
}

type HeaderRow = { kind: "header" };
type WorkAuthorizationRow = { kind: "workAuthorization"; id: string; data: WorkAuthorizationsSummaryFragment };
type Row = HeaderRow | WorkAuthorizationRow;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Name",
      workAuthorization: ({ name }) => name,
      mw: "324px",
    }),
    column<Row>({
      header: "File",
      workAuthorization: ({ documents }) => documents.flatMap((d) => d.name).join(", "),
      w: "320px",
    }),
    column<Row>({
      header: "Cost Code",
      workAuthorization: ({ projectItems }) => projectItems.flatMap((pi) => pi.item.costCode.displayName).join(", "),
      w: "355px",
    }),
    dateColumn<Row>({
      header: () => "Modified",
      workAuthorization: ({ updatedAt }) => dateCell(updatedAt),
      w: "171px",
    }),
  ];
}

function createRowStyles(projectId: string): RowStyles<Row> {
  return {
    header: {},
    workAuthorization: {
      rowLink: ({ id }) => createWorkAuthorizationUrl(projectId, id),
    },
  };
}

function buildRows(workAuths: WorkAuthorizationsSummaryFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...workAuths.map((wa) => {
      return {
        kind: "workAuthorization" as const,
        id: wa.id,
        data: wa,
      };
    }),
  ];
}
