import { useDashboardFilterContext } from "../../DashboardFilterContext";
import {
  ActionItemApprovalFragment,
  TaskParentPillFragment,
  useActionItemsApprovalsQuery,
} from "src/generated/graphql-types";
import { formatNumberToString, queryResult } from "src/utils";
import { Accordion, LoadingSkeleton, formatDate } from "@homebound/beam";
import { ItemCard } from "../../components/ItemCard";
import { ActionItemTypes } from "../../enums";
import { capitalCase } from "change-case";
import { formatDistanceToNow } from "date-fns";
import { ActionItemsContainer, EmptyActionItems } from "./ActionItemsPage";
import { useApprovalSuperDrawer } from "src/routes/components/Approval/ApprovalSuperDrawer";

export function ActionItemsApprovals() {
  const { filter } = useDashboardFilterContext();
  const query = useActionItemsApprovalsQuery({
    variables: { userId: filter.internalUser?.first! },
    skip: !filter.internalUser?.first,
  });
  return queryResult(query, {
    data: (data) => <ActionItemsApprovalsData approvals={data.approvals?.approvals ?? []} />,
    loading: () => <LoadingSkeleton rows={3} columns={3} size="lg" />,
    showLoading: "always",
  });
}

type ActionItemsApprovalsDataProps = {
  approvals: ActionItemApprovalFragment[];
};

function ActionItemsApprovalsData({ approvals }: ActionItemsApprovalsDataProps) {
  const openApproval = useApprovalSuperDrawer();
  return (
    <Accordion title={`Approvals (${approvals.length})`} defaultExpanded topBorder={false}>
      {approvals.isEmpty && <EmptyActionItems title="No pending approvals" />}
      <ActionItemsContainer count={approvals.length}>
        {approvals.map((a) => (
          <ItemCard
            key={a.id}
            actionType={ActionItemTypes.APPROVALS}
            onClick={() => openApproval(a.id)}
            title={`${capitalCase(a.source?.__typename ?? "")} ${capitalCase(a.subject.__typename)} Approval`.trim()}
            description={
              <p>
                {a.requestedBy?.name ?? "Someone"} requested approval for a {capitalCase(a.subject.__typename)} worth $
                {formatNumberToString((a.subject.valueInCents ?? 0) / 100)} for "{a.subject.name}" on{" "}
                {a.requestedAt && formatDate(new Date(a.requestedAt), "MMMM dd")}.
              </p>
            }
            {...(a.requestedAt && { duration: formatDistanceToNow(new Date(a.requestedAt), { addSuffix: true }) })}
            parent={a.rootProject as TaskParentPillFragment}
            assignee={a.requestedBy}
            project={a.rootProject}
          />
        ))}
      </ActionItemsContainer>
    </Accordion>
  );
}
