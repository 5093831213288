import React from "react";
import { useProductOfferingStore } from "../ProductOfferingScopeContext";
import { TableActions } from "src/routes/layout/TableActions";
import { SearchBox } from "src/components";
import { GroupBySelectField } from "./GroupBySelectField";
import { SortBySelectField } from "./SortBySelectField";
import { Css } from "@homebound/beam";

export function ProductOfferingScopeTableActionsComponent() {
  const setSearch = useProductOfferingStore((state) => state.setItemTableSearch);

  return (
    <TableActions>
      <div css={Css.fg1.df.aic.gap1.$}>
        <div css={Css.mwPx(140).$}>
          <SearchBox onSearch={setSearch} debounceDelayInMs={200} />
        </div>
        <div>
          <GroupBySelectField />
        </div>
        <div>
          <SortBySelectField />
        </div>
      </div>
    </TableActions>
  );
}

export const ProductOfferingScopeTableActions = React.memo(ProductOfferingScopeTableActionsComponent);
