import { DateOnly } from "src/utils/dates";
import { subMonths } from "date-fns";
import { PaymentTerm, PaymentTermDueFrom } from "src/generated/graphql-types";

type PaymentTermToCalculate = Pick<PaymentTerm, "alignWithPaymentCycle" | "dueDay" | "dueFrom">;

export function calculateBillDueDate(billDate: DateOnly, paymentTerm: PaymentTermToCalculate): DateOnly {
  let billDueDate = new Date(billDate);
  // Calculates the bill due date using the payment term
  calculateDateUsingPaymentTerm(billDate, billDueDate, paymentTerm);

  /**
   * Aligning the bill due date with the payment cycle.
   * Updates the bill due date to align with the 15 / 30th payment cycle.
   * Note: We only align with the payment cycle if PaymentTermDueFrom.FromInvoiceBillDate is selected.
   *
   * The objective is to synchronize the bill's due date with either the 15th or 30th.
   * If the date is on or after the 15th, it is adjusted to the 15th;
   * otherwise, it is set to the last day of the preceding month on the 30th.
   */
  if (paymentTerm.alignWithPaymentCycle && paymentTerm.dueFrom.code === PaymentTermDueFrom.FromInvoiceBillDate) {
    if (billDueDate.getDate() >= 15) {
      billDueDate.setDate(15);
    } else {
      // set to the previous month
      billDueDate = subMonths(new Date(billDueDate), 1);
      // set to the 30th of the month, or the 28th in case of february
      billDueDate.setDate(billDueDate.getMonth() === 1 ? 28 : 30);
    }
  }
  return new DateOnly(billDueDate);
}

function calculateDateUsingPaymentTerm(billDate: Date, billDueDate: Date, paymentTerm: PaymentTermToCalculate) {
  // We're calculating how many days FROM the invoice bill date
  if (paymentTerm.dueFrom.code === PaymentTermDueFrom.FromInvoiceBillDate) {
    // Calculate the date using the payment term due day
    billDueDate.setDate(billDate.getDate() + paymentTerm.dueDay);
  } else if (paymentTerm.dueFrom.code === PaymentTermDueFrom.OfTheMonthOfInvoiceBillDate) {
    // If the payment term due date falls after the current day (Bill Date), set the due date to the next month on the payment term due date
    if (billDueDate.getDate() > paymentTerm.dueDay) {
      const nextMonth = billDueDate.getMonth() + 1;
      billDueDate.setMonth(nextMonth);
    }
    billDueDate.setDate(paymentTerm.dueDay);
  } else if (paymentTerm.dueFrom.code === PaymentTermDueFrom.OfNextMonthFromInvoiceBillDate) {
    // The Payment Term due date should be set to the next month from the invoice bill date.
    billDueDate.setDate(paymentTerm.dueDay);
    const nextMonth = billDueDate.getMonth() + 1;
    billDueDate.setMonth(nextMonth);
  }
}
