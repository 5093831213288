import {
  AutoSaveIndicator,
  Button,
  ButtonMenu,
  Css,
  MenuItem,
  ScrollableContent,
  SuperDrawerWidth,
  TabsWithContent,
  Tag,
  useModal,
  useSnackbar,
  useSuperDrawer,
  useTestIds,
} from "@homebound/beam";
import { useRef, useState } from "react";
import { Redirect } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  CommitmentEditorDataFragment,
  CommitmentStatus,
  DocumentType,
  ProjectFeature,
  useCommitmentWithProjectItemsQuery,
  useDeleteCommitmentMutation,
  useReleaseBidCommitmentsMutation,
  useSaveCommitmentMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { PandaDocUploadButton } from "src/routes/components/PandaDocUploadButton";
import { UploadPriceAgreementModal } from "src/routes/developments/components/UploadPriceAgreementModal";
import { PageHeader } from "src/routes/layout/PageHeader";
import { IdOrAddParams, ProjectParams } from "src/routes/routesDef";
import {
  createBillPageUrl,
  createBillUrlFromCommitmentOrChangeOrder,
  createCommitmentChangeOrderUrl,
  createCommitmentsUrl,
  createCommitmentUrl,
  createDevelopmentCommitmentUrl,
} from "src/RouteUrls";
import { commitmentStatusToTagTypeMapper, fail } from "src/utils";
import { hasData, renderLoadingOrError } from "src/utils/queryResult";
import { CommitmentBillsTableV3 } from "./components/CommitmentBillsTableV3";
import { CommitmentBillsTableV4 } from "./components/CommitmentBillsTableV4";
import { CommitmentOverview } from "./components/CommitmentOverview";
import { CommitmentOverviewCommentDrawer } from "./components/CommitmentOverviewCommentDrawer";
import { CommitmentHistoryTab } from "./components/HistoryTab";
import { LineItemsTab } from "./components/LineItemsTab";
import { PandaDocModalContent } from "./components/PandaDocModalContent";
import { ScheduleAndScopeTab } from "./components/ScheduleAndScopeTab";
import { useMaybeAllocateItemTaskModal } from "src/hooks/useMaybeAllocateItemTaskModal";
import { AddDepositModal } from "./components/AddDepositModal";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { useProjectContext } from "../context/ProjectContext";
import ExceededRevisedBudgetModal from "./components/ExceedRevisedBudgetModal";

export function CommitmentPage() {
  const { projectId, idOrAdd } = useParams<ProjectParams & IdOrAddParams>();
  const { lotType } = useProjectContext();
  const query = useCommitmentWithProjectItemsQuery({ variables: { commitmentId: idOrAdd! } });
  const [selectedTab, setSelectedTab] = useState<TabValue>("lineItems");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();
  const { openModal } = useModal();
  const [deleteCommitment] = useDeleteCommitmentMutation();
  const [releaseCommitments] = useReleaseBidCommitmentsMutation();
  const [saveCommitment] = useSaveCommitmentMutation();
  const { triggerNotice } = useSnackbar();
  const tid = useTestIds({});
  const { openInDrawer } = useSuperDrawer();
  const maybeOpenAllocateModal = useMaybeAllocateItemTaskModal();

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const commitment = query.data.commitment;
  const showClickToPayFlag = commitment.project.features.includes(ProjectFeature.ClickToPay);
  const asset = commitment.pandaDoc?.document?.asset;
  const { isBidCommitment } = commitment;
  const isDraftBidCommitment = isBidCommitment && commitment.status === CommitmentStatus.Draft;

  const openCommentDrawer = () => {
    openInDrawer({
      content: <CommitmentOverviewCommentDrawer commitmentId={commitment.id} />,
      width: SuperDrawerWidth.Small,
    });
  };

  const isUploadDisabled =
    (commitment.status === CommitmentStatus.Signed && !!commitment.pandaDoc?.externalPandaDocUrl) ||
    isBidCommitment ||
    commitment.status === CommitmentStatus.Voided;

  const handleMaybeOpenAllocationModal = (otherwise: VoidFunction) => {
    maybeOpenAllocateModal({
      projectItems: commitment.lineItems.map((li) => li.projectItem),
      // original op to perform if item allocation not allowed
      otherwise,
      projectId,
      stage: commitment.projectStage.stage.code,
      showClickToPayFlag,
      lotType,
    });
  };

  const queriedProjectId = commitment.project.id;
  if (queriedProjectId && queriedProjectId !== projectId) {
    return <Redirect to={createCommitmentUrl(queriedProjectId, commitment!.id)} />;
  }

  function actionMenuItems(projectId: string, commitment: CommitmentEditorDataFragment): MenuItem[] {
    // TODO: implement with BE once available
    // const duplicateItem = {
    //   label: "Duplicate",
    //   onClick: () => {},
    // };

    const reUploadToPandaDocItem = {
      disabled:
        !commitment.canCreatePandaDoc?.allowed ||
        !commitment.pandaDoc?.externalPandaDocUrl ||
        isBidCommitment ||
        commitment.status === CommitmentStatus.Voided,
      label: "Re-Upload to PandaDoc",
      onClick: () => {
        // show confirmation modal when re-uploading to PandaDoc
        openModal({
          content: <PandaDocModalContent ownerId={commitment.id} isReUpload={true} />,
        });
      },
    };

    const deleteItem = {
      label: "Delete",
      disabled: disableBasedOnPotentialOperation(commitment.canDelete),
      onClick: async () => {
        await deleteCommitment({
          variables: { input: { id: idOrAdd || fail("Missing commitment id") } },
        });
        history.push(createCommitmentsUrl(projectId));
      },
    };

    const addChangeOrderItem = {
      label: "Add Change Order",
      disabled: commitment.status !== CommitmentStatus.Signed,
      onClick: createCommitmentChangeOrderUrl(projectId, commitment.id),
    };

    const addBillItem = {
      label: "Add Bill",
      disabled: commitment.status !== CommitmentStatus.Signed,
      onClick: createBillPageUrl({ commitmentLikeId: commitment.id }),
    };

    const voidItem = {
      label: "Void",
      disabled: disableBasedOnPotentialOperation(commitment.canBeVoided),
      onClick: async () => {
        return openModal({
          content: (
            <ConfirmationModal
              onConfirmAction={async () => {
                const data = await saveCommitment({
                  variables: { input: { id: commitment.id, status: CommitmentStatus.Voided } },
                });

                if (data) {
                  triggerNotice({
                    message: `Commitment ${commitment.id} has been successfully voided`,
                    icon: "success",
                  });
                }
              }}
              title={`Void commitment #${commitment.accountingNumber} ${commitment.name}`}
              label="Void"
              confirmationMessage="Are you sure you want to void this commitment? This action cannot be undone"
            />
          ),
        });
      },
    };

    const uploadSignedPdfItem = {
      label: "Upload Price Agreement",
      disabled: isUploadDisabled,
      onClick: () => {
        // show document upload modal
        if (commitment.canBeReleased.allowed) {
          handleMaybeOpenAllocationModal(() => {
            openModal({
              content: (
                <UploadPriceAgreementModal
                  documentType={DocumentType.PriceAgreementCommitment}
                  ownerId={commitment.id}
                  parentId={projectId}
                />
              ),
            });
          });
        } else {
          openModal({
            content: <ExceededRevisedBudgetModal projectId={projectId} commitmentLikeType="Commitment" />,
          });
        }
      },
    };

    const addDepositItem = {
      label: commitment.canBeEdited ? "Add Deposit" : "View Deposit",
      onClick: () =>
        openModal({
          content: <AddDepositModal commitment={commitment} />,
        }),
    };

    const options: MenuItem[] = [addChangeOrderItem, addBillItem, voidItem, addDepositItem];

    if (!!commitment.developmentCommitment) {
      const dc = commitment.developmentCommitment;
      const viewGroupCommitment = {
        label: "View Group Commitment",
        onClick: createDevelopmentCommitmentUrl(dc.id),
      };

      const uploadUrl = dc.pandaDoc?.externalPandaDocUrl;
      const viewInPandaDoc = {
        label: "View in PandaDoc",
        disabled: !uploadUrl,
        onClick: uploadUrl!,
      };

      options.unshift(viewGroupCommitment, viewInPandaDoc);

      return options;
    }

    options.push(uploadSignedPdfItem, reUploadToPandaDocItem, deleteItem);

    if (commitment.pandaDoc) {
      const viewPandaDocInBluePrint = {
        label: "View PDF",
        disabled: !asset,
        onClick: () => buttonRef.current?.click(),
      };

      options.unshift(viewPandaDocInBluePrint);
    }

    return options;
  }

  return (
    <>
      <PageHeader
        breadcrumb={{ label: "Commitments", href: createCommitmentsUrl(projectId) }}
        title={`#${commitment.accountingNumber} ${commitment.tradePartner?.name || ""}`}
        left={
          <>
            <span css={Css.ttc.$}>
              <Tag type={commitmentStatusToTagTypeMapper[commitment.status]} text={commitment.statusText} />
            </span>
            <AutoSaveIndicator />
          </>
        }
        right={
          commitment && (
            <div css={Css.df.$}>
              <div css={Css.mr1.$}>
                <Button variant="secondary" label="Comment" onClick={openCommentDrawer} icon="comment"></Button>
              </div>
              {/* actions dropdown */}
              <div css={Css.mr1.$}>
                {/* TODO: forcing re-render via key - remove once ButtonMenu can handle dynamically updated items */}
                <ButtonMenu
                  key={!commitment.canDelete.allowed ? 2 : 1}
                  items={actionMenuItems(projectId, commitment)}
                  trigger={{ label: "Actions" }}
                />
              </div>
              {isDraftBidCommitment && (
                <Button
                  disabled={!isDraftBidCommitment}
                  label={"Release PO"}
                  onClick={() => {
                    handleMaybeOpenAllocationModal(async () => {
                      const data = await releaseCommitments({ variables: { commitmentLikeIds: [commitment.id] } });
                      if (data) {
                        triggerNotice({
                          message: "PO has been released successfully",
                          icon: "success",
                        });
                      }
                    });
                  }}
                  {...tid.releaseButton}
                />
              )}
              {!commitment.developmentCommitment && !isDraftBidCommitment && (
                <PandaDocUploadButton
                  parent={commitment}
                  onUpload={() => {
                    handleMaybeOpenAllocationModal(() =>
                      openModal({
                        // show confirmation modal when initially uploading to PandaDoc
                        content: <PandaDocModalContent ownerId={commitment.id} isReUpload={false} />,
                      }),
                    );
                  }}
                />
              )}
            </div>
          )
        }
      />
      <CommitmentOverview commitment={commitment} />
      {commitment && (
        <>
          <ScrollableContent virtualized>
            <div css={Css.mr3.vh100.$}>
              <TabsWithContent
                tabs={[
                  {
                    name: "Line Items",
                    value: "lineItems",
                    render: () => <LineItemsTab commitment={commitment} />,
                  },
                  {
                    name: "Schedule and Scope",
                    value: "scheduleAndScope",
                    render: () => <ScheduleAndScopeTab commitment={commitment} />,
                  },
                  {
                    name: "Bills & Credits",
                    value: "bills",
                    render: () => (
                      <div>
                        {showClickToPayFlag ? (
                          <CommitmentBillsTableV4
                            bills={commitment.bills}
                            expenseAllocations={commitment.expenseAllocations}
                          />
                        ) : (
                          <CommitmentBillsTableV3
                            bills={commitment.bills}
                            expenseAllocations={commitment.expenseAllocations}
                            commitmentId={commitment.id}
                            projectId={commitment.project.id}
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "History",
                    value: "history",
                    render: () => <CommitmentHistoryTab commitmentId={commitment.id} />,
                  },
                ]}
                selected={selectedTab}
                onChange={setSelectedTab}
              />
            </div>
          </ScrollableContent>
          {commitment.pandaDoc && asset && (
            <AssetGallery assets={[asset]}>
              {(openGallery) => (
                <div css={Css.dn.$}>
                  <Button label="" buttonRef={buttonRef} onClick={() => openGallery(asset)} />
                </div>
              )}
            </AssetGallery>
          )}
        </>
      )}
    </>
  );
}

type TabValue = "scheduleAndScope" | "lineItems" | "bills" | "history";
