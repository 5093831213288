import { ProjectRoleDetail, ChangeRequestSource, Maybe } from "src/generated/graphql-types";

export function getRoleOptions(roles: Omit<ProjectRoleDetail, "sortOrder">[]) {
  return roles.map((role) => ({ name: role.name, id: role.code }));
}

export function isPersonalizationChangeSource(source: Maybe<ChangeRequestSource>) {
  return (
    source === ChangeRequestSource.CustomerRequestPersonalization ||
    source === ChangeRequestSource.BoylCustomerRequestPersonalization
  );
}
