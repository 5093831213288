import {
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  Icon,
  numericColumn,
  Palette,
  RowStyles,
  selectColumn,
  SelectToggle,
  simpleHeader,
  useComputed,
} from "@homebound/beam";
import { ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import { percentageCell, Price, priceCell } from "src/components";
import { CollapseRowToggle } from "src/components/formFields/CollapseRowToggle";
import {
  ConfirmLineItemsCostLineItemEntityFragment,
  ContractType,
  Maybe,
  SaveInvoiceCostLineItemAllocationInput,
} from "src/generated/graphql-types";
import { createBillUrl, createProjectDocumentUrl } from "src/RouteUrls";
import { HeaderRow } from "../../../schedule-v2/table/utils";
import { InvoiceLineItemsFilter } from "../invoice-steps/ConfirmLineItems";
import { InvoiceHomeownerContractLineItemsStore } from "../models/InvoiceHomeownerContractLineItemsStore";
import { contractTypeToNameMapper } from "src/utils";

type InvoiceLineItemsTableProps = {
  store: InvoiceHomeownerContractLineItemsStore;
  api: GridTableApi<InvoiceLineItemsRow>;
  filter: InvoiceLineItemsFilter;
  projectId: string;
};

export function InvoiceLineItemsTable({ store, api, filter, projectId }: InvoiceLineItemsTableProps) {
  const selectedRowIds = useComputed(() => api.getSelectedRowIds(), [api]);
  const rows = useMemo(() => createRows(store, filter, selectedRowIds), [store, filter, selectedRowIds]);
  const rowStyles = useMemo(() => createRowStyles(rows, selectedRowIds), [rows, selectedRowIds]);
  const columns = useMemo(() => createColumns(projectId), [projectId]);
  return (
    <>
      <div css={Css.bt.bcGray200.$} />
      <GridTable
        api={api}
        style={{ rowHeight: "fixed", grouped: true }}
        columns={columns}
        rows={rows}
        rowStyles={rowStyles}
        fallbackMessage="There are no invoicable costs for the selected period"
        stickyHeader
      />
    </>
  );
}

function createRowStyles(
  rows: GridDataRow<InvoiceLineItemsRow>[],
  selectedRowIds: string[],
): RowStyles<InvoiceLineItemsRow> {
  // map all rows|children[]
  const rowItems = rows
    .flatMap((r) => r.children)
    .reduce<Map<string, string[]>>((acc, row) => {
      if (row) {
        const childrenIds = row.children?.map(({ id }) => id) ?? [];
        acc.set(row.id, childrenIds);
      }
      return acc;
    }, new Map());

  // If the parent is unselected or all children are unselected, use the deselect style
  function shouldUseDeselectStyle(rowId: string) {
    const unselected = !selectedRowIds.includes(rowId);
    const everyChildrenUnselected =
      rowItems.get(rowId)?.every((childrenId) => !selectedRowIds.includes(childrenId)) ?? false;

    return unselected && everyChildrenUnselected;
  }

  return {
    item: {
      cellCss(row) {
        return Css.if(shouldUseDeselectStyle(row.id)).gray400.$;
      },
      // override color style (bill & vendor name)
      rowCss(row) {
        const color = shouldUseDeselectStyle(row.id) ? Css.blue200.important.$ : {};
        return Css.addIn("& > div:nth-of-type(4), a, button", color).$;
      },
    },
    costLineItem: {
      cellCss(row) {
        return Css.tiny.if(!selectedRowIds.includes(row.id)).gray400.$;
      },
      // override color style (bill column & Link component)
      rowCss(row) {
        const color = !selectedRowIds.includes(row.id) ? Css.blue200.important.$ : {};
        return Css.addIn("& > div:nth-of-type(4), a", color).$;
      },
    },
  };
}

type RowData = {
  displayName: string;
  homeownerContractAmount: Maybe<number>;
  invoicedToDate: Maybe<number>;
  remainingFromPrior: Maybe<number>;
  periodActuals: Maybe<number>;
  subtotal: Maybe<number>;
  markup: Maybe<number | string>;
  toInvoice: Maybe<number>;
  exceedActuals?: boolean;
  entity?: ConfirmLineItemsCostLineItemEntityFragment;
  parentHCLIId: string;
  costLineItemId: Maybe<string>;
  isChangeOrder: boolean;
  isFixedMarkup: boolean;
};

type CostCodeRow = {
  kind: "costCode";
  id: string;
  data: Omit<RowData, "markup" | "entity" | "parentHCLIId" | "costLineItemId" | "isChangeOrder" | "isFixedMarkup">;
};

type ProjectItemRow = {
  kind: "item";
  id: string;
  data: Omit<RowData, "parentHCLIId">;
};

export type CostLineItemRow = {
  kind: "costLineItem";
  id: string;
  data: Omit<RowData, "displayName" | "markup" | "isFixedMarkup">;
};

type TotalRow = {
  kind: "totals";
  id: string;
  data: Omit<
    RowData,
    "markup" | "displayName" | "entity" | "parentHCLIId" | "costLineItemId" | "isChangeOrder" | "isFixedMarkup"
  >;
};

export type InvoiceLineItemsRow = ProjectItemRow | CostCodeRow | CostLineItemRow | TotalRow | HeaderRow;
export type InvoiceLineItemsDataRow = ProjectItemRow | CostCodeRow | CostLineItemRow | TotalRow;

function createColumns(projectId: string): GridColumn<InvoiceLineItemsRow>[] {
  return [
    collapseColumn<InvoiceLineItemsRow>({ w: "32px", item: () => emptyCell }),
    selectColumn<InvoiceLineItemsRow>({
      w: "32px",
      costLineItem: () => emptyCell,
      totals: () => emptyCell,
      header: () => emptyCell,
      costCode: (_, { row }) => (row.selectable === false ? emptyCell : <SelectToggle id={row.id} />),
      item: (_, { row }) => (row.selectable === false ? emptyCell : <SelectToggle id={row.id} />),
    }),
    column<InvoiceLineItemsRow>({
      header: "Cost Code",
      costCode: (row) => row.displayName,
      item: (row) => row.displayName,
      totals: () => ({ content: "Total of selected line items", colspan: 2 }),
      costLineItem: () => emptyCell,
    }),
    column<InvoiceLineItemsRow>({
      w: "150px",
      header: "Bill #",
      costCode: () => emptyCell,
      totals: () => emptyCell,
      item: (row) => <EntityNameLine entity={row.entity} isChangeOrder={row.isChangeOrder} projectId={projectId} />,
      costLineItem: (row) => (
        <EntityNameLine entity={row.entity} isChangeOrder={row.isChangeOrder} projectId={projectId} />
      ),
    }),
    collapseColumn<InvoiceLineItemsRow>({
      id: "collapse2",
      w: "120px",
      header: "Vendor Name",
      align: "left",
      costCode: () => emptyCell,
      item: (data, { row, api }) => {
        if (row.children && row.children.nonEmpty) {
          return (
            <div css={Css.addIn("button", Css.fwb.$).$}>
              <CollapseRowToggle label={`${row.children.length} items`} rowId={row.id} api={api} compact />
            </div>
          );
        }
        return getVendorNameFromCostEntity(data.entity);
      },
      costLineItem: (data, { row }) => {
        if (row.selectable === false) {
          return <span css={Css.ml4.$}>{getVendorNameFromCostEntity(data.entity)}</span>;
        }

        return (
          <>
            <SelectToggle id={row.id} />
            <span css={Css.ml2.$}>{getVendorNameFromCostEntity(data.entity)}</span>
          </>
        );
      },
    }),
    column<InvoiceLineItemsRow>({
      header: "Homeowner Contract",
      costCode: (row) => priceCell({ valueInCents: row.homeownerContractAmount }),
      item: (row) => priceCell({ valueInCents: row.homeownerContractAmount }),
      totals: (row) => <Price valueInCents={row.homeownerContractAmount} />,
      costLineItem: () => emptyCell,
      align: "right",
    }),
    column<InvoiceLineItemsRow>({
      header: "Invoiced To Date",
      costCode: (row) => priceCell({ valueInCents: row.invoicedToDate }),
      item: (row) => priceCell({ valueInCents: row.invoicedToDate }),
      totals: (row) => <Price valueInCents={row.invoicedToDate} />,
      costLineItem: (row) => (
        <span css={Css.tiny.$}>
          <Price valueInCents={row.invoicedToDate} />
        </span>
      ),
      align: "right",
    }),
    column<InvoiceLineItemsRow>({
      header: "Remaining From Prior",
      costCode: (row) => priceCell({ valueInCents: row.remainingFromPrior }),
      item: (row) => priceCell({ valueInCents: row.remainingFromPrior }),
      totals: (row) => <Price valueInCents={row.remainingFromPrior} />,
      align: "right",
      costLineItem: (row) => (
        <span css={Css.tiny.$}>
          <Price valueInCents={row.remainingFromPrior} />
        </span>
      ),
    }),
    column<InvoiceLineItemsRow>({
      header: "Period Actuals",
      costCode: (row) => priceCell({ valueInCents: row.periodActuals }),
      item: (row) => priceCell({ valueInCents: row.periodActuals }),
      totals: (row) => <Price valueInCents={row.periodActuals} />,
      align: "right",
      costLineItem: (row) => (
        <span css={Css.tiny.$}>
          <Price valueInCents={row.periodActuals} />
        </span>
      ),
    }),
    column<InvoiceLineItemsRow>({
      header: "Subtotal",
      costCode: (row) => priceCell({ valueInCents: row.subtotal }),
      item: (row) => priceCell({ valueInCents: row.subtotal }),
      totals: (row) => <Price valueInCents={row.subtotal} />,
      align: "right",
      costLineItem: (row) => (
        <span css={Css.tiny.$}>
          <Price valueInCents={row.subtotal} />
        </span>
      ),
    }),
    numericColumn<InvoiceLineItemsRow>({
      header: "Markup",
      costCode: () => emptyCell,
      item: (row) =>
        row.isFixedMarkup ? contractTypeToNameMapper[row.markup as ContractType] : percentageCell(row.markup as number),
      totals: () => emptyCell,
      align: "right",
      costLineItem: () => emptyCell,
    }),
    column<InvoiceLineItemsRow>({
      header: () => (
        <>
          <span css={Css.mr1.$}>To Invoice</span>
          <Icon icon="infoCircle" inc={2} />
        </>
      ),
      costCode: (row) => priceCell({ valueInCents: row.toInvoice }),
      item: (row) => {
        if (!row.exceedActuals) return priceCell({ valueInCents: row.toInvoice });
        return (
          <span css={Css.df.jcc.$}>
            <Icon
              data-testid="exceedActuals"
              icon="error"
              color={Palette.Yellow700}
              tooltip="The actualls for this line item exceed the homeowner contract so it has been capped at the homeowner contract"
              xss={Css.mr1.$}
              inc={2}
            />
            <Price valueInCents={row.toInvoice} />
          </span>
        );
      },
      totals: (row) => priceCell({ valueInCents: row.toInvoice }),
      align: "right",
      costLineItem: (row) => {
        if (!row.exceedActuals) return priceCell({ valueInCents: row.toInvoice });
        return (
          <span css={Css.df.jcc.$}>
            <Icon
              data-testid="exceedActualsBill"
              icon="error"
              color={Palette.Yellow700}
              tooltip="The actualls for this line item exceed the homeowner contract so it has been capped at the homeowner contract"
              xss={Css.mr1.$}
              inc={2}
            />
            <Price valueInCents={row.toInvoice} />
          </span>
        );
      },
    }),
  ];
}

function createRows(
  store: InvoiceHomeownerContractLineItemsStore,
  filter: InvoiceLineItemsFilter,
  selectRowsIds: string[],
): GridDataRow<InvoiceLineItemsRow>[] {
  const rows = store.children
    .filter((group) => (filter.showOnlyActuals ? group.hasChildrenActuals : true))
    .map((group) => group.toRow(selectRowsIds));
  const totals = rows
    .filter((row) => {
      const costLineItemRows = row.children!.flatMap((c) => c.children ?? []);
      if (costLineItemRows.nonEmpty) {
        return costLineItemRows.some((bli) => selectRowsIds.includes(bli!.id));
      }
      return selectRowsIds.includes(row.id);
    })
    .reduce(
      (acc, { data }) => ({
        invoicedToDate: (data.invoicedToDate ?? 0) + acc.invoicedToDate,
        periodActuals: (data.periodActuals ?? 0) + acc.periodActuals,
        remainingFromPrior: (data.remainingFromPrior ?? 0) + acc.remainingFromPrior,
        subtotal: (data.subtotal ?? 0) + acc.subtotal,
        toInvoice: (data.toInvoice ?? 0) + acc.toInvoice,
        homeownerContractAmount: (data.homeownerContractAmount ?? 0) + acc.homeownerContractAmount,
      }),
      {
        invoicedToDate: 0,
        periodActuals: 0,
        remainingFromPrior: 0,
        subtotal: 0,
        toInvoice: 0,
        homeownerContractAmount: 0,
      },
    );

  if (rows.length === 0) return [simpleHeader];
  return [simpleHeader, { kind: "totals", id: "total", data: totals }, ...rows];
}

function getVendorNameFromCostEntity(entity?: ConfirmLineItemsCostLineItemEntityFragment) {
  switch (entity?.__typename) {
    case "Bill":
      return entity.tradePartner.name;
    case "Expense":
      return entity.intacctVendorName;
  }
  return "";
}

function EntityNameLine(props: {
  entity?: ConfirmLineItemsCostLineItemEntityFragment;
  isChangeOrder: boolean;
  projectId: string;
}): ReactNode {
  const { entity, isChangeOrder, projectId } = props;
  if (!entity) return <></>;

  switch (entity.__typename) {
    case "Bill":
      const bill = entity;
      return (
        <Link to={createBillUrl(bill.id)} data-testid="entityLink" target="_blank">
          {isChangeOrder ? `CO ${bill.name}` : bill.name}
        </Link>
      );
    case "Expense":
      const expense = entity;
      const name = isChangeOrder ? "CO Direct Expense" : "Direct Expense";

      if (expense.documents.nonEmpty) {
        return (
          <Link
            to={createProjectDocumentUrl(projectId, expense.documents.first!.id)}
            data-testid="entityLink"
            target="_blank"
          >
            {name}
          </Link>
        );
      }

      return name;
  }
}

export function toInvoiceCostLineItemAllocationInput(
  row: CostLineItemRow,
  markupMultiplier: number,
): SaveInvoiceCostLineItemAllocationInput {
  const toInvoice = row.data.toInvoice as number;
  const costAmountInCents = toInvoice * (1 / markupMultiplier);
  return {
    costLineItemId: row.data.costLineItemId,
    costAmountInCents: Math.round(costAmountInCents),
    invoicedAmountInCents: Math.round(toInvoice),
  };
}
