import { useApolloClient } from "@apollo/client";
import { ButtonMenu, Css, GridDataRow, MenuItem, Tooltip, useModal, useRightPane, useToast } from "@homebound/beam";
import { useCallback, useMemo } from "react";
import {
  SaveScheduleTaskInput,
  ScheduleDetailsFragment,
  TaskFilterOptionsFragment,
  useDeleteSchedulePhaseMutation,
  useDeleteScheduleSubPhaseMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { cacheKeyFromId, PhaseRow, SubPhaseRow, TaskRow } from "src/routes/projects/schedule-v2/table/utils";
import { capitalize, isDefined } from "src/utils";
import { useScheduleStore } from "../contexts/ScheduleStore";
import { setTaskPaneState } from "../contexts/scheduleStoreReducer";
import { AddScheduleTaskPane, getAddTaskFormValues } from "./AddScheduleTaskPane";
import { AddSubPhasePane } from "./AddSubPhasePane";
import { ScheduleType } from "./ScheduleType";

type ChildRow = GridDataRow<TaskRow | SubPhaseRow>;
type EntityType = "task" | "phase" | "subPhase";
export type MoreOptionsRow = GridDataRow<PhaseRow | SubPhaseRow | TaskRow>;

type MoreOptionsMenuCellProps = {
  row: MoreOptionsRow;
  tasks?: TaskFilterOptionsFragment[];
  schedule: ScheduleDetailsFragment;
  onTaskSave: (input: SaveScheduleTaskInput) => void;
  scrollToSubPhase: (subPhaseId: string) => void;
  scheduleIsLocked: boolean;
};

export function MoreOptionsMenuCell({
  row,
  tasks,
  schedule,
  onTaskSave,
  scrollToSubPhase,
  scheduleIsLocked,
}: MoreOptionsMenuCellProps) {
  const { showToast } = useToast();
  const {
    scheduleState: { taskPaneState, scheduleType },
    dispatch,
  } = useScheduleStore();

  const [deleteScheduleSubPhase] = useDeleteScheduleSubPhaseMutation({ variables: { subPhaseId: row.id } });
  const [deleteSchedulePhase] = useDeleteSchedulePhaseMutation({
    variables: { phaseId: row.id },
    // Refetch the following query to ensure the null state will be presented to the user in the event of deleting all phases
    refetchQueries: ["AvailableGlobalPhases"],
  });

  const client = useApolloClient();

  const deleteTask = useCallback(() => {
    // Close the side panel before the delete succeeds to ensure the panel doesn't attempt to refecth data for a deleted task
    if (taskPaneState.taskPaneId === row.id) {
      dispatch(setTaskPaneState({ taskPaneId: undefined, scrollIntoViewType: undefined, tab: undefined }));
    }

    return onTaskSave({ id: row.id, delete: true });
  }, [onTaskSave, row.id, dispatch, taskPaneState.taskPaneId]);

  const entityConfig = useMemo<Record<EntityType, { delete: () => Promise<any> | void }>>(
    () => ({
      phase: { delete: deleteSchedulePhase },
      subPhase: { delete: deleteScheduleSubPhase },
      task: { delete: deleteTask },
    }),
    [deleteScheduleSubPhase, deleteTask, deleteSchedulePhase],
  );

  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function evictChildren(children?: GridDataRow<{ kind: string; id: string }>[]) {
    if (!children) return;

    children.forEach((item) => {
      client.cache.evict({ id: cacheKeyFromId(item.id) });
      evictChildren(item.children);
    });
  }

  const onDelete = useCallback(async () => {
    if (row.kind !== "task" && hasUnDeletableTaskChildren(row.children as ChildRow[])) {
      return showToast({ type: "error", message: "Cannot delete due to a child Milestone or Cutoff type task" });
    }
    await entityConfig[row.kind].delete();
    // evicting the phase from the cache
    client.cache.evict({ id: cacheKeyFromId(row.id) });
    // evicting the children of the phase (tasks, subPhases) from the cache
    evictChildren(row.children);
  }, [entityConfig, row, client.cache, evictChildren, showToast]);
  return (
    <MoreOptionsMenuCellData
      row={row}
      onDelete={onDelete}
      tasks={tasks}
      schedule={schedule}
      scrollToSubPhase={scrollToSubPhase}
      scheduleIsLocked={scheduleIsLocked}
      scheduleType={scheduleType}
    />
  );
}

type MoreOptionsMenuCellDataProps = {
  onDelete: () => void;
  row: GridDataRow<PhaseRow | SubPhaseRow | TaskRow>;
  defaultOpen?: boolean; // Only used for stories
  tasks?: TaskFilterOptionsFragment[];
  schedule: ScheduleDetailsFragment;
  scrollToSubPhase: (subPhaseId: string) => void;
  scheduleIsLocked: boolean;
  scheduleType: ScheduleType;
};
export function MoreOptionsMenuCellData({
  onDelete,
  row,
  defaultOpen,
  tasks,
  schedule,
  scrollToSubPhase,
  scheduleIsLocked,
  scheduleType,
}: MoreOptionsMenuCellDataProps) {
  const { openModal } = useModal();
  const { openRightPane } = useRightPane();
  const entityType = row.kind;
  const isTask = row.kind === "task";
  const isDisabled = isTask && isDefined(row.data.deadlineType);
  const refetchQueriesOnTaskAdd = refetchQueriesByScheduleType[scheduleType];

  const hasComment = isTask
    ? row.data.streams.some(({ commentCount }) => commentCount > 0)
    : row.data.tasks?.some((task) => task.streams.some(({ commentCount }) => commentCount > 0));
  const hasCost = isTask
    ? row.data.projectItems.length > 0
    : row.data.tasks?.some((task) => task.projectItems.length > 0);

  let confirmationElement = <>Are you sure you want to delete this {capitalize(row.kind)}?</>;
  if (hasCost || hasComment) {
    const associatedText = hasComment && hasCost ? "comments and costs" : hasComment ? "comments" : "costs";
    confirmationElement = (
      <div>
        {hasCost && <div css={Css.mb2.ttu.$}>Warning</div>}
        {`You are about to delete a ${capitalize(
          row.kind,
        )} with ${associatedText} associated, are you sure you want to delete?`}
      </div>
    );
  }

  const persistentItemsConfig: Record<EntityType, MenuItem[]> = {
    task: [
      {
        label: "Delete Task",
        onClick: () =>
          openModal({
            content: (
              <ConfirmationModal
                title="Delete Task"
                onConfirmAction={onDelete}
                label="Delete"
                danger={hasComment || hasCost}
                confirmationMessage={confirmationElement}
              />
            ),
          }),
        destructive: true,
        disabled: scheduleIsLocked || (isTask && !row.data.canDelete.allowed),
      },
    ],
    phase: [
      {
        label: "Delete Phase",
        onClick: () =>
          openModal({
            content: (
              <ConfirmationModal
                title="Delete Phase"
                onConfirmAction={onDelete}
                label="Delete"
                danger={hasComment || hasCost}
                confirmationMessage={confirmationElement}
              />
            ),
          }),
        destructive: true,
        disabled: scheduleIsLocked,
      },
    ],
    subPhase: [
      {
        label: "Delete Subphase",
        onClick: () =>
          openModal({
            content: (
              <ConfirmationModal
                title="Delete Subphase"
                onConfirmAction={onDelete}
                label="Delete"
                danger={hasComment || hasCost}
                confirmationMessage={confirmationElement}
              />
            ),
          }),
        destructive: true,
        disabled: scheduleIsLocked,
      },
    ],
  };
  const itemsConfig: Record<EntityType, MenuItem[]> = {
    task: [
      {
        label: "Add Task as Successor",
        onClick: () =>
          openRightPane({
            content: (
              <AddScheduleTaskPane
                type="successor"
                tasks={tasks || []}
                schedule={schedule}
                refetchQueries={refetchQueriesOnTaskAdd}
                formValues={getAddTaskFormValues(row, "successor")}
                scheduleIsLocked={scheduleIsLocked}
              />
            ),
          }),
        destructive: false,
        disabled: scheduleIsLocked,
      },
      {
        label: "Add Task as Predecessor",
        onClick: () =>
          openRightPane({
            content: (
              <AddScheduleTaskPane
                type="predecessor"
                tasks={tasks || []}
                schedule={schedule}
                refetchQueries={refetchQueriesOnTaskAdd}
                formValues={getAddTaskFormValues(row, "predecessor")}
                scheduleIsLocked={scheduleIsLocked}
              />
            ),
          }),
        destructive: false,
        disabled: scheduleIsLocked,
      },
    ],
    phase: [
      {
        label: "Add a Subphase",
        onClick: () =>
          openRightPane({
            content: <AddSubPhasePane scheduleId={schedule.id} phaseId={row.id} scrollToSubPhase={scrollToSubPhase} />,
          }),
        destructive: false,
        disabled: scheduleIsLocked,
      },
      {
        label: "Add Task",
        onClick: () =>
          openRightPane({
            content: (
              <AddScheduleTaskPane
                type="task"
                tasks={tasks || []}
                schedule={schedule}
                refetchQueries={refetchQueriesOnTaskAdd}
                formValues={getAddTaskFormValues(row)}
                scheduleIsLocked={scheduleIsLocked}
              />
            ),
          }),
        destructive: false,
        disabled: scheduleIsLocked,
      },
    ],
    subPhase: [
      {
        label: "Add Task",
        onClick: () =>
          openRightPane({
            content: (
              <AddScheduleTaskPane
                type="task"
                tasks={tasks || []}
                schedule={schedule}
                refetchQueries={refetchQueriesOnTaskAdd}
                formValues={getAddTaskFormValues(row)}
                scheduleIsLocked={scheduleIsLocked}
              />
            ),
          }),
        destructive: false,
        disabled: scheduleIsLocked,
      },
    ],
  };
  return (
    <Tooltip
      title="More Options"
      children={
        <ButtonMenu
          data-testid="moreOptionsMenu"
          items={itemsConfig[entityType]}
          persistentItems={persistentItemsConfig[entityType]}
          trigger={{ icon: "verticalDots" }}
          defaultOpen={defaultOpen}
          disabled={isDisabled}
          placement="right"
        />
      }
    />
  );
}

function hasUnDeletableTaskChildren(children?: ChildRow[]) {
  if (!children) return false;

  function isNonDeletableChild(row: ChildRow) {
    return row.kind === "task" ? !row.data.canDelete.allowed : false;
  }

  // Check the potential 2 level hierarchy for child tasks which are `deadlineType` or global task milestones which cannot be deleted
  // opting for this approach rather than a `potentialOperation` on the backend to avoid doing this nested loop on render-blocking listing calls
  // and defer the work to be done upon deletion attempt. Note there is backend validation, but it doesn't play nicely with our eager cache eviction (`evictChildren`)
  return children.some((row) => {
    if (row.children) {
      // Tasks associated directly to a Phase can exist alongside children
      if (isNonDeletableChild(row)) return true;

      return row.children.some((row) => isNonDeletableChild(row));
    }

    return isNonDeletableChild(row);
  });
}

const refetchQueriesByScheduleType: Record<ScheduleType, string[]> = {
  [ScheduleType.Project]: ["ProjectScheduleTableTasks", "ProjectSchedulePageV2"],
  [ScheduleType.Development]: ["ProjectScheduleTableTasks", "DevelopmentSchedule"],
  [ScheduleType.Template]: ["ProjectScheduleTableTasks", "ScheduleTemplateEditPage"],
};
