import { BoundNumberField, Css, Switch, Tooltip } from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import {
  ChangeEventLineItemType,
  HomeownerSelectionStatus,
  Maybe,
  ProjectItemInput,
  SelectionsTab_HomeownerSelectionFragment,
  SelectionsTab_PotentialBidItemFragment,
  useSaveProjectItemsMutation,
  useSelectionsTabSaveCeliMutation,
  useToggleFinalizeForHomeownerMutation,
} from "src/generated/graphql-types";
import { SelectionOptionsTable } from "src/routes/projects/selections/options/SelectionOptionsTable";
import { CutoffDetails } from "./CutoffDetails";
import { deriveFromInput } from "./recalc";

export type CurrentSelectionTableProps = {
  homeownerSelection: SelectionsTab_HomeownerSelectionFragment;
  potentialBidItems: SelectionsTab_PotentialBidItemFragment[];
};

export function CurrentSelectionTable({ homeownerSelection, potentialBidItems }: CurrentSelectionTableProps) {
  const [toggleFinalizeForHomeownerMutation] = useToggleFinalizeForHomeownerMutation();
  const [saveProjectItems] = useSaveProjectItemsMutation();
  const [saveCeli] = useSelectionsTabSaveCeliMutation();

  const toggleFinalizeForHomeowner = async (finalize: boolean) => {
    await toggleFinalizeForHomeownerMutation({
      variables: {
        optionId: homeownerSelection.selectedOption!.id,
        status: finalize ? HomeownerSelectionStatus.Finalized : HomeownerSelectionStatus.Selected,
      },
    });
  };

  const isFinalized = homeownerSelection.status.code === HomeownerSelectionStatus.Finalized;
  const isUndecided = homeownerSelection.status.code === HomeownerSelectionStatus.Undecided;
  const title = `${
    homeownerSelection.isProposedSelection ? "Proposed" : isFinalized ? "Finalized" : "Current"
  } Selection`;
  const readOnly = !homeownerSelection?.canEdit.allowed;
  const isCutoff = homeownerSelection?.changeEventLineItem?.type === ChangeEventLineItemType.Cutoff;

  const formState = useFormState({
    config: formConfig,
    init: {
      input: homeownerSelection,
      map: (homeownerSelection) => mapForm(homeownerSelection),
    },
    async autoSave(item) {
      // item as could be a project item or CELI
      const { quantity, id } = item.changedValue;
      if (homeownerSelection.changeEventLineItem) {
        const qtyChanged = (quantity || 0) - (homeownerSelection?.changeEventLineItem?.originalQuantity || 0);
        await saveCeli({ variables: { celi: { id, quantity: qtyChanged } } });
      } else {
        await saveProjectItems({ variables: { input: { projectItems: [{ id, quantity }] } } });
      }
      formState.commitChanges();
    },
    readOnly,
  });

  return (
    <>
      <CutoffDetails cutoff={homeownerSelection.projectCutoff} />
      <div css={Css.df.aic.my2.gap5.$}>
        <div css={Css.baseMd.$} data-testid="currentSelectionTitle">
          {title}
        </div>
        <div>
          <Tooltip
            title={
              isUndecided
                ? "An undecided selection cannot be finalized. Select a product to finalize this selection."
                : ""
            }
            placement="bottom"
            disabled={!isUndecided || !homeownerSelection?.canEdit.allowed}
          >
            <Switch
              data-testid="toggleFinalizeForHomeowner"
              label="Finalize for Homeowner"
              disabled={isUndecided || !homeownerSelection?.canEdit.allowed}
              onChange={toggleFinalizeForHomeowner}
              selected={isFinalized}
              compact
            />
          </Tooltip>
        </div>
      </div>
      <div css={Css.df.maxw50.gap1.mb2.$}>
        <BoundNumberField field={formState.quantity} compact readOnly={isCutoff || readOnly} />
        <BoundNumberField field={formState.totalCostInCents} type="cents" readOnly compact />
        <BoundNumberField field={formState.totalPriceInCents} type="cents" readOnly compact />
      </div>
      <SelectionOptionsTable
        selection={homeownerSelection!}
        potentialBidItems={potentialBidItems}
        options={homeownerSelection.selectedOption ? [homeownerSelection.selectedOption] : []}
        view="currentSelection"
      />
    </>
  );
}

type FormStateData = Pick<ProjectItemInput, "id" | "quantity"> & {
  totalPriceInCents: Maybe<number>;
  totalCostInCents?: Maybe<number>;
};

const formConfig: ObjectConfig<FormStateData> = {
  id: { type: "value" },
  quantity: { type: "value" },
  totalPriceInCents: { type: "value" },
  totalCostInCents: { type: "value" },
};

function mapForm(hs: Partial<SelectionsTab_HomeownerSelectionFragment>): FormStateData {
  const isOriginalHs = hs.changeEventLineItem && !hs.isProposedSelection;
  const isProposedHs = hs.changeEventLineItem && hs.isProposedSelection;
  const isProjectItemHs = !isOriginalHs && !isProposedHs;

  const isUndecided = hs.status?.code === HomeownerSelectionStatus.Undecided;
  const proposedQty = (hs.changeEventLineItem?.quantity || 0) + (hs.changeEventLineItem?.originalQuantity || 0);

  const { totalCostInCents, totalPriceInCents } = deriveFromInput({
    quantity: isProjectItemHs ? hs.projectItem?.quantity : proposedQty,
    unitCostInCents: isUndecided ? hs.undecidedUnitCostInCents : hs.selectedOption?.unitCostInCents,
    unitPriceInCents: isUndecided ? hs.undecidedUnitPriceInCents : hs.selectedOption?.unitPriceInCents,
  });
  return {
    id: isProjectItemHs ? hs.projectItem?.id : hs.changeEventLineItem?.id,
    quantity: isProjectItemHs ? hs.projectItem?.quantity : proposedQty,
    totalCostInCents: totalCostInCents || 0,
    totalPriceInCents: totalPriceInCents || 0,
  };
}
