import { useNewDesignPackageContext } from "./NewDesignPackageContext";
import { Css, Icon, IconButton, TextField } from "@homebound/beam";
import { CheckRow, NewDesignPackageOverviewPostit, NewDesignPackageWizardPage } from "./NewDesignPackageAtoms";
import { useState } from "react";
import { Observer } from "mobx-react";
import { createGlobalOptionsUrl } from "src/RouteUrls";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import { isDefined } from "src/utils";

export function NewDesignPackageUgrades() {
  const { formState, save, globalUpgradeGroups } = useNewDesignPackageContext();
  const [search, setSearch] = useState("");
  return (
    <NewDesignPackageWizardPage>
      <NewDesignPackageOverviewPostit />
      <div css={Css.xl3.$}>Choose your Upgrades</div>
      <div css={Css.base.$}>
        Upgrades are the options our buyers select to personalize their home. Selecting these options will give you the
        ability to tag products that will fall under these selections in buyer checkout. Please select any you plan to
        offer in this Design Package.
      </div>
      <TextField
        label="Search Upgrade Options"
        value={search}
        onChange={(v) => setSearch(v || "")}
        placeholder="Search by name, ID, location..."
        fullWidth
        clearable
      />
      <div css={Css.df.w100.fdc.$}>
        {globalUpgradeGroups
          .filter((gog) =>
            !search
              ? true
              : [gog.name, gog.id, gog.type.name, ...gog.globalOptions.map((go) => go.name)].some((str) =>
                  str.toLowerCase().includes(search.toLowerCase()),
                ),
          )
          .sortBy((gog) => gog.name)
          .flatMap((gog) => gog.globalOptions)
          .filter((go) => !isDefined(go.archivedDate) || formState.withGlobalOptionIds.value?.includes(go.id))
          .map((go, index, gos) => (
            <Observer key={go.id}>
              {() => (
                <CheckRow
                  label={go.name}
                  selected={!!formState.withGlobalOptionIds.value?.includes(go.id)}
                  onChange={(selected) => {
                    if (selected) {
                      formState.withGlobalOptionIds.value = [...formState.withGlobalOptionIds.value!, go.id];
                    } else {
                      formState.withGlobalOptionIds.value = formState.withGlobalOptionIds.value?.filter(
                        (id) => id !== go.id,
                      );
                    }
                  }}
                  bb={index !== gos.length - 1}
                >
                  <div css={Css.fg1.df.jcsb.aic.$}>
                    <div css={Css.df.gap2.$}>
                      <Icon icon="faucet" />
                      <div>{go.name}</div>
                    </div>
                    <IconButton
                      icon="linkExternal"
                      onClick={`${createGlobalOptionsUrl()}?search=${go.name}`}
                      openInNew
                      inc={2}
                    />
                  </div>
                </CheckRow>
              )}
            </Observer>
          ))}
      </div>
      <NextStepButton label="Save New Design Package" onClick={save} exitButton />
    </NewDesignPackageWizardPage>
  );
}
