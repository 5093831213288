import {
  BoundCheckboxField,
  BoundNumberField,
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import {
  PaymentTermDueFrom,
  PaymentTermDueFromDetail,
  PaymentTermFragment,
  PaymentTermsQuery,
  SavePaymentTermInput,
  useSavePaymentTermMutation,
} from "src/generated/graphql-types";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { ApolloQueryResult } from "@apollo/client";
import { useEffect } from "react";

export type PaymentTermModalProps = {
  paymentTerm?: PaymentTermFragment;
  dueFromDetails: PaymentTermDueFromDetail[];
  onClose: () => Promise<ApolloQueryResult<PaymentTermsQuery>>;
};

export function CreateOrUpdatePaymentTermModal(props: PaymentTermModalProps) {
  const { paymentTerm, dueFromDetails, onClose } = props;
  const { closeModal } = useModal();
  const [savePaymentTerm] = useSavePaymentTermMutation();
  const formState = useFormState({
    config: formConfig,
    init: {
      // Initialize with default values if paymentTerm is not provided
      input: paymentTerm ?? { active: true, dueFrom: dueFromDetails[0] },
      map: (pt) => ({
        ...pt,
        dueFrom: pt?.dueFrom.code,
      }),
    },
  });

  const onSubmit = async () => {
    await savePaymentTerm({ variables: { input: { ...formState.value } } });
    await onClose();
    closeModal();
  };

  return (
    <>
      <ModalHeader>{paymentTerm ? "Edit Payment Term" : "Add New Payment Term"}</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundTextField field={formState.name} label="Name" compact />
          <BoundTextField field={formState.description} label="Description" compact />
          <BoundSelectField
            options={[
              { id: true, name: "Active" },
              { id: false, name: "Inactive" },
            ]}
            field={formState.active}
            label="Status"
            compact
          />

          <div css={Css.baseBd.$}>Due Date</div>

          <BoundNumberField label="Days" field={formState.dueDay} compact />
          <BoundSelectField
            getOptionValue={(detail) => detail.code}
            getOptionLabel={(detail) => detail.name}
            options={dueFromDetails}
            disabledOptions={dueFromDetails
              .filter(maybeDisableDueFrom)
              .map((detail) => ({ value: detail.code, reason: "" }))}
            field={formState.dueFrom}
            compact
          />
          <BoundCheckboxField field={formState.alignWithPaymentCycle} />
        </FormLines>

        {paymentTerm && (
          <div>
            <div>
              Note: Changes to this payment term are only applicable to new trades. Trades that already use this payment
              term will not be impacted.
            </div>
            <div css={Css.mt1.$}>
              To change a Trade Partner's existing payment term, create and apply a new Payment Term.
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" data-testid="cancelButton" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              data-testid="saveButton"
              label={paymentTerm ? "Save" : "Add Payment Term"}
              disabled={!formState.valid}
              onClick={onSubmit}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

function maybeDisableDueFrom(dueFromDetail: PaymentTermDueFromDetail) {
  return ![
    PaymentTermDueFrom.FromInvoiceBillDate,
    PaymentTermDueFrom.OfNextMonthFromInvoiceBillDate,
    PaymentTermDueFrom.OfTheMonthOfInvoiceBillDate,
  ].includes(dueFromDetail.code);
}

const formConfig: ObjectConfig<SavePaymentTermInput> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  description: { type: "value", rules: [required] },
  active: { type: "value", rules: [required] },
  dueFrom: { type: "value", rules: [required] },
  dueDay: { type: "value", rules: [required] },
  alignWithPaymentCycle: { type: "value" },
};
