import { Css, useComputed } from "@homebound/beam";
import { ListFieldState, ObjectConfig, ObjectState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { Observer } from "mobx-react";
import { ReactNode, useCallback } from "react";
import { UploaderProvider, useUploaderContext } from "src/contexts/UploaderContext";
import { AssetInfoFragment, AssetInput, SaveAttachmentInput } from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { sortBy } from "src/utils";
import { AssetGallery, OpenGallery } from "../assetGallery/AssetGallery";
import { ProgressPill } from "../ProgressPill";
import { WithActions } from "../to-dos/WithActions";
import { UppyUploader, UppyUploaderProps } from "../uploader";

type SaveAssetModel = AssetInput & {
  downloadUrl: string;
  attachmentUrl: string;
  contentType: string;
  createdAt: Date;
};

export type SaveAttachmentModel = SaveAttachmentInput & {
  asset: SaveAssetModel;
};

export type BoundAttachmentsProps<T> = {
  field: ListFieldState<SaveAttachmentModel>;
  right?: ReactNode;
  readonly?: boolean;
  title?: string;
  uppyUploaderProps?: Pick<UppyUploaderProps, "dragDropText" | "maxNumberOfFiles" | "dragDropWidth" | "dragDropHeight">;
  showTitle?: boolean;
};

export function BoundAttachments<T>(props: BoundAttachmentsProps<T>) {
  const { field, right, readonly = false, title = "Attachments", uppyUploaderProps, showTitle = true } = props;
  const { dragDropText, maxNumberOfFiles, dragDropWidth, dragDropHeight } = uppyUploaderProps ?? {};
  const saveAttachment = useCallback(
    (file: UppyFile) => {
      const downloadUrl = file.meta.downloadUrl as string;
      field.add({
        asset: {
          downloadUrl,
          contentType: file.type!,
          fileName: file.name,
          s3Key: file.meta.s3Key as string,
          sizeInBytes: file.size,
          attachmentUrl: downloadUrl,
          createdAt: new Date(),
          delete: false,
        },
      });
    },
    [field],
  );

  const deleteAttachment = useCallback(
    (attachment: ObjectState<SaveAttachmentModel>) => {
      if (attachment.id) {
        attachment.asset.delete.set(true);
      } else {
        field.remove(attachment.value);
      }
    },
    [field],
  );

  const orderedAttachments = useComputed(() => {
    return sortBy(
      field.rows.filter((a) => !a.asset.delete.value),
      (x) => -x.asset.createdAt,
    );
  }, [field]);

  const renderAttachments = (openGallery: OpenGallery) => {
    return (
      <div css={Css.df.add({ flexWrap: "wrap" }).gap1.$}>
        {!readonly && (
          <div css={Css.df.mt1.$}>
            <UppyUploader
              onFinish={saveAttachment}
              dragDropText={dragDropText || ""}
              maxNumberOfFiles={maxNumberOfFiles || 1}
              dragDropWidth={dragDropWidth || 100}
              dragDropHeight={dragDropHeight || 100}
            />
          </div>
        )}
        {orderedAttachments.map((attachment, i) => {
          const asset = attachment.asset.value as AssetInfoFragment;
          return (
            <div key={attachment.id.value ?? i} data-testid={attachment.id.value ?? i} css={Css.wPx(100).df.mt1.$}>
              <WithActions
                left={
                  readonly
                    ? undefined
                    : { icon: "trash", tooltip: "delete", action: () => deleteAttachment(attachment) }
                }
                right={{ icon: "download", tooltip: "download", action: asset.attachmentUrl }}
                footer={{ text: asset.fileName! }}
                onClick={() => openGallery(asset)}
              >
                <AssetPreview asset={asset} dimensions={{ width: 100, height: 100 }} />
              </WithActions>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <UploaderProvider>
      <Observer>
        {() => (
          <div css={Css.df.fdc.maxw100.$}>
            {showTitle && (
              <div css={Css.df.jcsb.$}>
                <div css={Css.gray700.sm.my1.$}>
                  {title} ({orderedAttachments?.length ?? 0})
                </div>
                {right}
              </div>
            )}
            <div css={Css.df.fdc.gap1.$}>
              <AssetGallery
                assets={orderedAttachments.map((i) => i.asset.value as AssetInfoFragment)}
                display="horizontal"
              >
                {renderAttachments}
              </AssetGallery>
            </div>
          </div>
        )}
      </Observer>
    </UploaderProvider>
  );
}

export const attachmentConfig: ObjectConfig<SaveAttachmentModel> = {
  id: { type: "value" },
  asset: {
    type: "object",
    config: {
      id: { type: "value" },
      s3Key: { type: "value" },
      fileName: { type: "value" },
      contentType: { type: "value" },
      sizeInBytes: { type: "value" },
      downloadUrl: { type: "value" },
      attachmentUrl: { type: "value" },
      createdAt: { type: "value" },
      delete: { type: "value" },
    },
  },
};

export const formatAttachments = (attachments: SaveAttachmentModel[]) =>
  attachments.map(({ asset: { id, delete: _delete, fileName, contentType, s3Key, sizeInBytes }, ...attachment }) => ({
    ...attachment,
    asset: { id, fileName, contentType, s3Key, sizeInBytes, delete: _delete },
  }));

export function FileUploadProgress() {
  const { files } = useUploaderContext();
  if (Object.keys(files).length === 0) return null;

  const fileProgress = Object.keys(files).map((id: string) => {
    const { progress } = files[id];
    if (progress < 100) {
      return (
        <div key={id} css={Css.wPx(100).hPx(100).bn.br4.bshBasic.bgGray100.df.aic.jcc.mt1.mr1.$}>
          <ProgressPill fullWidth progress={progress} hideProgress />
        </div>
      );
    }
  });

  return <>{fileProgress}</>;
}
