import { Button, Css, FilterDefs, Filters, ScrollableContent, usePersistedFilter } from "@homebound/beam";
import { useMemo } from "react";
import { createPlanPackageEditUrl } from "src/RouteUrls";
import { PlanPackageFilter, PlanPackageFragment, ReadyPlan, usePlanPackagesQuery } from "src/generated/graphql-types";
import { useMarketFilter, useReadyPlanStatusFilter } from "src/hooks";
import { PlanPackageCard } from "./PlanPackageCard";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { queryResult } from "src/utils";

export function PlanPackagesPage() {
  const { marketFilter: market } = useMarketFilter();
  const { rpStatusFilter: status } = useReadyPlanStatusFilter();
  const filterDefs: FilterDefs<PlanPackageFilter> = useMemo(() => ({ market, status }), [market, status]);

  const { filter, setFilter } = usePersistedFilter<PlanPackageFilter>({
    filterDefs,
    storageKey: `plan-package-filter`,
  });

  // TODO: ADD market to fitlers
  const query = usePlanPackagesQuery({ variables: { filter: { status: filter.status } } });

  return (
    <div css={Css.h100.w100.$}>
      <PageHeader title="Plans" right={<Button label="Create" onClick={createPlanPackageEditUrl()} />} />
      <TableActions onlyLeft>
        <h2 css={Css.lgSb.$}>All Plans</h2>
        <Filters<PlanPackageFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
      </TableActions>
      <ScrollableContent>
        {queryResult(query, ({ planPackages: { entities } }) => (
          <PlanPackageList plans={entities} />
        ))}
      </ScrollableContent>
    </div>
  );
}

type PlanPackageListProps = {
  plans: PlanPackageFragment[];
};
function PlanPackageList(props: PlanPackageListProps) {
  const { plans } = props;
  return (
    <div css={Css.df.gap3.flexWrap("wrap").$}>
      {plans.length ? plans.map((plan) => <PlanPackageCard key={plan.id} planPackage={plan} />) : "No plans found"}
    </div>
  );
}
