import { Avatar, Css, IconButton } from "@homebound/beam";
import { useState } from "react";
import { CommentEditor } from "src/components/comments/CommentEditor";
import { CommentDeleteFunction, CommentSaveFunction } from "src/components/comments/CommentFeed";
import { CommentFragment, InternalUser } from "src/generated/graphql-types";
import { sanitizeHtml } from "src/utils";
import { SaveAttachmentModel } from "../boundAttachments/BoundAttachments";
import { CommentAttachmentsRow } from "src/components/comments/CommentAttachmentsRow";
import { formatDate } from "src/components/FormattedDate";

export type CommentRowProps = {
  comment: CommentFragment;
  currentUser: InternalUser;
  saveComment: CommentSaveFunction;
  deleteComment: CommentDeleteFunction;
};

export function CommentRow(props: CommentRowProps) {
  const { comment, saveComment, deleteComment, currentUser } = props;
  const { id: commentId, author } = comment;

  // only the author of the comment can delete or edit it
  const canEditOrDelete = (author.internalUser && currentUser.id === author.internalUser.id) || false;

  async function onSave(text: string, html: string, attachments: SaveAttachmentModel[], mentionedTags?: string[]) {
    // double check can be saved
    if (canEditOrDelete) {
      await saveComment(text, html, attachments, { commentId }, mentionedTags);
    }
  }

  async function onDelete() {
    // double check can be deleted
    if (canEditOrDelete) {
      await deleteComment(commentId);
    }
  }

  return <CommentRowDataView {...{ comment, canEditOrDelete, onSave, onDelete }} />;
}

type CommentRowDataViewProps = {
  comment: CommentFragment;
  canEditOrDelete: boolean;
  onSave: (text: string, html: string, attachments: SaveAttachmentModel[], tags?: string[]) => Promise<void>;
  onDelete: () => Promise<void>;
};

function CommentRowDataView(props: CommentRowDataViewProps) {
  const { comment, canEditOrDelete, onSave, onDelete } = props;
  const { id, text: initialText, html: initialHtml, author, createdAt, attachments, tags } = comment;

  // TODO if createdAt and updatedAt are not extremely similar times show that comment was edited?

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [text, setText] = useState<string>(initialText);
  const [html, setHtml] = useState<string>(initialHtml);

  const formattedDate = formatDate(createdAt, "short", "short");

  async function confirmAndDelete() {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      await onDelete();
    }
  }

  return (
    <div data-testid={`comment-${id}`} css={Css.display("flex").$}>
      <div css={Css.mr2.fs0.$}>
        <Avatar name={author.name} src={author.avatarUrl} />
      </div>
      <div css={Css.df.fdc.fg1.$}>
        <div css={Css.mb1.df.gap1.$}>
          <span css={Css.mr1.smMd.$}>{author.name}</span>
          <span css={Css.gray700.sm.$}>{formattedDate}</span>
          {!isEditing && canEditOrDelete && (
            <>
              <IconButton compact icon="pencil" data-testid={`comment-${id}-edit`} onClick={() => setIsEditing(true)} />
              {/* TODO add a confirm delete modal*/}
              <IconButton compact icon="trash" data-testid={`comment-${id}-delete`} onClick={confirmAndDelete} />
            </>
          )}
        </div>

        {!isEditing && (
          <>
            <div css={Css.mb1.wbbw.$} dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}></div>
            {attachments.nonEmpty && <CommentAttachmentsRow comment={comment} />}
          </>
        )}
        {isEditing && (
          <CommentEditor
            {...{ text, html, tags: tags.map(({ internalUser }) => internalUser.id), attachments }}
            onSave={async (text, html, attachments, mentions) => {
              await onSave(text, html, attachments, mentions);
              setIsEditing(false);
              setText(text);
              setHtml(html);
            }}
            onCancel={() => setIsEditing(false)}
          />
        )}
      </div>
    </div>
  );
}
