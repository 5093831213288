import { Css } from "@homebound/beam";
import { Project, TradesPunchlistPdfQuery, useTradesPunchlistPdfQuery } from "src/generated/graphql-types";
import { fail, queryResult } from "src/utils";
import { ArrayParam, useQueryParams } from "use-query-params";
import { Global } from "@emotion/react";

export function TradesPunchlistPdf() {
  const [{ punchlistItemIds }] = useQueryParams({
    punchlistItemIds: ArrayParam,
  });

  if (!punchlistItemIds?.nonEmpty) fail("Missing punchlistItemIds");

  const query = useTradesPunchlistPdfQuery({
    variables: { punchlistItemIds: punchlistItemIds.map((id) => id!) },
  });

  return queryResult(query, ({ punchlistItemTodos }) => <PunchlistView punchlistItemTodos={punchlistItemTodos} />);
}

type PunchListViewProps = {
  punchlistItemTodos: TradesPunchlistPdfQuery["punchlistItemTodos"];
};

function PunchlistView({ punchlistItemTodos }: PunchListViewProps) {
  const groupedByTrade = punchlistItemTodos
    .filter((p) => p.tradePartner !== undefined)
    .groupByObject((p) => p.tradePartner);

  const today = new Date();
  return (
    <>
      <Global styles={{ "@page": { size: "letter" } }} />
      <div>
        <div /* Header */ css={Css.w100.df.jcc.$}>
          <div css={Css.df.fdc.aic.$}>
            <div>
              <img src="/wordmark.svg" alt="Homebound" css={Css.hPx(18).$} />
            </div>
            <div css={Css.xl3Bd.pPx(2).$}>Punchlist</div>
            <div>{today.toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })}</div>
          </div>
        </div>

        <div css={Css.pPx(24).tal.$}>
          <p css={Css.pb1.$}>Hello,</p>
          <p>
            We are reaching out to share a list of punchlist items that are assigned to you. Please complete these
            requested changes.
          </p>

          <div /* Punchlist Items List */ css={Css.w100.py4.$}>
            <table css={Css.w100.$}>
              <thead>
                <tr>
                  <td css={Css.smMd.pb1.w25.$}>Punchlist Items</td>
                  <td css={Css.smMd.pb1.$}>Room</td>
                  <td css={Css.smMd.pb1.$}>Project</td>
                  <td css={Css.smMd.pb1.$}>Created</td>
                  <td css={Css.smMd.pb1.$}>Due</td>
                </tr>
              </thead>
              <tbody>
                {groupedByTrade.map(([tp, punchlistItems], i) => {
                  return (
                    <>
                      <tr /* Trade Partner */ css={Css.bt.bcGray400.$}>
                        <td css={Css.baseMd.pt1.$}>{tp!.name}</td>
                      </tr>
                      {punchlistItems.map((item, index, array) => {
                        const created = item.createdAt?.toLocaleDateString() ?? "";
                        const due = item.dueDate?.toLocaleDateString() ?? "";
                        const overDue = item.dueDate ? today > new Date(item.dueDate) : false;
                        const isLastItem = item === array.last;
                        return (
                          <>
                            <tr /* Punchlist Item */>
                              <td css={Css.vat.smMd.pt2.$}>{item.name}</td>
                              <td css={Css.vat.pt2.$}>{item.location?.name ?? ""}</td>
                              <td css={Css.vat.pt2.$}>{(item.parent as Project)?.name ?? ""}</td>
                              <td css={Css.vat.pt2.$}>{created}</td>
                              <td css={Css.vat.pt2.if(overDue).red500.$}>{due}</td>
                            </tr>
                            <tr /* Punchlist Item Description */>
                              <td
                                css={Css.wbbw.vat.py1.add("breakInside", "avoid").$}
                                dangerouslySetInnerHTML={{ __html: item.description ?? "" }}
                              />
                            </tr>
                            {/* Add border to last row, except for last punchlist item */}
                            <tr
                              /* Punchlist Images */ css={
                                Css.if(!isLastItem).bb.bcGray400.add("breakInside", "avoid").$
                              }
                            >
                              {item.attachments.nonEmpty && (
                                <td css={Css.pb2.$} colSpan={5}>
                                  {item.attachments.map(
                                    (a) =>
                                      a.asset.previewUrl && (
                                        <div key={a.asset.id} css={Css.hPx(75).wPx(75).df.fdc.aic.jcc.br16.bgGray400.$}>
                                          <img css={Css.maxw100.maxh100.$} src={a.asset.previewUrl} alt="attachment" />
                                        </div>
                                      ),
                                  )}
                                </td>
                              )}
                            </tr>
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
