import { ButtonGroup, Css, LoadingSkeleton, useTestIds } from "@homebound/beam";
import { useCallback } from "react";
import { useUpcomingTasksQuery } from "src/generated/graphql-types";
import { EmptyStateWrapper, shouldShowEmptyState } from "src/routes/personal-dashboard/components/EmptyStateWrapper";
import { useDashboardFilterContext } from "src/routes/personal-dashboard/DashboardFilterContext";
import { UpcomingView } from "src/routes/personal-dashboard/enums";
import { thirtyDaysAgo } from "src/routes/personal-dashboard/utils";
import { queryResult } from "src/utils";
import { nonClosedProjects } from "src/utils/projects";
import { StringParam, useQueryParams } from "use-query-params";
import { PersonalDashboardTitle } from "../../components/PersonalDashboardTitle";
import { UpcomingCalendarView } from "./UpcomingCalendarView";
import { UpcomingTasksTable } from "./UpcomingTasksTable";

export function UpcomingPage() {
  const tids = useTestIds({}, "dashboardUpcomingPage");
  const { filter } = useDashboardFilterContext();
  const showViewOptions = !shouldShowEmptyState(filter);

  const { upcomingView, toggleUpcomingView } = useUpcomingViewParam();

  return (
    <div {...tids} css={Css.h100.df.fdc.$}>
      <div css={Css.df.jcsb.aic.mb2.$}>
        <PersonalDashboardTitle title="Schedule Tasks" />
        {showViewOptions && (
          <div css={Css.df.baseMd.gap2.aic.$}>
            View
            <ButtonGroup
              buttons={[
                {
                  icon: "projects",
                  tooltip: "List View",
                  active: upcomingView === UpcomingView.List,
                  onClick: toggleUpcomingView,
                },
                {
                  icon: "calendar",
                  tooltip: "Calendar View",
                  active: upcomingView === UpcomingView.Calendar,
                  onClick: toggleUpcomingView,
                },
              ]}
              {...tids}
            />
          </div>
        )}
      </div>
      <EmptyStateWrapper title="upcoming tasks">
        <UpcomingTasksData />
      </EmptyStateWrapper>
    </div>
  );
}

function UpcomingTasksData() {
  const { filter, selectedTaskId, openTaskDetailPane } = useDashboardFilterContext();

  const query = useUpcomingTasksQuery({
    variables: {
      internalUser: filter.internalUser,
      project: filter.project,
      endDateOnOrAfter: thirtyDaysAgo(),
      projectStatus: nonClosedProjects,
    },
    skip: !filter.internalUser,
    fetchPolicy: "cache-first",
  });

  const tasksById = query.data?.scheduleTasks.keyBy((t) => t.id);

  const onTaskSelect = useCallback(
    (taskId: string, tabSelected?: string, scrollIntoViewType?: string) => {
      // Not having that task in the lookup should only happen during initial load during which we don't expect this callback to be fired
      if (!tasksById || !tasksById[taskId]) return null;
      openTaskDetailPane(tasksById[taskId], tabSelected, scrollIntoViewType);
    },
    [tasksById, openTaskDetailPane],
  );

  const { upcomingView } = useUpcomingViewParam();

  return queryResult(query, {
    data: ({ scheduleTasks, taskStatuses }) =>
      upcomingView === UpcomingView.List ? (
        <UpcomingTasksTable scheduleTasks={scheduleTasks} taskStatuses={taskStatuses} onTaskSelect={onTaskSelect} />
      ) : (
        <UpcomingCalendarView
          scheduleTasks={scheduleTasks}
          selectedTaskId={selectedTaskId}
          onTaskSelect={onTaskSelect}
        />
      ),
    loading: () => <LoadingSkeleton rows={10} columns={3} size="lg" />,
  });
}

export function useUpcomingViewParam() {
  const [{ upcomingView }, setQueryParams] = useQueryParams({ upcomingView: StringParam });
  // Initial route won't have a param set, but we always default to the list view
  const currentView = upcomingView ?? UpcomingView.List;

  function toggleUpcomingView() {
    const newView = currentView === UpcomingView.List ? UpcomingView.Calendar : UpcomingView.List;
    setQueryParams({ upcomingView: newView }, "pushIn");
  }
  return { upcomingView: currentView, toggleUpcomingView };
}
