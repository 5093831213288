import {
  Button,
  Chips,
  column,
  Css,
  emptyCell,
  GridTable,
  GridTableApi,
  numericColumn,
  ScrollableContent,
  ScrollableParent,
  selectColumn,
  simpleDataRows,
  SimpleHeaderAndData,
  Tooltip,
  useComputed,
  useGridTableApi,
  useModal,
} from "@homebound/beam";
import { useCallback, useMemo } from "react";
import {
  ChangeEventStatus,
  CommitmentStatus,
  ReleasePOsStep_CommitmentFragment,
  SaveCommitmentInput,
  useReleasePOsStepQuery,
  useUpdateCommitmentAutoReleaseStatusMutation,
} from "src/generated/graphql-types";
import { commitmentStatusToTagTypeMapper, queryResult } from "src/utils";
import { useManagePosWizardContext } from "./useManagePosWizard";
import { WizardHeader } from "src/components/stepper/useStepperWizard/WizardHeader";
import { BannerNotice, Icon, priceCell, tagCell } from "src/components";
import { StepActions } from "src/components/stepper";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";

export function ReleasePOsStep() {
  const { bcrId } = useManagePosWizardContext();
  const query = useReleasePOsStepQuery({ variables: { id: bcrId }, skip: !bcrId, fetchPolicy: "network-only" });

  return (
    <ScrollableParent>
      <WizardHeader
        pre="Manage Purchase Orders"
        header="Select POs to release"
        post="Below are autogenerated POs that reflect contract changes. Select and issue the new POs to send to trade partners."
        uncloseable
      >
        {queryResult(query, {
          data: ({ bidContractRevision }) => <ReleasePOsStepTable commitments={bidContractRevision.commitments} />,
          // Something is glitching out and populating, clearing out, and repopulating `data`, and `previousData`
          // isn't catching it, so just force Loader to be shown while that's settling down to prevent passing
          // <ReleasePOsStepTable /> undefined (when it isn't even nullable)
          showLoading: "always",
        })}
      </WizardHeader>
    </ScrollableParent>
  );
}

type ReleasePOsStepTableProps = {
  commitments: ReleasePOsStep_CommitmentFragment[];
};

function ReleasePOsStepTable({ commitments }: ReleasePOsStepTableProps) {
  const relevantCommitments = useMemo(
    () => commitments.filter((c) => c.status === CommitmentStatus.Draft),
    [commitments],
  );
  const { changeEventIds } = useManagePosWizardContext();
  const { closeModal } = useModal();
  const api = useGridTableApi<Row>();
  const releaseOrReadyCommitments = useReleaseOrReadyCommitments(api);
  const isAnythingPendingApproval = relevantCommitments.some(commitmentRequiresApproval);
  const columns = useMemo(() => createColumns(), []);
  const rows = useMemo(
    () =>
      simpleDataRows(relevantCommitments).map((r) => ({
        ...r,
        initSelected: (r.kind === "data" && r.data.releaseWhenReady) ?? false,
      })),
    [relevantCommitments],
  );
  const noRowsHaveToggled = useComputed(() => {
    const selected = api.getSelectedRowIds("data");
    return !api.getVisibleRows("data").some((r) => selected.includes(r.id) !== r.data.releaseWhenReady);
  }, [api]);
  // Even if the page is blank, there might be CEs that need to be Opened for Approval, so only show `Exit` if there's nothing left to do
  const showExitButton = changeEventIds.isEmpty && noRowsHaveToggled;

  return (
    <ScrollableContent>
      {isAnythingPendingApproval && (
        <div css={Css.mt3.$}>
          <BannerNotice icon="infoCircle" message="Some purchase orders need approval before they can be released" />
        </div>
      )}
      <GridTable<Row>
        stickyHeader
        fallbackMessage="No available purchase orders to manage"
        columns={columns}
        rows={rows}
        api={api}
      />
      {showExitButton ? (
        <StepActions>
          <Button label="Exit" onClick={closeModal} size="lg" variant="secondary" />
        </StepActions>
      ) : (
        <NextStepButton
          label={noRowsHaveToggled ? "Proceed to Approvals" : "Confirm Release"}
          variant={noRowsHaveToggled ? "secondary" : "primary"}
          disableCurrentStepOnLeaving
          onClick={releaseOrReadyCommitments}
        />
      )}
    </ScrollableContent>
  );
}

type Row = SimpleHeaderAndData<ReleasePOsStep_CommitmentFragment>;

const createColumns = () => [
  selectColumn<Row>(),
  column<Row>({
    header: "PO #",
    data: (c) => c.accountingNumber,
  }),
  column<Row>({
    header: "Address",
    data: (c) => c.project.name,
  }),
  column<Row>({
    header: "Plan",
    data: (c) => c.project.readyPlanConfig?.readyPlan?.displayName,
  }),
  column<Row>({
    header: "Cost Codes",
    data: (c) => <Chips values={c.costCodes.map((cc) => cc.number)} />,
  }),
  column<Row>({
    header: "New Trade Partner",
    data: (c) => c.tradePartner?.name,
  }),
  column<Row>({
    header: "Contract #",
    data: (c) => c.bidContractRevision?.version,
  }),
  numericColumn<Row>({
    header: "Old Cost",
    data: (c) => priceCell({ valueInCents: c.voidsCommitments.sum((c) => c.costChangeInCents) }),
  }),
  numericColumn<Row>({
    header: "Proposed Cost",
    data: (c) => priceCell({ valueInCents: c.costChangeInCents }),
  }),
  numericColumn<Row>({
    header: "Cost Change",
    data: (c) =>
      priceCell({
        valueInCents: c.costChangeInCents - c.voidsCommitments.sum((c) => c.costChangeInCents),
        displayDirection: true,
        zeroIs: "neutral",
      }),
  }),
  column<Row>({
    header: "Status",
    data: (c) => tagCell(commitmentStatusToTagTypeMapper[c.status], c.statusText),
  }),
  // Alert ⚠️ column for POs that require Approvals
  column<Row>({
    header: "",
    data: (c) =>
      commitmentRequiresApproval(c) ? (
        <Tooltip title="This draft PO requires approval before release">
          <Icon icon="alertInfo" />
        </Tooltip>
      ) : (
        emptyCell
      ),
  }),
];

function commitmentRequiresApproval(commitment: ReleasePOsStep_CommitmentFragment): boolean {
  // If no CE, then it's ready to release (or at least not blocked by Approval)
  if (!commitment.changeEvent) return false;
  // Otherwise, expect an Approval for Non-Finalized CEs
  return ![ChangeEventStatus.Accepted, ChangeEventStatus.Rejected].includes(commitment.changeEvent.status);
}

/**
 * Takes the selected rows in the Table and partitions them out into commitments that may be release
 * now, and those who must update their `releaseWhenReady` flag to go out after Approval.
 */
function useReleaseOrReadyCommitments(api: GridTableApi<Row>) {
  const { closeModal } = useModal();
  const [updateAutoReleaseStatus] = useUpdateCommitmentAutoReleaseStatusMutation();

  return useCallback(async () => {
    const selectedCommitmentIds = api.getSelectedRowIds("data");
    // Anything not waiting on Approval can release now, other Commitments need to update their `releaseWhenReady` flag
    const [requiresApproval, readyToRelease] = api
      .getVisibleRows("data")
      .partition((row) => commitmentRequiresApproval(row.data));

    const releaseNowInput = readyToRelease.filter((row) => selectedCommitmentIds.includes(row.id)).map((row) => row.id);
    const commitmentReleaseFlagInputs = requiresApproval
      // Only send mutations for rows that actually changed from their current status
      .filter((row) => row.data.releaseWhenReady !== selectedCommitmentIds.includes(row.id))
      .map<SaveCommitmentInput>((row) => ({
        id: row.id,
        releaseWhenReady: selectedCommitmentIds.includes(row.id),
      }));

    if (commitmentReleaseFlagInputs.isEmpty && releaseNowInput.isEmpty) return;
    // ignore `data` response because that's irrelevant to the next Approval step and we don't need it for anything
    const { errors } = await updateAutoReleaseStatus({
      variables: {
        commitmentReleaseFlagUpdates: commitmentReleaseFlagInputs,
        commitmentsToReleaseNow: releaseNowInput,
        // `releaseBidToCommitments` will break if called with empty input, so skip that half of the mutation if its inputs are empty
        skipRelease: releaseNowInput.isEmpty,
      },
    });

    // Errors are likely being displayed underneath the Wizard/Modal, so close it to reveal them
    if (errors?.nonEmpty) closeModal();
  }, [api, closeModal, updateAutoReleaseStatus]);
}
