import {
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Chips,
  Css,
  FieldGroup,
  FormLines,
  StaticField,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Fragment } from "react";
import { emptyCellDash } from "src/components";
import { Card } from "src/components/Card";
import { CommentFeed2 } from "src/components/comments2/CommentFeed";
import {
  SaveDevelopmentCommitmentInput,
  useDevelopmentCommitmentOverviewTabQuery,
  useSaveDevelopmentCommitmentMutation,
} from "src/generated/graphql-types";
import { centsToDollars, formatNumberToString, hasData, renderLoadingOrError, stageCodeToNameMapper } from "src/utils";

export function OverviewTab({ developmentCommitmentId }: { developmentCommitmentId: string }) {
  const query = useDevelopmentCommitmentOverviewTabQuery({
    variables: { id: developmentCommitmentId },
  });
  const [saveDevelopmentCommitment] = useSaveDevelopmentCommitmentMutation();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: query.data?.developmentCommitment,
      map: (dc) => ({
        id: dc.id,
        internalNote: dc.internalNote,
        name: dc.name,
        tradePartnerId: dc.tradePartner?.id,
        developmentId: dc.development?.id,
      }),
    },
    autoSave: async ({ changedValue }) => {
      await saveDevelopmentCommitment({ variables: { input: changedValue } });
      formState.commitChanges();
    },
  });

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const dc = query.data.developmentCommitment;
  const totalCommitted = dc.committedInCents
    ? `$${formatNumberToString(centsToDollars(dc.committedInCents))}`
    : emptyCellDash;

  return (
    <>
      <div css={Css.df.gap3.aifs.$}>
        <Card>
          <h2 css={Css.mb3.baseMd.$}>Details</h2>
          <FormLines width="full">
            <FieldGroup widths={["183px", "183px", "183px"]}>
              <StaticField label="Total Committed" value={totalCommitted} />
              <StaticField label="Market" value={dc.market.name} />
              <StaticField label="Stage" value={stageCodeToNameMapper[dc.stage]} />
            </FieldGroup>
            <StaticField label="Cost Types">
              {dc.costTypes && dc.costTypes?.length > 0 ? (
                <Chips values={dc.costTypes?.map((ct) => ct.name)} />
              ) : (
                emptyCellDash
              )}
            </StaticField>
          </FormLines>
          {!dc.canBeEdited ? (
            <FormLines width="full">
              <StaticField label="Commitment Name" value={dc.name} />
              <StaticField label="Trade Partner" value={dc.tradePartner?.name} />
              <StaticField label="Development" value={dc.development.name} />
              <StaticField label="Internal Note" value={dc.internalNote || emptyCellDash} />
            </FormLines>
          ) : (
            <FormLines width="full">
              <BoundTextField field={formState.name} label="Commitment Name" />
              <BoundSelectField field={formState.tradePartnerId} options={query.data.tradePartners || []} />
              <StaticField label="Development" value={dc.development.name} />
              <BoundTextAreaField field={formState.internalNote} label="Internal Note (optional)" />
            </FormLines>
          )}
        </Card>
        <Card>
          <div css={Css.maxw("360px").mla.$}>
            <div css={Css.mt6.mb2.br8.p2.bgGray100.$} data-testid="costCodeSummary">
              <h2 css={Css.baseMd.$}>Cost Codes</h2>
              {/* using a one-off here but replace when have a Beam static grid table */}
              {dc.costCodeSummary.length > 0 ? (
                <div
                  data-testid="costCodeSummaryTable"
                  css={{
                    ...Css.dg.gtc("auto 200px auto auto").mt3.rg1.gray700.$,
                    "& > div:nth-of-type(-n+4)": Css.tinySb.$,
                    "& > div:nth-of-type(n+5)": Css.asc.xs.$,
                    "& > div:nth-of-type(4n+3)": Css.tar.$,
                    "& > div:nth-of-type(4n+4)": Css.tar.$,
                  }}
                >
                  <div>Code</div>
                  <div>Description</div>
                  <div>Projects</div>
                  <div>Qty</div>
                  {dc.costCodeSummary.map((item) => {
                    return (
                      <Fragment key={item.costCode.id}>
                        <div>{item.costCode.number}</div>
                        <div>{item.costCode.name}</div>
                        <div>{item.numberOfProjects}</div>
                        <div>{item.quantity}</div>
                      </Fragment>
                    );
                  })}
                </div>
              ) : (
                <p css={Css.mt3.xs.$}>No cost codes exist for development commitment</p>
              )}
            </div>
            <CommentFeed2 commentable={dc} />
          </div>
        </Card>
      </div>
    </>
  );
}

const formConfig: ObjectConfig<
  Pick<SaveDevelopmentCommitmentInput, "id" | "internalNote" | "name" | "tradePartnerId" | "developmentId">
> = {
  id: { type: "value" },
  internalNote: { type: "value" },
  name: { type: "value", rules: [required] },
  tradePartnerId: { type: "value", rules: [required] },
  developmentId: { type: "value", rules: [required] },
};
