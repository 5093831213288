import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { initPageSettings, PageSettings, toFirstAndOffset } from "src/components/Pagination";
import {
  BidItemFilter,
  ItemCatalogDetailsFragment,
  ItemCatalogQueryHookResult,
  useItemCatalogQuery,
} from "src/generated/graphql-types";
import useQueryString from "src/hooks/useQueryString";

type ItemCatalogState = {
  bidItems: ItemCatalogDetailsFragment[];
  settings: ItemCatalogQueryParams;
  setSettings: Dispatch<SetStateAction<ItemCatalogQueryParams>>;
  setSearch: Dispatch<SetStateAction<string>>;
  refetch: ItemCatalogQueryHookResult["refetch"];
  loading: boolean;
  hasNextPage: boolean;
};

export type ItemCatalogQueryParams = Omit<BidItemFilter, "search"> & PageSettings;

export function useItemCatalog(): ItemCatalogState {
  const [search, setSearch] = useState<string>("");
  const [settings, setSettings] = useQueryString<ItemCatalogQueryParams>({
    page: initPageSettings.page,
    perPage: initPageSettings.perPage,
  });
  const { page, perPage, ...filterInput } = settings;

  const { data, loading, refetch } = useItemCatalogQuery({
    variables: {
      filter: { ...filterInput, search },
      ...toFirstAndOffset(page, perPage),
    },
  });

  const bidItems = useMemo(() => data?.bidItemsPage.bidItems ?? [], [data]);
  const hasNextPage = useMemo(() => data?.bidItemsPage.pageInfo.hasNextPage ?? false, [data]);

  return {
    bidItems,
    settings,
    setSettings,
    setSearch,
    refetch,
    loading,
    hasNextPage,
  };
}
