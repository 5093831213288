import { PlanEditorHeader } from "src/routes/components/PlanConfiguration/PlanEditorHeader";
import { createPlanDetailsUrl } from "src/RouteUrls";
import { ReactNode } from "react";
import { useParams } from "react-router";
import { DevelopmentParams, PlanDetailsParams } from "src/routes/routesDef";
import { useEditOptionsHeaderQuery } from "src/generated/graphql-types";

type EditOptionsHeaderProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  tooltip?: ReactNode;
  right?: ReactNode;
};

export function EditOptionsHeader({ title, subtitle, tooltip, right }: EditOptionsHeaderProps) {
  const params = useParams<DevelopmentParams & PlanDetailsParams>();
  const { developmentId, readyPlanId } = params;

  const { data } = useEditOptionsHeaderQuery({
    variables: { id: readyPlanId },
    skip: !readyPlanId,
    fetchPolicy: "cache-first",
  });
  const planName = data?.readyPlan.name;
  const developmentName = data?.readyPlan.development?.name;

  return (
    <PlanEditorHeader
      title={title}
      subtitle={subtitle}
      tooltip={tooltip}
      right={right}
      backUrl={createPlanDetailsUrl(developmentId, readyPlanId)}
      breadcrumbs={[
        { href: createPlanDetailsUrl(developmentId, readyPlanId), text: developmentName ? developmentName : "Plans" },
        { text: planName ? `Editing '${planName}'` : "Create New ReadyPlan" },
      ]}
    />
  );
}
