import { Avatar, AvatarProps, Chip, Css, useTestIds } from "@homebound/beam";
import { FormattedDate } from "src/components";
import { ApproverDetailFragment, ApproverStatus, UserType } from "src/generated/graphql-types";
import { approverChipTypeMapper } from "src/utils";

type ApproverDetailProps = {
  approver: Partial<Omit<ApproverDetailFragment, "__typename" | "user">> & Pick<ApproverDetailFragment, "user">;
};

export function ApproversDetail({ approver }: ApproverDetailProps) {
  const { user, status, statusUpdatedAt, requireSignature } = approver;
  const tid = useTestIds({}, "approvers");
  const [type, label] = status ? approverChipTypeMapper(status, requireSignature) : [];

  return (
    <div css={Css.df.fdr.aic.oh.gap1.$}>
      {user.__typename === "InternalUser" ? (
        <Avatar {...tid.avatar} src={user.avatar} name={user.name} showName size="sm" />
      ) : user.__typename === "TradePartnerUser" ? (
        <ApprovalAvatar {...tid.avatar} name={user.name} caption={user.tradePartner.name} />
      ) : (
        <ApprovalAvatar {...tid.avatar} name={user.name} caption="HO" />
      )}
      {status && status.code !== ApproverStatus.Pending && (
        <Chip {...tid.statusTag} type={type} text={label || ""} compact />
      )}
      <FormattedDate
        date={statusUpdatedAt ?? undefined}
        dateFormatStyle="short"
        timeFormatStyle="short"
        xss={Css.tiny.$}
      />
    </div>
  );
}

// Beam's Avatar will calculate initials from the name, even if that name has extra data (eg, that it's a homeowner or
// the specific trade) so we need a way to separate the initials from the name
export function ApprovalAvatar({ name, caption, ...rest }: { name: string; caption: string }) {
  const tid = useTestIds(rest, "avatar");
  const px = 24;
  const styles = Css.br100.wPx(px).hPx(px).mwPx(px).oh.$;
  return (
    <div css={Css.dif.aic.gap1.$}>
      <div css={{ ...styles, ...Css.sm.bgGray400.gray100.df.aic.jcc.$ }} {...tid}>
        {nameToInitials(name)}
      </div>
      <span css={Css.smMd.$}>{`${name} (${caption})`}</span>
    </div>
  );
}

// copied from beam
function nameToInitials(name: string) {
  return (
    name
      .split(" ")
      .map((n) => (n.length > 0 ? n[0].toUpperCase() : ""))
      .join("")
      // Return at most 3 initials
      .slice(0, 3)
  );
}
