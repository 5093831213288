import { Css, FullBleed, ScrollableContent } from "@homebound/beam";
import { useParams, useRouteMatch } from "react-router";
import {
  GlobalOptionTypeDrawerFragment,
  ReleaseToTradesTabLotConfigFragment,
  ScopeMode,
  useReleaseToTradesTabQuery,
} from "src/generated/graphql-types";
import { Cohort } from "src/routes/developments/lot-summary/lot-release/components/Cohort";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DevelopmentParams, developmentPaths } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { LotSummaryGroupBy, LotSummaryPageFiltersProps } from "../sequence-sheet/components/utils";
import { useEffect } from "react";

export function ReleaseToTradesTab({
  queryFilter,
  searchFilter,
  groupBy,
  setSummaryProjectIds,
}: LotSummaryPageFiltersProps) {
  const { developmentId } = useParams<DevelopmentParams>();
  const isLotReleasePage = Boolean(useRouteMatch([developmentPaths.lotSummaryRelease, developmentPaths.lotSummary]));
  const query = useReleaseToTradesTabQuery({
    variables: { filter: { development: [developmentId], ...queryFilter } },
  });
  return (
    <>
      {!isLotReleasePage && <PageHeader title="Lot Summary" />}
      {queryResult(query, (data) => (
        <ReleaseToTrades
          configs={data.projectReadyPlanConfigs ?? []}
          developmentId={developmentId}
          searchFilter={searchFilter}
          globalOptionTypes={data.globalOptionTypes}
          groupBy={groupBy}
          setSummaryProjectIds={setSummaryProjectIds}
        />
      ))}
    </>
  );
}

function ReleaseToTrades(props: {
  configs: ReleaseToTradesTabLotConfigFragment[];
  developmentId: string;
  searchFilter?: string;
  groupBy: LotSummaryGroupBy;
  globalOptionTypes: GlobalOptionTypeDrawerFragment[];
  setSummaryProjectIds?: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const { configs, developmentId, searchFilter, globalOptionTypes, groupBy, setSummaryProjectIds } = props;

  useEffect(() => {
    setSummaryProjectIds?.(configs.map((c) => c.project.id));
  }, [configs, setSummaryProjectIds]);

  return (
    <FullBleed>
      <div css={Css.bgGray100.$}>
        <ScrollableContent>
          {groupBy === LotSummaryGroupBy.cohort && (
            <ReleaseToTradesGroupByCohort
              configs={configs}
              developmentId={developmentId}
              searchFilter={searchFilter}
              globalOptionTypes={globalOptionTypes}
            />
          )}
          {groupBy === LotSummaryGroupBy.none && (
            <ReleaseToTradesGroupByNone
              configs={configs}
              developmentId={developmentId}
              searchFilter={searchFilter}
              globalOptionTypes={globalOptionTypes}
            />
          )}
        </ScrollableContent>
      </div>
    </FullBleed>
  );
}

function ReleaseToTradesGroupByCohort(props: {
  configs: ReleaseToTradesTabLotConfigFragment[];
  developmentId: string;
  searchFilter?: string;
  globalOptionTypes: GlobalOptionTypeDrawerFragment[];
}) {
  const { configs, developmentId, searchFilter, globalOptionTypes } = props;
  const cohorts = configs.groupBy(({ project }) => project.cohort?.name ?? "No Cohort");
  const sortedCohorts = Object.keys(cohorts).sortBy((name) =>
    cohorts[name].first!.project.cohort ? cohorts[name].first!.project.cohort?.createdAt ?? new Date() : new Date(),
  );
  const filteredCohorts = sortedCohorts.filter((name) =>
    cohorts[name].some((c) => c.project.scopeMode.code !== ScopeMode.None),
  );
  return (
    <>
      {filteredCohorts.map((cohortName) => (
        <Cohort
          key={cohortName}
          cohortName={cohortName}
          configs={cohorts[cohortName]}
          developmentId={developmentId}
          searchFilter={searchFilter}
          globalOptionTypes={globalOptionTypes}
        />
      ))}
    </>
  );
}

function ReleaseToTradesGroupByNone(props: {
  configs: ReleaseToTradesTabLotConfigFragment[];
  developmentId: string;
  searchFilter?: string;
  globalOptionTypes: GlobalOptionTypeDrawerFragment[];
}) {
  const { configs, developmentId, searchFilter, globalOptionTypes } = props;
  return (
    <>
      <Cohort
        configs={configs.filter((name) => name?.project?.scopeMode.code !== ScopeMode.None)}
        developmentId={developmentId}
        searchFilter={searchFilter}
        globalOptionTypes={globalOptionTypes}
      />
    </>
  );
}
