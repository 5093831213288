import {
  Button,
  Chip,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectField,
  Tooltip,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import {
  CopyItemTemplateItemsInput,
  SaveItemTemplateItemVersionInput,
  TakeoffsStore_CopyItemTemplateItemsMutation,
  useItemTemplatesQuery,
} from "src/generated/graphql-types";
import { itemTemplateStatusToTagType } from "src/utils";
import { FetchResult } from "@apollo/client";
import { CsvUploader } from "src/components/CsvUploader";
import { SaveItivResult } from "../TakeoffsManagerContext";
import { useTakeoffUploader } from "./useTakeoffUploader";

export type AddItemsFromExistingPlanModalProps = {
  templateId: string;
  copyFromTemplate: (
    input: CopyItemTemplateItemsInput,
  ) => Promise<FetchResult<TakeoffsStore_CopyItemTemplateItemsMutation>>;
  addItiv: (item: SaveItemTemplateItemVersionInput | SaveItemTemplateItemVersionInput[]) => SaveItivResult;
};

export function AddItemsFromExistingPlanModal(props: AddItemsFromExistingPlanModalProps) {
  const { templateId, copyFromTemplate, addItiv } = props;
  const testid = useTestIds(props);
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [sourceTemplateId, setSourceTemplateId] = useState<string>();
  const [usingCsv, setUsingCsv] = useState(false);
  const query = useItemTemplatesQuery({
    variables: { filter: { isPlanPackage: true, isLatest: true } },
  });
  const itemTemplateOptions = useMemo(
    () => query.data?.itemTemplates.filter((it) => it.id !== templateId).sortBy((it) => it.displayName) ?? [],
    [query, templateId],
  );
  const { errors, addError, handleOnDrop, handleOnClick, importDataReady } = useTakeoffUploader(addItiv);

  const maybeTriggerNotice = useCallback(
    (addedCount: number) => {
      const [sourceTemplate] = (query.data?.itemTemplates ?? []).filter((item) => item.id === sourceTemplateId);
      if (sourceTemplate) {
        triggerNotice({
          message: `${addedCount} items were successfully added from ${sourceTemplate.name}`,
        });
      }
    },
    [query.data?.itemTemplates, sourceTemplateId, triggerNotice],
  );

  const onClickHandler = useCallback(async () => {
    if (usingCsv) {
      await handleOnClick();
    } else if (sourceTemplateId) {
      const result = await copyFromTemplate({
        targetTemplateId: templateId,
        sourceTemplateId: sourceTemplateId!,
      });
      const items = result.data?.copyItemTemplateItems?.copiedItemVersions ?? [];
      maybeTriggerNotice(items.length);
    }
    closeModal();
  }, [templateId, sourceTemplateId, copyFromTemplate, maybeTriggerNotice, closeModal, usingCsv, handleOnClick]);

  const handleOnDropCsv = useCallback(
    async (...params: Parameters<typeof handleOnDrop>) => {
      setUsingCsv(true);
      setSourceTemplateId(undefined);
      await handleOnDrop(...params);
    },
    [setUsingCsv, handleOnDrop],
  );

  return (
    <>
      <ModalHeader>Import Items</ModalHeader>
      <ModalBody>
        <div css={Css.mt2.mb1.$}>
          <>
            <div css={Css.sm.gray800.$}>
              Import items from a <span css={Css.blue600.$}>.csv template</span> or from another plan
            </div>
            <label css={{ ...Css.gray700.smMd.mt3.db.$, "& button": Css.bcBlue600.bw2.bgBlue50.$ }}>
              Upload Takeoffs
              <CsvUploader errors={errors} onError={addError} onDrop={handleOnDropCsv} />
            </label>
            <div css={Css.mt2.mb1.$}>
              <SelectField
                {...testid.selectTemplate}
                label="Import from another plan"
                value={sourceTemplateId}
                onSelect={setSourceTemplateId}
                options={itemTemplateOptions}
                getOptionLabel={(template) =>
                  `${template.displayName}${template.isVersioned ? ` - v${template.version}` : ""}`
                }
                getOptionMenuLabel={(template) => (
                  <div css={Css.df.jcc.aic.$}>
                    <span css={Css.wPx(310).mrPx(20).$}>{template.displayName}</span>
                    {template.isVersioned && (
                      <span>
                        <Tooltip title={template.status} placement="right">
                          <Chip
                            text={`v${template.version}`}
                            type={itemTemplateStatusToTagType(template.status)}
                            compact
                          />
                        </Tooltip>
                      </span>
                    )}
                  </div>
                )}
                getOptionValue={(template) => template.id}
                disabled={query.loading || usingCsv}
                placeholder={
                  usingCsv
                    ? "You are importing from a csv file"
                    : query.loading
                      ? "Loading templates..."
                      : "Select plan"
                }
              />
            </div>
          </>
        </div>
      </ModalBody>
      <ModalFooter>
        <div css={Css.df.gap1.$}>
          <Button label="Cancel" variant="tertiary" onClick={closeModal} />
          <Button
            label="Add Items"
            variant="primary"
            disabled={usingCsv ? !importDataReady || errors.nonEmpty : !sourceTemplateId}
            onClick={onClickHandler}
            {...testid.addItemsbutton}
          />
        </div>
      </ModalFooter>
    </>
  );
}
