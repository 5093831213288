import {
  Css,
  FilterDefs,
  Filters,
  GridColumn,
  GridTable,
  NestedOption,
  ScrollableParent,
  column,
  emptyCell,
  treeFilter,
  usePersistedFilter,
} from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Price } from "src/components";
import {
  Maybe,
  ReviewReadyPlanCostsRpFragment,
  RpCostsFragment,
  useReviewReadyPlanCostsFiltersQuery,
  useReviewReadyPlanCostsQuery,
} from "src/generated/graphql-types";
import { DevelopmentParams } from "src/routes/routesDef";
import { isDefined } from "src/utils";

export function ReviewCostsPage() {
  const { developmentId } = useParams<DevelopmentParams>();

  const { data: filterData } = useReviewReadyPlanCostsFiltersQuery({ variables: { developmentId } });

  const globalOptionsTree = useMemo(() => optionsTree(filterData?.readyPlans), [filterData]);
  const filterDefs: FilterDefs<RpCostsFilter> = useMemo(() => {
    const itemTemplatesByReadyPlan: NestedOption<OptionType>[] = (
      filterData?.itemTemplates.groupByObject(
        (it) =>
          filterData?.readyPlans.find((rp) => rp.id === it.readyPlan?.id) ??
          fail(`ReadyPlan not found for template: ${it.id}`),
      ) ?? []
    ).map(([readyPlan, itemTemplates]) => ({
      id: readyPlan.id,
      name: readyPlan.displayName,
      children: itemTemplates.map((it) => ({ id: it.id, name: it.displayVersion })).reverse(),
    }));

    const readyPlans = treeFilter({
      filterBy: "leaf",
      defaultCollapsed: true,
      label: "Ready Plans",
      options: itemTemplatesByReadyPlan,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });

    const configureGlobalOptionIds = treeFilter({
      filterBy: "leaf",
      defaultCollapsed: true,
      label: "Configure Options",
      options: globalOptionsTree,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });

    const deltaGlobalOptionIds = treeFilter({
      filterBy: "leaf",
      defaultCollapsed: true,
      label: "Delta Options",
      options: globalOptionsTree,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    return { readyPlans, configureGlobalOptionIds, deltaGlobalOptionIds };
  }, [filterData, globalOptionsTree]);

  const { filter, setFilter } = usePersistedFilter({ storageKey: "reviewCostsPage", filterDefs });
  const { data } = useReviewReadyPlanCostsQuery({
    variables: {
      input: {
        readyPlanOrVersionIds: filter.readyPlans!,
        globalOptionIds: filter.configureGlobalOptionIds!,
        deltaForGlobalOptionOptionIds: filter.deltaGlobalOptionIds,
      },
    },
    skip: !filter.readyPlans?.length || !filter.configureGlobalOptionIds?.length,
  });
  const columns = useCreateColumns(data?.computeReadyPlanCosts.readyPlanCosts ?? []);
  const rows = useMemo(
    () =>
      createRows(
        data?.computeReadyPlanCosts.readyPlanCosts ?? [],
        globalOptionsTree
          .flatMap((gotype) => gotype.children)
          .compact()
          .filter((go) => (filter?.deltaGlobalOptionIds ?? []).includes(go.id)),
      ),
    [data, filter, globalOptionsTree],
  );

  return (
    <div>
      {filterData && <Filters filterDefs={filterDefs} filter={filter} onChange={setFilter} />}
      <ScrollableParent xss={Css.px8.$}>
        <GridTable columns={columns} rows={rows} />
      </ScrollableParent>
    </div>
  );
}

type ReadyPlanRow = { id: "expandableHeader"; kind: "expandableHeader"; data: RpCostsFragment[] };
type RequiredOptionsRow = { id: "header"; kind: "header"; data: RpCostsFragment[] };
type TotalCostRow = { id: "totalCost"; kind: "totalCost"; data: RpCostsFragment[] };
type DeltaOptionRow = {
  id: string;
  kind: "deltaOption";
  data: { option: NestedOption<OptionType>; rpCosts: RpCostsFragment[] };
};
type Row = ReadyPlanRow | RequiredOptionsRow | TotalCostRow | DeltaOptionRow;

const useCreateColumns = (allRpCosts: RpCostsFragment[]): GridColumn<Row>[] => {
  return useMemo(() => {
    return [
      column<Row>({
        id: "options",
        expandableHeader: "Ready Plan Version",
        header: "Required Options",
        totalCost: "Total Cost",
        deltaOption: ({ option }, { row: { id } }) => option.name,
        w: "200px",
      }),
      ...allRpCosts.map((rpCosts, index) => {
        return column<Row>({
          id: rpCosts.itemTemplate.id,
          expandableHeader: () => `${rpCosts.itemTemplate.displayName} - ${rpCosts.itemTemplate.displayVersion}`,
          header: (rpCosts) => `${rpCosts[index].computedCosts.length} permutations`,
          totalCost: (rpCosts) => {
            const costs = rpCosts[index].computedCosts.map(({ totalCostInCents }) => totalCostInCents);
            return <MaybePriceRange min={costs.min()} max={costs.max()} />;
          },
          deltaOption: ({ rpCosts }, { row: { id } }) => {
            const costs = rpCosts[index].computedCosts.map(
              ({ deltas }) => deltas.find((delta) => rpoToGoId(delta.readyPlanOption) === id)?.totalCostInCents ?? 0,
            );
            return <MaybePriceRange min={costs.min()} max={costs.max()} />;
          },
          // data: rpCosts,
          initExpanded: false,
          hideOnExpand: true,
          expandColumns: rpCosts.computedCosts.map((computedCost, permIndex) =>
            column<Row>({
              expandableHeader: emptyCell,
              header: (rpCosts) =>
                rpCosts[index].computedCosts[permIndex].readyPlanOptions.map((rpo) => rpo.displayName).join(", "),
              totalCost: () => <Price valueInCents={computedCost.totalCostInCents} />,
              deltaOption: ({ rpCosts }, { row: { id } }) => {
                const totalCostInCents = computedCost.deltas.find(
                  (delta) => rpoToGoId(delta.readyPlanOption) === id,
                )?.totalCostInCents;
                if (isDefined(totalCostInCents)) {
                  return <Price valueInCents={totalCostInCents} />;
                }
                return "-";
              },
              mw: "120px",
            }),
          ),
        });
      }),
    ];
  }, [allRpCosts]);
};

function createRows(readyPlanCosts: RpCostsFragment[], deltaOptions: NestedOption<OptionType>[]): Row[] {
  return [
    { id: "header", kind: "header", data: readyPlanCosts },
    { id: "expandableHeader", kind: "expandableHeader" as const, data: readyPlanCosts },
    { id: "totalCost", kind: "totalCost", data: readyPlanCosts },
    ...(deltaOptions.map((delta) => ({
      id: delta.id,
      kind: "deltaOption" as const,
      data: { option: delta, rpCosts: readyPlanCosts },
    })) ?? []),
  ];
}

type RpCostsFilter = {
  readyPlans: Maybe<string[]>;
  configureGlobalOptionIds: Maybe<string[]>;
  deltaGlobalOptionIds: Maybe<string[]>;
};

type OptionType = { id: string; name: string };

function optionsTree(readyPlans: ReviewReadyPlanCostsRpFragment[] | undefined): NestedOption<OptionType>[] {
  const uniqueOptions = Object.values(
    readyPlans
      ?.flatMap((rp) => rp.options)
      // Group options by `[GlobalOptionId][-[LocationId]]` to ensure we don't have duplicate options
      .groupBy(rpoToGoId) ?? {},
  )
    .map((rpos) => rpos.first!)
    .sortBy((rpo) => rpo.globalOption.type.order)
    .groupByObject((rpo) => rpo.globalOption.type)
    .map(([type, rpos]) => ({
      id: type.id,
      name: type.name,
      children: rpos
        .map((rpo) => ({
          id: rpoToGoId(rpo),
          name: rpo.displayName,
        }))
        .sortByKey("name"),
    }));
  return uniqueOptions;
}

function rpoToGoId(rpo: { location?: Maybe<{ id: string }>; globalOption: { id: string } }) {
  return `${rpo.globalOption.id}${rpo.location ? `-${rpo.location.id}` : ""}`;
}

function MaybePriceRange({ min, max }: { min: number; max: number }) {
  if (min === max) {
    return <Price valueInCents={min} />;
  }
  return (
    <>
      <Price valueInCents={min} /> - <Price valueInCents={max} />
    </>
  );
}
