import { ScheduleDraftMode_PlanTaskFragment, TaskStatus } from "src/generated/graphql-types";
import { Css, useSnackbar } from "@homebound/beam";
import { useDraftScheduleStore } from "./scheduleDraftStore";
import { createRef, useMemo } from "react";
import {
  getPlanTaskData,
  onDraftTaskEventDrop,
  onDraftTaskEventResize,
  renderTaskEventContent,
} from "../calendar/dynamicSchedulesCalendarUtils";
import { DynamicSchedulesCalendarWrapper } from "../calendar/DynamicSchedulesCalendar";
import FullCalendar from "@fullcalendar/react";

export function DraftScheduleCalendar({ planTasks }: { planTasks: ScheduleDraftMode_PlanTaskFragment[] }) {
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const { triggerNotice } = useSnackbar();

  const { events, planTasksById } = useMemo(() => {
    const filteredTasks = planTasks.filter((t) => t.status.code !== TaskStatus.Complete);
    return getPlanTaskData(filteredTasks);
  }, [planTasks]);

  // FullCalendar still uses legacy refs
  const calendarRef = createRef<FullCalendar>();

  return (
    <div css={Css.mr3.h100.pb3.$} data-testid="calendarViewContent">
      <DynamicSchedulesCalendarWrapper
        events={events}
        calendarRef={calendarRef}
        editable={true}
        // don't allow users to click into a task if the calendar is in edit mode
        eventClick={undefined}
        eventContent={(event) => renderTaskEventContent(event, planTasksById, true)}
        eventResize={({ event, revert }) => onDraftTaskEventResize(event, revert, setDraftTaskChanges, triggerNotice)}
        eventDrop={({ event, revert }) => onDraftTaskEventDrop(event, revert, setDraftTaskChanges, triggerNotice)}
      />
    </div>
  );
}
