import { FieldState, ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import {
  InputMaybe,
  SaveProjectContactInput,
  WarrantyProjectContactFragment,
  WarrantyTicketPageDetailsFragment,
  useProjectContactsQuery,
  useSaveProjectContactWarrantyMutation,
} from "src/generated/graphql-types";
import { TicketFormInput } from "../WarrantyTicketPage";
import { useState } from "react";
import { Css, Button, BoundTextField, Checkbox, Tooltip } from "@homebound/beam";
import { Observer } from "mobx-react";

type WarrantyContactSectionProps = {
  warrantyTicket: WarrantyTicketPageDetailsFragment;
  formState: ObjectState<Partial<TicketFormInput>>;
};

export function WarrantyContactSection({ warrantyTicket, formState }: WarrantyContactSectionProps) {
  const result = useProjectContactsQuery({ variables: { projectId: warrantyTicket.project.id } });
  const projectContacts = result.data?.project.contacts ?? [];

  const [showAddNew, setShowAddNew] = useState(false);

  return (
    <>
      <div css={Css.df.fdc.$}>
        <div css={Css.df.jcsb.aic.pb1.$}>
          <div css={Css.smBd.$}>Existing Contacts</div>
          <Tooltip title="Add warranty contacts for direct warranty communication; project contacts still receive standard messages.">
            <Button variant="tertiary" onClick={() => setShowAddNew(true)} label="+ Add New" />
          </Tooltip>
        </div>
        {projectContacts.length > 0 ? (
          <div css={Css.df.fdc.gap1.$}>
            {showAddNew && (
              <AddNewContact
                setShowAddNew={setShowAddNew}
                ticketFormState={formState}
                projectId={warrantyTicket.project.id}
              />
            )}
            {projectContacts.map((contact) => (
              <ContactCard key={contact.id} contact={contact} contactsState={formState.projectContactIds} />
            ))}
          </div>
        ) : (
          <AddNewContact
            setShowAddNew={setShowAddNew}
            ticketFormState={formState}
            projectId={warrantyTicket.project.id}
          />
        )}
      </div>
    </>
  );
}

type AddNewContactProps = {
  setShowAddNew: (show: boolean) => void;
  ticketFormState: ObjectState<Partial<TicketFormInput>>;
  projectId: string;
};

function AddNewContact({ setShowAddNew, ticketFormState, projectId }: AddNewContactProps) {
  const [saveProjectContact] = useSaveProjectContactWarrantyMutation({});

  const formState = useFormState({
    config: formConfig,
    init: {
      fullName: undefined,
      email: undefined,
      phoneNumber: undefined,
      projectId: projectId,
    },
  });

  async function saveProjectContactWarranty() {
    const result = await saveProjectContact({
      variables: {
        input: formState.value,
      },
      refetchQueries: ["ProjectContacts"],
    });
    if (result.data?.saveProjectContact.projectContact.id) {
      ticketFormState.projectContactIds.set([
        ...(ticketFormState.projectContactIds.value || []),
        result.data?.saveProjectContact.projectContact.id,
      ]);
      setShowAddNew(false);
    }
  }
  return (
    <div css={Css.w100.bgGray100.df.fdc.p2.br8.gap2.$}>
      <div css={Css.base.$}>Add New Contact</div>
      <BoundTextField field={formState.fullName} label="Full Name" />
      <BoundTextField field={formState.email} label="Email" />
      <BoundTextField field={formState.phoneNumber} label="Phone Number" />
      <div>
        <Button label="Cancel" variant="tertiary" onClick={() => setShowAddNew(false)} />
        <Observer>
          {() => <Button label="Create" onClick={saveProjectContactWarranty} disabled={!formState.valid} />}
        </Observer>
      </div>
    </div>
  );
}

type ContactCardProps = {
  contact: WarrantyProjectContactFragment;
  contactsState: FieldState<InputMaybe<string[]>>;
};
function ContactCard({ contact, contactsState }: ContactCardProps) {
  const [isSelected, setIsSelected] = useState(contactsState.value?.includes(contact.id) ?? false);

  function onCheckboxClick() {
    if (!isSelected) {
      contactsState.set([...(contactsState.value || []), contact.id]);
    } else {
      contactsState.set(contactsState.value?.filter((id) => id !== contact.id));
    }
    setIsSelected(!isSelected);
  }

  return (
    <div css={Css.bgGray100.df.jcsb.p2.aic.br8.$}>
      <div css={Css.df.fdc.$}>
        <div css={Css.sm.gray900.$}>{contact.fullName}</div>
        <div css={Css.xs.gray700.$}>{contact.phoneNumber}</div>
        <div css={Css.sm.blue600.$}>{contact.email}</div>
      </div>
      <Checkbox label="Selected" checkboxOnly selected={isSelected} onChange={onCheckboxClick} />
    </div>
  );
}

type CreateNewContactInput = Pick<SaveProjectContactInput, "fullName" | "email" | "phoneNumber" | "projectId">;

const formConfig: ObjectConfig<Partial<CreateNewContactInput>> = {
  fullName: { type: "value", rules: [required] },
  email: { type: "value", rules: [required] },
  phoneNumber: { type: "value", rules: [required] },
  projectId: { type: "value", rules: [required] },
};
