import { Css, ScrollableParent, Step, useTestIds } from "@homebound/beam";
import { StepperProvider, stepperBarHeight, useStepperContext } from "src/components/stepper";
import { foldEnum } from "src/utils";
import { DelayFlagPublishStep } from "./DelayFlagPublishStep";
import { ScheduleDraftMode_PlanTaskFragment } from "src/generated/graphql-types";

type PublishDraftScheduleStepperProps = {
  scheduleParentId: string;
  draftTasks: ScheduleDraftMode_PlanTaskFragment[];
};

enum PublishDraftScheduleStep {
  DelayFlags = "DelayFlags",
  TradeConfirm = "TradeConfirm",
}

export function PublishDraftScheduleStepper({ scheduleParentId, draftTasks }: PublishDraftScheduleStepperProps) {
  const tids = useTestIds({}, "publishDraftScheduleStepper");

  return (
    <div {...tids}>
      <StepperProvider steps={initialSteps}>
        <ScrollableParent xss={Css.mbPx(stepperBarHeight).$}>
          <PublishDraftScheduleStepperView scheduleParentId={scheduleParentId} draftTasks={draftTasks} />
        </ScrollableParent>
      </StepperProvider>
    </div>
  );
}

function PublishDraftScheduleStepperView({ scheduleParentId, draftTasks }: PublishDraftScheduleStepperProps) {
  const { currentStep } = useStepperContext();

  return (
    <>
      {foldEnum(currentStep.value, {
        [PublishDraftScheduleStep.DelayFlags]: (
          <DelayFlagPublishStep draftTasks={draftTasks} scheduleParentId={scheduleParentId} />
        ),
        // Hiding Trade Availability Request for Pilot - will be added back in a future release
        // [PublishDraftScheduleStep.TradeConfirm]: <TradeConfirmPublishStep scheduleParentId={scheduleParentId} />,
      })}
    </>
  );
}

const initialSteps: Step[] = [
  {
    label: "Add Delay Flags",
    disabled: false,
    state: "incomplete",
    value: PublishDraftScheduleStep.DelayFlags,
  },
  // {
  //   label: "Email Trades",
  //   disabled: false,
  //   state: "incomplete",
  //   value: PublishDraftScheduleStep.TradeConfirm,
  // },
];
