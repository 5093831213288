import { BoundSelectField, Button, Css, FormLines, useModal, useSnackbar, useTestIds } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import startCase from "lodash/startCase";
import { Observer } from "mobx-react";
import { useHistory, useLocation, useParams } from "react-router";
import {
  CostClassificationType,
  InvoiceScheduleAmountType,
  InvoiceScheduleTriggerType,
  InvoiceTermsForm_InvoiceScheduleTemplateFragment,
  LotType,
  NamedFragment,
  SaveInvoiceScheduleTemplateInput,
  useInvoiceTermsFormMetadataQuery,
  useInvoiceTermsFormQuery,
  useSaveInvoiceTermsMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { InvoiceTermsItems } from "./InvoiceTermsItems";
import { addEntityParam, createInvoiceTermsUrl } from "src/RouteUrls";
import { IdOrAddParams } from "src/routes/routesDef";
import { queriesResult } from "src/utils";
import { emptyCellDash } from "src/components";
import { useToggle } from "src/hooks";
import { DateOnly, DateTime, formatWithYear } from "src/utils/dates";

const DAYS_IN_MONTH = 31;

type InvoiceTermsFormState = {
  name: string;
  lotType: LotType;
  customerId: string;
};

type InvoiceTermsFormProps = {
  invoiceScheduleTemplateId?: string;
};

export function InvoiceTermsForm({ invoiceScheduleTemplateId }: InvoiceTermsFormProps) {
  const paramsIdOrAdd = useParams<IdOrAddParams>().idOrAdd;
  const idOrAdd = invoiceScheduleTemplateId ?? paramsIdOrAdd;
  const isNew = idOrAdd === addEntityParam;
  const metadataQuery = useInvoiceTermsFormMetadataQuery({
    variables: { filter: { isFromInvoiceAutomation: true } },
    fetchPolicy: "cache-first",
  });
  const invoiceTermsQuery = useInvoiceTermsFormQuery({ variables: { id: idOrAdd }, skip: isNew });

  return queriesResult([metadataQuery, invoiceTermsQuery] as const, {
    data: (metadata, invoiceTermData) => {
      return (
        <InvoiceTermsFormView
          customers={metadata.customers}
          globalPlanMilestones={metadata.globalPlanMilestones.entities}
          invoiceTerm={invoiceTermData?.invoiceScheduleTemplate}
        />
      );
    },
  });
}

type InvoiceTermsFormViewProps = {
  customers: NamedFragment[];
  globalPlanMilestones: NamedFragment[];
  invoiceTerm: InvoiceTermsForm_InvoiceScheduleTemplateFragment | undefined;
};

function InvoiceTermsFormView({ customers, globalPlanMilestones, invoiceTerm }: InvoiceTermsFormViewProps) {
  const history = useHistory();
  const tid = useTestIds({});
  const { inModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [saveInvoiceTerm] = useSaveInvoiceTermsMutation();
  const location = useLocation<InvoiceTermsFormState>();
  const [readOnly, toggleReadonly] = useToggle(!!invoiceTerm);
  const formState = useFormState({
    config: formConfig,
    init: { input: invoiceTerm ?? location.state, map: mapToForm },
  });
  const daysOfMonth = Array.from({ length: DAYS_IN_MONTH }, (_, i) => i + 1);

  return (
    <Observer>
      {() => (
        <>
          {!inModal && (
            <PageHeader
              title="Create Invoice Terms"
              right={
                !readOnly ? (
                  <>
                    <Button variant="tertiary" onClick={createInvoiceTermsUrl()} label="Cancel" />
                    <Button variant="primary" disabled={!formState.valid} onClick={toggleReadonly} label="Review" />
                  </>
                ) : (
                  <>
                    <Button variant="tertiary" onClick={toggleReadonly} label="Edit" />
                    <Button
                      variant="primary"
                      onClick={async () => {
                        const { createdAt, ...input } = formState.value;
                        const { data } = await saveInvoiceTerm({ variables: { input } });
                        if (data) {
                          triggerNotice({
                            message: invoiceTerm
                              ? "Invoice Terms successfully updated"
                              : `New Invoice Terms: ${data.saveInvoiceScheduleTemplate.invoiceScheduleTemplate.name} created`,
                            icon: "success",
                          });
                          history.push(createInvoiceTermsUrl());
                        }
                      }}
                      label={invoiceTerm ? "save" : "Create"}
                      disabled={!formState.dirty}
                    />
                  </>
                )
              }
            />
          )}

          <div css={Css.mt2.maxw("60%").mxa.br12.bgWhite.p4.if(!!inModal).maxw100.p1.$}>
            <section>
              {!readOnly ? (
                <div css={Css.xl2Sb.mb3.$}>New Invoice Terms</div>
              ) : (
                <>
                  <div css={Css.xl2Sb.$}>{formState.name.value}</div>
                  <div css={Css.sm.mt1.gray700.$}>
                    Created on {formatWithYear(new DateOnly(formState.createdAt.value))}
                  </div>
                  <div css={Css.mt4.mb3.lgSb.$}>Overview</div>
                </>
              )}
              <div css={Css.dg.gapPx(4).if(readOnly).wPx(480).$}>
                {!readOnly && (
                  <div {...tid.name} css={Css.df.$}>
                    <div css={Css.gray700.wPx(150).$}>Name</div>
                    <div css={Css.smMd.$}> {formState.name.value} </div>
                  </div>
                )}
                <div {...tid.productType} css={Css.df.$}>
                  <div css={Css.gray700.wPx(150).if(readOnly).w50.mb1.$}>Product Type</div>
                  <div css={Css.smMd.$}>{Object.values(LotType).find((type) => type === formState.lotType.value)}</div>
                </div>
                <div {...tid.customer} css={Css.df.$}>
                  <div css={Css.gray700.wPx(150).if(readOnly).w50.$}>Customer</div>
                  <div css={Css.smMd.$}>
                    {customers.find((customer) => customer.id === formState.customerId.value)?.name || "-"}
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div>
                <div css={Css.lgSb.mt4.mb3.$}>Soft Cost Invoicing Terms</div>
                <FormLines labelStyle={readOnly ? "left" : "above"} width={readOnly ? "md" : "full"}>
                  <div css={Css.df.gap2.mb1.if(readOnly).fdc.$}>
                    <BoundSelectField
                      field={formState.softCostAmountType}
                      readOnly={readOnly}
                      options={Object.values(InvoiceScheduleAmountType).map((type) => ({
                        id: type,
                        name: startCase(type),
                      }))}
                    />
                    <BoundSelectField
                      field={formState.softCostTriggerType}
                      readOnly={readOnly}
                      options={Object.values(InvoiceScheduleTriggerType).map((type) => ({ id: type, name: type }))}
                    />
                    {formState.softCostTriggerType.value === InvoiceScheduleTriggerType.Date && (
                      <BoundSelectField
                        field={formState.softCostDayOfMonth}
                        unsetLabel={emptyCellDash}
                        readOnly={readOnly}
                        options={daysOfMonth.map((val) => ({ id: val, name: val.toString() }))}
                      />
                    )}
                  </div>
                </FormLines>
                {formState.softCostTriggerType.value === InvoiceScheduleTriggerType.Task && (
                  <InvoiceTermsItems
                    formState={formState}
                    globalPlanMilestones={globalPlanMilestones}
                    costType={CostClassificationType.Soft}
                    readOnly={readOnly}
                  />
                )}
              </div>
              <div>
                <div css={Css.lgSb.mt4.mb3.$}>Hard Cost Invoicing Terms</div>
                <FormLines labelStyle={readOnly ? "left" : "above"} width={readOnly ? "md" : "full"}>
                  <div css={Css.df.gap2.mb1.if(readOnly).fdc.$}>
                    <BoundSelectField
                      field={formState.hardCostAmountType}
                      readOnly={readOnly}
                      options={Object.values(InvoiceScheduleAmountType).map((type) => ({
                        id: type,
                        name: startCase(type),
                      }))}
                    />
                    <BoundSelectField
                      field={formState.hardCostTriggerType}
                      readOnly={readOnly}
                      options={Object.values(InvoiceScheduleTriggerType).map((type) => ({ id: type, name: type }))}
                    />
                    {formState.hardCostTriggerType.value === InvoiceScheduleTriggerType.Date && (
                      <BoundSelectField
                        field={formState.hardCostDayOfMonth}
                        readOnly={readOnly}
                        unsetLabel={emptyCellDash}
                        options={daysOfMonth.map((val) => ({ id: val, name: val.toString() }))}
                      />
                    )}
                  </div>
                </FormLines>
                {formState.hardCostTriggerType.value === InvoiceScheduleTriggerType.Task && (
                  <InvoiceTermsItems
                    formState={formState}
                    globalPlanMilestones={globalPlanMilestones}
                    costType={CostClassificationType.Hard}
                    readOnly={readOnly}
                  />
                )}
              </div>
            </section>
          </div>
        </>
      )}
    </Observer>
  );
}

export type InvoiceScheduleTemplateForm = SaveInvoiceScheduleTemplateInput & { createdAt: DateTime };

const formConfig: ObjectConfig<InvoiceScheduleTemplateForm> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  lotType: { type: "value", rules: [required] },
  customerId: { type: "value" },
  softCostAmountType: { type: "value", rules: [required] },
  softCostTriggerType: { type: "value", rules: [required] },
  softCostDayOfMonth: { type: "value" },
  hardCostAmountType: { type: "value", rules: [required] },
  hardCostTriggerType: { type: "value", rules: [required] },
  hardCostDayOfMonth: { type: "value" },
  createdAt: { type: "value" },
  items: {
    type: "list",
    config: {
      id: { type: "value" },
      globalPlanMilestoneId: { type: "value", rules: [required] },
      frequency: { type: "value", rules: [required] },
      costClassificationType: { type: "value", rules: [required] },
      amountInBasisPoints: { type: "value", rules: [required] },
      iterations: { type: "value", rules: [required] },
      delete: { type: "value" },
    },
  },
};

function mapToForm(
  invoiceTerm: InvoiceTermsForm_InvoiceScheduleTemplateFragment | InvoiceTermsFormState,
): InvoiceScheduleTemplateForm {
  // Returns loaded invoice term or the initial state provided by CreateInvoiceTermsModal
  return "id" in invoiceTerm
    ? {
        ...invoiceTerm,
        customerId: invoiceTerm.customer?.id,
        softCostAmountType: invoiceTerm.softCostAmountType.code,
        softCostTriggerType: invoiceTerm.softCostTriggerType.code,
        hardCostAmountType: invoiceTerm.hardCostAmountType.code,
        hardCostTriggerType: invoiceTerm.hardCostTriggerType.code,
        lotType: invoiceTerm.lotType.code,
        items: invoiceTerm.items.map((item) => ({
          ...item,
          costClassificationType: item.costClassificationType.code,
          frequency: item.frequency.code,
          globalPlanMilestoneId: item.globalPlanMilestone?.id,
        })),
      }
    : { ...invoiceTerm, createdAt: new Date() };
}
