import {
  TradePartnerBidderFragment,
  useSelectBiddersStepQuery,
  TradePartnerOnboardingStatus,
  BidderBidPackageFragment,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import {
  Accordion,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  Tag,
  TagType,
  column,
  emptyCell,
  numericColumn,
  selectColumn,
  simpleDataRows,
  useGridTableApi,
} from "@homebound/beam";
import { Card } from "src/components/Card";
import { useEffect, useMemo, useState } from "react";
import { SearchBox } from "src/components";
import { ObjectState, f } from "@homebound/form-state";
import { BidPackageFormState } from "../AddBidPackagePage";
import { useReaction } from "src/hooks";

type SelectBiddersStepProps = {
  formState: ObjectState<BidPackageFormState>;
};

export function SelectBiddersStep({ formState }: SelectBiddersStepProps) {
  const query = useSelectBiddersStepQuery({
    variables: {
      bidPackageGroupId: formState.bidPackageGroupId.value!,
      costCodes: formState.bidPackages.value
        .flatMap((bp) => bp.costCodes)
        .unique()
        .compact(),
    },
  });
  return queryResult(query, ({ bidPackageGroup, tradePartners }) => (
    <SelectBiddersStepView
      bidPackages={bidPackageGroup.bidPackages}
      tradePartners={tradePartners}
      formState={formState}
    />
  ));
}

type SelectBiddersStepViewProps = {
  bidPackages: BidderBidPackageFragment[];
  tradePartners: TradePartnerBidderFragment[];
  formState: ObjectState<BidPackageFormState>;
};
export function SelectBiddersStepView({ bidPackages, tradePartners, formState }: SelectBiddersStepViewProps) {
  return (
    <>
      <div css={Css.df.fdc.aic.my6.$}>
        <div css={Css.xl3Sb.$}>Select Trades to Bid</div>
        <div css={Css.base.pt2.$}>
          As a standard, we recommend choosing at least three trades to send a bid request to.
        </div>
      </div>
      {bidPackages.map((bidPackage) => {
        const filteredTradePartners = tradePartners.filter((tp) =>
          bidPackage.costCodes.every((cc) => tp.costCodes.map((cc) => cc.id).includes(cc.id)),
        );
        return (
          <Card xss={Css.mt4.wfc.mxa.$} key={bidPackage.id}>
            <div css={Css.mw("730px").$}>
              <Accordion
                topBorder={false}
                defaultExpanded
                title={<div css={Css.lgSb.$}>{`${bidPackage.name} Trades`}</div>}
              >
                <div>
                  <TradeTable
                    formState={formState}
                    tradePartners={filteredTradePartners}
                    bidPackageId={bidPackage.id}
                    onSelectTradePartners={(tradePartnerIds) =>
                      onSelectTradePartners(tradePartnerIds, formState, bidPackage.id)
                    }
                  />
                </div>
              </Accordion>
            </div>
          </Card>
        );
      })}
    </>
  );
}

function TradeTable({
  tradePartners,
  onSelectTradePartners,
  formState,
  bidPackageId,
}: {
  tradePartners: TradePartnerBidderFragment[];
  onSelectTradePartners: (ids: string[]) => void;
  formState: ObjectState<BidPackageFormState>;
  bidPackageId: string;
}) {
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const rows = useMemo(() => createRows(tradePartners), [tradePartners]);
  const tableApi = useGridTableApi<Row>();

  useEffect(() => {
    const selectedTradeIds = formState.bidPackages.value
      .filter((bp) => bp.id === bidPackageId)
      .flatMap((bp) => bp.requests)
      .map((bidReq) => bidReq?.tradePartnerId)
      .compact();
    // On page load, if bidPackageGroup has been saved
    // initialize with already selected trades on the table
    if (formState.bidPackageGroupId.value) {
      selectedTradeIds.forEach((id) => tableApi.selectRow(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useReaction(
    () => tableApi.getSelectedRows("data").map((r) => r.data.id),
    (selectedTradePartnerIds) => {
      onSelectTradePartners(selectedTradePartnerIds);
    },
    [tableApi],
  );
  return (
    <div>
      <div css={Css.mb1.$}>
        <SearchBox onSearch={setSearchFilter} placeholder="Search Trades" updateQueryString={false} />
      </div>
      <GridTable<Row>
        api={tableApi}
        stickyHeader
        rows={rows}
        columns={columns}
        fallbackMessage="There are no Trade Partners For this Bid Category"
        filter={searchFilter}
        sorting={{ on: "client", initial: ["tasks", "DESC"] }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: TradePartnerBidderFragment };
type Row = HeaderRow | DataRow;

const columns: GridColumn<Row>[] = [
  selectColumn<Row>({ header: emptyCell }),
  column<Row>({ header: "Trade Partner", data: (tp) => tp.name, w: "230px" }),
  numericColumn<Row>({ id: "tasks", header: "Tasks", data: (tp) => tp.activeTasksCount, w: "130px" }),
  numericColumn<Row>({ header: "Contracts", data: (tp) => tp.activeContractsCount, w: "130px" }),
  column<Row>({
    header: "Onboarding",
    data: (tp) => ({
      value: tp.onboardingStatus.name,
      content: () => <Tag text={tp.onboardingStatus.name} type={onboardingStatusTagMapper(tp.onboardingStatus.code)} />,
    }),
    w: "130px",
  }),
];

function createRows(tradePartners: TradePartnerBidderFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(tradePartners);
}

function onboardingStatusTagMapper(onboardingStatus: TradePartnerOnboardingStatus): TagType {
  switch (onboardingStatus) {
    case TradePartnerOnboardingStatus.Complete:
      return "info";
    case TradePartnerOnboardingStatus.InProgress:
      return "caution";
    default:
      return "warning";
  }
}

function onSelectTradePartners(
  tradePartnerIds: string[],
  formState: ObjectState<BidPackageFormState>,
  bidPackageId: string,
) {
  const bidPackage = formState.bidPackages.rows.find((bc) => bc.id.value === bidPackageId);
  if (!bidPackage) {
    return;
  }
  let requests = bidPackage.requests.value || [];
  // remove trades that have been removed
  requests = requests.filter((t) => t.tradePartnerId && tradePartnerIds.includes(t.tradePartnerId));
  // add new trades
  requests = [
    ...requests,
    ...tradePartnerIds.filter((v) => !requests.some((t) => t.tradePartnerId === v)).map((v) => ({ tradePartnerId: v })),
  ];
  bidPackage.requests.set(requests);
}
