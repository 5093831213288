import { pascalCase } from "change-case";
import { ReactNode } from "react";
import { useRouteMatch } from "react-router-dom";
import { developmentPaths } from "src/routes/routesDef";
import { UserEventRowData } from "../../UserEventsTable";
import { Accordion, AccordionProps, ConfigChanges, SingleChangeText } from "../ProjectReadyPlanConfig/components";
import { weightedStyle } from "src/routes/my-blueprint/activity-feed/utils";

export function ReadyPlanDetailsEventText({ event, dateTag }: { event: UserEventRowData; dateTag?: ReactNode }) {
  const { url, type, createdBy, payload, name, parentTypeName } = event;
  const { __typename, __originalValues = {}, ...rest } = payload as any;
  const match = useRouteMatch(developmentPaths.activity);
  const { programData = {}, ...pdFields } = rest;
  const {
    __typename: pdTn,
    __originalValues: PdOriginalValues = {},
    ...programDataFields
  } = programData.__payload ?? {};
  const affectedAttributesValues = { ...pdFields, ...programDataFields };
  const affectedAttributes = Object.keys(affectedAttributesValues);
  const affectedAttributesCount = affectedAttributes.length;
  const updatePD = __originalValues.programData === null && rest?.programData !== undefined;

  const ConfigurationTag = ({ children }: { children: ReactNode }) =>
    url && !match ? (
      <a css={weightedStyle} href={parentTypeName === "Ready plan option" ? `${url}/optionData` : url}>
        {children}
      </a>
    ) : (
      <>{children}</>
    );
  const allOriginalValues = { ...__originalValues, ...PdOriginalValues };
  const result: AccordionProps = {
    title:
      affectedAttributesCount === 1 ? (
        <SingleChangeText
          ConfigurationTag={ConfigurationTag}
          attribute={
            affectedAttributes.first
              ? {
                  name: pascalCase(affectedAttributes.first),
                  oldValue:
                    typeof allOriginalValues[affectedAttributes.first] === "object" &&
                    allOriginalValues[affectedAttributes.first]?.name
                      ? allOriginalValues[affectedAttributes.first].name
                      : typeof allOriginalValues[affectedAttributes.first] === "boolean"
                        ? allOriginalValues[affectedAttributes.first] + ""
                        : allOriginalValues[affectedAttributes.first],
                  newValue:
                    typeof affectedAttributesValues[affectedAttributes.first] === "object" &&
                    affectedAttributesValues[affectedAttributes.first]?.name
                      ? affectedAttributesValues[affectedAttributes.first].name
                      : affectedAttributesValues[affectedAttributes.first] + "",
                }
              : undefined
          }
          createdBy={createdBy}
          dateTag={dateTag}
          projectTag={undefined}
          optionAdded={undefined}
          optionDeleted={undefined}
          name={name}
        />
      ) : affectedAttributesCount === 0 && updatePD ? (
        <>
          Program data for
          <ConfigurationTag>
            <span css={weightedStyle}> {name} </span>
          </ConfigurationTag>
          was added by <span css={weightedStyle}>{createdBy}</span> {dateTag}
        </>
      ) : (
        <>
          <ConfigurationTag>
            <span css={weightedStyle}>{affectedAttributesCount} updates </span>
          </ConfigurationTag>
          were made for <span css={weightedStyle}>{name}</span> by <span css={weightedStyle}>{createdBy}</span>{" "}
          {dateTag}
        </>
      ),
    children: "",
  };

  switch (type) {
    case "UPDATED":
      result.children = affectedAttributesCount > 1 && (
        <ConfigChanges
          fieldsChanged={affectedAttributes.map((attribute) => ({
            attributeName: pascalCase(attribute),
            newValue:
              typeof affectedAttributesValues[attribute] === "object" && affectedAttributesValues[attribute]?.name
                ? affectedAttributesValues[attribute].name
                : affectedAttributesValues[attribute] + "",
            oldValue:
              typeof allOriginalValues[attribute] === "object" && allOriginalValues[attribute]?.name
                ? allOriginalValues[attribute].name
                : typeof allOriginalValues[attribute] === "boolean"
                  ? allOriginalValues[attribute] + ""
                  : allOriginalValues[attribute],
          }))}
          optionsAdded={{}}
          optionsRemoved={{}}
        />
      );
      break;
    case "CREATED":
      result.title = (
        <>
          <span>
            <ConfigurationTag>
              <span css={weightedStyle}>{name} </span>
            </ConfigurationTag>
            was added by <span css={weightedStyle}>{createdBy}</span>
          </span>
          {dateTag}
        </>
      );
      break;
    default:
      return <></>;
  }
  return <Accordion {...result} />;
}
