import { Global } from "@emotion/react";
import { Css, GridColumn, GridTable, px, RowStyles, SimpleHeaderAndData, useTestIds } from "@homebound/beam";
import { Price } from "src/components";
import {
  LotType,
  PandaDocRole,
  ProjectCollaboratorRole,
  ProjectRole,
  useInvoicePandaDocPdfQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { StringParam, useQueryParam } from "use-query-params";
import { InvoicePdf } from "./InvoicePdf";
import { Initials, Radio } from "../components/PandaDocField";

export function InvoicePandaDocPdf() {
  const [invoiceId] = useQueryParam("invoiceId", StringParam);
  const query = useInvoicePandaDocPdfQuery({ variables: { invoiceId: invoiceId! } });
  const testIds = useTestIds({}, "invoicePdf");
  const paymentOptions = ["Wire Transfer", "Mailed Check", "Stripe Payment"];

  return queryResult(query, ({ invoice }) => {
    const { primaryHomeowner, customer } = invoice.project;
    const projectAccountant = invoice.project.teamMembers.find((tm) => tm.role.code === ProjectRole.Accounting);
    return (
      <>
        <Global
          styles={{
            "@page": { size: "letter" },
            ul: Css.ml1.my2.add({ listStyleType: "disc" }).$,
            li: Css.my2.$,
          }}
        />

        {/* Page 1 */}
        <div css={Css.p2.$}>
          <img src="/wordmark.svg" alt="Homebound" css={Css.h(px(30)).$} />

          <div css={Css.lgSb.tac.my3.$}>Invoice Payment Request</div>

          <div {...testIds.homeownerName} css={Css.mb2.$}>
            Attention: {customer?.name ?? primaryHomeowner?.fullName ?? "Client"}
          </div>

          <div>
            <p css={Css.mb2.$}>Hello,</p>
            <p css={Css.mb2.$}>
              Please find your invoice attached. You may choose to pay via Stripe, wire transfer, or by mailing a check.{" "}
              <strong>
                Both the banking information for wire transfers and the mailing address for checks have recently
                changed.{" "}
              </strong>
              You can find the updated information on the lower portion of the invoice. Payment is due{" "}
              <strong css={Css.tdu.$}>upon receipt</strong>.
            </p>
            <p>
              Please indicate below what method of payment you will be using, initial and then click Finish. Regardless
              of which option you choose, on the next screen, you will have the ability to pay via Stripe. If paying via
              wire or check, you may disregard this step.
            </p>

            <div css={Css.p3.$}>
              <Radio role={PandaDocRole.Homeowner} options={paymentOptions} />
            </div>
          </div>

          <div css={Css.mb2.$}>
            <GridTable
              style={{ bordered: true }}
              as="table"
              columns={columns}
              rowStyles={rowStyles}
              rows={[
                { id: invoice.id, data: invoice, kind: "header" },
                { id: invoice.id, data: invoice, kind: "data" },
              ]}
            />
          </div>

          <div css={Css.df.$}>
            <div css={Css.w50.df.fdc.aie.p1.$}>
              <Initials role={PandaDocRole.Homeowner} />
              <div>Homeowner Initials</div>
            </div>
            <div css={Css.w50.df.jcfe.$}>
              <div css={Css.df.fdc.wPx(225).gap2.$}>
                <div css={Css.df.jcsb.$}>
                  <div>Subtotal</div>
                  <strong>
                    <Price id="subtotal" valueInCents={invoice.amountInCents} />
                  </strong>
                </div>
                <div css={Css.f1.df.jcsb.$}>
                  <div>Credit</div>
                  <strong>
                    <Price id="credit" valueInCents={invoice.creditForInvoice?.amountInCents} />
                  </strong>
                </div>
                <div css={Css.f1.fwb.df.jcsb.lgSb.$}>
                  <div>Total Due</div>
                  <Price
                    id="totalDue"
                    valueInCents={invoice.amountInCents - (invoice.creditForInvoice?.amountInCents ?? 0)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div css={Css.mt1.$}>
            <div>Thank you!</div>
            <div {...testIds.accountantName}>{projectAccountant?.user?.name ?? ""}</div>
            <div {...testIds.accountantEmail}>{projectAccountant?.user?.email ?? ""}</div>
            <div>Project Accountant</div>
          </div>
        </div>

        <div style={{ pageBreakBefore: "always" }}></div>

        {/* Page 2 */}
        <InvoicePdf />
      </>
    );
  });
}

type Row = SimpleHeaderAndData<{ id: string; amountInCents: number }>;

const columns: GridColumn<Row>[] = [
  { header: "Amount Due", data: (invoice) => <Price valueInCents={invoice.amountInCents} />, align: "right" },
  { header: "Qty", data: () => 1, align: "right" },
  { header: "Subtotal", data: (invoice) => <Price valueInCents={invoice.amountInCents} />, align: "right" },
];

export const rowStyles: RowStyles<Row> = {
  header: { cellCss: Css.bgGray400.$ },
  data: { cellCss: Css.bgWhite.$ },
};
