import {
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  simpleHeader,
} from "@homebound/beam";

import { emptyCellDash, priceCell } from "src/components";
import { BillPdf_BillLineItemsFragment, BillPdf_TradeLineItemFragment } from "src/generated/graphql-types";
import { formatList } from "src/utils";

type BillPdfProps = {
  lineItems: BillPdf_BillLineItemsFragment[];
  enableProductConfigPlan: boolean;
};

export function BillPdfTable({ lineItems, enableProductConfigPlan }: BillPdfProps) {
  return (
    <div css={Css.mt6.$}>
      <div css={Css.df.fdr.jcsb.mb4.$}>
        <div css={Css.lgSb.$}>Completed Item Codes</div>
      </div>
      <GridTable
        as="table"
        rows={rows(lineItems, enableProductConfigPlan)}
        columns={columns()}
        style={{ bordered: false, allWhite: true }}
        rowStyles={{ header: { cellCss: Css.gray600.$ }, total: { cellCss: Css.smSb.mt2.$ } }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type GroupRow = { kind: "group"; data: string | BillPdf_TradeLineItemFragment[] };
type ItemRow = { kind: "item"; data: BillPdf_BillLineItemsFragment };
type TotalRow = { kind: "total"; data: number };
export type Row = HeaderRow | GroupRow | ItemRow | TotalRow;

function rows(lineItems: BillPdf_BillLineItemsFragment[], enableProductConfigPlan: boolean): GridDataRow<Row>[] {
  const groupedItis = enableProductConfigPlan ? groupByProratedLines(lineItems) : groupByTemplate(lineItems);
  return [
    simpleHeader,
    ...groupedItis,
    { kind: "total" as const, id: "total", data: lineItems.sum((li) => li.amountInCents) },
  ];
}

function groupByTemplate(lis: BillPdf_BillLineItemsFragment[]): GridDataRow<Row>[] {
  const groupedbyTemplates = lis.groupBy(
    (li) => li.projectItem.itemTemplateItem?.template.name ?? "Non template items",
  );
  return Object.entries(groupedbyTemplates).map(([templateName, clis]) => {
    const [planItems, optionItems] = clis
      .uniqueByKey("id")
      .partition((li) => !li.projectItem.itemTemplateItem || li.projectItem.itemTemplateItem.template.isPlanTemplate);
    return {
      kind: "group" as const,
      id: templateName,
      data: planItems.first?.projectItem.itemTemplateItem ? `${templateName} Base House` : templateName,
      pin: templateName.includes("Base House") ? { at: "first" as const, filter: true } : undefined,
      children: [...planItems, ...optionItems].map((iti) => ({
        kind: "item" as const,
        id: iti.id,
        data: iti,
      })),
    };
  });
}

function groupByProratedLines(lis: BillPdf_BillLineItemsFragment[]): GridDataRow<Row>[] {
  // Group by trade line item
  const groupedItems = lis.groupBy(
    (bli) =>
      bli.commitmentLineItem?.primaryBidContractLineItem?.prorations.map((li) => li.tradeLineItem.id).join("") ?? "",
  );
  return Object.entries(groupedItems).map(([tradeId, blis]) => {
    return {
      kind: "group" as const,
      id: tradeId,
      data:
        lis.first?.commitmentLineItem?.primaryBidContractLineItem?.prorations.map((proration) => ({
          ...proration.tradeLineItem,
          totalCostInCents: blis.sum((li) => li.amountInCents),
        })) ?? [],
      children: blis.map((li) => ({ kind: "item" as const, id: li.id, data: li })),
    };
  });
}

function columns(): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({ item: emptyCell }),
    column<Row>({
      id: "qty",
      header: "QTY",
      group: (data) => ({
        content: Array.isArray(data) ? formatList(data.map((data) => data.productOffering.displayName)) : data,
        colspan: 3,
      }),
      item: ({ commitmentLineItem }) =>
        commitmentLineItem?.newQuantity || commitmentLineItem?.quantity || emptyCellDash,
      total: emptyCell,
      w: "200px",
    }),
    column<Row>({
      id: "uom",
      header: "UoM",
      group: emptyCell,
      item: ({ projectItem: { itemTemplateItem, unitOfMeasure } }) =>
        itemTemplateItem?.unitOfMeasure.abbreviation.toLowerCase() ?? unitOfMeasure.abbreviation.toLowerCase(), // use pi if no iti
      total: emptyCell,
      w: "100px",
    }),
    column<Row>({
      id: "itemCode",
      header: "Item Code",
      group: emptyCell,
      item: ({ projectItem: { itemTemplateItem, item } }) => itemTemplateItem?.item.fullCode ?? item.fullCode,
      total: emptyCell,
      w: "150px",
    }),
    column<Row>({
      id: "description",
      header: "Description",
      group: emptyCell,
      item: ({ projectItem: { itemTemplateItem, name } }) => itemTemplateItem?.name ?? name,
      total: "Total Amount Due",
      w: "200px",
    }),
    column<Row>({
      id: "unitCost",
      header: "Unit Cost",
      group: emptyCell,
      item: ({ commitmentLineItem }) => {
        const { quantity, newQuantity, newTotalCostInCents, primaryBidContractLineItem } = commitmentLineItem ?? {};
        return commitmentLineItem?.projectItem.unitOfMeasure.useQuantity &&
          !primaryBidContractLineItem?.prorations.nonEmpty
          ? priceCell({ valueInCents: (newTotalCostInCents || 0) / (newQuantity || quantity || 1) })
          : emptyCellDash;
      },
      total: emptyCell,
      w: "140px",
    }),
    column<Row>({
      id: "totalCost",
      header: "Total Cost",
      // Show cost for each trade line item
      // Labor tasks associated with multiple trade line items will have their costs accounted for in each individual trade line
      group: (data) =>
        Array.isArray(data) && data.length === 1 ? priceCell({ valueInCents: data[0].totalCostInCents }) : emptyCell,
      item: ({ amountInCents, commitmentLineItem }) =>
        commitmentLineItem?.primaryBidContractLineItem?.prorations.nonEmpty
          ? emptyCell
          : priceCell({ valueInCents: amountInCents }),
      total: (data) => priceCell({ valueInCents: data }),
      w: "160px",
    }),
  ];
}
