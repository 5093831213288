import { Css, useTestIds } from "@homebound/beam";
import { ConfigOptionsAndDelta, ProductOfferingConfigForm } from "../AddProductOfferingConfigPage";
import { AddProductOfferingConfig_ReadyPlanOptionsFragment } from "src/generated/graphql-types";
import { ObjectState } from "@homebound/form-state";
import { OptionsByOptionGroupSection } from "./ OptionsByOptionGroupSection";

export type GroupedOptionsProps = {
  groupedRpos: Record<string, ConfigOptionsAndDelta[]>;
  formState: ObjectState<ProductOfferingConfigForm>;
  recomputeCosts: (
    rpo: AddProductOfferingConfig_ReadyPlanOptionsFragment,
    isSelected: boolean,
    prevMultiSelectRpoId?: string,
  ) => Promise<void>;
};

export function OptionsByTypeSection(props: GroupedOptionsProps) {
  const { groupedRpos: groupedRposByType, formState, recomputeCosts } = props;
  const tid = useTestIds({});
  return (
    <div css={Css.w("40%").aic.my2.$}>
      {Object.entries(groupedRposByType).map(([typeName, rpos]) => {
        const rposGroupedByOptionGroup = rpos
          .sortBy((res) => res.rpo.optionGroup.order ?? Number.POSITIVE_INFINITY)
          .groupBy((res) => res.rpo.optionGroup.name);
        return (
          <div key={typeName} css={Css.aic.my2.$}>
            <div css={Css.xlBd.$} {...tid.optType}>
              {typeName}
            </div>
            <OptionsByOptionGroupSection
              groupedRpos={rposGroupedByOptionGroup}
              formState={formState}
              recomputeCosts={recomputeCosts}
            />
          </div>
        );
      })}
    </div>
  );
}
