import { Css, ScrollableParent, Step } from "@homebound/beam";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { stepperBarHeight, StepperProvider, useStepperContext } from "src/components/stepper";
import {
  DevelopmentScheduleItemMappingFragment,
  DevelopmentScheduleItemMappingPageFragment,
  MappingScheduleTemplateFragment,
  ScheduleTemplateStatus,
  useDevelopmentScheduleItemMappingPageQuery,
} from "src/generated/graphql-types";
import { CostMapStepEnum } from "src/routes/developments/cost-mapping/enums";
import { MapItemsStep } from "src/routes/developments/cost-mapping/mapping-steps/MapItemsStep";
import { SummaryStep } from "src/routes/developments/cost-mapping/mapping-steps/SummaryStep";
import { TemplateSelectStep } from "src/routes/developments/cost-mapping/mapping-steps/TemplateSelectStep";
import { DevelopmentParams } from "src/routes/routesDef";
import { createDevelopmentScopeTemplatesUrl } from "src/RouteUrls";
import { groupBy, queryResult } from "src/utils";
import { ExitIconButton } from "./components/ExitIconButton";

export function DevelopmentScheduleCostMappingPage() {
  const { developmentId } = useParams<DevelopmentParams>();
  const query = useDevelopmentScheduleItemMappingPageQuery({
    variables: { developmentId },
  });

  return queryResult(query, {
    data: ({ development }) => {
      return (
        <StepperProvider steps={initialSteps}>
          <DevelopmentScheduleCostMappingPageView development={development} />
        </StepperProvider>
      );
    },
  });
}

export type ScheduleTemplateWithMappingInfo = MappingScheduleTemplateFragment & {
  stageMappings: DevelopmentScheduleItemMappingFragment[];
  total: number;
  mapped: number;
};

function DevelopmentScheduleCostMappingPageView({
  development,
}: {
  development: DevelopmentScheduleItemMappingPageFragment;
}) {
  const history = useHistory();
  const { currentStep, isLastStep } = useStepperContext();
  const [selectedTemplate, setSelectedTemplate] = useState<ScheduleTemplateWithMappingInfo>();

  const activeScheduleTemplates = useMemo(
    () => development.scheduleTemplates.filter((st) => st.status === ScheduleTemplateStatus.Published),
    [development.scheduleTemplates],
  );
  const itemMappingsByStage = useMemo(
    () => groupBy(development.itemMappings, (im) => im.stage),
    [development.itemMappings],
  );
  const scheduleTemplatesWithMappedStats: ScheduleTemplateWithMappingInfo[] = useMemo(() => {
    return activeScheduleTemplates.map((st) => {
      const stageMappings = itemMappingsByStage[st.stage] ?? [];
      const currentMappings = stageMappings.filter((sm) =>
        sm.scheduleTemplateItemMappings.some((stim) => stim.scheduleTemplate.id === st.id),
      );
      return {
        ...st,
        stageMappings,
        total: stageMappings.length,
        mapped: currentMappings.length,
      };
    });
  }, [activeScheduleTemplates, itemMappingsByStage]);

  const renderStep = useMemo(() => {
    switch (currentStep.value) {
      case CostMapStepEnum.SUMMARY:
        return (
          <SummaryStep scheduleTemplates={scheduleTemplatesWithMappedStats} onTemplateSelect={setSelectedTemplate} />
        );
      case CostMapStepEnum.ITEMS:
        return <MapItemsStep selectedScopeTemplate={selectedTemplate!} />;
      case CostMapStepEnum.TEMPLATES:
      default:
        return (
          <TemplateSelectStep
            scheduleTemplates={scheduleTemplatesWithMappedStats}
            selectedTemplate={selectedTemplate}
            onTemplateSelect={setSelectedTemplate}
          />
        );
    }
  }, [currentStep.value, scheduleTemplatesWithMappedStats, selectedTemplate]);

  return (
    <>
      <ScrollableParent xss={Css.mxPx(60).mbPx(stepperBarHeight).$}>
        <div css={Css.df.jcsb.gap4.mt4.$}>
          <div css={Css.sm.gray700.ttu.$}>COST MAPPING</div>
          {!isLastStep && (
            <ExitIconButton
              showModal={currentStep.value !== CostMapStepEnum.TEMPLATES}
              onCloseConfirmed={() => history.push(createDevelopmentScopeTemplatesUrl(development.id))}
            />
          )}
        </div>
        {renderStep}
      </ScrollableParent>
    </>
  );
}

const initialSteps: Step[] = [
  {
    label: "Select schedule template",
    disabled: false,
    state: "incomplete",
    value: CostMapStepEnum.TEMPLATES,
  },
  {
    label: "Map items",
    disabled: true,
    state: "incomplete",
    value: CostMapStepEnum.ITEMS,
  },
  {
    label: "Summary",
    disabled: true,
    state: "incomplete",
    value: CostMapStepEnum.SUMMARY,
  },
];
