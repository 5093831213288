import {
  BoundMultiSelectField,
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  BusinessFunctionAssets_ChangeRequestAssetFragment,
  ProjectRoleDetail,
  SaveChangeRequestAssetInput,
  useProjectRolesForBusinessFunctionQuery,
  useSaveChangeRequestAssetsMutation,
} from "src/generated/graphql-types";
import { BusinessFunctionDetails, BusinessFunctionEnums } from "./BusinessFunctionAssets";
import { getRoleOptions } from "../utils";
import { queryResult } from "src/utils";

export function BusinessFunctionAssetsModal({
  enums,
  businessFunctionType,
  row,
}: {
  enums: BusinessFunctionEnums;
  businessFunctionType: BusinessFunctionDetails;
  row?: BusinessFunctionAssets_ChangeRequestAssetFragment;
}) {
  const query = useProjectRolesForBusinessFunctionQuery({
    variables: { businessFunctionType: businessFunctionType.code },
  });

  return queryResult(query, {
    data: (result) => {
      const { projectRolesForBusinessFunctionType: roles } = result;

      return (
        <BusinessFunctionAssetsModalForm
          businessFunctionType={businessFunctionType}
          enums={enums}
          roles={roles}
          row={row}
        />
      );
    },
  });
}

export function BusinessFunctionAssetsModalForm({
  enums,
  businessFunctionType,
  row,
  roles,
}: {
  enums: BusinessFunctionEnums;
  businessFunctionType: BusinessFunctionDetails;
  row: BusinessFunctionAssets_ChangeRequestAssetFragment | undefined;
  roles: Omit<ProjectRoleDetail, "sortOrder">[];
}) {
  const { openModal, closeModal } = useModal();
  const tids = useTestIds({}, "businessFunctionAssetsModal");
  const formState = useFormState({
    config: formConfig,
    init: {
      input: row,
      map: (data) => ({
        id: data.id,
        name: data.name,
        scopes: data.scopes,
        implementer: data.implementer?.code,
        implementationReviewer: data.implementationReviewer?.code,
        platform: data.platform.code,
      }),
    },
  });

  const [saveChangeRequestAssets] = useSaveChangeRequestAssetsMutation({});

  const onSave = async (formState: SaveChangeRequestAssetInput) => {
    await saveChangeRequestAssets({
      variables: { input: { changeRequestAssets: [{ ...formState, functionType: businessFunctionType.code }] } },
      onCompleted: closeModal,
      refetchQueries: ["BusinessFunctionAssets"],
    });
  };

  const onDelete = async () => {
    await saveChangeRequestAssets({
      variables: { input: { changeRequestAssets: [{ delete: true, id: row?.id }] } },
      update: (cache) => {
        cache.evict({ id: `ChangeRequestAsset:${row?.id}` });
      },
      onCompleted: closeModal,
    });
  };

  return (
    <Observer>
      {() => (
        <div {...tids}>
          <ModalHeader>{`${row?.id ? "Edit" : "Add New"} ${businessFunctionType.name} Asset`}</ModalHeader>
          <ModalBody>
            <FormLines>
              <BoundTextField field={formState.name} label="Asset Name" />
              <BoundMultiSelectField
                field={formState.scopes}
                label="Applicable Scope"
                options={enums.changeRequestAssetScope}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ code }) => code}
              />
              <BoundSelectField
                field={formState.implementer}
                label="Implementer"
                options={getRoleOptions(roles) ?? []}
              />
              <BoundSelectField
                field={formState.implementationReviewer}
                label="Implementation Reviewer"
                options={getRoleOptions(roles) ?? []}
                unsetLabel="--"
              />
              <BoundSelectField
                field={formState.platform}
                label="Platform"
                options={enums.changeRequestAssetPlatform}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ code }) => code}
              />
            </FormLines>
          </ModalBody>

          <ModalFooter>
            <div css={Css.df.jcsb.if(!!row?.id).fg1.$}>
              {row?.id && (
                <div css={Css.fg1.$}>
                  <Button
                    label="Delete"
                    {...tids.delete}
                    onClick={() =>
                      openModal({
                        content: (
                          <BusinessFunctionAssetsDeleteModal name={row.name} onClose={closeModal} onDelete={onDelete} />
                        ),
                      })
                    }
                    variant="tertiaryDanger"
                  />
                </div>
              )}
              <div css={Css.mr1.$}>
                <Button label="Cancel" onClick={closeModal} variant="secondary" {...tids.cancel} />
              </div>
              <Button
                label="Save"
                onClick={() => onSave(formState.value)}
                disabled={!formState.dirty || !formState.valid}
                {...tids.save}
              />
            </div>
          </ModalFooter>
        </div>
      )}
    </Observer>
  );
}

const formConfig: ObjectConfig<SaveChangeRequestAssetInput> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  scopes: { type: "value", rules: [required] },
  implementer: { type: "value", rules: [required] },
  implementationReviewer: { type: "value" },
  platform: { type: "value", rules: [required] },
};

type BusinessFunctionAssetsDeleteModalProps = {
  name: string;
  onDelete: () => void;
  onClose: VoidFunction;
};

function BusinessFunctionAssetsDeleteModal({ name, onDelete, onClose }: BusinessFunctionAssetsDeleteModalProps) {
  const tids = useTestIds({}, "businessFunctionAssetsDeleteModal");
  return (
    <div {...tids}>
      <ModalHeader>Delete Asset</ModalHeader>
      <ModalBody>
        <p>
          {`Are you sure you want to delete this asset `}
          <span css={Css.blue600.smSb.$}>`{name}`</span>
          {`? Deleting this asset will archive it. Any Change Requests that currently have this as a 
          selected asset will not keep the asset assigned, and will not be linked to that entity anymore.`}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" onClick={onClose} variant="secondary" {...tids.cancel} />
        <Button label="Delete" onClick={onDelete} {...tids.delete} />
      </ModalFooter>
    </div>
  );
}
