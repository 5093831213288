import { Css, Icons, increment, Margin, Only, px, Xss } from "@homebound/beam";
import { Fragment, ReactElement } from "react";

export const icons = {
  // beam migrated
  ...Icons,
  alertError: Icons.errorCircle,
  alertCheck: Icons.checkCircle,
  alertInfo: Icons.infoCircle,
  angleDoubleDown: Icons.chevronsDown,
  angleDoubleRight: Icons.chevronsRight,
  back: Icons.arrowBack,
  cancel: Icons.x,
  caretDown: Icons.chevronDown,
  checkboxOn: Icons.checkboxChecked,
  checkboxOff: Icons.checkbox,
  checkmark: Icons.check,
  cloud: Icons.cloudUpload,
  commentEmpty: Icons.comment,
  dollar: Icons.finances,
  expand: Icons.chevronRight,
  collapse: Icons.chevronLeft,
  file: Icons.fileBlank,
  help: Icons.helpCircle,
  image: Icons.file,
  invoiceDollar: Icons.dollar,
  loading: Icons.loader,
  listUl: Icons.projects,
  ok: Icons.checkCircle,
  pdf: Icons.file,
  tools: Icons.tradePartners,
  polaroids: Icons.images,
  openInNew: Icons.linkExternal,

  // custom icons
  close: (
    <path d="M18 7.20857L16.7914 6L12 10.7914L7.20857 6L6 7.20857L10.7914 12L6 16.7914L7.20857 18L12 13.2086L16.7914 18L18 16.7914L13.2086 12L18 7.20857Z" />
  ),

  status: <circle cx="12" cy="12" r="8" />,

  // Should go away with the Beam switch
  toggleOff: (
    <Fragment>
      <path d="M8 9C6.372 9 5 10.372 5 12C5 13.628 6.372 15 8 15C9.628 15 11 13.628 11 12C11 10.372 9.628 9 8 9Z" />
      <path d="M16 6H8C4.704 6 2.018 8.682 2 11.986C2 11.991 2 11.995 2 12V12.016C2 12.02 2 12.024 2 12.028C2.015 15.322 4.703 18 8 18H16C19.309 18 22 15.309 22 12C22 8.691 19.309 6 16 6ZM16 16H8C5.798 16 4.004 14.208 4 12.01C4.004 9.799 5.798 8 8 8H16C18.206 8 20 9.794 20 12C20 14.206 18.206 16 16 16Z" />
    </Fragment>
  ),

  flag: <path d="M19 4H6V2H4V20H3V22H4H6H7V20H6V15H19C19.553 15 20 14.552 20 14V5C20 4.448 19.553 4 19 4Z" />,

  scissors: (
    <>
      <path d="M10 6.5C10 4.57 8.43 3 6.5 3C4.57 3 3 4.57 3 6.5C3 8.43 4.57 10 6.5 10C7.085 10 7.628 9.842 8.113 9.587L10.47 12.115L8.152 14.433C7.658 14.166 7.101 14 6.5 14C4.57 14 3 15.57 3 17.5C3 19.43 4.57 21 6.5 21C8.43 21 10 19.43 10 17.5C10 16.899 9.834 16.342 9.566 15.848L11.835 13.58L17 19.121C17.563 19.684 18.326 20 19.121 20H22L9.35 8.518C9.756 7.946 10 7.253 10 6.5ZM6.5 8C5.673 8 5 7.327 5 6.5C5 5.673 5.673 5 6.5 5C7.327 5 8 5.673 8 6.5C8 7.327 7.327 8 6.5 8ZM6.5 19C5.673 19 5 18.327 5 17.5C5 16.673 5.673 16 6.5 16C7.327 16 8 16.673 8 17.5C8 18.327 7.327 19 6.5 19Z" />
      <path d="M17 4.879L13.293 9.293L14.707 10.707L22 4H19.121C18.326 4 17.563 4.316 17 4.879Z" />
    </>
  ),
  diamond: (
    <path
      d="M12.103 2.429 12 2.279 11.897 2.429 5.397 11.929 5.349 12 5.397 12.071 11.897 21.571 12 21.721 12.103 21.571 18.603 12.071 18.651 12 18.603 11.929 12.103 2.429ZM12 17.955 7.881 12 12 6.045 16.119 12 12 17.955Z"
      stroke="#242424"
      strokeWidth="0.25"
    />
  ),
  criticalPath: (
    <>
      <path d="M15.344 20H7C5.621 20 4.5 18.879 4.5 17.5C4.5 16.121 5.621 15 7 15H14C15.93 15 17.5 13.43 17.5 11.5C17.5 9.57 15.93 8 14 8H9.139C8.748 8.771 8.271 9.443 7.785 10H14C14.827 10 15.5 10.673 15.5 11.5C15.5 12.327 14.827 13 14 13H7C4.519 13 2.5 15.019 2.5 17.5C2.5 19.981 4.519 22 7 22H16.593C16.136 21.422 15.7 20.753 15.344 20ZM5.5 2C3.846 2 2.5 3.346 2.5 5C2.5 8.188 5.5 10 5.5 10C5.5 10 8.5 8.187 8.5 5C8.5 3.346 7.154 2 5.5 2ZM5.5 6.5C4.672 6.5 4 5.828 4 5C4 4.172 4.672 3.5 5.5 3.5C6.328 3.5 7 4.172 7 5C7 5.828 6.328 6.5 5.5 6.5Z" />
      <path d="M19.5 14C17.846 14 16.5 15.346 16.5 17C16.5 20.188 19.5 22 19.5 22C19.5 22 22.5 20.187 22.5 17C22.5 15.346 21.154 14 19.5 14ZM19.5 18.5C18.672 18.5 18 17.828 18 17C18 16.172 18.672 15.5 19.5 15.5C20.328 15.5 21 16.172 21 17C21 17.828 20.328 18.5 19.5 18.5Z" />
    </>
  ),
  mapPin: (
    <>
      <path d="M12 17L13 15V9.858C14.721 9.411 16 7.858 16 6C16 3.794 14.206 2 12 2C9.794 2 8 3.794 8 6C8 7.858 9.279 9.411 11 9.858V15L12 17Z" />
      <path d="M16.267 10.563L15.734 12.491C18.325 13.207 20 14.584 20 16C20 17.892 16.715 20 12 20C7.285 20 4 17.892 4 16C4 14.584 5.675 13.207 8.267 12.49L7.734 10.562C4.197 11.54 2 13.623 2 16C2 19.364 6.393 22 12 22C17.607 22 22 19.364 22 16C22 13.623 19.803 11.54 16.267 10.563Z" />
    </>
  ),
  copy: (
    <>
      <path d="M20 2H10C8.897 2 8 2.897 8 4V8H4C2.897 8 2 8.897 2 10V20C2 21.103 2.897 22 4 22H14C15.103 22 16 21.103 16 20V16H20C21.103 16 22 15.103 22 14V4C22 2.897 21.103 2 20 2ZM4 20V10H14L14.002 20H4ZM20 14H16V10C16 8.897 15.103 8 14 8H10V4H20V14Z" />
    </>
  ),
  hardHatInverse: (
    <>
      <path d="M 4.175781 12.660156 C 4.070312 13.1875 4.015625 13.726562 4.015625 14.265625 L 4.015625 16.445312 L 2.667969 16.445312 L 2.667969 18.933594 L 21.332031 18.933594 L 21.332031 16.445312 L 19.984375 16.445312 L 19.984375 14.265625 C 19.972656 13.710938 19.902344 13.15625 19.78125 12.613281 C 19.671875 12.101562 19.515625 11.601562 19.3125 11.117188 C 19.117188 10.640625 18.867188 10.183594 18.570312 9.761719 C 18.285156 9.347656 17.960938 8.964844 17.601562 8.621094 C 17.253906 8.257812 16.871094 7.929688 16.460938 7.640625 C 16.128906 7.421875 15.78125 7.230469 15.421875 7.058594 L 15.421875 14.265625 L 13.140625 14.265625 L 13.140625 4 L 10.859375 4 L 10.859375 14.265625 L 8.578125 14.265625 L 8.578125 7.058594 C 8.21875 7.226562 7.871094 7.421875 7.539062 7.640625 C 6.6875 8.21875 5.953125 8.953125 5.371094 9.804688 C 5.085938 10.234375 4.84375 10.6875 4.640625 11.164062 C 4.433594 11.644531 4.277344 12.144531 4.175781 12.660156 Z M 4.175781 12.660156 " />
    </>
  ),
  truck: (
    <>
      <path d="M 19.148438 7.972656 C 18.789062 7.371094 18.132812 7 17.433594 7 L 15 7 L 15 5 C 15 4.445312 14.554688 4 14 4 L 4 4 C 2.898438 4 2 4.898438 2 6 L 2 16 C 2 16.746094 2.414062 17.390625 3.023438 17.734375 C 3.144531 19.554688 4.648438 21 6.5 21 C 8.257812 21 9.703125 19.691406 9.949219 18 L 13.050781 18 C 13.296875 19.691406 14.742188 21 16.5 21 C 18.257812 21 19.703125 19.691406 19.949219 18 L 20 18 C 21.101562 18 22 17.101562 22 16 L 22 13 C 22 12.816406 21.949219 12.640625 21.855469 12.484375 Z M 15 9 L 17.433594 9 L 19.234375 12 L 15 12 Z M 6.5 19 C 5.671875 19 5 18.328125 5 17.5 C 5 16.671875 5.671875 16 6.5 16 C 7.328125 16 8 16.671875 8 17.5 C 8 18.328125 7.328125 19 6.5 19 Z M 16.5 19 C 15.671875 19 15 18.328125 15 17.5 C 15 16.671875 15.671875 16 16.5 16 C 17.328125 16 18 16.671875 18 17.5 C 18 18.328125 17.328125 19 16.5 19 Z M 16.5 19 " />
    </>
  ),
  // MUI icons were copy/pasted from https://fonts.google.com/icons?icon.query=home&icon.size=24&icon.color=%235f6368&icon.platform=web
  // for Projects
  muiHome: (
    <>
      <path d="M160-120v-480l320-240 320 240v480H560v-280H400v280H160Z" />
    </>
  ),
  // for Commitments
  muiReceiptLong: (
    <>
      <path d="M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480Z" />
    </>
  ),
  // for Trade Partners
  muiConstruction: (
    <>
      <path d="M756-120 537-339l84-84 219 219-84 84Zm-552 0-84-84 276-276-68-68-28 28-51-51v82l-28 28-121-121 28-28h82l-50-50 142-142q20-20 43-29t47-9q24 0 47 9t43 29l-92 92 50 50-28 28 68 68 90-90q-4-11-6.5-23t-2.5-24q0-59 40.5-99.5T701-841q15 0 28.5 3t27.5 9l-99 99 72 72 99-99q7 14 9.5 27.5T841-701q0 59-40.5 99.5T701-561q-12 0-24-2t-23-7L204-120Z" />
    </>
  ),
  // for Plan Packages
  muiFoundation: (
    <>
      <path d="M200-120v-160h-80v-80h80v-166L88-440l-48-64 440-336 440 336-48 64-112-86v166h80v80h-80v160h-80v-160H520v160h-80v-160H280v160h-80Zm80-240h160v-349L280-587v227Zm240 0h160v-227L520-709v349Z" />
    </>
  ),
  // for Design Packages
  muiPalette: (
    <>
      <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 32.5-156t88-127Q256-817 330-848.5T488-880q80 0 151 27.5t124.5 76q53.5 48.5 85 115T880-518q0 115-70 176.5T640-280h-74q-9 0-12.5 5t-3.5 11q0 12 15 34.5t15 51.5q0 50-27.5 74T480-80ZM260-440q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120-160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm200 0q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120 160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Z" />
    </>
  ),
  // for Product Offering
  muiInstantMix: (
    <>
      <path d="M200-160v-280h-80v-80h240v80h-80v280h-80Zm0-440v-200h80v200h-80Zm160 0v-80h80v-120h80v120h80v80H360Zm80 440v-360h80v360h-80Zm240 0v-120h-80v-80h240v80h-80v120h-80Zm0-280v-360h80v360h-80Z" />
    </>
  ),
} as const;

export type IconKey = keyof typeof icons;

type IconXss = Xss<Margin>;

export type IconProps<X> = {
  id?: string;
  icon: keyof typeof icons;
  // TODO: This should go away and get pushed up into an `IconOnlyButton` component
  // that is a) more semantically correct and b) aria compliant. Basically adding
  // `onClick`s to random elements is an anti-pattern.
  onClick?: () => void;
  /** The size of the icon in increments, i.e. 1 == 8px, default is 3 == 24px. */
  inc?: number;
  /** The color to use, defaults to `currentColor` to match the surrounding text. */
  color?: string;
  xss?: X;
  /** The size of the icon in px */
  pxSize?: number;
};

export function Icon<X extends Only<IconXss, X>>(props: IconProps<X>): ReactElement {
  const {
    icon,
    // This prop || icon || id is a little excessive, but maybe good?
    id = (props as any)["data-testid"] || icon,
    inc = 3,
    color = "currentColor",
    xss,
    pxSize,
    onClick,
  } = props;
  const size = pxSize ? px(pxSize) : increment(inc);
  return (
    <svg
      aria-hidden={true}
      width={size}
      height={size}
      // mui icons use a different viewBox.
      // Use `icon?` because we have some stories that pass in invalid icons...
      viewBox={icon?.startsWith("mui") ? `0 -960 960 960` : `0 0 24 24`}
      xmlns="http://www.w3.org/2000/svg"
      css={{
        ...Css.fill(color).$,
        ...(xss ? xss : {}),
      }}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
      data-testid={id}
      data-icon={icon}
    >
      {icons[icon]}
    </svg>
  );
}
