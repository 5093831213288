import { IObservableArray, observable } from "mobx";
import { DevelopmentCommitmentPotentialLineItemFragment } from "src/generated/graphql-types";
import { observableAggregate, Selectable } from "src/models";
import { sum } from "src/utils";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";
import { DevelopmentCommitmentPotentialLineItem } from "./DevelopmentCommitmentPotentialLineItem";

export class DevelopmentCommitmentPotentialLineItemStore extends Selectable<ObservableCostCodeGroup> {
  readonly items: IObservableArray<DevelopmentCommitmentPotentialLineItem>;

  constructor(lineItems: DevelopmentCommitmentPotentialLineItemFragment[]) {
    const items = observable(lineItems.map((li) => new DevelopmentCommitmentPotentialLineItem(li)));
    const children = observableAggregate(items, (li) => li.itemCostCode.id, ObservableCostCodeGroup) as any;
    super("all", false, children);
    makeSimpleAutoObservable(this);
    this.items = items;
  }

  get selectedLineItems() {
    return this.items.filter((item) => item.selected);
  }
}

export class ObservableCostCodeGroup extends Selectable<DevelopmentCommitmentPotentialLineItem> {
  public name;

  constructor(lineItems: DevelopmentCommitmentPotentialLineItem[]) {
    const cc = lineItems[0].itemCostCode;
    super(cc.id, false, lineItems);
    this.name = cc.displayName;
    makeSimpleAutoObservable(this);
  }

  get totalCostInCents(): number {
    return this.children.map((c) => c.totalCostInCents || 0).reduce(sum, 0);
  }
}
