import { FetchResult } from "@apollo/client";
import {
  CommitmentStatus,
  ProjectFeature,
  SaveScheduleTasksMutation,
  useTaskBillModalContextLazyQuery,
} from "src/generated/graphql-types";
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useScheduleStore } from "./ScheduleStore";
import { HbLoadingSpinner, useModal } from "@homebound/beam";
import { TaskBillModal, TaskBillModalProps } from "../detail-pane/taskBills/TaskBillModal";
import { ScheduleType } from "../table/ScheduleType";

type TaskBillModalContextType = {
  // Function to open taskBill modal with the needed modal opts
  openTaskBillModal: (modalOpts: TaskBillModalProps) => void;
  // The `handleSaveTask` mutation result object to be accessed by consuming component that opened the modal
  contextSavedTaskResult: FetchResult<SaveScheduleTasksMutation>;
};

export const TaskBillContext = createContext<TaskBillModalContextType>({
  openTaskBillModal() {},
  contextSavedTaskResult: {},
});

// We create a C2P taskBill modal context in order to provide the ability to
// 1. Open the taskBill modal from any component
// 2. Take a `handleSaveTask` function that can only be called _after_ the taskBill modal form is submitted correctly
// 3. Provide the `handleSaveTask` mutation result object to the component that opened the modal
export function TaskBillModalProvider({ children }: { children: React.ReactNode }) {
  const { openModal } = useModal();
  const {
    scheduleState: { scheduleType },
  } = useScheduleStore();
  const [loadTask] = useTaskBillModalContextLazyQuery();
  const [contextSavedTaskResult, setContextTaskResult] = useState<FetchResult<SaveScheduleTasksMutation>>({});

  const openTaskBillModal = useCallback(
    async (modalOpts: TaskBillModalProps) => {
      const { scheduleTaskId, tradePartnerId, handleTaskSave, skipModal, onComplete } = modalOpts;
      if (!scheduleTaskId) fail("No schedule task id provided");

      // query and load the task to make sure we have all the required data before opening the modal
      const { loading, data } = await loadTask({ variables: { scheduleTaskId } });
      if (data) {
        const scheduleTask = data?.scheduleTask ?? fail("No schedule task found");
        const savedTaskHasUnbilledItems = (scheduleTask.projectItems ?? [])
          .flatMap((pi) => pi.commitmentLineItems)
          .filter((cli) => cli.owner.status === CommitmentStatus.Signed && cli.owner.tradePartner?.enableClickToPay)
          .some((cli) => cli.pendingUnbilledInCents !== 0);
        const showClickToPayFlag = scheduleTask.project?.features?.includes(ProjectFeature.ClickToPay);

        // If the task does not have unbilled items, we don't need to open the modal
        // And we can just call the handleTaskSave function and set the result in the context
        if (!savedTaskHasUnbilledItems || !showClickToPayFlag || skipModal) {
          const saveResult = handleTaskSave && (await handleTaskSave());
          saveResult && setContextTaskResult(saveResult);
          onComplete && (await onComplete());
          return;
        }

        if (savedTaskHasUnbilledItems && showClickToPayFlag && scheduleType === ScheduleType.Project) {
          openModal({
            content: (
              <TaskBillModal
                scheduleTaskId={scheduleTask.id}
                tradePartnerId={tradePartnerId}
                handleTaskSave={handleTaskSave}
                setContextTaskResult={setContextTaskResult}
                onComplete={onComplete}
              />
            ),
          });
        }
      } else if (loading) {
        openModal({ content: <HbLoadingSpinner /> });
      }
    },
    [loadTask, openModal, scheduleType],
  );

  const savedTaskRes = useMemo(() => contextSavedTaskResult, [contextSavedTaskResult]);

  return (
    // Provider gives access to the `openTaskBillModal` function and
    // The saved task result to be accessed by any child component
    <TaskBillContext.Provider value={{ contextSavedTaskResult: savedTaskRes, openTaskBillModal }}>
      {children}
    </TaskBillContext.Provider>
  );
}

export const useTaskBillContext = () => useContext(TaskBillContext);
