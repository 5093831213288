import { Button, ButtonGroup, ButtonMenu, Css, Icon, Tag, useTestIds } from "@homebound/beam";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  addEntityParam,
  createProductOfferingUrl,
  createDevelopmentProductOfferingUrl,
  createProductOfferingConfigsUrl,
} from "src/RouteUrls";
import { ProductOfferingsDetailFragment, useProductOfferingsPageQuery } from "src/generated/graphql-types";
import { EmptyState } from "src/routes/components/EmptyState";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DevelopmentParams } from "src/routes/routesDef";
import { queryResult, readyPlanStatusToTagType } from "src/utils";
import { EmptyPlanAndOptionImage } from "../plan-and-options/components/EmptyStateSvgs";
import { usePersistedToggle } from "src/hooks";
import { ProductOfferingsTable } from "./ProductOfferingsTable";
import { PLAN_FALLBACK_IMG } from "src/routes/libraries/plan-package/takeoffs/utils";
import { ProgressPill } from "src/components";
import { programDataBathRange, programDataRange } from "src/utils/programData";

export function ProductOfferingsPage() {
  const { developmentId } = useParams<DevelopmentParams>();
  const query = useProductOfferingsPageQuery({ variables: { development: developmentId } });

  return queryResult(query, {
    data: ({ productOfferings }) => {
      return <ProductOfferingsPageView developmentId={developmentId} productOfferings={productOfferings.entities} />;
    },
  });
}

type ProductOfferingsPageViewProps = {
  productOfferings: ProductOfferingsDetailFragment[];
  developmentId: string;
};

function ProductOfferingsPageView({ productOfferings, developmentId }: ProductOfferingsPageViewProps) {
  const { length } = productOfferings;
  const history = useHistory();
  const [onTileView, toggleView] = usePersistedToggle("productOfferingsTileView", true);
  return (
    <>
      <PageHeader
        title="Product Offerings"
        right={
          <>
            {length !== 0 && (
              <>
                <ButtonMenu
                  trigger={{ label: "Actions" }}
                  items={[{ label: "Compare Configurations", onClick: createProductOfferingConfigsUrl(developmentId) }]}
                />
                <Button
                  label="Create New"
                  onClick={() => history.push(createProductOfferingUrl(addEntityParam, developmentId))}
                />
              </>
            )}
          </>
        }
      />

      {length === 0 ? (
        <EmptyState
          message="There are no Product Offerings for this development yet"
          svg={<EmptyPlanAndOptionImage />}
          button={
            <Button
              label="Create Product Offering"
              onClick={() => history.push(createProductOfferingUrl(addEntityParam, developmentId))}
            />
          }
        />
      ) : (
        <>
          <div css={Css.df.gap1.aic.pb2.$}>
            <div css={Css.smBd.$}>View</div>
            <ButtonGroup
              buttons={[
                {
                  icon: "tile",
                  tooltip: "Tile View",
                  active: onTileView,
                  onClick: toggleView,
                },
                {
                  icon: "list",
                  tooltip: "List View",
                  active: !onTileView,
                  onClick: toggleView,
                },
              ]}
            />
          </div>
          {onTileView ? (
            <div data-testid="productOfferingList" css={Css.dg.gap3.pb2.jcc.gtc("repeat(auto-fill, 369px)").$}>
              {productOfferings.map((po) => (
                <ProductOfferingCard key={po.id} productOffering={po} developmentId={developmentId} />
              ))}
            </div>
          ) : (
            <div>
              <ProductOfferingsTable productOfferings={productOfferings} />
            </div>
          )}
        </>
      )}
    </>
  );
}

type ProductOfferingCardProp = {
  productOffering: ProductOfferingsDetailFragment;
  developmentId: string;
};

export function ProductOfferingCard({ productOffering, developmentId }: ProductOfferingCardProp) {
  const tid = useTestIds({}, "productOfferingCard");
  const { name, id, status, coverAsset, minProgramData, maxProgramData, options, planPackage, bidOutPercentage } =
    productOffering;
  const coverPhotoUrl = coverAsset?.previewUrl ?? PLAN_FALLBACK_IMG;
  const elevationsCount = options?.filter((option) => option?.type.isElevation).length ?? 0;
  return (
    <Link
      {...tid}
      css={Css.bgWhite.wPx(369).br8.bshBasic.p3.df.fdc.jcsb.gap3.aifs.onHover.bshHover.$}
      to={createDevelopmentProductOfferingUrl(developmentId, id)}
    >
      <div css={Css.w100.color("black").$}>
        <div css={Css.relative.$}>
          <img src={coverPhotoUrl} alt="Product Offering cover photo" {...tid.coverPhoto} css={Css.hPx(184).ma.$} />
          <div css={Css.absolute.top1.left1.$}>
            <Tag text={status.name} type={readyPlanStatusToTagType[status.code]} {...tid.status} />
          </div>
        </div>
        <div css={Css.mt3.$}>
          <div css={Css.df.jcsb.aic.$}>
            <h4 css={Css.xlSb.mbPx(4).$} {...tid.name}>
              {name}
            </h4>
            <Icon icon="verticalDots" />
          </div>
          <div css={Css.df.fdc.aic.w100.mt1.mb2.$}>
            <div css={Css.df.w100.$}>
              <span css={Css.sm.w50.$}>{programDataRange(minProgramData, maxProgramData, "sellableSqft")} sq ft</span>
              <span css={Css.sm.w50.$}>{elevationsCount} elevations</span>
            </div>
            <div css={Css.df.w100.$}>
              <span css={Css.sm.w50.$}>{programDataRange(minProgramData, maxProgramData, "bedrooms")} bed</span>
              <span css={Css.sm.w50.$}>Width: {programDataRange(minProgramData, maxProgramData, "widthInFeet")}</span>
            </div>
            <div css={Css.df.w100.$}>
              <span css={Css.sm.w50.$}>{programDataBathRange(minProgramData, maxProgramData)} bath</span>
              <span css={Css.sm.w50.$}>Depth: {programDataRange(minProgramData, maxProgramData, "depthInFeet")}</span>
            </div>
          </div>
          <div css={Css.smMd.mb1.$}>{planPackage.code}</div>
          <ProgressPill progress={bidOutPercentage} changeColorOnCompleted />
        </div>
      </div>
    </Link>
  );
}
