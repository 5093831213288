import { Css } from "@homebound/beam";
import { BannerNotice } from "src/components";
import { ManageProjectsBudgetsPage } from "./ManageProjectsBudgetsPage";
import { WizardHeader } from "src/components/stepper/useStepperWizard/WizardHeader";

export function ManageProjectBudgetsStep() {
  return (
    <WizardHeader
      pre="Update Project Budgets"
      header="Manage project budgets"
      post="Select the projects to apply changes and update the awarded budgets for."
      postBd="Once approved, budgets will be automatically updated."
    >
      <div css={Css.mt3.$}>
        <BannerNotice
          icon="infoCircle"
          message={
            <p>
              All changes will <strong>be automatically released</strong> after budget updates are approved
            </p>
          }
        />
      </div>

      <ManageProjectsBudgetsPage />
    </WizardHeader>
  );
}
