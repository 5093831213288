import {
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectField,
  ToggleChip,
  useComputed,
  useModal,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import {
  CreateCommitmentDataProjectFragment,
  SaveDevelopmentCommitmentInput,
  useCreateCommitmentDataQuery,
  useCreateCommitmentPotentialLineItemsQuery,
  useCreateDevelopmentCommitmentMutation,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { DevelopmentCommitmentPotentialLineItemStore } from "src/routes/development-commitments/models/DevelopmentCommitmentPotentialLineItemStore";
import { createDevelopmentCommitmentUrl } from "src/RouteUrls";
import { groupBy, pushInNextLoop } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { isContractualStage } from "src/utils/projects";
import { DevelopmentCommitmentPotentialLineItemTable } from "./DevelopmentCommitmentPotentialLineItemTable";

type CohortOption = {
  id: string;
  name: string;
  projectIds: string[];
};

export function CreateCommitmentModal() {
  const { data } = useCreateCommitmentDataQuery();
  const formState = useFormState({
    config: formConfig,
  });
  const [createDevelopmentCommitment] = useCreateDevelopmentCommitmentMutation();
  const { closeModal, setSize } = useModal();
  const [showSelectItems, toggleShowSelectItems] = useToggle(false);
  const { data: potentialLineItems } = useCreateCommitmentPotentialLineItemsQuery({
    variables: {
      projectIds: formState.projectIds.value || [],
      stage: formState.stage.value,
    },
    skip: !showSelectItems,
  });

  const history = useHistory();

  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSize(showSelectItems ? "xl" : "md"), [showSelectItems]);

  // Create line items store.
  const store = useMemo(
    () =>
      new DevelopmentCommitmentPotentialLineItemStore(
        potentialLineItems?.developmentCommitmentPotentialLineItems || [],
      ),
    [potentialLineItems],
  );

  const cohortAndProjectsOptions = useComputed(() => {
    if (!data?.projects?.length) {
      return [];
    }

    // filter out already selected projects
    const projects = data?.projects
      .filter((project) => !(formState.projectIds.value || []).includes(project.id))
      .filter((project) => project.cohort?.development?.id === formState.developmentId.value);
    // create the cohorts from the existing projects
    const cohorts = Object.values(
      groupBy(
        projects.filter((p) => !!p.cohort),
        (p) => p.cohort!.id,
      ),
    ).map((cohortProjects) => {
      // grab the cohort from the 1st project
      const cohort = cohortProjects[0].cohort!;
      return {
        id: cohort.id,
        name: `${cohort.market.name} ${cohort.name}`,
        projectIds: cohortProjects.map((p) => p.id),
      };
    });

    // combine/sort alphabetically
    return [...projects, ...cohorts].sort((a, b) => a.name.localeCompare(b.name));
  }, [data?.projects]);

  // Determine if there are projects selected in multiple markets
  const projectsErrorMessage = useComputed(() => {
    if (!data?.projects?.length) {
      return undefined;
    }

    const projectIds = formState.projectIds.value || [];
    let marketId = undefined;
    if (projectIds.length > 1) {
      for (let i = 0; i < projectIds.length; i++) {
        const project = data.projects.find((project) => project.id === projectIds[i]);
        if (!!marketId && marketId !== project!.market.id) {
          return "You cannot select projects from two separate markets.";
        }
        marketId = project!.market.id;
      }
    }
    return undefined;
  }, [data?.projects]);

  if (showSelectItems) {
    return (
      <>
        <ModalHeader>Select Items</ModalHeader>
        <ModalBody>
          <DevelopmentCommitmentPotentialLineItemTable stage={formState.stage.value!} store={store} />
        </ModalBody>
        <ModalFooter xss={Css.jcsb.$}>
          <Observer>
            {() => (
              <>
                <div>
                  <Button label="Back" variant="tertiary" onClick={() => toggleShowSelectItems()} icon="chevronLeft" />
                </div>
                <div css={Css.df.gap1.$}>
                  <Button variant="tertiary" label="Cancel" onClick={closeModal} />
                  <Button
                    disabled={!formState.valid || !store.selectedLineItems?.length || !!projectsErrorMessage}
                    label="Create Commitment"
                    onClick={async () => {
                      if (formState.canSave()) {
                        // get the first project to determine the market
                        const firstProject = data!.projects.find(
                          (project) => project.id === formState.projectIds.value![0],
                        );

                        const res = await createDevelopmentCommitment({
                          variables: {
                            input: {
                              marketId: firstProject!.market.id,
                              name: formState.name.value,
                              projectIds: formState.projectIds.value,
                              stage: formState.stage.value,
                              tradePartnerId: formState.tradePartnerId.value,
                              potentialLineItems: store.selectedLineItems.map(({ itemId, costType, locationObj }) => ({
                                itemId,
                                costType,
                                locationId: locationObj?.id,
                              })),
                              developmentId: formState.developmentId.value,
                            },
                          },
                        });
                        const commitmentId = res.data?.saveDevelopmentCommitment.developmentCommitment.id;
                        formState.commitChanges();
                        pushInNextLoop(history, createDevelopmentCommitmentUrl(commitmentId!));
                        closeModal();
                      }
                    }}
                  />
                </div>
              </>
            )}
          </Observer>
        </ModalFooter>
      </>
    );
  }

  return (
    <>
      <ModalHeader>Create Commitment</ModalHeader>
      <ModalBody>
        <h2 css={Css.baseMd.mb2.$}>Details</h2>
        <div css={Css.mb2.$}>
          <BoundTextField field={formState.name} label="Commitment Name" />
        </div>
        <div css={Css.mb2.$}>
          <BoundSelectField
            label="Stage"
            field={formState.stage}
            options={data?.stages.filter((d) => isContractualStage(d.code)) || []}
            getOptionLabel={(d) => d.name}
            getOptionValue={(d) => d.code}
          />
        </div>
        <div css={Css.mb2.$}>
          <BoundSelectField
            label="Trade Partner (Optional)"
            field={formState.tradePartnerId}
            options={data?.tradePartners || []}
          />
        </div>
        <div css={Css.mb2.$}>
          <BoundSelectField
            label="Development"
            field={formState.developmentId}
            options={data?.developments || []}
            onSelect={(itemId) => {
              formState.developmentId.set(itemId);
              formState.projectIds.set([]);
            }}
          />
        </div>

        <h2 css={Css.baseMd.mt5.mb2.$}>Projects</h2>
        <Observer>
          {() => (
            <>
              <div>
                {(formState.projectIds.value || []).map((projectId) => {
                  const project = data!.projects.find((project) => project.id === projectId);
                  return (
                    project && (
                      <ToggleChip
                        key={project.id}
                        text={project.name}
                        onClick={() => {
                          const currentProjectIds = formState.projectIds.value || [];
                          formState.projectIds.set(currentProjectIds.filter((projectId) => projectId !== project.id));
                        }}
                        xss={Css.mr1.mb1.$}
                      />
                    )
                  );
                })}
              </div>
              <div css={Css.mb2.$}>
                <SelectField
                  data-testid="cohortsProjects"
                  errorMsg={projectsErrorMessage}
                  label="Cohorts / Projects"
                  getOptionLabel={(option: CreateCommitmentDataProjectFragment | CohortOption) => {
                    if ("projectIds" in option) {
                      return `${option.name} (${option.projectIds.length} lot${
                        option.projectIds.length !== 1 ? "s" : ""
                      })`;
                    }
                    return option.name;
                  }}
                  value={undefined}
                  options={cohortAndProjectsOptions}
                  onSelect={(v: any, option: CreateCommitmentDataProjectFragment | CohortOption | undefined) => {
                    const currentProjectIds = formState.projectIds.value || [];
                    if (option) {
                      if ("projectIds" in option) {
                        formState.projectIds.set([...currentProjectIds, ...option.projectIds]);
                      } else {
                        formState.projectIds.set([...currentProjectIds, option.id]);
                      }
                    }
                  }}
                />
              </div>
            </>
          )}
        </Observer>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button variant="tertiary" label="Cancel" onClick={closeModal} />
              <Button
                disabled={!formState.valid || !!projectsErrorMessage}
                label="Next"
                onClick={() => toggleShowSelectItems()}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValue = Pick<
  SaveDevelopmentCommitmentInput,
  "name" | "projectIds" | "stage" | "tradePartnerId" | "developmentId"
>;

const formConfig: ObjectConfig<FormValue> = {
  name: { type: "value", rules: [required] },
  projectIds: { type: "value", rules: [required] },
  stage: { type: "value", rules: [required] },
  tradePartnerId: { type: "value", rules: [] },
  developmentId: { type: "value", rules: [required] },
};
