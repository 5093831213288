import { ValueAdapter } from "@homebound/form-state";
import { roundToDigits } from "src/utils/numbers";
import { isDefined } from "src/utils/arrays";

export const inchesToFeet: ValueAdapter<number | null | undefined> = {
  toValue(inches) {
    return !isDefined(inches) ? inches : roundToDigits(inches / 12, 2);
  },
  fromValue(feet) {
    return !isDefined(feet) ? feet : Math.round(feet * 12);
  },
};
