import { createContext, useContext, useRef, ReactNode } from "react";
import { createStore, useStore, StoreApi } from "zustand";
import {
  DraftPlanTaskInput,
  ScheduleDraftMode_PlanTaskFragment,
  DraftPlanScheduleQuery,
  SavePlanTaskScheduleFlagInput,
  InputMaybe,
  ScheduleFlagReasonType,
} from "src/generated/graphql-types";

const LAST_UPDATED_NOTIFICATION_TIMEOUT = 4_000;

export type UserAddedScheduleFlagsInput = Pick<
  SavePlanTaskScheduleFlagInput,
  "clientId" | "reasonId" | "durationInDays" | "id"
> & {
  taskId: string;
  taskName: string;
  title: string;
  scheduleFlagReasonType: {
    name: string;
    code: ScheduleFlagReasonType;
  };
};

export type DraftScheduleStoreState = {
  draftTaskChanges: DraftPlanTaskInput[];
  addDraftTaskChanges: (input: DraftPlanTaskInput[]) => void;
  initialTaskValues?: Record<string, ScheduleDraftMode_PlanTaskFragment>;
  maybeSetInitialValues: (input: DraftPlanScheduleQuery) => void;
  getInitialTaskValue: (taskId: string) => ScheduleDraftMode_PlanTaskFragment | undefined;
  reset: () => void;
  resetRequiredTaskScheduleFlag: (requiredFlags: SavePlanTaskScheduleFlagInput[]) => void;
  requiredScheduleFlags: SavePlanTaskScheduleFlagInput[];
  setRequiredScheduleFlags: (input: SavePlanTaskScheduleFlagInput[]) => void;
  userAddedScheduleFlags: UserAddedScheduleFlagsInput[];
  setUserAddedScheduleFlags: (input: UserAddedScheduleFlagsInput[]) => void;
  removeUserAddedScheduleFlags: (clientId: string) => void;
  // A record of validation errors keyed by the ID of whatever ObjectState gave the error
  validationErrors: Record<string, string[]>;
  setValidationErrors: (input: Record<string, string[]>) => void;
  lastUpdatedTaskId: InputMaybe<string>;
};

type ScheduleDraftStore = StoreApi<DraftScheduleStoreState>;
export type MockInitialValues = Pick<
  DraftScheduleStoreState,
  "draftTaskChanges" | "initialTaskValues" | "userAddedScheduleFlags" | "requiredScheduleFlags"
>;
type DraftScheduleStoreProviderProps = {
  children: ReactNode;
  // Override the store initial state for testing purposes
  mockInitialValues?: MockInitialValues;
};

const StoreContext = createContext<ScheduleDraftStore | undefined>(undefined);

export function DraftScheduleStoreProvider({ children, mockInitialValues }: DraftScheduleStoreProviderProps) {
  const storeRef = useRef<ScheduleDraftStore>();

  if (!storeRef.current) {
    storeRef.current = createDraftScheduleStore(mockInitialValues);
  }

  return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>;
}

function createDraftScheduleStore(mockInitialValues?: MockInitialValues) {
  const {
    draftTaskChanges: mockDraftTaskChanges,
    initialTaskValues: mockInitialTaskValues,
    requiredScheduleFlags: mockRequiredScheduleFlags,
    userAddedScheduleFlags: mockUserAddedScheduleFlags,
  } = mockInitialValues ?? {};

  return createStore<DraftScheduleStoreState>((set, get) => ({
    /**
     * This queue of "draft" task changes will be persist for the duration of the "draft" session. Each change will push a new value into the queue
     * and the server will evaluate each change in the order provided
     */
    draftTaskChanges: mockDraftTaskChanges ?? [],
    // It's important that new changes are always added to the end of the list as the inputs are processed in order.
    addDraftTaskChanges: (input) => {
      set({
        draftTaskChanges: [...get().draftTaskChanges, ...input],
        lastUpdatedTaskId: input.first?.id ?? input.first?.clientId,
      });

      // Automatically clear the lastUpdatedTaskId after a short delay
      setTimeout(() => {
        set({ lastUpdatedTaskId: null });
      }, LAST_UPDATED_NOTIFICATION_TIMEOUT);
    },

    /** This is a map of the initial values of the tasks, keyed by the task id.
     * The "maybeSet" action will check for existing values first so it is safe to call multiple times.
     */
    initialTaskValues: mockInitialTaskValues ?? undefined,
    maybeSetInitialValues: (queryResult) => {
      if (!get().initialTaskValues) {
        set({
          initialTaskValues: queryResult.draftPlanSchedule.planTasks.keyBy((t) => t.id),
        });
      }
    },
    getInitialTaskValue: (taskId) => get().initialTaskValues?.[taskId],

    // Clear the store state which will force-fetch the latest from the server
    reset: () => set({ draftTaskChanges: [], initialTaskValues: undefined }),
    // Clears out required schedule flags
    resetRequiredTaskScheduleFlag: (requiredFlags) => {
      // Create a set of required flag IDs for easy lookup
      const requiredFlagIds = new Set(requiredFlags.map((flag) => flag.clientId));
      return set({
        draftTaskChanges: [
          ...get().draftTaskChanges.map(({ scheduleFlags, ...others }) => ({
            ...others,
            // Update the draftTaskChanges by filtering out required flags
            scheduleFlags: scheduleFlags?.filter((flag) => !requiredFlagIds.has(flag.clientId)),
          })),
        ],
      });
    },
    requiredScheduleFlags: mockRequiredScheduleFlags ?? [],
    setRequiredScheduleFlags: (input) => set({ requiredScheduleFlags: input }),
    userAddedScheduleFlags: mockUserAddedScheduleFlags ?? [],
    setUserAddedScheduleFlags: (input) => set({ userAddedScheduleFlags: [...get().userAddedScheduleFlags, ...input] }),
    removeUserAddedScheduleFlags: (clientId) =>
      set({
        // Update the user added flags by filtering out deleted flags
        userAddedScheduleFlags: [...get().userAddedScheduleFlags.filter((flag) => flag.clientId !== clientId)],
      }),
    validationErrors: {},
    setValidationErrors: (input) => {
      const osKey = Object.keys(input)[0];
      // Pull out the name of the form state field that errored from the error message
      // ie: "knownDurationInDays: Task duration must be greater than 0 => Task duration must be greater than 0"
      const newErrors = input[osKey].map((error) => error.split(":")[1]);
      const existingErrors = get().validationErrors;
      set({
        validationErrors: {
          ...existingErrors,
          [osKey]: newErrors,
        },
      });
    },
    lastUpdatedTaskId: null,
  }));
}

export function useDraftScheduleStore<T>(selector: (state: DraftScheduleStoreState) => T) {
  const store = useContext(StoreContext);

  if (!store) {
    throw new Error("Missing DraftScheduleStoreProvider");
  }

  return useStore(store, selector);
}
