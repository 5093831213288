import { ChipSelectField, Css, ToggleChip } from "@homebound/beam";
import { useCallback } from "react";
import {
  GlobalOptionsDetailFragment,
  GlobalOptionTagDetailFragment,
  useSaveGlobalOptionsMutation,
} from "src/generated/graphql-types";

type GlobalOptionsTagColumnProps = {
  option: Pick<GlobalOptionsDetailFragment, "id" | "tags" | "supportedTags">;
};

export function GlobalOptionsTagColumn({ option }: GlobalOptionsTagColumnProps) {
  const { id: optionId, tags: currentTags, supportedTags } = option;
  const [saveGlobalOptions] = useSaveGlobalOptionsMutation();

  const nonAssignedTags: GlobalOptionTagDetailFragment[] =
    supportedTags.filter(({ id }) => !currentTags.some((t) => t.id === id)) ?? [];

  const onRemove = useCallback(
    async (t: GlobalOptionTagDetailFragment) => {
      await saveGlobalOptions({
        variables: {
          globalOptions: [{ id: optionId, tagIds: currentTags.filter(({ id }) => id !== t.id).map(({ id }) => id) }],
        },
      });
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveGlobalOptions, currentTags],
  );

  return (
    <div css={Css.df.gap1.add("flexWrap", "wrap").$}>
      {currentTags.map((tag) => (
        <ToggleChip key={tag.id} text={tag.name ?? ""} onClick={() => onRemove(tag)} />
      ))}
      <ChipSelectField
        label="Add Tag"
        value={undefined as any}
        placeholder="+Tag"
        options={nonAssignedTags}
        disabled={nonAssignedTags.length === 0 ? "There are no additional tags to select" : undefined}
        onSelect={async (tagId) => {
          await saveGlobalOptions({
            variables: {
              globalOptions: [{ id: optionId, tagIds: [...currentTags.map(({ id }) => id), tagId] }],
            },
          });
        }}
      />
    </div>
  );
}
