import {
  BoundDateField,
  BoundSelectField,
  BoundTextAreaField,
  Button,
  Css,
  FormHeading,
  FormLines,
  ScrollableContent,
  SelectField,
  TextField,
  useComputed,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, Rule, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useParams } from "react-router";
import { getStage } from "src/context";
import {
  Maybe,
  OverallTabQuery,
  OverallTab_ProjectFragment,
  ProjectInput,
  ProjectStatus,
  ProjectStatusChangeReason,
  useOverallTabQuery,
  useProjectStatusChangeReasonsQuery,
  useSaveProjectOverallTabMutation,
} from "src/generated/graphql-types";
import { noop, projectStatusDetails, queryResult, safeEntries } from "src/utils";
import { DateOnly, formatMonthDayYear } from "src/utils/dates";

export function OverallTab() {
  const { projectId } = useParams<{ projectId: string }>();
  const query = useOverallTabQuery({ variables: { projectId } });

  return queryResult(query, {
    data: (data) => <OverallTabDataView data={data} />,
  });
}

type OverallTabDataViewProps = {
  data: OverallTabQuery;
};

export function OverallTabDataView({ data }: OverallTabDataViewProps) {
  const { project, stages, cohorts } = data;
  const { market, cohort } = project;
  const developmentId = cohort?.development?.id;
  // grab all cohorts on a development
  const cohortsForDevelopment = cohorts?.filter((cohort) => developmentId === cohort?.development?.id);

  const { salesforcePropertyId } = project;
  const sfpId = salesforcePropertyId?.replace("urn:PROPERTY:", "");

  const undefinedOption = { id: undefined, name: "N/A" };

  const latestActiveStage = project.latestActiveStage?.stage.code;
  const statusChangeQuery = useProjectStatusChangeReasonsQuery({ variables: { projectStage: latestActiveStage } });

  const [saveProjectOverallTab] = useSaveProjectOverallTabMutation();

  const formState = useFormState({
    config: formConfig,
    init: { input: project, map: mapToFormState },
    addRules(state) {
      state.reasonId.rules.push(requiredRule(state, [ProjectStatus.Closed, ProjectStatus.Hold]));
      state.date.rules.push(requiredRule(state, [ProjectStatus.Closed, ProjectStatus.Hold]));
      state.warrantyStartDate.rules.push(requiredRule(state, [ProjectStatus.UnderWarranty]));
    },
    autoSave: async () => {
      const {
        value: { id, status, reasonId, internalNote, cohortId, date, warrantyStartDate },
      } = formState;

      if (formState.canSave() && id) {
        await saveProjectOverallTab({
          variables: {
            input: {
              id,
              status,
              projectStatusChange: {
                reasonId,
                internalNote,
                date: date ? new DateOnly(date) : null,
              },
              cohortId,
              warrantyStartDate: warrantyStartDate ? new DateOnly(warrantyStartDate) : null,
            },
          },
        });
      }
    },
  });

  const filteredChangeReasons = useComputed(
    () =>
      statusChangeQuery.data?.projectStatusChangeReasons
        ?.filter((pscr) => pscr.projectStatus === formState.status.value)
        .sort(sortProjectStatusChangeReasons),
    [statusChangeQuery],
  );

  const environment = getStage();
  const sfUrl =
    environment === "prod"
      ? "https://homebound.lightning.force.com"
      : "https://homebound--partialsb.sandbox.lightning.force.com";

  return (
    <ScrollableContent>
      <div css={Css.df.gap6.$}>
        <Observer>
          {() => (
            <div>
              <FormLines width="sm">
                <FormHeading title="Basic Information" />
                <BoundSelectField
                  label="Project status"
                  field={formState.status}
                  options={safeEntries(projectStatusDetails)}
                  getOptionLabel={([_, name]) => name}
                  getOptionValue={([code]) => code}
                  onSelect={(value) => {
                    formState.status.set(value);
                    formState.set({ reasonId: null, internalNote: "", date: null, warrantyStartDate: null });
                  }}
                />
                <BoundSelectField
                  label="Hold or disqualified reason"
                  field={formState.reasonId}
                  options={filteredChangeReasons || []}
                  disabled={
                    formState.status.value &&
                    [ProjectStatus.Active, ProjectStatus.Completed, ProjectStatus.UnderWarranty].includes(
                      formState.status.value,
                    )
                  }
                  errorMsg={formState.reasonId.errors.join(" ")}
                />
                <BoundDateField
                  label="Hold or disqualified date"
                  field={formState.date}
                  disabled={
                    formState.status.value &&
                    [ProjectStatus.Active, ProjectStatus.Completed, ProjectStatus.UnderWarranty].includes(
                      formState.status.value,
                    )
                  }
                  errorMsg={formState.date.errors.join(" ")}
                />
                <BoundDateField
                  label="Warranty start date"
                  field={formState.warrantyStartDate}
                  disabled={formState.status.value !== ProjectStatus.UnderWarranty}
                  errorMsg={formState.warrantyStartDate.errors.join(" ")}
                />
                <BoundTextAreaField
                  label="Project internal notes"
                  field={formState.internalNote}
                  helperText={
                    project?.projectStatusChange?.updatedAt &&
                    `Last update: ${formatMonthDayYear(new DateOnly(project?.projectStatusChange?.updatedAt))}`
                  }
                />
                <SelectField
                  label="Latest stage"
                  options={stages}
                  value={latestActiveStage}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ code }) => code}
                  // readOnly for now, this may change later
                  readOnly={true}
                  onSelect={noop}
                  helperText="Latest stage will automatically update when a signed contract is added to the stage"
                />

                <FormHeading title="Locations & Grouping" />
                <TextField label="Market" value={market.name} readOnly onChange={noop} />
                <TextField
                  label="Development"
                  value={cohort?.development?.name || undefinedOption.name}
                  onChange={noop}
                  readOnly
                />
                <BoundSelectField
                  label="Cohort"
                  field={formState.cohortId}
                  options={[undefinedOption, ...cohortsForDevelopment]}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id!}
                />
              </FormLines>
            </div>
          )}
        </Observer>
        {sfpId && (
          <FormLines>
            <h2 css={Css.baseMd.mb2.$}>Related Pages</h2>
            <Button
              data-testid="sfLink"
              variant="tertiary"
              label={"Salesforce Property"}
              onClick={`${sfUrl}/lightning/r/Property__c/${sfpId}/view`}
            />
          </FormLines>
        )}
      </div>
    </ScrollableContent>
  );
}

type FormValue = Pick<ProjectInput, "id" | "status" | "salesforceAccountId" | "cohortId"> & {
  reasonId?: Maybe<string>;
  internalNote?: Maybe<string>;
  date?: Maybe<Date>;
  warrantyStartDate?: Maybe<Date>;
};

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  status: { type: "value" },
  reasonId: { type: "value" },
  internalNote: { type: "value" },
  date: { type: "value" },
  cohortId: { type: "value" },
  warrantyStartDate: { type: "value" },
};

function mapToFormState(project: OverallTab_ProjectFragment): FormValue {
  if (!project) return {} as FormValue;

  const { id, status, projectStatusChange, cohort } = project;

  return {
    id,
    status: status.code,
    reasonId: projectStatusChange?.reason?.id,
    internalNote: projectStatusChange?.internalNote,
    date: projectStatusChange?.date,
    cohortId: cohort?.id,
    warrantyStartDate: project.warrantyStartDate,
  };
}

function requiredRule({ status }: ObjectState<FormValue>, statuses: ProjectStatus[]): Rule<Maybe<string | Date>> {
  return ({ value }) => {
    if (!value && status.value && statuses.includes(status.value)) {
      return `This field is required when setting the project status to ${projectStatusDetails[status.value]}`;
    }
    return undefined;
  };
}

type ProjectStatusChangeReasonGql = Pick<ProjectStatusChangeReason, "id" | "name" | "projectStage" | "projectStatus">;
function sortProjectStatusChangeReasons(
  { name: name1 }: ProjectStatusChangeReasonGql,
  { name: name2 }: ProjectStatusChangeReasonGql,
): number {
  // Force "Other" to the bottom of the list
  if (name1 === "Other") {
    return 1;
  } else if (name2 === "Other") {
    return -1;
  }

  // Otherwise, sort alphabetically
  return name1.localeCompare(name2);
}
