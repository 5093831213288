import { Step } from "@homebound/beam";
import kebabCase from "lodash/kebabCase";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { StepperProvider, useStepperContext } from "src/components/stepper";
import { WizardStep } from "src/components/stepper/useStepperWizard/useStepperWizard";
import { DevelopmentParams, PlanDetailsParams } from "src/routes/routesDef";
import { PlanDetailsStep } from "./1.PlanDetailsStep";
import { ChooseOptionsStep } from "./2.ChooseOptionsStep";
import { ConfigureOptionsStep } from "./3.ConfigureOptionsStep";
import { OptionDetailsStep } from "./4.OptionDetailsStep";

export function EditOptionsPage() {
  const params = useParams<DevelopmentParams & PlanDetailsParams>();
  const [steps] = useState(() => getSteps(params));
  const initialSteps = useMemo<Step[]>(() => {
    return steps.map((step) => ({ ...step, state: "incomplete", value: kebabCase(step.label) }));
  }, [steps]);

  return (
    <StepperProvider steps={initialSteps} newLayout>
      <StepPicker steps={steps} />
    </StepperProvider>
  );
}

function getSteps({ readyPlanId, developmentId }: DevelopmentParams & PlanDetailsParams): WizardStep[] {
  return [
    { label: "Plan Details", render: () => <PlanDetailsStep id={readyPlanId} developmentId={developmentId} /> },
    { label: "Choose Options", render: () => <ChooseOptionsStep id={readyPlanId} developmentId={developmentId} /> },
    {
      label: "Configure Options",
      render: () => <ConfigureOptionsStep id={readyPlanId} developmentId={developmentId} />,
    },
    { label: "Option Details", render: () => <OptionDetailsStep id={readyPlanId} developmentId={developmentId} /> },
  ];
}

function StepPicker({ steps }: { steps: WizardStep[] }) {
  const { currentStep } = useStepperContext();
  const { render: Component } =
    steps.find((s) => s.label === currentStep.label) ?? fail(`No Wizard step found for ${currentStep.label}`);
  return Component();
}
