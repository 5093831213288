import { Css } from "@homebound/beam";
import { Link } from "react-router-dom";
import { ProductAttributeType, OverviewTab_ProductFragment } from "src/generated/graphql-types";
import { createBidItemDetailsUrl } from "src/RouteUrls";
import { formatList } from "src/utils";

export type ProductOverviewSectionProps = {
  product: OverviewTab_ProductFragment;
};

export function ProductOverviewSection(props: ProductOverviewSectionProps) {
  const { product } = props;

  const finishAttributes = product.attributes
    .filter((a) => a.type === ProductAttributeType.Finish)
    .map(({ value }) => value);

  return (
    <div css={Css.sm.$}>
      <div css={Css.mb2.$} data-testid="productDescription">
        {product.description}
      </div>
      <div css={Css.mt2.$}>
        <div>
          <label css={Css.fw7.mr1.$}>Model #</label>
          <span data-testid="productModel">{product.sku ?? "Not available"}</span>
        </div>
        <div>
          <label css={Css.fw7.mr1.$}>Finish #</label>
          <span data-testid="productFinish">
            {finishAttributes.nonEmpty ? formatList(finishAttributes) : "Not available"}
          </span>
        </div>
        <div>
          <label css={Css.fw7.mr1.$}>Bid Items #</label>
          {product.bidItems.nonEmpty ? (
            <span data-testid="bidItems">
              {product.bidItems.map((bidItem) => (
                <span key={bidItem.id}>
                  <Link data-testid="bidItem" to={createBidItemDetailsUrl(bidItem.id)} target="_blank">
                    {bidItem.displayName}
                  </Link>
                  {bidItem.id !== product.bidItems.last?.id && ", "}
                </span>
              ))}
            </span>
          ) : (
            <span data-testid="bidItem">Not available</span>
          )}
        </div>
      </div>
    </div>
  );
}
