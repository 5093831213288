import { StringParam, useQueryParams } from "use-query-params";
import {
  ComputeProductOfferingConfigCostsQuery,
  ConfigsComparePage_ConfigCostsFragment,
  useComputeProductOfferingConfigCostsQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { useCallback, useState } from "react";
import { Button, Css, useTestIds } from "@homebound/beam";
import { useParams } from "react-router-dom";
import { DevelopmentParams, ProductOfferingConfigParams } from "src/routes/routesDef";
import { addEntityParam, createProductOfferingConfigUrl, createProductOfferingConfigsUrl } from "src/RouteUrls";
import { CompareProductConfigsGridLayout } from "./components/compare-product-configs/CompareProductConfigsGridLayout";

export function ProductOfferingConfigsComparePage() {
  const [{ configIds }] = useQueryParams({
    configIds: StringParam,
  });
  const query = useComputeProductOfferingConfigCostsQuery({
    variables: { input: { productOfferingConfigIds: configIds && JSON.parse(configIds) } },
    skip: !configIds,
  });

  return queryResult(query, ({ computeProductOfferingConfigCosts }) => (
    <ProductOfferingConfigsCompare computedConfigCosts={computeProductOfferingConfigCosts} />
  ));
}

function ProductOfferingConfigsCompare({
  computedConfigCosts,
}: {
  computedConfigCosts: ComputeProductOfferingConfigCostsQuery["computeProductOfferingConfigCosts"];
}) {
  const { productOfferingId, developmentId } = useParams<ProductOfferingConfigParams & DevelopmentParams>();
  const [{ configIds }, setQueryParam] = useQueryParams({
    configIds: StringParam,
  });
  const { configCosts } = computedConfigCosts;
  const [configs, setConfigs] = useState<ConfigsComparePage_ConfigCostsFragment[]>(
    configIds ? configCosts.filter((c) => JSON.parse(configIds).includes(c.productOfferingConfig.id)) : [],
  );
  const tid = useTestIds({});

  const removeConfig = useCallback(
    (removeConfigId: string) => {
      setConfigs((prev) => prev.filter((config) => config?.productOfferingConfig.id !== removeConfigId));
      setQueryParam({
        configIds: JSON.stringify(
          configs
            .filter((config) => config?.productOfferingConfig.id !== removeConfigId)
            .map((c) => c.productOfferingConfig.id),
        ),
      });
    },
    [configs, setQueryParam],
  );

  return (
    <div css={Css.vh100.$}>
      <div css={Css.df.fdr.jcsb.aifs.mt4.$}>
        <div css={Css.df.fdr.gap1.aic.blue700.cursorPointer.$}>
          <Button
            label="Back to Configurations"
            variant="text"
            icon="arrowBack"
            onClick={createProductOfferingConfigsUrl(developmentId, productOfferingId)}
          />
        </div>
        <div css={Css.df.fdc.aic.mbPx(120).$}>
          <div css={Css.xl3Sb.$} {...tid.title}>
            Compare Configurations
          </div>
          <div css={Css.base.pt2.$}>Compare the configurations created on the offering, or create new ones!</div>
        </div>

        {productOfferingId && configs.first?.productOfferingConfig.productOffering && (
          <Button
            onClick={createProductOfferingConfigUrl({
              idOrAdd: addEntityParam,
              // Update url params with metaIds to load the page with selectable options & delta costs
              productOfferingId,
              itemTemplateId: configs.first?.productOfferingConfig.productOffering.latestTemplate?.id,
              developmentId: developmentId,
            })}
            variant="secondary"
            label="Create New"
          />
        )}
      </div>
      <CompareProductConfigsGridLayout configs={configs} removeConfig={removeConfig} />
    </div>
  );
}
