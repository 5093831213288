import { ObjectState } from "@homebound/form-state";
import { BidPackageFormState } from "../AddBidPackagePage";
import { Accordion, Button, Css } from "@homebound/beam";
import { queryResult } from "src/utils";
import {
  ReviewScopeStep_ProductOfferingInfoFragment,
  useReviewScopeStep_BidPackageGroupQuery,
} from "src/generated/graphql-types";
import { useParams } from "react-router-dom";
import { BidPackageParams } from "src/routes/routesDef";
import { Card } from "src/components/Card";
import { useFullscreenModal } from "src/components/useFullscreenModal";
import { BidPackageLineItemModal } from "./BidPackageLineItemModal";

export function ReviewScopeStep({ formState }: { formState: ObjectState<BidPackageFormState> }) {
  const { bidPackageGroupId } = useParams<BidPackageParams>();
  const query = useReviewScopeStep_BidPackageGroupQuery({
    variables: { id: formState.bidPackageGroupId.value || bidPackageGroupId },
    skip: !formState.bidPackageGroupId.value && !bidPackageGroupId,
  });
  return queryResult(query, ({ bidPackageGroupProductOfferingsInfo }) => (
    <ReviewScopeStepView productOfferingInfos={bidPackageGroupProductOfferingsInfo.productOfferingInfos} />
  ));
}

export function ReviewScopeStepView({
  productOfferingInfos,
}: {
  productOfferingInfos: ReviewScopeStep_ProductOfferingInfoFragment[];
}) {
  const groupedOfferingsByPackage = productOfferingInfos.groupBy((info) => info.bidPackage.id);
  const { openFullscreen } = useFullscreenModal();
  return (
    <>
      <div css={Css.df.fdc.aic.mt5.mb4.$}>
        <div css={Css.xl3Sb.$}>Confirm Scope for the Packages</div>
        <div css={Css.base.pt2.$}>
          Based on your previous selections all line items missing contracted costs are pre-filled the packages.
        </div>
        <div css={Css.base.$}>These are automatically attached to the bids as a CSV file</div>
      </div>
      {Object.entries(groupedOfferingsByPackage).map(([bidPackageId, offerings]) => {
        return (
          <Card xss={Css.wfc.mxa.$} key={bidPackageId}>
            <div css={Css.mw("730px").$}>
              <Accordion
                topBorder={false}
                defaultExpanded
                title={
                  <div css={Css.lgBd.$}>
                    {/** We've already grouped by bidPackage, safe to assume we can get the first offering category name */}
                    {offerings.first?.bidPackage.name} Package
                    <Button
                      size="sm"
                      label="View Line Items"
                      variant="tertiary"
                      onClick={() => openFullscreen(<BidPackageLineItemModal bidPackageId={bidPackageId} />)}
                    ></Button>
                  </div>
                }
              >
                <OfferingsInfoSection offeringsInfo={offerings} />
              </Accordion>
            </div>
          </Card>
        );
      })}
    </>
  );
}

function OfferingsInfoSection({ offeringsInfo }: { offeringsInfo: ReviewScopeStep_ProductOfferingInfoFragment[] }) {
  return offeringsInfo.map(({ productOffering, numberOfLineItems, relevantOptions, bidPackage }) => {
    const groupedOptionTypes = relevantOptions.groupBy((opt) => opt.type.name);
    return (
      <div key={productOffering.id} css={Css.my1.bgGray100.br8.mb2.$}>
        <div css={Css.br12.hPx(144).pl2.dg.gtc("2fr 1fr").aic.$}>
          {/* Currently no image is available update when images are */}
          <div css={Css.m2.df.fdc.gap1.$}>
            <div css={Css.baseBd.$}>{productOffering.name}</div>
            <div css={Css.smSb.$}>
              Total {bidPackage.name} Line Items: {numberOfLineItems}
            </div>
          </div>

          <div css={Css.df.fdc.gap1.$}>
            {Object.entries(groupedOptionTypes).map(([typeName, opts]) => (
              <div key={typeName} css={Css.df.fdr.gap1.$}>
                <div css={Css.xsSb.w2.h2.borderRadius("50%").bgWhite.df.fdc.aic.$}>{opts.length}</div>
                <div css={Css.xs.$}>{typeName}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  });
}
