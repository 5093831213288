import {
  Button,
  column,
  dateColumn,
  emptyCell,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  multiFilter,
  numericColumn,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  singleFilter,
  useModal,
  usePersistedFilter,
} from "@homebound/beam";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { dateCell, markupCell, Percentage, priceCell, SearchBox, tagCell, yesNoCell } from "src/components";
import {
  ChangeEventFilter,
  ChangeEventsForProjectStage_ProjectItemFragment,
  ChangeEventsPageChangeEventFragment,
  ChangeEventStatus,
  ChangeEventType,
  CostConfidence,
  Maybe,
  Stage,
  useChangeEventsForProjectStageQuery,
} from "src/generated/graphql-types";
import { useStripStage } from "src/hooks/useStripStage";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { CreateChangeEventModal } from "src/routes/projects/change-events/components/CreateChangeEventModal";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { ProjectParams } from "src/routes/routesDef";
import { createChangeEventUrl } from "src/RouteUrls";
import {
  calculateMarkup,
  changeEventStatusToNameMapper,
  changeEventStatusToTagTypeMapper,
  changeEventTypeToNameMapper,
  stageCodeToNameMapper,
} from "src/utils";
import { hasData, renderLoadingOrError } from "src/utils/queryResult";
import { getStage } from "src/context";

export function ChangeEventsPage() {
  useStripStage();
  const { projectId } = useParams<ProjectParams>();
  const { clientNoun, latestActiveStage, shortClientNoun } = useProjectContext();

  const filterDefs: FilterDefs<ChangeEventsPageFilter> = useMemo(() => {
    const stages = [Stage.PreConExecution, Stage.Construction];
    const statuses = Object.values(ChangeEventStatus);

    const stage = singleFilter({
      options: [...stages.map((s) => ({ id: s, name: stageCodeToNameMapper[s] }))],
      defaultValue: stages.includes(latestActiveStage) ? latestActiveStage : undefined,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const status = multiFilter({
      options: [
        ...statuses
          .filter((status) => getStage() !== "prod" || status !== ChangeEventStatus.PendingSignature)
          .map((s) => ({ id: s, name: changeEventStatusToNameMapper[s] })),
      ],
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const type = multiFilter({
      options: Object.entries(ChangeEventType),
      getOptionValue: ([_, type]) => type,
      getOptionLabel: ([_, type]) => `${clientNoun} ${changeEventTypeToNameMapper(type)}`,
    });

    return { stage, status, type };
  }, [clientNoun, latestActiveStage]);

  const { setFilter, filter } = usePersistedFilter<ChangeEventsPageFilter>({
    storageKey: "changeEventsFilter",
    filterDefs,
  });

  const [searchFilter, setSearchFilter] = useState<string | undefined>();

  const { openModal } = useModal();

  return (
    <>
      <PageHeader
        title="Change Events"
        right={
          <Button
            label="Create Change Event"
            variant="secondary"
            onClick={() =>
              openModal({
                content: <CreateChangeEventModal projectId={projectId} />,
              })
            }
          />
        }
      />
      <TableActions>
        <Filters<ChangeEventsPageFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
        <SearchBox onSearch={setSearchFilter} />
      </TableActions>
      <ChangeEventsTable
        clientNoun={clientNoun}
        projectId={projectId}
        filter={filter}
        searchFilter={searchFilter}
        shortClientNoun={shortClientNoun}
      />
    </>
  );
}

type ChangeEventsTableProps = {
  clientNoun: string;
  projectId: string;
  filter: ChangeEventsPageFilter;
  searchFilter: string | undefined;
  shortClientNoun: string;
};

function ChangeEventsTable({ clientNoun, projectId, filter, searchFilter, shortClientNoun }: ChangeEventsTableProps) {
  const query = useChangeEventsForProjectStageQuery({
    variables: {
      projectId,
      stagesFilter: filter.stage && { stage: [filter.stage] },
      changeEventFilter: mapToFilter(filter),
    },
  });

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const { project } = query.data;
  const changeEvents = project.stages.flatMap((s) => s.changeEvents);
  const projectItems = project.stages.flatMap((s) => s.projectItems);

  return (
    <ScrollableContent>
      <GridTable
        columns={createColumns(clientNoun, shortClientNoun)}
        rows={createRows(changeEvents, projectItems)}
        rowStyles={createRowStyles(projectId)}
        fallbackMessage="There are no change events for the selected stage."
        sorting={{ on: "client" }}
        filter={searchFilter}
        stickyHeader
        style={{ rowHeight: "fixed", bordered: true, allWhite: true }}
      />
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: ChangeEventsPageChangeEventFragment };
type TotalRow = {
  kind: "totals";
  data: { markupPercentage: number; costInCents: number; priceInCents: number };
};

type Row = HeaderRow | DataRow | TotalRow;

const createColumns = (clientNoun: string, shortClientNoun: string): GridColumn<Row>[] =>
  [
    column<Row>({
      header: "Name",
      data: ({ title, identifier }) => ({
        content: `CE #${identifier} - ${title}`,
        value: `CE #${identifier} - ${title}`,
        sortValue: identifier,
      }),
      w: 1,
      mw: "250px",
      totals: emptyCell,
      sticky: "left",
    }),
    column<Row>({
      header: "Stage",
      data: ({ projectStage }) => projectStage.stage.name,
      totals: emptyCell,
      w: "120px",
    }),
    dateColumn<Row>({
      header: "Created",
      data: (data) => dateCell(data.createdAt),
      totals: emptyCell,
      w: "108px",
    }),
    dateColumn<Row>({
      header: "Updated",
      data: (data) => dateCell(data.updatedAt),
      totals: emptyCell,
      w: "108px",
    }),
    column<Row>({
      header: `${clientNoun} CO?`,
      data: (data) => yesNoCell(data.type.code === ChangeEventType.External),
      totals: emptyCell,
      w: "146px",
    }),
    column<Row>({
      header: "Impacted Budget",
      data: (data) => data.budgetPhase.name,
      totals: emptyCell,
      w: "146px",
    }),
    column<Row>({
      header: "LI Hard Bids",
      data: ({ lineItems }) => {
        const hardBidCount = lineItems.filter((li) => li.costConfidence === CostConfidence.HardBid).length;
        return `${hardBidCount}/${lineItems.length}`;
      },
      totals: emptyCell,
      w: "108px",
    }),
    numericColumn<Row>({
      header: "Cost",
      data: (data) => priceCell({ valueInCents: data.totalCostInCents }),
      totals: (totals) => priceCell({ valueInCents: totals.costInCents }),
      w: "100px",
    }),
    numericColumn<Row>({
      header: "Markup",
      data: (data) =>
        data.type.code !== ChangeEventType.Internal ? markupCell(data.totalCostInCents, data.totalPriceInCents) : "N/A",
      totals: (totals) => ({
        content: <Percentage percent={totals.markupPercentage} />,
        tooltip: "Calculate the original vs current markup",
      }),
      w: "164px",
    }),
    numericColumn<Row>({
      header: `Total ${shortClientNoun} Price`,
      data: (data) => priceCell({ valueInCents: data.totalPriceInCents }),
      totals: (totals) => priceCell({ valueInCents: totals.priceInCents }),
      w: "136px",
    }),
    column<Row>({
      header: "Status",
      data: ({ status }) => tagCell(changeEventStatusToTagTypeMapper[status], changeEventStatusToNameMapper[status]),
      totals: emptyCell,
      w: "100px",
      sticky: "right",
    }),
  ].compact();

function createRows(
  changeEvents: ChangeEventsPageChangeEventFragment[],
  projectItems: ChangeEventsForProjectStage_ProjectItemFragment[],
): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...changeEvents.map((ce) => ({ kind: "data" as const, id: ce.id, data: ce })),
    {
      kind: "totals",
      id: "totals",
      data: {
        costInCents: changeEvents.sum((ce) => ce.totalCostInCents),
        markupPercentage: calculateMarkupPercentage(changeEvents, projectItems),
        priceInCents: changeEvents.sum((ce) => ce.totalPriceInCents),
      },
    },
  ];
}

function createRowStyles(projectId: string): RowStyles<Row> {
  return {
    header: {},
    data: {
      rowLink: (ce) => createChangeEventUrl(projectId, ce.data.id),
    },
  };
}

type ChangeEventsPageFilter = Omit<ChangeEventFilter, "stage"> & {
  stage?: Maybe<Stage>;
};

/** Calculate markup looking up the original cost/price of the project */
function calculateMarkupPercentage(
  changeEvents: ChangeEventsPageChangeEventFragment[],
  projectItems: ChangeEventsForProjectStage_ProjectItemFragment[],
) {
  const nonInternalAccepted = changeEvents.filter(
    (ce) => ce.type.code !== ChangeEventType.Internal && ce.status === ChangeEventStatus.Accepted,
  );
  // Sum up the total cost/price of all accepted change events
  const changedBudgetInCents = nonInternalAccepted.sum((ce) => ce.totalCostInCents);
  const changedPriceInCents = nonInternalAccepted.sum((ce) => ce.totalPriceInCents);

  // Sum up the original cost/price of all project items
  const originalBudgetInCents = projectItems.sum((pi) => pi.originalBudgetInCents);
  const originalPriceInCents = projectItems.sum((pi) => pi.originalPriceInCents);

  // Calculate the current cost/price of the project (original + changed)
  const currentBudgetInCents = originalBudgetInCents + changedBudgetInCents;
  const currentPriceInCents = originalPriceInCents + changedPriceInCents;

  // Calculate the markup percentage for the original and current project
  const { markupPercentage: original } = calculateMarkup(originalBudgetInCents, originalPriceInCents);
  const { markupPercentage: current } = calculateMarkup(currentBudgetInCents, currentPriceInCents);

  // Return the difference between the current and original markup
  return current - original;
}

function mapToFilter(filter: ChangeEventsPageFilter): ChangeEventFilter {
  const { stage, status, type, ...rest } = filter;
  // Filter out void status by default
  const statusFilter = filter.status ?? Object.values(ChangeEventStatus).filter((s) => s !== ChangeEventStatus.Void);
  return {
    ...rest,
    ...{ status: statusFilter },
    ...(filter.type && { type: filter.type }),
  };
}
