import {
  actionColumn,
  Button,
  CollapseToggle,
  column,
  emptyCell,
  Filters,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridTable,
  numericColumn,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  singleFilter,
  usePersistedFilter,
  useSnackbar,
  useSuperDrawer,
} from "@homebound/beam";
import { MutableRefObject, useMemo, useRef, useState } from "react";
import { SearchBox } from "src/components";
import {
  AdminCostCodeFragment,
  AdminCostDivisionFragment,
  CostCodesQuery,
  useCostCodesQuery,
  useDeleteCostCodeMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { CostCodesSuperDrawerTabs } from "./CostCodesSuperDrawerTabs";
import { CreateNewCostCodeModal } from "./CreateNewCostCodeModal";
import { groupBy } from "src/utils";

/** Lists all cost divisions/codes in the system. */
export function CostCodesPage() {
  const rowLookup = useRef<GridRowLookup<Row>>();
  const { openInDrawer } = useSuperDrawer();
  const filterDefs = useMemo(() => {
    return {
      version: singleFilter({
        options: [
          { id: 1, name: "1" },
          { id: 2, name: "2" },
        ],
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => o.name,
        defaultValue: 1,
      }),
    };
  }, []);

  const { setFilter, filter } = usePersistedFilter<LocalCostCodeFilter>({
    storageKey: "itemsFilter",
    filterDefs,
  });
  const [searchFilter, setSearchFilter] = useState<string | undefined>("");

  const query = useCostCodesQuery({
    variables: {
      // Map our version number -> backend number[]
      filter: { version: filter.version ? [filter.version] : [1, 2] },
    },
  });

  const rowStyles = useMemo(() => createRowStyles(openInDrawer, rowLookup), [openInDrawer, rowLookup]);
  const rows = useMemo(() => createRows(query.data), [query]);

  return (
    <>
      <PageHeader title="Cost Codes" right={<CreateNewCostCodeModal />} />
      <TableActions>
        <Filters<LocalCostCodeFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <SearchBox onSearch={setSearchFilter} />
      </TableActions>
      <ScrollableContent virtualized>
        <GridTable
          stickyHeader
          filter={searchFilter}
          sorting={{ on: "client" }}
          {...{ rows, rowStyles, columns, rowLookup }}
        />
      </ScrollableContent>
    </>
  );
}

type Row =
  | { kind: "header"; data: undefined }
  | { kind: "division"; id: string; data: AdminCostDivisionFragment }
  | { kind: "code"; id: string; data: AdminCostCodeFragment };

const columns: GridColumn<Row>[] = [
  column<Row>({
    header: (data, { row }) => <CollapseToggle row={row} />,
    division: (data, { row }) => <CollapseToggle row={row} />,
    code: emptyCell,
    clientSideSort: false,
    w: "60px",
  }),
  numericColumn<Row>({
    header: "Id",
    division: ({ id }) => id,
    code: ({ id }) => id,
    w: "60px",
  }),
  numericColumn<Row>({
    header: "Version",
    division: ({ version }) => version,
    code: ({ version }) => version,
    w: "80px",
  }),
  column<Row>({
    header: "Name",
    division: ({ name }) => name,
    code: ({ name }) => ({ content: name, indent: 1 }),
    w: "350px",
  }),
  numericColumn<Row>({
    header: "Number",
    division: ({ number }) => number,
    code: ({ number }) => ({ content: number, indent: 1 }),
    w: "120px",
  }),
  column<Row>({
    header: "Stage",
    division: ({ stage }) => stage.name,
    code: emptyCell,
  }),
  numericColumn<Row>({
    header: "Cost Classification",
    division: emptyCell,
    code: ({ costClassification }) => costClassification?.name,
  }),
  numericColumn<Row>({
    header: "Cost Sub Classification",
    division: emptyCell,
    code: ({ costSubClassification }) => costSubClassification?.name,
  }),
  numericColumn<Row>({
    header: "QB Category Id",
    division: emptyCell,
    code: ({ quickbooksCategoryId }) => quickbooksCategoryId,
  }),
  numericColumn<Row>({
    header: "QB Product/Service Id",
    division: emptyCell,
    code: ({ quickbooksItemId }) => quickbooksItemId,
  }),
  numericColumn<Row>({
    header: "Intacct AP Account Id",
    division: emptyCell,
    code: ({ intacctApAccountId }) => intacctApAccountId,
  }),
  actionColumn<Row>({
    header: emptyCell,
    division: emptyCell,
    code: ({ id }) => <DeleteCostCodeButton id={id} />,
  }),
];

function createRowStyles(
  openInDrawer: ReturnType<typeof useSuperDrawer>["openInDrawer"],
  lookup: MutableRefObject<GridRowLookup<Row> | undefined>,
): RowStyles<Row> {
  function openRow(row: GridDataRow<Row>): void {
    if (row.kind === "code") {
      const { prev, next } = lookup.current!.lookup(row)["code"];
      openInDrawer({
        onPrevClick: prev && (() => openRow(prev)),
        onNextClick: next && (() => openRow(next)),
        content: <CostCodesSuperDrawerTabs costCode={row.data} />,
      });
    }
  }
  return { code: { onClick: openRow } };
}

function createRows(data: CostCodesQuery | undefined): GridDataRow<Row>[] {
  const costDivisions = groupBy(data?.costCodes || [], (cc) => cc.division.id);

  return [
    simpleHeader,
    ...(Object.values(costDivisions).map((costCodes) => ({
      kind: "division" as const,
      id: costCodes[0].division.id,
      data: costCodes[0].division,
      children: costCodes.map((cc) => ({ kind: "code" as const, id: cc.id, data: cc })),
    })) || []),
  ];
}

function DeleteCostCodeButton({ id }: { id: string }) {
  const { triggerNotice } = useSnackbar();
  const [deleteCostCode] = useDeleteCostCodeMutation({
    update: (cache) => cache.evict({ id: cache.identify({ __typename: "CostCode", id }) }),
  });
  const onDelete = async () => {
    const { errors } = await deleteCostCode({ variables: { input: { id } } });
    if (!errors) {
      triggerNotice({ message: "Cost code deleted", icon: "success" });
    }
  };

  return <Button variant="secondary" label="Delete" onClick={onDelete} />;
}

type LocalCostCodeFilter = {
  version: number;
};
