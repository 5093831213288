import { Button, Chip, Css, HbLoadingSpinner, IconButton } from "@homebound/beam";
import { createDesignCatalogUrl, createDesignPackageFinishScheduleUrl } from "src/RouteUrls";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DesignPackageSlotsLeftNav } from "./components/DesignPackageSlotsLeftNav";
import { useDesignPackageConfiguratorContext } from "./DesignPackageConfiguratorContext";
import { DesignPackageItemSlotEditor } from "./components/DesignPackageItemSlotEditor";
import { useNewDesignCatalogWizard } from "../new-design-catalog-wizard/useNewDesignCatalogWizard";
import { ItemTemplateStatus, useSyncPlanPackageMutation } from "src/generated/graphql-types";
import { useState } from "react";
import { DesignPackageLocationBadge } from "../designCatalogAtoms";
import { DesignPackageFilters } from "./components/DesignPackageFilters";

export function DesignPackageConfigurator() {
  const { designPackage } = useDesignPackageConfiguratorContext();
  const openNewDesignCatalogWizard = useNewDesignCatalogWizard(designPackage.id);
  const [syncPlanPackage] = useSyncPlanPackageMutation();
  const [syncing, setSyncing] = useState(false);
  return (
    <div css={{ ...h100ExclHdr, ...Css.w100.$ }}>
      <PageHeader
        title={designPackage.name}
        breadcrumb={[
          { href: createDesignCatalogUrl(), label: "Design Packages" },
          { href: window.location.pathname, label: designPackage.code ?? "" },
        ]}
        left={
          <>
            <DesignPackageLocationBadge designPackage={designPackage} />
            <IconButton icon="cog" onClick={openNewDesignCatalogWizard} />
          </>
        }
        right={
          <div css={Css.df.gap3.$}>
            <div>
              {designPackage.copyInProgress && (
                <div css={Css.df.fdr.aic.baseBd.red400.pr4.gap2.$}>
                  <div css={Css.maxwPx(64).maxhPx(64).$}>
                    <HbLoadingSpinner iconOnly />
                  </div>
                  Sync in progress...
                </div>
              )}
              <Chip text={designPackage.status.name ?? "loading"} />
            </div>
            <Button
              variant="text"
              label="Finish Schedule"
              onClick={createDesignPackageFinishScheduleUrl(designPackage.id)}
              icon="linkExternal"
            />
            {/* TODO: This is a temporary solution to re-sync plans. Remove/rework once versioning is implemented.*/}
            <Button
              disabled={syncing}
              label={"Sync Plan(s)"}
              onClick={async () => {
                setSyncing(true);
                const targetTemplate = designPackage.itemTemplates.find((it) => it.status === ItemTemplateStatus.Draft);
                if (!targetTemplate) return;
                await designPackage.designPackageSources.asyncForEach((dps) => {
                  const sourceTemplate = dps.planPackage.itemTemplates.find((it) => it.isLatest)!;
                  return syncPlanPackage({
                    variables: {
                      payload: {
                        sourceTemplateId: sourceTemplate.id,
                        targetTemplateId: targetTemplate.id,
                        designPackageSourceId: dps.id,
                      },
                    },
                  });
                });
                // Artificial delay to prevent sync spamming
                setTimeout(() => setSyncing(false), 5000);
              }}
            />
          </div>
        }
      />
      <div css={Css.df.fdr.h100.w100.$}>
        <div css={Css.wPx(340).mtPx(-24).mlPx(-24).$}>
          <DesignPackageSlotsLeftNav />
        </div>
        <div css={Css.h100.w100.px2.oya.$}>
          <DesignPackageFilters />
          <DesignPackageItemSlotEditor />
        </div>
      </div>
    </div>
  );
}

export const h100ExclHdr = Css.h(
  `calc(100% - ${
    48 + // navbar
    89 + // PageHeader
    -24 // PageHeader bottom margin
  }px)`,
).$;
