import {
  BoundMultiSelectField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ToggleChips,
  useModal,
} from "@homebound/beam";
import { ObjectConfig } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { DocumentEditorDetailFragment, Maybe, Scalars, ScheduleTaskInfoFragment } from "src/generated/graphql-types";
import { nonEmpty } from "src/utils";
import { useFormState } from "src/utils/formState";

export type DocumentScheduleTasksModalProps = {
  document: DocumentEditorDetailFragment;
  scheduleTasks: ScheduleTaskInfoFragment[];
  onSave: (taskIds: string[]) => void;
};

export function DocumentScheduleTasksModal({ document, scheduleTasks, onSave }: DocumentScheduleTasksModalProps) {
  const modalForm = useFormState({
    config: formConfig,
    init: { tasks: document.tasks.map((t) => t.id) },
  });
  const { closeModal } = useModal();

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>Link to Schedule Items</ModalHeader>
          <ModalBody>
            <p css={Css.mb3.$}>
              Link <span css={Css.fwb.$}>{document.displayName}</span> to schedule items
            </p>
            <h1 css={Css.lgSb.mb2.$}>Schedule Items</h1>
            <ToggleChips
              values={scheduleTasks.filter(({ id }) => modalForm.tasks.value?.includes(id))}
              getLabel={({ name }) => name}
              onRemove={(x) => modalForm.tasks.set(modalForm.tasks.value?.filter((id: string) => id !== x.id))}
            />
            {nonEmpty(modalForm.tasks.value ?? []) && <br />}
            <div css={Css.df.fdc.gap1.$}>
              <BoundMultiSelectField
                label="Schedule Items"
                aria-label="Schedule Items"
                labelStyle="hidden"
                options={scheduleTasks}
                field={modalForm.tasks}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="tertiary" label="Cancel" onClick={closeModal} />
            <Button
              variant="primary"
              label="Link Items"
              onClick={async () => {
                onSave(modalForm.tasks.value ?? []);
                closeModal();
              }}
            />
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type LinkScheduleTaskFormState = {
  tasks: Maybe<Array<Scalars["ID"]>>;
};

const formConfig: ObjectConfig<LinkScheduleTaskFormState> = {
  tasks: { type: "value" },
};
