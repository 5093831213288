import { Css, defaultTestId, navLink, Palette, useResponsiveGrid, useTestIds } from "@homebound/beam";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { formatDate, Icon } from "src/components";
import {
  ProjectInfoTile_ReadyPlanConfigFragment,
  ProjectInfoTile_ScheduleFragment,
  ProjectInfoTile_ScheduleTaskFragment,
  Stage,
  useProjectInfoTileQuery,
} from "src/generated/graphql-types";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { Tile, TileBaseProps } from "src/routes/projects/dashboard/components/Tile";
import { createDevelopmentLotDetailsUrl, createProjectScheduleUrl, createProjectSelectionsUrl } from "src/RouteUrls";
import { pluralize, queryResult } from "src/utils";
import { EmptyTile } from "../EmptyTile";

export function ProjectInfoTile(props: TileBaseProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const { latestActiveStage } = useProjectContext();
  const query = useProjectInfoTileQuery({ variables: { projectId, latestActiveStage } });

  return (
    <Tile {...props}>
      {queryResult(query, ({ project, completedMilestoneTasks, systemTasks }) => {
        const { readyPlanConfig, id, cohort } = project;

        return (
          <ProjectInfoTileDetails
            developmentId={cohort?.development?.id}
            projectReadyPlanConfigId={readyPlanConfig?.id}
            readyPlanConfig={readyPlanConfig}
            projectId={id}
            latestActiveStage={latestActiveStage}
            systemTasks={systemTasks ?? []}
            completedMilestoneTasks={completedMilestoneTasks ?? []}
            schedules={project.schedules}
          />
        );
      })}
    </Tile>
  );
}

type ProjectInfoTileDetailsProps = {
  readyPlanConfig: ProjectInfoTile_ReadyPlanConfigFragment | null | undefined;
  developmentId: string | undefined;
  projectReadyPlanConfigId: string | undefined;
  projectId: string;
  latestActiveStage: Stage;
  systemTasks: ProjectInfoTile_ScheduleTaskFragment[];
  completedMilestoneTasks: ProjectInfoTile_ScheduleTaskFragment[];
  schedules: ProjectInfoTile_ScheduleFragment[];
};

function ProjectInfoTileDetails({
  readyPlanConfig,
  developmentId,
  projectReadyPlanConfigId,
  projectId,
  latestActiveStage,
  completedMilestoneTasks,
  schedules,
  systemTasks,
}: ProjectInfoTileDetailsProps) {
  const tid = useTestIds({});

  const { gridStyles } = useResponsiveGrid({ minColumnWidth: 180, columns: 3, gap: 24 });

  return (
    <div css={{ ...gridStyles }}>
      {configurationColumn(tid, readyPlanConfig, developmentId, projectReadyPlanConfigId)}
      {timelineColumn(tid, projectId, latestActiveStage, systemTasks, completedMilestoneTasks, schedules)}
      {designPackageColumn(tid, readyPlanConfig, projectId, latestActiveStage)}
    </div>
  );
}

function configurationColumn(
  tid: Record<string, object>,
  readyPlanConfig: ProjectInfoTile_ReadyPlanConfigFragment | null | undefined,
  developmentId: string | undefined,
  projectReadyPlanConfigId: string | undefined,
) {
  // formatting sq footage to add a comma to the number
  const formattedSqFootage = readyPlanConfig?.programData?.sellableSqft?.toLocaleString();
  const title = (
    <span css={Css.baseSb.mr1.$} {...tid.configuration}>
      Configuration
    </span>
  );
  return (
    <div css={Css.df.fdc.gap2.xsMd.$}>
      {projectReadyPlanConfigId && developmentId ? (
        <Link
          to={createDevelopmentLotDetailsUrl(developmentId, projectReadyPlanConfigId)}
          target="_blank"
          css={Css.df.aic.onHover.tdu.$}
          className={navLink}
        >
          {title}
          <Icon inc={2} icon="linkExternal" color={Palette.Gray900} />
        </Link>
      ) : (
        title
      )}
      {readyPlanConfig ? (
        <>
          <div css={Css.df.fdc.gap1.$} {...tid.readyPlanName}>
            <p>{readyPlanConfig.readyPlan?.displayName}</p>
            <p>{readyPlanConfig.orientationDetail.name}</p>
          </div>
          <div css={Css.mt2.df.aic.$}>
            <Icon icon="bed" pxSize={16} />
            <span css={Css.ml3.$} {...tid.bedrooms}>
              {readyPlanConfig.programData?.bedrooms
                ? `${readyPlanConfig.programData.bedrooms} ${pluralize(readyPlanConfig.programData.bedrooms, "Bed")}`
                : "None"}
            </span>
          </div>
          <div css={Css.mt1.df.aic.$}>
            <Icon icon="faucet" pxSize={16} />
            <div css={Css.ml3.$}>
              <p {...tid.baths}>
                {readyPlanConfig.programData?.fullBaths
                  ? `${readyPlanConfig.programData.fullBaths} ${pluralize(
                      readyPlanConfig.programData.fullBaths,
                      "Full Bath",
                    )}`
                  : "None"}
                {readyPlanConfig.programData?.halfBaths &&
                  `${readyPlanConfig.programData.fullBaths ? "; " : ""}${
                    readyPlanConfig.programData.halfBaths
                  } ${pluralize(readyPlanConfig.programData.halfBaths, "Half Bath")}`}
              </p>
            </div>
          </div>
          <div css={Css.mt1.df.aic.$}>
            <Icon icon="sqFeet" pxSize={16} />
            <div css={Css.df.ml3.$}>
              <p {...tid.sqFt}>{formattedSqFootage ? `${formattedSqFootage} Sellable SF` : "None"}</p>
            </div>
          </div>
        </>
      ) : (
        <EmptyTile>
          Configuration has not <br /> been created yet
        </EmptyTile>
      )}
    </div>
  );
}

function timelineColumn(
  tid: Record<string, object>,
  projectId: string,
  latestActiveStage: Stage,
  systemTasks: ProjectInfoTile_ScheduleTaskFragment[],
  completedMilestoneTasks: ProjectInfoTile_ScheduleTaskFragment[],
  schedules: ProjectInfoTile_ScheduleFragment[],
) {
  const deliveryDate = systemTasks.nonEmpty ? formatDate(systemTasks[0].interval.endDate) : "None";
  const closeDate = systemTasks.nonEmpty ? formatDate(systemTasks[1].interval.endDate) : "None";
  const lastActiveMilestone = schedules.filter((s) => s.stage === latestActiveStage);
  const startDate = lastActiveMilestone.nonEmpty ? formatDate(lastActiveMilestone[0].interval?.startDate) : "None";

  const timelineDetails: { label: string; value: string }[] = [
    {
      label: "Start to Delivery",
      value: `${startDate} - ${deliveryDate}`,
    },
    { label: "Close", value: `${closeDate}` },
    { label: "Latest Milestone", value: `${formatLatestMilestone(completedMilestoneTasks)}` },
  ];

  return (
    <div css={Css.df.fdc.gap2.xsMd.$}>
      <Link
        to={createProjectScheduleUrl(projectId, latestActiveStage)}
        target="_blank"
        css={Css.df.aic.onHover.tdu.$}
        className={navLink}
      >
        <span css={Css.baseSb.mr1.$} {...tid.timeline}>
          Timeline
        </span>
        <Icon inc={2} icon="linkExternal" color={Palette.Gray900} />
      </Link>

      {schedules ? getDetailsList(timelineDetails) : <EmptyTile>No schedules have been created</EmptyTile>}

      {/* Hiding Sales date for now until Khalid works on #SC-27772
         <div css={Css.df.fdc.$}>
          <span css={Css.smBd.$}>Sales</span>
          <p>None</p>
        </div> */}
    </div>
  );
}

function designPackageColumn(
  tid: Record<string, object>,
  readyPlanConfig: ProjectInfoTile_ReadyPlanConfigFragment | null | undefined,
  projectId: string,
  latestActiveStage: Stage,
) {
  const designPackageDetails: { label: string; value: string | string[] }[] = [
    {
      label: "Interior Design Package",
      value:
        readyPlanConfig?.options
          ?.filter((o) => o.readyPlanOption.globalOption.group.forDesignInterior)
          .map((o) => o.readyPlanOption.name.replace("Design Package", "").replace("-", "")) ?? "None",
    },
    {
      label: "Exterior Design Package",
      value:
        readyPlanConfig?.options
          ?.filter(
            (o) =>
              o.readyPlanOption.globalOption.group.type.isElevation ||
              o.readyPlanOption.globalOption.group.type.isExteriorPalette,
          )
          .map((o) => o.readyPlanOption.name.replace("Design Package", "").replace("-", "")) ?? "None",
    },
  ];

  return (
    <div css={Css.df.fdc.gap2.xsMd.$}>
      <Link
        to={createProjectSelectionsUrl(projectId, latestActiveStage)}
        target="_blank"
        css={Css.df.aic.onHover.tdu.$}
        className={navLink}
      >
        <span css={Css.baseSb.mr1.$} {...tid.designLink}>
          Design
        </span>
        <Icon inc={2} icon="linkExternal" color={Palette.Gray900} />
      </Link>

      {readyPlanConfig ? (
        getDetailsList(designPackageDetails)
      ) : (
        <EmptyTile>
          ReadyPlan has not <br /> been selected yet
        </EmptyTile>
      )}
    </div>
  );
}

// helper to grab the last completed milestone task
function formatLatestMilestone(completedMilestoneTasks: ProjectInfoTile_ScheduleTaskFragment[] | undefined) {
  if (!completedMilestoneTasks) {
    return "None";
  }
  // sort the completed milestone tasks by their end date
  const latestMilestone = [...completedMilestoneTasks]
    .sort((a, b) => a.interval.endDate.getTime() - b.interval.endDate.getTime())
    .slice(-1)[0];
  // grab the last completed task
  return latestMilestone ? latestMilestone.name : "None";
}

// helper to render timeline + design package label/value items
function getDetailsItem({ label, value }: { label: string; value: string | string[] }) {
  const id = defaultTestId(label);
  return (
    <div key={label}>
      <div css={Css.smBd.mbPx(4).$} id={id}>
        {label}
      </div>
      <div data-testid={id} aria-labelledby={id} css={Css.df.fdc.gap1.$}>
        {Array.isArray(value) ? value.map((item) => <div key={item}>{item}</div>) : value}
      </div>
    </div>
  );
}

function getDetailsList(list: { label: string; value: string | string[] }[]) {
  return <div css={Css.df.fdc.gap3.$}>{list.map((item) => getDetailsItem(item))}</div>;
}
