import { Button, Checkbox, Css, DateRange, DateRangeField, FieldGroup, FormLines, SelectField } from "@homebound/beam";
import { ReactNode, useCallback, useState } from "react";
import { DateOperation, PlanTasksFilter, TaskStatus } from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";

export enum TaskGroupBy {
  None,
  Stage,
}

type DynamicSchedulesFilterModalWrapperProps<TFilter> = {
  onClose: VoidFunction;
  filter: TFilter;
  setFilter: (filter: TFilter) => void;
  additionalFilters: ReactNode;
};

export function DynamicSchedulesFilterModalWrapper<
  TFilter extends { startDateRange?: DateRange; endDateRange?: DateRange },
>({ onClose, filter, setFilter, additionalFilters }: DynamicSchedulesFilterModalWrapperProps<TFilter>) {
  const [modalFilter, setModalFilter] = useState<TFilter>({ ...mapToState(filter) });

  const onSubmit = useCallback(() => {
    setFilter({ ...(modalFilter satisfies TFilter) });
    onClose();
  }, [modalFilter, setFilter, onClose]);

  return (
    <FormLines>
      <FieldGroup>
        <DateRangeField
          value={modalFilter.startDateRange}
          onChange={(val) => setModalFilter((filter) => ({ ...filter, startDateRange: val }))}
          label="Start Date Range"
          compact
        />
        <DateRangeField
          value={modalFilter.endDateRange}
          onChange={(val) => setModalFilter((filter) => ({ ...filter, endDateRange: val }))}
          label="Finish Date Range"
          compact
        />
      </FieldGroup>
      {additionalFilters}
      <div css={Css.df.gap1.jcfe.$}>
        <Button variant="tertiary" label="Cancel" onClick={onClose} />
        <Button label="Apply" onClick={onSubmit} />
      </div>
    </FormLines>
  );
}

type DynamicSchedulesFilterModalProps = {
  onClose: VoidFunction;
  filter: CustomDynamicSchedulesFilter;
  setFilter: (filter: CustomDynamicSchedulesFilter) => void;
};

export function DynamicSchedulesFilterModal({ onClose, filter, setFilter }: DynamicSchedulesFilterModalProps) {
  const [modalFilter, setModalFilter] = useState<CustomDynamicSchedulesFilter>({ ...mapToState(filter) });

  return (
    <DynamicSchedulesFilterModalWrapper
      filter={filter}
      setFilter={(existingFilter) =>
        setFilter({
          ...existingFilter,
          ...modalFilter,
        })
      }
      onClose={onClose}
      additionalFilters={
        <>
          <SelectField
            value={modalFilter.groupBy}
            onSelect={(val) => setModalFilter((filter) => ({ ...filter, groupBy: val! }))}
            label="Group By"
            options={[
              { value: TaskGroupBy.None, label: "None" },
              { value: TaskGroupBy.Stage, label: "Stage" },
            ]}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
          <Checkbox
            label="Completed Tasks"
            selected={!!modalFilter.includeCompletedTasks}
            onChange={(val) => setModalFilter((filter) => ({ ...filter, includeCompletedTasks: val }))}
          />
        </>
      }
    />
  );
}

export type CustomDynamicSchedulesFilter = Pick<PlanTasksFilter, "status"> & {
  startDateRange: DateRange | undefined;
  endDateRange: DateRange | undefined;
  includeCompletedTasks: boolean;
  groupBy: TaskGroupBy;
};

export const customDynamicSchedulesFilterDefault: CustomDynamicSchedulesFilter = {
  startDateRange: undefined,
  endDateRange: undefined,
  includeCompletedTasks: false,
  groupBy: TaskGroupBy.None,
};

export function mapToFilter(filter: CustomDynamicSchedulesFilter): PlanTasksFilter {
  const { startDateRange, endDateRange, includeCompletedTasks, status, groupBy, ...otherFilters } = filter;
  // There is no status filter in the UI
  const nonComplete = Object.values(TaskStatus).filter((status) => status !== TaskStatus.Complete);
  return {
    ...otherFilters,
    ...(startDateRange && startDateRange.from && startDateRange.to
      ? {
          startDateRange: {
            op: DateOperation.Between,
            value: new DateOnly(new Date(startDateRange.from)),
            value2: new DateOnly(new Date(startDateRange.to)),
          },
        }
      : {}),
    ...(endDateRange && endDateRange.from && endDateRange.to
      ? {
          endDateRange: {
            op: DateOperation.Between,
            value: new DateOnly(new Date(endDateRange.from)),
            value2: new DateOnly(new Date(endDateRange.to)),
          },
        }
      : {}),
    status: [...(status ?? nonComplete), ...(includeCompletedTasks ? [TaskStatus.Complete] : [])],
  };
}

export function mapToState<TFilter extends { startDateRange?: DateRange; endDateRange?: DateRange }>(
  filter: TFilter,
): TFilter {
  const { startDateRange, endDateRange } = filter;
  return {
    ...filter,
    ...(startDateRange && startDateRange.from && startDateRange.to
      ? {
          startDateRange: {
            from: new Date(startDateRange.from),
            to: new Date(startDateRange.to),
          },
        }
      : {}),
    ...(endDateRange && endDateRange.from && endDateRange.to
      ? {
          endDateRange: {
            from: new Date(endDateRange.from),
            to: new Date(endDateRange.to),
          },
        }
      : {}),
  };
}
