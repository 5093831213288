import {
  actionColumn,
  ButtonMenu,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridDataRow,
  GridTable,
  Icon,
  IconButton,
  LoadingSkeleton,
  MenuItem,
  numericColumn,
  Palette,
  ScrollableContent,
  selectColumn,
  Tooltip,
  useModal,
  useToast,
} from "@homebound/beam";
import { useCallback, useMemo, useRef } from "react";
import {
  CostType,
  Order,
  PlanPackageTakeoffTable_GroupTotalsFragment,
  PlanPackageTakeoffTable_ItemsFragment,
  usePlanPackageTakeoffTableQuery,
} from "src/generated/graphql-types";
import {
  TakeoffsStoreState,
  useTakeoffsManagerContext,
  useTakeoffsStore,
} from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { StoreApi } from "zustand";
import { RowStyles } from "@homebound/beam/dist/components/Table/TableStyles";
import { DeleteItemsConfirmationModal } from "src/routes/libraries/plan-package/takeoffs/components/DeleteItemsConfirmationModal";
import { PlanPackageTakeoffTableEmpty } from "./PlanPackageTakeoffTableEmpty";
import { AddItemsFromExistingPlanModal } from "src/routes/libraries/plan-package/takeoffs/components/AddItemsFromExistingPlanModal";
import { TruncatedNameListCell } from "./TruncatedNameListCell";
import { ArchivedTag } from "src/components/ArchivedTag";
import { pluralize } from "src/utils";
import { AddEditLaborItemModal, AddEditMaterialItemModal, AddEditPlaceholderItemModal } from "../AddItems";
import { subFilterByOrder } from "src/utils/itemTemplateItem";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";

export type PlanPackageTakeoffTableProps = {
  itemTemplateId: string;
};

export function PlanPackageTakeoffTable(props: PlanPackageTakeoffTableProps) {
  const { itemTemplateId } = props;
  const itemTableGroupBy = useTakeoffsStore((state) => state.itemTableGroupBy);
  const itemTableSortBy = useTakeoffsStore((state) => state.itemTableSortBy);
  const filter = useTakeoffsStore((state) => state.filter);
  const isEditable = useTakeoffsStore((state) => state.itemTemplate.canEditLineItems.allowed === true);
  // Get the takeoffs store to manage loading items with the correct selected state. Not using the `useTakeoffsStore` hook to avoid unnecessary reactivity.
  const takeoffsStore = useTakeoffsManagerContext();
  const { openModal } = useModal();

  const { data, loading, previousData, fetchMore } = usePlanPackageTakeoffTableQuery({
    variables: {
      filter: { template: [itemTemplateId], excludeRemoved: true, ...filter },
      first: 25,
      order: [
        { field: itemTableGroupBy, direction: Order.Asc },
        { field: itemTableSortBy, direction: Order.Asc },
      ],
    },
    fetchPolicy: "cache-and-network",
  });

  const onDuplicateItem = useCallback(
    (itiv: PlanPackageTakeoffTable_ItemsFragment) => {
      const props = {
        takeoffsManagerStore: takeoffsStore,
        itemTemplateId: itemTemplateId,
        itivId: itiv.id,
        asNew: true,
      };
      openModal({
        content:
          itiv.costType === CostType.Labor ? (
            <AddEditLaborItemModal {...props} />
          ) : itiv.isPlaceholderMaterial ? (
            <AddEditPlaceholderItemModal {...props} />
          ) : (
            <AddEditMaterialItemModal {...props} />
          ),
      });
    },
    [openModal, itemTemplateId, takeoffsStore],
  );

  const onDeleteItem = useCallback(
    (itemId: string) => {
      openModal({
        content: <DeleteItemsConfirmationModal store={takeoffsStore} itemIdsToDelete={[itemId]} />,
      });
    },
    [openModal, takeoffsStore],
  );

  const addItemActions = useMemo(() => {
    return [
      {
        label: "Construction Material",
        onClick: () => {
          openModal({
            content: <AddEditMaterialItemModal takeoffsManagerStore={takeoffsStore} itemTemplateId={itemTemplateId} />,
          });
        },
      },
      {
        label: "Placeholder",
        onClick: () => {
          openModal({
            content: (
              <AddEditPlaceholderItemModal takeoffsManagerStore={takeoffsStore} itemTemplateId={itemTemplateId} />
            ),
          });
        },
      },
      {
        label: "Labor",
        onClick: () => {
          openModal({
            content: <AddEditLaborItemModal takeoffsManagerStore={takeoffsStore} itemTemplateId={itemTemplateId} />,
          });
        },
      },
    ];
  }, [itemTemplateId, openModal, takeoffsStore]);

  const onImportItems = useCallback(() => {
    const { saveItiv, copyFromTemplate } = takeoffsStore.getState();
    openModal({
      content: (
        <AddItemsFromExistingPlanModal
          templateId={itemTemplateId}
          copyFromTemplate={copyFromTemplate}
          addItiv={saveItiv}
        />
      ),
    });
  }, [itemTemplateId, openModal, takeoffsStore]);

  const { showToast } = useToast();
  const failedGroupIds = useRef<string[]>([]);
  const fetchMoreGroupItems = useCallback(
    async (groupTotals: PlanPackageTakeoffTable_GroupTotalsFragment) => {
      if (failedGroupIds.current.includes(groupTotals.groupId)) return;
      try {
        await fetchMore({
          variables: {
            first: null,
            subFilter: subFilterByOrder(itemTableGroupBy, groupTotals.groupId),
          },
        });
      } catch (e) {
        console.error(e);
        failedGroupIds.current.push(groupTotals.groupId);
        showToast({ type: "error", message: `Failed to fetch items for group ${groupTotals.displayName}` });
      }
    },
    [fetchMore, showToast, itemTableGroupBy, failedGroupIds],
  );

  const { items = [], groupTotals = [] } =
    data?.itemTemplateItemVersionsPage ?? previousData?.itemTemplateItemVersionsPage ?? {};

  const columns = useMemo(
    () => createColumns(fetchMoreGroupItems, onDuplicateItem, onDeleteItem, addItemActions, onImportItems, isEditable),
    [fetchMoreGroupItems, onDeleteItem, onDuplicateItem, addItemActions, onImportItems, isEditable],
  );
  const rows = useMemo(() => createRows(items, groupTotals, takeoffsStore), [items, groupTotals, takeoffsStore]);
  const rowStyles: RowStyles<ItemTemplateItemRows> = useMemo(() => {
    return {
      header: { cellCss: Css.gray900.$ },
      item: {
        onClick: (row) => {
          const props = {
            itivId: row.id,
            takeoffsManagerStore: takeoffsStore,
            itemTemplateId: itemTemplateId,
          };
          openModal({
            content:
              row.data.costType === CostType.Labor ? (
                <AddEditLaborItemModal {...props} />
              ) : row.data.isPlaceholderMaterial ? (
                <AddEditPlaceholderItemModal {...props} />
              ) : (
                <AddEditMaterialItemModal {...props} />
              ),
          });
        },
      },
    };
  }, [openModal, takeoffsStore, itemTemplateId]);

  return (
    <ScrollableContent virtualized>
      {loading ? (
        <>
          <LoadingSkeleton rows={1} columns={1} />
          <LoadingSkeleton rows={5} columns={5} />
        </>
      ) : rows.length === 1 && Object.keys(filter).length === 0 ? (
        <PlanPackageTakeoffTableEmpty />
      ) : (
        <GridTable
          as="virtual"
          style={{ grouped: true, allWhite: true, bordered: true, rowHeight: "fixed" }}
          columns={columns}
          rows={rows}
          stickyHeader
          api={takeoffsStore.getState().itemTableApi}
          rowStyles={rowStyles}
          fallbackMessage={"No items match the applied filters. Please adjust and try again"}
        />
      )}
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type GroupByRow = { kind: "groupBy"; data: PlanPackageTakeoffTable_GroupTotalsFragment };
export type ItemRow = { kind: "item"; data: PlanPackageTakeoffTable_ItemsFragment; id: string };
// Add optionId on to handle the case of an ItemSlot appearing in a BaseOption groupBy row. Thus allowing the add actions to put created items into the correct options
type LoadingRow = { kind: "loading"; data: PlanPackageTakeoffTable_GroupTotalsFragment; id: string };
export type ItemTemplateItemRows = HeaderRow | GroupByRow | ItemRow | LoadingRow;

function createColumns(
  fetchMoreGroupItems: (groupTotals: PlanPackageTakeoffTable_GroupTotalsFragment) => Promise<void>,
  onDuplicate: (itiv: PlanPackageTakeoffTable_ItemsFragment) => void,
  onDelete: (itemId: string) => void,
  addItemActions: MenuItem[],
  onImportItems: () => void,
  isEditable: boolean,
) {
  return [
    collapseColumn<ItemTemplateItemRows>({ loading: emptyCell, sticky: "left" }),
    selectColumn<ItemTemplateItemRows>({ loading: emptyCell, sticky: "left" }),
    column<ItemTemplateItemRows>({
      id: `${Column.ItemCode}`,
      header: "Item code",
      groupBy: (row) => ({
        colspan: isEditable ? 7 : 6,
        content: () => (
          <span css={Css.xlSb.w100.$}>
            <span css={Css.dib.truncate.w50.$}>
              {row.displayName.length > 55 ? (
                <Tooltip
                  title={
                    <ul css={Css.pl2.$}>
                      {row.displayName.split(",").map((name) => (
                        <li key={name}>{name}</li>
                      ))}
                    </ul>
                  }
                >
                  {row.displayName}
                </Tooltip>
              ) : (
                row.displayName
              )}
            </span>
            <span css={Css.gray700.sm.ml3.$}>
              {`${row.materialsInGroup} ${pluralize(row.materialsInGroup, "material")}`}
            </span>
            <span css={Css.gray700.sm.ml3.$}>{`${row.tasksInGroup} ${pluralize(row.tasksInGroup, "task")}`}</span>
          </span>
        ),
        alignment: "left",
        css: Css.maxw("66.5vw").$,
      }),
      item: (row) => (
        <Tooltip placement="top" title={row.item.name}>
          {row.item.fullCode}
        </Tooltip>
      ),
      loading: (row) => ({
        content: () => {
          fetchMoreGroupItems(row).catch(console.error);
          return (
            <>
              Loading...
              <Icon icon="refresh" />
            </>
          );
        },
      }),
      mw: "100px",
      sticky: "left",
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.Name}`,
      header: "Name",
      groupBy: emptyCell,
      item: (row) => row.name,
      loading: emptyCell,
      mw: "205px",
      sticky: "left",
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.MaterialCode}`,
      header: "Material Code",
      groupBy: emptyCell,
      item: (row) => {
        const attrs = row?.materialVariant?.materialAttributeValues;
        if (!attrs || attrs.length === 0) {
          return row?.materialVariant?.code;
        }

        return (
          <Tooltip
            placement="top"
            title={attrs.map(({ value, dimension }) => (
              <div key={value}>
                {dimension.name}: {value} {dimension.unitOfMeasure?.abbreviation}
              </div>
            ))}
          >
            <span css={Css.truncate.$}>{row?.materialVariant?.code}</span>
          </Tooltip>
        );
      },
      loading: emptyCell,
      mw: "120px",
      sticky: "left",
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.GenericIcon}`,
      header: "",
      groupBy: emptyCell,
      item: (row) =>
        row.isPlaceholderMaterial ? (
          <Tooltip title="Product slot for Design Package">
            <Icon icon="link" inc={2} color={Palette.Gray600} />
          </Tooltip>
        ) : (
          emptyCell
        ),
      loading: emptyCell,
      w: "40px",
    }),
    numericColumn<ItemTemplateItemRows>({
      id: `${Column.Quantity}`,
      header: "QTY",
      groupBy: () => emptyCell,
      item: (row) => (row.unitOfMeasure?.useQuantity ? row.quantity ?? "" : "N/A"),
      loading: emptyCell,
      align: "right",
      mw: "80px",
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.UnitOfMeasure}`,
      header: "UoM",
      groupBy: () => emptyCell,
      item: (row) => row.unitOfMeasure?.abbreviation,
      loading: emptyCell,
      mw: "80px",
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.Location}`,
      header: "Location",
      groupBy: () => emptyCell,
      item: (row) => row.location.displayLocationPath,
      loading: emptyCell,
      mw: "140px",
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.Option}`,
      header: "Option",
      groupBy: () => emptyCell,
      item: (row) => {
        const optionsCodes = row.options.map((rpo) => (
          <ArchivedTag key={rpo.id} active={rpo.active}>
            {rpo.code}
          </ArchivedTag>
        ));

        return <TruncatedNameListCell nameList={optionsCodes} />;
      },
      loading: emptyCell,
      mw: "140px",
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.TaskAllocation}`,
      header: "Task Allocation",
      groupBy: () => emptyCell,
      item: (row) => row.task?.name,
      loading: emptyCell,
      mw: "200px",
    }),
    ...(isEditable
      ? [
          actionColumn<ItemTemplateItemRows>({
            id: `${Column.Actions}`,
            header: () => emptyCell,
            groupBy: {
              content: () => {
                return (
                  <div css={Css.df.gap1.$}>
                    <IconButton inc={3} icon="upload" onClick={() => onImportItems()} color={Palette.Blue600} />
                    <ButtonMenu trigger={{ icon: "plus", color: Palette.Blue600 }} items={addItemActions} />
                  </div>
                );
              },
              sticky: "right",
            },
            loading: emptyCell,
            item: (row) => ({
              revealOnRowHover: true,
              content: () => {
                return (
                  <div css={Css.df.gap1.$}>
                    <IconButton
                      inc={2}
                      icon="duplicate"
                      onClick={() => onDuplicate(row)}
                      disabled={disableBasedOnPotentialOperation(row.canDuplicate)}
                    />
                    <IconButton inc={2} icon="trash" onClick={() => onDelete(row.id)} />
                  </div>
                );
              },
            }),
            w: "80px",
          }),
        ]
      : []),
  ];
}

function createRows(
  items: PlanPackageTakeoffTable_ItemsFragment[],
  groupTotals: PlanPackageTakeoffTable_GroupTotalsFragment[],
  takeoffsStore: StoreApi<TakeoffsStoreState>,
): GridDataRow<ItemTemplateItemRows>[] {
  const selectedItivIds = takeoffsStore.getState().getSelectedItivIds();
  const tableApi = takeoffsStore.getState().itemTableApi;
  return [
    { kind: "header", id: "header", data: undefined },
    ...groupTotals
      .filter((gt) => gt.groupId !== "template")
      .map((gt) => {
        const itemsInGroup = items.filter((rpo) => gt.itivIdsInGroup.includes(rpo.id)) ?? [];

        const children: Array<GridDataRow<ItemTemplateItemRows>> = itemsInGroup.map((itiv) => ({
          kind: "item" as const,
          id: itiv.id,
          data: itiv,
          initSelected: selectedItivIds.includes(itiv.id),
        }));

        const loadedItems = itemsInGroup.length;
        const initCollapsed = loadedItems === 0;
        const partiallyLoaded = loadedItems !== gt.itivsInGroup;
        if (partiallyLoaded) {
          // Make the loading row unselectable - so that it never appears in the selected but hidden collection
          children.push({ kind: "loading" as const, id: `loading-${gt.groupId}`, data: gt, selectable: false });

          // If we aren't loaded at all, then insert the not-loaded group row with inferSelectedState: false
          if (loadedItems === 0)
            return {
              kind: "groupBy" as const,
              initCollapsed,
              id: gt.groupId + "not-loaded",
              data: gt,
              children,
              // Set inferSelectedState to false so that we can still select the group, even though it has only unselectable loading children
              inferSelectedState: false as const,
            };
        }
        // Remove the group loading row if it exists to prevent a not-loaded row from appearing as a selected but hidden row
        if (tableApi) {
          tableApi.deleteRows([gt.groupId + "not-loaded"]);
        }
        return {
          kind: "groupBy" as const,
          id: gt.groupId,
          data: gt,
          children,
        };
      }),
  ];
}

export enum Column {
  ItemCode,
  Name,
  MaterialCode,
  GenericIcon,
  Quantity,
  UnitOfMeasure,
  Location,
  Option,
  TaskAllocation,
  Actions,
}
