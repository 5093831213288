import { Css, ScrollableParent, Step, useTestIds } from "@homebound/beam";
import { Link, Route, Switch, useLocation, useParams } from "react-router-dom";
import { Icon } from "src/components";
import { StepperProvider } from "src/components/stepper";
import { ReassignPurchaseOrdersParams, reassignPurchaseOrdersPaths } from "src/routes/routesDef";
import { createDevelopmentContractOverviewUrl, createDevelopmentPurchaseOrdersUrl } from "src/RouteUrls";
import { foldEnum } from "src/utils";
import { NotFound } from "../../NotFound";
import { ReassignPurchaseOrdersStep, ReassignPurchaseOrdersType } from "./enums";
import { ReassignPurchaseOrdersReleaseStep } from "./ReassignPurchaseOrdersReleaseStep";
import { ReassignPurchaseOrdersSelectStep } from "./ReassignPurchaseOrdersSelectStep";

export type ReassignPurchaseOrdersState = {
  reassignType: ReassignPurchaseOrdersType;
  bcrId?: string;
};

export function ReassignPurchaseOrdersPage() {
  const testIds = useTestIds({});
  const location = useLocation<ReassignPurchaseOrdersState>();
  const { reassignType, bcrId } = location.state;
  const { developmentId, currentStep } = useParams<ReassignPurchaseOrdersParams>();
  const steps: Step[] = [
    {
      label: foldEnum(reassignType, {
        [ReassignPurchaseOrdersType.TRADE_PARTNER]: "Select Trade Partner",
        [ReassignPurchaseOrdersType.VERSION]: "Select Version",
        [ReassignPurchaseOrdersType.MANAGE]: "Void POs",
      }),
      disabled: false,
      state: currentStep === ReassignPurchaseOrdersStep.SELECT ? "incomplete" : "complete",
      value: ReassignPurchaseOrdersStep.SELECT,
    },
    {
      label: "Release New POs",
      disabled: currentStep === ReassignPurchaseOrdersStep.SELECT,
      state: "incomplete",
      value: ReassignPurchaseOrdersStep.RELEASE,
    },
  ];

  return (
    <StepperProvider steps={steps}>
      <header css={Css.bb.bw1.bcGray200.mb5.p3.$}>
        <div css={Css.df.blue700.aic.xsMd.mlPx(-8).addIn("&:hover", Css.blue900.$).$}>
          <Icon icon="chevronLeft" />
          {reassignType === ReassignPurchaseOrdersType.MANAGE ? (
            <Link to={createDevelopmentContractOverviewUrl(developmentId, bcrId!)} className="navLink">
              Return to Contract
            </Link>
          ) : (
            <Link to={createDevelopmentPurchaseOrdersUrl(developmentId)} className="navLink">
              Return to Purchase Orders
            </Link>
          )}
        </div>
        <div css={Css.ttu.fw7.pt1.pbPx(3).$}>
          {reassignType === ReassignPurchaseOrdersType.MANAGE ? "Manage purchase orders" : `Change ${reassignType}s`}
        </div>
        <div {...testIds.headerTitle} css={Css.xl3Sb.fw7.pb1.$}>
          {foldEnum(currentStep, {
            [ReassignPurchaseOrdersStep.SELECT]:
              reassignType === ReassignPurchaseOrdersType.MANAGE
                ? "Void existing purchase orders"
                : `Select new ${reassignType}`,
            [ReassignPurchaseOrdersStep.RELEASE]: "Release new purchase orders",
          })}
        </div>
        <div {...testIds.headerSubtitle} css={Css.fw4.$}>
          {foldEnum(currentStep, {
            [ReassignPurchaseOrdersStep.SELECT]: () => (
              <div css={Css.gray700.$}>
                {reassignType === ReassignPurchaseOrdersType.MANAGE ? (
                  <span>
                    Select any existing purchase orders (POs) that you would like to void. Only unpaid POs can be
                    voided.
                  </span>
                ) : (
                  <span>
                    Select the proposed {reassignType} for each Purchase Order. The proposed {reassignType} MUST be able
                    to cover all cost codes in each PO. <br />
                    To change trades for partial POs, you must first void the PO and then choose the new trade in the
                    Lot Release workflow.
                  </span>
                )}
              </div>
            ),
            [ReassignPurchaseOrdersStep.RELEASE]: () => (
              <div css={Css.gray900.$}>
                Below are autogenerated POs that reflect contract changes. Select and issue the new POs to send to the
                proposed trade(s).
              </div>
            ),
          })}
        </div>
      </header>

      <ScrollableParent xss={Css.px3.$}>
        <div css={Css.pbPx(128).$}>
          <Switch>
            <Route path={reassignPurchaseOrdersPaths.select} exact component={ReassignPurchaseOrdersSelectStep} />
            <Route path={reassignPurchaseOrdersPaths.release} exact component={ReassignPurchaseOrdersReleaseStep} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </ScrollableParent>
    </StepperProvider>
  );
}
