import { Button, ButtonProps, useModal } from "@homebound/beam";
import { StepActions } from "../StepActions";
import { useStepperContext } from "../StepperContext";
import { useHistory } from "react-router";
import { pushInNextLoop } from "src/utils";

type NextStepButtonProps = Omit<ButtonProps, "onClick"> & {
  /** Invoked before navigation, likely to perform a mutation before navigation */
  onClick?: () => Promise<void>;
  /** If provided, renders an Exit/Cancel button to exit the wizard. Customizable via buttonProps or renders a tertiary "Cancel" button if simply `true` */
  exitButton?: true | (Omit<ButtonProps, "onClick"> & { onClick?: () => Promise<void> });
  /** Blocks the user from being able to return to this step once next/onClick is invoked */
  disableCurrentStepOnLeaving?: boolean;
  /** When provided will navigate to this page when the close action is fired */
  onCloseReturnUrl?: string;
};

export function NextStepButton(allProps: NextStepButtonProps) {
  const { exitButton, disableCurrentStepOnLeaving, onCloseReturnUrl, ...props } = allProps;
  const { currentStep, nextStep, setSteps, isLastStep } = useStepperContext();
  const { closeModal } = useModal();
  const history = useHistory();

  return (
    <StepActions>
      <>
        {exitButton &&
          (exitButton === true ? (
            <Button size="lg" onClick={onCloseReturnUrl ?? closeModal} label="Cancel" variant="tertiary" />
          ) : (
            <Button
              size="lg"
              {...exitButton}
              onClick={async () => {
                await exitButton.onClick?.();
                if (onCloseReturnUrl) pushInNextLoop(history, onCloseReturnUrl);
                else closeModal();
              }}
            />
          ))}
        <Button
          size="lg"
          {...props}
          onClick={async () => {
            await props.onClick?.();
            setSteps((oldSteps) =>
              oldSteps.map((os) => ({
                ...os,
                ...(os.value === currentStep.value && { state: "complete", disabled: disableCurrentStepOnLeaving }),
              })),
            );
            setTimeout(() => {
              nextStep();
              onCloseReturnUrl && isLastStep && history.push(onCloseReturnUrl);
            }, 0); // Suppresses `Can't perform a React state update on an unmounted component` error
          }}
        />
      </>
    </StepActions>
  );
}
