import { Card } from "src/components/Card";
import { SectionTitle } from "src/routes/libraries/plan-package/details/plan-overview/components/SectionTitle";
import { Maybe, Order, usePlanPackageRecentActivityQuery, User, useUserEventsQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { generateRowData, getEventText } from "src/routes/my-blueprint/activity-feed/UserEventsTable";
import { Css, LoadingSkeleton } from "@homebound/beam";
import { FormattedDate } from "src/components";

type PlanPackageRecentActivityProps = {
  planPackageId: string;
  currentUser?: Maybe<Pick<User, "id">>;
};

export function PlanPackageRecentActivity({ planPackageId, currentUser }: PlanPackageRecentActivityProps) {
  const { data } = usePlanPackageRecentActivityQuery({ variables: { id: planPackageId } });
  const options = data?.planPackage?.options.map((rpo) => rpo.id) ?? [];
  const rpopdms = data?.planPackage?.options.flatMap((rpo) => rpo.programDataModifiedBy.map((pdm) => pdm.id)) ?? [];
  const its = data?.planPackage?.itemTemplates.map((it) => it.id) ?? [];

  const query = useUserEventsQuery({
    variables: {
      filter: {
        parent: [planPackageId, ...options, ...rpopdms, ...its].compact(),
        parentType: ["rp", "rpo", "rpopdm", "it"],
      },
      limit: 10,
      order: { createdAt: Order.Desc },
    },
    skip: data === undefined,
  });
  return (
    <div>
      <SectionTitle title="Recent Activity" />
      <Card xss={Css.py3.pl3.$}>
        {queryResult(query, {
          skippable: true,
          loading: () => <LoadingSkeleton rows={10} size="sm" />,
          data: ({ userEvents }) => (
            <ul css={Css.listReset.df.fdc.gap2.pr3.maxhPx(250).overflowY("auto").$}>
              {userEvents.map((event) => {
                if (!currentUser) return null;
                const rowData = generateRowData(currentUser, event, false);
                if (!rowData) return null;

                return (
                  <li key={event.id} css={Css.df.fdc.gapPx(2).$}>
                    <span css={Css.sm.gray900.$}>{getEventText(rowData, false, false)}</span>
                    <span css={Css.tiny.gray700.$}>
                      <FormattedDate date={rowData.createdAt} dateFormatStyle={"medium"} timeFormatStyle={"short"} />
                    </span>
                  </li>
                );
              })}
            </ul>
          ),
        })}
      </Card>
    </div>
  );
}
