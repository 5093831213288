import {
  BoundRichTextField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Css,
  FieldGroup,
  FormLines,
  NumberField,
  px,
  SelectField,
  StaticField,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  CeliSpecOverviewTabChangeEventLineItemFragment,
  CeliSpecOverviewTabQuery,
  ChangeEventLineItemType,
  CostCode,
  CostType,
  SaveChangeEventLineItemInput,
  useCeliSpecOverviewTabQuery,
  useCeliSpecOverviewTabSaveCeliMutation,
} from "src/generated/graphql-types";
import { NotFound } from "src/routes/NotFound";
import { ObservableChangeEventLineItem } from "src/routes/projects/change-events/models/ObservableChangeEventLineItem";
import { queryResult, safeEntries } from "src/utils";
import { entityNotFoundError } from "src/utils/error";

type CeliSpecOverviewTabProps = {
  changeEventLineItem: ObservableChangeEventLineItem;
};

export function CeliSpecOverviewTab({ changeEventLineItem }: CeliSpecOverviewTabProps) {
  const query = useCeliSpecOverviewTabQuery({ variables: { filter: { id: [changeEventLineItem.id] } } });

  return queryResult(query, {
    data: ({ changeEventLineItems, locations, unitsOfMeasure }) => {
      const celi = changeEventLineItems[0];

      if (!celi) {
        return <NotFound error={entityNotFoundError(changeEventLineItem.id)} />;
      }

      return (
        <SpecDataView
          celi={celi}
          locations={locations}
          observableCeli={changeEventLineItem}
          unitsOfMeasure={unitsOfMeasure}
        />
      );
    },
  });
}

type SpecDataViewProps = {
  celi: CeliSpecOverviewTabChangeEventLineItemFragment;
  locations: CeliSpecOverviewTabQuery["locations"];
  observableCeli: ObservableChangeEventLineItem;
  unitsOfMeasure: CeliSpecOverviewTabQuery["unitsOfMeasure"];
};

function SpecDataView({ celi, locations, unitsOfMeasure, observableCeli }: SpecDataViewProps) {
  const [saveCeli] = useCeliSpecOverviewTabSaveCeliMutation();
  const readOnly = !celi.changeEvent.canEditLineItems.allowed;

  const formState = useFormState({
    config: formConfig,
    init: {
      input: celi,
      map: (celi) => mapCeliToFormState(celi),
    },
    autoSave: async ({ changedValue }) => {
      await saveCeli({ variables: { celi: { ...(changedValue as SaveChangeEventLineItemInput) } } });
      formState.commitChanges();
    },
    readOnly,
  });

  return (
    <div css={Css.df.aifs.gap2.jcsb.$}>
      <form css={Css.fg1.maxw(px(480)).df.gap5.fdc.$}>
        <fieldset css={Css.df.fdc.gap2.$}>
          <legend css={Css.baseMd.gray900.mb2.$}>Details</legend>
          <FieldGroup>
            <StaticField label="Item" value={formState.projectItemName.value} />
            <StaticField label="Trade Category" value={formState.tradeCategoryName.value || "Unassigned"} />
            <StaticField label="Cost Code" value={formState.costCode.value} />
          </FieldGroup>
          <BoundTextField compact field={formState.name} label="Display Name" />
          <BoundSelectField
            compact
            field={formState.costType}
            getOptionLabel={([name]) => name}
            getOptionValue={([, code]) => code}
            options={safeEntries(CostType)}
            readOnly={readOnly || celi.type !== ChangeEventLineItemType.Add}
          />
          {!formState.locationId.value && celi.type !== ChangeEventLineItemType.Add ? (
            <StaticField label="Location" value="N/A" />
          ) : (
            <BoundSelectField
              compact
              field={formState.locationId}
              getOptionMenuLabel={(l) => l.name}
              options={locations}
              readOnly={readOnly || celi.type !== ChangeEventLineItemType.Add}
            />
          )}
        </fieldset>
        <fieldset css={Css.df.fdc.gap2.$}>
          <legend css={Css.baseMd.gray900.mb2.$}>Notes</legend>
          <BoundRichTextField field={formState.specifications} label="Specifications (visible to everyone)" />
          <BoundTextAreaField field={formState.tradePartnerNote} label="Trade Note (only visible to trades)" />
          <BoundTextAreaField
            field={formState.internalNote}
            label="Internal Note (only visible to Homebound employees)"
          />
        </fieldset>
      </form>
      {/* using observable below as to stay in sync with CELI table */}
      <div css={Css.p2.bgGray100.br4.wPx(360).$}>
        <h2 css={Css.mb3.baseMd.gray900.$}>Specs</h2>
        <Observer>
          {() => (
            <FormLines width="full">
              <FieldGroup>
                {observableCeli.unitOfMeasure?.useQuantity === false ? (
                  <StaticField label="Qty" value="N/A" />
                ) : (
                  <NumberField
                    displayDirection
                    label="Qty"
                    onChange={(val) => {
                      if (val !== undefined) {
                        observableCeli.updateProposedQuantity(val);
                      }
                    }}
                    readOnly={readOnly}
                    value={observableCeli.proposedQuantity}
                  />
                )}
                {/* if we do not have `unitsOfMeasure` then default the options to this row's unitOfMeasure so `SelectField` can show the value */}
                <SelectField
                  label="Unit of Measure"
                  onSelect={(val) => observableCeli.updateUnitOfMeasureId(val)}
                  options={unitsOfMeasure || (observableCeli.unitOfMeasure ? [observableCeli.unitOfMeasure] : [])}
                  readOnly={readOnly || observableCeli.type === ChangeEventLineItemType.Modify}
                  value={observableCeli.unitOfMeasureId}
                />
              </FieldGroup>
              {observableCeli.unitOfMeasure?.useQuantity === false || observableCeli.proposedQuantity === 0 ? (
                <StaticField label="Unit Cost" value="N/A" />
              ) : (
                <NumberField
                  label="Unit Cost"
                  onChange={(val) => {
                    if (val !== undefined) {
                      observableCeli.updateProposedUnitCostInCents(val);
                    }
                  }}
                  readOnly={readOnly}
                  type="cents"
                  value={observableCeli.proposedUnitCostInCents}
                />
              )}
              <NumberField
                label="Total Estimated Cost"
                onChange={(val) => {
                  if (val !== undefined) {
                    observableCeli.updateProposedTotalCostInCents(val);
                  }
                }}
                readOnly={readOnly}
                type="cents"
                value={observableCeli.proposedTotalCostInCents}
              />
              <NumberField
                label="Markup"
                onChange={(val) => {
                  if (val !== undefined) {
                    observableCeli.updateProposedMarkupPercent(val);
                  }
                }}
                readOnly={readOnly || observableCeli.isInternal}
                type="percent"
                numFractionDigits={2}
                value={observableCeli.proposedMarkupPercent}
              />
              <NumberField
                label="Total Homeowner Price"
                onChange={(val) => {
                  if (val !== undefined) {
                    observableCeli.updateProposedTotalPriceInCents(val);
                  }
                }}
                readOnly={readOnly || observableCeli.isInternal}
                type="cents"
                value={observableCeli.proposedTotalPriceInCents}
              />
            </FormLines>
          )}
        </Observer>
      </div>
    </div>
  );
}

type FormValue = SaveChangeEventLineItemInput & {
  costCode: CostCode["name"];
  projectItemName: string | undefined;
  tradeCategoryName: string | null | undefined;
};

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  costCode: { type: "value", rules: [required], readOnly: true },
  costType: { type: "value", rules: [required] },
  internalNote: { type: "value" },
  locationId: { type: "value" },
  name: { type: "value", rules: [required] },
  projectItemName: { type: "value", rules: [required], readOnly: true },
  specifications: { type: "value" },
  tradeCategoryName: { type: "value", readOnly: true },
  tradePartnerNote: { type: "value" },
};

function mapCeliToFormState(celi: CeliSpecOverviewTabChangeEventLineItemFragment): FormValue {
  return {
    id: celi.id,
    costCode: celi.projectItem.item.costCode.displayName,
    costType: celi.costType,
    internalNote: celi.internalNote,
    locationId: celi.location?.id,
    name: celi.name,
    projectItemName: celi.projectItem.name,
    specifications: celi.specifications,
    tradeCategoryName: celi.projectItem.item.tradeCategory?.name,
    tradePartnerNote: celi.tradePartnerNote,
  };
}
