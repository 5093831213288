import { BoundSelectField } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { ChangeRequestPriorityDetail, ChangeRequestPriority, InputMaybe } from "src/generated/graphql-types";

type UrgencyFieldProps = {
  field: FieldState<InputMaybe<ChangeRequestPriority>>;
  options: ChangeRequestPriorityDetail[];
};

export function UrgencyField(props: UrgencyFieldProps) {
  const { field, options } = props;

  return (
    <BoundSelectField
      label="Urgency"
      field={field}
      options={options}
      getOptionLabel={getUrgencyLabels}
      getOptionValue={({ code }) => code as ChangeRequestPriority}
      helperText="Urgency will affect due dates for associated ToDos and Approvals."
      compact
    />
  );
}

function getUrgencyLabels(option: ChangeRequestPriorityDetail): string {
  switch (option.code) {
    case ChangeRequestPriority.Low:
      return "Low = 2 weeks";
    case ChangeRequestPriority.Medium:
      return "Medium = 1 week";
    case ChangeRequestPriority.High:
      return "High = 3 business days";
    case ChangeRequestPriority.Urgent:
      return "Urgent = 1 business day";
    case ChangeRequestPriority.Expedited:
      return "Expedited = Same business day";
    default:
      return "";
  }
}
