import {
  InputMaybe,
  InternalUserDetailFragment,
  Maybe,
  SaveWarrantyTicketAvailabilityInput,
  SaveWarrantyTicketInput,
  SaveWarrantyTicketItemInput,
  WarrantyTicketItemStatusesFragment,
  WarrantyTicketItemTypeDetail,
  WarrantyTicketPageDetailsFragment,
  WarrantyTicketTypeDetail,
  useSaveFollowersMutation,
  useSaveWarrantyTicketPageMutation,
  useWarrantyTicketEnumsQuery,
  useWarrantyTicketQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { WarrantyParams, warrantyPaths } from "../routesDef";
import { useParams } from "react-router";
import { AutoSaveIndicator, Css, ScrollableParent } from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { WarrantyListView } from "./WarrantyListView";
import { WarrantySummaryView } from "./WarrantySummaryView";
import { PageHeader } from "../layout/PageHeader";
import { SaveAttachmentModel, attachmentConfig } from "src/components/boundAttachments/BoundAttachments";
import pick from "lodash/pick";

export function WarrantyTicketPage() {
  const { warrantyTicketId } = useParams<WarrantyParams>();
  const query = useWarrantyTicketQuery({ variables: { id: warrantyTicketId } });
  const warrantyEnums = useWarrantyTicketEnumsQuery();
  const {
    warrantyTicketItemStatusesDetail = [],
    warrantyTicketItemTypesDetail = [],
    warrantyTicketTypesDetail = [],
  } = warrantyEnums.data ?? {};

  return queryResult(query, ({ warrantyTicket, internalUsers }) => (
    <WarrantyTicketPageDataView
      warrantyTicket={warrantyTicket}
      warrantyTicketItemStatuses={warrantyTicketItemStatusesDetail}
      warrantyTicketItemTypes={warrantyTicketItemTypesDetail}
      warrantyTicketTypes={warrantyTicketTypesDetail}
      teamMembers={internalUsers}
    />
  ));
}

type WarrantyTicketPageDataViewProps = {
  warrantyTicket: WarrantyTicketPageDetailsFragment;
  warrantyTicketItemStatuses: WarrantyTicketItemStatusesFragment[];
  warrantyTicketItemTypes: WarrantyTicketItemTypeDetail[];
  warrantyTicketTypes: WarrantyTicketTypeDetail[];
  teamMembers: InternalUserDetailFragment[];
};

function WarrantyTicketPageDataView({
  warrantyTicket,
  warrantyTicketItemStatuses,
  warrantyTicketItemTypes,
  warrantyTicketTypes,
  teamMembers,
}: WarrantyTicketPageDataViewProps) {
  const [saveWarrantyTicket] = useSaveWarrantyTicketPageMutation();
  const [saveFollowersMutation] = useSaveFollowersMutation();

  const formState = useFormState<Partial<TicketFormInput>, WarrantyTicketPageDetailsFragment>({
    config: formConfig,
    init: {
      input: warrantyTicket,
      map: (wt) => ({
        id: wt.id,
        title: wt.title,
        type: wt.type?.code,
        homeownerVisible: wt.homeownerVisible,
        urgent: wt.urgent,
        projectContactIds: wt.projectContacts.map((pc) => pc.id),
        followerIds: wt.followers.map((f) => f.id),
        assigneeIds: wt.assignees.map((a) => a.id),
        availabilities: wt.availabilities.map((a) => ({
          id: a.id,
          dayOfWeek: a.dayOfWeek.code,
          timeOfDay: a.timeOfDay.code,
        })),
        attachments: wt.attachments,
        items: wt.items.map((wti) => ({
          id: wti.id,
          status: wti.status.code,
          description: wti.description,
          type: wti.type?.code,
          attachments: wti.attachments,
          homeownerVisible: wti.homeownerVisible,
        })),
      }),
    },
    autoSave: async (os) => {
      const { id, followerIds } = os.changedValue;
      if (followerIds) {
        await saveFollowersMutation({
          variables: { input: { followableId: id!, followerIds, fromTag: false } },
        });
        os.commitChanges();
      } else {
        await saveWarrantyTicket({
          variables: { input: mapFormToInput(os.changedValue) },
        });
        os.commitChanges();
      }
    },
  });

  return (
    <>
      <PageHeader
        xss={Css.px3.$}
        title="Ticket Detail"
        breadcrumb={{ label: "Warranty Requests", href: warrantyPaths.base }}
        left={<AutoSaveIndicator />}
      />
      <div css={Css.df.bgGray100.w100.h100.px3.gap2.oh.$}>
        <ScrollableParent xss={Css.f("0 0 66%").$}>
          <WarrantyListView
            warrantyTicket={warrantyTicket}
            warrantyTicketItemStatuses={warrantyTicketItemStatuses}
            warrantyTicketItemTypes={warrantyTicketItemTypes}
            formState={formState}
          />
        </ScrollableParent>
        <ScrollableParent xss={Css.f("0 0 33%").bgWhite.pt5.relative.$}>
          <WarrantySummaryView
            warrantyTicket={warrantyTicket}
            formState={formState}
            warrantyTicketTypes={warrantyTicketTypes}
            teamMembers={teamMembers}
          />
        </ScrollableParent>
      </div>
    </>
  );
}

function mapFormToInput(form: Partial<TicketFormInput>): SaveWarrantyTicketInput {
  // We need to map the attachments to the correct format for the API
  return {
    ...form,
    attachments: form.attachments?.map((attachment) => ({
      ...attachment,
      asset: pick(attachment.asset, [
        // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
        "contentType",
        "fileName",
        "id",
        "s3Key",
        "sizeInBytes",
        "delete",
      ]),
    })),
    items: form.items?.map((item) => ({
      ...item,
      attachments: item.attachments?.map((attachment) => ({
        ...attachment,
        asset: pick(attachment.asset, [
          // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
          "contentType",
          "fileName",
          "id",
          "s3Key",
          "sizeInBytes",
          "delete",
        ]),
      })),
    })),
  };
}

export type TicketItemFormInput = Pick<
  SaveWarrantyTicketItemInput,
  "id" | "status" | "description" | "type" | "homeownerVisible"
> & {
  attachments: InputMaybe<SaveAttachmentModel[]>;
};

export type TicketAvailabilityFormInput = Pick<SaveWarrantyTicketAvailabilityInput, "id" | "dayOfWeek" | "timeOfDay">;

export type TicketFormInput = Pick<
  SaveWarrantyTicketInput,
  "id" | "title" | "type" | "homeownerVisible" | "projectContactIds" | "urgent" | "assigneeIds"
> & {
  items: TicketItemFormInput[];
  availabilities: TicketAvailabilityFormInput[];
  attachments: InputMaybe<SaveAttachmentModel[]>;
  followerIds: Maybe<string[]>;
};

export const formConfig: ObjectConfig<Partial<TicketFormInput>> = {
  id: { type: "value" },
  title: { type: "value" },
  type: { type: "value" },
  homeownerVisible: { type: "value" },
  urgent: { type: "value" },
  projectContactIds: { type: "value" },
  followerIds: { type: "value" },
  assigneeIds: { type: "value" },
  availabilities: {
    type: "list",
    config: {
      id: { type: "value" },
      dayOfWeek: { type: "value" },
      timeOfDay: { type: "value" },
    },
  },
  attachments: {
    type: "list",
    config: attachmentConfig,
  },
  items: {
    type: "list",
    config: {
      id: { type: "value" },
      status: { type: "value" },
      description: { type: "value" },
      type: { type: "value" },
      homeownerVisible: { type: "value" },
      attachments: {
        type: "list",
        config: attachmentConfig,
      },
    },
  },
};
