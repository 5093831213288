import {
  BoundRichTextField,
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  useRightPane,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BannerNotice } from "src/components";
import {
  Asset,
  AssetInput,
  InternalUser,
  JobLogDetailFragment,
  JobLogImageDetailFragment,
  JobLogNoteDetailFragment,
  JobLogNoteType,
  Maybe,
  Scalars,
  useDeleteJobLogNoteMutation,
  useSaveJobLogImageMutation,
  useSaveJobLogNoteMutation,
} from "src/generated/graphql-types";
import { jobLogNoteFormWidth } from "src/routes/projects/job-logs/JobLogNoteRightPaneContent";
import { JobLogNoteScheduleFlags } from "src/routes/projects/job-logs/JobLogNoteScheduleFlags";
import { JobLogNoteScheduleTasks } from "src/routes/projects/job-logs/JobLogNoteScheduleTasks";
import { createDevelopmentJobLogsUrl, createProjectJobLogsUrl } from "src/RouteUrls";
import { getDisabledReasons, nonEmpty, sortBy } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { JobLogImages, UppyAsset } from "./JobLogImages";
import { jobLogNoteTypes } from "./JobLogsPage";

type JobLogNoteFormProps = {
  jobLog: JobLogDetailFragment;
  jobLogNote?: JobLogNoteDetailFragment;
  currentUser: InternalUser;
  sourceTaskId?: string;
  developmentId?: string;
  refetchJobLogs?: () => void;
};

export function JobLogNoteForm(props: JobLogNoteFormProps) {
  const { jobLog, jobLogNote, currentUser, developmentId, refetchJobLogs, sourceTaskId } = props;
  const isProject = !developmentId;
  const { tradePartner } = jobLogNote || {};
  const { project } = jobLog;
  const isReadOnly = !!jobLogNote?.id && !project.canEditJobLogs.allowed;

  const {
    project: { tradePartners },
  } = jobLog;

  const tid = useTestIds({}, "jobLogNoteForm");

  const { push } = useHistory();
  const { closeRightPane } = useRightPane();
  const { triggerNotice } = useSnackbar();

  const [saveJobLogNote] = useSaveJobLogNoteMutation();
  const [saveJobLogImage] = useSaveJobLogImageMutation();
  const [deleteJobLogNote] = useDeleteJobLogNoteMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialScheduleTasks = jobLogNote
    ? jobLogNote?.scheduleTasks.map((st) => st.id)
    : sourceTaskId
      ? [sourceTaskId]
      : [];

  const form = useFormState({
    config: formConfig,
    init: {
      type: jobLogNote?.type || JobLogNoteType.TradePartnerWork,
      title: jobLogNote?.title || "",
      content: jobLogNote?.content,
      tradePartnerId: tradePartner?.id,
      // this form attr is used to handle the job log note images, if the note doesn't exist we set an empty array
      images: jobLogNote?.jobLogImages || [],
      // This form attr is used to handle the assets for when a note doesn't exist yet, but images want to be added to it
      imageAssets: [],
      scheduleTasks: initialScheduleTasks,
    },
    readOnly: isReadOnly,
  });

  async function onSubmit() {
    setIsSubmitting(true);
    if (form.canSave()) {
      const { data } = await saveJobLogNote({
        variables: {
          input: {
            id: jobLogNote?.id,
            jobLogId: jobLog.id,
            internalUserId: jobLogNote?.id ? undefined : currentUser.id,
            content: form.content.value,
            type: form.type.value as JobLogNoteType,
            tradePartnerId: form.tradePartnerId.value,
            title: form.title.value,
            scheduleTaskIds: form.scheduleTasks.value,
          },
        },
      });
      const jobLogNoteResponse = data?.saveJobLogNote.jobLogNote;

      if (!jobLogNoteResponse) {
        // If the note wasn't able to be saved correctly we go back
        form.revertChanges();
      } else if (!jobLogNote) {
        /*
          If the note is being created in this form, we're going to upload
          the assets to the just created note
        */
        await form.imageAssets.value.asyncForEach((asset) =>
          saveJobLogImage({
            variables: {
              input: {
                /*
                  We set the asset id to undefined because since the note didn't exist
                  the upload wasn't handled by JobLogImages, therefore the id of this asset
                  is a UppyFile id, which is useful for this form to handle image/asset removals
                  but useless and meaning less to BE.

                  This WILL NOT re-create the assets because in order for this to
                  run the note doesn't have to exists, therefore, the assets neither exist
                */
                asset: { ...asset, id: undefined },
                parentId: jobLogNoteResponse.id,
              },
            },
          }),
        );
      }
      push(
        `${isProject ? createProjectJobLogsUrl(jobLog.project.id) : createDevelopmentJobLogsUrl(developmentId)}${
          window.location.search
        }`,
      );
      refetchJobLogs?.();
      triggerNotice({ icon: "success", message: "Note successfully saved" });
      closeRightPane();
    }
  }

  function handleImageUpload(file: UppyFile, asset: UppyAsset) {
    form.images.add({
      id: file.id,
      asset: {
        id: file.id,
        version: "",
        contentType: file.meta.type,
        fileName: file.name,
        downloadUrl: file.meta.downloadUrl as string,
      } as Asset,
      createdAt: new Date(),
    });

    form.imageAssets.add(asset);
  }

  function handleImageRemove(id: string) {
    /*
      We remove the image and asset matching the id.

      This id can be either a UppyFile id (in case the note is being created)
      or a JobLogImage id (if we're just removing images from an existing note)
    */
    const otherIds = (i: any) => i.id !== id;
    form.images.set(form.images.value.filter(otherIds));
    form.imageAssets.set(form.imageAssets.value.filter(otherIds));
  }

  const handleJobLogNoteDelete = async () => {
    if (jobLogNote?.id) {
      setIsSubmitting(true);
      await deleteJobLogNote({
        refetchQueries: ["JobLogs"],
        variables: {
          input: {
            id: jobLogNote.id,
          },
        },
      });
    }
    closeRightPane();
  };

  return (
    <div css={Css.df.fdc.mb2.wPx(jobLogNoteFormWidth).$} data-testid="jobLogNoteForm">
      <Observer>
        {() => (
          <>
            {form.readOnly && (
              <BannerNotice
                message={getDisabledReasons(jobLog.project.canEditJobLogs)}
                icon="error"
                xss={Css.bgYellow200.gray900.$}
              />
            )}
            <h2 css={Css.gray900.smMd.mb1.mt1.$}>Note Title</h2>
            <div css={Css.mb1.$}>
              <BoundTextField label="titleField" labelStyle="hidden" placeholder="Note Title" field={form.title} />
            </div>
            <h2 css={Css.gray900.smMd.mb1.$}>Reason</h2>
            <div css={Css.mb1.$} {...tid.reasonSelect}>
              <BoundSelectField
                labelStyle="hidden"
                placeholder="Select a note type"
                field={form.type}
                options={sortBy(jobLogNoteTypes, (noteType) => noteType.value)}
                getOptionValue={({ value }) => value}
                getOptionLabel={({ label }) => label}
              />
            </div>
            <div css={Css.mb1.$} {...tid.textField}>
              <BoundRichTextField label="" placeholder="Enter text here..." field={form.content} />
            </div>
            <h2 css={Css.gray900.baseMd.mb1.$}>Photos</h2>
            <div css={Css.mb2.$}>
              <Observer>
                {() => (
                  <JobLogImages
                    editingEnabled={!form.readOnly}
                    compact={nonEmpty(form.images.value)}
                    display={"horizontal"}
                    parentId={jobLogNote?.id}
                    images={form.images.value}
                    onUpload={handleImageUpload}
                    onRemove={handleImageRemove}
                  />
                )}
              </Observer>
            </div>
            <h2 css={Css.gray900.baseMd.mb1.$}>Trade Partner</h2>
            <div css={Css.df.fdc.gap2.mb2.$} {...tid.tradePartner}>
              <BoundSelectField
                label=""
                aria-label="trade-partner"
                placeholder="None selected"
                field={form.tradePartnerId}
                options={sortBy(tradePartners, ({ name }) => name)}
              />
            </div>
            <div css={Css.mb5.$}>
              <JobLogNoteScheduleFlags scheduleTaskIds={form.scheduleTasks.value ?? []} isReadOnly={form.readOnly} />
            </div>
            <div css={Css.mb2.$}>
              <JobLogNoteScheduleTasks jobLog={jobLog} field={form.scheduleTasks} isReadOnly={form.readOnly} />
            </div>
            <div>
              <div css={Css.df.jcfe.gap1.mt2.$}>
                <Button
                  label="Delete Note"
                  variant="tertiaryDanger"
                  icon="trash"
                  onClick={handleJobLogNoteDelete}
                  disabled={isReadOnly || isSubmitting}
                />
                {/* `onClick` is required for buttons, but because this button is of `type=submit` within a form then it doesn't need an onclick handler. So make `onClick` a no-op. */}
                <Button
                  label={jobLogNote ? "Save note" : "Add note"}
                  type="submit"
                  disabled={isReadOnly || !form.valid || isSubmitting || !form.dirty}
                  onClick={async () => await onSubmit()}
                />
              </div>
            </div>
          </>
        )}
      </Observer>
    </div>
  );
}

type FormValue = {
  type?: Maybe<string>;
  content?: Maybe<string>;
  flags?: Maybe<Array<Scalars["ID"]>>;
  tradePartnerId?: Maybe<Scalars["ID"]>;
  images?: Maybe<Array<JobLogImageDetailFragment>>;
  imageAssets?: Maybe<AssetInput>[];
  scheduleTasks?: Maybe<Array<string>>;
  title?: Maybe<string>;
};

const formConfig: ObjectConfig<FormValue> = {
  type: { type: "value", rules: [required] },
  content: { type: "value", rules: [required] },
  flags: { type: "value" },
  tradePartnerId: { type: "value" },
  images: {
    type: "list",
    config: {
      id: { type: "value" },
      createdAt: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          fileName: { type: "value" },
          contentType: { type: "value" },
          attachmentUrl: { type: "value" },
          createdAt: { type: "value" },
          downloadUrl: { type: "value" },
          version: { type: "value" },
        },
      },
    },
  },
  imageAssets: {
    type: "list",
    config: {
      id: { type: "value" },
      s3Key: { type: "value" },
      contentType: { type: "value" },
      fileName: { type: "value" },
    },
  },
  scheduleTasks: { type: "value" },
  title: { type: "value" },
};
