import {
  Accessibility,
  Activity,
  AirVent,
  Airplay,
  AlarmClock,
  AlarmSmoke,
  Album,
  AlignCenter,
  AlignCenterHorizontal,
  AlignCenterVertical,
  AlignVerticalDistributeCenter,
  AlignVerticalDistributeEnd,
  AlignVerticalDistributeStart,
  AlignVerticalJustifyCenter,
  AlignVerticalJustifyEnd,
  AlignVerticalJustifyStart,
  AlignVerticalSpaceAround,
  AlignVerticalSpaceBetween,
  Ambulance,
  Anchor,
  Antenna,
  Anvil,
  Apple,
  Archive,
  ArchiveX,
  Armchair,
  ArrowBigDown,
  ArrowBigUp,
  ArrowLeftRight,
  ArrowsUpFromLine,
  Atom,
  AudioLines,
  AudioWaveform,
  Award,
  Axe,
  Axis3d,
  Baby,
  Backpack,
  Badge,
  BadgeCent,
  BadgeCheck,
  BadgeDollarSign,
  BadgeHelp,
  BadgeMinus,
  BadgePercent,
  BadgePlus,
  BadgeX,
  BaggageClaim,
  Banana,
  Banknote,
  Barcode,
  Bath,
  Battery,
  BatteryCharging,
  Beaker,
  Bean,
  Bed,
  BedDouble,
  BedSingle,
  Beef,
  Beer,
  Bell,
  BellElectric,
  Bike,
  Binary,
  Biohazard,
  Bird,
  Blend,
  Blinds,
  Blocks,
  Bluetooth,
  Bolt,
  Bomb,
  Bone,
  Book,
  BookA,
  BookCheck,
  BookUser,
  Bookmark,
  BookmarkCheck,
  BoomBox,
  Bot,
  Box,
  BoxSelect,
  Boxes,
  Brain,
  BrainCircuit,
  BrickWall,
  Briefcase,
  BriefcaseMedical,
  BringToFront,
  Brush,
  Bug,
  Building,
  Bus,
  Cable,
  BusFront,
  CableCar,
  Cake,
  CakeSlice,
  Calculator,
  CalendarCheck,
  Camera,
  Candy,
  CandyCane,
  Captions,
  Car,
  CarFront,
  Caravan,
  Carrot,
  CassetteTape,
  Castle,
  Cat,
  Cctv,
  ChefHat,
  Cherry,
  Church,
  Cigarette,
  CigaretteOff,
  CircleArrowDown,
  CircleArrowLeft,
  CircleArrowRight,
  CircleDashed,
  CircleDotDashed,
  CircleEqual,
  CircleGauge,
  CircleOff,
  CircleStop,
  CircuitBoard,
  Citrus,
  Clapperboard,
  ClipboardCheck,
  ClipboardPenLine,
  CloudDrizzle,
  CloudLightning,
  CloudSun,
  Clover,
  Club,
  Coffee,
  Cog,
  Codesandbox,
  Coins,
  Columns4,
  Combine,
  Command,
  Compass,
  Component,
  Computer,
  ConciergeBell,
  Cone,
  Construction,
  Contact,
  Container,
  Contrast,
  Cookie,
  CookingPot,
  Cpu,
  CreditCard,
  Croissant,
  Cross,
  Crosshair,
  Crown,
  Cuboid,
  CupSoda,
  Cylinder,
  Dessert,
  Diameter,
  Dice5,
  Dices,
  Disc2,
  Disc3,
  DiscAlbum,
  Dna,
  Divide,
  Dog,
  Donut,
  DoorClosed,
  DoorOpen,
  DraftingCompass,
  Drill,
  Droplet,
  Drum,
  Drumstick,
  Dumbbell,
  Ear,
  EarOff,
  Earth,
  Eclipse,
  Egg,
  EggFried,
  Eraser,
  Fingerprint,
  FireExtinguisher,
  Fish,
  Flag,
  Flame,
  FlameKindling,
  Flashlight,
  FlaskConical,
  FlaskRound,
  Flower,
  Flower2,
  Footprints,
  Forklift,
  Fuel,
  Gamepad,
  Gamepad2,
  Gauge,
  Gavel,
  Gem,
  Ghost,
  Gift,
  GlassWater,
  Glasses,
  Globe,
  Goal,
  Grape,
  Grid3x3,
  Grid2x2,
  Guitar,
  Ham,
  Hand,
  HandCoins,
  Handshake,
  HardHat,
  Haze,
  Headphones,
  Heart,
  HeartPulse,
  Heater,
  Home,
  Hop,
  Hospital,
  Hourglass,
  IceCreamBowl,
  IceCreamCone,
  Image,
  Inbox,
  Infinity,
  Joystick,
  Key,
  KeyRound,
  KeySquare,
  Keyboard,
  KeyboardMusic,
  Lamp,
  LampCeiling,
  LampDesk,
  LampFloor,
  LampWallDown,
  LampWallUp,
  LandPlot,
  Landmark,
  Laptop,
  LaptopMinimal,
  Lasso,
  Laugh,
  LayoutDashboard,
  Leaf,
  LeafyGreen,
  Library,
  LibraryBig,
  LifeBuoy,
  Lightbulb,
  Loader,
  LocateFixed,
  LockKeyhole,
  LockKeyholeOpen,
  Lollipop,
  Luggage,
  Magnet,
  Mail,
  Map,
  MapPin,
  Martini,
  Medal,
  Megaphone,
  MemoryStick,
  MessageCircleHeart,
  MessageCircleMore,
  Mic,
  MicVocal,
  Microscope,
  Microwave,
  Milestone,
  Milk,
  MonitorCheck,
  Monitor,
  MonitorPlay,
  Moon,
  Mountain,
  MountainSnow,
  Mouse,
  Music,
  Music2,
  Music3,
  Music4,
  Nfc,
  Notebook,
  Nut,
  Octagon,
  Orbit,
  Package,
  Package2,
  PackageOpen,
  PaintBucket,
  PaintRoller,
  Paintbrush,
  Paintbrush2,
  Palette,
  PanelBottomDashed,
  Paperclip,
  ParkingMeter,
  PartyPopper,
  PawPrint,
  PenTool,
  PencilRuler,
  Pentagon,
  PersonStanding,
  Percent,
  Phone,
  PhoneCall,
  Piano,
  Pickaxe,
  PieChart,
  PiggyBank,
  Pill,
  Pin,
  Pipette,
  Pizza,
  Plane,
  Plug,
  PlugZap,
  PlugZap2,
  Pocket,
  PocketKnife,
  Pointer,
  Popcorn,
  Popsicle,
  Power,
  Printer,
  Projector,
  Proportions,
  Puzzle,
  Pyramid,
  QrCode,
  Rabbit,
  Radar,
  Radiation,
  Radical,
  Radio,
  RadioReceiver,
  RadioTower,
  Radius,
  RailSymbol,
  Rainbow,
  Rat,
  Ratio,
  Receipt,
  ReceiptCent,
  RectangleEllipsis,
  RectangleHorizontal,
  RectangleVertical,
  Recycle,
  RefreshCcwDot,
  Refrigerator,
  Ribbon,
  Rocket,
  RockingChair,
  RollerCoaster,
  Rotate3d,
  Route,
  Router,
  Rows2,
  Rows3,
  Ruler,
  Rss,
  Sailboat,
  Salad,
  Sandwich,
  Satellite,
  SatelliteDish,
  Save,
  Scale,
  Scale3d,
  School,
  Scissors,
  Scroll,
  ScrollText,
  SendToBack,
  Shapes,
  Shell,
  Shield,
  ShieldCheck,
  ShieldHalf,
  Ship,
  ShipWheel,
  Shirt,
  ShoppingBag,
  ShoppingBasket,
  ShoppingCart,
  Shovel,
  ShowerHead,
  Shrub,
  Shuffle,
  Signpost,
  SignpostBig,
  Siren,
  Skull,
  Slice,
  Slash,
  SlidersHorizontal,
  SlidersVertical,
  Smartphone,
  SmartphoneCharging,
  SmartphoneNfc,
  Smile,
  Snail,
  Snowflake,
  Sofa,
  Soup,
  Spade,
  Sparkle,
  Sparkles,
  Speaker,
  Speech,
  SprayCan,
  Sprout,
  SquareStack,
  Squirrel,
  Stamp,
  Star,
  Stethoscope,
  Store,
  Sun,
  SunDim,
  SunMedium,
  Sunrise,
  Sunset,
  SwatchBook,
  SwitchCamera,
  Sword,
  Swords,
  Syringe,
  Tablet,
  Tablets,
  Tag,
  Tags,
  Tally5,
  Target,
  Telescope,
  Tent,
  TentTree,
  TestTube,
  TestTubeDiagonal,
  Theater,
  Thermometer,
  ThermometerSnowflake,
  ThermometerSun,
  Ticket,
  Timer,
  Tornado,
  TowerControl,
  Tractor,
  TrafficCone,
  TrainFront,
  TrainFrontTunnel,
  TrainTrack,
  TramFront,
  TreeDeciduous,
  TreePalm,
  TreePine,
  Trees,
  Triangle,
  TriangleRight,
  Trophy,
  Truck,
  Turtle,
  Tv,
  Umbrella,
  UmbrellaOff,
  Ungroup,
  University,
  Unplug,
  Utensils,
  UtensilsCrossed,
  UtilityPole,
  VenetianMask,
  Video,
  Videotape,
  Wallet,
  WalletMinimal,
  Wallpaper,
  WandSparkles,
  Warehouse,
  WashingMachine,
  Watch,
  Waves,
  Waypoints,
  Webcam,
  Weight,
  Wheat,
  Wifi,
  Wind,
  Wine,
  WineOff,
  Worm,
  Wrench,
  Zap,
  LucideIcon,
} from "lucide-react";

type DesignUpgradeIconList = Record<string, LucideIcon>;
export type IconName = keyof DesignUpgradeIconList;
/**
 * Lucide Icons has around ~1400 total icons in their library.
 * To avoid loading all of them in the client bundle, we've created a subset of icons specifically for Design Upgrades.
 */
export const DesignUpgradeIcons: DesignUpgradeIconList = {
  Accessibility,
  Activity,
  AirVent,
  Airplay,
  AlarmClock,
  AlarmSmoke,
  Album,
  AlignCenter,
  AlignCenterHorizontal,
  AlignCenterVertical,
  AlignVerticalDistributeCenter,
  AlignVerticalDistributeEnd,
  AlignVerticalDistributeStart,
  AlignVerticalJustifyCenter,
  AlignVerticalJustifyEnd,
  AlignVerticalJustifyStart,
  AlignVerticalSpaceAround,
  AlignVerticalSpaceBetween,
  Ambulance,
  Anchor,
  Antenna,
  Anvil,
  Apple,
  Archive,
  ArchiveX,
  Armchair,
  ArrowBigDown,
  ArrowBigUp,
  ArrowLeftRight,
  ArrowsUpFromLine,
  Atom,
  AudioLines,
  AudioWaveform,
  Award,
  Axe,
  Axis3d,
  Baby,
  Backpack,
  Badge,
  BadgeCent,
  BadgeCheck,
  BadgeDollarSign,
  BadgeHelp,
  BadgeMinus,
  BadgePercent,
  BadgePlus,
  BadgeX,
  BaggageClaim,
  Banana,
  Banknote,
  Barcode,
  Bath,
  Battery,
  BatteryCharging,
  Beaker,
  Bean,
  Bed,
  BedDouble,
  BedSingle,
  Beef,
  Beer,
  Bell,
  BellElectric,
  Bike,
  Binary,
  Biohazard,
  Bird,
  Blend,
  Blinds,
  Blocks,
  Bluetooth,
  Bolt,
  Bomb,
  Bone,
  Book,
  BookA,
  BookCheck,
  BookUser,
  Bookmark,
  BookmarkCheck,
  BoomBox,
  Bot,
  Box,
  BoxSelect,
  Boxes,
  Brain,
  BrainCircuit,
  BrickWall,
  Briefcase,
  BriefcaseMedical,
  BringToFront,
  Brush,
  Bug,
  Building,
  Bus,
  Cable,
  BusFront,
  CableCar,
  Cake,
  CakeSlice,
  Calculator,
  CalendarCheck,
  Camera,
  Candy,
  CandyCane,
  Captions,
  Car,
  CarFront,
  Caravan,
  Carrot,
  CassetteTape,
  Castle,
  Cat,
  Cctv,
  ChefHat,
  Cherry,
  Church,
  Cigarette,
  CigaretteOff,
  CircleArrowDown,
  CircleArrowLeft,
  CircleArrowRight,
  CircleDashed,
  CircleDotDashed,
  CircleEqual,
  CircleGauge,
  CircleOff,
  CircleStop,
  CircuitBoard,
  Citrus,
  Clapperboard,
  ClipboardCheck,
  ClipboardPenLine,
  CloudDrizzle,
  CloudLightning,
  CloudSun,
  Clover,
  Club,
  Coffee,
  Cog,
  Codesandbox,
  Coins,
  Columns4,
  Combine,
  Command,
  Compass,
  Component,
  Computer,
  ConciergeBell,
  Cone,
  Construction,
  Contact,
  Container,
  Contrast,
  Cookie,
  CookingPot,
  Cpu,
  CreditCard,
  Croissant,
  Cross,
  Crosshair,
  Crown,
  Cuboid,
  CupSoda,
  Cylinder,
  Dessert,
  Diameter,
  Dice5,
  Dices,
  Disc2,
  Disc3,
  DiscAlbum,
  Dna,
  Divide,
  Dog,
  Donut,
  DoorClosed,
  DoorOpen,
  DraftingCompass,
  Drill,
  Droplet,
  Drum,
  Drumstick,
  Dumbbell,
  Ear,
  EarOff,
  Earth,
  Eclipse,
  Egg,
  EggFried,
  Eraser,
  Fingerprint,
  FireExtinguisher,
  Fish,
  Flag,
  Flame,
  FlameKindling,
  Flashlight,
  FlaskConical,
  FlaskRound,
  Flower,
  Flower2,
  Footprints,
  Forklift,
  Fuel,
  Gamepad,
  Gamepad2,
  Gauge,
  Gavel,
  Gem,
  Ghost,
  Gift,
  GlassWater,
  Glasses,
  Globe,
  Goal,
  Grape,
  Grid3x3,
  Grid2x2,
  Guitar,
  Ham,
  Hand,
  HandCoins,
  Handshake,
  HardHat,
  Haze,
  Headphones,
  Heart,
  HeartPulse,
  Heater,
  Home,
  Hop,
  Hospital,
  Hourglass,
  IceCreamBowl,
  IceCreamCone,
  Image,
  Inbox,
  Infinity,
  Joystick,
  Key,
  KeyRound,
  KeySquare,
  Keyboard,
  KeyboardMusic,
  Lamp,
  LampCeiling,
  LampDesk,
  LampFloor,
  LampWallDown,
  LampWallUp,
  LandPlot,
  Landmark,
  Laptop,
  LaptopMinimal,
  Lasso,
  Laugh,
  LayoutDashboard,
  Leaf,
  LeafyGreen,
  Library,
  LibraryBig,
  LifeBuoy,
  Lightbulb,
  Loader,
  LocateFixed,
  LockKeyhole,
  LockKeyholeOpen,
  Lollipop,
  Luggage,
  Magnet,
  Mail,
  Map,
  MapPin,
  Martini,
  Medal,
  Megaphone,
  MemoryStick,
  MessageCircleHeart,
  MessageCircleMore,
  Mic,
  MicVocal,
  Microscope,
  Microwave,
  Milestone,
  Milk,
  MonitorCheck,
  Monitor,
  MonitorPlay,
  Moon,
  Mountain,
  MountainSnow,
  Mouse,
  Music,
  Music2,
  Music3,
  Music4,
  Nfc,
  Notebook,
  Nut,
  Octagon,
  Orbit,
  Package,
  Package2,
  PackageOpen,
  PaintBucket,
  PaintRoller,
  Paintbrush,
  Paintbrush2,
  Palette,
  PanelBottomDashed,
  Paperclip,
  ParkingMeter,
  PartyPopper,
  PawPrint,
  PenTool,
  PencilRuler,
  Pentagon,
  PersonStanding,
  Percent,
  Phone,
  PhoneCall,
  Piano,
  Pickaxe,
  PieChart,
  PiggyBank,
  Pill,
  Pin,
  Pipette,
  Pizza,
  Plane,
  Plug,
  PlugZap,
  PlugZap2,
  Pocket,
  PocketKnife,
  Pointer,
  Popcorn,
  Popsicle,
  Power,
  Printer,
  Projector,
  Proportions,
  Puzzle,
  Pyramid,
  QrCode,
  Rabbit,
  Radar,
  Radiation,
  Radical,
  Radio,
  RadioReceiver,
  RadioTower,
  Radius,
  RailSymbol,
  Rainbow,
  Rat,
  Ratio,
  Receipt,
  ReceiptCent,
  RectangleEllipsis,
  RectangleHorizontal,
  RectangleVertical,
  Recycle,
  RefreshCcwDot,
  Refrigerator,
  Ribbon,
  Rocket,
  RockingChair,
  RollerCoaster,
  Rotate3d,
  Route,
  Router,
  Rows2,
  Rows3,
  Ruler,
  Rss,
  Sailboat,
  Salad,
  Sandwich,
  Satellite,
  SatelliteDish,
  Save,
  Scale,
  Scale3d,
  School,
  Scissors,
  Scroll,
  ScrollText,
  SendToBack,
  Shapes,
  Shell,
  Shield,
  ShieldCheck,
  ShieldHalf,
  Ship,
  ShipWheel,
  Shirt,
  ShoppingBag,
  ShoppingBasket,
  ShoppingCart,
  Shovel,
  ShowerHead,
  Shrub,
  Shuffle,
  Signpost,
  SignpostBig,
  Siren,
  Skull,
  Slice,
  Slash,
  SlidersHorizontal,
  SlidersVertical,
  Smartphone,
  SmartphoneCharging,
  SmartphoneNfc,
  Smile,
  Snail,
  Snowflake,
  Sofa,
  Soup,
  Spade,
  Sparkle,
  Sparkles,
  Speaker,
  Speech,
  SprayCan,
  Sprout,
  SquareStack,
  Squirrel,
  Stamp,
  Star,
  Stethoscope,
  Store,
  Sun,
  SunDim,
  SunMedium,
  Sunrise,
  Sunset,
  SwatchBook,
  SwitchCamera,
  Sword,
  Swords,
  Syringe,
  Tablet,
  Tablets,
  Tag,
  Tags,
  Tally5,
  Target,
  Telescope,
  Tent,
  TentTree,
  TestTube,
  TestTubeDiagonal,
  Theater,
  Thermometer,
  ThermometerSnowflake,
  ThermometerSun,
  Ticket,
  Timer,
  Tornado,
  TowerControl,
  Tractor,
  TrafficCone,
  TrainFront,
  TrainFrontTunnel,
  TrainTrack,
  TramFront,
  TreeDeciduous,
  TreePalm,
  TreePine,
  Trees,
  Triangle,
  TriangleRight,
  Trophy,
  Truck,
  Turtle,
  Tv,
  Umbrella,
  UmbrellaOff,
  Ungroup,
  University,
  Unplug,
  Utensils,
  UtensilsCrossed,
  UtilityPole,
  VenetianMask,
  Video,
  Videotape,
  Wallet,
  WalletMinimal,
  Wallpaper,
  WandSparkles,
  Warehouse,
  WashingMachine,
  Watch,
  Waves,
  Waypoints,
  Webcam,
  Weight,
  Wheat,
  Wifi,
  Wind,
  Wine,
  WineOff,
  Worm,
  Wrench,
  Zap,
};
