import { ApolloCache, ApolloClient, DefaultContext, MutationFunctionOptions, useApolloClient } from "@apollo/client";
import { FetchResult } from "@apollo/client/link/core";
import { Css, useToast, UseToastProps } from "@homebound/beam";
import { useCallback, useRef } from "react";
import {
  Exact,
  ProjectFeature,
  SaveScheduleTaskInput,
  SaveScheduleTasksInput,
  SaveScheduleTasksMutation,
  ScheduleTask,
  TaskScheduleDetailsFragment,
  TaskStatus,
  useSaveScheduleTasksMutation,
} from "src/generated/graphql-types";
import { useDelayFlagModal } from "src/hooks/useDelayFlagModal";
import { DeepPartial, formatList } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { cacheKeyFromId } from "./table/utils";
import { useProjectContext } from "../context/ProjectContext";
import { useTaskBillContext } from "./contexts/TaskBillModalContext";
import { Link } from "react-router-dom";
import { getBillLabel } from "src/components/detailPane/bill/utils";

/** Eventually we may use this to serialize other mutations, like deleting a phase/sub-phase/task */
export type OperationInput = SaveScheduleTaskInput & {
  endDate?: DateOnly | null | undefined;
};
type OperationResult = DeepPartial<ScheduleTask>;

export type Operation = { input: OperationInput; result?: OperationResult };
type FailedOperations = { operations: Operation[] };

export type OperationStatus = {
  pendingOperations: Operation[];
  completedOperations: Operation[];
  failedOperations: FailedOperations[];
  operationInProgress: boolean;
};
/**
 * Serializes saveScheduleTasks mutation calls (and eventually other) to avoid concurrent
 * changes to the schedule from the same client.
 *
 * In the future, this could also serve as the basis for some other features which have been discussed:
 *   - Providing toasts that saves are in-progress/completed
 *   - Providing a mechanism/UX to retry the failedOperations
 *   - Immediately update the cache to reflect the expected results on of in-flight operations
 */
export function useScheduleMutationManager(refetchAvailableGlobalPhases: Function, canAddDelays: boolean = false) {
  const { onCompleted } = useDelayFlagModal();
  const { features } = useProjectContext();
  const showClickToPayFlag = features.includes(ProjectFeature.ClickToPay);
  const [saveTask] = useSaveScheduleTasksMutation({ onCompleted: canAddDelays ? onCompleted : undefined });
  const client = useApolloClient();
  const { showToast } = useToast();
  const { openTaskBillModal: maybeOpenTaskBillModal, contextSavedTaskResult } = useTaskBillContext();

  const operationStatus = useRef<OperationStatus>({
    pendingOperations: [],
    completedOperations: [],
    failedOperations: [],
    operationInProgress: false,
  });

  const enqueueOperation = useCallback(
    (operation: OperationInput) => {
      operationStatus.current.pendingOperations.push({ input: operation });
      void executePendingOperations(
        operationStatus.current,
        client,
        onOperationFailure,
        showToast,
        refetchAvailableGlobalPhases,
        saveTask,
        showClickToPayFlag,
        maybeOpenTaskBillModal,
        contextSavedTaskResult,
      );
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, showToast, refetchAvailableGlobalPhases, saveTask, maybeOpenTaskBillModal],
  );

  return { enqueueOperation };
}

export async function executePendingOperations(
  operationStatus: OperationStatus,
  client: ApolloClient<any>,
  onFailure: typeof onOperationFailure,
  showToast: UseToastProps["showToast"],
  refetchAvailableGlobalPhases: Function,
  saveTask: (
    options?: MutationFunctionOptions<
      SaveScheduleTasksMutation,
      Exact<{ input: SaveScheduleTasksInput }>,
      DefaultContext,
      any
    >,
  ) => Promise<FetchResult<SaveScheduleTasksMutation>>,
  showClickToPayFlag: boolean,
  maybeOpenTaskBillModal: (modalOpts: {
    scheduleTaskId: string;
    handleTaskSave: () => Promise<FetchResult<SaveScheduleTasksMutation>>;
    tradePartnerId?: string;
  }) => void,
  contextSavedTaskResult: FetchResult<SaveScheduleTasksMutation>,
): Promise<void> {
  console.debug("Operation Status", operationStatus);

  const { pendingOperations, completedOperations } = operationStatus;
  if (operationStatus.operationInProgress === true || pendingOperations.length === 0) {
    // At the end of this method, we call executePendingOperations again to ensure any operations
    // which hit this clause will be processed as soon as the operation in progress completes.
    return;
  }

  operationStatus.operationInProgress = true;
  const currentOperations = [...pendingOperations];
  pendingOperations.length = 0;

  // update the cache if the input is changing dates
  currentOperations.forEach((po) => {
    maybeUpdateCache(client, po.input);
  });
  try {
    const tasks = currentOperations.map((po) => mapOperationInputToMutationInput(po));
    let result: FetchResult<SaveScheduleTasksMutation> = {};

    // Safe to use the `first` task since the c2p modal logic
    // only cares about single task operations, where the task is updated to complete
    const task = tasks.first;
    const enableC2P = showClickToPayFlag && task?.status === TaskStatus.Complete;
    if (enableC2P) {
      if (!task.id) fail("No task found");
      maybeOpenTaskBillModal({
        scheduleTaskId: task.id,
        // `handleTaskSave` is called if the modal is skipped or if the task has no unbilled items
        // otherwise its called after the modal is submitted
        handleTaskSave: () => saveTask({ variables: { input: { tasks } } }),
      });
    }

    result =
      // We handle the `saveTask` directly in the task bill modal to ensure that the modal form is completed, _before_ users are allowed to update task status to `complete`
      // This ensures that we do not miss billing trades when tasks are completed
      // If the task is marked complete and we are C2P enabled, then we want to use the taskBill context provider's save task result
      // Otherwise we've bypassed the C2P modal and can directly save the task here and use the result
      enableC2P ? contextSavedTaskResult : await saveTask({ variables: { input: { tasks } } });

    if (result.data) {
      // Trigger notifications for generated deposit bills
      result.data.saveScheduleTasks.generatedDepositBills?.forEach((b) =>
        showToast({
          type: "success",
          message: (
            <div css={Css.df.fdr.gap1.$}>
              <Link target="_blank" to={b.blueprintUrl.path}>
                {getBillLabel(b)}
              </Link>
              {`billed for ${formatList(
                b.parents.map(
                  (parent) =>
                    `${parent.__typename === "CommitmentChangeOrder" ? `CO` : `PO`}#${parent.accountingNumber}`,
                ),
              )}`}
            </div>
          ),
        }),
      );

      maybeSetOperationResults(currentOperations, result.data?.saveScheduleTasks.scheduleTasks);
      currentOperations.forEach((op) => {
        maybeAlertTaskMoved(op, showToast);
        maybeRefetchAvailableGlobalPhases(op, refetchAvailableGlobalPhases);
      });
      maybeAddNewTasksToCache(client.cache, currentOperations);
      maybeRemoveTasksFromCache(client.cache, currentOperations);
      completedOperations.push(...currentOperations);
      // Notify the user of any generated invoices from completed tasks
      const invoices = result.data.saveScheduleTasks.generatedInvoices;
      if (invoices.nonEmpty) {
        showToast({
          type: "success",
          message: `A new invoice was generated for: ${formatList(invoices.map((i) => i.title))}`,
        });
      }
    } else {
      onFailure(result, operationStatus, currentOperations);
    }
  } catch (e) {
    onFailure(e as Error, operationStatus, currentOperations);
  } finally {
    operationStatus.operationInProgress = false;
  }

  return executePendingOperations(
    operationStatus,
    client,
    onFailure,
    showToast,
    refetchAvailableGlobalPhases,
    saveTask,
    showClickToPayFlag,
    maybeOpenTaskBillModal,
    contextSavedTaskResult,
  );
}

/** On failure, we want to record which operations failed */
export function onOperationFailure(
  result: Error | FetchResult,
  operationStatus: OperationStatus,
  currentOperations: Operation[],
) {
  operationStatus.failedOperations.push({ operations: currentOperations });
}
/**
 * Match results to their associated operation so that further processing can compare operations/results as needed.
 *
 * The main caveat here is that delete requests do not have a corresponding entry in the `results`.
 * So if a delete request is batched with an update to another task, then we need to skip that operation
 * when matching up the results.
 *
 * NOTE: This function relies on the fact that the results are returned in the same order as the requests.
 * We could do matching based on IDs in the future, should this change.
 */
export function maybeSetOperationResults(operations: Operation[], results: DeepPartial<ScheduleTask>[]) {
  let resultIndex = 0;
  operations.forEach((op) => {
    if (op.input.delete) {
      return;
    }
    op.result = results[resultIndex++];
  });
}

/**
 * Show a success message when a task is moved to a new SchedulePhase or ScheduleSubPhase, so that users will realize
 * why the task they are editing has disappeared from view.
 */
export function maybeAlertTaskMoved(operation: Operation, showToast: UseToastProps["showToast"]) {
  const { input, result } = operation;
  if (result && !isNewTask(operation)) {
    const { schedulePhase, scheduleSubPhase } = result;
    // if the schedulePhase exists, and has changed, then show a success message
    if (schedulePhase && input.schedulePhase) {
      showToast({
        type: "success",
        message: input.schedulePhase ? `Task successfully moved to ${schedulePhase.name}` : "",
      });
    }
    // if the scheduleSubPhase exists, and has changed, then show a success message
    else if (scheduleSubPhase && input.scheduleSubPhase) {
      showToast({
        type: "success",
        message: input.scheduleSubPhase ? `Task successfully moved to ${scheduleSubPhase.name}` : "",
      });
    } else if (input.hasOwnProperty("scheduleSubPhase") && !input.scheduleSubPhase) {
      showToast({
        type: "success",
        message: `Task successfully moved to phase level`,
      });
    }
  }
}

/**
 * Maybe refetches the available globalPhases, if a task phase has been changed.
 * This allows for phases added via a tasks Phase drop down to appear immediately,
 * without needing to refresh the page.
 *
 * TODO: This could be updated to directly mutate the cache in the event of a new Phase being added as
 * part of the saveTasks mutation. However, that will require a fair bit more work...
 */
export function maybeRefetchAvailableGlobalPhases(operation: Operation, refetchAvailableGlobalPhases: Function) {
  if (!isNewTask(operation) && operation.input.schedulePhase) refetchAvailableGlobalPhases();
}

function isNewTask(operation: Operation) {
  return !operation.input.id;
}

function isDeletedTask(operation: Operation) {
  return operation.input.delete;
}

export function maybeAddNewTasksToCache(cache: ApolloCache<any>, operations: Operation[]) {
  const newTaskRefs = operations
    // Find all operations which were creating new tasks
    .filter((op) => isNewTask(op) && op.result)
    // Convert the returned IDs to cache keys
    .map((op) => [op, cache.identify(op.result!)])
    // Filter out any which couldn't be identified for some reason...
    .filter(([op, cacheKey]) => {
      if (cacheKey) {
        return true;
      }
      console.warn("Failed to identify new task cache key", op);
      return false;
    })
    // Map the cache keys to references
    .map(([, cacheKey]) => ({ __ref: cacheKey }));

  if (newTaskRefs.length > 0) {
    // Make sure the task appears in `tasks` query used for the table, filters, and dependencies, even though it may not match
    // the currently applied filters. This is intentional so that the user can see the task they just created, otherwise
    // they might think that it is broken. This may change in the future if we get a UX for saying `hey, that task you just
    // added is filtered from view`
    cache.modify({
      fields: {
        scheduleTasks(existingTasks = []) {
          return [...existingTasks, ...newTaskRefs];
        },
      },
    });
  }
}

export function maybeRemoveTasksFromCache(cache: ApolloCache<any>, operations: Operation[]) {
  const deletedTaskOp = operations
    // Find all operations which were deleting tasks
    .filter((op) => isDeletedTask(op));

  if (deletedTaskOp.length > 0) {
    cache.modify({
      fields: {
        scheduleTasks(existingTasks = []) {
          return existingTasks.filter(
            (task: any) => !deletedTaskOp.some(({ input }) => cacheKeyFromId(input.id!) === task.__ref),
          );
        },
      },
    });
  }
}

export function maybeUpdateCache(client: ApolloClient<any>, operation: OperationInput) {
  const { durationInDays, startDate, endDate } = operation;
  // if the input date has changed, then update the cache
  if (isUpdatedDate(operation) && operation.id) {
    client.cache.modify({
      id: cacheKeyFromId(operation.id),
      fields: {
        interval: (cachedInterval) => {
          return {
            ...cachedInterval,
            startDate,
            endDate,
            durationInDays,
          };
        },
      },
    });
  }
}

// helper to determine if the input date has been updated
function isUpdatedDate(operation: OperationInput) {
  return operation.endDate || operation.startDate || operation.durationInDays;
}

function mapOperationInputToMutationInput(operation: Operation) {
  // filtering out endDate as the backend does not support endDate
  const { endDate, ...otherInputs } = operation.input;
  return otherInputs;
}

export type TaskWithDelayDependantFields = Pick<
  TaskScheduleDetailsFragment,
  "id" | "predecessorDependencies" | "successorDependencies" | "interval" | "isCriticalPath" | "globalTask"
>;

/**
 * This function determines whether or not we should check for impacted milestones / crtical path tasks
 * after the mutation completes. It should return true in the follow scenarios:
 *
 *
 * - The task is new
 *
 * - The task is old
 *   AND IS a critical path or milestone
 *     AND its start date is later than its original
 *     OR its end date is later than its original
 *     OR its duration is greater than its original
 *     OR it has a predecessor or successor added
 *   OR is not critical path / milestone
 *     AND it has a successor change
 */
