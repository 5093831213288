import { ObjectState } from "@homebound/form-state";
import { ProductOfferingConfigForm } from "../AddProductOfferingConfigPage";
import { AddProductOfferingConfig_ReadyPlanOptionsFragment } from "src/generated/graphql-types";
import { GroupedOptionsProps } from "./OptionsByTypeSection";
import { Checkbox, RadioGroupField } from "@homebound/beam";

type OptionSelectProps = Omit<GroupedOptionsProps, "groupedRpos"> & {
  rpo: AddProductOfferingConfig_ReadyPlanOptionsFragment;
  disabled: boolean;
  formState: ObjectState<ProductOfferingConfigForm>;
};

export function OptionSelect(props: OptionSelectProps) {
  const { disabled, rpo, formState, recomputeCosts } = props;
  return (
    <div>
      {rpo.optionGroup.isMultiOptionGroup ? (
        <RadioGroupField
          label=""
          aria-label={`rpoId-${rpo.id}`}
          disabled={disabled}
          options={[{ label: "", value: rpo.id }]}
          onChange={async (val) => {
            // If another multi option group rpo was selected, find it for removal
            // Multi Opt groups can currently have only 1 RPO per group
            const prevMultiOptRpoId = rpo.optionGroup.options.find((rpo) =>
              formState.value.readyPlanOptions.map((rpo) => rpo.readyPlanOptionId).includes(rpo.id),
            )?.id;
            // Refetch config cost/ RPO deltas/ programData to update formState.
            await recomputeCosts(rpo, !!val, prevMultiOptRpoId);
          }}
          value={
            formState.value.readyPlanOptions?.find((os) => os.readyPlanOptionId === rpo.id)?.readyPlanOptionId || ""
          }
        />
      ) : (
        <Checkbox
          label=""
          aria-label={`rpoId-${rpo.id}`}
          disabled={disabled}
          onChange={async (isSelected) => {
            await recomputeCosts(rpo, isSelected);
          }}
          data-testid={`checkboxInput`}
          selected={!!formState.value.readyPlanOptions?.find((os) => os.readyPlanOptionId === rpo.id)}
        />
      )}
    </div>
  );
}
