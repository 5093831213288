import { AutoSaveIndicator, Css } from "@homebound/beam";
import { useParams } from "react-router";
import { ItemTemplateStatus, ItemTemplateStatusDetail, PotentialOperation2 } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { PublishTakeoffsButton } from "src/routes/libraries/plan-package/takeoffs/components/PublishTakeoffsButton";
import { EditTakeoffsButton } from "src/routes/libraries/plan-package/takeoffs/components/EditTakeoffsButton";

export type TakeoffHeaderProps = {
  itemTemplateId: string;
  canCreateNewVersion: PotentialOperation2;
  statusDetail: ItemTemplateStatusDetail;
};

export function TakeoffsHeader(props: TakeoffHeaderProps) {
  const { itemTemplateId: id, statusDetail, canCreateNewVersion } = props;
  const { planPackageId } = useParams<{ planPackageId: string }>();

  return (
    <PageHeader
      xss={Css.bgTransparent.bn.mb0.$}
      titleSize="xl3Sb"
      title="Takeoff Manager"
      left={<AutoSaveIndicator />}
      right={
        statusDetail.code !== ItemTemplateStatus.Draft ? (
          <EditTakeoffsButton
            canCreateNewVersion={canCreateNewVersion}
            itemTemplateId={id}
            planPackageId={planPackageId}
          />
        ) : (
          statusDetail.code === ItemTemplateStatus.Draft && <PublishTakeoffsButton id={id} />
        )
      }
    />
  );
}
