import {
  BoundNumberField,
  BoundSelectField,
  Button,
  Css,
  IconButton,
  Palette,
  useComputed,
  useTestIds,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useCallback, useEffect } from "react";
import {
  CostClassificationType,
  InvoiceScheduleItemFrequency,
  InvoiceScheduleTriggerType,
  NamedFragment,
  SaveInvoiceScheduleTemplateItemInput,
} from "src/generated/graphql-types";
import { InvoiceScheduleTemplateForm } from "./InvoiceTermsForm";
import { basisPointsToPercentage } from "src/utils/basisPoints";
import { sentenceCase } from "change-case";

type InvoiceTermsItemProps = {
  formState: ObjectState<InvoiceScheduleTemplateForm>;
  costType: CostClassificationType;
  globalPlanMilestones: NamedFragment[];
  readOnly: boolean;
};

export function InvoiceTermsItems(props: InvoiceTermsItemProps) {
  const tid = useTestIds({});
  const { formState, costType, globalPlanMilestones, readOnly } = props;

  const items = useComputed(
    () => formState.items.rows.filter((i) => i.costClassificationType.value === costType && !i.delete.value),
    [formState.items],
  );

  const addItem = useCallback(
    () => formState.items.add({ costClassificationType: costType }),
    [formState.items, costType],
  );

  const deleteItem = useCallback(
    (item: ObjectState<SaveInvoiceScheduleTemplateItemInput>) =>
      item.id.value ? item.delete.set(true) : formState.items.remove(item.value),
    [formState.items],
  );

  // Add an empty field by default
  useEffect(() => {
    if (items.isEmpty) {
      addItem();
    }
  }, [items, addItem]);

  // Clean the item list if the invoice trigger type is no longer TASK
  useEffect(() => {
    return () => {
      if (
        (costType === CostClassificationType.Hard &&
          formState.hardCostTriggerType.value !== InvoiceScheduleTriggerType.Task) ||
        (costType === CostClassificationType.Soft &&
          formState.softCostTriggerType.value !== InvoiceScheduleTriggerType.Task)
      ) {
        items.forEach(deleteItem);
      }
    };
  }, [formState, items, deleteItem, costType]);

  return !readOnly ? (
    <div css={Css.mt2.$}>
      {items.map((item, i) => (
        <div css={Css.df.fg1.mt1.gap2.$} key={item.id.value || `${costType}-${i}`}>
          <BoundSelectField
            field={item.globalPlanMilestoneId}
            label="Invoice Trigger"
            placeholder="Select a task"
            options={globalPlanMilestones}
          />
          <BoundSelectField
            field={item.frequency}
            placeholder="Choose Frequency"
            options={Object.values(InvoiceScheduleItemFrequency).map((type) => ({ id: type, name: type }))}
          />
          <div css={Css.wPx(250).$}>
            <BoundNumberField field={item.iterations} />
          </div>
          <div css={Css.wPx(250).$}>
            <BoundNumberField
              field={item.amountInBasisPoints}
              type="basisPoints"
              label="Amount %"
              data-testid="amountPercent"
            />
          </div>
          <div css={Css.mtPx(25).$}>
            <IconButton
              onClick={() => deleteItem(item)}
              disabled={formState.items.rows.length === 1}
              icon="trash"
              color={Palette.Gray700}
            />
          </div>
        </div>
      ))}
      <div css={Css.mt2.$}>
        <Button icon="plus" variant="tertiary" label="Add Another Trigger" onClick={addItem} />
      </div>
    </div>
  ) : (
    <div css={Css.mt2.$}>
      <div css={Css.df.sm.gray700.fg1.$}>
        <div css={Css.mw50.$}> Invoice Trigger</div>
        <div css={Css.wPx(250).$}> Frequency </div>
        <div css={Css.wPx(250).$}> Iterations </div>
        <div css={Css.wPx(250).$}> Amount % </div>
      </div>
      {items.map((item, i) => (
        <div css={Css.df.my2.smMd.$} key={item.id.value || `${costType}-${i}`}>
          <div {...tid.globalPlanMilestone} css={Css.mw50.$}>
            {globalPlanMilestones.find((gpm) => gpm.id === item.globalPlanMilestoneId.value)?.name}
          </div>
          <div {...tid.frequency} css={Css.wPx(250).$}>
            {sentenceCase(item.frequency.value ?? "")}
          </div>
          <div {...tid.iterations} css={Css.wPx(250).$}>
            {item.iterations.value}
          </div>
          <div {...tid.amountPercent} css={Css.wPx(250).$}>
            {basisPointsToPercentage(item.amountInBasisPoints.value ?? 0)}%
          </div>
        </div>
      ))}
    </div>
  );
}
