import {
  Css,
  FullBleed,
  GridDataRow,
  SuperDrawerWidth,
  useComputed,
  useGridTableApi,
  useSuperDrawer,
} from "@homebound/beam";
import { useCallback, useEffect, useRef } from "react";
import { useParams, useRouteMatch } from "react-router";
import { matchPath, useHistory } from "react-router-dom";
import { LotCheckFormDrawer } from "src/routes/developments/lot-summary/sequence-sheet/components/lot-check-form/LotCheckFormDrawer";
import { LotDetailDrawer } from "src/routes/developments/lot-summary/sequence-sheet/components/lot-detail/LotDetailDrawer";
import { DevelopmentParams, developmentPaths, LotConfigDetailParams } from "src/routes/routesDef";
import { createDevelopmentLotDetailsUrl, createDevelopmentLotSequenceSheetUrl } from "src/RouteUrls";
import { LotSequenceSheetTable, Row } from "./components/sequence-sheet-table/LotSequenceSheetTable";
import { LotSummaryPageFiltersProps } from "./components/utils";

export function LotSequenceSheet({
  queryFilter,
  searchFilter,
  groupBy,
  setSummaryProjectIds,
}: LotSummaryPageFiltersProps) {
  const { developmentId } = useParams<DevelopmentParams>();
  const routeMatch = useRouteMatch<LotConfigDetailParams>([
    developmentPaths.lotDetails,
    developmentPaths.lotSiteConditionDetails,
  ]);
  const { push } = useHistory();
  const api = useGridTableApi<Row>();
  const { openInDrawer, closeDrawer, isDrawerOpen } = useSuperDrawer();

  const prevPathPRPCItemRef = useRef<string | undefined>();
  const prevPathPRPCItem = prevPathPRPCItemRef.current;
  const pathPRPCId = routeMatch?.params.projectReadyPlanConfigId;
  const pathPSCProjectId = routeMatch?.params.projectIdFromSiteCondition;

  useEffect(() => {
    prevPathPRPCItemRef.current = pathPRPCId;
  });

  const openLotDetailsDrawer = useCallback(
    (pathPRPCId: string) => {
      const lineItemRows = api.getVisibleRows("project");
      const prpcRow = lineItemRows.find((r) => r.id === pathPRPCId);
      if (!prpcRow) return;
      const { prev, next } = findPrevNext(lineItemRows, prpcRow);
      const handlePrevNextClick = (newRow: GridDataRow<Row>) => {
        const url = createDevelopmentLotDetailsUrl(developmentId, newRow.id);
        push(url);
      };
      openInDrawer({
        content: <LotDetailDrawer key={pathPRPCId} configId={pathPRPCId} developmentId={developmentId} />,
        width: SuperDrawerWidth.Small,
        onClose: () => {
          // Only redirect back to Lot Sequence page if we are still on the Lot Sequence URL
          // It is possible we could be redirecting outside of the page which caused the drawer to close.
          // If so, then do not redirect back to Lot Sequence page.
          if (
            matchPath(window.location.pathname, {
              path: [developmentPaths.lotSequenceSheet, developmentPaths.lotSummary],
            })
          ) {
            // When closing the SuperDrawer, remove /:configId
            push(createDevelopmentLotSequenceSheetUrl(developmentId));
          }
        },
        onPrevClick: prev && (() => handlePrevNextClick(prev)),
        onNextClick: next && (() => handlePrevNextClick(next)),
      });
    },
    [openInDrawer, developmentId, push, api],
  );

  const openProjectSiteConditionDetailsDrawer = useCallback(
    (pathPSCProjectId: string) => {
      if (!pathPSCProjectId) return;
      openInDrawer({
        content: <LotCheckFormDrawer projectId={pathPSCProjectId} developmentId={developmentId} />,
        onClose: () => {
          // When closing the SuperDrawer, remove /:configId
          push(createDevelopmentLotSequenceSheetUrl(developmentId));
        },
      });
    },
    [developmentId, openInDrawer, push],
  );

  // Close the SuperDrawer if the URLs don't have an prpc as param
  useEffect(() => {
    if (!routeMatch?.isExact) closeDrawer();
  }, [routeMatch, closeDrawer]);
  // Close super drawer on page unmount
  useEffect(() => closeDrawer, [closeDrawer]);

  // Open lot details drawer when the table is loaded & the URL matches
  const hasRows = useComputed(() => api.getVisibleRows("project").length > 0, [api]);
  useEffect(() => {
    if (pathPRPCId && hasRows && (!isDrawerOpen || pathPRPCId !== prevPathPRPCItem)) {
      openLotDetailsDrawer(pathPRPCId);
    }
  }, [pathPRPCId, openLotDetailsDrawer, prevPathPRPCItem, isDrawerOpen, hasRows]);

  // Open project site condition details drawer when the table is loaded & the URL matches
  useEffect(() => {
    if (pathPSCProjectId && !isDrawerOpen) {
      openProjectSiteConditionDetailsDrawer(pathPSCProjectId);
    }
  }, [pathPSCProjectId, openProjectSiteConditionDetailsDrawer, isDrawerOpen]);

  return (
    <FullBleed>
      <div css={Css.bgGray100.$}>
        <LotSequenceSheetTable
          api={api}
          developmentId={developmentId}
          queryFilter={queryFilter}
          groupBy={groupBy}
          searchFilter={searchFilter}
          setSummaryProjectIds={setSummaryProjectIds}
        />
      </div>
    </FullBleed>
  );
}

function findPrevNext(
  rows: GridDataRow<Row>[],
  row: GridDataRow<Row>,
): { prev: GridDataRow<Row>; next: GridDataRow<Row> } {
  let key: "prev" | "next" = "prev";
  const result: any = {};

  for (let i = 0; i < rows.length; i++) {
    const each = rows[i];
    // Flip from prev to next when we find it
    if (each.kind === row.kind && each.id === row.id) {
      key = "next";
    } else {
      if (key === "prev") {
        // prev always overwrites what was there before
        result[key] = each;
      } else {
        // next only writes first seen
        result[key] ?? (result[key] = each);
      }
    }
  }
  return result;
}
