import { Css } from "@homebound/beam";
import { HistoryFeed } from "src/components";
import { TaskDetailsPage_PlanTaskFragment } from "src/generated/graphql-types";

type HistoryCardProps = {
  task: TaskDetailsPage_PlanTaskFragment;
};

export function HistoryCard({ task }: HistoryCardProps) {
  const { history } = task;
  return (
    <div css={Css.gc(2).gr(3).maxhPx(650).df.fdc.bgWhite.br8.p3.$}>
      <h2 css={Css.xlBd.mb1.$}>History</h2>
      <div css={Css.oa.$}>
        <HistoryFeed historyItems={history} renderAsCard={false} />
      </div>
    </div>
  );
}
