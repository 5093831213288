import { Css } from "@homebound/beam";
import isEqual from "lodash/isEqual";
import { Percentage, Price } from "src/components";
import {
  ItemTemplateLineItemsTab_BasedOnIti_OriginalValuesFragment,
  ScopeChangeType,
  Named,
  Maybe,
  ItemTemplateItemLineItemWithChangesFragment,
  ItemTemplateLineItemsTab_ReadyPlanOptionFragment,
} from "src/generated/graphql-types";
import { isDefined } from "src/utils";

/**
 * Determines if the specified field has been changed and return a tooltip and css style of the associated row.
 * NOTE: Later we may refactor this to support fields outside of a table as well.
 */
export function maybeChangedValue(
  row: ItemTemplateItemLineItemWithChangesFragment,
  field: keyof ItemTemplateItemLineItemWithChangesFragment &
    keyof ItemTemplateLineItemsTab_BasedOnIti_OriginalValuesFragment,
  allOptions: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[] = [],
) {
  const opts = getMaybeChangedValueConfigs()[field] ?? {};
  const basedOn = row.basedOn;
  const lineChanged =
    ![ScopeChangeType.None, ScopeChangeType.System, ScopeChangeType.Removed, ScopeChangeType.Add].includes(
      row.scopeChangeType,
    ) && basedOn;

  if (!lineChanged) {
    return {};
  }

  const isObject = isNamed(row[field]);
  const currentValue =
    Object.hasOwn(opts, "currentValue") && isDefined(opts.currentValue) ? opts.currentValue(row) : row[field];
  const basedOnValue =
    Object.hasOwn(opts, "basedOnValue") && isDefined(opts.basedOnValue) ? opts.basedOnValue(row) : basedOn[field];
  const fieldChanged = isObject
    ? (currentValue as any)?.id !== (basedOnValue as any)?.id
    : !isEqual(currentValue, basedOnValue);

  if (!fieldChanged) return {};

  const displayFormat = opts.displayFormat ?? inferDisplayFormat(field);
  const originalDisplayValue = tooltipOriginalDisplay(
    currentValue,
    basedOnValue,
    displayFormat,
    opts.unsetValue,
    allOptions,
  );

  return {
    css: Css.bgYellow300.important.$,
    tooltip: <>Originally: {originalDisplayValue}</>,
  };
}

function inferDisplayFormat(field: string): MaybeChangedValueDisplayFormat {
  if (field.endsWith("InCents")) return "cents";
  if (field.endsWith("Percentage")) return "percentage";
  if (field.endsWith("BasisPoints")) return "basisPoints";
  return "raw";
}

function tooltipOriginalDisplay(
  currentValue: Maybe<Named | string | number> | string[],
  basedOnValue: Maybe<Named | string | number> | string[],
  displayFormat: MaybeChangedValueDisplayFormat,
  unsetValue: string = "N/A",
  allOptions: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[] = [],
) {
  if (isNamed(currentValue) || isNamed(basedOnValue)) {
    // We know that either the current or basedOn value is a Named, so we know it changed from one named entity to something or undefined...
    return isNamed(basedOnValue) ? basedOnValue.name : unsetValue;
  }
  if (displayFormat === "cents") {
    return <Price valueInCents={basedOnValue as number} />;
  }
  if (displayFormat === "percentage") {
    return <Percentage percent={basedOnValue as number} />;
  }
  if (displayFormat === "basisPoints") {
    return <Percentage percent={(basedOnValue as number) / 100} />;
  }
  if (Array.isArray(basedOnValue)) {
    if (basedOnValue.isEmpty) return unsetValue;
    return mapOptionIdsToDisplayName(basedOnValue, allOptions);
  }

  return basedOnValue ?? unsetValue;
}

function isNamed(obj: any): obj is Named {
  return isDefined(obj) && !!(obj as any).name;
}

type MaybeChangedValueDisplayFormat = "cents" | "percentage" | "basisPoints" | "raw";
type MaybeChangedValueConfig = {
  // Indicates the field should be displayed as a price
  displayFormat?: MaybeChangedValueDisplayFormat;
  // Optionally specify the basedOnValue when needed
  basedOnValue?: (row: ItemTemplateItemLineItemWithChangesFragment) => Maybe<Named | string | number>;
  currentValue?: (row: ItemTemplateItemLineItemWithChangesFragment) => Maybe<Named | string | number>;
  unsetValue?: string;
};

var maybeChangedValueConfigs:
  | Partial<Record<keyof ItemTemplateLineItemsTab_BasedOnIti_OriginalValuesFragment, MaybeChangedValueConfig>>
  | undefined = undefined;

// Avoid instantiating the config object until it's needed
function getMaybeChangedValueConfigs() {
  return (
    maybeChangedValueConfigs ??
    (maybeChangedValueConfigs = {
      unitCostInCents: {
        // Due to the difference between the way the FE/BE compute unitCostInCents, a special case is needed
        currentValue: (row) => (isDefined(row.quantity) ? row.unitCostInCents : undefined),
        basedOnValue: (row) => (isDefined(row.quantity) ? row.basedOn?.unitCostInCents : undefined),
      },
      baseProduct: {
        unsetValue: "None",
      },
      elevationIds: { unsetValue: "All" },
      specOptionIds: { unsetValue: "All" },
      otherOptionIds: { unsetValue: "Base" },
      bidItem: {
        unsetValue: "None",
      },
      preferredBidContractLineItem: { unsetValue: "Manual" },
    })
  );
}

function mapOptionIdsToDisplayName(ids: string[], allOptions: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[]) {
  return ids.map((id) => allOptions.find((o) => o.id === id)?.displayName ?? id).join(", ");
}
