import {
  BoundDateField,
  BoundSelectField,
  BoundTextAreaField,
  Button,
  ButtonMenu,
  Css,
  FullBleed,
  Icon,
  MenuItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  RadioGroupField,
  SelectField,
  Tag,
  TagType,
  Tooltip,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { addDays } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { AssetGallery, OpenGallery } from "src/components/assetGallery/AssetGallery";
import {
  BidContractRevisionFragment,
  BidContractRevisionStatus,
  DevContractHeader_ItemTemplateCostUpdateFragment,
  DocumentType,
  SaveBidContractInput,
  SaveBidContractRevisionInput,
  useDeleteBidContractRevisionMutation,
  useItemTemplateCostUpdatesQuery,
  useSaveBidContractMutation,
  useSaveBidContractRevisionMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { DeleteConfirmationModal } from "src/routes/components/DeleteConfirmationModal";
import { PandaDocUploadButton } from "src/routes/components/PandaDocUploadButton";
import { PageHeaderBreadcrumbs } from "src/routes/layout/PageHeader";
import { createDevelopmentContractOverviewUrl, createDevelopmentProcurementUrl } from "src/RouteUrls";
import { DateOnly, formatWithShortYear } from "src/utils/dates";
import { ObjectConfig, useFormState } from "src/utils/formState";
import { DeletePandaDocModal } from "../../projects/commitments/components/DeletePandaDocModal";
import { useManagePosWizard } from "../manage-pos-wizard/useManagePosWizard";
import { useDevContractWizard } from "./upload/UploadDevelopmentContractWizard";
import { UploadPriceAgreementModal } from "./UploadPriceAgreementModal";
import { formatList, partition, pluralize } from "src/utils";
import { useToggle } from "src/hooks";

type DevelopmentContractHeaderProp = {
  selectedRevision: BidContractRevisionFragment;
};

export function DevelopmentContractHeader({ selectedRevision }: DevelopmentContractHeaderProp) {
  const history = useHistory();
  const testIds = useTestIds({});
  const { bidContract } = selectedRevision;
  const {
    parent: { id: parentId },
    tradePartner,
    isInternalEstimate,
    bidRequest,
  } = bidContract;
  const headerBreadcrumbs = [{ label: "All Contracts", href: createDevelopmentProcurementUrl(parentId) }];
  const { openModal } = useModal();
  const [saveBidContract] = useSaveBidContractMutation();
  const [saveBidContractRevision] = useSaveBidContractRevisionMutation();
  const openManagePosWizard = useManagePosWizard(selectedRevision.id);
  const [editInternalNote, toggleEditInternalNote] = useToggle(false);
  const openGalleryRef = useRef<OpenGallery | null>(null);

  const disabledDays = useMemo(() => {
    const previousRevision = selectedRevision.bidContract.revisions.find(
      (bcr) => parseInt(bcr.version) === parseInt(selectedRevision.version) - 1,
    );
    return previousRevision ? [{ before: addDays(previousRevision.startDate, 1) }, (date: Date) => false] : [];
  }, [selectedRevision]);

  const formState = useFormState({
    config: formConfig,
    init: {
      input: bidContract,
      map: (i) => i,
    },
    autoSave: (fs) => saveBidContract({ variables: { input: fs.changedValue } }),
  });

  const formStateRevision = useFormState({
    config: formConfigRevision,
    init: { input: selectedRevision, map: (i) => i },
  });

  return (
    <>
      <FullBleed>
        <header css={Css.df.fdc.bb.bw1.bcGray200.py2.$}>
          <PageHeaderBreadcrumbs breadcrumb={headerBreadcrumbs} />
          <div {...testIds.contractHeaderCard} css={Css.df.fdr.w100.aic.mt1.$}>
            <div css={Css.aic.wPx(450).$}>
              <div {...testIds.headerTitle} css={Css.lgSb.$}>
                {isInternalEstimate ? "Homebound Budget Estimate" : `${tradePartner?.name} Contract`}
              </div>
              <div {...testIds.status} css={Css.df.fdr.aic.ttc.$}>
                <div css={Css.wPx(118).mr2.$}>
                  <SelectField
                    borderless
                    label="Versions"
                    labelStyle="hidden"
                    options={selectedRevision.bidContract.revisions.map((revision) => ({
                      label: `Version ${revision.version}`,
                      value: revision.id,
                    }))}
                    value={selectedRevision.id}
                    getOptionLabel={(o) => o.label}
                    getOptionValue={(o) => o.value}
                    onSelect={(val) => history.push(createDevelopmentContractOverviewUrl(parentId, val!))}
                  />
                </div>
                <Tag text={selectedRevision.status.name} type={getTagTypeFromStatus(selectedRevision.status.code)} />
              </div>
              {!selectedRevision.itemTemplateCostUpdates.isEmpty && (
                <TemplatesCostUpdateInfo
                  templatesInfo={selectedRevision.itemTemplateCostUpdates}
                  bidContractRevisionId={selectedRevision.id}
                />
              )}
            </div>

            <div css={Css.df.fdr.mwPx(300).$}>
              {selectedRevision.signedOn && (
                <div {...testIds.signedOn} css={Css.df.fdc.mr5.$}>
                  <div css={Css.sm.gray700.mbPx(4).$}>Signed on</div>
                  <div css={Css.smMd.ptPx(10).$}>{formatWithShortYear(selectedRevision.signedOn)}</div>
                </div>
              )}
              {!isInternalEstimate && !bidRequest && (
                <div css={Css.sm.gray700.wPx(108).mr5.$}>
                  <BoundSelectField
                    borderless
                    field={formState.isPrimary}
                    label="Preference"
                    options={[
                      { label: "Primary", value: true },
                      { label: "Secondary", value: false },
                    ]}
                    getOptionLabel={(o) => o.label}
                    getOptionValue={(o) => o.value}
                  />
                </div>
              )}
              <div {...testIds.effectiveDates} css={Css.df.aifs.fdc.mr5.$}>
                <div css={Css.sm.gray700.mbPx(4).$}>Effective Date</div>
                <div css={Css.smMd.$}>
                  {!selectedRevision.endDate ? (
                    <BoundDateField
                      borderless
                      disabledDays={disabledDays}
                      readOnly={selectedRevision.status.code !== BidContractRevisionStatus.Draft}
                      field={formStateRevision.startDate}
                      onChange={async (val) => {
                        await saveBidContractRevision({
                          variables: {
                            input: { id: selectedRevision.id, startDate: val ? new DateOnly(val) : undefined },
                          },
                        });
                      }}
                      label="start date"
                      labelStyle="hidden"
                    />
                  ) : (
                    <div css={Css.ptPx(10).$}>
                      {formatWithShortYear(selectedRevision.startDate)} -{" "}
                      {formatWithShortYear(selectedRevision.endDate)}
                    </div>
                  )}
                </div>
              </div>

              {
                <div>
                  {editInternalNote ? (
                    <div css={Css.mr2.sm.$}>
                      <BoundTextAreaField field={formStateRevision.internalNote} label="Internal Note (optional)" />
                    </div>
                  ) : (
                    <>
                      <div css={Css.gray700.mbPx(11).$}>Internal Note (optional)</div>
                      <p css={Css.sm.mr2.mbPx(4).$} {...testIds.internalNote}>
                        {formStateRevision.internalNote.value}
                      </p>
                    </>
                  )}
                  <Button
                    {...testIds.internalNoteButton}
                    variant="text"
                    label={
                      <span css={Css.blue400.mr2.tinyMd.$}>
                        {editInternalNote
                          ? "Save note"
                          : formStateRevision.internalNote.value
                            ? "Edit note"
                            : "Click to add"}
                      </span>
                    }
                    onClick={async () => {
                      if (editInternalNote) {
                        await saveBidContractRevision({
                          variables: {
                            input: { id: selectedRevision.id, internalNote: formStateRevision.internalNote.value },
                          },
                        });
                      }
                      toggleEditInternalNote();
                    }}
                  />
                </div>
              }
            </div>

            <div css={Css.df.fdr.jcsb.ml("auto").$}>
              <div css={Css.mr1.$}>
                {selectedRevision.document && (
                  <AssetGallery assets={[selectedRevision.document.asset]}>
                    {(openGallery) => {
                      openGalleryRef.current = openGallery;
                    }}
                  </AssetGallery>
                )}

                {isInternalEstimate ? (
                  <Button
                    label="Revise Estimate"
                    disabled={selectedRevision.status.code === BidContractRevisionStatus.Draft}
                    variant="secondary"
                    onClick={() => openModal({ content: <CreateRevisionModal revision={selectedRevision} /> })}
                  />
                ) : (
                  <ButtonMenu
                    items={actionMenuItems(selectedRevision, parentId, openModal, openGalleryRef)}
                    trigger={{ label: "Actions" }}
                  />
                )}
              </div>
              {isInternalEstimate ? (
                <Button
                  label="Publish Estimate"
                  disabled={selectedRevision.status.code === BidContractRevisionStatus.Signed}
                  onClick={() =>
                    openModal({
                      content: <PublishInternalEstimateModal bidContractRevisionId={selectedRevision.id} />,
                    })
                  }
                />
              ) : selectedRevision.status.code !== BidContractRevisionStatus.Signed ? (
                <PandaDocUploadButton
                  parent={selectedRevision}
                  onUpload={() => {
                    openModal({
                      content: <UploadContractModal bidContractRevisionId={selectedRevision.id} />,
                    });
                  }}
                />
              ) : (
                <Button label="Manage POs" onClick={() => openManagePosWizard()} />
              )}
            </div>
          </div>
        </header>
      </FullBleed>
    </>
  );
}

type FormValue = Pick<SaveBidContractInput, "id" | "isPrimary">;

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  isPrimary: { type: "value" },
};

type FormValueRevision = Pick<SaveBidContractRevisionInput, "id" | "internalNote"> & {
  startDate: Date | null | undefined;
};

const formConfigRevision: ObjectConfig<FormValueRevision> = {
  id: { type: "value" },
  startDate: { type: "value" },
  internalNote: { type: "value" },
};

export const getTagTypeFromStatus = (statusCode?: BidContractRevisionStatus): TagType => {
  switch (statusCode) {
    case BidContractRevisionStatus.Draft:
      return "info";
    case BidContractRevisionStatus.Signed:
      return "success";
    case BidContractRevisionStatus.Uploaded:
    case BidContractRevisionStatus.PartiallySigned:
      return "caution";
    default:
      return "info";
  }
};

export function canDeletePandaDoc(status: BidContractRevisionStatus) {
  switch (status) {
    case BidContractRevisionStatus.Uploaded:
    case BidContractRevisionStatus.Sent:
      return true;
    default:
      return false;
  }
}

function actionMenuItems(
  revision: BidContractRevisionFragment,
  parentId: string,
  openModal: (props: ModalProps) => void,
  openGalleryRef: React.RefObject<OpenGallery>,
): MenuItem[] {
  return [
    {
      label: "Upload Price Agreement",
      disabled: revision.status.code !== BidContractRevisionStatus.Draft,
      onClick: () =>
        openModal({
          content: (
            <UploadPriceAgreementModal
              parentId={parentId}
              ownerId={revision.id}
              documentType={DocumentType.PriceAgreementBidContractRevision}
            />
          ),
        }),
    },
    ...(revision.document && !revision.pandaDoc
      ? [
          {
            label: "View PDF",
            onClick: () => openGalleryRef.current?.(revision.document!.asset),
          },
        ]
      : [
          {
            label: "View in PandaDoc",
            disabled: !revision.pandaDoc?.externalPandaDocUrl,
            onClick: revision?.pandaDoc?.externalPandaDocUrl!,
          },
        ]),

    {
      label: "Delete Upload",
      disabled: !canDeletePandaDoc(revision.status.code),
      onClick: () =>
        openModal({
          content: <DeletePandaDocModal ownerId={revision.id} id={revision.pandaDoc?.id || ""} />,
        }),
    },
    {
      label: "Revise contract",
      disabled: revision.status.code !== BidContractRevisionStatus.Signed,
      onClick: () => openModal({ content: <CreateRevisionModal revision={revision} /> }),
    },
    {
      label: "Delete",
      disabled: !revision.canDelete.allowed,
      onClick: () => openModal({ content: <DeleteRevisionEventModal revision={revision} parentId={parentId} /> }),
    },
  ];
}

type CreateRevisionModalProps = {
  revision: BidContractRevisionFragment;
};

function CreateRevisionModal({ revision }: CreateRevisionModalProps) {
  const [saveBidContractRevision] = useSaveBidContractRevisionMutation();
  const { triggerNotice } = useSnackbar();
  const history = useHistory();

  return (
    <ConfirmationModal
      onConfirmAction={async () => {
        const { data } = await saveBidContractRevision({
          variables: { input: { bidContractId: revision.bidContract.id, startDate: new DateOnly(new Date()) } },
        });
        if (data) {
          const savedBcr = data.saveBidContractRevision.bidContractRevision;
          triggerNotice({
            message: (
              <>
                <div>
                  Version {savedBcr.version} ({savedBcr.status.name}) has been created!
                </div>
                {!revision.bidContract.isInternalEstimate && (
                  <div css={Css.mt2.$}>Once you have completed revisions, upload the revised contract to PandaDoc.</div>
                )}
              </>
            ),
            icon: "success",
          });
          history.push(createDevelopmentContractOverviewUrl(revision.bidContract.parent.id, savedBcr.id));
        }
      }}
      title={`Revise ${revision.bidContract.isInternalEstimate ? "Budget Estimate" : "Contract"}`}
      label={`Create Version ${revision.bidContract.revisions.length + 1}`}
      confirmationMessage={
        <div>
          <div>
            Do you want to create a new version of this contract? This will create a new draft
            {!revision.bidContract.isInternalEstimate ? " contract" : " estimate"} for you to revise.
          </div>
          {!revision.bidContract.isInternalEstimate && (
            <div css={Css.mt2.$}>
              Because this contract version has been signed, you will need to upload the new version to PandaDoc. This
              new version must be signed by the trade partner for it to be active.
            </div>
          )}
        </div>
      }
    />
  );
}

function DeleteRevisionEventModal(props: { revision: BidContractRevisionFragment; parentId: string }) {
  const { revision, parentId } = props;
  const history = useHistory();
  const [deleteBidContractRevisionMutation] = useDeleteBidContractRevisionMutation();
  const { triggerNotice } = useSnackbar();
  return (
    <DeleteConfirmationModal
      confirmationMessage="Are you sure you want to delete this revision?"
      entityType="Development Contract Revision"
      onConfirmDelete={async () => {
        await deleteBidContractRevisionMutation({
          variables: { input: { id: revision.id } },
        });

        triggerNotice({
          message: (
            <>
              <div>Development Contract version has been deleted</div>
            </>
          ),
          icon: "success",
        });

        history.push(createDevelopmentProcurementUrl(parentId));
      }}
    />
  );
}

export type UploadOptions = "budgets" | "uploadOnly" | "publishOnly";

type UploadContractModalProps = {
  bidContractRevisionId: string;
};

function UploadContractModal({ bidContractRevisionId }: UploadContractModalProps) {
  const [mode, setMode] = useState<UploadOptions>("budgets");
  const { closeModal } = useModal();
  const openWizard = useDevContractWizard(bidContractRevisionId, mode);

  return (
    <>
      <ModalHeader>Upload Contract</ModalHeader>
      <ModalBody>
        <div>Are you sure you want to upload this contract to PandaDoc?</div>
        <div css={Css.smBd.pt3.pb1.$}>If so, how do you want this contract to impact budgets and purchase orders?</div>
        <RadioGroupField<UploadOptions>
          label="next step selection" // for aria
          labelStyle="hidden"
          options={[
            { label: "Update budgets", value: "budgets" },
            { label: "Upload only (no budget updates)", value: "uploadOnly" },
          ]}
          value={mode}
          onChange={setMode}
        />
        <div css={Css.xs.pt2.$}>
          <div css={Css.mbPx(-8).$}>Note:</div>
          <ul css={Css.add({ paddingInlineStart: "18px" }).$}>
            <li>You can manage budgets and POs later from the contract actions menu.</li>
            <li>
              All voided and new POs will go out after this contract version is signed and the budget updates are
              approved.
            </li>
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button label="Upload" onClick={() => openWizard()} />
      </ModalFooter>
    </>
  );
}

function PublishInternalEstimateModal({ bidContractRevisionId }: UploadContractModalProps) {
  const [mode, setMode] = useState<UploadOptions>("budgets");
  const { closeModal } = useModal();
  const openWizard = useDevContractWizard(bidContractRevisionId, mode);

  return (
    <>
      <ModalHeader>Publish Budget Estimate</ModalHeader>
      <ModalBody>
        <div>
          Are you sure you want to publish this estimate? You won't be able to make changes to this version later.
        </div>
        <div css={Css.smBd.pt3.pb1.$}>
          If so, how do you want these estimates to impact budgets and purchase orders?
        </div>
        <RadioGroupField<UploadOptions>
          label="next step selection"
          labelStyle="hidden"
          options={[
            { label: "Publish and update budgets", value: "budgets" },
            { label: "Publish only (no budget updates)", value: "publishOnly" },
          ]}
          value={mode}
          onChange={setMode}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button label="Publish" onClick={() => openWizard()} />
      </ModalFooter>
    </>
  );
}

type TemplatesCostUpdateInfoProps = {
  templatesInfo: DevContractHeader_ItemTemplateCostUpdateFragment[];
  bidContractRevisionId: string;
};

function TemplatesCostUpdateInfo({ templatesInfo, bidContractRevisionId }: TemplatesCostUpdateInfoProps) {
  const tid = useTestIds({});
  const [completed, pending] = partition(templatesInfo, (info) => info.completed);
  const listTemplates = (itcus: DevContractHeader_ItemTemplateCostUpdateFragment[]) =>
    formatList(itcus.map(({ itemTemplate }) => `${itemTemplate.displayName} (Version ${itemTemplate.version})`));
  // Refetch the list of pending templates if there's any
  const templatesInfoQuery = useItemTemplateCostUpdatesQuery({
    skip: templatesInfo.isEmpty || pending.isEmpty,
    pollInterval: 5000,
    variables: { bidContractRevisionId },
  });

  return (
    <div {...tid.templatesToUpdate}>
      <div css={Css.xsMd.df.fdr.aic.$}>
        <div css={Css.mr1.$}>
          <Icon icon={completed.length === templatesInfo.length ? "checklistComplete" : "refresh"} />
        </div>
        <div>
          <Tooltip title={listTemplates(completed)} placement="bottom">
            {completed.length} {pluralize(completed.length, "template")} updated
          </Tooltip>
          {!pending.isEmpty && (
            <Tooltip title={listTemplates(pending)} placement="bottom">
              <span css={Css.xsMd.red600.mlPx(2).$}> ({pending.length} pending) </span>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}
