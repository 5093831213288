import { Css, useTestIds } from "@homebound/beam";
import { ConfigsComparePage_ConfigCostsFragment } from "src/generated/graphql-types";
import { labeledProgramData } from "../../utils";
import { Price } from "src/components/Price";

type FormattedProgramData = {
  label: string;
  value: number | null;
};

export function ProgramDataSection({
  config,
}: {
  config: ConfigsComparePage_ConfigCostsFragment["productOfferingConfig"];
}) {
  const { programData } = config;
  const { __typename, id, minLotSizeInSqft, minLotDepthInFeet, minLotWidthInFeet, ...pd } = programData;
  return (
    <>
      <ProgramDataCard title="Plan Data" formattedPds={labeledProgramData(pd)} showBaseCost />
      <ProgramDataCard
        title="Program Lot Needs"
        formattedPds={labeledProgramData({ minLotSizeInSqft, minLotDepthInFeet, minLotWidthInFeet })}
      />
    </>
  );
}

function ProgramDataCard({
  title,
  formattedPds,
  showBaseCost,
}: {
  title: string;
  formattedPds: FormattedProgramData[];
  showBaseCost?: boolean;
}) {
  const tid = useTestIds({});
  return (
    <div css={Css.aic.my8.$}>
      <div css={Css.xlBd.mb1.$}>{title}</div>
      <div css={Css.p3.bgWhite.aic.bshBasic.br8.addIn("& > div:last-child", Css.bsn.$).$}>
        {showBaseCost && (
          <div css={Css.df.fdc.bb.bcGray200.py2.$}>
            <div css={Css.dg.gtc("3fr .1fr").aic.gap4.$}>
              <div css={Css.smBd.wsnw.oh.add("textOverflow", "ellipsis").$}>Base Price</div>
              <div css={Css.gray700.$}>
                {/** TODO: replace with BE config base cost */}
                <Price valueInCents={0} />
              </div>
            </div>
          </div>
        )}

        {formattedPds.map((pd) => (
          <div key={pd.label} css={Css.df.fdc.bb.bcGray200.py2.$}>
            <div css={Css.dg.gtc("3fr .1fr").aic.gap4.$}>
              <div {...tid[`${pd.label.replaceAll(" ", "")}`]} css={Css.smBd.wsnw.oh.add("textOverflow", "ellipsis").$}>
                {pd.label}
              </div>
              <div {...tid[`${pd.label.replaceAll(" ", "")}_val`]} css={Css.gray700.$}>
                {pd.value}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
