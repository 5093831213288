import { BoundTextField, Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import {
  SaveTradeCategoryInput,
  TradeCategory,
  TradeCategoryInfoFragment,
  useSaveTradeCategoryMutation,
} from "src/generated/graphql-types";

type TradeCategoriesEditorProps = {
  tradeCategory?: TradeCategoryInfoFragment;
};

export function TradeCategoriesEditor({ tradeCategory }: TradeCategoriesEditorProps) {
  const [saveCategoryMutation] = useSaveTradeCategoryMutation({ refetchQueries: ["GetTradeCategories"] });
  const { closeModal } = useModal();

  return (
    <TradeCategoriesEditorDataView
      onSave={async (data) => {
        await saveCategoryMutation({ variables: { input: data } });
        closeModal();
      }}
      tradeCategory={tradeCategory}
    />
  );
}

type TradeCategoriesEditorDataViewProps = {
  tradeCategory?: TradeCategoryInfoFragment;
  onSave: (data: SaveTradeCategoryInput) => void;
};

export function TradeCategoriesEditorDataView({ tradeCategory, onSave }: TradeCategoriesEditorDataViewProps) {
  const formstate = useFormState({
    init: tradeCategory,
    config: formConfig,
  });

  return (
    <>
      <ModalHeader>{tradeCategory ? "Update Category" : "Add Category"}</ModalHeader>
      <ModalBody>
        <BoundTextField field={formstate.number} />
        <BoundTextField field={formstate.name} />
      </ModalBody>
      <ModalFooter>
        <Button label="Submit" data-testid="submit" onClick={() => onSave(formstate.value)} />
      </ModalFooter>
    </>
  );
}

type FormValue = {
  id: TradeCategory["id"];
  name?: TradeCategory["name"] | null;
  number?: TradeCategory["number"] | null;
};

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  number: { type: "value", rules: [required] },
};
