import { Css, useTestIds } from "@homebound/beam";
import { ReactNode } from "react";
import { TradePartnerSignatoryContactFragment } from "src/generated/graphql-types";
import { fail } from "src/utils";

type CommitmentAddressesProps = {
  tradePartner: { name: string; id: string };
  signatoryContact: TradePartnerSignatoryContactFragment;
  children?: ReactNode;
};

/** Shows the Homebound address (hardcoded) and the TP address side-by-side. */
export function CommitmentAddresses({ tradePartner, signatoryContact, children }: CommitmentAddressesProps) {
  const address = signatoryContact.address || fail(`${signatoryContact.name} does not have an address`);
  const tid = useTestIds({});

  return (
    <div css={Css.df.fdr.mt2.$}>
      <div css={Css.df.f1.fdc.p1.wPx(562).$}>
        <div css={Css.fwb.$}>Homebound Technologies, Inc.</div>
        <div>1 Letterman Drive, Bldg C Ste 3500</div>
        <div>San Francisco, CA 94129</div>
        <div>CACL #1043883</div>
      </div>

      {tradePartner && (
        <div css={Css.df.f1.fdc.p1.wPx(562).$}>
          <div {...tid.tradePartnerName} css={Css.fwb.$}>
            {tradePartner.name}
          </div>
          <div>{signatoryContact.name}</div>
          <div>
            {address.street1} {address.street2}
          </div>
          <div>
            {address.city}, {address.state} {address.postalCode} {address.country}
          </div>
          <div>Email: {signatoryContact.email}</div>
          <div>Phone: {signatoryContact.officePhone || signatoryContact.mobilePhone}</div>
        </div>
      )}

      {children}
    </div>
  );
}
