import {
  Button,
  Checkbox,
  Css,
  GridColumn,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SimpleHeaderAndData,
  column,
  simpleDataRows,
  useModal,
} from "@homebound/beam";
import {
  DesignPackageConfiguratorContext,
  useDesignPackageConfiguratorContext,
} from "../DesignPackageConfiguratorContext";
import {
  DesignPackageItemSlotEditorDocument,
  DesignPackageItivCardFragment,
  RegroupItivPlanLocationsInput,
  useRegroupItivPlanLocationsMutation,
} from "src/generated/graphql-types";
import useSetState from "src/hooks/useSetState";
import { useRef } from "react";
import { fail } from "src/utils";

type TakeoffLocationsModalProps = { placeholderItivId: string };

export function TakeoffLocationsModal({ placeholderItivId }: TakeoffLocationsModalProps) {
  const { closeModal } = useModal();
  const { selectedSlots } = useDesignPackageConfiguratorContext();
  const [save] = useRegroupItivPlanLocationsMutation({ refetchQueries: [DesignPackageItemSlotEditorDocument] });
  const allLoadedItivs = selectedSlots?.flatMap((dpSlot) => dpSlot.items) ?? [];
  const placeholder = allLoadedItivs?.find((itiv) => itiv.id === placeholderItivId) ?? fail("Placeholder not found");
  const matchingPlaceholders = allLoadedItivs
    .filter((itiv) => !itiv.scope.placeholder)
    .filter((itiv) => itiv.scope.dpSignature === placeholder.scope.dpSignature);
  const locations = simplifyLocationPaths(
    matchingPlaceholders?.flatMap((itiv) => itiv.scope.planLocations).sortByKey("id") ?? [],
  );
  const [locToItiv, setLocToItiv] = useSetState(() => {
    return locations.mapToObject((loc) => [
      loc.id,
      matchingPlaceholders.find((itiv) => itiv.scope.planLocations.some((loc2) => loc2.id === loc.id))?.id ??
        fail("location not found in any itiv"),
    ]);
  });
  const ref = useRef<string[]>(
    matchingPlaceholders.map((itiv) => itiv.scope.customPlaceholderName ?? itiv.displayName),
  );
  const columns = useCreateColumns(matchingPlaceholders, locToItiv, setLocToItiv, ref);
  return (
    <>
      <ModalHeader>Product Groupings Manager</ModalHeader>
      <ModalBody>
        {locations.length <= 1 ? (
          <div css={Css.smBd.red400.$}>Not enough locations to regroup. Nothing to do here. Please close.</div>
        ) : (
          <GridTable columns={columns} rows={simpleDataRows(locations)} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button label="Close" onClick={closeModal} variant={locations.length <= 1 ? "primary" : "secondary"} />
        {locations.length > 1 && (
          <Button
            label="Save"
            disabled={locations.length <= 1}
            onClick={async () => {
              const locItivTuples = Object.entries(locToItiv);
              const inputs = matchingPlaceholders.map<RegroupItivPlanLocationsInput>((itiv, ix) => ({
                id: itiv.id,
                customPlaceholderName: ref.current[ix],
                planLocations: locItivTuples.filter(([, itivId]) => itivId === itiv.id).map(([locId]) => locId),
              }));
              await save({ variables: { inputs, newGroupName: ref.current.last } });
              closeModal();
            }}
          />
        )}
      </ModalFooter>
    </>
  );
}

type PlanLocation = DesignPackageItivCardFragment["scope"]["planLocations"][number];
type SimplifiedPlanLocation = { simplifiedPath: string } & PlanLocation;
type Row = SimpleHeaderAndData<SimplifiedPlanLocation>;

function useCreateColumns(
  placeholders: DesignPackageItivCardFragment[],
  locToItiv: Record<string, string>,
  setLocToItiv: (locToItiv: Record<string, string>) => void,
  ref: React.MutableRefObject<string[]>,
): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Location",
      data: (loc) => loc.simplifiedPath,
    }),
    ...placeholders.map((itiv, ix) =>
      column<Row>({
        header: () => (
          <EditableDiv onInput={(i) => (ref.current[ix] = i.currentTarget.innerText)}>{ref.current[ix]}</EditableDiv>
        ),
        data: (loc) => (
          <Checkbox
            label=""
            checkboxOnly
            selected={locToItiv[loc.id] === itiv.id}
            onChange={() => setLocToItiv({ [loc.id]: itiv.id })}
          />
        ),
        w: "140px",
      }),
    ),
    column<Row>({
      header: () => (
        <EditableDiv onInput={(i) => (ref.current[placeholders.length] = i.currentTarget.innerText)}>New</EditableDiv>
      ),
      data: (loc) => (
        <Checkbox
          label=""
          checkboxOnly
          selected={locToItiv[loc.id] === "new"}
          onChange={() => setLocToItiv({ [loc.id]: "new" })}
        />
      ),
      w: "100px",
    }),
  ];
}

/**
 * We don't need all the extra styling from a normal input. Just a basic div that's editable. We're doing this without
 * figma/ux, so subject to change.
 */
function EditableDiv({
  children,
  onInput,
}: React.PropsWithChildren<{ onInput: React.FormEventHandler<HTMLDivElement> }>) {
  return (
    <div css={Css.cursorPointer.hmaxc.$} contentEditable suppressContentEditableWarning onInput={onInput}>
      {children}
    </div>
  );
}

export function useTakeoffLocationsModal() {
  const { openModal } = useModal();
  const context = useDesignPackageConfiguratorContext();

  return (placeholderItivId: string) => {
    openModal({
      size: "xl",
      content: (
        <DesignPackageConfiguratorContext.Provider value={context}>
          <TakeoffLocationsModal placeholderItivId={placeholderItivId} />
        </DesignPackageConfiguratorContext.Provider>
      ),
    });
  };
}

/**
 * Location pathnames can get very long and verbose, and notably have much repetition when viewed side by side.
 * This can take a collection of Locations with an {id,path} and remove from {path} the common prefix of all paths.
 * i.e. given:
 * [
 *  "Building~Level 1~Kitchen~Nook~Cabinet~Top Drawer~Pull",
 *  "Building~Level 1~Kitchen~Nook~Cabinet~Bottom Drawer~Pull",
 * ]
 * this can detect "Building~Level 1~Kitchen~Nook~Cabinet~" as the common prefix and remove it, resulting in:
 * ["Top Drawer~Pull", "Bottom Drawer~Pull"]
 */
export function simplifyLocationPaths(locations: PlanLocation[]): SimplifiedPlanLocation[] {
  const locTuples = locations.map((loc) => Array.from(loc.pathArray));
  while (locTuples.everyHasSame((tup) => tup.first) && locTuples.first?.nonEmpty) {
    locTuples.forEach((tup) => tup.shift());
  }
  return locTuples.map((tup, ix) => ({
    ...locations[ix],
    simplifiedPath:
      tup.join(" > ").trim() ||
      // if we cleared out everything (maybe there was only 1?), just show the whole original path
      locations[ix].pathArray.join(" > ").trim(),
  }));
}
