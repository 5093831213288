import { useMemo } from "react";
import { ToDoType, ToDoTypeDetail, useToDoTypesQuery } from "../../generated/graphql-types";
import { sortBy } from "src/utils";

export function useToDoTypes(): ToDoTypeDetail[] {
  const typesQuery = useToDoTypesQuery({
    fetchPolicy: "cache-first",
  });

  const toDoTypes = (
    typesQuery.data?.toDoTypes ?? Object.values(ToDoType).map((code) => ({ code, name: code.replace(/_/g, " ") }))
  )
    // Filter out warranty to-dos for now in the future we will remove this type entirely
    .filter((t) => t.code !== ToDoType.Warranty);

  const ordered = useMemo(() => sortBy(toDoTypes, (t) => t.name), [toDoTypes]);

  return ordered;
}
