import { RouteTabWithContent, TabContent, Tabs } from "@homebound/beam";
import { FinancesPageSharedQuery, useFinancesPageSharedQuery } from "src/generated/graphql-types";
import { BillsPage } from "src/routes/bills/BillsPage";
import { InvoicesPage } from "src/routes/invoices/InvoicesPage";
import { PageHeader } from "src/routes/layout/PageHeader";
import { billsPath, invoicesPath, invoiceTermsPath, purchaseOrdersPath } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { PurchaseOrdersPage } from "../developments/purchase-orders/PurchaseOrdersPage";
import { InvoiceTermsPage } from "./invoice-terms/InvoiceTermsPage";
import add from "date-fns/add";
import { DateFilterOperation } from "src/utils";
import { DateOnly } from "src/utils/dates";

export function FinancesPage() {
  const query = useFinancesPageSharedQuery();
  return queryResult(query, {
    data: (data) => <FinancesPageFilterDataView shared={data} />,
  });
}

type FinancesPageFilterDataViewProps = {
  shared: FinancesPageSharedQuery;
};

function FinancesPageFilterDataView({ shared }: FinancesPageFilterDataViewProps) {
  const tabs: RouteTabWithContent[] = [
    {
      name: "Bills & Credits",
      href: billsPath,
      path: billsPath,
      render: () => <BillsPage shared={shared} />,
    },
    {
      name: "Invoices",
      href: invoicesPath,
      path: invoicesPath,
      render: () => <InvoicesPage shared={shared} />,
    },
    {
      name: "Purchase Orders",
      href: purchaseOrdersPath,
      path: purchaseOrdersPath,
      render: () => <PurchaseOrdersPage isGlobal={true} />,
    },
    { name: "Invoice Terms", href: invoiceTermsPath, path: invoiceTermsPath, render: () => <InvoiceTermsPage /> },
  ];

  return (
    <>
      <PageHeader title="Finances" hideTitle left={<Tabs tabs={tabs} />} />
      <TabContent tabs={tabs} />
    </>
  );
}

export const financesDefaultFilter = {
  dueDate: {
    op: DateFilterOperation.Before,
    value: new DateOnly(add(new Date(), { months: 1 })),
  },
};
