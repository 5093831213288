import {
  Button,
  Css,
  HbLoadingSpinner,
  Palette,
  PresentationProvider,
  RichTextField,
  StaticField,
  Switch,
  ToggleChipGroup,
} from "@homebound/beam";
import {
  DesignPackageItemSlotEditorDocument,
  useBidItemPickerBidItemDetailQuery,
  useProductItivCurrentSelectionsQuery,
  useItApi_SaveItemTemplateItemVersionsMutation,
  useItivPlaceholderRequirementsQuery,
  ProductItivCurrentSelectionsQueryResult,
} from "src/generated/graphql-types";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Icon } from "src/components";
import { useSetState } from "react-use";
import { fail, noop, sanitizeHtml } from "src/utils";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { useDesignPackageConfiguratorContext } from "../../DesignPackageConfiguratorContext";
import { ChipStealingPreview } from "../../components/ChipStealingPreview";
import { createDesignCatalogUrl, createDesignPackageProductSearchUrl } from "src/RouteUrls";
import { useHistory, useParams } from "react-router-dom";
import { DesingPackageSearchParams } from "src/routes/routesDef";
import { PageHeader } from "src/routes/layout/PageHeader";
import { h100ExclHdr } from "../../DesignPackageConfigurator";
import { designUpgradeTagColor, planPackageTagColor } from "../../../designCatalogAtoms";

export function ItemSlotBidItemPage() {
  const { designPackageId, placeholderItivId, productItivId } = useParams<DesingPackageSearchParams>();
  const history = useHistory();
  const { designPackage, selectedTemplateId, bidItemId } = useDesignPackageConfiguratorContext();
  const isDisabledBidItem = bidItemId === "noBidItem";

  // 99% shot placeholder is already in the cache from prior steps, so return that immediately if available
  const placeholderQuery = useItivPlaceholderRequirementsQuery({
    variables: { placeholderItivId },
    fetchPolicy: "cache-first",
  });
  const productItivQuery = useProductItivCurrentSelectionsQuery({
    variables: { productItivId: productItivId ?? "" },
    skip: !productItivId,
  });
  const { data, loading } = useBidItemPickerBidItemDetailQuery({
    variables: { id: bidItemId! },
    skip: !!isDisabledBidItem,
  });
  const { designOptionRpogs, selectedOptions, setSelectedOptions, upgradeRpogs, planRpogs } = useManageOptionSelections(
    productItivQuery.data,
  );

  // the user's "desire" to use planOptions is only part of the equation
  const [maybeShowPlanOptions, setMaybeShowPlanOptions] = useState(false);
  const [designNotes, setDesignNotes] = useState<string | null | undefined>();
  const showPlanOptions = selectedOptions.plans?.nonEmpty || (maybeShowPlanOptions && planRpogs.nonEmpty);

  const [saveNewOrExistingProductItiv] = useItApi_SaveItemTemplateItemVersionsMutation({
    // reload ItemSlotEditor with any backfilled cards. Much easier than requerying for them via mutation-peel-back.
    refetchQueries: [DesignPackageItemSlotEditorDocument],
  });

  const bidItem = data?.bidItems.first;
  if (loading || (!bidItem && !isDisabledBidItem)) return <HbLoadingSpinner />;

  const placeholderIti = placeholderQuery.data?.itemTemplateItemVersion.scope;
  const mv = bidItem?.parentMaterialVariant;
  const listing = mv?.listing;
  if (!isDisabledBidItem && (!mv || !listing)) fail("BidItem has no parent Material Variant or listing");

  return (
    <div css={{ ...Css.df.fdc.$, ...h100ExclHdr }}>
      <PageHeader
        title="Product Details"
        breadcrumb={[
          { href: createDesignCatalogUrl(), label: "Design Packages" },
          { href: createDesignCatalogUrl(designPackage.id), label: designPackage.name },
        ]}
        backButton={() => history.goBack()}
        xss={Css.p3.$}
      />
      <div css={Css.df.jcc.bgGray100.oya.$}>
        <div css={Css.df.fdc.gap4.maxwPx(1040).mbPx(80).$}>
          <div css={Css.xl3Md.$}>Select product for {placeholderIti?.item.name}</div>
          {isDisabledBidItem ? (
            <>
              <div css={Css.xl3.$}>Apply Options to Empty Slot for {placeholderIti?.item.name}</div>
              <div>
                <div css={Css.df.fdr.aifs.py3.gap4.$}>
                  <div css={Css.h100.mhPx(338).wPx(300).df.fdc.aic.jcc.gap1.tac.ba.bcGray700.br8.bshBasic.$}>
                    <Icon /* Not a CTA */ icon="remove" color={Palette.Gray700} inc={4} />
                    <div css={Css.gray700.baseSb.maxw50.$}>Make this an empty Product Slot</div>
                  </div>
                  <div css={Css.wPx(400).df.fdc.gap1.$}>
                    <span>
                      Sometimes a product type isn't offered with certain Package Options (e.g., no refrigerator with
                      the Essential package). In these cases, tell Design Packages to leave it empty!
                    </span>
                    <span>Specify which Package Options won't offer a {placeholderIti?.item.name}.</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div css={Css.xl3.$}>Product Details</div>
              <div>
                <div css={Css.xlSb.$}>{listing?.name ?? "no name?"}</div>
                <div css={Css.df.fdr.aifs.py3.gap4.$}>
                  <div>
                    <img
                      src={mv?.featuredImage?.asset?.previewUrl ?? PRODUCT_FALLBACK_IMG_URL}
                      alt={`${listing?.name}-product`}
                      css={Css.h100.wPx(300).objectCover.oh.$}
                    />
                  </div>
                  <div css={Css.wPx(400).df.fdc.gap1.$}>
                    <PresentationProvider fieldProps={{ labelStyle: "left" }}>
                      <StaticField label="Manufacturer" value={listing?.brand?.name} />
                      <StaticField label="SKU/Model #" value={mv?.modelNumber ?? "n/a"} />
                      {mv?.materialAttributeValues.map((mav) => (
                        // NOTE: This field might need to be updated to handle range values
                        //    SC-55230
                        <StaticField key={mav.id} label={mav.dimension.name} value={mav?.value ?? undefined} />
                      ))}
                    </PresentationProvider>
                  </div>
                </div>
              </div>
            </>
          )}
          <div /* Divider */ css={Css.bb.w100.bcGray300.$} />
          <div css={Css.df.fdc.gap2.mb2.$}>
            <ChipStealingPreview
              selectedOptions={selectedOptions}
              placeholderItivId={placeholderItivId}
              productItivId={productItivId}
            />
            <div css={Css.xl2.$}>Apply To:</div>
            {designOptionRpogs.isEmpty && upgradeRpogs.isEmpty && planRpogs.isEmpty && (
              <div css={Css.asc.xl.$}>No options available</div>
            )}
            {designOptionRpogs
              .sortBy((rpog) => rpog.order)
              .map((rpog) => (
                <ToggleChipGroup
                  key={rpog.id}
                  label={rpog.name}
                  options={rpog.options.map((rpo) => ({
                    label: rpo.name,
                    value: rpo.id,
                    startAdornment: rpo.designPackageAbbreviation ? (
                      <span css={Css.smBd.$}>{rpo.designPackageAbbreviation}</span>
                    ) : undefined,
                  }))}
                  values={selectedOptions[rpog.id] ?? []}
                  onChange={(opitonIds) => setSelectedOptions({ [rpog.id]: opitonIds })}
                  xss={rpog.group.designPackageTagColor ? Css.bgColor(rpog.group.designPackageTagColor).$ : undefined}
                />
              ))}
            {upgradeRpogs.nonEmpty && (
              <ToggleChipGroup
                label="Upgrades"
                values={selectedOptions["upgrades"] ?? []}
                onChange={(optionIds) => setSelectedOptions({ upgrades: optionIds })}
                options={upgradeRpogs
                  .flatMap((rpog) => rpog.options)
                  .map((rpo) => ({
                    label: rpo.name,
                    value: rpo.id,
                    startAdornment: rpo.designPackageAbbreviation ? (
                      <span css={Css.smBd.$}>{rpo.designPackageAbbreviation}</span>
                    ) : undefined,
                  }))}
                xss={Css.bgColor(designUpgradeTagColor).$}
              />
            )}
            {planRpogs.nonEmpty && (
              <>
                <Switch
                  label="Applies to all plans"
                  labelStyle="left"
                  tooltip="Apply this product to all plans in the design package. If disabled, you can specifically designate this product to a given plan."
                  selected={!showPlanOptions /* `applies to all` ==> don't show planoptions */}
                  onChange={(v) => {
                    setMaybeShowPlanOptions(!v); // since we're inverting the switch, we need to invert the update
                    if (v) setSelectedOptions({ plans: [] }); // clear out selections, as "Applies to all" means individual selections are no longer important
                  }}
                />
                {showPlanOptions && (
                  <ToggleChipGroup
                    label="Plans"
                    values={selectedOptions["plans"] ?? []}
                    onChange={(optionIds) => setSelectedOptions({ plans: optionIds })}
                    options={planRpogs
                      .flatMap((rpog) => rpog.options)
                      .map((rpo) => ({
                        label: rpo.name,
                        value: rpo.id,
                        startAdornment: rpo.designPackageAbbreviation ? (
                          <span css={Css.smBd.$}>{rpo.designPackageAbbreviation}</span>
                        ) : undefined,
                      }))}
                    xss={Css.bgColor(planPackageTagColor).$}
                  />
                )}
              </>
            )}
            <div /* Design Notes */>
              <RichTextField
                label="Design Notes"
                placeholder="Add some notes..."
                value={productItivQuery.data?.itemTemplateItemVersion.scope.specifications ?? ""}
                onChange={setDesignNotes}
                onBlur={noop}
                onFocus={noop}
              />
            </div>
          </div>
        </div>
        <div
          /* BottomPanel  */ css={Css.absolute.bottom0.w100.df.fdr.jcfe.aic.p4.bgWhite.bt.bcGray300.gap2.sm.hPx(80).$}
        >
          <Button
            variant="textSecondary"
            label="Back to Product Search"
            onClick={createDesignPackageProductSearchUrl(designPackageId, placeholderItivId, productItivId)}
            size="lg"
          />
          {productItivId && (
            <Button
              variant="tertiaryDanger"
              label="Clear Product Selection"
              onClick={async () => {
                await saveNewOrExistingProductItiv({
                  variables: { items: [{ id: productItivId, bidItemId: null }] },
                });
                history.push(createDesignCatalogUrl(designPackageId));
              }}
              size="lg"
            />
          )}
          <Button
            variant="secondary"
            label="Cancel"
            onClick={() => history.push(createDesignCatalogUrl(designPackageId))}
            size="lg"
          />
          <Button
            label="Add Product"
            onClick={async () => {
              await saveNewOrExistingProductItiv({
                variables: {
                  items: [
                    {
                      specifications: sanitizeHtml(designNotes ?? ""),
                      ...(isDisabledBidItem
                        ? { isDisabledBidItem: true }
                        : { bidItemId: bidItem?.id ?? fail("unable to determine bidItem ID to save") }),
                      optionIds: Object.values(selectedOptions).flat().compact().unique(),
                      // If we have a product itiv update it, otherwise create new
                      ...(productItivId
                        ? // UPDATE
                          { id: productItivId }
                        : // CREATE
                          {
                            placeholderId: placeholderIti?.id ?? fail("can't save without placeholder iti"), // `placeholderIti` is only nullable if the query hasn't loaded, which should complete long before users are smashing buttons.
                            // TODO: Eventually selectedTemplateId will be user-selectable, at which point we need to block this operation from being executable in non-Draft templates long before this fn ever invokes.
                            templateId: selectedTemplateId,
                            slotId: placeholderIti?.slot.id ?? fail("can't save without slot"), // should be inferrable (by backend) via placeholder but config.setDefault breaks backend tests
                            totalCostInCents: 0, // backend throws without this
                          }),
                    },
                  ],
                },
              });
              history.push(createDesignCatalogUrl(designPackageId));
            }}
            disabled={!placeholderIti}
            size="lg"
          />
        </div>
      </div>
    </div>
  );
}

/** Manages some quirks around Options and Chip-Stealing */
function useManageOptionSelections(productItivQuery: ProductItivCurrentSelectionsQueryResult["data"]) {
  const { selectedOptionIds, designPackage, loading } = useDesignPackageConfiguratorContext();
  const [selectedOptions, setSelectedOptions] = useSetState<Record<string, undefined | string[]>>({});

  const [forDesignRpogs, upgradeRpogs, planRpogs] = useMemo(() => {
    return [
      designPackage.optionGroups.filter((rpog) => rpog.forDesignPackages),
      designPackage.optionGroups.filter((rpog) => rpog.forDesignUpgrade),
      designPackage.optionGroups.filter((rpog) => rpog.isPlanPackage),
    ];
  }, [designPackage.optionGroups]);

  /** Re-init currently-selected options from productItiv when its query resolves */
  useEffect(() => {
    if (forDesignRpogs.isEmpty && upgradeRpogs.isEmpty && planRpogs.isEmpty) return;
    const selectedRpoIds =
      productItivQuery?.itemTemplateItemVersion.scope.options.map((rpo) => rpo.id) ?? selectedOptionIds;

    setSelectedOptions({
      ...forDesignRpogs.mapToObject((rpog) => [
        rpog.id,
        rpog.options.filter((rpo) => selectedRpoIds.isEmpty || selectedRpoIds.includes(rpo.id)).map((rpo) => rpo.id),
      ]),
      upgrades: upgradeRpogs
        .flatMap((rpog) => rpog.options)
        .filter((rpo) => selectedRpoIds.includes(rpo.id))
        .map((rpo) => rpo.id),
      plans: planRpogs
        .flatMap((rpog) => rpog.options)
        .filter((rpo) => selectedRpoIds.includes(rpo.id))
        .map((rpo) => rpo.id),
    });
  }, [productItivQuery, forDesignRpogs, upgradeRpogs, setSelectedOptions, planRpogs, selectedOptionIds]);

  /** Forcefully reenable options if all are deselected, as "No" options means "All" options. Only applies to forDesignPackage RPOGs. */
  useEffect(() => {
    forDesignRpogs
      // where an option group is empty
      .filter((rpog) => selectedOptions[rpog.id]?.isEmpty && rpog.options.nonEmpty)
      // re-select all options in that group
      .forEach((rpog) => setSelectedOptions({ [rpog.id]: rpog.options.map((rpo) => rpo.id) }));
  }, [forDesignRpogs, selectedOptions, setSelectedOptions]);

  return { selectedOptions, setSelectedOptions, designOptionRpogs: forDesignRpogs, upgradeRpogs, planRpogs };
}
