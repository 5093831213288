import {
  CollapseToggle,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  Tag,
  Tooltip,
  cardStyle,
  column,
  emptyCell,
} from "@homebound/beam";
import { ReadOnlyReadyPlanOptionRowFragment } from "src/generated/graphql-types";
import { pluralize } from "src/utils";
import { useMemo } from "react";

type OptionsTableProps = {
  readyPlanOptions: ReadOnlyReadyPlanOptionRowFragment[];
};

export function ReadyOnlyOptionsTable({ readyPlanOptions }: OptionsTableProps) {
  const rows: GridDataRow<Row>[] = useMemo(() => createRows(readyPlanOptions), [readyPlanOptions]);

  return (
    <GridTable
      rows={rows}
      columns={createColumns(readyPlanOptions)}
      style={tableStyles}
      rowStyles={rowStyles}
      // TODO: it would be nice to have virtualization and a full page scroll
      // as="virtual"
    />
  );
}

export enum Columns {
  Select,
  Name,
  Type,
  DefaultOf,
  Prerequisites,
  Conflicts,
}

function createColumns(rpos: ReadOnlyReadyPlanOptionRowFragment[]): GridColumn<Row>[] {
  return [
    column<Row>({
      id: `${Columns.Name}`,
      header: "Name",
      parent: ({ rpo }, row) => ({
        content: () => (
          <div css={Css.df.aic.gap2.$}>
            <div css={Css.df.if(rpo.optionGroup.isSingleOptionGroup).fdc.$}>
              {rpo.optionGroup.isMultiOptionGroup && <Icon icon="archive" xss={Css.mrPx(5).$} />}
              <span css={Css.lgSb.$}>{rpo.optionGroup.name}</span>
              {rpo.optionGroup.isSingleOptionGroup && (
                <div css={Css.df.gap1.aic.$}>
                  <div css={Css.xs.$}>{rpo.globalOption.code}</div>
                  <Tag text={rpo.active ? "Active" : "Archived"} type={rpo.active ? "success" : "warning"} />
                  {rpo.location?.name && <Tag text={rpo.location.name} type="neutral" />}
                </div>
              )}
            </div>
          </div>
        ),
        value: `${rpo.optionGroup.name} ${rpo.globalOption.code}`,
      }),
      child: ({ rpo }, { row }) => ({
        content: () => (
          <div css={Css.df.aic.gap2.$}>
            <div css={Css.df.fdc.$}>
              <span css={Css.baseSb.$}>{rpo.name}</span>
              <div css={Css.df.gap1.aic.$}>
                <span css={Css.xs.$}>{rpo.globalOption.code}</span>
                <Tag text={rpo.active ? "Active" : "Archived"} type={rpo.active ? "success" : "warning"} />
                {rpo.location?.name && <Tag text={rpo.location.name} type="neutral" />}
              </div>
            </div>
          </div>
        ),
        value: `${rpo.name} ${rpo.globalOption.code}`,
        css: Css.sm.$,
      }),
      w: "700px",
    }),
    column<Row>({
      id: `${Columns.Type}`,
      header: "Type",
      parent: ({ rpo }) => ({
        content: () => (
          <>
            {rpo.type.name && (
              <Tag
                text={rpo.type.name}
                type={rpo.optionGroup.isSingleOptionGroup ? undefined : "info"}
                xss={rpo.optionGroup.isSingleOptionGroup ? Css.bgPurple200.$ : undefined}
              />
            )}
          </>
        ),
        value: rpo.type.name,
      }),
      child: ({ rpo }) => ({
        content: () => (
          <div css={Css.df.fdc.gap1.$}>
            {rpo.optionGroup.name && (
              <div>
                <Tag text={rpo.optionGroup.name} type="info" />
              </div>
            )}
            {rpo.type.name && (
              <div>
                <Tag text={rpo.type.name} xss={Css.bgPurple200.$} />
              </div>
            )}
          </div>
        ),
        value: rpo.type.name,
      }),
    }),
    column<Row>({
      id: `${Columns.Prerequisites}`,
      header: "Prerequisites",
      parent: ({ rpo }) =>
        // show same prereq select if group only has 1 member
        rpo.optionGroup.isSingleOptionGroup ? <PrerequisitesTag rpo={rpo} /> : emptyCell,
      child: ({ rpo }) => <PrerequisitesTag rpo={rpo} />,
    }),
    column<Row>({
      id: `${Columns.Conflicts}`,
      header: "Conflicts",
      parent: ({ rpo }) => (rpo.optionGroup.isSingleOptionGroup ? <ConflictTag rpo={rpo} /> : emptyCell),
      child: ({ rpo }) => <ConflictTag rpo={rpo} />,
    }),
    column<Row>({
      id: `${Columns.DefaultOf}`,
      header: "Default of",
      parent: ({ rpo }, row) =>
        rpo.optionGroup.isSingleOptionGroup ? (
          <div css={Css.df.gap2.$}>
            <DefaultOfTag rpo={rpo} /> <CollapseToggle row={row.row} />
          </div>
        ) : (
          <CollapseToggle row={row.row} />
        ),
      align: "right",
      child: ({ rpo }) => <DefaultOfTag rpo={rpo} />,
    }),
  ];
}

function createRows(rpos: ReadOnlyReadyPlanOptionRowFragment[]): GridDataRow<Row>[] {
  // Group by option groups
  const optionGroups = rpos.filter((rpo) => rpo.active).groupBy((o) => o.optionGroup?.id ?? "");
  // sort by group order
  return Object.entries(optionGroups)
    .sort(
      ([optionGroupIdA, rposA], [optionGroupIdB, rposB]) =>
        (rposA[0].optionGroup?.order ?? Number.POSITIVE_INFINITY) -
        (rposB[0].optionGroup?.order ?? Number.POSITIVE_INFINITY),
    )
    .map(([optionGroupId, rpos]) => {
      return {
        kind: "parent" as const,
        data: {
          rpo: rpos[0],
        },
        id: optionGroupId,
        draggable: true,
        children: rpos[0].optionGroup.isMultiOptionGroup
          ? rpos
              .flatMap((rpo) => ({
                kind: "child" as const,
                id: rpo.id,
                data: {
                  rpo: rpo,
                },
                draggable: true,
              }))
              .sortBy((rpo) => rpo.data.rpo.order ?? Number.POSITIVE_INFINITY)
          : undefined,
      };
    });
}

export function ConflictTag({
  rpo,
}: {
  rpo: Pick<ReadOnlyReadyPlanOptionRowFragment, "optionConflicts" | "optionConflictChildren">;
}) {
  const conflicts = rpo.optionConflicts.concat(rpo.optionConflictChildren);
  if (conflicts.length === 0) return null;
  return (
    <Tooltip
      title={
        <>
          <div>{`${pluralize(conflicts, "Conflict")}:`}</div>
          {conflicts.map((c) => (
            <div key={c.id}>
              {c.name} {c.code}
            </div>
          ))}
        </>
      }
      placement="top"
    >
      <Tag text={`${conflicts.length} ${pluralize(conflicts.length, "conflict")}`} type="caution" />
    </Tooltip>
  );
}

export function PrerequisitesTag({ rpo }: { rpo: Pick<ReadOnlyReadyPlanOptionRowFragment, "optionPrerequisites"> }) {
  if (rpo.optionPrerequisites.length === 0) return null;
  return (
    <Tooltip
      title={
        <>
          <div>{`${pluralize(rpo.optionPrerequisites, "Prerequisite")}:`}</div>
          {rpo.optionPrerequisites.map((c) => (
            <div key={c.id}>
              {c.name} {c.code}
            </div>
          ))}
        </>
      }
      placement="top"
    >
      <Tag
        text={`${rpo.optionPrerequisites.length} ${pluralize(rpo.optionPrerequisites.length, "prerequisite")}`}
        type="caution"
      />
    </Tooltip>
  );
}

function DefaultOfTag({ rpo }: { rpo: Pick<ReadOnlyReadyPlanOptionRowFragment, "optionDefaultsIf"> }) {
  if (rpo.optionDefaultsIf.length === 0) return null;
  return (
    <Tooltip
      title={
        <>
          <div>{`${pluralize(rpo.optionDefaultsIf, "Default of")}:`}</div>
          {rpo.optionDefaultsIf.map((c) => (
            <div key={c.id}>
              {c.name} {c.code}
            </div>
          ))}
        </>
      }
      placement="top"
    >
      <Tag
        text={`${rpo.optionDefaultsIf.length} ${pluralize(rpo.optionDefaultsIf.length, "default of")}`}
        type="caution"
      />
    </Tooltip>
  );
}

type HeaderRow = { kind: "header"; id: string; data: undefined };

type ParentRow = {
  kind: "parent";
  id: string;
  data: {
    rpo: ReadOnlyReadyPlanOptionRowFragment;
  };
};

type ChildRow = {
  kind: "child";
  id: string;
  data: {
    rpo: ReadOnlyReadyPlanOptionRowFragment;
  };
};

export type Row = HeaderRow | ParentRow | ChildRow;

const tableStyles = {
  ...cardStyle,
  cellCss: {
    ...cardStyle.cellCss,
    ...Css.aic.pPx(12).$,
  },
  presentationSettings: {
    borderless: false,
  },
};

const rowStyles = { child: { rowCss: Css.bgGray100.$, cellCss: Css.bgGray100.$ } };
