import { SelectField } from "@homebound/beam";
import { useProductOfferingContext, useProductOfferingStore } from "../ProductOfferingScopeContext";
import { isItivOrderField } from "src/routes/libraries/plan-package/takeoffs/utils";
import { offeringItemTableGroupByOptions } from "../utils";

// Copied from src/routes/libraries/plan-package/takeoffs/components/GroupBySelectField.tsx
export function GroupBySelectField() {
  const itemTableGroupBy = useProductOfferingStore((state) => state.itemTableGroupBy);
  const state = useProductOfferingContext();
  const setItemTableGroupBy = state.getState().setItemTableGroupBy;

  return (
    <SelectField
      compact
      label="Group By"
      labelStyle="inline"
      getOptionLabel={(o) => o.name}
      getOptionValue={(o) => o.id}
      onSelect={(v) => v && isItivOrderField(v) && setItemTableGroupBy(v)}
      options={offeringItemTableGroupByOptions()}
      value={itemTableGroupBy}
      sizeToContent
    />
  );
}
