import { FetchResult } from "@apollo/client";
import {
  ButtonMenu,
  Css,
  HasIdAndName,
  MenuItem,
  ScrollableContent,
  Switch,
  useComputed,
  useModal,
  useSnackbar,
  useToast,
} from "@homebound/beam";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { HeaderBar, SearchBox } from "src/components";
import { MaxInlineChips } from "src/components/MaxInlineChips";
import {
  ApplyItemTemplateToChangeEventInput,
  BulkAddProjectItem_ProjectItemFragment,
  ChangeEventLineItemsTabChangeEventFragment,
  ChangeEventLineItemsTabItemFragment,
  ChangeEventPageDocument,
  ChangeEventType,
  CreateChangeEventLineItemsTabMutation,
  DeleteEntitiesInput,
  HomeownerSelectionStatus,
  SaveChangeEventLineItemInput,
  SpecsAndSelections_UnitOfMeasureFragment,
  useAddChangeEventLineItemsTabMutation,
  useApplyItemTemplateToChangeEventLineItemsTabMutation,
  useChangeEventLineItemsTabQuery,
  useCreateChangeEventLineItemsTabMutation,
  useDeleteChangeEventLineItemsTabMutation,
  useSaveChangeEventLineItemsTabMutation,
  useSaveHomeownerSelectionChangeEventLineItemsTabMutation,
} from "src/generated/graphql-types";
import { BulkAddItemCostCodesModal } from "src/routes/components/BulkAddItemCostCodesModal";
import { BulkAddProjectItemsModal, CeliGroupByValue } from "src/routes/components/BulkAddProjectItemsModal";
import { DeleteConfirmationModal } from "src/routes/components/DeleteConfirmationModal";
import { NotFound } from "src/routes/NotFound";
import { ChangeEventLineItemsTable } from "src/routes/projects/change-events/ChangeEventLineItemsTable";
import { AppliedTemplatesModal } from "src/routes/projects/change-events/components/AppliedTemplatesModal";
import { ApplyTemplateModal } from "src/routes/projects/change-events/components/ApplyTemplateModal";
import { ImportFromCommittedModal } from "src/routes/projects/change-events/components/ImportFromCommittedModal";
import { ImportChangeEventLineItemsModal } from "src/routes/projects/change-events/ImportChangeEventLineItemsModal";
import { ChangeEventLineItemStore } from "src/routes/projects/change-events/models/ChangeEventLineItemStore";
import { ChangeEventParams, ProjectParams } from "src/routes/routesDef";
import { createTradeScopeOfWorkUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";
import { entityNotFoundError } from "src/utils/error";
import { openNewTab } from "src/utils/window";
import { UpdateMarkupModal } from "../selections/bulkActions/UpdateMarkupModal";
import { useQueryStringPersistedToggle } from "src/hooks";

export function ChangeEventLineItemsTab() {
  const { projectId, changeEventId } = useParams<ProjectParams & ChangeEventParams>();
  const query = useChangeEventLineItemsTabQuery({ variables: { id: changeEventId } });
  return queryResult(query, {
    data: ({ changeEvent, locations, unitsOfMeasure, items }) => {
      if (!changeEvent) {
        return <NotFound error={entityNotFoundError(changeEventId)} />;
      }

      return changeEvent ? (
        <ChangeEventLineItems
          changeEvent={changeEvent}
          projectId={projectId}
          unitsOfMeasure={unitsOfMeasure}
          locations={locations}
          items={items}
        />
      ) : (
        <NotFound />
      );
    },
  });
}

type ChangeEventLineItemsProps = {
  changeEvent: ChangeEventLineItemsTabChangeEventFragment;
  projectId: string;
  unitsOfMeasure: SpecsAndSelections_UnitOfMeasureFragment[];
  locations: HasIdAndName[];
  items: ChangeEventLineItemsTabItemFragment[];
};

function ChangeEventLineItems(props: ChangeEventLineItemsProps) {
  const { changeEvent, projectId, unitsOfMeasure, locations, items } = props;
  const [addCeli] = useAddChangeEventLineItemsTabMutation();
  const [saveCeli] = useSaveChangeEventLineItemsTabMutation();
  const [saveCelis] = useSaveChangeEventLineItemsTabMutation();
  const [createCeli] = useCreateChangeEventLineItemsTabMutation();
  const [deleteCelis] = useDeleteChangeEventLineItemsTabMutation();
  const [applyItemTemplateToChangeEvent] = useApplyItemTemplateToChangeEventLineItemsTabMutation();
  const [search, setSearch] = useState("");
  const { openModal } = useModal();

  const { canEditLineItems, projectStage } = changeEvent;
  const {
    stage: { code: stage },
    projectItems,
  } = projectStage;

  // include any PIs that aren't already on this change event (not associated to CE CELI)
  const projectItemsNotInChangeEvent = useMemo(() => {
    const changeEventCeliPiIds = changeEvent.lineItems.map((celi) => celi.projectItem.id);
    return projectItems.filter((pi) => !changeEventCeliPiIds.includes(pi.id));
  }, [changeEvent, projectItems]);

  const onSave = useCallback(
    async (input: SaveChangeEventLineItemInput) => {
      await saveCeli({ variables: { input: { changeEventLineItems: [input] } } });
    },
    [saveCeli],
  );

  // create change event line items store
  const store = useMemo(() => new ChangeEventLineItemStore([], false, onSave), [onSave]);
  useEffect(() => {
    store.isInternal = changeEvent.type.code === ChangeEventType.Internal;
    store.upsertLineItems(changeEvent.lineItems);
  }, [changeEvent, store]);

  const onCreate = useCallback(
    async (input: SaveChangeEventLineItemInput) => {
      const response = await createCeli({ variables: { input: { changeEventLineItems: [input] } } });
      const items = response.data?.saveChangeEventLineItems.changeEventLineItems;
      if (items) {
        store.upsertLineItems(items);
        // keep track of list of ids that are "new" items from inline add
        const newCeliIds = items.map((item) => item.id);
        setPinnedCelis((currentlyPinnedCeliIds) => [...currentlyPinnedCeliIds, ...newCeliIds]);
      }
    },
    [createCeli, store],
  );

  const onBulkCreate = useCallback(
    async (inputs: SaveChangeEventLineItemInput[]) => {
      const response = await saveCelis({ variables: { input: { changeEventLineItems: [...inputs] } } });
      const items = response.data?.saveChangeEventLineItems.changeEventLineItems;
      if (items) store.upsertLineItems(items);
    },
    [saveCelis, store],
  );

  const onDelete = useCallback(
    async (input: DeleteEntitiesInput) => {
      const idsToDelete = input.id;
      await deleteCelis({
        variables: { input },
        update: (cache) => idsToDelete.forEach((id) => cache.evict({ id: `ChangeEventLineItem:${id}` })),
      });
      store.removeLineItems(idsToDelete);
    },
    [deleteCelis, store],
  );

  const [showPlanSource, togglePlanSource] = useQueryStringPersistedToggle("showPlanSource");

  /**
   * CELI items to pin
   * pin new CELIs created via Add Inline Celi behavior
   * pinned CELIs are reset when ChangeEventLineItemTable unmounts
   */
  const [pinnedCelis, setPinnedCelis] = useState<string[]>([]);

  async function onAddExistingProjectItem(
    projectItems: BulkAddProjectItem_ProjectItemFragment[],
    fromTradeBilled: boolean = false,
  ) {
    const changeEventLineItemsInput: SaveChangeEventLineItemInput[] = projectItems.map((pi) => ({
      changeEventId: changeEvent?.id,
      projectItemId: pi.id,
      totalCostInCents: fromTradeBilled
        ? pi.budgetFinancials.tradeBilled - pi.budgetFinancials.revisedInternalBudget
        : undefined,
    }));
    const result = await addCeli({
      variables: { input: { changeEventLineItems: changeEventLineItemsInput } },
    });
    const items = result.data?.saveChangeEventLineItems.changeEventLineItems;
    if (items) {
      store.upsertLineItems(items);
    }
  }

  async function onApplyTemplate(input: ApplyItemTemplateToChangeEventInput) {
    const result = await applyItemTemplateToChangeEvent({ variables: { input } });
    const items = result.data?.applyItemTemplateToChangeEvent.lineItems;
    if (items) {
      store.upsertLineItems(items);
    }
  }

  async function handleSaveChangeEventLineItems(result: FetchResult<CreateChangeEventLineItemsTabMutation>) {
    const items = result.data?.saveChangeEventLineItems.changeEventLineItems;
    if (items) {
      store.upsertLineItems(items);
    }
  }

  const existingProjectItemIds = useComputed(() => store.items.map((celi) => celi.projectItemId), [store.items]);

  return (
    <>
      {changeEvent && changeEvent.appliedTemplates.length > 0 && (
        <div>
          <div css={Css.xsMd.gray700.$}>Templates</div>
          <MaxInlineChips
            values={changeEvent.appliedTemplates.map((templ) => templ.displayName)}
            maxInlineChips={2}
            onButtonClick={() => {
              openModal({
                content: <AppliedTemplatesModal templates={changeEvent.appliedTemplates} />,
              });
            }}
          />
        </div>
      )}
      <HeaderBar
        right={
          <>
            <Switch label="Show Plan Source" selected={showPlanSource} onChange={togglePlanSource} />
            {/* observe mobx store (line items) to trigger re-renders */}
            <SearchBox onSearch={setSearch} />
            <BulkActionsMenu store={store} canEditLineItems={canEditLineItems} onDelete={onDelete} />
            {canEditLineItems.allowed && (
              <>
                <ButtonMenu
                  items={[
                    {
                      label: "From Template",
                      onClick: () => {
                        openModal({
                          content: (
                            <ApplyTemplateModal
                              changeEventId={changeEvent.id}
                              onApplyTemplate={onApplyTemplate}
                              stage={stage}
                            />
                          ),
                        });
                      },
                    },
                    {
                      label: "From Import",
                      onClick: () => {
                        openModal({
                          content: (
                            <ImportChangeEventLineItemsModal
                              onImport={handleSaveChangeEventLineItems}
                              changeEventId={changeEvent.id}
                              unitsOfMeasure={unitsOfMeasure}
                              items={items}
                              locations={locations}
                            />
                          ),
                        });
                      },
                    },
                    {
                      label: "From Project",
                      onClick: () => {
                        openModal({
                          size: "xxl",
                          content: (
                            <BulkAddProjectItemsModal
                              onAdd={(_, pis) => onAddExistingProjectItem(pis)}
                              stage={stage}
                              projectId={projectId}
                              groupBy={CeliGroupByValue.CostCode}
                              hiddenProjectItemIds={existingProjectItemIds}
                              submitButtonText="Add to Change Event"
                            />
                          ),
                        });
                      },
                    },
                    {
                      label: "From Committed",
                      onClick: () =>
                        openModal({ content: <ImportFromCommittedModal changeEventId={changeEvent.id} /> }),
                    },
                    {
                      label: "From Trade Billed",
                      onClick: () => {
                        openModal({
                          size: "xxl",
                          content: (
                            <BulkAddProjectItemsModal
                              onAdd={(_, pis) => onAddExistingProjectItem(pis, true)}
                              stage={stage}
                              projectId={projectId}
                              groupBy={CeliGroupByValue.CostCode}
                              hiddenProjectItemIds={(pi) => {
                                // Hide existing project items or matches the trade billed with the revised internal budget
                                const { tradeBilled, revisedInternalBudget } = pi.budgetFinancials;
                                return existingProjectItemIds.includes(pi.id) || tradeBilled === revisedInternalBudget;
                              }}
                              submitButtonText="Add to Change Event"
                            />
                          ),
                        });
                      },
                    },
                    {
                      label: "By Cost Codes",
                      onClick: () => {
                        openModal({
                          size: "xxl",
                          content: (
                            <BulkAddItemCostCodesModal
                              onAdd={(celis) => onBulkCreate(celis)}
                              stage={stage}
                              changeEventId={changeEvent.id}
                              locations={locations}
                            />
                          ),
                        });
                      },
                    },
                    {
                      label: "By Trade Partner",
                      onClick: () => {
                        openModal({
                          size: "xl",
                          content: (
                            <BulkAddProjectItemsModal
                              onAdd={(_, pis) => onAddExistingProjectItem(pis)}
                              stage={stage}
                              projectId={projectId}
                              groupBy={CeliGroupByValue.CurrentTradePartner}
                              hiddenProjectItemIds={existingProjectItemIds}
                              submitButtonText="Add to Change Event"
                            />
                          ),
                        });
                      },
                    },
                  ]}
                  trigger={{ label: "Add Items" }}
                />
              </>
            )}
          </>
        }
      />
      <ScrollableContent virtualized>
        <ChangeEventLineItemsTable
          items={items}
          locations={locations}
          readOnly={!changeEvent.canEditLineItems.allowed}
          onCreate={onCreate}
          onDelete={onDelete}
          pinnedCelis={pinnedCelis}
          projectItemsList={projectItemsNotInChangeEvent}
          store={store}
          search={search}
          showPlanSource={showPlanSource}
          unitsOfMeasure={unitsOfMeasure}
        />
      </ScrollableContent>
    </>
  );
}

function BulkActionsMenu(props: {
  store: ChangeEventLineItemStore;
  canEditLineItems: { allowed: boolean };
  onDelete: (input: DeleteEntitiesInput) => void;
}) {
  const { store, canEditLineItems, onDelete } = props;
  const [saveCelis] = useSaveChangeEventLineItemsTabMutation();
  const [saveHomeownerSelection] = useSaveHomeownerSelectionChangeEventLineItemsTabMutation({
    refetchQueries: [ChangeEventPageDocument],
  });
  const { showToast } = useToast();
  const { openModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const hasZeroSelectedItems = useComputed(() => store.selectedLineItems.length === 0, [store]);

  const onSave = useCallback(
    async (...inputs: SaveChangeEventLineItemInput[]) => {
      const { errors } = await saveCelis({ variables: { input: { changeEventLineItems: [...inputs] } } });
      const errorText = errors && errors.map((e) => e.message).join(" ");
      if (errorText) {
        triggerNotice({ message: errorText, icon: "error" });
      }
      return !!errorText;
    },
    [saveCelis, triggerNotice],
  );

  const menuItems: MenuItem[] = [
    {
      disabled: hasZeroSelectedItems,
      label: "Create Scope of Work",
      onClick: () => {
        const selectedCeliIds = store.selectedLineItems.map((celi) => celi.id);
        openNewTab(createTradeScopeOfWorkUrl(selectedCeliIds));
        store.clearSelections();
      },
    },
    {
      disabled: hasZeroSelectedItems || !canEditLineItems.allowed,
      label: "Finalize Product Selections",
      onClick: async () => {
        const selectedLineItems = store.selectedLineItems;

        // return homeownerSelections for any proposedSelections that are not finalized
        // Note that we purposefully allow finalizing selections w/o selected options to unblock
        // selections-that-maybe-aren't-really-selected using the undecided option
        const itemsWithProposedSelection = selectedLineItems
          .filter((celi) => celi.proposedSelection && !celi.proposedIsFinalized)
          .map((celi) => ({ id: celi.proposedSelection!.id, status: HomeownerSelectionStatus.Finalized }));

        // no bulk save multiple homeownerSelections endpoint available so looping
        const response = await Promise.all(
          itemsWithProposedSelection.map(async (hs) => await saveHomeownerSelection({ variables: { input: hs } })),
        );
        showToast({
          type: "success",
          message: `${response.length} proposed ${response.length === 1 ? "selection" : "selections"} finalized of ${
            selectedLineItems.length
          } selected ${selectedLineItems.length > 1 ? "items" : "item"}`,
        });
      },
    },
    {
      disabled: hasZeroSelectedItems || !canEditLineItems.allowed,
      label: "Unfinalize Product Selections",
      onClick: async () => {
        const selectedLineItems = store.selectedLineItems;

        // return homeownerSelections for any proposedSelections that are finalized
        const itemsWithProposedSelection = selectedLineItems
          .filter((celi) => celi.proposedSelectedOption && celi.proposedIsFinalized)
          .map((celi) => ({ id: celi.proposedSelection?.id, status: HomeownerSelectionStatus.Selected }));

        // no bulk save multiple homeownerSelections endpoint available so looping
        const response = await Promise.all(
          itemsWithProposedSelection.map(async (hs) => await saveHomeownerSelection({ variables: { input: hs } })),
        );
        showToast({
          type: "success",
          message: `${response.length} proposed ${response.length === 1 ? "selection" : "selections"} unfinalized of ${
            selectedLineItems.length
          } selected ${selectedLineItems.length > 1 ? "items" : "item"}`,
        });
      },
    },
    {
      disabled: hasZeroSelectedItems || store.disableProjectMarkup || !canEditLineItems.allowed,
      label: "Update Markup",
      onClick: () =>
        openModal({
          content: <UpdateMarkupModal selectedItems={store.selectedLineItems} onSave={onSave} kind="changeEvent" />,
        }),
    },
    {
      disabled: hasZeroSelectedItems || !canEditLineItems.allowed,
      label: "Delete Items",
      onClick: () => {
        const selectedCeliIds = store.selectedLineItems.map((celi) => celi.id);
        openModal({
          content: (
            <DeleteConfirmationModal
              confirmationMessage={
                <div>
                  <p css={Css.smMd.mb2.$}>
                    {selectedCeliIds.length} {selectedCeliIds.length === 1 ? "item" : "items"} selected
                  </p>
                  <p>Are you sure you want to delete these items? This action cannot be undone.</p>
                </div>
              }
              entityType="Change Event Line Item"
              onConfirmDelete={async () => {
                if (selectedCeliIds && selectedCeliIds.length > 0) {
                  await onDelete({ id: selectedCeliIds });
                }
              }}
            />
          ),
        });
      },
    },
  ];
  return <ButtonMenu items={menuItems} trigger={{ label: "Actions" }} />;
}
