import {
  Button,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  ModalBody,
  ModalHeader,
  ModalProps,
  Tag,
  Tooltip,
  TriggerNoticeProps,
  simpleHeader,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { PdfViewer } from "src/components";
import { lienWaiverStatusTagMapper } from "src/components/detailPane/bill/utils";
import {
  BillPageV2_LienWaiverFragment,
  BillPageV2_LienWaiver_PandaDocFragment,
  useSavePandaDocMutation,
} from "src/generated/graphql-types";
import { PandaDocConfirmationModal } from "src/routes/development-commitments/components/PandaDocConfirmationModal";
import { formatList } from "src/utils";
import { openNewTab } from "src/utils/window";

type ColumnHeaderRow = {
  kind: "header";
  id: string;
};
type LienWaiverRow = {
  kind: "lienWaiver";
  id: string;
  data: BillPageV2_LienWaiverFragment;
};
type Row = ColumnHeaderRow | LienWaiverRow;

const createColumns = (
  isLienWaiverReceiver: boolean,
  openModal: (props: ModalProps) => void,
  triggerNotice: (props: TriggerNoticeProps) => {
    close: () => void;
  },
  savePandaDoc: any,
) => {
  const conditionColumn: GridColumn<Row> = {
    header: () => ({
      content: <div css={Css.$}>Condition</div>,
      name: "Condition",
    }),
    lienWaiver: (row) => {
      const [lienWaiverStatus, text] = lienWaiverStatusTagMapper({
        status: row?.status?.code,
        isLienWaiverReceiver: isLienWaiverReceiver,
        condition: row?.condition?.name,
      });
      return {
        content: (
          <div css={Css.$}>
            <Tag type={lienWaiverStatus} text={text} />
          </div>
        ),
      };
    },
  };

  const typeColumn: GridColumn<Row> = {
    header: () => ({
      content: <div css={Css.$}>Type</div>,
      name: "Type",
    }),
    lienWaiver: (row) => ({
      content: <div css={Css.$}>{row.type.name}</div>,
    }),
  };

  const documentColumn: GridColumn<Row> = {
    header: () => ({
      content: <div css={Css.$}>Document</div>,
      name: "Document",
    }),
    lienWaiver: (row) => ({
      content: () => {
        function openDocumentModal(pandaDoc: BillPageV2_LienWaiver_PandaDocFragment) {
          openModal({
            content: <PandaDocModal pandaDoc={pandaDoc} />,
            size: "xxl",
          });
        }
        const hasDocument = !!row.pandaDoc.document;
        const isDisabled =
          (hasDocument && !row.pandaDoc.document) || (!hasDocument && !row.pandaDoc.externalPandaDocUrl);

        return (
          <Tooltip title={isDisabled ? "Not uploaded" : ""} disabled={!isDisabled} placement="bottom">
            <IconButton
              data-testid="lien-waiver-panda-doc"
              icon="document"
              onClick={() =>
                hasDocument ? openDocumentModal(row.pandaDoc) : openNewTab(row.pandaDoc.externalPandaDocUrl!)
              }
              disabled={isDisabled}
            />
          </Tooltip>
        );
      },
    }),
  };

  const reuploadColumn: GridColumn<Row> = {
    header: () => ({
      content: <div css={Css.$}>Re-upload</div>,
      name: "Reupload",
    }),
    lienWaiver: (row) => ({
      content: () => {
        return (
          <IconButton
            data-testid="lien-waiver-re-upload"
            icon="upload"
            onClick={async () => {
              openModal({
                content: <PandaDocConfirmationModal ownerId={row.id} documentName="lien waiver" isReUpload />,
              });
            }}
            disabled={
              row.canCreatePandaDoc.allowed === false
                ? formatList(row.canCreatePandaDoc.disabledReasons.map((r) => r.message))
                : undefined
            }
          />
        );
      },
    }),
  };

  return [conditionColumn, typeColumn, documentColumn, reuploadColumn];
};

export const PandaDocModal = ({ pandaDoc }: { pandaDoc: BillPageV2_LienWaiver_PandaDocFragment }) => {
  const tids = useTestIds({}, "pandaDocModal");
  const { document } = pandaDoc;

  return (
    <div {...tids}>
      <ModalHeader>Panda Doc</ModalHeader>
      <ModalBody>
        <div css={Css.bgWhite.bshBasic.oa.br8.mb3.$}>
          <PdfViewer hasHeader assets={[document?.asset]} title={""} />
        </div>
      </ModalBody>
    </div>
  );
};

const createRows = (data: BillPageV2_LienWaiverFragment[]) => {
  const rows: GridDataRow<Row>[] = [];
  rows.push(simpleHeader);
  data.forEach((l) => {
    rows.push({
      kind: "lienWaiver",
      id: l.id,
      data: l,
    });
  });

  return rows;
};

export const BillLienWaiversDataTable = ({
  isLienWaiverReceiver,
  lienWaivers,
}: {
  isLienWaiverReceiver: boolean;
  lienWaivers: BillPageV2_LienWaiverFragment[];
}) => {
  const { openModal } = useModal();
  const [savePandaDoc] = useSavePandaDocMutation();
  const { triggerNotice } = useSnackbar();
  const columns = [...createColumns(isLienWaiverReceiver, openModal, triggerNotice, savePandaDoc)];
  const rows: GridDataRow<Row>[] = createRows(lienWaivers);

  return (
    <div css={Css.px1.$}>
      <GridTable columns={columns} rows={rows} />
    </div>
  );
};
