import { Checkbox, Chip, Css, useComputed } from "@homebound/beam";
import React, { useMemo } from "react";
import { createDesignCatalogUrl } from "src/RouteUrls";
import { PageHeader } from "src/routes/layout/PageHeader";
import { useNewDesignPackageContext } from "./NewDesignPackageContext";
import { designUpgradeTagColor } from "../designCatalogAtoms";
import { isDefined } from "src/utils";

type NewDesignPackageWizardPageProps = unknown;
export function NewDesignPackageWizardPage({ children }: React.PropsWithChildren<NewDesignPackageWizardPageProps>) {
  return (
    <>
      <PageHeader
        title="Create a new Design Package"
        breadcrumb={[
          { label: "Design Packages", href: createDesignCatalogUrl() },
          { label: "Create a New Design Package", href: "" },
        ]}
        xss={Css.px4.mb0.$}
      />
      <div /* fullpage, scrollable area, gray */ css={Css.df.fdc.aic.p4.w100.h100.oys.pbPx(215).bgGray100.$}>
        <div css={Css.df.fdc.aifs.gap3.hmaxc.maxwPx(600).$}>{children}</div>
      </div>
    </>
  );
}

type CheckRowProps = React.PropsWithChildren<{
  label: string;
  selected: boolean;
  onChange: (selected: boolean) => void;
  bb?: boolean;
}>;

export function CheckRow({ label, selected, onChange, bb, children }: CheckRowProps) {
  return (
    <label
      css={{
        ...Css.df.aic.gap2.cursorPointer.px2.py1.onHover.bgGray200.$, // base
        ...Css.if(!!bb).bb.bcGray300.$, // border bottom
        ...Css.onHover.bgGray200.$, // hover state (if/onHover were competing with each other, so break them out)
      }}
    >
      <Checkbox
        label={label}
        selected={selected}
        onChange={(val) => onChange(val)}
        checkboxOnly
        withLabelElement={false}
      />
      {children}
    </label>
  );
}

/**
 * Figma specs show this "post-it" note in the top right of the 2nd and 3rd steps that effectively show
 * the current formState rendered as chips for SpecLevel, Style, InteriorScheme, etc. Should probably make
 * a dedicated ticket to hydrate this with real data and keep it up to date.
 *
 * https://www.figma.com/file/FWNFerGSHMiZevdyqWdO7C/Q2-2023-%7C-%F0%9F%8F%A0-Design-Packages?type=design&node-id=6124-160265&mode=design&t=uajyHmOvjnPzodRY-4
 *
 * The specs make this look like it shows "Up to this point" data so it might be annoying if it's updating in
 * realtime with the current step. May want a Config Prop to control which stages of data are actually showing.
 */
export function NewDesignPackageOverviewPostit() {
  const { formState, globalOptionGroups, globalUpgradeGroups } = useNewDesignPackageContext();
  const name = useComputed(() => formState.value.name, [formState]);
  /** This prevents us having to load back up the tree from gog->go->gog in gql again after flatmapping */
  const gogByGoId = useMemo(
    () =>
      Object.fromEntries(
        [...globalOptionGroups, ...globalUpgradeGroups].flatMap((gog) =>
          gog.globalOptions.map((go) => [go.id, gog] as const),
        ),
      ),
    [globalOptionGroups, globalUpgradeGroups],
  );

  const packageOptions = useComputed(
    () =>
      globalOptionGroups
        .flatMap((gog) => gog.globalOptions)
        .filter((go) => formState.withGlobalOptionIds.value?.includes(go.id)),
    [formState],
  );

  const upgradeOptions = useComputed(
    () =>
      globalUpgradeGroups
        .flatMap((gog) => gog.globalOptions)
        .filter((go) => formState.withGlobalOptionIds.value?.includes(go.id)),
    [formState],
  );

  return (
    <div css={Css.mwPx(120).maxwPx(260).p2.bgWhite.absolute.rightPx(35).topPx(120).br4.bshBasic.df.fdc.gap2.$}>
      <div>
        <div css={Css.xs.$}>Name</div>
        <div css={Css.xlMd.$}>{name}</div>
      </div>
      <div>
        <div css={Css.xs.pb1.$}>Package Options</div>
        <div>
          {packageOptions.map((go) => (
            <Chip
              key={go.id}
              text={
                <div css={Css.df.gap1.sm.$}>
                  {go.designPackageAbbreviation && <span css={Css.smBd.$}>{go.designPackageAbbreviation}</span>}
                  {go.name}
                </div>
              }
              xss={
                Css.mPx(4).if(isDefined(go.group.designPackageTagColor)).bgColor(go.group.designPackageTagColor!).white
                  .$
              }
            />
          ))}
        </div>
      </div>
      <div>
        <div css={Css.xs.pb1.$}>Addons & Interior Options</div>
        <div>
          {upgradeOptions.map((go) => (
            <Chip
              key={go.id}
              text={
                <div css={Css.df.gap1.$}>
                  {go.designPackageAbbreviation && <span css={Css.smBd.$}>{go.designPackageAbbreviation}</span>}
                  {go.name}
                </div>
              }
              xss={Css.bgColor(designUpgradeTagColor).white.mPx(4).$}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
