import { CommentFeed } from "src/components";
import { Card } from "src/components/Card";
import { useGetCommentsQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { SectionTitle } from "src/routes/libraries/plan-package/details/plan-overview/components/SectionTitle";

export function PlanPackageComments({ planPackageId }: { planPackageId: string }) {
  const query = useGetCommentsQuery({ variables: { commentableId: planPackageId } });
  return (
    <div>
      <SectionTitle title="Comments" />
      <Card>
        {queryResult(query, ({ commentable }) => (
          <CommentFeed commentable={commentable} showFollowers={false} showCommentTitle={false} />
        ))}
      </Card>
    </div>
  );
}
