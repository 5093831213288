import { Autocomplete } from "@homebound/beam";
import { PresentationFieldProps } from "@homebound/beam/dist/components/PresentationContext";
import { useState } from "react";
import {
  TradePartnerAutocomplete_TradePartnerFragment,
  useTradePartnerAutocompleteQuery,
} from "src/generated/graphql-types";

export type ProjectAutocompleteFieldProps = PresentationFieldProps & {
  label?: string;
  onSelect: (selected: TradePartnerAutocomplete_TradePartnerFragment) => void;
  disabled?: boolean;
  autofillValue?: string;
  required?: boolean;
};

export function TradePartnerAutocompleteField(props: ProjectAutocompleteFieldProps) {
  const { onSelect, disabled, label = "Trade Partner", autofillValue, ...others } = props;
  const [search, setSearch] = useState<string>(autofillValue ?? "");

  const { data: searchData, previousData: previousSearchData } = useTradePartnerAutocompleteQuery({
    variables: { filter: { search }, page: { limit: 100 } },
    skip: !search,
    nextFetchPolicy: "cache-first",
  });

  return (
    <Autocomplete<TradePartnerAutocomplete_TradePartnerFragment>
      {...others}
      label={label}
      disabled={disabled}
      getOptionValue={(o) => o.id}
      getOptionLabel={(o) => o.name}
      onSelect={onSelect}
      onInputChange={(t) => setSearch(t ?? "")}
      value={search}
      options={searchData?.tradePartnersPage.entities ?? previousSearchData?.tradePartnersPage.entities ?? []}
    />
  );
}
