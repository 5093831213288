import {
  BoundCheckboxGroupField,
  Button,
  Checkbox,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSuperDrawer,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { Price } from "src/components/Price";
import {
  AcceptChangeEventModalQuery,
  ChangeEventType,
  SaveChangeEventInput,
  useAcceptChangeEventModalQuery,
  useSaveApprovalChangeEventMutation,
} from "src/generated/graphql-types";
import { ApprovalSuperDrawer } from "src/routes/components/Approval/ApprovalSuperDrawer";
import { CreateChangeEventApproval } from "src/routes/components/Approval/changeEvents/CreateChangeEventApproval";
import { queryResult } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";

type AcceptChangeEventModalProps = {
  changeEventId: string;
};

type AcceptChangeEventModalViewProps = {
  changeEvent: AcceptChangeEventModalQuery["changeEvent"];
};

export function AcceptChangeEventModal({ changeEventId }: AcceptChangeEventModalProps) {
  const query = useAcceptChangeEventModalQuery({ variables: { id: changeEventId! } });
  return queryResult(query, (data) => <AcceptChangeEventModalView changeEvent={data.changeEvent!} />);
}
function AcceptChangeEventModalView({ changeEvent }: AcceptChangeEventModalViewProps) {
  const { closeModal } = useModal();
  const { openInDrawer } = useSuperDrawer();
  const [saveApprovalChangeEvent] = useSaveApprovalChangeEventMutation();

  const tradePartnerOptions = useMemo(
    () => changeEvent.impactedTrades.map((tp) => ({ label: tp.name, value: tp.id })).sortByKey("label"),
    [changeEvent],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      tradesToDraftCommitmentsFor: changeEvent.tradesToDraftCommitmentsFor.nonEmpty
        ? changeEvent.tradesToDraftCommitmentsFor.map((tp) => tp.id)
        : [],
      id: changeEvent.id,
    },
  });

  const isChargeable = changeEvent.type.code === ChangeEventType.External;

  return (
    <>
      <ModalHeader>Accept Change Event</ModalHeader>
      <ModalBody>
        <h2 css={Css.baseMd.mb2.$}>{`CE #${changeEvent.identifier} - ${changeEvent.title}`}</h2>
        <div css={Css.w100.mb3.$}>
          <div css={Css.dib.w50.$}>
            <div css={Css.xsMd.fw4.mb(0.5).$}>Reviewed Contract Impact</div>
            <Price id="budgetImpact" valueInCents={changeEvent.budgetImpactInCents} displayDirection />
          </div>
          <div css={Css.dib.w50.$}>
            <div css={Css.xsMd.fw4.mb(0.5).$}>Revised Budget Impact</div>
            <Price id="revisedBudget" valueInCents={changeEvent.totalCostInCents} displayDirection />
          </div>
        </div>

        <div css={Css.bb.bcGray200.w100.mb2.$} />

        <h2 css={Css.baseMd.mb2.$}>Are you sure you want to accept this change event?</h2>
        {isChargeable && (
          <h2 data-testid="chargeableWarning" css={Css.sm.red700.mb2.$}>
            Proceeding with this action will result in charges to the{" "}
            {changeEvent.projectStage.project.lotType.clientNoun}
          </h2>
        )}
        <div css={Css.mb3.$}>
          <BoundCheckboxGroupField
            field={formState.tradesToDraftCommitmentsFor}
            options={tradePartnerOptions}
            label="Generate draft commitments for the following Trade Partners:"
          />
          <div css={Css.py2.$}>
            <Observer>
              {() => (
                <Checkbox
                  label="None"
                  selected={!!formState.tradesToDraftCommitmentsFor.value?.isEmpty}
                  onChange={() => formState.tradesToDraftCommitmentsFor.set([])}
                />
              )}
            </Observer>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button variant="tertiary" label="Cancel" onClick={closeModal} />
              <Button
                label="Submit for Approval"
                onClick={async () => {
                  const formValue = formState.value;

                  formState.dirty &&
                    (await saveApprovalChangeEvent({
                      variables: {
                        input: {
                          id: changeEvent.id,
                          tradesToDraftCommitmentsFor: formValue.tradesToDraftCommitmentsFor,
                        },
                      },
                    }));

                  closeModal();
                  openInDrawer({
                    content: (
                      <ApprovalSuperDrawer approvalIds={[changeEvent.approval?.id]}>
                        <CreateChangeEventApproval subjectId={changeEvent.id} />
                      </ApprovalSuperDrawer>
                    ),
                  });
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

const formConfig: ObjectConfig<SaveChangeEventInput> = {
  id: { type: "value", rules: [required] },
  tradesToDraftCommitmentsFor: { type: "value" },
};
