import {
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  selectColumn,
  simpleHeader,
  Switch,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { useContext, useMemo } from "react";
import { priceCell } from "src/components";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import {
  BidContractRevisionStatus,
  ManageProjectsBudgets_BidContractRevisionFragment,
  ManageProjectsBudgets_PotentialProjectItemFragment,
  useAssignWorkToBidContractsMutation,
  useManageProjectsBudgetsStepQuery,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { groupBy, noop, queryResult } from "src/utils";
import { UploadDevContractContext, useDevContractWizardBcrUpload } from "../UploadDevelopmentContractWizard";

export function ManageProjectsBudgetsPage() {
  const { bidContractRevisionId } = useContext(UploadDevContractContext);
  // Query the data from all project items that this BCR could be applied to
  const query = useManageProjectsBudgetsStepQuery({ variables: { bcrId: bidContractRevisionId } });

  return queryResult(query, (data) => {
    return <ManageProjectsBudgetsView bidContractRevision={data.bidContractRevision} />;
  });
}

type ManageProjectsBudgetsViewProps = {
  bidContractRevision: ManageProjectsBudgets_BidContractRevisionFragment;
};

export function ManageProjectsBudgetsView({ bidContractRevision }: ManageProjectsBudgetsViewProps) {
  const [filterByTradePartner, toggleFilter] = useToggle(false);
  const filteredProjectItems = useMemo(
    () =>
      bidContractRevision.potentialProjectItems.filter((ppi) =>
        filterByTradePartner
          ? ppi.projectItem.bidContractLineItem?.revision.bidContract.tradePartner?.id ===
            bidContractRevision.bidContract.tradePartner?.id
          : true,
      ),
    [bidContractRevision, filterByTradePartner],
  );

  const tableApi = useGridTableApi<Row>();
  const { setChangeEventIds, bidContractRevisionId } = useContext(UploadDevContractContext);
  const { closeModal } = useModal();
  const columns = useMemo(() => createColumns(bidContractRevision.version), [bidContractRevision]);
  const rows = useMemo(() => createRows(filteredProjectItems), [filteredProjectItems]);
  const selectedProjectItemIds = useComputed(() => tableApi.getSelectedRowIds("projectItem"), [tableApi]);
  const { uploadBcr, signBcr } = useDevContractWizardBcrUpload(bidContractRevisionId);
  const { triggerNotice } = useSnackbar();
  const [assignWorkToBidContract, { loading }] = useAssignWorkToBidContractsMutation({
    variables: { input: [{ projectItemIds: selectedProjectItemIds, bidContractRevisionId }] },
  });
  // Skip uploading to PD if the contract is an internal estimate
  const handleBcr =
    bidContractRevision.status.code === BidContractRevisionStatus.Signed
      ? // Upload Price Agreement is defacto-signed so don't try to upload anything if BCR is already signed
        noop
      : bidContractRevision.bidContract.isInternalEstimate
        ? signBcr
        : uploadBcr;

  return (
    <>
      <div css={Css.mb2.$}>
        {!bidContractRevision.bidContract.isInternalEstimate && (
          <Switch
            data-testid="toggleFilter"
            label="Only show projects this trade is assigned to"
            selected={filterByTradePartner}
            onChange={toggleFilter}
          />
        )}
      </div>
      <div css={Css.pb8.oya.maxh("50VH").$}>
        <GridTable
          columns={columns}
          rows={rows}
          style={{ grouped: true }}
          stickyHeader
          rowStyles={rowStyles}
          api={tableApi}
          fallbackMessage="There are no available project items"
        />
      </div>
      <NextStepButton
        label="Confirm Budgets"
        disabled={loading || selectedProjectItemIds.isEmpty}
        disableCurrentStepOnLeaving
        onClick={async () => {
          const { errors, data } = await assignWorkToBidContract();
          const changeEvents = data?.assignWorkToBidContract;
          // Banner is likely set from this error, so close the modal/wizard to reveal it
          if (errors?.nonEmpty) return closeModal();
          if (!changeEvents || changeEvents?.isEmpty) {
            await handleBcr();
            triggerNotice({
              message: "Skipping Approvals because there are no changes to review.",
              icon: "success",
            });
            closeModal();
          } else {
            setChangeEventIds(changeEvents.map((ce) => ce.id));
            await handleBcr();
          }
        }}
      />
    </>
  );
}

// Add border to split between current / proposed / updated
const cellBorderStyles = Css.addIn("& > div:nth-of-type(5)", Css.bl.bcGray400.$)
  .addIn("& > div:nth-of-type(8)", Css.bl.bcGray400.$)
  .addIn("& > div:nth-of-type(10)", Css.bl.bcGray400.$).$;

const rowStyles: RowStyles<Row> = {
  header: { rowCss: cellBorderStyles },
  projectItem: { rowCss: cellBorderStyles },
};

type HeaderRow = { kind: "header" };
type GroupedRow = { kind: "group"; data: ManageProjectsBudgets_PotentialProjectItemFragment[] };
type ProjectItemRow = { kind: "projectItem"; data: ManageProjectsBudgets_PotentialProjectItemFragment };
type Row = HeaderRow | GroupedRow | ProjectItemRow;

function createColumns(bcrVersion: string): GridColumn<Row>[] {
  return [
    collapseColumn<Row>(),
    selectColumn<Row>(),
    column<Row>({
      header: "Project Item",
      group: (data) => ({ content: data.first?.projectItem.project.name, colspan: 5 }),
      projectItem: ({ projectItem }) => projectItem.displayName,
    }),
    column<Row>({
      header: "Plan",
      group: emptyCell,
      projectItem: ({ projectItem }) => projectItem.itemTemplateItem?.template.name,
    }),
    column<Row>({
      header: "Current trade",
      group: emptyCell,
      projectItem: ({ projectItem }) => projectItem.bidContractLineItem?.revision.bidContract.tradePartner?.name,
    }),
    column<Row>({
      header: "Current version",
      group: emptyCell,
      projectItem: ({ projectItem }) => projectItem.bidContractLineItem?.revision.version,
    }),
    numericColumn<Row>({
      header: "Current Cost",
      group: emptyCell,
      projectItem: ({ projectItem }) => priceCell({ valueInCents: projectItem.totalCostInCents }),
    }),
    column<Row>({
      header: "Proposed version",
      group: emptyCell,
      projectItem: () => bcrVersion,
    }),
    numericColumn<Row>({
      header: "Proposed cost",
      group: emptyCell,
      projectItem: ({ totalCostInCents }) => priceCell({ valueInCents: totalCostInCents }),
    }),
    numericColumn<Row>({
      header: "Budget impact",
      group: emptyCell,
      projectItem: ({ projectItem, totalCostInCents }) =>
        priceCell({
          valueInCents: totalCostInCents - projectItem.totalCostInCents,
          invertColors: true,
          zeroIs: "neutral",
        }),
    }),
    numericColumn<Row>({
      header: "Updated budget",
      group: emptyCell,
      projectItem: ({ totalCostInCents }) => priceCell({ valueInCents: totalCostInCents }),
    }),
  ];
}

function createRows(projectItems: ManageProjectsBudgets_PotentialProjectItemFragment[]): GridDataRow<Row>[] {
  const groupedProjectItems = Object.entries(groupBy(projectItems, (pi) => pi.projectItem.project.id));

  return [
    simpleHeader,
    ...groupedProjectItems.map(([projectId, projectItems]) => ({
      kind: "group" as const,
      id: projectId,
      data: projectItems,
      children: projectItems.map((pi) => ({
        kind: "projectItem" as const,
        id: pi.projectItem.id,
        data: pi,
      })),
    })),
  ];
}
