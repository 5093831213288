import { Button, Css, MultiSelectField } from "@homebound/beam";
import { useState } from "react";
import { FollowerFragment } from "../generated/graphql-types";
import { useInternalUsers } from "src/hooks/useInternalUsers";

export type FollowersProps = {
  currentFollowers: FollowerFragment[];
  onSave: (followerIds: string[]) => Promise<void>;
  onCancel: () => void;
};

export function Followers(props: FollowersProps) {
  const { currentFollowers, onSave, onCancel } = props;
  const [selectedFollowerIds, setSelectedFollowerIds] = useState<string[]>(currentFollowers.map((f) => f.id));
  const { internalUsers } = useInternalUsers();

  return (
    <div css={Css.wPx(372).$}>
      <MultiSelectField
        label="Watching"
        values={selectedFollowerIds}
        options={internalUsers}
        onSelect={setSelectedFollowerIds}
        data-testid="followers"
      />
      <div css={Css.mt1.df.gap1.$}>
        <Button label="Save" onClick={() => onSave(selectedFollowerIds)} data-testid="followers_save" />
        <Button
          variant="secondary"
          label="Cancel"
          onClick={() => {
            setSelectedFollowerIds(currentFollowers.map((f) => f.id));
            onCancel();
          }}
          data-testid="followers_cancel"
        />
      </div>
    </div>
  );
}
