import { useFormState } from "@homebound/form-state";
import { useHistory, useParams } from "react-router-dom";
import {
  EditProductOfferingFragment,
  useEditProductOfferingPageQuery,
  useReviewStep_SaveProductOfferingMutation,
} from "src/generated/graphql-types";
import { ProductOfferingParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { formConfig } from "../add-product-offering/AddProductOfferingPage";
import { OptionsView } from "../add-product-offering/components/4.OptionsStep";
import { Button, useSnackbar } from "@homebound/beam";
import { createDevelopmentProductOfferingUrl } from "src/RouteUrls";
import { StringParam, useQueryParams } from "use-query-params";
import { PageHeader } from "src/routes/layout/PageHeader";

export function EditProductOfferingPage() {
  const { productOfferingId } = useParams<ProductOfferingParams>();
  const query = useEditProductOfferingPageQuery({ variables: { id: productOfferingId } });
  return queryResult(query, ({ productOffering }) => <EditProductOfferingView productOffering={productOffering} />);
}

export function EditProductOfferingView({ productOffering }: { productOffering: EditProductOfferingFragment }) {
  const [{ devId }] = useQueryParams({
    devId: StringParam,
  });
  const { productOfferingId } = useParams<ProductOfferingParams>();
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const [saveProductOffering] = useReviewStep_SaveProductOfferingMutation();

  const { originalOptionsAndDefaultIfs, allPossibleReadyPlanOptions } = productOffering;
  const externalReadyPlanOptions = originalOptionsAndDefaultIfs.compact().map((o) => ({
    externalReadyPlanOptionId: o?.externalReadyPlanOption.id,
    externalOptionDefaultsIfIds: o.externalOptionDefaultsIfs.map((opt) => opt.id),
  }));

  const formState = useFormState({
    config: formConfig,
    init: {
      externalReadyPlanOptions,
    },
  });

  return (
    <>
      <PageHeader
        title=""
        right={
          <>
            <Button
              size="lg"
              variant="secondary"
              label="Cancel"
              onClick={() => {
                if (devId) {
                  history.push(createDevelopmentProductOfferingUrl(devId, productOfferingId));
                }
              }}
            />

            <Button
              size="lg"
              label="Save Options"
              onClick={async () => {
                if (!formState) return;
                const res = await saveProductOffering({
                  variables: {
                    input: {
                      id: productOffering.id,
                      externalReadyPlanOptions: formState.externalReadyPlanOptions.value,
                    },
                  },
                });
                if (res.data?.saveProductOffering.productOffering) {
                  const productOfferingId = res.data?.saveProductOffering.productOffering.id;
                  triggerNotice({ message: "Offering successfully saved! Procurement will review and start bidding" });
                  // TODO: route to a `global offering detail page` when offering is created from global library
                  if (devId) {
                    history.push(createDevelopmentProductOfferingUrl(devId, productOfferingId));
                  }
                }
              }}
            />
          </>
        }
      />
      <OptionsView formState={formState} readyPlanOptions={allPossibleReadyPlanOptions} />
    </>
  );
}
