import { Css, useModal } from "@homebound/beam";
import { ReadyPlanStatus, useSavePlanPackageMutation } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";

export function PublishPlanPackageModal({ planPackageId }: { planPackageId: string }) {
  const { closeModal } = useModal();
  const [savePlanPackage] = useSavePlanPackageMutation();

  async function publishPlanPackage() {
    await savePlanPackage({ variables: { input: { id: planPackageId, status: ReadyPlanStatus.Active } } });
    closeModal();
  }

  return (
    <ConfirmationModal
      title="Publish Plan"
      label="Publish"
      onConfirmAction={async () => await publishPlanPackage()}
      confirmationMessage={
        <>
          <div css={Css.smSb.mb1.$}>Are you sure you want to publish this Plan?</div>
          <div>Once published, this plan can be used to create Design Packages and Product Offerings.</div>
        </>
      }
    />
  );
}
