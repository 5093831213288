import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal, useSnackbar } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  ResultingDelayResponseFragment,
  ScheduleFlagReasonDetailFragment,
  TaskScheduleDetailsFragment,
  useSaveScheduleFlagMutation,
  useScheduleFlagReasonsQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { formConfig } from "./ScheduleFlag";
import { ScheduleFlagForm } from "./ScheduleFlagForm";

export type ScheduleTaskForFlagModal = Pick<TaskScheduleDetailsFragment, "id" | "name">;
type ScheduleFlagModalProps = {
  resultingDelay: ResultingDelayResponseFragment;
};

export function ScheduleFlagModal({ resultingDelay }: ScheduleFlagModalProps) {
  const { id, name } = resultingDelay.scheduleTask;
  const delayDuration = resultingDelay.durationInDays;

  const query = useScheduleFlagReasonsQuery();

  return queryResult(query, {
    data: ({ scheduleFlagReasons }) => (
      <ScheduleFlagModalContent
        scheduleTaskId={id}
        scheduleFlagReasons={scheduleFlagReasons!}
        scheduleTaskName={name}
        delayDuration={delayDuration}
        oldTaskStart={resultingDelay.oldTaskStart}
        oldTaskEnd={resultingDelay.oldTaskEnd}
      />
    ),
  });
}

function ScheduleFlagModalContent({
  scheduleTaskId,
  scheduleFlagReasons,
  scheduleTaskName,
  delayDuration,
  oldTaskStart,
  oldTaskEnd,
}: {
  scheduleTaskId: string;
  scheduleFlagReasons: ScheduleFlagReasonDetailFragment[];
  scheduleTaskName: string;
  delayDuration: number;
  oldTaskStart: DateOnly;
  oldTaskEnd: DateOnly;
}) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [saveScheduleFlag] = useSaveScheduleFlagMutation();

  const formState = useFormState({
    config: formConfig,
    init: { durationInDays: delayDuration },
  });

  const onSave = async () => {
    const { reasonId, description, durationInDays } = formState.value;
    await saveScheduleFlag({
      variables: {
        input: {
          reasonId: reasonId,
          scheduleTaskId,
          durationInDays,
          description,
          oldTaskStart,
          oldTaskEnd,
        },
      },
    });

    closeModal();
    triggerNotice({ message: "Task's dates changed, and a delay flag was added.", icon: "success" });
  };

  return (
    <>
      <ModalHeader>Add a Delay Flag</ModalHeader>
      <ModalBody>
        <div css={Css.sm.gray700.$}>A delay flag will be added to the recently updated task: {scheduleTaskName}.</div>
        <ScheduleFlagForm formState={formState} reasons={scheduleFlagReasons} />
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Observer>
          {() => <Button label="Confirm" disabled={!formState.canSave()} variant="primary" onClick={onSave} />}
        </Observer>
      </ModalFooter>
    </>
  );
}
