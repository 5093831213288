import { Button, ButtonMenu, Css, StaticField, useModal, useSuperDrawer, useToast } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { useHistory } from "react-router-dom";
import { createBillUrl } from "src/RouteUrls";
import { Price, UppyUploader, formatDate, FileUploadProgress } from "src/components";
import { UploaderProvider } from "src/contexts/UploaderContext";
import {
  useConvertExpenseToBillMutation,
  useSaveExpenseTableDocumentMutation,
  useSaveDocumentsToExpenseMutation,
  ExpensesTable_ExpenseFragment,
  SaveDocumentInput,
  SaveDocumentsToExpenseMutationHookResult,
  SaveExpenseTableDocumentMutationHookResult,
  DocumentType,
} from "src/generated/graphql-types";
import { BudgetSuperDrawer } from "src/routes/projects/budget/components/BudgetSuperDrawer";
import { ChangeCostCodeModal } from "src/routes/projects/expenses/components/ChangeCostCodeModal";
import { ExpenseDocumentsTable } from "src/routes/projects/expenses/components/ExpenseDocumentsTable";
import { ExpenseTableRow, ExpensesTableInput } from "src/routes/projects/expenses/components/ExpensesTable";
import { fail, formatList } from "src/utils";

type ExpenseCellDetailProps = {
  row: ExpenseTableRow;
  unallocatedView: boolean;
  formState: ObjectState<ExpensesTableInput>;
};

export function ExpenseCellDetail({ formState, row, unallocatedView }: ExpenseCellDetailProps) {
  const history = useHistory();
  const { openModal } = useModal();
  const { showToast } = useToast();
  const { openInDrawer } = useSuperDrawer();
  const [convertToBill] = useConvertExpenseToBillMutation();
  const [saveDocuments] = useSaveExpenseTableDocumentMutation();
  const [saveExpenseDocument] = useSaveDocumentsToExpenseMutation();

  const handleConvertToBill = async (row: ExpenseTableRow) => {
    const { data: converted } = await convertToBill({
      variables: { input: { expenseId: row.id } },
    });
    if (!converted) {
      fail("Failed to convert bill.");
    }
    showToast({
      message: "Expense successfully converted to Bill",
      type: "success",
    });
    history.push(createBillUrl(converted?.convertExpenseToBill.bill.id));
  };

  const openBudgetSuperDrawer = (row: ExpenseTableRow) => {
    const { project, costCode } = row;
    const projectItemIdsByCostCode = row.potentialProjectItems.groupBy(
      (ppi) => ppi.item.costCode.id,
      (ppi) => ppi.id,
    );
    const projectItemIds = projectItemIdsByCostCode[costCode!.id] ?? [];
    if (projectItemIds.isEmpty) return;
    openInDrawer({
      content: (
        <BudgetSuperDrawer
          projectItemIds={projectItemIds}
          title={costCode!.displayName}
          projectId={project.id}
          isPiRow={false}
        />
      ),
    });
  };

  const selectedProjectItemIds = row.tableApi.getSelectedRowIds("projectItem");
  const selectedExpenseRows = formState.expenseAllocations.rows.filter((r) =>
    selectedProjectItemIds.includes(r.projectItemId.value!),
  );
  const selectedAmountInCents = selectedExpenseRows.sum((pi) => pi.amountInCents.value || 0);
  const expenseRemainingInCents = row.amountInCents - selectedAmountInCents;

  const hasRemaining = selectedExpenseRows.nonEmpty && expenseRemainingInCents !== 0;

  return (
    <div css={Css.df.fdc.h100.aife.$}>
      <div css={Css.br8.ba.bcGray300.bgWhite.$}>
        <div css={Css.relative.df.jcfe.mt1.mr1.$}>
          <ButtonMenu
            trigger={{ icon: "verticalDots" }}
            items={[
              {
                icon: "refresh",
                label: "Convert to Bill",
                disabled: unallocatedView
                  ? "You can only convert allocated expenses to bills"
                  : !row.canBeConvertedToBill.allowed
                    ? formatList(row.canBeConvertedToBill.disabledReasons.map((a) => a.message))
                    : undefined,
                onClick: () => handleConvertToBill(row),
              },
              {
                icon: "changeEvent",
                label: "Change Cost Code",
                disabled: !unallocatedView
                  ? "You can only change cost code for unallocated expenses"
                  : !row.canChangeCostCode
                    ? !row.hasCostCode
                      ? "Accounting should fix the cost code in Intacct"
                      : "You can only change the cost code for expenses in the current month"
                    : undefined,
                onClick: () => openModal({ size: "sm", content: <ChangeCostCodeModal formState={formState} /> }),
              },
            ]}
          />
        </div>
        <div css={Css.df.gap2.mb3.mx3.$}>
          <StaticField label="ID" data-testid="expenseId">
            <div css={Css.df.fdc.$}>
              <div>{row.id}</div>
              <div>{row.intacctApBillId}</div>
            </div>
          </StaticField>
          <StaticField label="Cost Code">
            {row.hasCostCode && (
              <Button
                variant="text"
                size="sm"
                data-testid="openCostCodeDrawer"
                label={formState.expense.costCode.value?.number}
                onClick={() => openBudgetSuperDrawer(row)}
              />
            )}
          </StaticField>
          <StaticField label="Merchant">
            <div css={Css.wPx(230).$}>
              <div css={Css.dib.if(row.isBrex).red600.$}>{row.intacctVendorName}</div> - {row.description}
            </div>
          </StaticField>
          <StaticField label="Posted Date" value={formatDate(row.expenseDate)} />
          <StaticField label="Amount">
            <div css={Css.mla.$}>
              <Price valueInCents={row.amountInCents} />
            </div>
          </StaticField>
        </div>

        {row.hasCostCode && (
          <UploaderProvider>
            <div css={Css.dg.pcc.mt3.mb1.$}>
              <UppyUploader
                allowedFileTypes={["application/pdf"]}
                onFinish={(file) => handleSaveReceipt(saveDocuments, saveExpenseDocument, row, formState, file)}
                dragDropText={"Drag & drop or click to upload receipts"}
                dragDropWidth={475}
              />
              <FileUploadProgress hideOnComplete />
            </div>
          </UploaderProvider>
        )}
      </div>

      {hasRemaining && (
        <div css={Css.xs.red800.mt2.fr.$}>
          <Price valueInCents={Math.abs(expenseRemainingInCents)} />{" "}
          {expenseRemainingInCents > 0 ? `remains unallocated.` : `over the amount to be allocated.`}
        </div>
      )}

      <div css={Css.w100.if(hasRemaining).mt2.$}>
        <ExpenseDocumentsTable
          documents={formState.expense.documents.value}
          onDelete={async (documentId) => {
            formState.expense.documents.value.delete(documentId);
            formState.expense.documents.set(new Map(formState.expense.documents.value));
            await saveExpenseDocument({
              variables: { id: row.id, documents: [...formState.expense.documents.value.keys()] },
            });
          }}
        />
      </div>
    </div>
  );
}

type SaveDocumentsMutation = SaveExpenseTableDocumentMutationHookResult[0];
type SaveExpensesDocumentMutation = SaveDocumentsToExpenseMutationHookResult[0];

async function handleSaveReceipt(
  saveDocuments: SaveDocumentsMutation,
  saveExpenseDocument: SaveExpensesDocumentMutation,
  expense: ExpensesTable_ExpenseFragment,
  formState: ObjectState<ExpensesTableInput>,
  file: UppyFile,
) {
  const saveDocumentInput: SaveDocumentInput = {
    documentType: DocumentType.Expense,
    name: file.name,
    sizeInBytes: file.size,
    parentId: expense.project.id,
    asset: {
      contentType: file.type,
      fileName: file.name,
      s3Key: file.meta.s3Key as string,
      sizeInBytes: file.size,
    },
  };
  // create entry in Asset table and entry in Document table and associate asset to document
  const { data } = await saveDocuments({ variables: { input: saveDocumentInput } });
  const document = data!.saveDocuments.documents[0];
  formState.expense.documents.set(new Map([...formState.expense.documents.value, [document.id, document]]));
  await saveExpenseDocument({
    variables: { id: expense.id, documents: [...formState.expense.documents.value.keys()] },
  });
}
