import { Observer } from "mobx-react";
import { BoundNumberField, Css, IconButton } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { ProrationFormValues } from "../ProrateBidModal";

const threeDigitCurrencyFormat = { style: "currency" as const, currency: "USD", minimumFractionDigits: 3 };

export function ProrationUnitCostColumn({ prorationState }: { prorationState: ObjectState<ProrationFormValues> }) {
  return (
    <Observer>
      {() => (
        <div css={Css.relative.$}>
          {prorationState.userEntered.value && (
            <div css={Css.absolute.left("-4px").top("2px").z1.$}>
              <IconButton
                icon="pin"
                onClick={() => prorationState.set({ userEntered: false })}
                tooltip="Click the pin to enable estimation overwriting."
              />
            </div>
          )}
          <BoundNumberField
            numberFormatOptions={threeDigitCurrencyFormat}
            numFractionDigits={3}
            field={prorationState.unitCostInCents}
            onChange={(unitCostInCents) => prorationState.set({ unitCostInCents, userEntered: true })}
          />
        </div>
      )}
    </Observer>
  );
}

export function ProrationCostColumn({ prorationState }: { prorationState: ObjectState<ProrationFormValues> }) {
  return (
    <Observer>
      {() => (
        <div css={Css.relative.$}>
          {prorationState.userEntered.value && (
            <div css={Css.absolute.left("-4px").top("2px").z1.$}>
              <IconButton
                icon="pin"
                onClick={() => prorationState.set({ userEntered: false })}
                tooltip="Click the pin to enable estimation overwriting."
              />
            </div>
          )}
          <BoundNumberField
            field={prorationState.costInCents}
            onChange={(costInCents) => prorationState.set({ costInCents, userEntered: true })}
          />
        </div>
      )}
    </Observer>
  );
}
