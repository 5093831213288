import { Css, Icon, increment, Margin, Only, Padding, Palette, Properties } from "@homebound/beam";
import { CommentStreamCountDetailFragment } from "src/generated/graphql-types";
import { sum } from "src/utils/utils";

type CommentCountBubbleXStyles = Pick<Properties, Padding | Margin>;

type CommentCountBubbleProps<X> = {
  streams: CommentStreamCountDetailFragment[];
  onClick?: () => void;
  size?: number;
  xss?: X;
};

export function CommentCountBubble<X extends Only<CommentCountBubbleXStyles, X>>(props: CommentCountBubbleProps<X>) {
  const { streams, onClick, xss, size = 3 } = props;
  const commentCount = streams.map(({ commentCount }) => commentCount).reduce(sum, 0);
  const unreadCount = streams.map(({ unreadCount }) => unreadCount).reduce(sum, 0);
  const dimension = increment(size);
  const unread = unreadCount > 0;
  const count = unread ? unreadCount : commentCount;
  const hasComments = count > 0;
  const color = unread ? Palette.Blue700 : Palette.Gray600;

  return (
    <div css={{ ...Css.df.aic.if(!!onClick).cursorPointer.$, ...xss }} data-testid="commentBubble" onClick={onClick}>
      <svg
        aria-hidden={true}
        width={dimension}
        height={dimension}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        css={Css.fill(color).$}
      >
        <path d={unread ? unReadCommentBubblePath : readCommentBubblePath} />
        {hasComments && (
          <text fill={unread ? Palette.White : undefined} textAnchor="middle" x="12px" y="13px" css={Css.tinyBd.$}>
            {count > 99 ? 99 : count}
          </text>
        )}
      </svg>
      {count > 99 && <Icon icon="plus" inc={size - 2} color={color} />}
    </div>
  );
}

const readCommentBubblePath =
  "M12 2C6.486 2 2 5.589 2 10C2 12.908 3.898 15.516 7 16.934V22L12.34 17.995C17.697 17.852 22 14.32 22 10C22 5.589 17.514 2 12 2ZM12 16H11.667L9 18V15.583L8.359 15.336C5.67 14.301 4 12.256 4 10C4 6.691 7.589 4 12 4C16.411 4 20 6.691 20 10C20 13.309 16.411 16 12 16Z";

const unReadCommentBubblePath =
  "M11.1071 0.714279C4.98266 0.714279 0 4.70063 0 9.59999C0 12.8299 2.10813 15.7267 5.55357 17.3017V22.9286L11.4848 18.4801C17.4349 18.3213 22.2143 14.3983 22.2143 9.59999C22.2143 4.70063 17.2316 0.714279 11.1071 0.714279Z";
