import { GridDataRow } from "@homebound/beam";
import {
  ConfirmLineItemsCostLineItemAllocationFragment,
  ConfirmLineItemsCostLineItemFragment,
  ConfirmLineItemsHomeownerContractLineItemFragment,
  ConfirmLineItemsHomeownerProjectItemFragment,
  InvoiceV2Fragment,
  HomeownerContractChangeOrder,
} from "src/generated/graphql-types";
import { Selectable } from "src/models/Selectable";
import { InvoiceLineItemsDataRow } from "../components/InvoiceLineItemsTable";
import { InvoiceLineItemsFilter } from "../invoice-steps/ConfirmLineItems";
import { InvoiceCostLineItem } from "./InvoiceCostLineItem";
import { calculateHCLIMarkup, isFixedMarkupByContractType } from "../../utils";

type InvoiceHomeownerContractLineItemProps = {
  homeownerContractLineItem: ConfirmLineItemsHomeownerContractLineItemFragment;
  allHomeownerContractLineItems: ConfirmLineItemsHomeownerContractLineItemFragment[];
  filter: InvoiceLineItemsFilter;
  invoice: InvoiceV2Fragment;
  clisAmountUsed: Map<string, number>;
  projectItem: ConfirmLineItemsHomeownerProjectItemFragment;
  projectItemHclis: ConfirmLineItemsHomeownerContractLineItemFragment[];
};

export class InvoiceHomeownerContractLineItem extends Selectable {
  private readonly invoice: InvoiceV2Fragment;
  clisAmountUsed: Map<string, number>;
  allHomeownerContractLineItems: ConfirmLineItemsHomeownerContractLineItemFragment[];
  homeownerContractLineItem: ConfirmLineItemsHomeownerContractLineItemFragment;
  projectItem: ConfirmLineItemsHomeownerProjectItemFragment;
  filter!: InvoiceLineItemsFilter;
  allCostLineItemAllocations: ConfirmLineItemsCostLineItemAllocationFragment[];
  projectItemHclis: ConfirmLineItemsHomeownerContractLineItemFragment[];

  constructor({
    filter,
    homeownerContractLineItem,
    invoice,
    allHomeownerContractLineItems,
    clisAmountUsed,
    projectItem,
    projectItemHclis,
  }: InvoiceHomeownerContractLineItemProps) {
    super(`${homeownerContractLineItem.id}`, false);

    this.clisAmountUsed = clisAmountUsed;
    this.homeownerContractLineItem = homeownerContractLineItem;
    this.allHomeownerContractLineItems = allHomeownerContractLineItems;
    this.allCostLineItemAllocations = allHomeownerContractLineItems
      .flatMap((hcli) => hcli.invoiceLineItems)
      .filter((ili) => ili.invoice.id !== invoice.id)
      .flatMap((ili) => ili.costLineItemAllocations);
    this.invoice = invoice;
    this.projectItem = projectItem;
    this.projectItemHclis = projectItemHclis;
    if (filter) this.filter = filter;
  }

  costLineItems(forSelectedPeriod: boolean = false): ConfirmLineItemsCostLineItemFragment[] {
    const allocations = this.costLineItemAllocations();
    const costLineItems = this.projectItem.costLineItems
      .filter((cli) => {
        const totalAllocated = this.allCostLineItemAllocations
          .filter((clia) => clia.costLineItemId === cli.id)
          .sum((clia) => clia.costAmountInCents);
        const isCreditAllocated = cli.isTradePartnerCredit && cli.amountInCents === -totalAllocated;

        const isHistoricFullAllocated =
          cli.historicAmountInvoiced === (cli.isTradePartnerCredit ? -1 : 1) * cli.amountInCents;

        if (isCreditAllocated || isHistoricFullAllocated) return false;
        return true;
      })
      .map((cli) => {
        // if is a trade partner credit, then use the negative amount
        const amountInCents = cli.isTradePartnerCredit ? -Math.abs(cli.amountInCents) : cli.amountInCents;
        return { ...cli, amountInCents };
      });

    const filterDate = (cli: ConfirmLineItemsCostLineItemFragment) => cli.costDate <= new Date(this.filter.endDate);

    const costLineItemsForSelectedPeriod = forSelectedPeriod
      ? costLineItems.filter(filterDate)
      : costLineItems.filter((cli) => (this.filter.showOnlyActuals ? filterDate(cli) : true));

    // if this is already invoiced then we should ignore it
    const invoiced = allocations
      .filter(
        (clia) =>
          costLineItemsForSelectedPeriod.some((cli) => clia.costLineItemId === cli.id) &&
          !this.isCostLineItemUsedBeforeInAnotherLineItem(clia.costLineItemId),
      )
      .sum((clia) => clia.invoicedAmountInCents);

    const isFullyInvoiced = !this.isNegativeContract && invoiced >= this.homeownerContractAmount;
    const isNegativeAndChangeOrderAndSingleCostLineItem =
      this.isNegativeContract && this.isChangeOrder && costLineItemsForSelectedPeriod.length === 1;
    if (isFullyInvoiced || isNegativeAndChangeOrderAndSingleCostLineItem) return [];

    // if there is more than one bill line item, then cutoff the ones when its higher than homeownercontract amount
    if (this.filter.showOnlyActuals && costLineItemsForSelectedPeriod.length > 1) {
      const hcliInvoicedToDate = allocations.map((clia) => clia.invoicedAmountInCents).sum();
      const vendorCreditTotal =
        costLineItemsForSelectedPeriod.filter((cli) => cli.isTradePartnerCredit).sum((cli) => cli.amountInCents) *
        this.markupMultiplier;

      const allocationsTotalByCLI = allocations.groupBy((clia) => clia.costLineItemId);
      return costLineItemsForSelectedPeriod.reduce<ConfirmLineItemsCostLineItemFragment[]>((acc, cli) => {
        if (cli.isTradePartnerCredit) {
          return [...acc, cli];
        }
        const previousUsed = this.getCostLineItemTotalAmountUsedBefore(cli);
        const totalAccumulated = acc.sum((before) => {
          const actualAmount = before.amountInCents - this.getCostLineItemTotalAmountUsedBefore(before);
          const allocated = (allocationsTotalByCLI[before.id] || []).sum((clia) => clia.invoicedAmountInCents);

          const wasUsedInAnotherHCLI = this.isCostLineItemUsedBeforeInAnotherLineItem(cli.id);
          const billed = wasUsedInAnotherHCLI ? this.getPreviousAmountBilled(before.id) : 0;
          return actualAmount - allocated - billed;
        });

        if (
          (vendorCreditTotal + totalAccumulated - previousUsed) * this.markupMultiplier + hcliInvoicedToDate >=
          this.homeownerContractAmount
        ) {
          return acc;
        }

        // if total To Invoice of the before clis is minor/equal than contract amount
        return [...acc, cli];
      }, []);
    }

    return costLineItemsForSelectedPeriod;
  }

  costLineItemAllocations(onlyBefore: boolean = false) {
    const lineItems = onlyBefore ? this.lineItemsBefore : this.allHomeownerContractLineItems;
    // get allocations of the others invoices
    return lineItems
      .flatMap((hcli) => hcli.invoiceLineItems)
      .filter(
        (ili) =>
          ili.invoice.id !== this.invoice.id && ili.homeownerContractLineItem.id === this.homeownerContractLineItem.id,
      )
      .flatMap((ili) => ili.costLineItemAllocations);
  }

  unallocatedCostLineItems(forSelectedPeriod: boolean = false) {
    const costLineItems = this.costLineItems(forSelectedPeriod);
    const perCostTotals = this.costLineItemAllocations().groupBy(
      (clia) => clia.costLineItemId,
      (clia) => clia.costAmountInCents,
    );

    // this keeps track of clis invoiced before the current cli, it is initialized with credits
    const invoicedBeforeAndCreditMap = costLineItems.reduce(
      (acc, cli) => {
        if (cli.isTradePartnerCredit) {
          acc[cli.id] = cli.amountInCents;
        }
        return acc;
      },
      {} as { [key: string]: number },
    );

    return (
      costLineItems
        // is credit partner and not already used or non fully allocated
        .filter((cli) => {
          if (cli.isTradePartnerCredit) {
            return this.isCostLineItemUsedBeforeInAnotherLineItem(cli.id) === false;
          }

          return (perCostTotals[cli.id]?.sum() ?? 0) < cli.amountInCents;
        })
        .map((cli) => {
          // amountInCents should first take into account existing allocations on the cost line item
          const previousAmountBilled = this.getPreviousAmountBilled(cli.id);

          // it should also take into account the amount of the cost that has been previously used in other HCLIs
          const totalCliAmountUsedBefore = this.getCostLineItemTotalAmountUsedBefore(cli);

          let amountInCents = cli.amountInCents - previousAmountBilled - totalCliAmountUsedBefore;

          // cap the cost amount used if there is a another HCLI
          const isInAnotherHCLI = this.projectItemHclis.length > 1;

          if (isInAnotherHCLI) {
            const invoicedBeforeAndCredits = Object.keys(invoicedBeforeAndCreditMap)
              .filter((key) => key !== cli.id)
              .sum((key) => (invoicedBeforeAndCreditMap[key] ?? 0) * this.markupMultiplier);

            const previousInvoices = this.costLineItemAllocations().sum((clia) => clia.invoicedAmountInCents);

            const maxInvoiceAmount = this.homeownerContractAmount - invoicedBeforeAndCredits - previousInvoices;
            const maxBillAmount = maxInvoiceAmount * (1 / this.markupMultiplier);

            amountInCents = amountInCents > maxBillAmount ? maxBillAmount : amountInCents;
          }

          invoicedBeforeAndCreditMap[cli.id] = amountInCents;
          return { ...cli, amountInCents };
        })
    );
  }

  private get lineItemsBefore() {
    // get the HCLI index on the list
    const lineItemIndex = this.allHomeownerContractLineItems.findIndex(
      (hcli) => hcli.id === this.homeownerContractLineItem.id,
    );
    // slice the hclis by the first until the index found
    return this.allHomeownerContractLineItems.slice(0, lineItemIndex);
  }

  private get contractType() {
    return this.homeownerContractLineItem.owner.contractType;
  }

  get uniqueCost() {
    const costLineItems = this.unallocatedCostLineItems();
    return costLineItems.length > 1 ? null : costLineItems.first;
  }

  get hasSingleCost() {
    return Boolean(this.uniqueCost);
  }

  get homeownerContractAmount() {
    return this.homeownerContractLineItem.priceChangeInCents!;
  }

  private get isNegativeContract() {
    return this.homeownerContractAmount < 0;
  }

  get isChangeOrder() {
    return this.homeownerContractLineItem.owner.__typename === "HomeownerContractChangeOrder";
  }

  periodActuals(unallocatedCostLineItems: ConfirmLineItemsCostLineItemFragment[]) {
    return unallocatedCostLineItems.filter((cli) => cli.costDate >= this.filter.startDate);
  }

  remainingFromPrior(unallocatedCostLineItems: ConfirmLineItemsCostLineItemFragment[]) {
    return unallocatedCostLineItems.filter((cli) => cli.costDate < this.filter.startDate);
  }

  // Check if HCLI has actuals within period (dates filter)
  get hasActualsOnCurrentPeriod() {
    const unallocatedOnPeriod = this.unallocatedCostLineItems(true);
    return unallocatedOnPeriod.some((i) => i.amountInCents !== 0);
  }

  get isFixedMarkup() {
    return isFixedMarkupByContractType(this.contractType);
  }

  get markupMultiplier() {
    return calculateHCLIMarkup(this.homeownerContractLineItem);
  }

  // sum amount of all HCLI before that is not fully invoiced and has the CLI
  private getCostLineItemTotalAmountUsedBefore(cli: ConfirmLineItemsCostLineItemFragment) {
    return this.lineItemsBefore
      .filter((hcli) => this.projectItemHclis.map((h) => h.id).includes(hcli.id))
      .sum((hcli) => this.clisAmountUsed.get(`${hcli.id}-${cli.id}`) ?? 0);
  }

  private isCostLineItemUsedBeforeInAnotherLineItem(costLineItemId: string) {
    return this.lineItemsBefore.some(
      (hcli) =>
        hcli.id !== this.homeownerContractLineItem.id && this.clisAmountUsed.get(`${hcli.id}-${costLineItemId}`) !== 0,
    );
  }

  toRow(selectedRowsIds: string[]): GridDataRow<InvoiceLineItemsDataRow> {
    const isSelected = (cliId: string) => selectedRowsIds.includes(`${this.homeownerContractLineItem.id}-${cliId}`);

    const costLineItems = this.costLineItems();
    const unallocatedCostLineItems = this.unallocatedCostLineItems();

    // if line has unique bill then don't filter by selected
    const actuals = this.hasSingleCost
      ? {
          costLineItemAllocations: this.costLineItemAllocations(),
          periodActuals: this.periodActuals(unallocatedCostLineItems),
          remainingFromPrior: this.remainingFromPrior(unallocatedCostLineItems),
        }
      : {
          costLineItemAllocations: this.costLineItemAllocations().filter((clia) => isSelected(clia.costLineItemId)),
          periodActuals: this.periodActuals(unallocatedCostLineItems).filter((cli) => isSelected(cli.id)),
          remainingFromPrior: this.remainingFromPrior(unallocatedCostLineItems).filter((cli) => isSelected(cli.id)),
        };

    const subtotal =
      actuals.periodActuals.sum((cli) => cli.amountInCents) +
      actuals.remainingFromPrior.sum((cli) => cli.amountInCents);

    const invoicedToDate = this.invoicedToDate();

    let toInvoice = subtotal * this.markupMultiplier;
    const exceedActuals = toInvoice + invoicedToDate > this.homeownerContractAmount;
    if (exceedActuals) {
      toInvoice = this.homeownerContractAmount - invoicedToDate;
    }

    return {
      kind: "item" as const,
      id: this.homeownerContractLineItem.id,
      initCollapsed: true,
      selectable: this.hasActualsOnCurrentPeriod ? undefined : false,
      data: {
        displayName: this.isChangeOrder
          ? `${(this.homeownerContractLineItem.owner as HomeownerContractChangeOrder).shortName} ${
              this.projectItem.name
            }`
          : this.projectItem.name,
        exceedActuals: exceedActuals && !this.isNegativeContract,
        homeownerContractAmount: this.homeownerContractAmount,
        invoicedToDate,
        remainingFromPrior: actuals.remainingFromPrior.sum((cli) => cli.amountInCents),
        periodActuals: actuals.periodActuals.sum((cli) => cli.amountInCents),
        subtotal,
        isFixedMarkup: this.isFixedMarkup,
        markup: this.isFixedMarkup
          ? this.contractType
          : this.homeownerContractLineItem.owner.costPlusMarkupBasisPoints! / 100,
        toInvoice,
        entity: this.uniqueCost?.entity,
        costLineItemId: this.uniqueCost?.id,
        isChangeOrder: this.isChangeOrder,
      },
      children: !this.hasSingleCost
        ? costLineItems
            // filtering by old hclis that share the same Cost Line Item, to avoid duplication
            .filter(
              // check if the CLI has already used by some HCLI before or its already fully completely
              (cli) => {
                // if cost is partner credit then use it
                if (cli.isTradePartnerCredit) return true;
                const totalAmountUsedBefore = this.getCostLineItemTotalAmountUsedBefore(cli);
                return totalAmountUsedBefore < cli.amountInCents;
              },
            )
            .map((cli) => new InvoiceCostLineItem(cli, this).toRow())
        : undefined, // Keep undefined so that we stay selectable
    };
  }

  invoicedToDate() {
    // sum up allocations not on this invoice
    return this.homeownerContractLineItem.invoiceLineItems
      .filter((ili) => ili.invoice.id !== this.invoice.id)
      .flatMap((ili) => ili.costLineItemAllocations)
      .flatMap((clia) => clia.invoicedAmountInCents)
      .sum();
  }

  private getPreviousAmountBilled(bliId: string) {
    return this.allCostLineItemAllocations
      .filter((clia) => clia.costLineItemId === bliId)
      .map((clia) => clia.costAmountInCents)
      .sum();
  }
}
