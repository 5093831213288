import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal, useSnackbar } from "@homebound/beam";
import { BillId, InvoiceId } from "@homebound/graphql-service-factories";
import { ApproverUserDetailFragment, useSubmitApprovalMutation } from "src/generated/graphql-types";
import { ApproversDetail } from "src/routes/components/Approval/ApproverDetail";

export type SubmitApprovalModalProps = {
  subject: "Bill" | "Invoice";
  subjectId: BillId | InvoiceId;
  predictedApprovers: ApproverUserDetailFragment[];
};

export function SubmitApprovalModal(props: SubmitApprovalModalProps) {
  const { subject, subjectId, predictedApprovers } = props;
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [saveApproval, { loading }] = useSubmitApprovalMutation();

  return (
    <>
      <ModalHeader>Approval</ModalHeader>
      <ModalBody>
        Do you want to submit this {subject.toLowerCase()} for an approval? It will be sent automatically to:
        <div css={Css.df.fdc.gap1.mt2.$}>
          {predictedApprovers.map((approver) => (
            <ApproversDetail key={approver.id} approver={{ user: approver }} />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label="Submit for approval"
          disabled={loading}
          onClick={async () => {
            await saveApproval({ variables: { input: { subjectId } } });
            triggerNotice({ message: `${subject} submitted for approval` });
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
