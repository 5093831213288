import {
  Button,
  Chip,
  Chips,
  Css,
  GridColumn,
  GridDataRow,
  GridSortConfig,
  GridTable,
  Palette,
  RightPaneLayout,
  ScrollableContent,
  Tooltip,
  column,
  emptyCell,
  getTableStyles,
  simpleHeader,
  useRightPane,
} from "@homebound/beam";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ChangeLogTableFragment,
  ChangeRequestFragment,
  ChangeRequestOrder,
  ChangeTypeDetail,
  Order,
  useChangeLogTableQuery,
} from "src/generated/graphql-types";
import { Price } from "../Price";
import { Percentage } from "src/components/Percentage";
import { daysAgo, formatCentsToPrice, maybeDeTagId, queryResult } from "src/utils";
import { Icon } from "../Icon";
import { parseOrder, toOrder } from "src/utils/ordering";
import { ChangeRequestStatusPill } from "./ChangeRequestStatus";
import { ChangeLogRightPageContent } from "./ChangeLogRightPaneContent";
import { useHistory, useRouteMatch } from "react-router";
import { changeLogPaths, developmentPaths, projectPaths } from "src/routes/routesDef";
import { ChangeLogTableFilter, ChangeLogView, ChangeLogViewFilterConfig } from "./ChangeLog";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { Link } from "react-router-dom";
import {
  createDevelopmentChangeLogDetailsUrl,
  createGlobalChangeLogDetailsUrl,
  createProjectChangeLogDetailsUrl,
} from "src/RouteUrls";
import { chipCell } from "../gridTableCells";

type ChangeLogTableProps = {
  view: ChangeLogView;
  filter: ChangeLogTableFilter;
  changeTypes: ChangeTypeDetail[];
  projectId?: string;
  developmentId?: string;
};

export function ChangeLogTable({ view, filter, changeTypes, projectId, developmentId }: ChangeLogTableProps) {
  const { id: currentInternalUserId } = useCurrentUser();

  const [orderBy, setOrderBy] = useState<ChangeRequestOrder>({ updatedAt: Order.Desc });

  const { myRequests, development, ...filters } = filter;

  // Check if view is project
  const isProject = view === ChangeLogView.Project;
  const isDevelopment = view === ChangeLogView.Development;

  const query = useChangeLogTableQuery({
    variables: {
      filter: {
        ...filters,
        internalUser: myRequests && currentInternalUserId ? [currentInternalUserId] : undefined,
        projectWithDevelopment: isProject && projectId ? [projectId] : undefined,
        development: isDevelopment && developmentId ? [developmentId] : development,
      },
      orderBy,
      page: {
        offset: 0,
        limit: 100,
      },
    },
  });

  const maybeFetchMore = useCallback(async () => {
    if (query.data?.changeRequests.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: {
          page: {
            offset: query.data.changeRequests.entities.length,
            limit: 100,
          },
        },
      });
    }
  }, [query]);

  return queryResult(query, ({ changeRequests }) => (
    <ChangeLogTableContent
      view={view}
      changeRequests={changeRequests ?? {}}
      maybeFetchMore={maybeFetchMore}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      changeTypes={changeTypes}
      projectId={projectId}
      developmentId={developmentId}
    />
  ));
}

type ChangeLogTableContentProps = {
  view: ChangeLogView;
  changeRequests: ChangeLogTableFragment;
  maybeFetchMore: () => void;
  orderBy: ChangeRequestOrder;
  setOrderBy: (order: ChangeRequestOrder) => void;
  changeTypes: ChangeTypeDetail[];
  projectId?: string;
  developmentId?: string;
};

function ChangeLogTableContent({
  view,
  changeRequests,
  maybeFetchMore,
  orderBy,
  setOrderBy,
  changeTypes,
  projectId,
  developmentId,
}: ChangeLogTableContentProps) {
  const { push } = useHistory();
  const { openRightPane } = useRightPane();

  const changeRequestDrawerMatch = useRouteMatch<{ changeRequestId: string }>([
    changeLogPaths.details,
    projectPaths.changeLogDetails,
    developmentPaths.changeLogDetails,
  ]);

  const changeRequestId = changeRequestDrawerMatch?.params?.changeRequestId;

  useEffect(() => {
    if (changeRequestId) {
      openRightPane({
        content: (
          <ChangeLogRightPageContent
            key={changeRequestId}
            id={changeRequestId}
            view={view}
            projectId={projectId}
            developmentId={developmentId}
          />
        ),
      });
    }
  }, [changeRequestId, developmentId, openRightPane, projectId, push, view]);

  const handleViewClick = useCallback(
    (changeRequestId: string) => {
      push(
        `${ChangeLogViewFilterConfig[view].detailsUrl({ changeRequestId, projectId, developmentId })}${
          window.location.search
        }`,
      );
    },
    [developmentId, projectId, push, view],
  );

  const columns = useMemo(
    () => createColumns(handleViewClick, changeTypes, { projectId, developmentId }),
    [handleViewClick, changeTypes, projectId, developmentId],
  );

  const rows = useMemo(() => createRows(changeRequests.entities), [changeRequests]);

  const initSortState: GridSortConfig = useMemo(
    () => ({
      on: "server",
      onSort: (key, direction) => setOrderBy(toOrder(key, direction)),
      value: parseOrder(orderBy),
    }),
    [orderBy, setOrderBy],
  );

  const styles = getTableStyles({ allWhite: true });

  return (
    <ScrollableContent virtualized>
      {/* Hack for the grid table's shadow to be visible when using right pane layout */}
      <div
        css={
          Css.w100
            .addIn(
              "> div > div:first-of-type",
              Css.h("calc(100% - 24px)").bshBasic.if(view === ChangeLogView.Global).mx3.else.mr3.$,
            )
            .if(view === ChangeLogView.Global)
            .h("calc(100% - 136px)").else.h100.$
        }
      >
        <RightPaneLayout paneBgColor={Palette.Transparent} paneWidth={484}>
          <div css={Css.br8.w100.h100.oh.bshBasic.bgWhite.$}>
            <div css={Css.w100.h100.oa.$}>
              <GridTable
                columns={columns}
                rows={rows}
                infiniteScroll={{ onEndReached: maybeFetchMore }}
                as="virtual"
                sorting={initSortState}
                style={{
                  ...styles,
                  rootCss: {
                    ...styles.rootCss,
                    ...Css.maxw100.br8.h100.addIn("> div:first-of-type", Css.w100.$).$,
                  },
                }}
              />
            </div>
          </div>
        </RightPaneLayout>
      </div>
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: ChangeRequestFragment };
type TotalRow = {
  kind: "totals";
  data: {
    estBudgetImpact: number;
    markup: number;
    estRetailPrice: number;
    estBuyerOutOfPocket: number;
    estRevenueImpact: number;
    estMarginImpact: number;
  };
};

type Row = HeaderRow | DataRow | TotalRow;

const createColumns = (
  handleViewClick: (changeRequestId: string) => void,
  changeTypesEnum: ChangeTypeDetail[],
  projectOrDevelopmentIds: { projectId?: string; developmentId?: string },
): GridColumn<Row>[] => [
  column<Row>({
    header: "Change Request",
    data: ({ name, id }) => ({
      content: () => <Link to={getDetailsLink(id, projectOrDevelopmentIds)}>{name}</Link>,
    }),
    totals: "Totals",
    mw: "160px",
    serverSideSortKey: "title",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Lots/ Devs Impacted</span>
        <Tooltip title="Where the proposed changes could potentially have an impact.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ scopes }) => {
      const scopeNames = scopes.map(({ target }) => {
        if (target.__typename === "Development") {
          return target.name;
        }
        return target.buildAddress.street1;
      });
      return chipCell(scopeNames, 3, undefined, true);
    },
    totals: emptyCell,
    w: "180px",
  }),
  column<Row>({
    header: "Status",
    data: ({ status }) => <ChangeRequestStatusPill status={status} />,
    totals: emptyCell,
    serverSideSortKey: "status",
    mw: "150px",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Estimated Budget Impact</span>
        <Tooltip title="This is the total cost to Homebound of making the change.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ estimateBudgetImpactInCents }) => <Price valueInCents={estimateBudgetImpactInCents} />,
    totals: ({ estBudgetImpact }) => formatCentsToPrice(estBudgetImpact),
    serverSideSortKey: "estimateBudgetImpactInCents",
    w: "160px",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Markup</span>
        <Tooltip title="This is the expected markup for the buyer.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ markupBasisPoints }) => <Percentage percent={markupBasisPoints} basisPoints />,
    serverSideSortKey: "markupBasisPoints",
    totals: ({ markup }) => formatCentsToPrice(markup),
    w: "100px",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Estimated Retail Price</span>
        <Tooltip title="This is the value to the buyer if they were to pay full price for the change.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ estimatedRetailPriceInCents }) => <Price valueInCents={estimatedRetailPriceInCents} />,
    totals: ({ estRetailPrice }) => formatCentsToPrice(estRetailPrice),
    serverSideSortKey: "estimatedRetailPriceInCents",
    w: "160px",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Estimated Buyer Out-of-Pocket Cost</span>
        <Tooltip title="This is the price the buyer will pay.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ estimatedBuyerOutOfPocketInCents }) => <Price valueInCents={estimatedBuyerOutOfPocketInCents} />,
    totals: ({ estBuyerOutOfPocket }) => formatCentsToPrice(estBuyerOutOfPocket),
    serverSideSortKey: "estimatedBuyerOutOfPocketInCents",
    w: "180px",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Estimated Revenue Impact</span>
        <Tooltip title="This is the additional revenue the change will generate.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ estimateRevenueImpactInCents }) => <Price valueInCents={estimateRevenueImpactInCents} />,
    totals: ({ estRevenueImpact }) => formatCentsToPrice(estRevenueImpact),
    serverSideSortKey: "estimateRevenueImpactInCents",
    w: "165px",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Estimated Margin Impact</span>
        <Tooltip title="This is the additional profit the change will generate.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ estimateMarginImpactBasisPoints }) => <Percentage percent={estimateMarginImpactBasisPoints} basisPoints />,
    totals: ({ estMarginImpact }) => (estMarginImpact / 100).toFixed(2) + "%",
    serverSideSortKey: "estimateMarginImpactBasisPoints",
    w: "156px",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Rationale</span>
        <Tooltip title="Why the change request is needed.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ rationale }) => <div css={Css.maxhPx(50).oh.add("textOverflow", "ellipsis").$}>{rationale}</div>,
    totals: emptyCell,
    mw: "210px",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Group</span>
        <Tooltip title="The group that the change request belongs to.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ groups }) => (groups.nonEmpty ? <Chips values={groups.map(({ name }) => name)} /> : null),
    totals: emptyCell,
    mw: "150px",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Type of Change</span>
        <Tooltip title="The types of assets that might be impacted by a change request.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ id, changeTypes }) => {
      return chipCell(
        changeTypes.map((value) => {
          const changeType = changeTypesEnum.find(({ code }) => code === value);
          return changeType ? changeType.name : "";
        }),
        3,
        undefined,
        true,
      );
    },
    totals: emptyCell,
    serverSideSortKey: "changeTypes",
    mw: "150px",
  }),
  column<Row>({
    header: () => (
      <div css={Css.df.gap1.$}>
        <span>Source</span>
        <Tooltip title="The change source captures the stage of the building process where the necessity for the change was realized.">
          <Icon icon="infoCircle" inc={2} color={Palette.Gray600} />
        </Tooltip>
      </div>
    ),
    data: ({ source }) => <Chip text={source.name} compact />,
    totals: emptyCell,
    serverSideSortKey: "source",
    mw: "150px",
  }),
  column<Row>({
    header: "Last Updated",
    data: ({ updatedAt }) => daysAgo(updatedAt),
    totals: emptyCell,
    w: "160px",
    serverSideSortKey: "updatedAt",
  }),
  column<Row>({
    header: "ID",
    data: ({ id }) => maybeDeTagId(id),
    totals: emptyCell,
    w: "100px",
    serverSideSortKey: "id",
  }),
  column<Row>({
    header: "",
    data: ({ id }) => <Button label="View" onClick={() => handleViewClick(id)} variant="secondary" />,
    totals: emptyCell,
    w: "110px",
    sticky: "right",
  }),
];

function createRows(changeRequests: ChangeRequestFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    {
      kind: "totals",
      id: "total",
      data: {
        estBudgetImpact: changeRequests.sum((cr) => cr.estimateBudgetImpactInCents ?? 0),
        markup: changeRequests.sum(
          (cr) => ((cr.markupBasisPoints ?? 0) / 100 / 100) * (cr.estimateBudgetImpactInCents ?? 0),
        ),
        estRetailPrice: changeRequests.sum((cr) => cr.estimatedRetailPriceInCents ?? 0),
        estBuyerOutOfPocket: changeRequests.sum((cr) => cr.estimatedBuyerOutOfPocketInCents ?? 0),
        estRevenueImpact: changeRequests.sum((cr) => cr.estimateRevenueImpactInCents ?? 0),
        estMarginImpact: changeRequests.sum((cr) => cr.estimateMarginImpactBasisPoints ?? 0) / changeRequests.length,
      },
    },
    ...changeRequests.map((changeRequest) => ({ kind: "data" as const, id: changeRequest.id, data: changeRequest })),
  ];
}

function getDetailsLink(
  changeRequestId: string,
  { projectId, developmentId }: { projectId?: string; developmentId?: string },
) {
  if (projectId) {
    return createProjectChangeLogDetailsUrl(projectId, changeRequestId);
  }
  if (developmentId) {
    return createDevelopmentChangeLogDetailsUrl(developmentId, changeRequestId);
  }
  return createGlobalChangeLogDetailsUrl(changeRequestId);
}
