import { StoreApi } from "zustand";
import { TakeoffsStoreState } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { Css, useSnackbar } from "@homebound/beam";
import { pluralize } from "src/utils";
import { DeleteConfirmationModal } from "src/routes/components/DeleteConfirmationModal";

type DeleteItemsConfirmationModalProps = {
  store: StoreApi<TakeoffsStoreState>;
  itemIdsToDelete: string[];
};

export function DeleteItemsConfirmationModal({ store, itemIdsToDelete }: DeleteItemsConfirmationModalProps) {
  const { saveItiv } = store.getState();

  const { triggerNotice } = useSnackbar();
  return (
    <DeleteConfirmationModal
      entityType="Item"
      confirmationMessage={
        <div>
          <p css={Css.smMd.mb2.$}>
            {itemIdsToDelete.length} {pluralize(itemIdsToDelete, "item")} selected
          </p>
          <p>Are you sure you want to delete these items? This action cannot be undone.</p>
        </div>
      }
      onConfirmDelete={async () => {
        const { data } = await saveItiv(itemIdsToDelete.map((id) => ({ id, remove: true })));
        if (data) {
          const deleteItems =
            data.saveItemTemplateItemVersions.deleted.length + data.saveItemTemplateItemVersions.removed.length;

          triggerNotice({
            message: `${deleteItems === 1 ? "Item has" : `${deleteItems} items have`} been deleted`,
            icon: "success",
          });
        }
      }}
    />
  );
}
