import { ReadyPlanAssetType } from "src/generated/graphql-types";
import { BoundSelectField, Button, CheckboxGroup, Css, Icon, useModal } from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { DefaultedReadyPlanOption, sortElevationsByActiveAndBase } from "../2.ElevationsStep";
import { ObjectState } from "@homebound/form-state";
import { CoverPhotoUploader } from "src/routes/libraries/plan-package/stepper/components/CoverPhotoUploader";
import { useCallback } from "react";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";

export const ElevationForm = (props: {
  elevation: ObjectState<DefaultedReadyPlanOption>;
  allElevations: readonly ObjectState<DefaultedReadyPlanOption>[];
  index: number;
  nameOptions: { value: string; label: string }[];
  setElevations: (elevations: DefaultedReadyPlanOption[]) => void;
}) => {
  const { elevation, allElevations, index, nameOptions, setElevations } = props;
  const { openModal } = useModal();
  const saveCoverPhoto = useCallback(
    (file: UppyFile | undefined) => {
      if (!file) {
        elevation.assets.set([...elevation.assets.value.filter((rpa) => rpa.type !== ReadyPlanAssetType.CoverPhoto)]);
        return;
      }

      elevation.assets.set([
        ...elevation.assets.value,
        {
          asset: {
            contentType: file.type,
            fileName: file.name,
            s3Key: file.meta.s3Key as string,
            sizeInBytes: file.data.size,
            previewUrl: file.meta.previewUrl as string,
          },
          parentId: elevation.id.value,
          type: ReadyPlanAssetType.CoverPhoto,
        },
      ]);
    },
    [elevation],
  );

  const onArchiveElevation = () => {
    const saveElevationActive = (active: boolean) => {
      elevation.set({ active });
      setElevations(sortElevationsByActiveAndBase([...allElevations.values()].map((e) => e.value)));
    };
    // confirm archiving, but not activation
    if (elevation.active.value) {
      openModal({
        content: (
          <ConfirmationModal
            confirmationMessage={
              <div>
                <div css={Css.fw6.mb3.$}>Are you sure you want to archive this elevation?</div>
                You will have the option to reactivate it at any time.
              </div>
            }
            onConfirmAction={async () => {
              saveElevationActive(false);
            }}
            title="Archive Elevation"
            label="Archive"
          />
        ),
      });
    } else {
      saveElevationActive(true);
    }
  };

  const onChangeBaseElevation = (values: string[]) => {
    // shouldn't be able to unset a base elevation, only choose a new one
    if (!values.includes("base")) {
      return;
    }

    const otherBaseExists = allElevations.some((e, idx) => e.order.value === 1 && idx !== index);

    const saveElevationDefault = (base: boolean) => {
      // splice out the base elevation
      // insert it at the beginning of the array
      // set order for each elevation to their index + 1
      const newOrderElevations = [...allElevations.values()];
      const baseElevation = newOrderElevations.splice(index, 1);
      newOrderElevations.unshift(...baseElevation);
      newOrderElevations.forEach((e, idx) => {
        e.order.set(idx + 1);
      });

      // re-sort when we change the order, or active
      setElevations(sortElevationsByActiveAndBase([...newOrderElevations.values()].map((e) => e.value)));
    };

    // if we're setting an elevation as base, and another base exists, confirm the change
    if (elevation.active.value && otherBaseExists) {
      const prevBaseIndex = allElevations.findIndex((e) => e.order.value === 1); // should always be zero
      const prevBase = allElevations[prevBaseIndex];

      const prevBaseName =
        prevBase.displayName.value ?? nameOptions.find((go) => go.value === prevBase.globalOptionId.value)?.label;
      const newBaseName =
        elevation.displayName.value ?? nameOptions.find((go) => go.value === elevation.globalOptionId.value)?.label;

      openModal({
        content: (
          <ConfirmationModal
            confirmationMessage={
              <div css={Css.whiteSpace("pre-wrap").$}>
                <div css={Css.fw6.mb3.$}>Are you sure you want to make the following Base elevation change?</div>
                <div css={Css.df.fdr.$}>
                  <div css={Css.df.fdc.mr2.$}>
                    Previous
                    <div css={Css.fw6.$}>{prevBaseName ?? `Elevation ${prevBaseIndex + 1}`}</div>
                  </div>
                  <Icon icon="arrowRight" />
                  <div css={Css.df.fdc.ml2.$}>
                    New
                    <div css={Css.fw6.$}>{newBaseName ?? `Elevation ${index + 1}`}</div>
                  </div>
                </div>
              </div>
            }
            onConfirmAction={() => {
              saveElevationDefault(values.includes("base"));
            }}
            title="Change Base Elevation"
            label="Save"
          />
        ),
      });
    } else {
      saveElevationDefault(values.includes("base"));
    }
  };

  const coverPhotoUrl = elevation.assets?.value.find((asset) => asset.type === ReadyPlanAssetType.CoverPhoto)?.asset
    ?.previewUrl;

  return (
    <div>
      <div css={Css.df.fdr.mb3.jcsb.$}>
        <div css={Css.base.fw6.color("black").$}>Elevation {index + 1}</div>
        <Button
          label={elevation.active.value ? "Archive Elevation" : "Activate Elevation"}
          variant="tertiary"
          onClick={onArchiveElevation}
        />
      </div>
      <BoundSelectField
        options={nameOptions}
        label="Elevation*"
        labelStyle="left"
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        field={elevation.globalOptionId}
        disabled={
          elevation.id.value ? (
            <>Cannot change the global option of existing elevation</>
          ) : !elevation.active.value ? (
            <>Archived</>
          ) : undefined
        }
        disabledOptions={allElevations.map((e) => ({ value: e.globalOptionId.value, reason: "Already in use" }))}
      />
      {elevation.active.value && (
        <>
          <div css={Css.mb3.mt3.gap1.$}>
            <CheckboxGroup
              label="Base Elevation?"
              labelStyle="left"
              onChange={(values) => {
                onChangeBaseElevation(values);
              }}
              values={[elevation.order.value == 1 ? "base" : ""]}
              options={[
                {
                  value: "base",
                  label: "Mark as Base Elevation",
                  description:
                    "The Base Elevation should be the least expensive elevation option offered on this plan. This will also be the default elevation.",
                  disabled: !elevation.active.value,
                },
              ]}
            />
          </div>
          <div css={Css.df.fdr.$}>
            <div css={Css.w50.$}>Cover Photo</div>
            <CoverPhotoUploader coverPhotoUrl={coverPhotoUrl} saveCoverPhoto={saveCoverPhoto} />
          </div>
        </>
      )}

      {/* line divider */}
      <div css={Css.mb3.mt3.ba.bcGray100.$} />
    </div>
  );
};
