import { Button, ButtonGroup, Css, IconButton, Palette, useBreakpoint } from "@homebound/beam";
import {
  ApprovalStatus,
  ApproverStatus,
  PandaDocStatus,
  useApprovalSuperDrawer_SaveApprovalMutation,
  useCreatePandaDocSessionMutation,
} from "src/generated/graphql-types";
import { fail } from "src/utils";
import { ApprovalDrawerViews, useApprovalContext } from "./ApprovalSuperDrawer";
import { useUpdateApprovers } from "./useUpdateApprovers";
import { openNewTab } from "src/utils/window";

export function ApprovalReviewButtons() {
  const { view, setView, currentUserId, approvals, loading } = useApprovalContext();
  const updateApprovers = useUpdateApprovers();
  const [createPandaDocSession] = useCreatePandaDocSessionMutation();
  const [updateApproval] = useApprovalSuperDrawer_SaveApprovalMutation();

  if (view !== ApprovalDrawerViews.Review) return null;

  const approval = approvals?.first ?? fail("Should not be possible to be `Reviewing` 0 Approvals");
  const approversForUser = approval.approvers
    // only "my" approvals
    .filter((approver) => approver.user.id === currentUserId)
    // which are review-able
    .filter((approver) => [ApproverStatus.Pending, ApproverStatus.ActionRequired].includes(approver.status.code))
    .sort((approver) => approver.order);

  const currentApprover = approversForUser.first;

  const isRetractable = approval.status.code === ApprovalStatus.Requested && approval.requestedBy?.id === currentUserId;
  const isSignable = approval.status.code === ApprovalStatus.Requested && currentApprover?.requireSignature;
  const isReviewable = approval.status.code === ApprovalStatus.Requested && !isSignable && !!updateApprovers;
  const isReopenable = approval.status.code === ApprovalStatus.ChangesNeeded;

  const { pandaDoc } = currentApprover?.pandaDocRecipient ?? {};

  return (
    <div css={Css.absolute.bottom0.left0.right0.w100.hPx(80).bt.bcGray200.p3.df.fdr.gap1.jcfe.aic.bgWhite.$}>
      {isRetractable && (
        <Button variant="secondary" label="Retract Approval" onClick={() => setView(ApprovalDrawerViews.Retracting)} />
      )}
      {isReopenable && (
        <Button
          label="Re-Request Approval"
          onClick={() => updateApproval({ variables: { input: { id: approval.id, reopen: true } } })}
        />
      )}
      {isReviewable && (
        <ApprovalReviewActionButtons
          onApprove={() => updateApprovers(ApproverStatus.Approved)}
          onReject={() => setView(ApprovalDrawerViews.Rejecting)}
          onRequestChanges={() => setView(ApprovalDrawerViews.RequestingChanges)}
          disabled={loading}
          disableRequestChanges={
            // "Request Changes" flow does not apply to Change Requests that have reached the approval stage
            approval.subject?.__typename === "ChangeRequest"
              ? "Cannot Request Changes on Change Request Approvals; please Approve or Reject instead."
              : undefined
          }
        />
      )}
      {isSignable && (
        <Button
          label="Sign in PandaDoc"
          disabled={
            !pandaDoc?.externalPandaDocId
              ? "PandaDoc is still uploading or has not been sent for signature yet"
              : [PandaDocStatus.Draft, PandaDocStatus.Uploaded].includes(pandaDoc.status)
                ? "PandaDoc is still in draft and cannot be signed until it's sent"
                : false
          }
          onClick={async () => {
            // Create the pandadoc session
            const { data } = await createPandaDocSession({ variables: { input: { pandaDocId: pandaDoc!.id } } });
            // Opens a new tab and navigates to the pandadoc using the session id
            openNewTab(`https://app.pandadoc.com/s/${data?.createPandaDocSession.sessionId}`);
          }}
        />
      )}
    </div>
  );
}

type ApprovalReviewActionButtonsProps = {
  onApprove: VoidFunction;
  onRequestChanges: VoidFunction;
  onReject: VoidFunction;
  disableRequestChanges?: string;
  disabled?: boolean;
};

export function ApprovalReviewActionButtons({
  onApprove,
  onRequestChanges,
  onReject,
  disabled,
  disableRequestChanges,
}: ApprovalReviewActionButtonsProps) {
  const { sm } = useBreakpoint();
  return (
    <>
      <ButtonGroup
        disabled={disabled}
        buttons={[
          {
            ...(sm
              ? { icon: "xCircle", iconColor: Palette.Red600, tooltip: "Reject" }
              : { text: <div css={Css.if(!!disabled).red100.else.red600.$}>Reject</div> }),
            onClick: onReject,
          },
          {
            ...(sm
              ? { icon: "changeEvent", iconColor: Palette.Orange600, tooltip: "Request Changes" }
              : { text: "Request Changes" }),
            onClick: onRequestChanges,
            disabled: disableRequestChanges,
          },
        ]}
      />
      {sm ? (
        <IconButton
          icon="checkCircle"
          onClick={onApprove}
          disabled={disabled}
          color={disabled ? Palette.Gray400 : Palette.Green600}
        />
      ) : (
        <Button label="Approve" size="sm" onClick={onApprove} disabled={disabled} />
      )}
    </>
  );
}
