import { GridRowLookup, useRightPane } from "@homebound/beam";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  ProjectRoleDetailsFragment,
  ProjectStageProjectItemDetailFragment,
  ScheduleDetailsFragment,
  ScheduleStatus,
  Schedule_TeamMembersFragment,
  TaskAssigneeFragment,
  TaskFilterOptionsFragment,
  TaskStatusesFragment,
  TaskTradePartnerFragment,
  useAvailableGlobalPhasesQuery,
} from "src/generated/graphql-types";
import { ScheduleCalendar } from "src/routes/projects/schedule-v2/calendar/ScheduleCalendar";
import { queryResultToSchedulePhaseContextProps } from "src/routes/projects/schedule-v2/contexts/SchedulePhaseContext";
import { ScheduleGantt } from "src/routes/projects/schedule-v2/gantt/ScheduleGantt";
import { OperationInput, useScheduleMutationManager } from "src/routes/projects/schedule-v2/scheduleMutationManager";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { useScheduleStore } from "./contexts/ScheduleStore";
import { setEmptyScheduleState, setTaskPaneState } from "./contexts/scheduleStoreReducer";
import { TaskDetailPane } from "./detail-pane/TaskDetailPane";
import { LoadingState, NullState } from "./NullState";
import { EmptyScheduleStates, ScheduleViewType } from "./scheduleUtils";

import { isDefined } from "src/utils";
import { ScheduleRow } from "./table/utils";
import { ScheduleTable } from "./ScheduleTable";

type ScheduleProps = {
  schedule: ScheduleDetailsFragment;
  tasks: TaskFilterOptionsFragment[];
  projectItems?: ProjectStageProjectItemDetailFragment[];
  isPublishedOrArchivedTemplate?: boolean;
  filterOptions?: {
    taskStatuses: TaskStatusesFragment[];
    assignees: TaskAssigneeFragment[];
    tradePartners: TaskTradePartnerFragment[];
  };
  projectRoleDetails?: ProjectRoleDetailsFragment[];
  fullScreen?: boolean;
  toggleFullScreen?: () => void;
};

export function Schedule(props: ScheduleProps) {
  const {
    schedule,
    tasks,
    projectItems,
    filterOptions = { taskStatuses: [], assignees: [], tradePartners: [] },
    projectRoleDetails,
    fullScreen,
    toggleFullScreen,
  } = props;

  const { isLocked } = schedule;

  const { dispatch, scheduleState } = useScheduleStore();
  const lookup = useRef<GridRowLookup<ScheduleRow>>();
  const {
    taskPaneState: { taskPaneId },
    scheduleType,
    scheduleViewType,
    emptyScheduleState,
  } = scheduleState;

  const isTemplate = scheduleType === ScheduleType.Template;
  const { openRightPane, closeRightPane } = useRightPane();

  const hasScrolled = useRef(false);
  /* there is a race condition happening when trying to scroll to a task in the schedule and scrolling to a task in the detail pane at the same time.
 Our workaround is to ensure virtuoso only scrolls on first render */
  useEffect(
    () => {
      // if we haven't scrolled, we're on the schedule page and this is our first render
      if (!hasScrolled.current && scheduleViewType === ScheduleViewType.List && lookup.current) {
        const row = lookup.current.currentList().find(({ id }) => id === taskPaneId);
        // wrapping this logic in a try/catch as it is causing an error in one test. ideally this is a Beam fix in SC-35992
        try {
          if (row) {
            lookup.current.scrollTo("task", row.id);
            hasScrolled.current = true;
          }
        } catch (e) {}
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lookup.current, scheduleViewType, taskPaneId],
  );

  useEffect(() => {
    // resets hasScrolled when switching to gannt or calendar
    if (scheduleViewType !== ScheduleViewType.List) {
      hasScrolled.current = false;
    }
  }, [scheduleViewType]);

  useEffect(
    () => {
      // Checks for taskPaneId and opens pane, if no id we close the pane
      // Also checks when schedule view changes to maintain consistency between views
      scheduleViewType && taskPaneId ? openRightPane({ content: taskDetailPaneWrapper() }) : closeRightPane();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taskPaneId, scheduleViewType],
  );

  // get all globalPhases that are active
  const { data: availableGlobalPhasesData, refetch: refetchAvailableGlobalPhases } = useAvailableGlobalPhasesQuery({
    variables: { scheduleId: schedule.id, stage: schedule.stage },
  });

  // Use the presence of schedulePhases to determine if the null state should be shown, we fall back to assuming there are phases while
  // data is loading to prevent the null state showing until we know there is no data
  const hasSchedulePhases = availableGlobalPhasesData ? !!availableGlobalPhasesData?.schedulePhases.length : true;
  useEffect(() => {
    if (!hasSchedulePhases && !isTemplate) {
      dispatch(setEmptyScheduleState(EmptyScheduleStates.ApplyTemplateNullState));
    } else {
      dispatch(setEmptyScheduleState(null));
    }
  }, [hasSchedulePhases, dispatch, isTemplate]);

  const { enqueueOperation } = useScheduleMutationManager(
    refetchAvailableGlobalPhases,
    schedule.status === ScheduleStatus.Active,
  );

  const onTaskSave = useCallback(
    async (changedValue: OperationInput) => {
      enqueueOperation(changedValue);
    },
    [enqueueOperation],
  );

  const globalPhaseContextProps = useMemo(
    () => queryResultToSchedulePhaseContextProps(availableGlobalPhasesData),
    [availableGlobalPhasesData],
  );

  const onRowClick = useCallback(
    (taskId: string, scrollIntoViewType: "comments" | "predecessor" | "successor" | undefined = undefined) => {
      dispatch(
        setTaskPaneState({
          taskPaneId: taskId,
          scrollIntoViewType,
          tab: "details",
        }),
      );
    },
    [dispatch],
  );

  const setPaneId = useCallback(
    (taskId: string) => {
      dispatch(
        setTaskPaneState({
          taskPaneId: taskId,
        }),
      );
    },
    [dispatch],
  );

  if (emptyScheduleState === EmptyScheduleStates.ApplyTemplateNullState) {
    return (
      <NullState
        stage={schedule.stage}
        scheduleParentId={schedule.parent.id}
        marketId={schedule.parent.market?.id}
        scheduleSetting={schedule.scheduleSetting}
      />
    );
  }

  if (emptyScheduleState === EmptyScheduleStates.LoadingState) return <LoadingState />;

  function taskDetailPaneWrapper() {
    if (!taskPaneId) return null;

    return (
      <TaskDetailPane
        scheduleSetting={schedule.scheduleSetting}
        scheduleStatus={schedule.status}
        taskId={taskPaneId}
        tasks={tasks}
        projectItems={projectItems || []}
        scheduleIsLocked={isLocked}
        scheduleParentId={schedule.parent.id}
        onJump={(taskId: string) => {
          setPaneId(taskId);
        }}
        onSwitch={(taskId: string, direction: "prev" | "next") => {
          const row = lookup.current?.currentList().find(({ id }) => id === taskId);
          if (row) {
            const { prev, next } = lookup.current!.lookup(row)["task"];
            const nextTask = direction === "prev" ? prev : next;
            nextTask && setPaneId(nextTask.id);
          }
        }}
        onClose={() => {
          dispatch(
            setTaskPaneState({
              taskPaneId: undefined,
              scrollIntoViewType: undefined,
              tab: undefined,
            }),
          );
        }}
        onSave={(changedValue) => {
          if (taskPaneId) {
            void onTaskSave({ id: taskPaneId, ...changedValue });
          }
        }}
      />
    );
  }

  if (scheduleViewType === ScheduleViewType.Gantt) {
    return (
      <ScheduleGantt
        scheduleId={schedule.id}
        scheduleIsLocked={isLocked}
        fullScreen={fullScreen}
        toggleFullScreen={toggleFullScreen}
      />
    );
  } else if (scheduleViewType === ScheduleViewType.Calendar) {
    return (
      <ScheduleCalendar
        schedule={schedule}
        onTaskSave={onTaskSave}
        onEventClick={onRowClick}
        fullScreen={fullScreen}
        toggleFullScreen={toggleFullScreen}
        searchedTaskId={undefined}
        filterOptions={filterOptions}
      />
    );
  }

  return (
    <ScheduleTable
      schedule={schedule}
      tasks={tasks}
      projectRoleDetails={projectRoleDetails}
      fullScreen={fullScreen}
      toggleFullScreen={toggleFullScreen}
      onTaskSave={onTaskSave}
      filterOptions={filterOptions}
      scheduleIsLocked={isLocked}
      onRowClick={onRowClick}
      globalPhaseContextProps={globalPhaseContextProps}
      setPaneId={setPaneId}
      lookup={lookup}
    />
  );
}

export function getScheduleTeamMembers(parent: Schedule_TeamMembersFragment) {
  return [
    ...(parent?.__typename === "Project" ? parent.teamMembers.map(({ user }) => user).filter(isDefined) : []),
    ...(parent?.__typename === "Development"
      ? parent.teamMembers.map(({ internalUser }) => internalUser).filter(isDefined)
      : []),
  ].uniqueByKey("id");
}
