import {
  Button,
  ButtonModal,
  Css,
  EditColumnsButton,
  GridRowLookup,
  GridTableApi,
  Icon,
  IconButton,
  Palette,
  RightPaneLayout,
  ScrollableContent,
  useGridTableApi,
  useGroupBy,
} from "@homebound/beam";
import { ScheduleViewTabs } from "./ScheduleViewTabs";
import { ScheduleClearFilters } from "./contexts/ScheduleClearFilters";
import { SchedulePhaseContext, SchedulePhaseContextProps } from "./contexts/SchedulePhaseContext";
import { SearchBox } from "src/components";
import { ProjectScheduleTableV2 } from "./ProjectScheduleTableV2";
import {
  createScheduleColumns,
  determineTaskDateEditabilityById,
  formConfig,
  getDateValueToUpdate,
  mapToForm,
  mapToInput,
  ScheduleListGroupBy,
  ScheduleRow,
  TaskDetailsFormInput,
} from "./table/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScheduleFilterModal } from "./ScheduleFilterModal";
import { getScheduleTeamMembers } from "./Schedule";
import { useApolloClient } from "@apollo/client";
import { ObjectState, useFormStates } from "@homebound/form-state";
import {
  ProjectRoleDetailsFragment,
  SaveScheduleTaskInput,
  ScheduleDetailsFragment,
  TaskAssigneeFragment,
  TaskFilterOptionsFragment,
  TaskScheduleDetailsFragment,
  TaskStatusesFragment,
  TaskTradePartnerFragment,
} from "src/generated/graphql-types";
import {
  CustomTaskFilter,
  customTaskFilterDefault,
  definedFilterValues,
  mapToFilter,
  useQueryStorage,
} from "./table/filterUtils";
import { ScheduleViewType } from "./scheduleUtils";
import { useScheduleStore } from "./contexts/ScheduleStore";

type ScheduleTableProps = {
  schedule: ScheduleDetailsFragment;
  tasks: TaskFilterOptionsFragment[];
  projectRoleDetails?: ProjectRoleDetailsFragment[];
  fullScreen?: boolean;
  toggleFullScreen?: () => void;
  onTaskSave: (input: SaveScheduleTaskInput) => void;
  filterOptions: {
    assignees: TaskAssigneeFragment[];
    tradePartners: TaskTradePartnerFragment[];
    taskStatuses: TaskStatusesFragment[];
  };
  scheduleIsLocked: boolean;
  onRowClick: (taskId: any, scrollIntoViewType?: any) => void;
  globalPhaseContextProps: SchedulePhaseContextProps;
  setPaneId: (taskId: string) => void;
  lookup: React.MutableRefObject<GridRowLookup<ScheduleRow> | undefined>;
};

export function ScheduleTable(props: ScheduleTableProps) {
  const {
    schedule,
    fullScreen,
    toggleFullScreen,
    tasks,
    projectRoleDetails,
    onTaskSave,
    filterOptions,
    scheduleIsLocked,
    onRowClick,
    globalPhaseContextProps,
    setPaneId,
    lookup,
  } = props;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { dispatch, scheduleState } = useScheduleStore();

  const {
    taskPaneState: { taskPaneId },
    scheduleType,
    scheduleViewType,
  } = scheduleState;
  const gridTableApi = useGridTableApi<ScheduleRow>();
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);
  // custom hook to allow us to set queryParams to use as a filter instead of using persistedFilter
  const { queryStorage, setQueryStorage } = useQueryStorage({
    storageKey: "scheduleFilter",
    initialQueryStorage: customTaskFilterDefault,
  });
  const taskFilter = useMemo(() => mapToFilter(queryStorage), [queryStorage]);

  const isTaskFilterDefined = useMemo(() => {
    return Object.keys(definedFilterValues(taskFilter)).length > 0;
  }, [taskFilter]);
  const availableTableWidthRef = useRef<HTMLDivElement | null>(null);

  const client = useApolloClient();
  const { getFormState } = useFormStates<Partial<TaskDetailsFormInput>, TaskScheduleDetailsFragment>({
    config: formConfig,
    map: mapToForm,
    autoSave: async (os: ObjectState<Partial<TaskDetailsFormInput>>) => {
      const canEdit = determineTaskDateEditabilityById(os.id.value!, client);
      const { input } = getDateValueToUpdate(os, os.changedValue, canEdit, schedule?.scheduleSetting);
      await onTaskSave(mapToInput(input));
      os.commitChanges();
    },
    getId: (o: TaskScheduleDetailsFragment) => o.id,
  });

  const groupBy = useGroupBy<ScheduleListGroupBy>({
    phase: "Phase",
    upcoming: "Upcoming",
    none: "None",
  });

  // Sync the groupBy with the queryStorage
  useEffect(
    () => {
      if (queryStorage.groupBy) {
        groupBy.setValue(queryStorage.groupBy);
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryStorage.groupBy],
  );

  const clearFilters = useCallback(() => {
    setQueryStorage({} as CustomTaskFilter);
  }, [setQueryStorage]);
  const scrollToSubPhase = useCallback(
    (subPhaseId: string) => {
      // Scroll to subPhase position only applies to the list view of the schedule
      if (scheduleViewType !== ScheduleViewType.List || !lookup.current) return null;
      lookup.current.scrollTo("subPhase", subPhaseId);
    },
    [scheduleViewType, lookup],
  );
  const teamMembers = useMemo(() => getScheduleTeamMembers(schedule.parent), [schedule.parent]);

  // memoize the trigger props so they don't _always_ appear as a new object.
  const filterButtonTrigger = useMemo(() => ({ label: "Filter" }), []);
  // memoize the callback function
  const showScheduleFilterModal = useCallback(
    (close: VoidFunction) => {
      return (
        <ScheduleFilterModal
          filterOptions={filterOptions}
          filter={queryStorage}
          setFilter={setQueryStorage}
          groupBy={groupBy}
          close={close}
        />
      );
    },
    [filterOptions, queryStorage, setQueryStorage, groupBy],
  );

  const tableColumns = useMemo(() => {
    return createScheduleColumns({
      schedule,
      onTaskSave,
      onRowClick,
      taskStatuses: filterOptions?.taskStatuses ?? [],
      teamMembers: teamMembers ?? [],
      lazyTaskObjectStateFactory: getFormState,
      scheduleIsLocked,
      listGroupByValue: groupBy.value,
      scheduleType,
      tasks,
      dispatch,
      scrollToSubPhase,
      setPaneId,
      projectRoleDetails: projectRoleDetails ?? [],
    });
  }, [
    schedule,
    onTaskSave,
    onRowClick,
    filterOptions?.taskStatuses,
    teamMembers,
    projectRoleDetails,
    getFormState,
    groupBy.value,
    scheduleType,
    tasks,
    dispatch,
    scrollToSubPhase,
    setPaneId,
    scheduleIsLocked,
  ]);

  const [showScrollButton, setShowScrollButton] = useState(false);

  function handleScrollButtonVisibility(ev: React.UIEvent<HTMLDivElement, UIEvent>) {
    // Apply only if the event is within container
    if (!scrollableContainerRef.current || !scrollableContainerRef.current.contains(ev.target as HTMLElement))
      return null;

    const scrollTarget = ev.target as HTMLDivElement;
    const scroller = scrollTarget.getAttribute("data-virtuoso-scroller");
    const breakpointPx = 20;
    const scrollTopPx = scrollTarget.scrollTop;

    // When a user scrolls past the "breakpointPx" for the first time, show the scrollToTop button
    if (scroller && scrollTopPx > breakpointPx && !showScrollButton) {
      setShowScrollButton(true);
    } else if (scrollTopPx < breakpointPx && showScrollButton) {
      // When scrolling back to the top, hide the scrollToTopButton
      setShowScrollButton(false);
    }
  }

  return (
    <SchedulePhaseContext.Provider value={globalPhaseContextProps}>
      <ScheduleClearFilters.Provider value={clearFilters}>
        <div css={Css.df.fb2.jcsb.pt3.pb1.$}>
          <ScheduleViewTabs />
          <div css={Css.df.jcfe.gap1.mr1.$}>
            <ButtonModal content={showScheduleFilterModal} trigger={filterButtonTrigger} />
            {isTaskFilterDefined && (
              <Button label="Clear" variant="tertiary" onClick={() => setQueryStorage({} as CustomTaskFilter)} />
            )}
            <EditColumnsButton
              api={gridTableApi}
              trigger={{ label: "Columns" }}
              placement="right"
              columns={tableColumns}
              title="Select columns to show"
            />
            <SearchBox clearable debounceDelayInMs={100} onSearch={setSearchTerm} />
            {toggleFullScreen && (
              <IconButton
                color={Palette.Gray900}
                contrast={true}
                icon={fullScreen ? "collapse" : "expand"}
                onClick={toggleFullScreen}
              />
            )}
          </div>
        </div>
        <div ref={availableTableWidthRef}>
          <ScrollableContent virtualized>
            <div css={Css.h100.mr3.$}>
              <RightPaneLayout>
                <div css={Css.h100.$} onScroll={handleScrollButtonVisibility} ref={scrollableContainerRef}>
                  <ProjectScheduleTableV2
                    schedule={schedule}
                    taskFilter={taskFilter}
                    availableTableWidthRef={availableTableWidthRef}
                    searchTerm={searchTerm}
                    listGroupByValue={groupBy.value}
                    taskPaneId={taskPaneId}
                    rowLookup={lookup}
                    gridTableApi={gridTableApi}
                    columns={tableColumns}
                  />
                  {showScrollButton && <ScheduleScrollToTopButton gridTableApi={gridTableApi} />}
                </div>
              </RightPaneLayout>
            </div>
          </ScrollableContent>
        </div>
      </ScheduleClearFilters.Provider>
    </SchedulePhaseContext.Provider>
  );
}

function ScheduleScrollToTopButton({ gridTableApi }: { gridTableApi: GridTableApi<ScheduleRow> }) {
  return (
    <div css={Css.fixed.right1.bottom1.mr6.z9999.$}>
      <button
        data-testid="scheduleScrollToTop"
        css={Css.bgBlue900.white.br100.sqPx(42).onHover.smMd.bgGray100.blue700.bshBasic.$}
        onClick={() => {
          gridTableApi.scrollToIndex(0);
        }}
      >
        <Icon icon="arrowUp" inc={3} xss={Css.ma.$} />
      </button>
    </div>
  );
}
