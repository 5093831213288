import { EventApi, EventContentArg, EventInput } from "@fullcalendar/react";
import { Css, Palette, Tooltip, TriggerNoticeProps } from "@homebound/beam";
import { addDays, differenceInBusinessDays, subDays } from "date-fns";
import { formatDate } from "src/components";
import { Icon, IconKey } from "src/components/Icon";
import {
  DraftPlanTaskInput,
  DynamicSchedulesCalendar_PlanTaskFragment,
  ScheduleDraftMode_PlanTaskFragment,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";

export type DateIconsProps = {
  delayFlag?: string;
  materialDrop?: string;
  tradePartner?: string;
  isCalendarView?: boolean;
  workingDays?: string;
};

type CalendarPlanTask = DynamicSchedulesCalendar_PlanTaskFragment | ScheduleDraftMode_PlanTaskFragment;

// renders and styles our task "event" content
export function renderTaskEventContent(
  arg: EventContentArg,
  planTasksById: Record<string, CalendarPlanTask>,
  isEditMode?: boolean,
) {
  const planTask = planTasksById[arg.event.id] ?? [];
  const delayFlags = planTask.scheduleFlags ?? [];
  const materialDrop = planTask.globalPlanTask.developmentDrops.first;
  const workingDays = planTask.workDays.sortBy((wd) => wd.workDay.date);

  const delayFlagTooltip = delayFlags
    .map((d) => `[${d.reason?.title}] created on ${new Date(d.createdAt).toLocaleDateString()}`)
    .join(", ");
  const materialDropTooltip = materialDrop?.name ?? "";
  const workindDaysTooltip = workingDays.map((pt) => formatDate(pt.workDay.date, "short")).join(", ");
  const tradePartnersScheduledTooltip = planTask.tradePartner?.name ?? "";
  const isTaskManuallyScheduled = isTaskDraftMode(planTask) && planTask.isManuallyScheduled;

  return (
    <div
      css={{
        ...Css.df.aic.cursorPointer.gap1.p1.hPx(32).$,
        ...(isEditMode && Css.cursor("default").$),
      }}
    >
      {isTaskManuallyScheduled && <Icon icon="pin" color={Palette.Blue600} inc={2} />}
      {planTask.isCriticalPath && (
        <Tooltip title="Critical Path Task">
          <Icon icon="criticalPath" color={Palette.Gray900} inc={2} />
        </Tooltip>
      )}
      <div css={Css.br4.xsSb.$}>{arg.event.title}</div>
      <DateIcons
        delayFlag={delayFlagTooltip}
        materialDrop={materialDropTooltip}
        isCalendarView
        workingDays={workindDaysTooltip}
        tradePartner={tradePartnersScheduledTooltip}
      />
    </div>
  );
}

export function createTaskCalendarEvents(planTasks: CalendarPlanTask[]) {
  return planTasks.flatMap<EventInput>((pt) => {
    return [
      {
        id: pt.id,
        start: pt.startDate,
        // We add one day to the end date since FullCalendar's endDates are not inclusive when using allday events
        end: addDays(pt.endDate, 1),
        allDay: true,
        title: pt.name,
        backgroundColor: Palette.Blue100,
        textColor: Palette.Blue600,
      },
    ];
  });
}

export function getPlanTaskData(planTasks: CalendarPlanTask[]) {
  const events = createTaskCalendarEvents(planTasks);
  const planTasksById = planTasks.keyBy((pt) => pt.id);
  return { events, planTasksById };
}

export function DateIcons({ delayFlag, materialDrop, tradePartner, isCalendarView, workingDays }: DateIconsProps) {
  const containerStyles = isCalendarView
    ? Css.df.jcc.aic.pPx(3).br100.bgWhite.wPx(18).hPx(18).$
    : Css.df.jcc.aic.pPx(3).br100.bgWhite.wPx(25).hPx(25).$;

  const icons = [
    ...(delayFlag ? [{ icon: "flag", title: `Delay flag for ${delayFlag}.`, color: Palette.Red600, inc: 2 }] : []),
    ...(materialDrop ? [{ icon: "truck", title: `${materialDrop}`, color: Palette.Gray800, inc: undefined }] : []),
    ...(tradePartner ? [{ icon: "hardHat", title: `${tradePartner}`, color: Palette.Gray800, inc: undefined }] : []),
    ...(workingDays
      ? [{ icon: "calendar", title: `Working days: [${workingDays}]`, color: Palette.Blue500, inc: undefined }]
      : []),
  ];

  return isCalendarView ? (
    <div css={Css.df.jcsb.gap1.$}>
      {icons.map((icon, index) => (
        <div key={index}>
          <Tooltip title={icon.title}>
            <div css={containerStyles}>
              <Icon icon={icon.icon as IconKey} color={icon.color} inc={icon.inc} />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  ) : (
    <div css={Css.df.fdrr.fww.h100.w100.jcsb.absolute.gap2.$}>
      {/* The first icon will be at the bottom right */}
      {icons.length > 0 && (
        <div css={Css.absolute.bottomPx(5).rightPx(5).$}>
          {
            <Tooltip title={icons[0].title}>
              <div css={containerStyles}>
                <Icon icon={icons[0].icon as IconKey} color={icons[0].color} inc={icons[0].inc} />
              </div>
            </Tooltip>
          }
        </div>
      )}
      {/* The second icon will be at the top right */}
      {icons.length > 1 && (
        <div css={Css.absolute.topPx(5).rightPx(5).$}>
          <div css={containerStyles}>
            {
              <Tooltip title={icons[1].title}>
                <div css={containerStyles}>
                  <Icon icon={icons[1].icon as IconKey} color={icons[1].color} inc={icons[1].inc} />
                </div>
              </Tooltip>
            }
          </div>
        </div>
      )}
      {/* The last icon will be at the top left */}
      {icons.length > 2 && (
        <div css={Css.absolute.topPx(5).leftPx(5).$}>
          <div css={containerStyles}>
            {
              <Tooltip title={icons[2].title}>
                <div css={containerStyles}>
                  <Icon icon={icons[2].icon as IconKey} color={icons[2].color} inc={icons[2].inc} />
                </div>
              </Tooltip>
            }
          </div>
        </div>
      )}
    </div>
  );
}

export function onDraftTaskEventDrop(
  event: EventApi,
  revert: () => void,
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void,
  triggerNotice: (props: TriggerNoticeProps) => { close: () => void },
) {
  const taskId = event.id;
  const newStartDate = event.start ? new DateOnly(event.start) : undefined;
  const isDisabled = isDisabledDay(newStartDate);

  // Show error message if the start date is moved to a non-working day (Saturday or Sunday)
  if (isDisabled) {
    revert();
    return triggerNotice({
      message: `Start Date of ${formatDate(newStartDate)} is not a working day for this schedule`,
      icon: "error",
    });
  }

  return setDraftTaskChanges([
    {
      id: taskId,
      earliestStartDate: newStartDate,
      isManuallyScheduled: true,
    },
  ]);
}

export function onDraftTaskEventResize(
  event: EventApi,
  revert: () => void,
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void,
  triggerNotice: (props: TriggerNoticeProps) => { close: () => void },
) {
  const startDate = event.start ? new DateOnly(event.start) : undefined;
  const taskId = event.id;
  // Subtract one day from the end date since we added one day when rendering the event
  const endDate = event.end ? new DateOnly(subDays(event.end, 1)) : undefined;
  // Add one day to the duration difference because the end date is not inclusive
  const durationInDays = (startDate && endDate && differenceInBusinessDays(endDate, startDate) + 1) || undefined;
  const isDisabled = isDisabledDay(startDate, endDate);

  // Show error message if endDate is moved to a non-working day (Saturday or Sunday)
  if (isDisabled) {
    revert();
    return triggerNotice({
      message: `End Date of ${formatDate(endDate)} is not a working day for this schedule`,
      icon: "error",
    });
  }

  return setDraftTaskChanges([
    {
      id: taskId,
      knownDurationInDays: durationInDays,
    },
  ]);
}

function isDisabledDay(newStartDate: DateOnly | undefined, endDate?: DateOnly | undefined) {
  const totalWorkingDays = [1, 2, 3, 4, 5];

  if (newStartDate && !totalWorkingDays.includes(newStartDate.getDay())) {
    return true;
  }

  if (endDate && !totalWorkingDays.includes(endDate.getDay())) {
    return true;
  }
}

// helper type guard function to determine which fragment the planTask is
function isTaskDraftMode(
  planTask: DynamicSchedulesCalendar_PlanTaskFragment | ScheduleDraftMode_PlanTaskFragment,
): planTask is ScheduleDraftMode_PlanTaskFragment {
  return planTask !== undefined && planTask.hasOwnProperty("isManuallyScheduled");
}
