import {
  BoundSelectField,
  BoundTextField,
  BoundMultiSelectField,
  Button,
  Css,
  FormLines,
  useModal,
  useSnackbar,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useState } from "react";
import { CommentFeed2 } from "src/components";
import {
  BidItemCostTypeFragment,
  BidItemItemsFragment,
  BidItemSuperDrawer_BidItemFragment,
  BidItemUnitsOfMeasureFragment,
  SaveBidItemInput,
  useSaveBidItemMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { BidItemSuperDrawer } from "../BidItemSuperDrawer";

export type BidItemOverviewTabProps = {
  bidItem: BidItemSuperDrawer_BidItemFragment;
  items: BidItemItemsFragment[];
  unitsOfMeasure: BidItemUnitsOfMeasureFragment[];
  costTypes: BidItemCostTypeFragment[];
};

export function BidItemOverviewTab(props: BidItemOverviewTabProps) {
  const { bidItem, items, unitsOfMeasure, costTypes } = props;
  const [saveBidItem, { loading }] = useSaveBidItemMutation();
  const { closeDrawer } = useSuperDrawer();
  const [readOnly, setReadOnly] = useState(true);
  const { triggerNotice } = useSnackbar();
  const { openModal } = useModal();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: bidItem,
      map: ({ items, costType, unitOfMeasure, ...others }) => ({
        items: items.map((item) => item.id),
        costType: costType.code,
        unitOfMeasureId: unitOfMeasure.id,
        ...others,
      }),
    },
  });

  const handleSave = async () => {
    const { data } = await saveBidItem({
      variables: {
        input: formState.value,
      },
    });

    const bidItem = data?.saveBidItem.bidItem;

    if (bidItem) {
      triggerNotice({
        message: `Bid item: ${bidItem.name} (${bidItem.id}) successfully updated`,
        icon: "success",
      });
      closeDrawer();
    }
  };

  return (
    <div>
      <div css={Css.df.$}>
        <div css={Css.wPx(550).$}>
          <div css={Css.xlSb.mb2.$}>{bidItem.name}</div>
          <FormLines labelStyle="left">
            <BoundTextField field={formState.code} readOnly compact label="Bid item code" />
            <BoundSelectField
              field={formState.costType}
              readOnly
              getOptionValue={({ code }) => code}
              getOptionLabel={({ name }) => name}
              options={costTypes}
              label="Cost type"
            />
            <BoundSelectField field={formState.unitOfMeasureId} readOnly options={unitsOfMeasure} />
            <BoundTextField field={formState.name} readOnly={readOnly} compact label="Description" />
            <BoundMultiSelectField
              field={formState.items}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ displayName }) => displayName}
              readOnly={readOnly}
              options={items}
              label="Item code(s)"
            />
          </FormLines>
        </div>
        <div css={Css.w100.$}>
          <div css={Css.df.$}>
            <Observer>
              {() => (
                <div css={Css.mla.df.gap1.pr1.$}>
                  {readOnly ? (
                    <>
                      <Button
                        label="Duplicate"
                        onClick={() => openModal({ content: <DuplicationModal bidItem={bidItem} /> })}
                      />
                      <Button label="Edit" variant="secondary" onClick={() => setReadOnly(false)} />
                    </>
                  ) : (
                    <>
                      <Button
                        label="Cancel"
                        variant="tertiary"
                        disabled={loading}
                        onClick={() => {
                          formState.revertChanges();
                          setReadOnly(true);
                        }}
                      />
                      <Button
                        label="Save changes"
                        disabled={loading || !formState.dirty}
                        variant="primary"
                        onClick={handleSave}
                      />
                    </>
                  )}
                </div>
              )}
            </Observer>
          </div>
          <div css={Css.df.mt4.$}>
            <div css={Css.mla.$}>
              <CommentFeed2 commentable={bidItem} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type FormValue = Omit<SaveBidItemInput, "products">;

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  code: { type: "value", rules: [required] },
  costType: { type: "value", rules: [required] },
  unitOfMeasureId: { type: "value", rules: [required] },
  items: { type: "value", rules: [required] },
};

type DuplicationModalProps = {
  bidItem: BidItemSuperDrawer_BidItemFragment;
};

function DuplicationModal({ bidItem }: DuplicationModalProps) {
  const { triggerNotice } = useSnackbar();
  const [saveBidItem] = useSaveBidItemMutation();
  const { closeDrawer, openInDrawer } = useSuperDrawer();

  return (
    <ConfirmationModal
      confirmationMessage="Do you want to duplicate this bid item? A new one will be created with the same information"
      onConfirmAction={async () => {
        const { data } = await saveBidItem({
          variables: {
            // Map the current bid item's information
            input: {
              name: `${bidItem.name} [COPY]`,
              code: `${bidItem.code}-2`,
              costType: bidItem.costType.code,
              items: bidItem.items.map((i) => i.id),
              unitOfMeasureId: bidItem.unitOfMeasure.id,
            },
          },
        });

        if (data) {
          triggerNotice({
            icon: "success",
            message: "Bid Item successfully duplicated",
            action: {
              label: "Go to Bid Item",
              onClick: () =>
                openInDrawer({
                  content: <BidItemSuperDrawer bidItemId={data.saveBidItem.bidItem.id} />,
                }),
              variant: "tertiary",
            },
          });

          closeDrawer();
        }
      }}
      title="Duplicate bid item"
      label="Confirm"
    />
  );
}
