import {
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  IconButton,
  useRightPane,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { addBusinessDays, differenceInBusinessDays } from "date-fns";
import { Observer } from "mobx-react";
import { useMemo, useState } from "react";
import {
  AddTaskPaneScheduleDetailsFragment,
  ProjectFeature,
  SaveScheduleTaskInput,
  ScheduleTask,
  ScheduleTaskDateEditabilityFragment,
  TaskDependencyType,
  TaskStatus,
  useAddScheduleTaskQuery,
  useSaveScheduleTasksMutation,
} from "src/generated/graphql-types";
import { checklistConfig, TaskChecklistItems } from "src/routes/projects/schedule-v2/detail-pane/TaskChecklistItems";
import { PhaseRow, SubPhaseRow, TaskRow } from "src/routes/projects/schedule-v2/table/utils";
import { capitalize, noop } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";
import { useScheduleStore } from "../contexts/ScheduleStore";
import { setTaskPaneState } from "../contexts/scheduleStoreReducer";
import { RoleSelectField } from "../detail-pane/RoleSelectField";
import { AddingType, TaskDependencies } from "../detail-pane/TaskDependencies";
import { AddScheduleTaskPhases } from "./AddScheduleTaskPhases";
import { DateCellField } from "./DateCellField";
import { DurationCellField } from "./DurationCellField";
import { ScheduleType } from "./ScheduleType";
import { DependencyValue, getBusinessDays, TaskDetailsFormInput } from "./utils";
import { useProjectContext } from "../../context/ProjectContext";

export type AddScheduleTaskPaneProps = {
  tasks: Pick<ScheduleTask, "id" | "name">[];
  schedule: AddTaskPaneScheduleDetailsFragment;
  type: AddingType;
  refetchQueries: string[];
  scheduleIsLocked?: boolean;
  formValues: Partial<
    Pick<
      TaskDetailsFormInput,
      | "startDate"
      | "endDate"
      | "schedulePhase"
      | "scheduleSubPhase"
      | "predecessorDependencies"
      | "successorDependencies"
    >
  >;
  afterSave?: (createdTaskId: string) => void;
};

export function AddScheduleTaskPane({
  tasks,
  schedule,
  type,
  formValues,
  refetchQueries,
  afterSave,
  scheduleIsLocked,
}: AddScheduleTaskPaneProps) {
  const { closeRightPane } = useRightPane();
  const { triggerNotice } = useSnackbar();
  const {
    dispatch,
    scheduleState: { scheduleType, taskNumberMap },
  } = useScheduleStore();
  const [saveTask] = useSaveScheduleTasksMutation({
    onCompleted: (data) => {
      const createdTask = data.saveScheduleTasks.scheduleTasks.first;
      if (afterSave && createdTask) afterSave(createdTask.id);
    },
  });
  const today: DateOnly = new DateOnly(new Date());

  const { startDate, endDate, schedulePhase, scheduleSubPhase, predecessorDependencies, successorDependencies } =
    formValues;

  const globalParentId = scheduleSubPhase || schedulePhase;

  const title = getTitle();
  /** Disabled dates apply when a predecessor is add to the task so for Add a Successor Task initial value must be true because it has a predecessor */
  const [disabledDates, setDisabledDates] = useState(type === "successor");
  const businessDays = getBusinessDays(schedule?.scheduleSetting?.workingDays ?? []);
  const exceptions = schedule?.scheduleSetting?.exceptions;
  const parentsWithTradePartners = scheduleType === ScheduleType.Template ? [] : [schedule?.parent?.id];
  const query = useAddScheduleTaskQuery({
    variables: { parentsWithTradePartners },
    fetchPolicy: "cache-first",
  });
  const { data } = query;

  const [taskEditability, setTaskEditability] = useState<ScheduleTaskDateEditabilityFragment>({
    id: "",
    canEditEndDate: {
      allowed: true,
      disabledReasons: [],
    },
    canEditStartDate: {
      allowed: true,
      disabledReasons: [],
    },
    interval: {
      startDate: today,
      endDate: today,
      durationInDays: 0,
    },
    canEditDuration: {
      allowed: true,
      disabledReasons: [],
    },
  });

  // Memoizing formConfig so we can conditionaly check against globalParentId
  const formConfig: ObjectConfig<Partial<TaskDetailsFormInput>> = useMemo(
    () => ({
      name: { type: "value", rules: [required] },
      templateProjectRole: { type: "value" },
      startDate: { type: "value" },
      endDate: { type: "value" },
      durationInDays: { type: "value", rules: [required] },
      tradePartnerId: { type: "value" },
      internalUserId: { type: "value" },
      schedulePhase: { type: "value", rules: !globalParentId ? [required] : [] },
      scheduleSubPhase: { type: "value" },
      successorDependencies: {
        type: "list",
        config: dependencyConfig,
      },
      predecessorDependencies: {
        type: "list",
        config: dependencyConfig,
      },
      scheduleTaskChecklistItems: {
        type: "list",
        config: checklistConfig,
      },
    }),
    [globalParentId],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      name: "",
      templateProjectRole: undefined,
      startDate: startDate || today,
      endDate: endDate || today,
      durationInDays:
        endDate && startDate && type !== "predecessor" && type !== "successor"
          ? differenceInBusinessDays(endDate, startDate, { businessDays, exceptions })
          : 0,
      tradePartnerId: undefined,
      internalUserId: undefined,
      successorDependencies: successorDependencies || [],
      predecessorDependencies: predecessorDependencies || [],
      scheduleTaskChecklistItems: [],
    },
  });

  function handleDependencies() {
    setDisabledDates(!formState.predecessorDependencies.value.isEmpty);
  }

  function mapToInput(changedValue: Partial<TaskDetailsFormInput>, globalParentId?: string): SaveScheduleTaskInput {
    const {
      predecessorDependencies,
      successorDependencies,
      name,
      startDate,
      durationInDays,
      internalUserId,
      tradePartnerId,
      scheduleSubPhase,
      schedulePhase,
      scheduleTaskChecklistItems,
      templateProjectRole,
    } = changedValue;

    // if there is a subphase it will link to the proper phase
    const parentId = globalParentId || scheduleSubPhase || schedulePhase!;

    return {
      name: name?.trim(),
      templateProjectRole,
      startDate: startDate ? new DateOnly(startDate) : new DateOnly(new Date()),
      durationInDays: durationInDays ?? 0,
      scheduleId: schedule?.id,
      internalUserId,
      tradePartnerId,
      [parentId.startsWith("ms:") ? "schedulePhase" : "scheduleSubPhase"]: parentId,
      status: TaskStatus.NotStarted,
      scheduleTaskChecklistItems,
      ...(predecessorDependencies
        ? {
            predecessorDependencies: predecessorDependencies.map(({ id, lagInDays, type }) => ({
              predecessorId: id,
              lagInDays,
              type,
            })),
          }
        : {}),
      ...(successorDependencies
        ? {
            successorDependencies: successorDependencies.map(({ id, lagInDays, type }) => ({
              successorId: id,
              lagInDays,
              type,
            })),
          }
        : {}),
    };
  }

  const onSubmit = async () => {
    const result = await saveTask({
      refetchQueries,
      variables: {
        input: {
          tasks: [mapToInput(formState.value, globalParentId!)],
        },
      },
    });

    if (result.data) {
      triggerNotice({
        icon: "success",
        message: "Task successfully added!",
      });
      dispatch(
        setTaskPaneState({
          taskPaneId: result?.data?.saveScheduleTasks?.scheduleTasks[0].id,
          tab: "details",
        }),
      );
    }
  };

  function onChangeStartDate(value: Date | undefined) {
    formState.startDate.set(new DateOnly(value || today));
    updateDuration();
    updateTaskEditability();
  }

  function onChangeEndDate(value: Date | undefined) {
    formState.endDate.set(new DateOnly(value || today));
    updateDuration();
    updateTaskEditability();
  }

  function onChangeDuration(value: number | undefined) {
    formState.durationInDays.set(value);
    value === 0 ? formState.endDate.set(formState.startDate.value) : updateEndDate();
    updateTaskEditability();
  }

  function updateEndDate() {
    const start = formState.startDate.value || today;
    const duration = formState.durationInDays.value ?? 0;
    formState.endDate.set(new DateOnly(addBusinessDays(start, duration, { businessDays, exceptions })));
  }

  function updateDuration() {
    const start = formState.startDate.value || today;
    const end = formState.endDate.value || today;
    const duration = differenceInBusinessDays(end, start, { businessDays, exceptions });

    // Reset the end date if the selected value in the start date is greater than the end date
    if (duration < 0) formState.endDate.set(start);

    formState.durationInDays.set(duration < 0 ? 0 : duration);
  }

  function updateTaskEditability() {
    const start = formState.startDate.value || today;
    const end = formState.endDate.value || today;
    const duration = formState.durationInDays.value ?? 0;

    setTaskEditability((prevState) => ({
      ...prevState,
      interval: {
        startDate: start,
        endDate: end,
        durationInDays: duration,
      },
    }));
  }

  function getTitle(): string {
    if (type !== "task") return `Add a ${capitalize(type)} Task`;
    if (schedulePhase) {
      return "Add Task to Phase";
    } else if (scheduleSubPhase) {
      return "Add Task to Subphase";
    } else {
      return "Add Schedule Task";
    }
  }

  return (
    // className needed to calendar behaviour
    <div css={Css.px4.pt3.pbPx(80).bgWhite.h100.oa.$} data-testid="add_task_pane" className="add_task_pane">
      <div css={Css.df.jcsb.aic.gap1.mb3.$}>
        <div css={Css.lgSb.$} data-testid="add_task_pane_title">
          {title}
        </div>
        <IconButton data-testid="closePane" icon="x" onClick={closeRightPane} />
      </div>
      <FormLines width="full">
        <BoundTextField field={formState.name} label="Task Name" />
        <div css={Css.df.gap1.$}>
          <DateCellField
            iconLeft={false}
            field="startDate"
            objectState={formState}
            editability={taskEditability}
            scheduleIsLocked={false}
            label="Start Date"
            onChange={onChangeStartDate}
            disabledField={disabledDates}
          />
          <DateCellField
            iconLeft={false}
            field="endDate"
            objectState={formState}
            editability={taskEditability}
            scheduleIsLocked={false}
            label="End Date"
            onChange={onChangeEndDate}
            disabledField={disabledDates}
          />
        </div>
        <DurationCellField
          objectState={formState}
          editability={taskEditability}
          scheduleIsLocked={false}
          onChange={onChangeDuration}
        />
        {scheduleType === ScheduleType.Template && (
          <RoleSelectField formState={formState} scheduleIsLocked={scheduleIsLocked ?? false} />
        )}
        {scheduleType !== ScheduleType.Template && (
          <>
            <BoundSelectField
              label="Assignee"
              compact
              field={formState.internalUserId}
              options={data?.internalUsers || []}
              fieldDecoration={internalUserAvatar}
              getOptionMenuLabel={internalUserMenuLabel}
            />
            <BoundSelectField
              label="Trade Partner"
              compact
              field={formState.tradePartnerId}
              options={data?.tradePartners || []}
              disabled={data?.tradePartners.isEmpty && "There are no trade partners with commitments on this project"}
            />

            {!globalParentId && <AddScheduleTaskPhases formState={formState} scheduleId={schedule.id} />}
          </>
        )}
        <TaskDependencies
          formState={formState}
          tasks={tasks}
          taskId={""}
          onSave={handleDependencies}
          onJump={noop}
          scheduleIsLocked={false}
          isNewTask={true}
          type={type}
          taskNumberMap={taskNumberMap}
        />
        <TaskChecklistItems scheduleIsLocked={false} isTemplate={false} formState={formState} newTask />
      </FormLines>
      <div css={Css.px4.df.aic.jcfe.bt.bcGray200.mxPx(-32).wPx(450).hPx(70).fixed.bottom0.bgWhite.$}>
        <Button data-testid="cancelButton" variant="tertiary" label="Cancel" onClick={closeRightPane} />
        <Observer>{() => <Button label="Add task" onClick={onSubmit} disabled={!formState.valid} />}</Observer>
      </div>
    </div>
  );
}

const dependencyConfig: ObjectConfig<DependencyValue> = {
  type: { type: "value" },
  lagInDays: { type: "value" },
  delete: { type: "value" },
  id: { type: "value" },
  name: { type: "value" },
};

type SimplifiedScheduleRows =
  | {
      kind: "task";
      id: string;
      data: Pick<TaskRow["data"], "interval" | "id" | "name"> & {
        globalPhase?: { id: string } | null;
        globalSubPhase?: { id: string } | null;
      };
    }
  | { kind: "subPhase"; id: string; data: Pick<SubPhaseRow["data"], "subPhaseId"> }
  | { kind: "phase"; id: string; data: Pick<PhaseRow["data"], "phaseId"> };

/** Given kinded/row data for a schedule entity, generate form inputs for the task form */
export function getAddTaskFormValues(scheduleRow: SimplifiedScheduleRows, addingType?: AddingType) {
  switch (scheduleRow.kind) {
    case "task":
      return {
        schedulePhase: scheduleRow.data.globalPhase?.id,
        scheduleSubPhase: scheduleRow.data.globalSubPhase?.id,
        startDate: scheduleRow.data.interval?.endDate,
        endDate: scheduleRow.data.interval?.endDate,
        predecessorDependencies:
          addingType === "successor" && scheduleRow.data.id
            ? [
                {
                  id: scheduleRow.data.id,
                  name: scheduleRow.data.name,
                  type: TaskDependencyType.FinishStart,
                  delete: false,
                  lagInDays: 0,
                },
              ]
            : [],
        successorDependencies:
          addingType === "predecessor" && scheduleRow.data.id
            ? [
                {
                  id: scheduleRow.data.id,
                  name: scheduleRow.data.name,
                  type: TaskDependencyType.FinishStart,
                  delete: false,
                  lagInDays: 0,
                },
              ]
            : [],
      };
    case "phase":
      return {
        schedulePhase: scheduleRow.data.phaseId,
      };
    case "subPhase":
      return {
        scheduleSubPhase: scheduleRow.data.subPhaseId,
      };
  }
}
