import FullCalendar, { EventClickArg } from "@fullcalendar/react";
import { createRef, useCallback } from "react";

import { PersonalDashboard_ScheduleTaskFragment } from "src/generated/graphql-types";
import {
  createTaskCalendarEvents,
  renderTaskEventContent,
} from "src/routes/projects/schedule-v2/calendar/calendarTaskUtils";
import { WrappedFullCalendar } from "src/routes/projects/schedule-v2/calendar/WrappedFullCalendar";
import { TaskColorLegend } from "src/routes/projects/schedule-v2/components/TaskColorLegend";

type UpcomingCalendarViewProps = {
  scheduleTasks: PersonalDashboard_ScheduleTaskFragment[];
  onTaskSelect: (id: string) => void;
  selectedTaskId: string | undefined;
};

export function UpcomingCalendarView(props: UpcomingCalendarViewProps) {
  const { scheduleTasks, selectedTaskId, onTaskSelect } = props;
  const calendarRef = createRef<FullCalendar>();

  const events = createTaskCalendarEvents(scheduleTasks, { selectedTaskId });

  const onTaskClick = useCallback(
    ({ event }: EventClickArg) => {
      onTaskSelect(event.id);
    },
    [onTaskSelect],
  );

  return (
    <WrappedFullCalendar
      calendarRef={calendarRef}
      events={events}
      eventClick={onTaskClick}
      eventContent={renderTaskEventContent}
      legend={<TaskColorLegend title="Color legend:" />}
      dayMaxEvents={2}
      editable={false}
    />
  );
}
