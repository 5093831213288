import {
  ButtonModal,
  Css,
  Icon,
  IconButton,
  LoadingSkeleton,
  Palette,
  useBreakpoint,
  useTestIds,
} from "@homebound/beam";
import { startOfToday, subDays } from "date-fns";
import { useHistory } from "react-router";
import { TasksSummaryHeaderQuery, useTasksSummaryHeaderQuery } from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { DAYS_IN_WEEK, useWeekRangeFilter } from "src/routes/developments/reports/useWeekRangeFilter";
import { useDashboardFilterContext } from "src/routes/personal-dashboard/DashboardFilterContext";
import { SummaryTaskTypes, UpcomingDueIn } from "src/routes/personal-dashboard/enums";
import { personalDashboardPaths } from "src/routes/routesDef";
import { pluralize, queryResult } from "src/utils";
import { addDaysDateOnly, DateOnly } from "src/utils/dates";
import { nonClosedProjects } from "src/utils/projects";

const WEEKS_TO_SHOW = 4;
export function TasksSummaryHeader() {
  const rangeFilter = useWeekRangeFilter({ weekCount: WEEKS_TO_SHOW });
  const { sm } = useBreakpoint();
  const { dateRange, weekCount } = rangeFilter;
  // creating a date range to grab all tasks who's start or end date are within 4 weeks from today (4 weeks prior and 4 weeks ahead)
  const eightWeekStartRange = subDays(dateRange.start, weekCount * DAYS_IN_WEEK);
  const query = useTasksSummaryHeaderQuery({
    variables: {
      taskIntervalStart: new DateOnly(eightWeekStartRange),
      taskIntervalEnd: dateRange.end,
      projectStatus: nonClosedProjects,
    },
    fetchPolicy: "cache-first",
  });

  return queryResult(query, {
    data: (data) => {
      const triggerContent = sm ? (
        <span css={Css.xs.gray900.$}>
          <span css={Css.blue700.smBd.$}>{data?.scheduleTasks?.length}</span> Tasks
        </span>
      ) : (
        <div css={Css.df.aic.gap2.$}>
          <span css={Css.blue700.xl4.$}>{data?.scheduleTasks?.length}</span>
          <span>{pluralize(data?.scheduleTasks, "Task")}</span>
        </div>
      );

      return (
        <ButtonModal
          content={<TasksSummaryLayout data={data} />}
          trigger={{ label: triggerContent }}
          variant={sm ? "text" : "textSecondary"}
          placement={"right"}
        />
      );
    },
    loading: () => <LoadingSkeleton rows={1} columns={1} />,
  });
}

function TasksSummaryLayout({ data }: { data: TasksSummaryHeaderQuery }) {
  const { scheduleTasks } = data;
  const tid = useTestIds({}, "taskSummaryHeader");
  const today = new DateOnly(startOfToday());
  const history = useHistory();
  const { filter, setFilter } = useDashboardFilterContext();
  const currentInternalUser = useCurrentUser();
  // filter tasks that are overdue by 1 day
  const overdueTasks = scheduleTasks.filter((t) => t.interval.endDate <= addDaysDateOnly(today, -1));

  // helper for formatting assigned and overdue tasks
  function formatTasks(count: number, taskType: SummaryTaskTypes.ASSIGNED | SummaryTaskTypes.STALE) {
    const isAssigned = taskType === SummaryTaskTypes.ASSIGNED;
    const isStale = taskType === SummaryTaskTypes.STALE;
    const taskLabel = isAssigned ? "task" : "stale task";
    const taskTypeLabel = isAssigned ? SummaryTaskTypes.ASSIGNED : SummaryTaskTypes.STALE;

    return (
      <div data-testid={`${taskType}TaskIconButtonWrapper`} css={Css.df.aife.pt1.if(count === 0).pt1.$}>
        {count > 0 ? (
          <span css={Css.xl3Bd.if(isAssigned).blue700.if(isStale).red600.$}>
            {count} <span css={Css.ml1.mr1.gray900.xlBd.$}>{pluralize(count, taskLabel)}</span>
          </span>
        ) : (
          <span css={Css.xlBd.aic.$}>{`No ${taskTypeLabel} tasks`}</span>
        )}
        <IconButton
          icon={"chevronRight"}
          data-testid={taskType}
          inc={3}
          color={Palette.Gray700}
          onClick={() => {
            // routes to the upcoming tab page
            history.push(personalDashboardPaths.upcoming);
            // if a task is overdue, set filter to overdue, keep assigned to filter for current user
            setFilter({
              ...filter,
              dueIn: isStale ? UpcomingDueIn.STALE : undefined,
              internalUser: [currentInternalUser.id],
            });
          }}
        />
      </div>
    );
  }

  return (
    <div {...tid} css={Css.df.jcsb.smBd.wPx(535).hPx(75).if("sm").fdc.gap4.h("auto").w100.$}>
      <div>
        <span css={Css.df.aic.$}>
          Waiting on you
          <Icon
            icon={"infoCircle"}
            inc={2}
            xss={Css.ml1.$}
            color={Palette.Gray900}
            tooltip={"All tasks within the last 30 days that have an end date before today that are not completed"}
          />
        </span>
        {formatTasks(overdueTasks.length, SummaryTaskTypes.STALE)}
      </div>
      <div>
        <span css={Css.df.aic.$}>
          <div>Assigned to you</div>
          <Icon
            icon={"infoCircle"}
            inc={2}
            xss={Css.ml1.$}
            color={Palette.Gray900}
            tooltip={
              "All upcoming and stale (but not completed) tasks assigned to you in the next 4 weeks, as well as in-progress tasks from the last 4 weeks"
            }
          />
        </span>
        <div>{formatTasks(scheduleTasks.length, SummaryTaskTypes.ASSIGNED)}</div>
      </div>
    </div>
  );
}
