import { Css, Step, useComputed, useToast } from "@homebound/beam";
import { addDays } from "date-fns";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { stepperBarHeight, StepperProvider, useStepperContext } from "src/components/stepper";
import {
  ContractType,
  InvoiceProjectDataProjectFragment,
  InvoiceProjectStageFragment,
  InvoiceStatus,
  InvoiceV2Fragment,
  useInvoicePageV2Query,
  useInvoiceProjectDataQuery,
} from "src/generated/graphql-types";
import { IdOrAddParams, ProjectParams } from "src/routes/routesDef";
import { createInvoiceOldUrl, createInvoicesUrl } from "src/RouteUrls";
import { getStageFromSlug, hasData, renderLoadingOrError, StageUrlSlug } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { StringParam, useQueryParams } from "use-query-params";
import { InvoiceStepEnum } from "./enums";
import { ConfirmDrawAmount } from "./invoice-steps/ConfirmDrawAmount";
import { ConfirmLineItems } from "./invoice-steps/ConfirmLineItems";
import { InvoiceSettings } from "./invoice-steps/InvoiceSettings";
import { ReviewInvoice } from "./invoice-steps/ReviewInvoice";
import { SelectFixedItems } from "./invoice-steps/SelectFixedItems";
import { useHiddenProjectSideNav } from "src/hooks";

export default function InvoicePageV2() {
  useHiddenProjectSideNav();

  return (
    <StepperProvider steps={initialSteps}>
      <InvoicePageV2QueryWrapper />
    </StepperProvider>
  );
}

function InvoicePageV2QueryWrapper() {
  const { projectId, idOrAdd } = useParams<ProjectParams & IdOrAddParams>();
  const [{ stageSlug }] = useQueryParams({ stageSlug: StringParam });
  const history = useHistory();
  const { showToast } = useToast();
  const isNew = idOrAdd === "add";
  const invoiceProjectDataQuery = useInvoiceProjectDataQuery({ variables: { projectId } });
  const invoiceQuery = useInvoicePageV2Query({ variables: { id: idOrAdd }, skip: isNew });

  if (!isNew && !hasData(invoiceQuery)) {
    return renderLoadingOrError(invoiceQuery);
  }
  if (!hasData(invoiceProjectDataQuery)) {
    return renderLoadingOrError(invoiceProjectDataQuery);
  }
  // in case the user access directly the page without stageSlug param
  if (isNew && !stageSlug) {
    showToast({ type: "error", message: "You can't create a new invoice without pre select the project stage" });
    history.replace(createInvoicesUrl(projectId));
    return <></>;
  }
  // If we aren't drafting an invoice switch to published view
  if (!isNew && invoiceQuery.data?.invoice.status.code !== InvoiceStatus.Draft) {
    history.replace(createInvoiceOldUrl(projectId, idOrAdd));
    return <></>;
  }

  return <InvoicePageV2View invoice={invoiceQuery.data?.invoice} project={invoiceProjectDataQuery.data.project} />;
}

type InvoicePageV2ViewProps = {
  invoice?: InvoiceV2Fragment;
  project: InvoiceProjectDataProjectFragment;
};

function InvoicePageV2View({ invoice, project }: InvoicePageV2ViewProps) {
  const history = useHistory();
  const { currentStep, setSteps, goToStep } = useStepperContext();
  const { projectId } = useParams<ProjectParams & IdOrAddParams>();
  const [{ stageSlug }] = useQueryParams({ stageSlug: StringParam });

  const [draftInvoice, setDraftInvoice] = useState<InvoiceV2Fragment | undefined>(invoice);

  useComputed(() => {
    let projectStage;
    if (invoice) {
      // Store in state
      setDraftInvoice(invoice);
      projectStage = invoice.projectStage;
    } else if (stageSlug) {
      const stageParam = getStageFromSlug(stageSlug as StageUrlSlug);
      projectStage = project.stages.find((s) => s.stage.code === stageParam);
    }
    // Initialize the steps
    const hasCostPlusLineItems = (invoice?.lineItems || []).some(
      (li) => li.homeownerContractLineItem.owner.contractType === ContractType.CostPlus,
    );
    const hasFixedLineItems = (invoice?.lineItems || []).some(
      (li) => li.homeownerContractLineItem.owner.contractType === ContractType.Fixed,
    );

    const steps: Step[] = [
      {
        ...initialSteps[0],
        disabled: false,
        state: invoice ? "complete" : "incomplete",
      },
    ];
    if (projectStage?.hasDrawsToInvoice) {
      steps.push({
        ...initialSteps[1],
        disabled: !invoice,
        state: invoice?.drawLineItems?.length ? "complete" : "incomplete",
      });
    }
    if (projectStage?.hasCostsToInvoice) {
      steps.push({
        ...initialSteps[2],
        disabled: !invoice,
        state: hasCostPlusLineItems ? "complete" : "incomplete",
      });
    }
    if (projectStage?.hasFixedToInvoice) {
      steps.push({
        ...initialSteps[3],
        disabled: !invoice,
        state: hasFixedLineItems ? "complete" : "incomplete",
      });
    }
    steps.push({
      ...initialSteps[4],
      disabled: !(invoice?.drawLineItems?.length || invoice?.lineItems?.length),
      state: "incomplete",
    });
    setSteps(steps);

    // Go to appropriate step
    if (hasFixedLineItems) {
      goToStep(InvoiceStepEnum.SELECT_FIXED_ITEM);
    } else if (hasCostPlusLineItems) {
      goToStep(InvoiceStepEnum.SELECT_COST_PLUS_ITEM);
    } else if (invoice && invoice.drawLineItems.length > 0) {
      goToStep(InvoiceStepEnum.SELECT_DRAW_AMOUNT);
    }
  }, [invoice, project]);

  const newestInvoiceDate = useMemo(() => {
    const projectStages = project.stages;
    if (!projectStages || !draftInvoice) {
      return null;
    }

    const currentStage = projectStages.find((ps) => ps.id === draftInvoice!.projectStage.id);
    const invoiceDates = (currentStage?.invoices || [])
      .filter((inv) => inv.id !== draftInvoice!.id)
      .sort((a, b) => new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime());
    return invoiceDates.last ? new DateOnly(addDays(invoiceDates.last.invoiceDate, 1)) : project.startDate;
  }, [draftInvoice, project]);

  const exit = () => history.push(createInvoicesUrl(projectId));

  let projectStage: InvoiceProjectStageFragment;
  if (stageSlug) {
    const stageParam = getStageFromSlug(stageSlug as StageUrlSlug);
    projectStage = project.stages.find((s) => s.stage.code === stageParam)!;
  }

  const renderStep = () => {
    switch (currentStep.value) {
      case InvoiceStepEnum.SELECT_DRAW_AMOUNT:
        return (
          <ConfirmDrawAmount
            onExit={exit}
            setDraftInvoice={setDraftInvoice}
            draftInvoice={draftInvoice!}
            startDate={newestInvoiceDate!}
          />
        );
      case InvoiceStepEnum.SELECT_COST_PLUS_ITEM:
        return (
          <ConfirmLineItems
            onExit={exit}
            setDraftInvoice={setDraftInvoice}
            invoice={draftInvoice!}
            startDate={newestInvoiceDate!}
          />
        );
      case InvoiceStepEnum.SELECT_FIXED_ITEM:
        return <SelectFixedItems onExit={exit} setDraftInvoice={setDraftInvoice} invoice={draftInvoice!} />;
      case InvoiceStepEnum.REVIEW:
        return <ReviewInvoice lineItemStartDate={newestInvoiceDate!} onExit={exit} draftInvoice={draftInvoice!} />;
      default:
        return (
          <InvoiceSettings
            projectStage={projectStage}
            setDraftInvoice={setDraftInvoice}
            draftInvoice={draftInvoice}
            onExit={exit}
          />
        );
    }
  };

  return <div css={Css.mbPx(stepperBarHeight).$}>{renderStep()}</div>;
}

const initialSteps: Step[] = [
  {
    label: "Invoice settings",
    disabled: false,
    state: "incomplete" as const,
    value: InvoiceStepEnum.SETTINGS,
  },
  {
    label: "Select draw amounts",
    disabled: true,
    state: "incomplete",
    value: InvoiceStepEnum.SELECT_DRAW_AMOUNT,
  },
  {
    label: "Select Cost+ items",
    disabled: true,
    state: "incomplete",
    value: InvoiceStepEnum.SELECT_COST_PLUS_ITEM,
  },
  {
    label: "Select Fixed items",
    disabled: true,
    state: "incomplete",
    value: InvoiceStepEnum.SELECT_FIXED_ITEM,
  },
  {
    label: "Review invoice",
    disabled: true,
    state: "incomplete",
    value: InvoiceStepEnum.REVIEW,
  },
];
