import { BoundRichTextField, BoundSwitchField, Css, TabContent, Tabs, TabWithContent, Tooltip } from "@homebound/beam";
import { CommentFeed } from "src/components";
import {
  DocumentDetailDocumentTypeFragment,
  ProjectFeature,
  ProjectScheduleDocumentDetailFragment,
  ProjectStageProjectItemDetailFragment,
  SaveScheduleTaskInput,
  ScheduleSettingDetailsFragment,
  ScheduleStatus,
  Stage,
  TaskDetailPaneFragment,
  TaskFilterOptionsFragment,
  TaskTradePartnerFragment,
} from "src/generated/graphql-types";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { setTaskPaneState } from "src/routes/projects/schedule-v2/contexts/scheduleStoreReducer";
import { HistoryTab } from "src/routes/projects/schedule-v2/detail-pane/HistoryTab";
import { MaybeRenderForScheduleType } from "src/routes/projects/schedule-v2/MaybeRenderForScheduleType";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { CostAllocation } from "./CostAllocation";
import { DocumentRequirements } from "./document-requirements/DocumentRequirements";
import { TaskBills } from "./TaskBills";
import { FormValue } from "./TaskDetailPane";
import { TaskDetails } from "./TaskDetails";
import { TaskDocuments } from "./TaskDocuments";
import { TaskJobLogs } from "./TaskJobLogs";
import { TaskPurchaseOrders } from "./TaskPurchaseOrders";
import { useProjectContext } from "../../context/ProjectContext";

type TaskDetailPaneTabsProps = {
  scheduleParentId: string;
  projectItems: ProjectStageProjectItemDetailFragment[];
  documents: ProjectScheduleDocumentDetailFragment[];
  documentTypes: DocumentDetailDocumentTypeFragment[];
  formState: FormValue;
  task: TaskDetailPaneFragment;
  tasks: TaskFilterOptionsFragment[];
  taskId: string;
  onSave: (changedValue: SaveScheduleTaskInput) => void;
  onJump: (id: string) => void;
  onClose: VoidFunction;
  scheduleIsLocked: boolean;
  isTemplate: boolean;
  tradePartners: TaskTradePartnerFragment[];
  scheduleSetting?: ScheduleSettingDetailsFragment | null;
  scheduleStatus?: ScheduleStatus;
};

export function TaskDetailPaneTabs({
  scheduleParentId,
  formState,
  task,
  projectItems,
  documents,
  documentTypes,
  tasks,
  taskId,
  onSave,
  onJump,
  onClose,
  scheduleIsLocked,
  isTemplate,
  tradePartners,
  scheduleSetting,
  scheduleStatus,
}: TaskDetailPaneTabsProps) {
  const allocatedProjectItemIds = tasks.flatMap(({ projectItems }) => projectItems).map(({ id }) => id);
  const purchaseOrders = task.projectItems.filter(({ currentCommitment }) => currentCommitment?.document?.asset);
  const {
    dispatch,
    scheduleState: {
      taskPaneState: { tab },
      stage,
    },
  } = useScheduleStore();
  const { features } = useProjectContext();
  const showClickToPayFlag = features.includes(ProjectFeature.ClickToPay);

  const tabs: TabWithContent[] = [
    {
      name: "Details",
      value: "details",
      render: () => (
        <div data-testid="details" css={Css.df.fdc.jcsb.mbPx(70).$}>
          <TaskDetails
            scheduleIsLocked={scheduleIsLocked}
            isTemplate={isTemplate}
            tradePartners={tradePartners}
            formState={formState}
            task={task}
            scheduleSetting={scheduleSetting}
            scheduleStatus={scheduleStatus}
            tasks={tasks}
            onSave={onSave}
            onJump={onJump}
          />
        </div>
      ),
    },
    {
      name: "Relationships",
      value: "relationships",
      render: () => {
        const canEditDescription = formState.value.homeownerVisible && !scheduleIsLocked;
        return (
          <div data-testid="relationships">
            <MaybeRenderForScheduleType renderForTypes={[ScheduleType.Project]}>
              {projectItems && (
                <CostAllocation
                  formState={formState}
                  projectItems={projectItems}
                  allocatedProjectItemIds={allocatedProjectItemIds}
                />
              )}

              <TaskPurchaseOrders taskProjectItems={purchaseOrders} />
              {showClickToPayFlag && <TaskBills bills={task.bills} />}
              {stage === Stage.Construction && (
                <TaskJobLogs
                  jobLogNotes={task.jobLogNotes}
                  sourceTask={task}
                  scheduleSetting={scheduleSetting}
                  onClose={onClose}
                />
              )}
            </MaybeRenderForScheduleType>
            <MaybeRenderForScheduleType
              renderForTypes={[ScheduleType.Project, ScheduleType.Template]}
              optionalHideForStages={[Stage.Development]}
            >
              <div css={Css.df.fdc.mt3.gap3.gray900.$}>
                <BoundSwitchField
                  label="Visible to Homeowner"
                  labelStyle="inline"
                  disabled={scheduleIsLocked}
                  field={formState.homeownerVisible}
                />
                <div>
                  <BoundRichTextField
                    label="Description"
                    readOnly={!canEditDescription}
                    placeholder={canEditDescription ? "Enter a description" : ""}
                    field={formState.homeownerDescription}
                  />
                  <div css={Css.gray700.xs.mtPx(4).$}>This is content that will be visible to the homeowner in HOP</div>
                </div>
              </div>
            </MaybeRenderForScheduleType>
          </div>
        );
      },
    },
    {
      name: `Files`,
      value: "files",
      render: () => (
        <div data-testid="filesTab">
          <MaybeRenderForScheduleType renderForTypes={[ScheduleType.Project, ScheduleType.Development]}>
            <div css={Css.mb1.$}>
              <TaskDocuments
                formState={formState}
                documents={documents}
                documentTypes={documentTypes}
                parentId={scheduleParentId}
                task={task}
              />
            </div>
          </MaybeRenderForScheduleType>
          <MaybeRenderForScheduleType renderForTypes={[ScheduleType.Template]}>
            <DocumentRequirements
              scheduleTaskId={taskId}
              documentRequirements={task.requiredTaskDocuments}
              documentTypes={documentTypes}
              scheduleIsLocked={scheduleIsLocked}
            />
          </MaybeRenderForScheduleType>
        </div>
      ),
    },
    {
      name: `Comments`,
      value: "comments",
      render: () => <CommentFeed commentable={task} />,
    },
    {
      name: `History`,
      value: "history",
      render: () => (
        <div css={Css.mb1.$}>
          <HistoryTab taskId={taskId} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div css={Css.fs0.$}>
        <Tabs
          selected={tab}
          tabs={isTemplate ? [tabs[0], tabs[1], tabs[2]] : tabs}
          onChange={(value) => dispatch(setTaskPaneState({ tab: value }))}
        />
      </div>
      <TabContent
        contentXss={Css.df.fdc.fg1.mt1.$}
        selected={tab}
        tabs={isTemplate ? [tabs[0], tabs[1], tabs[2]] : tabs}
      />
    </>
  );
}
