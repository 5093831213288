import {
  Button,
  Checkbox,
  Css,
  FilterDefs,
  Filters,
  multiFilter,
  Palette,
  ScrollableParent,
  singleFilter,
  Tooltip,
  useModal,
  usePersistedFilter,
  useTestIds,
} from "@homebound/beam";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Icon } from "src/components";
import {
  TradeCategoryLeadTimeFilter,
  TradeCategoryLeadTimesLike_TradeCategoryLeadTimesLikeFragment,
  TradeCategoryLeadTimes_TradeCategoryLeadTimeFragment,
  useTradeCategoryLeadTimesMetaDataQuery,
  useTradeCategoryLeadTimesQuery,
} from "src/generated/graphql-types";
import { createTradePartnersUrl } from "src/RouteUrls";
import { HasIdAndName, queryResult } from "src/utils";
import { PageHeader } from "../../layout/PageHeader";
import { TableActions } from "../../layout/TableActions";
import { TradeCategoryLeadTimesModal } from "./TradeCategoryLeadTimesModal";
import { formatLeadTime } from "./utils";

type TradeCategoryLeadTimePageLayoutProps = {
  markets: HasIdAndName[];
  tradeCategories: HasIdAndName[];
};
// adding id as an option since our default "like" does not have an ID and Trade Category lead time needs it
type TradeCategoryLeadTimeLikeWithOptionalId = TradeCategoryLeadTimesLike_TradeCategoryLeadTimesLikeFragment & {
  id?: string;
};

export type AllTradeCategoryLeadTimes =
  | TradeCategoryLeadTimeLikeWithOptionalId
  | TradeCategoryLeadTimes_TradeCategoryLeadTimeFragment;

export function TradeCategoryLeadTimesPage() {
  const query = useTradeCategoryLeadTimesMetaDataQuery();
  return queryResult(query, {
    data: ({ markets, tradeCategories }) => {
      return <TradeCategoryLeadTimesPageLayout markets={markets} tradeCategories={tradeCategories} />;
    },
  });
}

export function TradeCategoryLeadTimesPageLayout({
  markets: allMarkets,
  tradeCategories,
}: TradeCategoryLeadTimePageLayoutProps) {
  const filterDefs: FilterDefs<TradeCategoryLeadTimeFilter> = useMemo(() => {
    const tradeCategory = multiFilter({
      options: tradeCategories,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
      label: "Category",
    });

    const market = singleFilter({
      label: "Markets",
      options: allMarkets,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
      nothingSelectedText: "None",
    });
    return { tradeCategory, market };
  }, [allMarkets, tradeCategories]);

  const { setFilter, filter } = usePersistedFilter<TradeCategoryLeadTimeFilter>({
    storageKey: "tradeCategoryLeadTimeFilter",
    filterDefs,
  });
  // local state to track (multiple) selected categories since we're not using GridTable
  const [selectedCategory, setSelectedCategory] = useState<AllTradeCategoryLeadTimes[]>([]);

  useEffect(() => {
    // if the market changes, make sure to unselect the category
    setSelectedCategory([]);
  }, [filter.market, filter.tradeCategory]);
  const { openModal } = useModal();

  const { data } = useTradeCategoryLeadTimesQuery({
    variables: { filter: { market: filter.market, tradeCategory: filter.tradeCategory } },
    skip: !filter.market,
  });

  return (
    <ScrollableParent xss={Css.px3.bgGray100.$}>
      <PageHeader
        title="Lead Times"
        xss={Css.bgWhite.$}
        breadcrumb={{ label: "Trade Partners", href: createTradePartnersUrl() }}
        left={
          <Tooltip
            title={
              "Define the amount of time that each Trade Partner needs to be notified in advance of starting a task."
            }
          >
            <Icon inc={2} icon="infoCircle" color={Palette.Gray700} />
          </Tooltip>
        }
      />
      <div css={Css.maxwPx(800).mx("auto").$}>
        <p css={Css.gray700.sm.mt1.mb3.$}>Lead Times can fluctuate, so approach these with flexibility.</p>
        <TableActions>
          <Filters filter={filter} onChange={setFilter} filterDefs={filterDefs} />
          <Button
            label="Update Selected Lead Times"
            disabled={selectedCategory.length === 0}
            onClick={() =>
              openModal({
                content: (
                  <TradeCategoryLeadTimesModal
                    marketId={filter.market}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                  />
                ),
              })
            }
          />
        </TableActions>
      </div>
      <div css={Css.maxwPx(800).bgWhite.mx("auto").br8.bshBasic.p3.$}>
        <div>
          {data?.tradeCategoryLeadTimes ? (
            <TradeCategoryLeadTimesTable
              tradeCategoryLeadTimes={data.tradeCategoryLeadTimes}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          ) : (
            <EmptyStateWrapper />
          )}
        </div>
      </div>
    </ScrollableParent>
  );
}

function TradeCategoryLeadTimesTable({
  tradeCategoryLeadTimes,
  selectedCategory,
  setSelectedCategory,
}: {
  tradeCategoryLeadTimes: AllTradeCategoryLeadTimes[];
  selectedCategory: AllTradeCategoryLeadTimes[];
  setSelectedCategory: React.Dispatch<React.SetStateAction<AllTradeCategoryLeadTimes[]>>;
}) {
  const tid = useTestIds({}, "tradeCategoryLeadTimes");
  return (
    <div css={Css.dg.gtc("2fr 1fr").$} {...tid}>
      <div>Trade Partner Category</div>
      <div>Frequency</div>
      {tradeCategoryLeadTimes.map((tclt) => {
        const isSelected = selectedCategory.map((sc) => sc.tradeCategory.id).includes(tclt.tradeCategory.id);
        return (
          <Fragment key={tclt.tradeCategory.id}>
            <div css={Css.df.aic.mt3.$}>
              <Checkbox
                selected={isSelected}
                onChange={(selected) =>
                  setSelectedCategory(
                    selected
                      ? [...selectedCategory, tclt]
                      : selectedCategory.filter((sc) => sc.tradeCategory.id !== tclt.tradeCategory.id),
                  )
                }
                label={`${tclt.tradeCategory.name} lead time`}
                checkboxOnly
              />
              <div css={Css.ml1.gray900.smMd.$} {...tid.name}>
                {tclt.tradeCategory.name}
              </div>
            </div>
            <div css={Css.mt3.wPx(83).$} {...tid.leadTime}>
              <div aria-label={`${tclt.tradeCategory.name} lead time`}>
                {formatLeadTime(tclt.leadTimeInDays, tclt.leadTimeFrequency)}
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}

function EmptyStateWrapper() {
  return (
    <div data-testid="emptyState" css={Css.df.aic.jcc.bgGray100.br8.p4.$}>
      <div css={Css.tac.maxwPx(750).$}>
        <p css={Css.xs.$}>Select a market to see the lead times.</p>
      </div>
    </div>
  );
}
