import {
  BoundChipSelectField,
  Css,
  GridDataRow,
  GridSortConfig,
  GridTable,
  Palette,
  ResponsiveGrid,
  ResponsiveGridItem,
  column,
  simpleHeader,
  Icon,
  Tooltip,
  emptyCell,
  collapseColumn,
  CollapseToggle,
  ScrollableContent,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { createTaskDetailsPageUrl } from "src/RouteUrls";
import {
  DynamicSchedulesList_PlanTaskFragment,
  DynamicSchedulesEnums_EnumDetailsFragment,
  Order,
  PlanTasksOrder,
  SavePlanTaskInput,
  TaskStatus,
  useDynamicSchedulesListQuery,
  useSavePlanTaskListMutation,
} from "src/generated/graphql-types";
import { assertNever, pluralize, queryResult } from "src/utils";
import { parseOrder, toOrder } from "src/utils/ordering";
import {
  customDynamicSchedulesFilterDefault,
  mapToFilter,
  TaskGroupBy,
} from "../components/DynamicSchedulesFilterModal";
import { PlanScheduleConfidence } from "../components/PlanScheduleConfidence";
import { PlanTaskProgress } from "../components/PlanTaskProgress";
import { ScheduleDateVariance } from "../components/ScheduleVariance";
import { useScheduleSnapshots, ScheduleSnapshotsHookResult } from "../components/useScheduleSnapshots";
import { useQueryStorage } from "../../schedule-v2/table/filterUtils";
import { StringParam, useQueryParams } from "use-query-params";
import { ObjectState, useFormStates, ObjectConfig } from "@homebound/form-state";
import { formatDate } from "src/components";
import { TaskStatusSelect } from "../../schedule-v2/components/TaskStatusSelect";

export function DynamicSchedulesList() {
  const { projectId } = useParams<{ projectId: string }>();
  const [orderBy, setOrderBy] = useState<PlanTasksOrder>({ startDate: Order.Asc });
  const { queryStorage: filter } = useQueryStorage({
    storageKey: "dynamicSchedulesFilter",
    initialQueryStorage: customDynamicSchedulesFilterDefault,
  });
  const [{ search }] = useQueryParams({ search: StringParam });

  const query = useDynamicSchedulesListQuery({
    variables: {
      filter: {
        scheduleParent: [projectId],
        ...mapToFilter(filter),
      },
      order: orderBy,
      page: {
        offset: 0,
        limit: 250,
      },
    },
  });

  const maybeFetchMore = useCallback(async () => {
    await query.fetchMore({
      variables: {
        filter: {
          scheduleParent: [projectId],
          ...mapToFilter(filter),
        },
        order: orderBy,
        page: {
          offset: query.data?.planTasks.entities.length,
          limit: 100,
        },
      },
    });
  }, [query, projectId, filter, orderBy]);

  return queryResult(query, (data) => {
    const {
      planTasks: { entities },
      enumDetails,
    } = data;

    const filteredEntities = entities.filter((task) => task.name.toLowerCase().includes(search?.toLowerCase() ?? ""));

    return (
      <div data-testid="listViewContent">
        <DynamicScheduleTable
          planTasks={filteredEntities}
          maybeFetchMore={maybeFetchMore}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          parentId={projectId}
          enumDetails={enumDetails}
          groupBy={filter.groupBy}
        />
      </div>
    );
  });
}

type DynamicScheduleTableProps = {
  planTasks: DynamicSchedulesList_PlanTaskFragment[];
  maybeFetchMore: () => void;
  orderBy: PlanTasksOrder;
  setOrderBy: (order: PlanTasksOrder) => void;
  parentId: string;
  enumDetails: DynamicSchedulesEnums_EnumDetailsFragment;
  groupBy: TaskGroupBy;
};

function DynamicScheduleTable(props: DynamicScheduleTableProps) {
  const { planTasks, maybeFetchMore, orderBy, setOrderBy, parentId, enumDetails, groupBy = TaskGroupBy.None } = props;

  const [savePlanTask] = useSavePlanTaskListMutation();
  const { getFormState } = useFormStates<FormInput, DynamicSchedulesList_PlanTaskFragment>({
    config: formConfig,
    map: (task) => ({ id: task.id, tradePartnerStatus: task.tradePartnerStatus.code, status: task.status.code }),
    getId: (t) => t.id,
    autoSave: async ({ value }) => {
      await savePlanTask({ variables: { input: value } });
    },
  });

  const { getTaskSnapshot } = useScheduleSnapshots(parentId);

  const rows = useMemo(() => createRows(planTasks, groupBy), [planTasks, groupBy]);
  const columns = useMemo(
    () => createColumns(getFormState, enumDetails, getTaskSnapshot, groupBy),
    [getFormState, enumDetails, getTaskSnapshot, groupBy],
  );

  const history = useHistory();

  const initSortState: GridSortConfig = useMemo(
    () => ({
      on: "server",
      onSort: (key, direction) => setOrderBy(toOrder(key, direction)),
      value: parseOrder(orderBy),
    }),
    [orderBy, setOrderBy],
  );

  return (
    <ScrollableContent virtualized>
      <div css={Css.w100.h("calc(100% - 24px)").addIn("> div", Css.h100.$).$}>
        <ResponsiveGrid columns={14} minColumnWidth={40} gap={30}>
          <ResponsiveGridItem colSpan={1} />
          <ResponsiveGridItem colSpan={12}>
            <GridTable
              as="virtual"
              columns={columns}
              rows={rows}
              rowStyles={{
                task: {
                  // onClick instead of rowLink to prevent clicks within inputs from bubbling to the link
                  onClick: (row) => history.push(createTaskDetailsPageUrl(parentId, row.data.id)),
                  cellCss: (row) => (row.data.isStale ? Css.bgYellow50.$ : Css.$),
                },
              }}
              infiniteScroll={{ onEndReached: maybeFetchMore }}
              sorting={initSortState}
              stickyHeader
            />
          </ResponsiveGridItem>
          <ResponsiveGridItem colSpan={1} />
        </ResponsiveGrid>
      </div>
    </ScrollableContent>
  );
}

type FormInput = Pick<SavePlanTaskInput, "id" | "tradePartnerStatus" | "status">;
type GetFormState = (row: DynamicSchedulesList_PlanTaskFragment) => ObjectState<FormInput>;

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  tradePartnerStatus: { type: "value" },
  status: { type: "value" },
};

type HeaderRow = { kind: "header" };
type StageRow = { kind: "stage"; data: { name: string } };
type TaskRow = { kind: "task"; data: DynamicSchedulesList_PlanTaskFragment };
type Row = HeaderRow | StageRow | TaskRow;

function createColumns(
  getFormState: GetFormState,
  enumDetails: DynamicSchedulesEnums_EnumDetailsFragment,
  getTaskSnapshot: ScheduleSnapshotsHookResult["getTaskSnapshot"],
  groupBy: TaskGroupBy,
) {
  return [
    ...(groupBy === TaskGroupBy.Stage
      ? [
          collapseColumn<Row>({
            header: emptyCell,
            stage: (_, { row }) => <CollapseToggle row={row} />,
            task: emptyCell,
          }),
        ]
      : []),
    column<Row>({
      header: () => <span css={Css.lgSb.$}>Tasks</span>,
      stage: ({ name }) => <span css={Css.smSb.$}>{name}</span>,
      task: ({ name }) => <span>{name}</span>,
    }),
    column<Row>({
      header: "Start",
      stage: emptyCell,
      task: ({ startDate, id }) => (
        <>
          <Tooltip title={formatDate(startDate, "long")}>
            <span>{formatDate(startDate, "weekDayMonthShort")}</span>
          </Tooltip>
          <ScheduleDateVariance baseline={getTaskSnapshot(id)?.startDate} current={startDate} />
        </>
      ),
      serverSideSortKey: "startDate",
    }),
    column<Row>({
      header: "End",
      stage: emptyCell,
      task: ({ endDate }) => (
        <Tooltip title={formatDate(endDate, "long")}>
          <span>{formatDate(endDate, "weekDayMonthShort")}</span>
        </Tooltip>
      ),
      serverSideSortKey: "endDate",
    }),
    column<Row>({
      header: "Progress",
      stage: emptyCell,
      task: (task) => <PlanTaskProgress task={task} />,
      mw: "160px",
    }),
    column<Row>({
      header: () => <PlanTaskConfidenceHeader />,
      stage: emptyCell,
      task: ({ simulationProbability, status }) => (
        <PlanScheduleConfidence
          probabilityBasisPoints={simulationProbability?.endDateBasisPoints}
          isComplete={status.code === TaskStatus.Complete}
          entityType="task"
        />
      ),
    }),
    column<Row>({
      header: "Duration",
      stage: emptyCell,
      task: ({ durationInDays }) => {
        return (
          <span>
            {durationInDays} {pluralize(durationInDays, "day", "days")}
          </span>
        );
      },
    }),
    column<Row>({
      header: "Trade Scheduled",
      stage: emptyCell,
      task: (task) => <TradeCell task={task} />,
    }),
    column<Row>({
      header: "Status",
      stage: emptyCell,
      task: (task) => (
        <TaskStatusSelect
          hideLabel
          options={enumDetails.taskStatus}
          canComplete={task.canComplete}
          canStart={task.canStart}
          statusField={getFormState(task).status}
          disabled={false}
        />
      ),
      w: "150px",
    }),
    column<Row>({
      header: "Trade Status",
      stage: emptyCell,
      task: (task) => <TradeStatusCell task={task} getFormState={getFormState} enumDetails={enumDetails} />,
      w: "150px",
    }),
  ];
}

function TradeCell({ task }: { task: DynamicSchedulesList_PlanTaskFragment }) {
  const uniqueCommittedTrades = task.committedTradePartners.uniqueBy((tp) => tp.id);
  if (!task.tradePartner) return null;

  return (
    <div css={Css.df.gap1.aic.jcc.$}>
      {uniqueCommittedTrades.length > 1 && (
        <Icon
          icon="error"
          color={Palette.Red600}
          tooltip="Only 1 trade partner per task is recommended. The trade partner schedule status only applies to the first trade partner."
          inc={2.3}
        />
      )}
      <span css={Css.xsMd.$}>{task.tradePartner?.name}</span>
    </div>
  );
}

function TradeStatusCell({
  task,
  getFormState,
  enumDetails,
}: {
  task: DynamicSchedulesList_PlanTaskFragment;
  getFormState: GetFormState;
  enumDetails: DynamicSchedulesEnums_EnumDetailsFragment;
}) {
  const os = getFormState(task);

  // Don't show the trade status option if there is no trade
  if (!task.tradePartner) return null;

  return (
    <div>
      <BoundChipSelectField
        field={os.tradePartnerStatus}
        options={enumDetails.tradePartnerTaskStatus.sortByKey("name")}
        getOptionValue={(o) => o.code}
        getOptionLabel={(o) => o.name}
      />
    </div>
  );
}

function createRows(planTasks: DynamicSchedulesList_PlanTaskFragment[], groupBy: TaskGroupBy): GridDataRow<Row>[] {
  switch (groupBy) {
    case TaskGroupBy.Stage:
      return createStageRows(planTasks);
    case TaskGroupBy.None:
      return createFlatRows(planTasks);
    default:
      assertNever(groupBy);
  }
}

function createFlatRows(planTasks: DynamicSchedulesList_PlanTaskFragment[]): GridDataRow<Row>[] {
  return [simpleHeader, ...planTasks.map((task) => ({ kind: "task" as const, id: task.id, data: task }))];
}

function createStageRows(planTasks: DynamicSchedulesList_PlanTaskFragment[]): GridDataRow<Row>[] {
  const stageGroups = planTasks.groupBy((task) => task.stage?.id ?? "no-stage");
  return [
    simpleHeader,
    ...Object.entries(stageGroups)
      .sortBy(([_, tasks]) => {
        const earliestDate = tasks.min((t) => t.startDate);
        return earliestDate?.getTime() ?? 0;
      })
      .flatMap(([stageId, tasks]) => [
        {
          kind: "stage" as const,
          id: stageId,
          data: {
            name: tasks.first?.stage?.name ?? "No Stage",
          },
          children: tasks.map((task) => ({ kind: "task" as const, id: task.id, data: task })),
        },
      ]),
  ];
}

export function PlanTaskConfidenceHeader() {
  return (
    <div css={Css.df.gap1.aic.$}>
      <span>Confidence</span>
      <Icon
        icon="infoCircle"
        inc={2}
        color={Palette.Gray600}
        tooltip="The certainty of the task date ending on the estimated end date. High >= 80%, Medium >= 50%, Low < 50%."
      />
    </div>
  );
}
