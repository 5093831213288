import { SuperDrawerContent, SuperDrawerHeader, TabsWithContent, Tag, useSessionStorage } from "@homebound/beam";
import { useBidItemSuperDrawerDetailsQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { BidItemCostHistoryTab } from "./tabs/BidItemCostHistoryTab";
import { BidItemOverviewTab } from "./tabs/BidItemOverviewTab";

export type SelectionsSuperDrawerProps = {
  bidItemId: string;
};

export function BidItemSuperDrawer({ bidItemId }: SelectionsSuperDrawerProps) {
  const [selectedTab, setSelectedTab] = useSessionStorage("bidItemSuperDrawerTab", "overview");
  const bidItemDetailQuery = useBidItemSuperDrawerDetailsQuery({ variables: { bidItemId } });

  return queryResult(bidItemDetailQuery, ({ bidItems: [bidItem], ...otherOptions }) => {
    const overviewTab = {
      name: "Overview",
      value: "overview",
      render: () => <BidItemOverviewTab bidItem={bidItem} {...otherOptions} />,
    };

    const historyTab = {
      name: "Cost history",
      value: "history",
      render: () => <BidItemCostHistoryTab bidItem={bidItem} />,
    };

    return (
      <SuperDrawerContent>
        <SuperDrawerHeader title={bidItem.name} />
        <TabsWithContent tabs={[overviewTab, historyTab]} selected={selectedTab} onChange={setSelectedTab} />
      </SuperDrawerContent>
    );
  });
}
