import {
  actionColumn,
  booleanFilter,
  Button,
  collapseColumn,
  column,
  Css,
  dateColumn,
  emptyCell,
  FilterDefs,
  Filters,
  GridSortConfig,
  GridTable,
  GridTableApi,
  multiFilter,
  Palette,
  RightPaneLayout,
  RowStyles,
  ScrollableContent,
  selectColumn,
  simpleHeader,
  Tag,
  toggleFilter,
  useComputed,
  useGridTableApi,
  useGroupBy,
  useModal,
  usePersistedFilter,
  useRightPane,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { dateCell, FileUploadProgressAlert, SearchBox, UppyUploader } from "src/components";
import { Icon } from "src/components/Icon";
import { UploaderProvider } from "src/contexts/UploaderContext";
import {
  DocumentCategory,
  DocumentCategoryDetail,
  DocumentDetailDocumentTypeFragment,
  DocumentDetailFragment,
  DocumentsFilter,
  DocumentType,
  NamedDevelopmentCohortsProjectsFragment,
  ProjectDocumentsProjectDetailFragment,
  ProjectDocumentsQuery,
  SaveDocumentInput,
  useDevelopmentProjectsQuery,
  useDocumentsPageMetadataQuery,
  useProjectDocumentsQuery,
  useSaveDocumentsMutation,
  useToggleDocumentAsFavoritedMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { DocumentsActionsMenu } from "src/routes/projects/documents/DocumentsActionsMenu";
import { UpdateGDriveModal } from "src/routes/projects/documents/UpdateGDriveModal";
import { createDevelopmentDocumentUrl, createProjectDocumentUrl } from "src/RouteUrls";
import {
  formatNumberToString,
  groupBy,
  isDefined,
  isEmpty,
  nonEmpty,
  partition,
  pushInNextLoop,
  safeEntries,
  sortBy,
} from "src/utils";
import { getGoogleDriveLink } from "src/utils/googleDrive";
import { queryResult } from "src/utils/queryResult";
import { zIndexes } from "src/utils/styles/zIndexes";
import { StringParam, useQueryParams } from "use-query-params";
import { DocumentEditor } from "./DocumentEditor";

export function DocumentsPage() {
  const { projectId, developmentId } = useParams<{ projectId: string; developmentId: string }>();
  const metaDataQuery = useDocumentsPageMetadataQuery();
  // Query to get all projects related to a development, so DocumentsDataView will fetch documents for this group of projects
  // This query will only run if we are in /developments/{developmentId}/documents route
  const { data: developmentData } = useDevelopmentProjectsQuery({
    variables: { developmentId },
    skip: !isDefined(developmentId),
  });
  const development = developmentData?.development;
  return queryResult(metaDataQuery, {
    data: ({ documentTypes, documentCategories }) => {
      const parentIds = projectId
        ? [projectId]
        : development?.cohorts?.flatMap((c) => c.projects.map((p) => p.id)) ?? [];
      return (
        <DocumentsDataView
          developmentId={development?.id}
          parentIds={parentIds}
          documentTypes={sortBy(documentTypes, ({ name }) => name)}
          documentCategories={sortBy(documentCategories, ({ name }) => name)}
          development={development}
        />
      );
    },
  });
}

type DocumentsDataViewProps = {
  developmentId?: string;
  parentIds: string[];
  documentTypes: DocumentDetailDocumentTypeFragment[];
  documentCategories: DocumentCategoryDetail[];
  development?: NamedDevelopmentCohortsProjectsFragment;
};

function DocumentsDataView({
  developmentId,
  parentIds,
  documentTypes,
  documentCategories,
  development,
}: DocumentsDataViewProps) {
  const tid = useTestIds({}, "documentsPage");
  const [, nonSystemControlledDocumentTypes] = partition(documentTypes, ({ systemControlled }) => systemControlled);
  const deSelectedCategories = [
    DocumentCategory.Accounting,
    DocumentCategory.Contractual,
    DocumentCategory.Procurement,
  ] as DocumentCategory[];

  const filterDefs: FilterDefs<DocumentsFilter> = useMemo(
    () => {
      const category = multiFilter({
        label: "Category",
        options: sortBy(documentCategories, (category) => category.name),
        defaultValue: documentCategories
          .filter((category) => !deSelectedCategories.includes(category.code))
          .map((category) => category.code),
        getOptionValue: (o) => o.code,
        getOptionLabel: (o) => o.name,
      });

      const homeownerVisible = booleanFilter({
        label: "Visibility",
        options: [
          [undefined, "All"],
          [true, "Homeowner"],
          [false, "Internal Only"],
        ],
      });

      const includeDeleted = toggleFilter({ label: "Show Archived" });

      return { category, homeownerVisible, includeDeleted };
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentTypes],
  );

  const projectGroupByOptions = { category: "Category", none: "None" };
  const developmentGroupByOptions = { category: "Category", isDevelopment: "Project", none: "None" };
  const uncontrolledDocumentTypes = useMemo(() => documentTypes.filter((d) => !d.systemControlled), [documentTypes]);
  const parentId = developmentId ?? parentIds[0];
  const isDevelopmentPage = developmentId === parentId;
  const groupBy = useGroupBy(isDevelopmentPage ? developmentGroupByOptions : projectGroupByOptions);
  const { setFilter, filter } = usePersistedFilter<DocumentsFilter>({
    storageKey: `${parentId}_documentsFilter`,
    filterDefs,
  });
  const { openModal } = useModal();
  const { triggerNotice } = useSnackbar();

  const query = useProjectDocumentsQuery({
    variables: {
      projectIds: parentIds,
      filter: {
        ...filter,
        parentIds: [...(isDevelopmentPage ? [developmentId] : []), ...parentIds],
      },
      limit: DOCUMENT_LIMIT,
    },
    skip: isEmpty(parentIds),
    onCompleted: (data) => {
      if (data.documents.length === DOCUMENT_LIMIT) {
        triggerNotice({
          icon: "warning",
          message: `This list has been truncated to ${formatNumberToString(
            DOCUMENT_LIMIT,
            true,
          )} documents, please add more filters.`,
        });
      }
    },
  });

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [allowDocRedirect, setAllowDocRedirect] = useState(true);

  const [{ documentId }] = useQueryParams({ documentId: StringParam });
  const [saveDocuments] = useSaveDocumentsMutation();
  const history = useHistory();

  const { openRightPane, closeRightPane } = useRightPane();

  useEffect(
    () => {
      documentId
        ? openRightPane({
            content: (
              <DocumentEditor
                documentId={documentId}
                groupBy={groupBy.value}
                documentTypes={nonSystemControlledDocumentTypes}
              />
            ),
          })
        : closeRightPane();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentId],
  );

  // Once an asset has been uploaded, create a document with an asset
  async function handleOnFinish(file: UppyFile) {
    // This is to prevent multiple file uploads to redirect one after each other
    if (allowDocRedirect) {
      setAllowDocRedirect(false);
    }
    const saveDocumentsInput: [SaveDocumentInput] = [
      {
        documentType: DocumentType.General,
        name: file.name,
        sizeInBytes: file.size,
        parentId: parentId,
        asset: {
          contentType: file.type,
          fileName: file.name,
          s3Key: file.meta.s3Key as string,
          sizeInBytes: file.size,
        },
      },
    ];
    const { data } = await saveDocuments({ variables: { input: saveDocumentsInput } });
    await query.refetch();
    const savedDoc = data?.saveDocuments?.documents[0];
    if (savedDoc && allowDocRedirect) {
      const baseUrl = isDevelopmentPage
        ? createDevelopmentDocumentUrl(parentId, savedDoc.id)
        : createProjectDocumentUrl(parentId, savedDoc.id);
      pushInNextLoop(history, `${baseUrl}${groupBy ? `&groupBy=${groupBy.value}` : ""}`);
    }
  }

  const tableApi = useGridTableApi<Row>();

  const selectedDocuments: DocumentDetailFragment[] = useComputed(
    () => tableApi.getSelectedRows("document").map((row) => row.data),
    [tableApi],
  );

  return queryResult(query, {
    data: ({ documents, projects }: ProjectDocumentsQuery) => {
      const project = projects[0];
      return (
        <div {...tid}>
          <UploaderProvider>
            <PageHeader
              xss={Css.bgWhite.$}
              title="Documents"
              right={
                !isDevelopmentPage && (
                  <>
                    <Button
                      label="Edit GDrive Link"
                      variant="secondary"
                      icon="pencil"
                      onClick={() =>
                        openModal({
                          content: (
                            <UpdateGDriveModal
                              projectId={parentId}
                              googleDriveLink={getGoogleDriveLink(project.googleDriveFolderId || "")}
                              refetch={query.refetch}
                            />
                          ),
                        })
                      }
                    />
                    {project.googleDriveFolderId && (
                      <Button label="Google Drive" onClick={getGoogleDriveLink(project.googleDriveFolderId)} />
                    )}
                  </>
                )
              }
            />
            <TableActions>
              <Filters groupBy={groupBy} filter={filter} onChange={setFilter} filterDefs={filterDefs} />
              <div css={Css.df.aic.gap2.$}>
                <SearchBox onSearch={setSearchFilter} />
                <DocumentsActionsMenu
                  selectedDocuments={selectedDocuments}
                  uncontrolledDocumentTypes={uncontrolledDocumentTypes}
                  tableRefetch={query.refetch}
                  tableApi={tableApi}
                  development={development}
                />
                <UppyUploader onFinish={handleOnFinish} onComplete={() => setAllowDocRedirect(true)} />
              </div>
            </TableActions>
            <ScrollableContent omitBottomPadding>
              <RightPaneLayout>
                <>
                  <DocumentsTable
                    documents={documents}
                    projectId={parentId}
                    projects={projects}
                    isDevelopmentPage={isDevelopmentPage}
                    searchFilter={searchFilter}
                    selectedDocumentId={documentId}
                    tableApi={tableApi}
                    documentCategories={documentCategories}
                    groupBy={groupBy.value}
                  />
                  <div
                    css={Css.fixed.bottom2.w50.add("zIndex", zIndexes.modal).$}
                    data-testid="documentsPage_fileUploader"
                  >
                    <FileUploadProgressAlert />
                  </div>
                </>
              </RightPaneLayout>
            </ScrollableContent>
          </UploaderProvider>
        </div>
      );
    },
  });
}

type DocumentsTableProps = {
  documents: DocumentDetailFragment[];
  projectId: string;
  projects: ProjectDocumentsProjectDetailFragment[];
  isDevelopmentPage: boolean;
  searchFilter?: string;
  selectedDocumentId: string | undefined | null;
  tableApi: GridTableApi<Row>;
  documentCategories: DocumentCategoryDetail[];
  groupBy: string;
};

function DocumentsTable(props: DocumentsTableProps) {
  const {
    documents,
    projects,
    projectId,
    isDevelopmentPage,
    searchFilter = "",
    selectedDocumentId,
    tableApi,
    documentCategories,
    groupBy,
  } = props;

  const [toggleDocumentAsFavoritedMutation] = useToggleDocumentAsFavoritedMutation();

  // GroupBy is not store in the filters so we need set it again
  const rowStyles: RowStyles<Row> = useMemo(() => {
    return {
      header: {},
      document: {
        rowLink: ({ data }) =>
          `${
            isDevelopmentPage
              ? createDevelopmentDocumentUrl(projectId, data.id)
              : createProjectDocumentUrl(projectId, data.id)
          }${groupBy ? `&groupBy=${groupBy}` : ""}`,
      },
    };
  }, [isDevelopmentPage, projectId, groupBy]);

  const groupByCategory = groupBy === "category";
  const groupByIsDevelopment = groupBy === "isDevelopment";
  const rows = useMemo(
    () => (documents ? createRows(documents, groupByCategory, groupByIsDevelopment) : []),
    [documents, groupByCategory, groupByIsDevelopment],
  );
  const columns = useMemo(
    () => {
      const categoryColumn = column<Row>({
        header: "Category",
        document: (document) => documentCategories?.find((d) => d.code === document.category)?.name ?? " ",
        group: (data) => {
          const { category, childCount } = data;
          return {
            content: () =>
              category ? (
                <>
                  <div css={Css.mr1.$}>
                    {documentCategories?.find((d) => d.code === category)?.name ?? "No Category"}
                  </div>
                  {childCount && childCount > 0 && <Tag text={childCount.toString()} />}
                </>
              ) : (
                ""
              ),
            sortValue: data.category,
            colspan: 7,
          };
        },
      });
      const favoriteColumn = actionColumn<Row>({
        id: "favorite",
        header: emptyCell,
        document: (document) => ({
          content: (
            <Icon
              icon={document.isFavorited ? "starFilled" : "star"}
              color={document.isFavorited ? Palette.Yellow500 : Palette.Gray900}
              onClick={() => toggleDocumentAsFavoritedMutation({ variables: { input: { documentId: document.id } } })}
            />
          ),
          sortValue: document.isFavorited,
        }),
        w: "45px",
        group: emptyCell,
        clientSideSort: false,
      });
      const nameColumn = column<Row>({
        header: "Name",
        document: (document) => ({ content: document.displayName, value: document.displayName.toLowerCase() }),
        mw: "300px",
        group: emptyCell,
      });
      const typeColumn = column<Row>({
        header: "Type",
        document: ({ documentType: { name, code } }) =>
          code === DocumentType.General ? { content: () => <Icon icon="error" />, value: undefined } : name,
        group: emptyCell,
      });
      const dateIssuanceColumn = dateColumn<Row>({
        header: "Issuance Date",
        document: (document) => dateCell(document.issuanceDate),
        w: "95px",
        group: emptyCell,
      });
      const hoVisibleColumn = column<Row>({
        header: "H/O Visibile",
        document: ({ homeownerVisible }) => (homeownerVisible ? "Yes" : "No"),
        w: "80px",
        group: emptyCell,
      });
      const archiveColumn = column<Row>({
        header: "  ",
        document: ({ isDeleted }) => (isDeleted ? <Icon icon={"archive"} /> : " "),
        w: "45px",
        clientSideSort: false,
        group: emptyCell,
      });
      const projectColumn = column<Row>({
        header: "Project",
        document: ({ parent }) =>
          parent.__typename === "Project" ? projects.find(({ id }) => id === parent?.id)?.name : "--",
        mw: "160px",
        group: (data) => {
          const { project, childCount } = data;
          return {
            content: () =>
              project ? (
                <>
                  <div css={Css.mr1.$}>{project}</div>
                  {childCount && childCount > 0 && <Tag text={childCount.toString()} />}
                </>
              ) : (
                <></>
              ),
            sortValue: data.project,
            colspan: 7,
          };
        },
      });

      return [
        collapseColumn<Row>(),
        selectColumn<Row>(),
        favoriteColumn,
        ...(groupByCategory ? [categoryColumn] : []),
        ...(groupByIsDevelopment ? [projectColumn] : []),
        nameColumn,
        typeColumn,
        ...(!groupByCategory ? [categoryColumn] : []),
        dateIssuanceColumn,
        hoVisibleColumn,
        ...(isDevelopmentPage && !groupByIsDevelopment ? [projectColumn] : []),
        archiveColumn,
      ];
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentCategories, groupByCategory, groupByIsDevelopment, isDevelopmentPage],
  );

  // Find is setting the primary column to favoriteColumn
  const sortingConfig: GridSortConfig = {
    on: "client",
    primary: ["favorite", "DESC"],
  };

  return (
    <GridTable
      as="virtual"
      columns={columns}
      rows={rows}
      rowStyles={rowStyles}
      sorting={sortingConfig}
      stickyHeader
      filter={searchFilter}
      style={{ rowHeight: "fixed", grouped: groupByCategory || groupByIsDevelopment, bordered: true, allWhite: true }}
      activeRowId={selectedDocumentId ? `document_${selectedDocumentId}` : undefined}
      api={tableApi}
      fallbackMessage="No documents found"
    />
  );
}

function createRows(documents: DocumentDetailFragment[], groupByCategory: boolean, groupByIsDevelopment: boolean) {
  const createDocumentRow = (doc: DocumentDetailFragment) => ({
    kind: "document" as const,
    id: doc.id,
    data: doc,
  });
  const kind = "group" as const;
  if (groupByCategory) {
    const [withCategory, noCategory] = partition(documents, (d) => !!d.category);
    const groups = groupBy(withCategory, (doc) => doc.category!);
    const rows = safeEntries(groups).map(([groupName, docList]) => ({
      kind,
      id: groupName,
      data: { category: groupName, childCount: docList.length },
      children: docList.map(createDocumentRow),
    }));
    return [
      simpleHeader,
      ...rows,
      ...(nonEmpty(noCategory)
        ? [
            {
              kind,
              id: "No Category",
              data: { category: "No Category", childCount: noCategory.length },
              children: noCategory.map(createDocumentRow),
              pin: "last" as const,
            },
          ]
        : []),
    ];
  }
  if (groupByIsDevelopment) {
    const [developmentDocuments, projectDocuments] = partition(documents, (d) => d.parent.__typename === "Development");
    return [
      simpleHeader,
      ...(projectDocuments.length > 0
        ? [
            {
              kind,
              id: "Project Level",
              data: { project: "Project Level", childCount: projectDocuments.length },
              children: projectDocuments.map(createDocumentRow),
            },
          ]
        : []),
      ...(developmentDocuments.length > 0
        ? [
            {
              kind,
              id: "Development Level",
              data: { project: "Development Level", childCount: developmentDocuments.length },
              children: developmentDocuments.map(createDocumentRow),
              pin: "last" as const,
            },
          ]
        : []),
    ];
  }
  return [simpleHeader, ...documents.map(createDocumentRow)];
}

type HeaderRow = { kind: "header" };
type DocumentRow = { kind: "document"; data: DocumentDetailFragment };
type GroupedByRow = { kind: "group"; data: { category?: string; project?: string; childCount?: number } };
type Row = HeaderRow | DocumentRow | GroupedByRow;

export const DOCUMENT_LIMIT = 500;
