import {
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  ModalProps,
  StaticField,
  TabsWithContent,
  Tag,
  Tooltip,
  column,
  emptyCell,
  numericColumn,
  simpleHeader,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { sentenceCase } from "change-case";
import {
  CommentFeed,
  FormActions,
  FormMode,
  HistoryFeed,
  PdfViewer,
  Price,
  priceCell,
  priceTotal,
} from "src/components";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { InvoiceNumber } from "src/components/formFields/InvoiceNumber";
import {
  CostClassificationType,
  InvoiceEditorInvoiceFragment,
  InvoiceStatus,
  InvoiceType,
  LotType,
  ProjectStageDetailFragment,
  SaveInvoiceInput,
} from "src/generated/graphql-types";
import { invoiceStatusToTagType } from "src/routes/invoices/InvoicesPage";
import { approvalStatusToTagTypeMapper, formatCentsToPrice, isDefined, sum } from "src/utils";
import { isContractualStage } from "src/utils/projects";
import { useProjectContext } from "../../context/ProjectContext";
import { PageHeaderActions } from "src/routes/layout/PageHeader";
import { useFormState } from "@homebound/form-state";
import { calculatePercentageCompleteByAmount, calculateUninvoiced } from "../utils";
import { Link } from "react-router-dom";
import {
  createHomeownerContractChangeOrderDrawsUrl,
  createHomeownerContractDrawsUrl,
  createHomeownerContractInvoiceScheduleUrl,
  createProjectScheduleUrl,
} from "src/RouteUrls";
import { Fragment, useMemo, useState } from "react";
import { IComputedValue, computed } from "mobx";
import { DateOnly, formatWithShortYear, formatWithYear } from "src/utils/dates";
import {
  InvoiceFormState,
  formConfigInvoiceEditor,
  mapToFormInvoiceEditor,
  mapToInputInvoiceEditor,
} from "../InvoiceEditor";
import { useApprovalSuperDrawer } from "src/routes/components/Approval/ApprovalSuperDrawer";
import { BillsModal } from "../BillsModal";
import { Observer } from "mobx-react";
import { InvoiceBanner } from "./InvoiceBanner";

export type InvoiceEditorV2Props = {
  mode: FormMode;
  projectId: string;
  projectStages: ProjectStageDetailFragment[];
  invoice: InvoiceEditorInvoiceFragment;
  onCancel: () => void;
  onEdit: () => void;
  onSave: (formState: SaveInvoiceInput | undefined, redirect?: boolean) => Promise<void>;
  onDelete: () => Promise<void>;
};

export function InvoiceEditorV2(props: InvoiceEditorV2Props) {
  const { projectId, projectStages, invoice, mode, onSave, onEdit, onDelete, onCancel } = props;
  const testIds = useTestIds({}, "invoiceEditor");
  const { clientNoun } = useProjectContext();
  const openApproval = useApprovalSuperDrawer();
  const { openModal } = useModal();
  const readOnly = mode === "read";
  const [tab, setTab] = useState("details");
  const isApprovedOrAuto = invoice.status.code === InvoiceStatus.Approved || invoice.type.code === InvoiceType.Auto;
  const { primaryHomeowner, customerOwnerEntity } = invoice.projectStage.project;

  const formState = useFormState({
    config: formConfigInvoiceEditor,
    init: { input: invoice, map: (invoice) => mapToFormInvoiceEditor(invoice) },
    readOnly,
  });

  const totalsRowData: ComputedTotals = useMemo(
    () =>
      computed(() => ({
        totalAmountInCents: [
          ...formState.lineItems.rows.map((r) => r.amountInCents.value || 0),
          ...formState.drawLineItems.rows.map((r) => r.amountInCents.value || 0),
          ...formState.scheduledLineItems.rows.map((r) => r.amountInCents.value || 0),
        ].reduce(sum, 0),
        totalSoftCostInCents: [
          ...formState.lineItems.rows
            .filter((r) => r.costClassification.value === CostClassificationType.Soft)
            .map((r) => r.amountInCents.value || 0 - (r.marginInCents.value || 0)),
          ...formState.scheduledLineItems.rows
            .filter((r) => r.costClassification.value === CostClassificationType.Soft)
            .map((r) => r.amountInCents.value - r.marginInCents.value),
        ].reduce(sum, 0),
        totalHardCostInCents: [
          ...formState.lineItems.rows
            .filter((r) => r.costClassification.value === CostClassificationType.Hard)
            .map((r) => r.amountInCents.value || 0 - (r.marginInCents.value || 0)),
          ...formState.scheduledLineItems.rows
            .filter((li) => li.costClassification.value === CostClassificationType.Hard)
            .map((li) => li.amountInCents.value - li.marginInCents.value),
        ].reduce(sum, 0),
        totalMarkupInCents: formState.scheduledLineItems.value.map((li) => li.marginInCents).reduce(sum, 0),
      })),
    [formState],
  );

  return (
    <Observer>
      {() => (
        <>
          <PageHeaderActions>
            <>
              <FormActions
                {...{ mode, onEdit, onCancel, formState, onDelete }}
                entityType="Invoice"
                entityName={`Invoice ${invoice?.id}`}
                secondaryVariant="tertiary"
                deleteDisabledReason={isApprovedOrAuto ? "Only unapproved manual invoices could be deleted" : ""}
                onSave={() => onSave(mapToInputInvoiceEditor(formState), true)}
              />
              {invoice.pandaDocs.first?.externalPandaDocUrl && (
                <Button label="View in PandaDoc" onClick={invoice.pandaDocs.first.externalPandaDocUrl} />
              )}
            </>
          </PageHeaderActions>
          {mode === "read" && <InvoiceBanner invoiceId={invoice.id} hasSyncError={Boolean(invoice.intacctSyncError)} />}
          <div css={Css.df.gap2.$}>
            <div css={Css.w50.$}>
              <div css={Css.bgWhite.p3.br8.bshBasic.$}>
                {invoice.isHistorical ? (
                  <Tag type="info" text="HISTORICAL" />
                ) : (
                  <Tag type={invoiceStatusToTagType[invoice.status.code]} text={invoice.status.name} />
                )}
                <div css={Css.df.pt2.lgBd.$}>
                  <div>{invoice.name}</div>
                  <div css={Css.mla.$}>{formatCentsToPrice(invoice.amountInCents)}</div>
                </div>
                <div css={Css.mb1.df.if(mode !== "read").my2.$}>
                  <div css={Css.maxw75.oh.$}>
                    <BoundTextField
                      field={formState.title}
                      labelStyle={mode === "read" ? "hidden" : "inline"}
                      compact
                    />
                  </div>
                  <div css={Css.smSb.ta("right").mla.mya.$}>
                    Paid amount: {formatCentsToPrice(invoice.amountPaidInCents)}
                  </div>
                </div>

                <TabsWithContent
                  tabs={[
                    {
                      name: "Details",
                      value: "details",
                      render: () => (
                        <div>
                          {invoice.intacctSyncError && (
                            <StaticField label="Sync Error">
                              <div css={Css.red700.$}> {invoice.intacctSyncError} </div>
                            </StaticField>
                          )}
                          <div css={Css.df.w100.py2.$}>
                            {isDefined(formState.projectStageId.value) && (
                              <div css={Css.w25.$}>
                                <InvoiceNumber
                                  formState={formState}
                                  isHistoricalInvoiceNumber={invoice?.isHistoricalInvoiceNumber}
                                  mode={mode}
                                  stage={projectStages.find((s) => s.id === formState.projectStageId.value)!.stage.code}
                                />
                              </div>
                            )}
                            <div css={Css.w25.if(mode !== "read").pr2.$}>
                              <BoundBeamDateField
                                field={formState.invoiceDate}
                                helperText="Enter the date of this invoice"
                              />
                            </div>
                            <div css={Css.w25.if(mode !== "read").pr2.$}>
                              <BoundBeamDateField
                                field={formState.dueDate}
                                helperText={`Enter the date the ${clientNoun.toLowerCase()} needs to pay this invoice by`}
                              />
                            </div>
                            {invoice.paidDate && <StaticField label="Paid" value={formatWithYear(invoice.paidDate)} />}
                          </div>
                          <div css={Css.df.jcsb.$}>
                            <div css={Css.mw25.$}>
                              <StaticField value={invoice.intacctId ?? ""} label="Intacct ID" />
                            </div>
                            <div css={Css.mw25.$}>
                              <StaticField value={invoice.type.name} label="Type" />
                            </div>
                            <Tooltip
                              title="Cannot change if there are existing Line Items"
                              disabled={!(invoice?.id || formState.lineItems.rows.length === 0)}
                            >
                              <BoundSelectField
                                label="Related Contract"
                                field={formState.projectStageId}
                                options={projectStages.filter((s) => isContractualStage(s.stage.code))}
                                getOptionLabel={({ stage }) => stage.name}
                                getOptionValue={({ id }) => id}
                                readOnly={Boolean(
                                  invoice ||
                                    formState.lineItems.rows.length > 0 ||
                                    formState.drawLineItems.rows.length > 0 ||
                                    mode === "read",
                                )}
                                disabledOptions={projectStages.filter((ps) => !ps.hasSignedContract).map((ps) => ps.id)}
                                helperText={mode === "read" ? undefined : "Only signed contracts can be selected"}
                              />
                            </Tooltip>
                            <BoundTextAreaField
                              field={formState.description}
                              label={`Memo${mode !== "read" ? " (optional)" : ""}`}
                              helperText={`This will be visible to the ${clientNoun.toLowerCase()}`}
                            />
                          </div>
                          <div {...testIds.billTo} css={Css.mb3.$}>
                            <div css={Css.gray700.mbPx(2).$}>Bill To</div>
                            <div css={Css.smSb.$}>
                              {customerOwnerEntity?.name ??
                                (primaryHomeowner && (
                                  <div>
                                    <div>{primaryHomeowner.fullName}</div>
                                    {primaryHomeowner.address && (
                                      <>
                                        <div>{primaryHomeowner.address.street1}</div>
                                        <div>
                                          {`${primaryHomeowner.address.city}, ${primaryHomeowner.address.state} ${primaryHomeowner.address.postalCode}`}
                                        </div>
                                      </>
                                    )}
                                    <Link to={`mailto:${primaryHomeowner.email}`}>{primaryHomeowner.email}</Link>
                                  </div>
                                )) ??
                                "Client"}
                            </div>
                          </div>
                          {invoice.parent && (
                            <div {...testIds.parent} css={Css.mb3.$}>
                              {"schedule" in invoice.parent ? (
                                <>
                                  <div css={Css.sm.gray700.mb1.$}>Related Schedule Task</div>
                                  <div css={Css.br4.bgGray100.p2.$}>
                                    <div css={Css.df.jcsb.$}>
                                      <div css={Css.truncate.maxw50.mya.fw5.$}>
                                        <Link
                                          data-testid="scheduleLink"
                                          to={createProjectScheduleUrl(
                                            projectId,
                                            invoice.parent.schedule.stageDetail.code,
                                            invoice.parent.id,
                                          )}
                                          target="_blank"
                                        >
                                          {invoice.parent.name}
                                        </Link>
                                      </div>
                                      <div css={Css.xs.mya.$}>
                                        <div>{sentenceCase(invoice.parent.statusDetail.code)}</div>
                                        {invoice.parent.completedAt && (
                                          <div>{formatWithShortYear(invoice.parent.completedAt)}</div>
                                        )}
                                      </div>
                                      <div css={Css.xs.mwPx(120).mya.$}>{invoice.parent.assignee?.name}</div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                invoice.parent.__typename === "PlanMilestone" && (
                                  <>
                                    <div css={Css.sm.gray700.mb1.$}>Related Plan Milestone</div>
                                    <div css={Css.br4.bgGray100.p2.$}>
                                      <div css={Css.df.jcsb.$}>
                                        <div css={Css.truncate.maxw50.mya.fw5.$}>{invoice.parent.name}</div>
                                        <div css={Css.xs.mya.$}>
                                          <div>{sentenceCase(invoice.parent.taskStatusRollup.code)}</div>
                                          {"completedDate" in invoice.parent && invoice.parent.completedDate && (
                                            <div>{formatWithShortYear(new DateOnly(invoice.parent.completedDate))}</div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          )}
                          <div {...testIds.approval}>
                            <div css={Css.sm.gray700.mb1.$}>Approval</div>
                            <div css={Css.br4.bgGray100.p2.$}>
                              {invoice.approval && (
                                <div css={Css.df.jcsb.$}>
                                  <Button
                                    variant="text"
                                    label={invoice.approval.name}
                                    onClick={() => openApproval(invoice.approval!.id)}
                                  />
                                  <Tag
                                    type={approvalStatusToTagTypeMapper(invoice.approval)[0]}
                                    text={approvalStatusToTagTypeMapper(invoice.approval)[1]}
                                  />
                                  <div css={Css.xs.mwPx(120).$}>{invoice.approval.requestedBy?.name}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      name: "Comments",
                      value: "comments",
                      render: () => <CommentFeed commentable={invoice} />,
                    },
                    {
                      name: "History",
                      value: "history",
                      render: () => <HistoryFeed historyItems={invoice.history} />,
                    },
                  ]}
                  selected={tab}
                  onChange={setTab}
                />
              </div>
              <div css={Css.mt2.p2.bgWhite.bshBasic.br8.$}>
                <GridTable
                  stickyHeader
                  style={{ allWhite: true }}
                  columns={createLineItemColumns(
                    projectId,
                    invoice.projectStage.project.lotType?.code,
                    mode,
                    testIds.contractItem,
                    clientNoun,
                    openModal,
                  )}
                  rows={createLineItemRows(formState, totalsRowData, mode)}
                />
              </div>
            </div>
            {invoice.document && (
              <div css={Css.bgWhite.w50.bshBasic.oa.br8.$}>
                <PdfViewer hasHeader assets={[invoice.document.asset]} />
              </div>
            )}
          </div>
        </>
      )}
    </Observer>
  );
}

type HeaderRow = { kind: "header" };
type IndividualItemRow = { kind: "contractLineItem"; data: InvoiceFormState["lineItems"]["rows"][0] };
type DrawItemRow = { kind: "drawLineItem"; data: InvoiceFormState["drawLineItems"]["rows"][0] };
type ScheduledItemRow = { kind: "scheduledLineItem"; data: InvoiceFormState["scheduledLineItems"]["rows"][0] };
type TotalRow = { kind: "total"; data: TotalData };
type Row = HeaderRow | IndividualItemRow | DrawItemRow | ScheduledItemRow | TotalRow;
type TotalData = {
  totalAmountInCents: number;
  totalSoftCostInCents: number;
  totalHardCostInCents: number;
  totalMarkupInCents: number;
};
type ComputedTotals = IComputedValue<TotalData>;

function createLineItemRows(
  formState: InvoiceFormState,
  totalsRowData: ComputedTotals,
  mode: FormMode,
): GridDataRow<Row>[] {
  return [
    simpleHeader,
    // Add the contract line items
    ...formState.lineItems.rows
      .filter((r) => r.delete.value !== true)
      // In "read" mode omit ChangeOrder rows that are not part of this invoice
      .filter((r) => (mode !== "read" || !r.isChangeOrder.value ? true : r.id.value))
      .map((data, i) => ({ kind: "contractLineItem" as const, id: `contractLineItem-${i}`, data })),
    // Add the draw line items
    ...formState.drawLineItems.rows
      .filter((r) => r.delete.value !== true)
      .map((data, i) => ({ kind: "drawLineItem" as const, id: `drawLineItem-${i}`, data })),
    ...formState.scheduledLineItems.rows.map((data, i) => ({
      kind: "scheduledLineItem" as const,
      id: `scheduledLineItem-${i}`,
      data,
    })),
    { kind: "total" as const, id: "total", data: totalsRowData.get() },
  ];
}

function createLineItemColumns(
  projectId: string,
  lotType: LotType | undefined,
  mode: FormMode,
  contractItemId: object,
  clientNoun: string,
  openModal: (props: ModalProps) => void,
): GridColumn<Row>[] {
  const projectItemOrChangeOrderColumn = column<Row>({
    header: () => "Project Item",
    contractLineItem: (row) => (
      <span {...contractItemId} css={Css.if(!!row.isChangeOrder.value).pl2.$}>
        {row.name.value}
      </span>
    ),
    drawLineItem: (row) => ({
      content: (
        <Link
          data-testid="drawLineItemLink"
          target="_blank"
          to={
            row.contractId.value.startsWith("hcco:")
              ? createHomeownerContractChangeOrderDrawsUrl(projectId, row.contractId.value)
              : createHomeownerContractDrawsUrl(projectId, row.contractId.value)
          }
        >
          {row.description.value}
        </Link>
      ),
      colspan: 2,
    }),
    scheduledLineItem: (row) =>
      !!row.contractId.value ? (
        <Link
          data-testid="scheduledLineItemLink"
          target="_blank"
          to={createHomeownerContractInvoiceScheduleUrl(projectId, row.contractId.value)}
        >
          {row.name.value}
        </Link>
      ) : (
        row.name.value
      ),
    total: () => "Totals",
    w: 3,
  });

  const billsLinkColumn = column<Row>({
    header: () => emptyCell,
    contractLineItem: (row) => {
      if (row.isChangeOrder.value || row.billIds.value.length <= 0) {
        return emptyCell;
      }
      return (
        <Button
          variant="tertiary"
          data-testid="viewBills"
          label="Bills"
          onClick={() => {
            openModal({ content: <BillsModal projectItemId={row.projectItemId.value} />, size: "xl" });
          }}
        />
      );
    },
    drawLineItem: emptyCell,
    scheduledLineItem: () => emptyCell,
    total: emptyCell,
  });

  const softCostColumn = column<Row>({
    header: () => "Soft Costs",
    contractLineItem: (row) =>
      row.costClassification.value === CostClassificationType.Soft && row.amountInCents.value
        ? priceCell({ valueInCents: row.amountInCents.value - (row.marginInCents.value || 0) })
        : emptyCell,
    drawLineItem: emptyCell,
    scheduledLineItem: (row) =>
      row.costClassification.value === CostClassificationType.Soft
        ? priceCell({ valueInCents: row.amountInCents.value - row.marginInCents.value })
        : emptyCell,
    total: (row) => priceTotal({ id: "softCostTotal", valueInCents: row.totalSoftCostInCents }),
  });

  const hardCostColumn = column<Row>({
    header: () => "Hard Costs",
    contractLineItem: (row) =>
      row.costClassification.value === CostClassificationType.Hard && row.amountInCents.value
        ? priceCell({ valueInCents: row.amountInCents.value - (row.marginInCents.value || 0) })
        : emptyCell,
    drawLineItem: emptyCell,
    scheduledLineItem: (row) =>
      row.costClassification.value === CostClassificationType.Hard
        ? priceCell({ valueInCents: row.amountInCents.value - row.marginInCents.value })
        : emptyCell,
    total: (row) => priceTotal({ id: "hardCostTotal", valueInCents: row.totalHardCostInCents }),
  });

  const markupColumn = column<Row>({
    header: () => "Markup",
    contractLineItem: emptyCell,
    drawLineItem: emptyCell,
    scheduledLineItem: (row) => priceCell({ valueInCents: row.marginInCents.value }),
    total: (row) => priceTotal({ id: "markupTotal", valueInCents: row.totalMarkupInCents }),
  });

  const homeownerPriceColumn = numericColumn<Row>({
    header: () => `${clientNoun} Price`,
    contractLineItem: (row) => {
      if (!row.homeownerContractLineItemId.value) {
        return <Fragment />;
      }
      return <Price id="homeownerPrice" valueInCents={row.priceChangeInCents.value} />;
    },
    drawLineItem: emptyCell,
    scheduledLineItem: () => emptyCell,
    total: emptyCell,
  });

  const otherInvoicesColumn = numericColumn<Row>({
    header: () => "Other Invoices",
    contractLineItem: (row) =>
      !row.homeownerContractLineItemId.value ? (
        <Fragment />
      ) : (
        <Price id="otherInvoices" valueInCents={row.otherInvoices.value?.price} />
      ),
    drawLineItem: emptyCell,
    scheduledLineItem: () => emptyCell,
    total: emptyCell,
  });

  const uninvoicedColumn = numericColumn<Row>({
    header: () => "Not Invoiced",
    contractLineItem: (row) =>
      !row.homeownerContractLineItemId.value ? (
        <Fragment />
      ) : (
        <Price id="uninvoiced" valueInCents={row.uninvoiced.value?.price} />
      ),
    drawLineItem: emptyCell,
    scheduledLineItem: () => emptyCell,
    total: emptyCell,
  });

  const amountColumn = numericColumn<Row>({
    header: () => "This Invoice",
    contractLineItem: (row) => {
      if (!row.homeownerContractLineItemId.value) {
        return <Fragment />;
      }
      if (mode === "read") {
        return <Price id="thisInvoice" valueInCents={row.value.amountInCents ?? undefined} />;
      }
      return (
        <BoundNumberField
          data-testid="invoiceAmount"
          type="cents"
          field={row.amountInCents}
          onChange={(amountInCents) => {
            amountInCents = amountInCents || 0;
            const percentComplete = calculatePercentageCompleteByAmount(row, amountInCents);
            row.set({
              amountInCents,
              percentComplete,
              uninvoiced: calculateUninvoiced(row, amountInCents, percentComplete),
            });
          }}
        />
      );
    },
    drawLineItem: (row) => priceCell({ id: "thisInvoice", valueInCents: row.amountInCents.value }),
    scheduledLineItem: (row) => priceCell({ id: "thisInvoice", valueInCents: row.amountInCents.value }),
    total: (row) => priceTotal({ id: "invoicedTotal", valueInCents: row.totalAmountInCents }),
  });

  return [
    projectItemOrChangeOrderColumn,
    billsLinkColumn,
    ...(lotType === LotType.Bool
      ? [softCostColumn, hardCostColumn, markupColumn]
      : [homeownerPriceColumn, otherInvoicesColumn, uninvoicedColumn]),
    amountColumn,
  ];
}
