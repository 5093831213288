import { BoundSelectField, BoundSelectFieldProps } from "@homebound/beam";
import { LocationDataFragment, LocationsFilter, useLocationsQuery } from "src/generated/graphql-types";

// type LocationsBoundSelectFieldProps = {
//   field: FieldState<any, string | null | undefined>;
//   disabledOptions?: string[];
//   label?: string;
//   placeholder?: string;
// }

type LocationsBoundSelectFieldProps = Omit<
  BoundSelectFieldProps<LocationDataFragment, string>,
  "options" | "multiselect" | "getOptionLabel" | "getOptionValue"
> & {
  getOptionLabel?: (o: LocationDataFragment) => string;
  getOptionValue?: (o: LocationDataFragment) => string;
  filter?: LocationsFilter;
};

export function LocationsBoundSelectField(props: LocationsBoundSelectFieldProps) {
  const {
    placeholder = "Select a Location",
    getOptionLabel = (o) => o.name,
    getOptionValue = (o) => o.id,
    filter,
    ...otherProps
  } = props;
  const { data, loading } = useLocationsQuery({
    fetchPolicy: "cache-and-network",
    ...(filter && { variables: { filter } }),
  });

  return (
    <BoundSelectField
      {...otherProps}
      placeholder={loading ? "Loading..." : placeholder}
      options={data?.locations ?? []}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  );
}
