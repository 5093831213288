import {
  PlanTask,
  PlanTaskSimulation_SimulationProbabilityFragment,
  DraftTaskConstraintAndAllowance_GlobalPlanTaskFragment,
  PotentialDateBasisPoints,
  Named,
  Maybe,
} from "src/generated/graphql-types";
import { useMemo } from "react";
import { Css, Icon, Palette, Tooltip, useTestIds } from "@homebound/beam";
import { formatDate } from "src/components";
import { basisPointsToPercentage } from "src/utils/basisPoints";

type DebugTooltipProps = {
  debugMode?: boolean;
  task: Pick<PlanTask, "slackInDays" | "isCriticalPath" | "isManuallyScheduled" | "earliestStartDate"> & {
    simulationProbability: PlanTaskSimulation_SimulationProbabilityFragment;
    globalPlanTask: DraftTaskConstraintAndAllowance_GlobalPlanTaskFragment;
  };
};

export function DebugTooltip({ debugMode, task }: DebugTooltipProps) {
  const tids = useTestIds({}, "debugToolTip");

  if (!debugMode) return null;

  return (
    <div {...tids}>
      <Tooltip title={<TooltipContent task={task} />}>
        <Icon icon="infoCircle" color={Palette.Gray600} />
      </Tooltip>
    </div>
  );
}

function TooltipContent({ task }: DebugTooltipProps) {
  const {
    simulationProbability: { potentialStartDateBasisPoints, potentialEndDateBasisPoints },
    globalPlanTask,
    slackInDays,
    isCriticalPath,
    isManuallyScheduled,
    earliestStartDate,
  } = task;

  const { constrainedBy, allowedBy } = useMemo(() => {
    const { constraints, allowances } = globalPlanTask;
    return {
      constrainedBy: constraints.flatMap((constraint) => constraint.constraintItem),
      allowedBy: allowances.flatMap((allowance) => allowance.constraintItem),
    };
  }, [globalPlanTask]);

  return (
    <div css={Css.df.fdc.gap1.p1.$}>
      <div>Fixed Start Date: {isManuallyScheduled && earliestStartDate ? formatDate(earliestStartDate) : "null"}</div>
      <div>Critical Path: {isCriticalPath.toString()}</div>
      <div>Slack in Days: {slackInDays}</div>
      <DateProbabilityDisplay dateProbability={potentialStartDateBasisPoints} label="Start Date Probability" />
      <DateProbabilityDisplay dateProbability={potentialEndDateBasisPoints} label="End Date Probability" />
      <ConstraintItemDisplay label="Constraints" constraintItems={constrainedBy} />
      <ConstraintItemDisplay label="Allowances" constraintItems={allowedBy} />
    </div>
  );
}

function DateProbabilityDisplay({
  dateProbability,
  label,
}: {
  dateProbability: Maybe<PotentialDateBasisPoints[]>;
  label: string;
}) {
  if (!dateProbability) return null;

  return (
    <div>
      {label}:
      <ul>
        {dateProbability.map((dateProbability) => {
          const { date, basisPoints } = dateProbability;

          return (
            <li key={`${label}-${date.toDateString()}`}>
              {formatDate(date)}: {basisPointsToPercentage(basisPoints)}%
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function ConstraintItemDisplay({ constraintItems, label }: { constraintItems: Named[]; label: string }) {
  return (
    <div>
      {label}:
      <ul>
        {constraintItems.map(({ name }) => {
          return <li key={`${label}-${name}`}>{name}</li>;
        })}
      </ul>
    </div>
  );
}
