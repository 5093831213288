import { BoundNumberField, BoundSelectField, Css, Icon, Palette, Tooltip, useComputed } from "@homebound/beam";
import { FieldState, ObjectConfig, required, useFormState } from "@homebound/form-state";
import padStart from "lodash/padStart";
import { Observer } from "mobx-react";
import { useEffect } from "react";
import { InputMaybe, ReadyPlanType } from "src/generated/graphql-types";
import { safeEntries, USStates } from "src/utils";

type CreateReadyPlanCodeProps = {
  /** A useFormState() field such as `formState.code` */
  codeField: FieldState<string | null | undefined>;
  typeField: FieldState<ReadyPlanType | null | undefined>;
  existingCode?: boolean | undefined;
};

export function CreateReadyPlanCode({ codeField, existingCode, typeField }: CreateReadyPlanCodeProps) {
  const numIntegers = 3;
  const formState = useFormState({ config });
  const errorMessage = "This plan code already exists";

  const code = useComputed(() => {
    const planValue = formState.plan.value ? padStart(formState.plan.value.toString(), numIntegers, "0") : "";
    return `${formState.state.value}-${formState.width.value}-${planValue}`;
  }, [formState]);

  useEffect(() => {
    const pattern = /^([A-Z]){2}-(\d){1,}-(\d){3}$/;
    return code && pattern.test(code) ? codeField.set(code) : codeField.set(undefined);
  }, [code, codeField]);

  return (
    <Observer>
      {() => (
        <>
          <div data-testid="planCode" css={Css.df.gap1.aic.mbPx(4).$}>
            <div css={Css.gray700.$}>Plan Code</div>
            <Tooltip placement="right" title="Code used to reference the plan throughout Blueprint">
              <Icon icon="infoCircle" color={Palette.Gray900} inc={2} />
            </Tooltip>
          </div>
          <div css={Css.dg.gtc("93px 23px 96px 23px 80px 23px 80px").$}>
            <BoundSelectField
              field={formState.type}
              options={safeEntries(readyPlanTypeToValueMapper)}
              getOptionLabel={([name]) => name}
              getOptionValue={([_, code]) => code}
              placeholder="Type..."
              compact
              labelStyle="hidden"
              hideErrorMessage
              errorMsg={
                (formState.type.touched ? formState.type.errors.join(" ") : undefined) ||
                (existingCode ? errorMessage : undefined)
              }
              onSelect={(val) => {
                formState.set({ type: val });
                typeField.set(valueToReadyPlanTypeMapper[val!]);
              }}
            />
            <span css={Css.lh("32px").ta("center").$}>-</span>
            <BoundSelectField
              field={formState.state}
              options={USStates}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.name}
              placeholder="State..."
              compact
              labelStyle="hidden"
              hideErrorMessage
              errorMsg={
                (formState.state.touched ? formState.state.errors.join(" ") : undefined) ||
                (existingCode ? errorMessage : undefined)
              }
            />
            <span css={Css.lh("32px").ta("center").$}>-</span>
            <BoundNumberField
              field={formState.width}
              compact
              placeholder="Width..."
              labelStyle="hidden"
              useGrouping={false}
              hideErrorMessage
              errorMsg={
                (formState.width.touched ? formState.width.errors.join(" ") : undefined) ||
                (existingCode ? errorMessage : undefined)
              }
            />
            <span css={Css.lh("32px").ta("center").$}>-</span>
            <BoundNumberField
              field={formState.plan}
              compact
              placeholder="Plan #..."
              labelStyle="hidden"
              numIntegerDigits={numIntegers}
              useGrouping={false}
              hideErrorMessage
              errorMsg={
                (formState.plan.touched ? formState.plan.errors.join(" ") : undefined) ||
                (existingCode ? errorMessage : undefined)
              }
            />
          </div>
          {existingCode ? <CodeErrorMessage /> : undefined}
        </>
      )}
    </Observer>
  );
}

type FormValue = {
  type: InputMaybe<string>;
  state: InputMaybe<string>;
  width: InputMaybe<number>;
  plan: InputMaybe<number>;
};

const config: ObjectConfig<FormValue> = {
  type: { type: "value", rules: [required] },
  state: { type: "value", rules: [required] },
  width: { type: "value", rules: [required] },
  plan: { type: "value", rules: [required] },
};

function CodeErrorMessage() {
  return (
    <div data-testid={`codeErrorMsg`} css={Css.red600.df.aic.mtPx(4).$}>
      <Icon icon="error" inc={3} color={Palette.Red600} />
      <span css={Css.ml1.$}>This plan code already exists</span>
    </div>
  );
}

const readyPlanTypeToValueMapper: Record<ReadyPlanType, string> = {
  [ReadyPlanType.Adu]: "ADU",
  [ReadyPlanType.Mfu]: "MFU",
  [ReadyPlanType.Sfh]: "SFH",
};

const valueToReadyPlanTypeMapper: Record<string, ReadyPlanType> = {
  ADU: ReadyPlanType.Adu,
  MFU: ReadyPlanType.Mfu,
  SFH: ReadyPlanType.Sfh,
};
