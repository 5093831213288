import { SelectField } from "@homebound/beam";
import { isItivOrderField, takeoffItemTableSortByOptions } from "src/routes/libraries/plan-package/takeoffs/utils";
import { useTakeoffsStore } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";

export function SortBySelectField() {
  const itemTableSortBy = useTakeoffsStore((state) => state.itemTableSortBy);
  const setItemTableSortBy = useTakeoffsStore((state) => state.setItemTableSortBy);

  return (
    <SelectField
      compact
      label="Sort By"
      labelStyle="inline"
      getOptionLabel={(o) => o.name}
      getOptionValue={(o) => o.id}
      onSelect={(v) => v && isItivOrderField(v) && setItemTableSortBy(v)}
      options={takeoffItemTableSortByOptions()}
      value={itemTableSortBy}
      sizeToContent
    />
  );
}
