import { Css, FilterDefs, Filters, multiFilter, useComputed, useFilter, useSnackbar } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useEffect, useMemo, useState } from "react";
import { SearchBox, useNavigationCheck } from "src/components";
import {
  AddOptionsGlobalOptionTypeFragment,
  useAddOptionsMetadataQuery,
  useAddOptionsPageQuery,
  useSaveReadyPlanOptionProgramDataModificationMutation,
  usePlanPackage_SaveReadyPlanOptionsMutation,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { OptionDetailsTable } from "src/routes/developments/plan-and-options/components/OptionDetailsTable";
import { addRpoConfig, toSaveReadyPlanOptionInput, toSaveReadyPlanOptionProgramDataModification } from "./utils";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import { createPlanPackageUrl } from "src/RouteUrls";
import { useHistory } from "react-router";
import { PlanPackageEditorHeader } from "src/routes/libraries/plan-package/stepper/components/PlanPackageEditorHeader";
import { StepLayout } from "src/components/stepper/StepLayout";

type OptionDetailsStepProps = {
  id: string;
  setStepDirty: (dirty: boolean) => void;
};

export function OptionDetailsStep({ id, setStepDirty }: OptionDetailsStepProps) {
  // const tid = useTestIds({}, "optionDetailsStep");
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const { data: metadata } = useAddOptionsMetadataQuery();
  const query = useAddOptionsPageQuery({ variables: { id } });
  const [saveRpos] = usePlanPackage_SaveReadyPlanOptionsMutation();
  const [saveReadyPlanOptionProgramDataModification] = useSaveReadyPlanOptionProgramDataModificationMutation();
  const globalOptionTypes = useMemo(() => query.data?.globalOptionTypes ?? [], [query.data?.globalOptionTypes]);

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const filterDefs: FilterDefs<OptionTypeFilter> = useMemo(() => {
    const option = multiFilter({
      label: "Filter by",
      options: globalOptionTypes,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    return { option };
  }, [globalOptionTypes]);
  const { filter, setFilter } = useFilter({ filterDefs });

  const optionsTypes: AddOptionsGlobalOptionTypeFragment[] = useMemo(() => {
    const options = globalOptionTypes.map(({ id }) => id);
    return globalOptionTypes.filter(
      (got) =>
        !got.isSpecLevel && (!filter?.option?.length ? options.includes(got.id) : filter.option.includes(got.id)),
    );
  }, [globalOptionTypes, filter.option]);

  const formState = useFormState({
    config: addRpoConfig,
    init: {
      query: query,
      map: ({ readyPlan }) => ({
        readyPlanOptions: readyPlan.options.map((o) => ({
          fragment: o,
          id: o.id,
          name: o.name,
          active: o.active,
          globalOptionId: o.globalOption.id,
          readyPlanId: readyPlan.id,
          locationId: o.location?.id,
          optionTypeId: o.type.id,
          programData: {
            ...o.programData,
            basementConfig: o.programData?.basementConfig?.code,
            primaryBedroom: o.programData?.primaryBedroom?.code,
            garageConfiguration: o.programData?.garageConfiguration?.code,
          },
          programDataModifiedBy: o.programDataModifiedBy.map((pdm) => ({
            ...pdm,
            globalOptionCode: pdm.matchesOption.globalOption.code,
            programData: {
              ...pdm?.programData,
              basementConfig: pdm?.programData?.basementConfig?.code,
              garageConfiguration: pdm?.programData?.garageConfiguration?.code,
              primaryBedroom: pdm?.programData?.primaryBedroom?.code,
            },
          })),
          isElevation: o.type.isElevation,
          globalTypeName: o.type.name,
          globalOptionCode: o.globalOption.code,
          globalOptionDescription: o.description,
          globalOptionLocation: o.location,
          childOptions: o.childOptions.map((c) => ({
            id: c.id,
            active: c.active,
            globalOptionId: c.globalOption.id,
            globalOptionName: `${c.globalOption.code} - ${c.globalOption.name}`,
          })),
        })),
      }),
    },
  });

  const isDirty = useComputed(() => formState.dirty, [formState.dirty]);
  useEffect(() => {
    setStepDirty(isDirty);
  }, [setStepDirty, isDirty]);
  const { useRegisterNavigationCheck } = useNavigationCheck();
  useRegisterNavigationCheck(() => !formState.dirty, [formState]);

  const onSave = async () => {
    // Save Plan Details
    const toSaveReadyPlanDetails = formState.changedValue;
    // Check if any change other than the id exists to execute the save
    const realChanges = Object.keys(toSaveReadyPlanDetails).find((k) => k !== "id");
    if (realChanges && query.data?.readyPlan) {
      await Promise.all([
        toSaveReadyPlanOptionInput(formState, query.data.readyPlan, saveRpos),
        toSaveReadyPlanOptionProgramDataModification(formState, saveReadyPlanOptionProgramDataModification),
      ]);
      formState.commitChanges();
    }

    triggerNotice({
      message: `Your Ready Plan Options have been updated!`,
    });

    history.push(createPlanPackageUrl(id));
  };

  return (
    <StepLayout
      header={
        <PlanPackageEditorHeader
          title="Option Details"
          subtitle="Enter the program data for the Plan Options."
          tooltip="Decide if any of the options that you selected in the previous step will add or subtract square footage or bedrooms, bathrooms, garages, or carport."
        />
      }
      body={
        <div css={Css.pt3.df.fdc.fg1.$}>
          <TableActions onlyRight>
            <div css={Css.df.gap1.jcfe.$}>
              <Filters<OptionTypeFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
              <SearchBox onSearch={setSearchFilter} clearable updateQueryString={false} />
            </div>
          </TableActions>

          <div css={Css.fg1.pb1.$}>
            <OptionDetailsTable
              formState={formState}
              searchFilter={searchFilter}
              optionsTypes={optionsTypes}
              metadata={metadata}
              filter={{}}
              showActions={false}
            />
          </div>
          <NextStepButton
            label="Finish"
            disabled={false}
            onClick={onSave}
            onCloseReturnUrl={createPlanPackageUrl(id)}
            exitButton={{
              variant: "secondary",
              label: "Save & Exit",
              onClick: onSave,
            }}
          />
        </div>
      }
    />
  );
}

type OptionTypeFilter = { option: string[] };
