import { Css } from "@homebound/beam";
import { camelCase } from "change-case";
import { Fragment } from "react";
import { useTradeScopeOfWorkQuery } from "src/generated/graphql-types";
import { HasChildren, partition } from "src/utils";
import { queryResult } from "src/utils/queryResult";
import { StringParam, useQueryParam } from "use-query-params";

const productForNoSelection = {
  name: "No selection",
  manufacturerUrl: "N/A",
  description: "N/A",
};

export function TradeScopeOfWorkPdf() {
  const [changeEventLineItemIds] = useQueryParam("changeEventLineItemIds", StringParam);
  const id = changeEventLineItemIds?.split(",") ?? [];
  const query = useTradeScopeOfWorkQuery({ variables: { filter: { id } } });
  return queryResult(query, {
    data: ({ changeEventLineItems }) => {
      const [specifications, selections] = partition(changeEventLineItems ?? [], (celi) => !celi.homeownerSelection);
      return (
        <Fragment>
          {(
            [
              [specifications, "Specifications"],
              [selections, "Product Selections"],
            ] as const
          ).map(([lineItems, title]) =>
            lineItems.length > 0 ? (
              <div key={title} css={Css.mb5.$} data-testid={`${camelCase(title)}LineItems`}>
                <h1 css={Css.gray800.fwb.xl2Sb.m0.$} data-testid="title">
                  Scope of Work - {title}
                </h1>
                {lineItems.map((lineItem) => {
                  const {
                    id,
                    displayName,
                    quantity,
                    unitOfMeasure,
                    homeownerSelection,
                    specifications,
                    tradePartnerNote,
                  } = lineItem;
                  const product = homeownerSelection
                    ? homeownerSelection.selectedOption?.product ?? productForNoSelection
                    : undefined;

                  return (
                    <div key={id}>
                      <div css={Css.base.fwb.mt2.$} data-testid="displayName">
                        {displayName}
                      </div>
                      <ul css={Css.listReset.add("listStyleType", "disc").add("padding", "revert").$}>
                        {product && <TradeScopeOfWorkRow title="Product Name">{product.name}</TradeScopeOfWorkRow>}
                        <TradeScopeOfWorkRow title="Quantity">
                          {quantity} {unitOfMeasure?.name}
                        </TradeScopeOfWorkRow>
                        <TradeScopeOfWorkRow title="Specifications">{specifications}</TradeScopeOfWorkRow>
                        <TradeScopeOfWorkRow title="Trade Note">{tradePartnerNote}</TradeScopeOfWorkRow>
                        {product && (
                          <TradeScopeOfWorkRow title="Product Description">{product.description}</TradeScopeOfWorkRow>
                        )}
                        {product && (
                          <TradeScopeOfWorkRow title="Product Manufacturer URL">
                            {product === productForNoSelection ? (
                              product.manufacturerUrl
                            ) : (
                              <a href={product.manufacturerUrl ?? ""}>{product.manufacturerUrl}</a>
                            )}
                          </TradeScopeOfWorkRow>
                        )}
                      </ul>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Fragment />
            ),
          )}
        </Fragment>
      );
    },
  });
}

type TradeScopeOfWorkRowProps = HasChildren & {
  title: string;
};
function TradeScopeOfWorkRow(props: TradeScopeOfWorkRowProps) {
  return (
    <li css={Css.my("5px").$} data-testid={props["data-testid"] ?? camelCase(props.title)}>
      <span css={Css.tdu.mr1.$}>{props.title}:</span>
      {props.children}
    </li>
  );
}
