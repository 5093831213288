import { Css, RouteTabWithContent, TabContent, Tabs } from "@homebound/beam";
import { useParams, useRouteMatch } from "react-router-dom";
import { useLotReleasePageQuery } from "src/generated/graphql-types";
import { ConfirmTradesStep } from "src/routes/developments/lot-summary/lot-release/ConfirmTradesStep";
import { developmentLotConfigPaths, LotConfigParams } from "src/routes/routesDef";
import { createDevelopmentLotConfigConfirmUrl, createDevelopmentLotConfigReviewUrl } from "src/RouteUrls";
import { SubHeader } from "./components/SubHeader";
import { ReviewOrdersStep } from "./ReviewOrdersStep";

export function LotReleasePage() {
  const { developmentId, projectId } = useParams<LotConfigParams>();
  const isConfirmPage = !!useRouteMatch([developmentLotConfigPaths.confirm]);
  const title = isConfirmPage
    ? "Confirm trades and select line items to release"
    : "Review purchase orders to be released";
  const { data } = useLotReleasePageQuery({
    variables: { id: projectId! },
    skip: !projectId,
  });
  const tabs: RouteTabWithContent[] = [
    {
      name: "Confirm Trades",
      href: createDevelopmentLotConfigConfirmUrl(developmentId, projectId!),
      path: developmentLotConfigPaths.confirm,
      render: () => <ConfirmTradesStep />,
    },
    {
      name: "Release POs",
      href: createDevelopmentLotConfigReviewUrl(developmentId, projectId!),
      path: developmentLotConfigPaths.review,
      render: () => <ReviewOrdersStep />,
    },
  ];

  return (
    <>
      <div css={Css.bgWhite.$}>
        <div css={Css.px8.$}>
          <SubHeader preHeader={data?.project.name ?? ""}>{title}</SubHeader>
          <Tabs tabs={tabs} />
        </div>
      </div>
      <div css={Css.pbPx(100).$}>
        <TabContent tabs={tabs} />
      </div>
    </>
  );
}
