import { BoundTextField, Button, Css, FormLines, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { Maybe } from "graphql/jsutils/Maybe";
import { Observer } from "mobx-react";
import { ProjectInput, useSaveProjectOverallTabMutation } from "src/generated/graphql-types";
import { ObjectConfig, Rule, useFormState } from "src/utils/formState";
import { getGoogleDriveLink, googleDriveFoldersUrl } from "src/utils/googleDrive";

export type GVariableModalProps = {
  projectId: string;
  googleDriveLink: string;
  refetch?: () => void;
};

export function UpdateGDriveModal({ projectId, googleDriveLink, refetch }: GVariableModalProps) {
  const { closeModal } = useModal();
  const [saveProjectGDrive] = useSaveProjectOverallTabMutation();
  const formState = useFormState({
    config: formConfig,
    init: { id: projectId, googleDriveFolderId: googleDriveLink },
  });

  return (
    <>
      <ModalHeader>Edit Google Drive Link</ModalHeader>
      <ModalBody>
        <FormLines>
          <h2 css={Css.baseMd.mb1.$}>Google Drive Link URL</h2>
          <BoundTextField labelStyle="hidden" field={formState.googleDriveFolderId} />
          <Observer>
            {() => (
              <Button
                label="Test Link"
                onClick={getGoogleDriveLink(formState.googleDriveFolderId.value!)}
                variant="text"
                disabled={!formState.valid}
              />
            )}
          </Observer>
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              label="Apply"
              disabled={!formState.valid}
              onClick={async () => {
                if (formState.canSave()) {
                  await saveProjectGDrive({
                    variables: { input: mapToInput(formState.value) },
                  });
                  closeModal();
                  refetch && refetch();
                }
              }}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

function mapToInput(formInput: FormValue): ProjectInput {
  return {
    id: formInput.id,
    googleDriveFolderId: formInput.googleDriveFolderId?.replace(`${googleDriveFoldersUrl}/`, "").replace(/\?.*/, ""),
  };
}

type FormValue = Omit<ProjectInput, "value">;

const googleDriveRule: Rule<Maybe<string>> = ({ value }) => {
  if (value?.includes(`${googleDriveFoldersUrl}`)) {
    return undefined;
  }
  // If it did not pass the above validation, then the user entered an Invalid Google Drive URL.
  return "Invalid Google Drive Link URL";
};

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  googleDriveFolderId: { type: "value", rules: [googleDriveRule] },
};
