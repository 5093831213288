import { BoundSelectField, BoundTextField, Button, Css, TextField, useSnackbar } from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  DesignPackageAddProductMetadataDocument,
  InputMaybe,
  MaterialType,
  SaveMaterialVariantInput,
  useAddProductItivPlaceholderQuery,
  useDesignPackageAddProductMetadataQuery,
  useSaveMaterialBrandMutation,
  useSaveMaterialVariantMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { MaterialDimensionsForm } from "src/routes/libraries/material-catalog/components/MaterialDimensionsForm";
import { MaterialImageEditor } from "src/routes/libraries/material-catalog/components/MaterialImageEditor";
import { VariantImageAsset } from "src/routes/libraries/material-catalog/MaterialPage";
import {
  createDesignCatalogUrl,
  createDesignPackageProductSearchUrl,
  createDesignPackageSlotDetailUrl,
} from "src/RouteUrls";
import { fail } from "src/utils";
import { Observer } from "mobx-react";
import omit from "lodash/omit";

type DesignPackageAddProductParams = {
  designPackageId: string;
  placeholderItivId: string;
  productItivId: string;
};

export function AddProductPage() {
  const history = useHistory();
  const { triggerNotice } = useSnackbar();
  const { designPackageId, placeholderItivId, productItivId } = useParams<DesignPackageAddProductParams>();
  const [saveMaterialBrand] = useSaveMaterialBrandMutation();
  const [saveMaterialVariant] = useSaveMaterialVariantMutation();

  // Pull in the Placeholder requirements (like `36in Gas Range`) and possible Attribute Filters (like Finish, Installation Type, etc.)
  const query = useAddProductItivPlaceholderQuery({
    variables: { placeholderItivId: placeholderItivId ?? fail("placeholderItivId is required") },
  });

  const placeholderIti = query.data?.itemTemplateItemVersion.scope;
  const itemMads = placeholderIti?.item.materialAttributeDimensions ?? [];

  const { designPackage, materialBrands } =
    useDesignPackageAddProductMetadataQuery({ variables: { id: designPackageId } }).data ?? {};
  const breadCrumb = [{ href: createDesignCatalogUrl(designPackageId), label: "Design Packages" }];
  if (designPackage?.code) breadCrumb.push({ href: "", label: designPackage.code });

  const formState = useFormState({
    init: { type: MaterialType.Product },
    config: formConfig,
  });
  const [newBrand, setNewBrand] = useState<string | undefined>(undefined);

  async function saveBrand() {
    if (newBrand) {
      const { data } = await saveMaterialBrand({
        variables: { input: { name: newBrand } },
        refetchQueries: [DesignPackageAddProductMetadataDocument],
      });
      setNewBrand(undefined);
      if (data) {
        formState.brandId.set(data.saveMaterialBrand.materialBrand.id);
      }
    }
  }

  const handleSave = async () => {
    const { images, ...input } = formState.value;
    const { data } = await saveMaterialVariant({
      variables: {
        input: {
          ...input,
          itemId: placeholderIti?.item.id,
          images: images?.map((i) => omit(i, "downloadUrl", "attachmentUrl")),
        },
      },
    });

    const savedMaterial = data?.saveMaterialVariant.materialVariant;

    if (savedMaterial) {
      triggerNotice({
        message: `Material: ${savedMaterial.code} created`,
        icon: "success",
      });
    }

    return savedMaterial;
  };

  return (
    <div>
      <PageHeader title="Add New Product" backButton={() => history.goBack()} breadcrumb={breadCrumb} />
      <div css={Css.df.jcc.pb6.$}>
        <div /* Form Container */ css={Css.mwPx(550).$}>
          <section css={Css.bb.bcGray400.py4.$}>
            <div css={Css.xlMd.mb2.$}>Product Details</div>
            <Observer>
              {() => (
                <div css={Css.df.fdc.gap2.$}>
                  <div>
                    Product Type: <span css={Css.smMd.$}>{placeholderIti?.item.name}</span>
                  </div>
                  <BoundTextField label="Name" field={formState.materialName} />
                  <div /* Brand select with inline create */ css={Css.w100.$}>
                    {newBrand === undefined ? (
                      <div /* Select Brand */ css={Css.df.gap1.aife.$}>
                        <BoundSelectField label="Brand" field={formState.brandId} options={materialBrands ?? []} />
                        <Button
                          label="New Brand"
                          size="md"
                          icon="plus"
                          variant="tertiary"
                          onClick={() => setNewBrand("")}
                        />
                      </div>
                    ) : (
                      <div /* Create Brand */ css={Css.df.aife.gap1.$}>
                        <TextField label="Brand" value={newBrand} onChange={(v) => setNewBrand(v)} />
                        <div css={Css.df.aib.gap1.$}>
                          <Button label="Save" size="md" variant="primary" onClick={saveBrand} />
                          <Button label="Cancel" variant="text" onClick={() => setNewBrand(undefined)} />
                        </div>
                      </div>
                    )}
                  </div>
                  <BoundTextField label="SKU / Model Number" field={formState.modelNumber} />
                  <BoundTextField label="Manufacturer URL" field={formState.manufacturerUrl} />
                </div>
              )}
            </Observer>
          </section>
          <section css={Css.py4.df.fdc.gap2.$}>
            <div css={Css.xlMd.$}>Product Attributes</div>
            <MaterialDimensionsForm dimensions={itemMads} formState={formState} />
          </section>
        </div>

        <div /* Product Images */ css={Css.mwPx(550).df.fdc.aife.$}>
          <section css={Css.py4.df.fdc.$}>
            <div css={Css.xlMd.pb4.$}>Product Images</div>
            <MaterialImageEditor formState={formState} />
          </section>
        </div>
      </div>
      <Observer>
        {() => (
          <div /* BottomFooter */ css={Css.absolute.bottom0.w100.df.jcfe.aic.p4.bgWhite.bt.bcGray300.gap2.sm.hPx(80).$}>
            <Button
              variant="textSecondary"
              label="Back to Product Search"
              onClick={createDesignPackageProductSearchUrl(designPackageId, placeholderItivId)}
              size="lg"
            />
            <Button
              label="Add & Select Product"
              onClick={async () => {
                const newMaterialVariant = await handleSave();
                // Navigate to the product details page
                history.push(
                  createDesignPackageSlotDetailUrl(
                    designPackageId,
                    placeholderItivId,
                    productItivId,
                    newMaterialVariant?.defaultBidItem.id,
                  ),
                );
              }}
              disabled={!formState.dirty}
              size="lg"
            />
          </div>
        )}
      </Observer>
    </div>
  );
}

export type AddProductDimensionsFormState = ObjectState<SaveMaterialVariantInput>;
export type AddProductFormValue = SaveMaterialVariantInput & {
  images?: InputMaybe<Array<VariantImageAsset>>;
};

const formConfig: ObjectConfig<AddProductFormValue> = {
  id: { type: "value" },
  type: { type: "value" },
  materialName: { type: "value", rules: [required] },
  brandId: { type: "value" },
  modelNumber: { type: "value" },
  manufacturerUrl: { type: "value" },
  mavIds: { type: "value" },
  images: {
    type: "list",
    config: {
      id: { type: "value" },
      sortOrder: { type: "value" },
      attachmentUrl: { type: "value" },
      downloadUrl: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          s3Key: { type: "value" },
          fileName: { type: "value" },
          contentType: { type: "value" },
          sizeInBytes: { type: "value" },
          delete: { type: "value" },
        },
      },
    },
  },
};
