import React, { Dispatch, ReactNode, useEffect, useReducer } from "react";
import { Stage } from "src/generated/graphql-types";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { ScheduleViewType } from "../scheduleUtils";
import {
  Actions,
  INITIAL_STATE,
  scheduleReducer,
  ScheduleStoreState,
  setScheduleStage,
  setTaskPaneState,
} from "./scheduleStoreReducer";
const ScheduleStore = React.createContext<{ scheduleState: ScheduleStoreState; dispatch: Dispatch<Actions> }>({
  scheduleState: INITIAL_STATE,
  dispatch: () => null,
});
ScheduleStore.displayName = "ScheduleStore";

export const useScheduleStore = () => React.useContext(ScheduleStore);

export function ScheduleStoreProvider({
  children,
  initialStateOverride = {},
  scheduleType,
  stage,
  disableQueryParams = false,
  delayFeatureFlagIsOn = false,
}: {
  children: ReactNode;
  scheduleType: ScheduleType;
  initialStateOverride?: Partial<ScheduleStoreState>;
  stage: Stage;
  delayFeatureFlagIsOn?: boolean;
  /** Disable persisting schedule store state via query params. Useful for displaying the task sidebar on non-schedule pages */
  disableQueryParams?: boolean;
}) {
  const [{ taskId, tabSelected, selectedViewType, scrollInto }, setQueryParams] = useQueryParams({
    isPaneOpen: BooleanParam,
    taskId: StringParam,
    tabSelected: StringParam,
    selectedViewType: StringParam,
    scrollInto: StringParam,
  });

  // Initialize state with values from queryParams
  const mergedInitialState = {
    ...INITIAL_STATE,
    taskPaneState: {
      ...INITIAL_STATE.taskPaneState,
      taskPaneId: taskId || undefined,
      tab: tabSelected || "details",
      scrollIntoViewType: (scrollInto as any) || undefined,
    },
    scheduleViewType: ScheduleViewType[selectedViewType as ScheduleViewType] || ScheduleViewType.List,
  };

  // Allow passing in an initial state override to set specific values without an initial render
  // Useful for tests
  const [scheduleState, dispatch] = useReducer(scheduleReducer, {
    ...mergedInitialState,
    ...initialStateOverride,
    scheduleType,
    stage,
    delayFeatureFlagIsOn,
  });

  useEffect(() => dispatch(setScheduleStage(stage)), [stage]);

  // if we have an override, then reset the initial state if the scroll into view type changes
  useEffect(() => {
    if (initialStateOverride.taskPaneState) {
      dispatch(setTaskPaneState(initialStateOverride.taskPaneState));
    }
  }, [initialStateOverride.taskPaneState?.scrollIntoViewType, initialStateOverride.taskPaneState]);

  useEffect(() => {
    if (disableQueryParams) return;

    setQueryParams({
      isPaneOpen: !!scheduleState.taskPaneState.taskPaneId,
      taskId: scheduleState.taskPaneState.taskPaneId,
      tabSelected: scheduleState.taskPaneState.tab,
      selectedViewType: scheduleState.scheduleViewType,
      scrollInto: scheduleState.taskPaneState.scrollIntoViewType,
    });
  }, [scheduleState, setQueryParams, disableQueryParams]);

  return <ScheduleStore.Provider value={{ scheduleState, dispatch }}>{children}</ScheduleStore.Provider>;
}
