import { createContext, PropsWithChildren, useContext, useCallback, useState } from "react";
import {
  MaterialCatalogDocument,
  MaterialSuperDrawer_MaterialVariantFragment,
  useMaterialSuperDrawerQuery,
  useSaveMaterialVariantMutation,
} from "src/generated/graphql-types";
import { MaterialFormValue, MaterialPageFormConfig } from "../../MaterialPage";
import { ObjectState, useFormState } from "src/utils/formState";
import { useSnackbar, useSuperDrawer } from "@homebound/beam";
import { useToggle } from "src/hooks";
import { noop } from "src/utils";
import omit from "lodash/omit";

export type MaterialSuperDrawerContextProps = {
  variant: MaterialSuperDrawer_MaterialVariantFragment | undefined;
  formState: ObjectState<MaterialFormValue>;
  createCopy: VoidFunction;
  loading: boolean;
  readOnly: boolean;
  toggleReadOnly: VoidFunction;
  save: () => Promise<void>;
  cancel: VoidFunction;
};

const MaterialSuperDrawerContext = createContext<MaterialSuperDrawerContextProps>({
  variant: undefined,
  loading: false,
  formState: undefined!,
  createCopy: () => {},
  readOnly: false,
  toggleReadOnly: () => {},
  save: () => Promise.resolve(),
  cancel: () => {},
});

type MaterialSuperDrawerContextProviderProps = PropsWithChildren<{
  variantId: string;
  readOnly: boolean;
}>;

export function MaterialSuperDrawerContextProvider(props: MaterialSuperDrawerContextProviderProps) {
  const { children, variantId } = props;
  const { data, loading } = useMaterialSuperDrawerQuery({
    variables: { filter: { id: [variantId], includeArchived: true } },
  });

  const [saveMaterialVariant, { loading: saving }] = useSaveMaterialVariantMutation({
    errorPolicy: "all", // Overriding error handling to avoid our default error banner
    onError: noop,
  });
  const [readOnly, toggleReadOnly] = useToggle(props.readOnly);
  const [copying, setCopying] = useState(false);
  const { closeDrawer } = useSuperDrawer();
  const { triggerNotice } = useSnackbar();

  const formState = useFormState({
    config: MaterialPageFormConfig,
    init: {
      input: [copying, data?.materialVariants?.entities.first] as const,
      map: ([copying, variant]) => {
        if (!variant) return {};

        const { id, modelNumber, codeOverride, isArchived, listing, images, materialAttributeValues, components } =
          variant;

        return {
          id: copying ? undefined : id,
          codeOverride,
          modelNumber,
          isArchived: isArchived,
          materialName: listing.name,
          type: listing.type.code,
          itemId: listing.item.id,
          brandId: listing.brand?.id,
          manufacturerUrl: listing.manufacturerUrl,
          images: copying
            ? []
            : images.map(({ id, sortOrder, asset }) => ({
                id,
                asset: { id: asset?.id, fileName: asset?.fileName, contentType: asset?.contentType },
                attachmentUrl: asset?.attachmentUrl,
                downloadUrl: asset?.downloadUrl,
                sortOrder,
              })),
          mavIds: materialAttributeValues.map((mav) => mav.id),
          components: components.map(({ id, qualifier, component }) => ({
            id: copying ? undefined : id,
            qualifier,
            componentId: component.id,
            componentItemId: component.listing.item.id,
            componentValues: component.materialAttributeValues.map((mav) => mav.id),
          })),
        };
      },
    },
    loading,
    readOnly,
  });

  // Enables copy mode
  const createCopy = useCallback(() => {
    toggleReadOnly(false);
    setCopying(true);
  }, [toggleReadOnly, setCopying]);

  // Cancel edit or copy mode
  const cancel = useCallback(() => {
    formState.revertChanges();
    setCopying(false);
    toggleReadOnly(true);
  }, [formState, toggleReadOnly, setCopying]);

  const save = useCallback(async () => {
    if (!formState) return;

    const value = copying ? formState.value : formState.changedValue;
    const { data, errors } = await saveMaterialVariant({
      variables: {
        input: {
          ...value,
          mavIds: formState.value.mavIds,
          images: formState.images.value.map((im) => omit(im, "downloadUrl", "attachmentUrl")), // We include all children images so that we don't accidentally delete any
          components: formState.components.value?.map(
            ({ componentId, componentItemId, componentValues, ...otherOpts }) => ({
              ...otherOpts,
              ...(componentItemId ? { componentItemId, componentValues } : { componentId }), // Take out the component Id since it references the placeholder used as base for the new MV
            }),
          ),
        },
      },
      refetchQueries: [MaterialCatalogDocument],
    });

    if (errors?.nonEmpty) {
      const mvUniquenessError = errors?.some(
        (e) => e.message.includes("have the same attributes") || e.message.includes("material_variants_code_key"),
      );

      triggerNotice({
        message: mvUniquenessError
          ? "This material conflicts with an existing material. Please add or alter it's attributes to make it unique"
          : errors.map((e) => e.message).join(", "),
        icon: "error",
      });

      return;
    }

    const savedMaterial = data?.saveMaterialVariant.materialVariant;

    if (savedMaterial) {
      triggerNotice({
        message: `Material: ${savedMaterial.code} ${copying ? "created" : "updated"}`,
        icon: "success",
      });

      copying ? closeDrawer() : toggleReadOnly();
    }
  }, [formState, saveMaterialVariant, toggleReadOnly, closeDrawer, triggerNotice, copying]);

  return (
    <MaterialSuperDrawerContext.Provider
      value={{
        variant: data?.materialVariants.entities.first,
        formState,
        loading: loading || saving,
        createCopy,
        cancel,
        save,
        readOnly,
        toggleReadOnly,
      }}
    >
      {children}
    </MaterialSuperDrawerContext.Provider>
  );
}

export function useMaterialSuperDrawerContext() {
  return useContext(MaterialSuperDrawerContext);
}
