import { Button, Css, FullBleed, SelectField, Tag, useBreakpoint, useModal } from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  ProjectRoleDetailsFragment,
  ScheduleDetailsFragment,
  ScheduleTemplatesDetailFragment,
  ScheduleTemplateStatus,
  Stage,
  TaskFilterOptionsFragment,
  useAvailableGlobalPhasesQuery,
  useSaveScheduleTemplatesMutation,
  useScheduleTemplateEditPageQuery,
} from "src/generated/graphql-types";
import { StaticHeaderField } from "src/components";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { TemplateLoader } from "src/routes/libraries/schedules/TemplateLoader";
import { LabelledText } from "src/routes/projects/schedule-v2/components/LabelledText";
import {
  queryResultToSchedulePhaseContextProps,
  SchedulePhaseContext,
} from "src/routes/projects/schedule-v2/contexts/SchedulePhaseContext";
import { ScheduleStoreProvider } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { Schedule } from "src/routes/projects/schedule-v2/Schedule";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { ScheduleTemplateParams, scheduleTemplatesPath } from "src/routes/routesDef";
import { queryResult, safeEntries, scheduleStatusToTagType, stageCodeToNameMapper } from "src/utils";
import { CreateOrUpdateScheduleTemplateForm } from "./CreateOrUpdateScheduleTemplateModal";

export function ScheduleTemplateDetailPage() {
  const { scheduleTemplateId } = useParams<ScheduleTemplateParams>();
  const query = useScheduleTemplateEditPageQuery({
    variables: { scheduleTemplateId: scheduleTemplateId },
  });

  return queryResult(query, {
    data: ({ scheduleTemplate, schedules, scheduleTasks, projectRoleDetails }) => {
      const { 0: schedule } = schedules;
      return (
        <ScheduleStoreProvider scheduleType={ScheduleType.Template} stage={schedule.stage}>
          <ScheduleTemplateDetailPageData
            scheduleTemplate={scheduleTemplate!}
            schedule={schedule}
            scheduleTasks={scheduleTasks}
            projectRoleDetails={projectRoleDetails}
          />
        </ScheduleStoreProvider>
      );
    },
    loading: () => <TemplateLoader />,
  });
}

type ScheduleTemplateDetailPageDataProps = {
  scheduleTemplate: ScheduleTemplatesDetailFragment;
  schedule: ScheduleDetailsFragment;
  scheduleTasks: TaskFilterOptionsFragment[];
  projectRoleDetails: ProjectRoleDetailsFragment[];
};

export function ScheduleTemplateDetailPageData({
  schedule,
  scheduleTemplate,
  scheduleTasks,
  projectRoleDetails,
}: ScheduleTemplateDetailPageDataProps) {
  // Fetching this early so that we can render the milestone dropdown at the page level for Template Schedules
  const { data: availableGlobalPhasesData } = useAvailableGlobalPhasesQuery({
    variables: { scheduleId: schedule.id, stage: schedule.stage },
    nextFetchPolicy: "cache-only",
  });
  const globalPhaseContextProps = useMemo(
    () => queryResultToSchedulePhaseContextProps(availableGlobalPhasesData),
    [availableGlobalPhasesData],
  );
  const isPublishedOrArchivedTemplate =
    scheduleTemplate.status === ScheduleTemplateStatus.Published ||
    scheduleTemplate.status === ScheduleTemplateStatus.Archived;

  return (
    <SchedulePhaseContext.Provider value={globalPhaseContextProps}>
      <div>
        <FullBleed>
          <div css={Css.bgWhite.pb1.$}>
            <Link css={Css.xsMd.gray700.dib.mt2.$} to={scheduleTemplatesPath} className="navLink">
              Schedule Templates
            </Link>
            <ScheduleTemplateDetailHeaderData
              scheduleTemplate={scheduleTemplate}
              schedule={schedule}
              scheduleTasks={scheduleTasks}
            />
          </div>
        </FullBleed>
      </div>
      <Schedule
        schedule={schedule}
        tasks={scheduleTasks ?? []}
        isPublishedOrArchivedTemplate={isPublishedOrArchivedTemplate}
        projectRoleDetails={projectRoleDetails}
      />
    </SchedulePhaseContext.Provider>
  );
}

type ScheduleTemplateHeaderDataProps = {
  scheduleTemplate: ScheduleTemplatesDetailFragment;
  schedule: ScheduleDetailsFragment;
  scheduleTasks: TaskFilterOptionsFragment[];
};

const changeStatusConfig = {
  [ScheduleTemplateStatus.Draft]: {
    confirmationModalContent:
      "Editing this template will move it back to a draft state. You will have to publish again to apply this template.",
    confirmationModalHeaderText: "Confirm template editing",
    confirmationModalSubmitText: "Continue",
  },
  [ScheduleTemplateStatus.Published]: {
    confirmationModalContent:
      "Editing this template will move it back to a draft state. You will have to publish again to apply this template.",
    confirmationModalHeaderText: "Confirm template details",
    confirmationModalSubmitText: "Publish",
  },
  [ScheduleTemplateStatus.Archived]: {
    confirmationModalContent: "Are you sure you want to archive this template?",
    confirmationModalHeaderText: "Confirm template archiving",
    confirmationModalSubmitText: "Archive template",
  },
};

type EditDetailsButtonProps = {
  scheduleTemplateId: string;
  scheduleType: ScheduleType;
};

function EditDetailsButton({ scheduleTemplateId, scheduleType }: EditDetailsButtonProps) {
  const { openModal } = useModal();
  return (
    <div css={Css.mr1.ml1.$}>
      <Button
        variant="secondary"
        label="Edit Details"
        onClick={() =>
          openModal({
            content: (
              <CreateOrUpdateScheduleTemplateForm
                title={"Edit template name and market"}
                scheduleTemplateId={scheduleTemplateId}
                scheduleType={scheduleType}
              />
            ),
          })
        }
      />
    </div>
  );
}

function PublishModalContent({ scheduleTemplate, schedule, scheduleTasks }: ScheduleTemplateHeaderDataProps) {
  return (
    <>
      <div css={Css.df.mb3.$}>
        <div css={Css.df.fb1.$}>
          <LabelledText label="Name">{scheduleTemplate?.name}</LabelledText>
        </div>
        <div css={Css.df.fb2.$}>
          <LabelledText label="Market">{scheduleTemplate?.market?.name}</LabelledText>
        </div>
      </div>
      <div css={Css.df.mb3.$}>
        <div css={Css.df.fb1.$}>
          <div css={Css.df.gap8.$}>
            <LabelledText label="Total Duration">{schedule.interval?.durationInDays || "--"}</LabelledText>
            <LabelledText label="Number of Tasks">{scheduleTasks.length || "--"}</LabelledText>
          </div>
        </div>
        <div css={Css.df.fb2.$}>
          <LabelledText label="Schedule Type">{stageCodeToNameMapper[scheduleTemplate?.stage]}</LabelledText>
        </div>
      </div>
    </>
  );
}

function ScheduleTemplateDetailHeaderData({
  scheduleTemplate,
  scheduleTasks,
  schedule,
}: ScheduleTemplateHeaderDataProps) {
  const breakpoints = useBreakpoint();
  const isDraft = scheduleTemplate.status === ScheduleTemplateStatus.Draft;
  const { openModal } = useModal();
  const [saveScheduleTemplates] = useSaveScheduleTemplatesMutation();

  function editAndStatusSelect() {
    return (
      <div css={Css.df.jcfe.aife.ifMdAndDown.maxwPx(270).$}>
        <SelectField
          label="Status"
          compact
          labelStyle="hidden"
          options={safeEntries(ScheduleTemplateStatus)}
          value={scheduleTemplate.statusDetail.code}
          getOptionLabel={([name]) => name}
          getOptionValue={([_, code]) => code}
          onSelect={(val) => {
            const { confirmationModalHeaderText, confirmationModalSubmitText, confirmationModalContent } =
              changeStatusConfig[val!];
            openModal({
              content: (
                <ConfirmationModal
                  title={confirmationModalHeaderText}
                  onConfirmAction={async () => {
                    await saveScheduleTemplates({
                      variables: {
                        scheduleTemplates: {
                          scheduleTemplates: [{ id: scheduleTemplate.id, status: val }],
                        },
                      },
                    });
                  }}
                  label={confirmationModalSubmitText}
                  danger={val === ScheduleTemplateStatus.Archived}
                  confirmationMessage={
                    val === ScheduleTemplateStatus.Published ? (
                      <PublishModalContent
                        scheduleTemplate={scheduleTemplate}
                        schedule={schedule}
                        scheduleTasks={scheduleTasks}
                      />
                    ) : (
                      confirmationModalContent
                    )
                  }
                />
              ),
            });
          }}
        />
        {isDraft && (
          <EditDetailsButton
            scheduleTemplateId={scheduleTemplate.id}
            scheduleType={schedule.stage === Stage.Development ? ScheduleType.Development : ScheduleType.Project}
          />
        )}
      </div>
    );
  }

  return (
    <div css={Css.mt1.pb2.w100.df.jcsb.gap2.$}>
      <div css={Css.df.jcsb.w100.ifLg.gap2.ifMdAndDown.fdc.wsnw.$}>
        <div css={Css.df.fg1.aifs.$}>
          <h1 data-testid="header_title" css={Css.xlSb.mrPx(22).whiteSpace("pre-wrap").gap1.maxwPx(300).$}>
            {scheduleTemplate?.name}
          </h1>
          <div css={Css.wsnw.$}>
            <Tag
              data-testid="templateStatus"
              text={scheduleTemplate.statusDetail.name}
              type={scheduleStatusToTagType[scheduleTemplate.statusDetail.code]}
            />
          </div>
          {breakpoints.mdAndDown && <div css={Css.ifLg.dn.ifMdAndDown.mla.pl2.$}>{editAndStatusSelect()}</div>}
        </div>
        <div css={Css.df.gap6.fg1.ifMdAndDown.mt2.$}>
          <StaticHeaderField label="Total Duration" value={schedule.interval?.durationInDays || "--"} />
          <StaticHeaderField label="Number of Tasks" value={scheduleTasks.length || "--"} />
          <StaticHeaderField
            label="Schedule Type"
            value={scheduleTemplate?.stage ? `${stageCodeToNameMapper[scheduleTemplate?.stage]}` : "--"}
          />
          <StaticHeaderField
            label="Market"
            value={
              scheduleTemplate?.market ? (
                <span>
                  <label css={Css.mr1.$}>{scheduleTemplate?.market?.name}</label>
                </span>
              ) : (
                <span>--</span>
              )
            }
          />
          <StaticHeaderField
            label="Developments"
            value={
              scheduleTemplate?.developments.nonEmpty ? (
                <span>{scheduleTemplate.developments.map((m) => m.name).join(", ")}</span>
              ) : (
                <span>--</span>
              )
            }
          />
        </div>
      </div>

      <div css={Css.ifMdAndDown.dn.$}>{editAndStatusSelect()}</div>
    </div>
  );
}
