import { Css, useTestIds, FormLines, BoundSelectField, useComputed } from "@homebound/beam";
import { ProductOfferingStepActions } from "./ProductOfferingStepActions";
import {
  DesignPackage_DesignPackagesStepFragment,
  DesignPackageLocation,
  SaveProductOfferingInput,
  useDesignPackagesStepQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { ObjectState } from "@homebound/form-state";
import { Card } from "src/components/Card";
import { useEffect } from "react";

type DesignPackagesStepProps = {
  designPackages: DesignPackage_DesignPackagesStepFragment[];
  formState: ObjectState<Partial<SaveProductOfferingInput>>;
};

export function DesignPackagesStep({ formState }: { formState: DesignPackagesStepProps["formState"] }) {
  const query = useDesignPackagesStepQuery();
  return queryResult(query, ({ designPackages }) => (
    <DesignPackagesStepView designPackages={designPackages.entities} formState={formState} />
  ));
}

function DesignPackagesStepView(props: DesignPackagesStepProps) {
  const { designPackages, formState } = props;
  const tid = useTestIds({});

  const sortedDesignPackages = designPackages
    // If there are design packages associated to the current plan they should be shown first
    .map((dp) => ({ ...dp, associatedToPlan: dp.planPackages.some((pp) => pp.id === formState.planPackageId.value) }))
    .sortBy((f) => (f.associatedToPlan ? 0 : 1));
  const exteriorDesignPackages = sortedDesignPackages.filter(
    (dp) => dp.location.code === DesignPackageLocation.Exterior,
  );
  const interiorDesignPackages = sortedDesignPackages.filter(
    (dp) => dp.location.code === DesignPackageLocation.Interior,
  );

  const selectedExteriorDesignPackage = useComputed(
    () => designPackages.find((dp) => dp.id === formState.exteriorDesignPackageId.value),
    [designPackages, formState.exteriorDesignPackageId],
  );
  const selectedInteriorDesignPackage = useComputed(
    () => designPackages.find((dp) => dp.id === formState.interiorDesignPackageId.value),
    [designPackages, formState.interiorDesignPackageId],
  );

  // When the page loads if there are design packages associated to the plan package, select the first one
  useEffect(() => {
    if (exteriorDesignPackages[0]?.associatedToPlan && !formState.exteriorDesignPackageId.value) {
      formState.exteriorDesignPackageId.set(exteriorDesignPackages[0].id);
    }
    if (interiorDesignPackages[0]?.associatedToPlan && !formState.interiorDesignPackageId.value) {
      formState.interiorDesignPackageId.set(interiorDesignPackages[0].id);
    }
    // We don't want to put formstate in this hook because we don't want this to rerun when formstate changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div css={Css.df.fdc.aic.$}>
        <div css={Css.xl3Sb.$} {...tid.title}>
          Confirm your Design Packages
        </div>
        <div css={Css.base.pt2.$}>Please make sure the correct Exterior and Interior Design Packages have</div>
        <div css={Css.base.pbPx(60).$}>been selected for this {formState.name.value} offering.</div>
        <Card xss={Css.w50.$}>
          <FormLines labelStyle="left" compact width="full">
            <BoundSelectField
              label="Exterior"
              options={exteriorDesignPackages}
              field={formState.exteriorDesignPackageId}
              getOptionLabel={(d) => d.name}
              getOptionValue={(d) => d.id}
              // grey out the design packages that are not associated to the plan
              getOptionMenuLabel={(d) => <div css={Css.if(!d.associatedToPlan).gray400.$}>{d.name}</div>}
              // clear out external ready plan options when a design package is selected
              onSelect={(exteriorDesignPackageId) =>
                formState.set({ exteriorDesignPackageId, externalReadyPlanOptions: [] })
              }
            />
            {selectedExteriorDesignPackage && (
              <div css={Css.w50.mla.xs.relative.top("-8px").$}>
                {selectedExteriorDesignPackage.optionGroups
                  .filter((rpog) => rpog.forDesignExterior)
                  .sortByKey("order")
                  .map((rpog) => `${rpog.name} ${rpog.options.length}`)
                  .join(" - ")}
              </div>
            )}
            <BoundSelectField
              label="Interior"
              options={interiorDesignPackages}
              field={formState.interiorDesignPackageId}
              getOptionLabel={(d) => d.name}
              getOptionValue={(d) => d.id}
              // grey out the design packages that are not associated to the plan
              getOptionMenuLabel={(d) => <div css={Css.if(!d.associatedToPlan).gray400.$}>{d.name}</div>}
              // clear out external ready plan options when a design package is selected
              onSelect={(interiorDesignPackageId) =>
                formState.set({ interiorDesignPackageId, externalReadyPlanOptions: [] })
              }
            />
            {selectedInteriorDesignPackage && (
              <div css={Css.w50.mla.xs.relative.top("-8px").$}>
                {selectedInteriorDesignPackage.optionGroups
                  .filter((rpog) => rpog.forDesignInterior)
                  .sortByKey("order")
                  .map((rpog) => `${rpog.name} ${rpog.options.length}`)
                  .join(" - ")}
              </div>
            )}
          </FormLines>
        </Card>
      </div>
      <ProductOfferingStepActions />
    </>
  );
}
