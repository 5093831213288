import { Css, useModal, useToast } from "@homebound/beam";
import { useParams } from "react-router-dom";
import {
  useDeleteWorkAuthorizationMutation,
  useSaveWorkAuthorizationMutation,
  useWorkAuthorizationDetailsQuery,
  useWorkAuthorizationProjectStagesQuery,
} from "src/generated/graphql-types";
import { useModeParam } from "src/hooks";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ProjectParams } from "src/routes/routesDef";
import { createWorkAuthorizationsUrl } from "src/RouteUrls";
import { hasData, queryResult, renderLoadingOrError } from "src/utils";
import { WorkAuthorizationEditor } from "./WorkAuthorizationEditor";

export function WorkAuthorizationPage() {
  const { showToast } = useToast();
  const { openModal } = useModal();
  const { projectId } = useParams<ProjectParams>();
  const { mode, isNew, id, onCancel, onEdit, onSave } = useModeParam({
    listPageUrl: createWorkAuthorizationsUrl(projectId),
  });

  const [saveWorkAuthorization] = useSaveWorkAuthorizationMutation();
  const [deleteWorkAuthorization] = useDeleteWorkAuthorizationMutation();

  const query = useWorkAuthorizationDetailsQuery({ variables: { workAuthorizationId: id! }, skip: isNew });
  const projectStagesQuery = useWorkAuthorizationProjectStagesQuery({ variables: { projectId } });

  if (!hasData(query) && !isNew) {
    return renderLoadingOrError(query);
  }

  return queryResult(projectStagesQuery, ({ project }) => (
    <>
      <PageHeader title="Work Authorization" breadcrumb={{ label: "Contracts", href: "#" }} />
      <WorkAuthorizationEditor
        mode={mode}
        projectId={projectId}
        projectStages={project.stages}
        workAuthorization={query.data?.workAuthorization}
        onEdit={onEdit}
        onDelete={async () => {
          await deleteWorkAuthorization({ variables: { workAuthorizationId: id! } });
          onSave();
        }}
        onCancel={() =>
          openModal({
            content: (
              <ConfirmationModal
                confirmationMessage={
                  <div css={Css.df.fdc.$}>
                    <div>Would you like to cancel?</div>
                    <div>Your changes will not be saved.</div>
                  </div>
                }
                onConfirmAction={onCancel}
                title="Discard Changes?"
                label="Yes"
              />
            ),
          })
        }
        onSave={async (input) => {
          if (input) {
            await saveWorkAuthorization({ variables: { workAuthorization: input } });
            showToast({ type: "success", message: "Your work authorization has been created" });
            onSave();
          }
        }}
      />
    </>
  ));
}
