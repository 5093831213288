import { Css } from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { DragDrop, StatusBar } from "@uppy/react";
import { Observer } from "mobx-react";
import { useUppyUploader, UseUppyUploaderProps } from "src/components";
import { AssetResizing, AssetResizingFormat, AssetResizingRegion } from "src/generated/graphql-types";
import { jobLogNoteFormWidth } from "src/routes/projects/job-logs/JobLogNoteRightPaneContent";

const allowedFileTypes = ["image/gif", "image/jpeg", "image/png", "video/mp4", "video/quicktime"];

export type ImageEditorProps = {
  newElement: (file: UppyFile) => Promise<void>;
  compact?: boolean;
};

export function JobLogImageEditor(props: ImageEditorProps) {
  const { newElement, compact = false } = props;
  async function handleOnFinish(file: UppyFile) {
    await newElement(file);
  }

  const previewResizing: AssetResizing = {
    region: AssetResizingRegion.Full,
    format: AssetResizingFormat.Jpg,
  };

  function renderForm() {
    return (
      <div css={Css.ba.bcBlue700.bsDashed.bw2.borderRadius("8px").$}>
        {compact ? (
          <ImageUploaderCompact onFinish={handleOnFinish} previewResizing={previewResizing} />
        ) : (
          <ImageUploaderWide onFinish={handleOnFinish} previewResizing={previewResizing} />
        )}
      </div>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

function ImageUploaderWide(props: UseUppyUploaderProps) {
  const dropHereOr = "Drag & drop or click to upload photos";
  const uppy = useUppyUploader({ ...props, allowedFileTypes });
  const icon = encodeURIComponent(`
    <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-icon="image" class="css-g360or" fill="#0369A1">
      <path d="M13 19V15H16L12 10L8 15H11V19H13Z" />
      <path d="M7 19H9V17H7C5.346 17 4 15.654 4 14C4 12.596 5.199 11.244 6.673 10.985L7.254 10.883L7.446 10.325C8.149 8.274 9.895 7 12 7C14.757 7 17 9.243 17 12V13H18C19.103 13 20 13.897 20 15C20 16.103 19.103 17 18 17H15V19H18C20.206 19 22 17.206 22 15C22 13.12 20.695 11.538 18.944 11.112C18.507 7.67 15.56 5 12 5C9.244 5 6.85 6.611 5.757 9.15C3.609 9.792 2 11.82 2 14C2 16.757 4.243 19 7 19Z" />
    </svg>
	`);
  return (
    <div
      data-testid="jobLogImageEditor"
      css={{
        ...Css.df.fdc.aic.jcc.$,
        "& button": Css.df.jcc.aic.cursorPointer.blue700.bgBlue50.borderRadius("8px").mwPx(96).$,
        "& button:hover": Css.bgGray300.$,
        // Hide the icon that comes with uppy
        "& .uppy-DragDrop-arrow": Css.dn.$,
        "& .uppy-DragDrop-inner": Css.df.aic.jcc.gap1.$,
        "& .uppy-DragDrop-note": Css.dn.$,
        // Add in our `upload` icon as a background-image.
        "& .uppy-DragDrop-inner::before": Css.wPx(24).hPx(24).add("content", `url("data:image/svg+xml,${icon}")`).$,
      }}
    >
      <DragDrop uppy={uppy} height={96} width={jobLogNoteFormWidth} locale={{ strings: { dropHereOr } }} />
      <StatusBar uppy={uppy} hideUploadButton hideCancelButton hidePauseResumeButton />
    </div>
  );
}

function ImageUploaderCompact(props: UseUppyUploaderProps) {
  const uppy = useUppyUploader({ ...props, allowedFileTypes });
  const icon = encodeURIComponent(`
		<svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-icon="image" class="css-g360or" fill="#0369A1">
      <path d="M13 19V15H16L12 10L8 15H11V19H13Z" />
      <path d="M7 19H9V17H7C5.346 17 4 15.654 4 14C4 12.596 5.199 11.244 6.673 10.985L7.254 10.883L7.446 10.325C8.149 8.274 9.895 7 12 7C14.757 7 17 9.243 17 12V13H18C19.103 13 20 13.897 20 15C20 16.103 19.103 17 18 17H15V19H18C20.206 19 22 17.206 22 15C22 13.12 20.695 11.538 18.944 11.112C18.507 7.67 15.56 5 12 5C9.244 5 6.85 6.611 5.757 9.15C3.609 9.792 2 11.82 2 14C2 16.757 4.243 19 7 19Z" />
		</svg>
	`);
  return (
    <div
      data-testid="jobLogImageEditor"
      css={{
        ...Css.df.fdc.aic.jcc.$,
        "& .uppy-DragDrop-label": Css.gray500.smMd.$,
        "& button": Css.df.jcc.aic.cursorPointer.blue700.bgBlue50.borderRadius("8px").mwPx(96).$,
        "& button:hover": Css.bgGray300.$,
        // Hide the icon that comes with uppy
        "& .uppy-DragDrop-arrow": Css.dn.$,
        "& .uppy-DragDrop-inner": Css.df.fdc.aic.jcc.$,
        // Add in our `upload` icon as a background-image.
        "& .uppy-DragDrop-inner::before": Css.wPx(24).hPx(24).add("content", `url("data:image/svg+xml,${icon}")`).$,
      }}
    >
      <DragDrop uppy={uppy} height={96} width={"max(96px, 100%)"} locale={{ strings: { dropHereOr: "" } }} />
      <StatusBar uppy={uppy} hideUploadButton hideCancelButton hidePauseResumeButton />
    </div>
  );
}
