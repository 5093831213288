import {
  MarketBidPackagesPageMetadataFragment,
  Order,
  ProductOfferingConfigDetailFragment,
  ProductOfferingConfigOrder,
  ProductOfferingConfigsFilter,
  useProductOfferingConfigsPageMetadataQuery,
  useProductOfferingConfigsQuery,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { pageSchema, queryResult } from "src/utils";
import { useMemo, useState } from "react";
import {
  Avatar,
  Button,
  column,
  Css,
  dateColumn,
  FilterDefs,
  Filters,
  GridColumn,
  Pagination,
  RowStyles,
  ScrollableContent,
  selectColumn,
  simpleDataRows,
  simpleHeader,
  SimpleHeaderAndData,
  treeFilter,
  useComputed,
  useGridTableApi,
  usePersistedFilter,
} from "@homebound/beam";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { dateCell, QueryTable, SearchBox } from "src/components";
import { parseOrder, toOrder } from "src/utils/ordering";
import {
  addEntityParam,
  createProductOfferingConfigEditUrl,
  createProductOfferingConfigsCompareUrl,
  createProductOfferingConfigUrl,
  createProductOfferingsUrl,
  createProductOfferingUrl,
} from "src/RouteUrls";
import { EmptyState } from "src/routes/components/EmptyState";
import { EmptyPlanAndOptionImage } from "src/routes/developments/plan-and-options/components/EmptyStateSvgs";

export function ProductOfferingConfigsPage() {
  const query = useProductOfferingConfigsPageMetadataQuery();
  return queryResult(query, {
    data: ({ markets }) => <ProductOfferingConfigsPageView marketsOrIds={markets} />,
  });
}

export type ProductOfferingConfigsPageViewProps = {
  // Configures the view to show all markets and developments, a specific development, or a specific product offering.
  marketsOrIds:
    | MarketBidPackagesPageMetadataFragment[]
    | { developmentId: string; productOffering?: { id: string; templateId?: string } };
};

export function ProductOfferingConfigsPageView({ marketsOrIds }: ProductOfferingConfigsPageViewProps) {
  const [pageSettings, setPageSettings] = useZodQueryString(pageSchema);
  const [order, setOrder] = useState<ProductOfferingConfigOrder>({ updatedAt: Order.Desc });
  const filterDefs: FilterDefs<ProductOfferingConfigsFilter> = useMemo(
    () =>
      Array.isArray(marketsOrIds)
        ? {
            developments: treeFilter({
              defaultCollapsed: true,
              label: "Markets and Developments",
              filterBy: "leaf",
              getOptionLabel: (o) => o.name,
              getOptionValue: (o) => o.id,
              options: marketsOrIds.map((m) => ({
                id: m.id,
                name: m.name,
                children: m.developments.map((d) => ({ id: d.id, name: d.name })),
              })),
            }),
          }
        : {},
    [marketsOrIds],
  );

  const { filter, setFilter } = usePersistedFilter<ProductOfferingConfigsFilter>({
    storageKey: "productOfferingConfigsFilter",
    filterDefs,
  });
  const [searchFilter, setSearchFilter] = useState<string>("");

  const { developmentId, productOffering } = !Array.isArray(marketsOrIds)
    ? marketsOrIds
    : { developmentId: undefined, productOffering: undefined };
  const query = useProductOfferingConfigsQuery({
    variables: {
      filter: {
        search: searchFilter || undefined,
        developments: developmentId ? [developmentId] : undefined,
        productOfferings: productOffering ? [productOffering.id] : undefined,
        ...filter,
      },
      order,
      page: pageSettings,
    },
  });
  const { loading, data } = query;
  const configs = data?.productOfferingConfigs.entities;
  const backUrl = productOffering
    ? createProductOfferingUrl(productOffering.id, developmentId)
    : createProductOfferingsUrl(developmentId);
  const subHeader = (
    <div>
      <div css={Css.xl2Bd.$}>Compare Configurations</div>
      <div css={Css.gray700.$}>
        {productOffering
          ? "Compare the configurations created on the offering, or create new ones!"
          : "Select as many saved configurations on product offerings below, then select compare"}
      </div>
    </div>
  );

  const api = useGridTableApi<Row>();
  const selectedRows = useComputed(() => api.getSelectedRows(), [api]);
  const columns = useMemo(() => createColumns(!!developmentId), [developmentId]);
  const rowStyles: RowStyles<Row> = useMemo(
    () => ({
      data: {
        rowLink: ({ id, data: { productOffering } }) =>
          createProductOfferingConfigEditUrl({
            productOfferingConfigId: id,
            // Update url params with metaIds to load the page with selectable options & delta costs
            productOfferingId: productOffering.id,
            itemTemplateId:
              productOffering.itemTemplates.find((it) => it.isLatestActive)?.id ||
              productOffering.itemTemplates.last?.id,
            developmentId,
          }),
      },
    }),
    [developmentId],
  );
  const disableCompare = useMemo(
    () =>
      (selectedRows.isEmpty && "No configurations selected") ||
      (selectedRows.length > 10 && "No greater than 10 configurations can be compared at once"),
    [selectedRows.isEmpty, selectedRows.length],
  );

  return (
    <>
      {!loading && productOffering && configs?.isEmpty ? (
        <>
          <PageHeader title="Compare Configurations" hideTitle backButton={backUrl} left={subHeader} />
          <EmptyState
            message="There are no configurations on this offering yet"
            svg={<EmptyPlanAndOptionImage />}
            button={
              <Button
                label="Create Configuration"
                onClick={createProductOfferingConfigUrl({
                  idOrAdd: addEntityParam,
                  productOfferingId: productOffering.id,
                  itemTemplateId: productOffering.templateId,
                  developmentId,
                })}
                disabled={!productOffering.templateId && "No Item template found for this offering"}
              />
            }
          />
        </>
      ) : (
        <>
          <PageHeader
            title="Compare Configurations"
            hideTitle
            backButton={backUrl}
            left={subHeader}
            right={
              <>
                {productOffering && (
                  <Button
                    label="Create New"
                    variant="secondary"
                    onClick={createProductOfferingConfigUrl({
                      idOrAdd: addEntityParam,
                      productOfferingId: productOffering.id,
                      itemTemplateId: productOffering.templateId,
                      developmentId,
                    })}
                    disabled={!productOffering.templateId && "No Item template found for this offering"}
                  />
                )}
                <Button
                  label="Compare"
                  variant="primary"
                  disabled={disableCompare}
                  onClick={createProductOfferingConfigsCompareUrl(
                    selectedRows.map((r) => r.id),
                    productOffering?.id,
                    developmentId,
                  )}
                />
              </>
            }
          />
          <TableActions>
            {!developmentId && <Filters filterDefs={filterDefs} filter={filter} onChange={setFilter} />}
            <SearchBox onSearch={setSearchFilter} />
          </TableActions>
          <ScrollableContent>
            <QueryTable
              {...{ api, columns, rowStyles, query }}
              createRows={() => [simpleHeader, ...simpleDataRows(data?.productOfferingConfigs.entities)]}
              sorting={{
                on: "server",
                onSort: (key, direction) => setOrder(toOrder(key, direction)),
                value: parseOrder(order),
              }}
              stickyHeader
              fallbackMessage="There aren't any configurations to display."
            />
            <Pagination
              page={[pageSettings, setPageSettings]}
              totalCount={data?.productOfferingConfigs.pageInfo.totalCount || 0}
            />
          </ScrollableContent>
        </>
      )}
    </>
  );
}

type Row = SimpleHeaderAndData<ProductOfferingConfigDetailFragment>;

const createColumns = (hideDevelopment: boolean): GridColumn<Row>[] => [
  selectColumn(),
  column<Row>({ header: "Name", data: ({ name }) => name }),
  column<Row>({ header: "Offering", data: ({ productOffering }) => productOffering.name }),
  column<Row>({
    header: "Created by",
    data: ({ createdBy }) => (
      <Avatar src={createdBy?.internalUser?.avatar} name={createdBy.internalUser?.name} showName />
    ),
  }),
  dateColumn<Row>({ header: "Created", data: ({ createdAt }) => dateCell(createdAt) }),
  dateColumn<Row>({ header: "Updated", serverSideSortKey: "updatedAt", data: ({ updatedAt }) => dateCell(updatedAt) }),
  ...(hideDevelopment
    ? []
    : [column<Row>({ header: "Developments", data: ({ productOffering }) => productOffering.development?.name })]),
];
