import { BoundSelectField, Button, ModalBody, ModalFooter, ModalHeader, useModal, FormLines } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  InstallTasksPageQuery,
  ItemPlanTaskMappingsDocument,
  SaveItemPlanTaskMappingInput,
  useSaveItemPlanTaskMappingMutation,
} from "src/generated/graphql-types";

export type AddInstallTaskModalProps = {
  data: InstallTasksPageQuery;
};

export function AddInstallTaskModal({ data }: AddInstallTaskModalProps) {
  const { closeModal } = useModal();
  const formState = useFormState({
    config: InstallTaskFormConfig,
  });

  const [saveIPTM] = useSaveItemPlanTaskMappingMutation();

  async function onSave() {
    await saveIPTM({ variables: { input: formState.value }, refetchQueries: [ItemPlanTaskMappingsDocument] });
    closeModal();
  }

  return (
    <>
      <ModalHeader>New Install Task</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundSelectField field={formState.itemId} options={data.items} />
          <BoundSelectField field={formState.locationId} options={data.locations} />
          <BoundSelectField field={formState.taskId} options={data.globalPlanTasks.entities} />
        </FormLines>
      </ModalBody>
      <Observer>
        {() => (
          <ModalFooter>
            <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
            <Button label="Save" disabled={!formState.valid} onClick={onSave} />
          </ModalFooter>
        )}
      </Observer>
    </>
  );
}

export const InstallTaskFormConfig: ObjectConfig<SaveItemPlanTaskMappingInput> = {
  id: { type: "value" },
  itemId: { type: "value", rules: [required] },
  locationId: { type: "value" },
  taskId: { type: "value", rules: [required] },
};
