import {
  Accordion,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ToggleChipGroup,
  useModal,
} from "@homebound/beam";
import { useContext, useState } from "react";
import { DesignPackageCompareModeContext } from "../DesignPackageCompareModeContext";
import { CompareMode_DesignPackageFragment } from "src/generated/graphql-types";
import { isDefined } from "src/utils";
import { designUpgradeTagColor, planPackageTagColor } from "../../designCatalogAtoms";

type CompareConfigModalProps = { columnIndex: number };

export function CompareConfigModal({ columnIndex }: CompareConfigModalProps) {
  const { closeModal } = useModal();
  const { designPackage, columns, updateFilter } = useContext(DesignPackageCompareModeContext);
  const { dpOptionsByGroup, ppOptions, upgradeOptions } = useOptionsByGroup(designPackage);

  // there's an apply button so don't save anything until they're ready for it
  const [local, setLocal] = useState<string[]>(columns[columnIndex] ?? []);

  // an option from each forDesignPackages group must be selected
  const disabled = !dpOptionsByGroup.every(([rpog, rpos]) => rpos.some((rpo) => local.includes(rpo.id)));

  return (
    <div css={Css.df.fdc.gap2.maxwPx(324).$}>
      <ModalHeader>
        <div css={Css.pb2.$}>Configure Column {columnIndex + 1}</div>
      </ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap2.mb2.$}>
          {dpOptionsByGroup.map(([rpog, rpos]) => (
            <ToggleChipGroup
              key={rpog.id}
              label={rpog.name}
              options={rpos.map((rpo) => ({
                label: rpo.name,
                value: rpo.id,
                startAdornment: <span css={Css.smBd.$}>{rpo.designPackageAbbreviation}</span>,
              }))}
              values={local}
              onChange={(rpoIds) => {
                let updatedRpoIds = rpoIds;
                // When a user selects a second option from a group, remove the first one
                if (rpoIds.length > 1) updatedRpoIds = [rpoIds.last!];
                // Options is a flat array between all groups, so pull out the options for this current group
                const ids = rpos.map((rpo) => rpo.id);
                const currentRposWithoutThisGroup: string[] = local.filter((rpoId) => !ids.includes(rpoId));
                setLocal([...currentRposWithoutThisGroup, ...updatedRpoIds].compact().unique());
              }}
              xss={
                Css.if(isDefined(rpog.group.designPackageTagColor)).bgColor(rpog.group.designPackageTagColor!).gray900.$
              }
            />
          ))}
          {upgradeOptions && (
            <ToggleChipGroup
              label="Upgrades"
              options={upgradeOptions.map((rpo) => ({
                label: rpo.name,
                value: rpo.id,
                startAdornment: <span css={Css.smBd.$}>{rpo.designPackageAbbreviation}</span>,
              }))}
              values={local}
              onChange={(rpoIds) => {
                const ids = upgradeOptions.map((rpo) => rpo.id);
                const currentRposWithoutThisGroup: string[] = local.filter((rpoId) => !ids.includes(rpoId));
                setLocal([...currentRposWithoutThisGroup, ...rpoIds].compact().unique());
              }}
              xss={Css.bgColor(designUpgradeTagColor).gray900.$}
            />
          )}
        </div>
        {ppOptions && (
          <Accordion title="Advanced Settings">
            <div>
              <ToggleChipGroup
                label="Plan Packages"
                options={ppOptions.map((rpo) => ({
                  label: rpo.name,
                  value: rpo.id,
                  startAdornment: <span css={Css.smBd.$}>{rpo.designPackageAbbreviation}</span>,
                }))}
                values={local}
                onChange={(rpoIds) => {
                  let updatedRpoIds = rpoIds;
                  // When a user selects a second option from a group, remove the first one
                  if (rpoIds.length > 1) updatedRpoIds = [rpoIds.last!];
                  // Options is a flat array between all groups, so pull out the options for this current group
                  const ids = ppOptions.map((rpo) => rpo.id);
                  const currentOptionsWithoutThisGroup: string[] = local.filter((rpoId) => !ids.includes(rpoId));
                  setLocal([...currentOptionsWithoutThisGroup, ...updatedRpoIds].compact().unique());
                }}
                xss={Css.bgColor(planPackageTagColor).gray900.$}
              />
            </div>
          </Accordion>
        )}
      </ModalBody>
      <ModalFooter>
        <div css={Css.df.jcfe.aic.gap1.$}>
          <Button label="Cancel" variant="text" onClick={() => closeModal()} />
          <Button
            label="Apply"
            disabled={disabled}
            onClick={() => {
              updateFilter(columnIndex, local);
              closeModal();
            }}
          />
        </div>
      </ModalFooter>
    </div>
  );
}

function useOptionsByGroup(designPackage: CompareMode_DesignPackageFragment) {
  const dpOptionsByGroup = designPackage.options
    .filter((rpo) => rpo.optionGroup.forDesignPackages)
    .sortBy((rpo) => rpo.order)
    .groupByObject((rpo) => rpo.optionGroup)
    .sortBy(([rpog]) => rpog.order);
  // isPlanPackage options are in a single group so no need to group them
  const ppOptions = designPackage.options.filter((rpo) => rpo.optionGroup.isPlanPackage).sortBy((rpo) => rpo.order);
  // return all upgrade options in a flat array so we can display them in a single Upgrades group
  const upgradeOptions = designPackage.options
    .filter((rpo) => rpo.optionGroup.forDesignUpgrade)
    .sortBy((rpo) => rpo.optionGroup.order);

  return { dpOptionsByGroup, ppOptions, upgradeOptions };
}
