import { createContext, PropsWithChildren, ReactNode, useCallback, useContext, useMemo } from "react";
import {
  EnabledFeatureFlagFragment,
  FeatureFlagType,
  useDevelopmentFeatureFlagsQuery,
  useProjectFeatureFlagsQuery,
  useUserFeatureFlagsQuery,
} from "src/generated/graphql-types";
import { useDevelopmentContext } from "src/routes/developments/context/DevelopmentContext";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";

type FeatureFlagContextProps = {
  userFlags: EnabledFeatureFlagFragment[];
};

export const FeatureFlagContext = createContext<FeatureFlagContextProps>({ userFlags: [] });

export function FeatureFlagProvider({ children }: PropsWithChildren<unknown>) {
  const { data } = useUserFeatureFlagsQuery();
  const context = useMemo(() => ({ userFlags: data?.userFeatureFlags ?? [] }), [data]);
  return <FeatureFlagContext.Provider value={context}>{children}</FeatureFlagContext.Provider>;
}

export function useFeatureFlagContext() {
  return useContext(FeatureFlagContext);
}

type FeatureFlagHookOptions = {
  developmentId?: string;
  projectId?: string;
};

export type FeatureFlagHook = {
  /** Checks `flag` within the current user/development/project context. */
  featureIsEnabled: (flag: FeatureFlagType) => boolean;
  /** Checks `flag` within a different development/project context, requires an async call. */
  asyncFeatureIsEnabled: (flag: FeatureFlagType, options: FeatureFlagHookOptions) => Promise<boolean>;
};

/**
 * Returns two `featureIsEnabled` / `asyncFeatureIsEnabled` functions to check if a feature flag is enabled.
 *
 * If you already know the specific feature flag you want, see `useFeatureFlag`.
 */
export function useFeatureFlags(): FeatureFlagHook {
  const { userFlags } = useFeatureFlagContext();
  // Extract the flags from the project and development contexts and combine them with the user's feature flags.
  const activeProject = useProjectContext();
  const activeDevelopment = useDevelopmentContext();

  // Use async "lazy" queries to fetch the feature flags for the a project and development in case were not on a project or development page.
  const { refetch: fetchProject } = useProjectFeatureFlagsQuery({ skip: true });
  const { refetch: fetchDevelopment } = useDevelopmentFeatureFlagsQuery({ skip: true });

  const featureIsEnabled = useCallback(
    (featureFlag: FeatureFlagType) => {
      const availableFeatures = [
        ...activeProject.featureFlags,
        ...activeDevelopment.featureFlags,
        ...userFlags.map((ff) => ff.type.code),
      ].unique();
      return availableFeatures.includes(featureFlag);
    },
    [activeProject, activeDevelopment, userFlags],
  );

  const asyncFeatureIsEnabled = useCallback(
    async (featureFlag: FeatureFlagType, options: FeatureFlagHookOptions) => {
      const { developmentId, projectId } = options;
      // Fetch the feature flags for the project and development if they are not already available.
      const availableFeatures = userFlags.map((ff) => ff.type.code);
      if (projectId) {
        const { data } = await fetchProject({ projectId });
        availableFeatures.push(...data.project.featureFlags.map((ff) => ff.type.code));
      }
      if (developmentId) {
        const { data } = await fetchDevelopment({ developmentId });
        availableFeatures.push(...data.development.featureFlags.map((ff) => ff.type.code));
      }
      return availableFeatures.includes(featureFlag);
    },
    [userFlags, fetchDevelopment, fetchProject],
  );

  return { featureIsEnabled, asyncFeatureIsEnabled };
}

/** Returns `true` if `flag` is enabled within the current user/development/project context. */
export function useFeatureFlag(flag: FeatureFlagType): boolean {
  const { featureIsEnabled } = useFeatureFlags();
  return featureIsEnabled(flag);
}

type FeaturedComponentProps = {
  featureFlag: FeatureFlagType;
  children?: ReactNode;
};

/** Conditionally renders `children` only if `featureFlag` is enabled. */
export function FeaturedComponent({ featureFlag, children }: FeaturedComponentProps) {
  const { featureIsEnabled } = useFeatureFlags();
  const isFeatureEnabled = featureIsEnabled(featureFlag);
  return isFeatureEnabled ? <>{children}</> : null;
}
