import { Accordion, LoadingSkeleton, useTestIds } from "@homebound/beam";
import { formatDistanceToNow } from "date-fns";
import { useMemo } from "react";
import { ActionItemsDelayFlagsQuery, useActionItemsDelayFlagsQuery } from "src/generated/graphql-types";
import { queryResult, sortBy } from "src/utils";
import { ItemCard } from "../../components/ItemCard";
import { useDashboardFilterContext } from "../../DashboardFilterContext";
import { ActionItemTypes } from "../../enums";
import { filterScheduleFlagsByNonClosedStatus } from "../../utils";
import { ActionItemsContainer, EmptyActionItems, LINK_CSS } from "./ActionItemsPage";

export function ActionItemsDelayFlags() {
  const { filter } = useDashboardFilterContext();
  const query = useActionItemsDelayFlagsQuery({
    variables: { internalUser: filter.internalUser, scheduleParent: filter.project, scheduleIsActive: true },
    skip: !filter.internalUser,
  });

  return queryResult(query, {
    data: (data) => <ActionItemsDelayFlagsLayout data={data} />,
    loading: () => <LoadingSkeleton rows={3} columns={3} size="lg" />,
    showLoading: "always",
  });
}

function ActionItemsDelayFlagsLayout({ data }: { data: ActionItemsDelayFlagsQuery }) {
  const tids = useTestIds({}, "actionItemDelayFlags");
  const { scheduleFlags } = data;

  const { openTaskDetailPane, selectedTaskId } = useDashboardFilterContext();

  const filteredScheduleFlags = useMemo(() => {
    return filterScheduleFlagsByNonClosedStatus(scheduleFlags);
  }, [scheduleFlags]);

  const delayFlagItems = useMemo(() => {
    // TODO: move to grapql sort order
    const sortedScheduleFlags = sortBy(filteredScheduleFlags, (flag) => flag.createdAt);
    return sortedScheduleFlags.map(({ id, scheduleTask, reason }) => {
      return scheduleTask ? (
        <ItemCard
          key={`${id}_${reason?.id}_${scheduleTask.id}`}
          title={reason?.title}
          actionType={ActionItemTypes.DELAYS}
          onClick={() => openTaskDetailPane(scheduleTask)}
          description={
            <p>
              A delay flag{" "}
              <span css={LINK_CSS} onClick={() => openTaskDetailPane(scheduleTask)}>
                {reason?.title}
              </span>{" "}
              has been added to a task{" "}
              <span css={LINK_CSS} onClick={() => openTaskDetailPane(scheduleTask)}>
                {scheduleTask.name}
              </span>
            </p>
          }
          duration={formatDistanceToNow(scheduleTask.interval.startDate, { addSuffix: true })}
          project={scheduleTask.project}
          assignee={scheduleTask.assignee?.user}
          disabledBtn={scheduleTask.id === selectedTaskId}
          parent={scheduleTask.parent}
        />
      ) : null;
    });
  }, [filteredScheduleFlags, openTaskDetailPane, selectedTaskId]);

  return (
    <Accordion {...tids} title={`Delay Flags (${delayFlagItems.length})`} defaultExpanded topBorder={false}>
      {delayFlagItems.isEmpty && <EmptyActionItems title="No delays" />}
      <ActionItemsContainer count={delayFlagItems.length}>{delayFlagItems}</ActionItemsContainer>
    </Accordion>
  );
}
