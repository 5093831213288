import {
  BoundMultiSelectField,
  BoundTextField,
  Button,
  Css,
  SuperDrawerContent,
  SuperDrawerHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import {
  InputMaybe,
  UserGroupFragment,
  UserGroupsDocument,
  useSaveUserGroupMutation,
  useUserGroupsMetadataQuery,
} from "src/generated/graphql-types";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";

type UserGroupSuperDrawerProps = {
  userGroup?: UserGroupFragment;
};

export function UserGroupSuperDrawer({ userGroup }: UserGroupSuperDrawerProps) {
  const { data } = useUserGroupsMetadataQuery();
  const [createUserGroup] = useSaveUserGroupMutation();
  const { closeDrawer } = useSuperDrawer();

  const users = data?.internalUsers ?? [];

  const formState = useFormState({
    config,
    init: {
      id: userGroup?.id,
      name: userGroup?.name,
      userIds: userGroup?.users.map((u) => u.id!) ?? [],
    },
  });

  const handleCreateUserGroup = useCallback(async () => {
    if (formState.canSave()) {
      await createUserGroup({ variables: { input: formState.value }, refetchQueries: [UserGroupsDocument] });
      closeDrawer();
    }
  }, [closeDrawer, createUserGroup, formState]);

  const action = userGroup ? "Update" : "Create";

  return (
    <Observer>
      {() => (
        <SuperDrawerContent>
          <SuperDrawerHeader title={`${action} User Group`} />
          <div css={Css.df.fdc.gap2.h100.$}>
            <BoundTextField label="Name" field={formState.name} />
            <BoundMultiSelectField
              label="Users"
              field={formState.userIds}
              options={users}
              getOptionValue={({ user }) => user.id!}
              getOptionLabel={({ name }) => name}
              fieldDecoration={internalUserAvatar}
              getOptionMenuLabel={internalUserMenuLabel}
            />
            <div css={Css.df.jcfe.$}>
              <Button label={action} onClick={handleCreateUserGroup} disabled={!formState.valid} />
            </div>
          </div>
        </SuperDrawerContent>
      )}
    </Observer>
  );
}

type FormValue = {
  id: InputMaybe<string>;
  name: InputMaybe<string>;
  userIds: InputMaybe<string[]>;
};

const config: ObjectConfig<FormValue> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  userIds: { type: "value", rules: [required] },
};
