import { Avatar, Css } from "@homebound/beam";
import { CommentEditor, CommentRow } from "src/components/index";
import {
  CommentFragment,
  CommentStreamDetailFragment,
  CommentStreamVisibility,
  InternalUser,
  useMarkCommentStreamAsReadMutation,
} from "src/generated/graphql-types";
import { SaveAttachmentModel } from "../boundAttachments/BoundAttachments";
import { CommentDeleteFunction, CommentSaveFunction } from "./CommentFeed";

import { useEffect, useRef, useState } from "react";
import { useIntersectionObserver } from "usehooks-ts";

export type CommentStreamProps = {
  stream?: CommentStreamDetailFragment;
  streamVisibility: CommentStreamVisibility;
  saveComment: CommentSaveFunction;
  deleteComment: CommentDeleteFunction;
  currentUser: InternalUser;
  commentableId: string;
};

export function CommentStream(props: CommentStreamProps) {
  const { stream, streamVisibility, saveComment, deleteComment, currentUser, commentableId } = props;
  const commentStreamId = stream?.id;
  const comments = stream?.comments || [];
  const { name, avatar } = currentUser;
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { freezeOnceVisible: true });
  const isIntersecting = entry?.isIntersecting ?? false;
  const [markCommentStreamAsRead, { called: mutationFired }] = useMarkCommentStreamAsReadMutation();

  useEffect(() => {
    if (stream && stream?.unreadCount > 0 && isIntersecting && !mutationFired) {
      void markCommentStreamAsRead({ variables: { input: { id: stream.id } } });
    }
  }, [stream, isIntersecting, mutationFired, markCommentStreamAsRead]);

  async function handleSave(text: string, html: string, attachments: SaveAttachmentModel[], mentions?: string[]) {
    await saveComment(text, html, attachments, { commentStreamId, streamVisibility }, mentions);
  }

  return (
    /**
     * fdcr: Render bottom-up with Y-overflow scrolling off the top of the component, ensuring
     *    users see the Editor and most recent comments on page-load
     * maxh: "lgtm" values picked after comparing 1080p and 1440p monitors on the ProjectOverview
     *    page. Some constraint is required for overflow+fdcr to work as desired.
     */
    <div ref={ref} css={Css.maxh("calc(max(65vh, 900px))").oya.oxh.df.fdcr.$}>
      <div css={Css.df.$}>
        <div css={Css.mr2.fs0.$}>
          <Avatar name={name} src={avatar} />
        </div>
        <CommentEditor
          commentableId={commentableId}
          commentStreamId={commentStreamId}
          streamVisibility={streamVisibility}
          onSave={handleSave}
          submitText="Add"
        />
      </div>
      <div css={Css.mt2.$}>
        {comments.map((comment: CommentFragment) => {
          return (
            <div css={Css.mb4.$} key={comment.id}>
              <CommentRow {...{ comment, currentUser, saveComment, deleteComment }} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
