import { BoundMultiSelectField, BoundSelectField, BoundTextField, Css, FormLines } from "@homebound/beam";
import {
  DocumentDetailDocumentTypeFragment,
  SaveGlobalPlanTaskRequiredDocumentInput,
  useProjectRoleDetailsQuery,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { sortBy } from "src/utils";
import { ObjectState, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { globalPlanTaskRequiredDocumentConfig } from "./TaskCatalogForm";

export function AddRequiredDocumentModal({
  onDocumentAdd,
  documentTypes,
}: {
  onDocumentAdd: (local: ObjectState<SaveGlobalPlanTaskRequiredDocumentInput>) => void;
  documentTypes: DocumentDetailDocumentTypeFragment[];
}) {
  const { data } = useProjectRoleDetailsQuery({ fetchPolicy: "cache-first" });
  const projectRoleDetails = data?.projectRoleDetails ?? [];

  const formState = useFormState({
    config: globalPlanTaskRequiredDocumentConfig,
  });
  const docTypeOptions = sortBy(documentTypes, ({ name }) => name).filter((dt) => !dt.systemControlled);
  const roleOptions = sortBy(projectRoleDetails, ({ name }) => name);

  return (
    <Observer>
      {() => (
        <ConfirmationModal
          title="Add a Document Requirement"
          onConfirmAction={() => onDocumentAdd(formState)}
          confirmationMessage={
            <div>
              <div css={Css.gray700.fw4.mb2.$}>
                Choose a document that will be required to mark a plan task as 'Complete'
              </div>
              <FormLines>
                <BoundTextField label="Name*" field={formState.name} />
                <BoundSelectField label="Type*" options={docTypeOptions} field={formState.documentType} />
                <BoundMultiSelectField
                  label="Watchers"
                  options={roleOptions}
                  getOptionValue={(pr) => pr.code}
                  getOptionLabel={(pr) => pr.name}
                  field={formState.watchers}
                />
              </FormLines>
            </div>
          }
          label="Add Document"
          disabled={!formState.valid}
        />
      )}
    </Observer>
  );
}
