import { BoundSelectField, Css, FormLines, useComputed, useModal, useSnackbar } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  InputMaybe,
  ScheduleDraftMode_PlanTaskFragment,
  TaskStatus,
  useScheduleDraftModeScheduleFlagReasonsQuery,
} from "src/generated/graphql-types";
import { BoundScheduleFlagReasonSelects } from "../components/BoundScheduleFlagReasonSelects";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { generateClientId } from "./ScheduleDraftModeDelayFlagModal";
import { useCallback } from "react";
import { UserAddedScheduleFlagsInput } from "./scheduleDraftStore";

type ScheduleDraftModeCalendarDelayFlagModalProps = {
  setUserAddedScheduleFlags: (input: UserAddedScheduleFlagsInput[]) => void;
  planTasks: ScheduleDraftMode_PlanTaskFragment[];
};

export function ScheduleDraftModeCalendarDelayFlagModal({
  setUserAddedScheduleFlags,
  planTasks,
}: ScheduleDraftModeCalendarDelayFlagModalProps) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const { data } = useScheduleDraftModeScheduleFlagReasonsQuery();
  const { scheduleFlagReasons } = data || { scheduleFlagReasons: [] };
  const formState = useFormState({ config: formConfig });

  const userAddedScheduleFlagReasons = useComputed(
    () => scheduleFlagReasons.filter((flag) => flag.id === formState.reasonId.value) ?? [],
    [scheduleFlagReasons, formState.reasonId.value],
  );

  const userAddedScheduleFlagTask = useComputed(() => {
    const filteredTasks = planTasks.filter((t) => t.status.code !== TaskStatus.Complete);
    return filteredTasks.find((pt) => pt.id === formState.taskId.value);
  }, [planTasks, formState.taskId.value]);

  const onSubmit = useCallback(() => {
    setUserAddedScheduleFlags([
      // set userAddedScheduleFlags with information to use in the delete modal
      ...userAddedScheduleFlagReasons.map((flag) => ({
        durationInDays: 0,
        reasonId: formState.reasonId.value,
        clientId: formState.taskId.value && generateClientId(formState.taskId.value, formState?.reasonId.value),
        title: flag.title,
        taskId: formState.taskId.value ?? "",
        taskName: userAddedScheduleFlagTask?.name ?? "",
        scheduleFlagReasonType: {
          name: flag.scheduleFlagReasonType.name,
          code: flag.scheduleFlagReasonType.code,
        },
      })),
    ]);
    closeModal();

    triggerNotice({ message: "Delay flag has been added." });
  }, [
    closeModal,
    triggerNotice,
    setUserAddedScheduleFlags,
    userAddedScheduleFlagReasons,
    userAddedScheduleFlagTask,
    formState.reasonId.value,
    formState.taskId.value,
  ]);

  return (
    <Observer>
      {() => (
        <ConfirmationModal
          confirmationMessage={
            <FormLines>
              <div css={Css.sm.$}>Select a task and reason for the delay below.</div>
              <BoundSelectField
                field={formState.taskId}
                label="Task Name"
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                options={planTasks.sortBy((pt) => pt.name)}
                compact
              />
              <div css={Css.dg.gap2.gtc("auto auto").$}>
                <BoundScheduleFlagReasonSelects field={formState.reasonId} scheduleFlagReasons={scheduleFlagReasons} />
              </div>
            </FormLines>
          }
          label="Add Delay"
          disabled={!formState.valid || !formState.reasonId.value}
          onConfirmAction={onSubmit}
          title="Add a Delay"
        />
      )}
    </Observer>
  );
}

type FormConfig = {
  taskId: string | null | undefined;
  reasonId: InputMaybe<string>;
};

const formConfig: ObjectConfig<FormConfig> = {
  taskId: { type: "value", rules: [required] },
  reasonId: { type: "value" },
};
