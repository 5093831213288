import { Button, Css, FilterDefs, Filters, treeFilter, usePersistedFilter } from "@homebound/beam";
import { createMilestoneCatalogFormUrl, createMilestoneCatalogUrl } from "src/RouteUrls";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { MilestoneCatalogTable } from "./MilestoneCatalogTable";
import { SearchBox } from "src/components";
import { useCallback, useMemo, useState } from "react";
import {
  GlobalPlanMilestoneFilter,
  MilestoneCatalogPageMetaDataQuery,
  Order,
  useMilestoneCatalogPageMetaDataQuery,
  useMilestoneCatalogPageQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { formatTreeSelectOptions } from "./MilestoneCatalogForm";

export function MilestoneCatalogPage() {
  const query = useMilestoneCatalogPageMetaDataQuery();

  return queryResult(query, (data) => <MilestoneCatalogPageView data={data} />);
}

type MilestoneCatalogPageViewProps = {
  data: MilestoneCatalogPageMetaDataQuery;
};

export function MilestoneCatalogPageView({ data }: MilestoneCatalogPageViewProps) {
  const [search, setSearch] = useState<string | undefined>();

  const { globalPlanMilestoneGroups } = data ?? {
    globalPlanMilestoneGroups: [],
  };

  const filterDefs: FilterDefs<GlobalPlanMilestoneFilter> = useMemo(() => {
    const treeSelectOptions = formatTreeSelectOptions(globalPlanMilestoneGroups);

    return {
      groups: treeFilter({
        defaultCollapsed: true,
        label: "Groups",
        filterBy: "leaf",
        getOptionLabel: (o) => o.name,
        getOptionValue: (o) => o.id,
        options: treeSelectOptions,
      }),
    };
  }, [globalPlanMilestoneGroups]);

  const { setFilter, filter } = usePersistedFilter<GlobalPlanMilestoneFilter>({
    storageKey: "globalPlanMilestoneFilter",
    filterDefs,
  });

  const query = useMilestoneCatalogPageQuery({
    variables: {
      filter: { name: search, ...filter },
      page: { offset: 0, limit: 100 },
      orderBy: { createdAt: Order.Desc },
    },
  });

  const maybeFetchMore = useCallback(async () => {
    if (query.data?.globalPlanMilestones.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: {
          page: {
            offset: query.data?.globalPlanMilestones.entities.length,
            limit: 100,
          },
        },
      });
    }
  }, [query]);

  return (
    <div>
      <PageHeader
        breadcrumb={{ href: createMilestoneCatalogUrl(), label: "Milestone Catalog" }}
        title={"Milestone Catalog"}
        right={<Button label="Create New" onClick={createMilestoneCatalogFormUrl()} />}
      />
      <TableActions>
        <div css={Css.df.gap1.$}>
          <Filters filter={filter} onChange={setFilter} filterDefs={filterDefs} />
          <SearchBox onSearch={setSearch} placeholder="Search milestones" />
        </div>
        {queryResult(query, ({ globalPlanMilestones }) => (
          <MilestoneCatalogTable globalPlanMilestones={globalPlanMilestones.entities} maybeFetchMore={maybeFetchMore} />
        ))}
      </TableActions>
    </div>
  );
}
