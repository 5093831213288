import { Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { useCallback } from "react";
import { WarrantyTicketItemStatusSelect } from "./WarrantyTicketItemStatusSelect";
import {
  SaveWarrantyTicketItemInput,
  WarrantyTicketItemStatus,
  WarrantyTicketItemStatusesFragment,
} from "src/generated/graphql-types";
import { Observer } from "mobx-react";

export type ChangeStatusModalProps = {
  warrantyTicketItemStatusesDetail: WarrantyTicketItemStatusesFragment[];
  onConfirmAction: (status: WarrantyTicketItemStatus) => void;
};

export function ChangeStatusModal(props: ChangeStatusModalProps) {
  const { warrantyTicketItemStatusesDetail, onConfirmAction } = props;
  const { closeModal } = useModal();
  const formState = useFormState({
    config: formConfig,
  });

  const onConfirmClick = useCallback(() => {
    onConfirmAction(formState.status.value!);
    closeModal();
  }, [onConfirmAction, closeModal, formState.status]);

  return (
    <>
      <ModalHeader>Change Status</ModalHeader>
      <ModalBody>
        <WarrantyTicketItemStatusSelect
          compact={false}
          options={warrantyTicketItemStatusesDetail}
          statusField={formState.status}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button disabled={!formState.status.value} label={"Change"} onClick={onConfirmClick} variant="primary" />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormInput = Pick<SaveWarrantyTicketItemInput, "status">;

const formConfig: ObjectConfig<FormInput> = {
  status: { type: "value" },
};
