import { FetchResult } from "@apollo/client/link/core";
import {
  Button,
  ButtonMenu,
  Css,
  FilterDefs,
  Tooltip,
  useGridTableApi,
  useModal,
  usePersistedFilter,
  useSnackbar,
  useSuperDrawer,
  useTestIds,
  useToast,
} from "@homebound/beam";
import { useCallback, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import {
  ApplyItemTemplateInput,
  EstimateType,
  FeatureFlagType,
  HomeownerSelectionStatus,
  ProjectItemFilter,
  ProjectItemInput,
  SaveProjectItemsMutation,
  SpecsAndSelectionItemsQuery,
  Stage,
  useAddProjectItemMutation,
  useApplyItemTemplateMutation,
  useSaveProjectItemsMutation,
  useSpecsAndSelectionItemsQuery,
  useSpecsAndSelectionsRouteHandlerLazyQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import {
  createSpecsAndSelectionsFilterDef,
  SpecsAndSelectionsFilter,
} from "src/routes/projects/components/SpecsAndSelectionsFilterModal";
import { SpecsAndSelectionsTable } from "src/routes/projects/components/SpecsAndSelectionsTable";
import { ProjectItemsContextProvider, useProjectItemsStore } from "src/routes/projects/context/ProjectItemsContext";
import { CreateEstimateModal } from "src/routes/projects/estimates/CreateEstimateModal";
import { SpecsAndSelectionsRow } from "src/routes/projects/models/ProjectItemStore";
import { AddProjectItemModal, AddSingleItemInput } from "src/routes/projects/selections/AddProjectItemModal";
import { ApplyTemplateModal } from "src/routes/projects/selections/ApplyTemplateModal";
import { SpecsAndSelectionsBulkActionsMenu } from "src/routes/projects/selections/bulkActions/SpecsAndSelectionsBulkActionsMenu";
import { SetupModal } from "src/routes/projects/selections/cutoffs/SetupModal";
import { ImportProjectItemsModal } from "src/routes/projects/selections/ImportProjectItemsModal";
import { SpecsAndSelectionsHeaderTag } from "src/routes/projects/selections/SpecsAndSelectionsHeaderTag";
import { ProjectParams, projectPaths } from "src/routes/routesDef";
import { createFinishScheduleUrl, createProjectSelectionDetailsUrl } from "src/RouteUrls";
import { assertNever } from "src/utils";
import { queryResult } from "src/utils/queryResult";
import { useQueryParams } from "use-query-params";
import { LotConfigurationDetailsStaticField } from "../components/LotConfigurationDetailsStaticField";
import { ManageCutoffsDrawer } from "./cutoffs/ManageCutoffsDrawer";
import { useFeatureFlags } from "src/contexts/FeatureFlags/FeatureFlagContext";

/**
 * Handles routing logic for /specs-and-selections and /specs-and-selections/:projectItemId
 * page where selection projectItems would be shown via the SuperDrawer and all others
 */
export function SpecsAndSelectionsRouteHandler({ stage }: { stage: Stage }) {
  // Matches /:projectId/pre-con-services or /:projectId/specs-and-selections
  const basePathMatch = useRouteMatch<ProjectParams>([projectPaths.preConServices, projectPaths.specsAndSelections]);
  // Matches /:projectId/pre-con-services/:projectItemId or /:projectId/specs-and-selections/:projectItemId
  const projectItemPathMatch = useRouteMatch<ProjectParams & { projectItemId: string }>([
    projectPaths.preConServicesDetail,
    projectPaths.specsAndSelectionsDetail,
  ]);
  // Lazily get the projectItem since projectItemId is not guaranteed to be in the URL
  const [getProjectItem, query] = useSpecsAndSelectionsRouteHandlerLazyQuery({ fetchPolicy: "cache-first" });

  const projectItemId = projectItemPathMatch?.params?.projectItemId;

  // Attempt to query for a projectItem when projectItemId changes.
  useEffect(() => {
    // Prevent triggering a query when query is in loading or has been called.
    if (projectItemId && !query.loading && !query.called) {
      void getProjectItem({ variables: { projectItemId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectItemId]);

  // Pass through when going to /specs-and-selections or when we don't have data
  // to determine which page to render.
  if (basePathMatch?.isExact || !query.data || query.loading) {
    return <SpecsAndSelectionsPage stage={stage} />;
  }

  // Handles when query.data is stale
  if (projectItemId && query.data.projectItem.id !== projectItemId) {
    void getProjectItem({ variables: { projectItemId } });
    return <SpecsAndSelectionsPage stage={stage} />;
  }

  const isSelection = !!query.data.projectItem.homeownerSelection;
  if (isSelection) return <SpecsAndSelectionsPage stage={stage} />;
  return <SpecsAndSelectionsPage stage={stage} />;
}

export function SpecsAndSelectionsPage({ stage }: { stage: Stage }) {
  const { projectId } = useParams<ProjectParams>();

  const [filterSet, setFilterSet] = useState(false);
  const [filterDefs, setFilterDefs] = useState<FilterDefs<SpecsAndSelectionsFilter>>(() => {
    // Even though we don't know the cost codes/locations yet, make an initial filter def
    // so that usePersistedFilter can validate any url/session storage values.
    return createSpecsAndSelectionsFilterDef(stage, [], []);
  });
  const { filter: uiFilter, setFilter } = usePersistedFilter({
    filterDefs,
    storageKey: "projectItemFilter",
  });

  const gqlFilter = toGqlFilter(uiFilter);
  const query = useSpecsAndSelectionItemsQuery({
    variables: { projectId, stage, filter: gqlFilter },
    fetchPolicy: "cache-and-network",
    // We use nextFetchPolicy to avoid ProjectItemStore (and its observers) being re-initialized after auto-save.
    // This is fine b/c we assume the ObservableProjectItems are update to anyway. We could remove this if the
    // `initialize` call upserted the store instead of just fully re-creating it.
    nextFetchPolicy: "standby",
  });

  // We can't create filterDefs until the 1st query comes back with the metadata; in theory
  // we should probably break these out into separate GQL queries so we're not re-fetching the
  // metadata each time they change a filter.
  if (!filterSet && query.data) {
    setFilterDefs(createSpecsAndSelectionsFilterDef(stage, query.data.costCodes, query.data.locations));
    setFilterSet(true);
  }

  const { closeDrawer, isDrawerOpen } = useSuperDrawer();
  /**
   * When un-mounting this page, close the Superdrawer.
   * This is to prevent having a SuperDrawer stay open when navigating multiple
   * pages back/forward as the SuperDrawer is outside the ReactRouter scope.
   */
  useEffect(() => {
    return function unmountSpecsAndSelectionsPage() {
      isDrawerOpen && closeDrawer();
    };
  }, [closeDrawer, isDrawerOpen]);

  return queryResult(query, (data) => (
    <ProjectItemsContextProvider>
      <SpecsAndSelectionsPageContent
        data={data}
        projectId={projectId}
        stage={stage}
        filterDefs={filterDefs}
        uiFilter={uiFilter}
        gqlFilter={gqlFilter}
        setFilter={setFilter}
      />
    </ProjectItemsContextProvider>
  ));
}

type SpecsAndSelectionsPageContentProps = {
  data: SpecsAndSelectionItemsQuery;
  projectId: string;
  stage: Stage;
  filterDefs: FilterDefs<SpecsAndSelectionsFilter>;
  uiFilter: SpecsAndSelectionsFilter;
  gqlFilter: ProjectItemFilter;
  setFilter: (filter: SpecsAndSelectionsFilter) => void;
};

function SpecsAndSelectionsPageContent(props: SpecsAndSelectionsPageContentProps) {
  const { data, projectId, stage, filterDefs, uiFilter, gqlFilter, setFilter } = props;
  const { project, unitsOfMeasure, locations, scheduleTasks } = data;
  const { projectItems, id: projectStageId, hasSignedContract, canEditProjectItemsDirectly } = project.stage;
  const api = useGridTableApi<SpecsAndSelectionsRow>();
  const { store } = useProjectItemsStore();
  const [saveProjectItems] = useSaveProjectItemsMutation();
  const [applyItemTemplate] = useApplyItemTemplateMutation();
  const [addProjectItem] = useAddProjectItemMutation();
  const [, setQueryParams] = useQueryParams({});
  const { openModal } = useModal();
  const { openInDrawer } = useSuperDrawer();
  const { showToast } = useToast();
  const { triggerNotice } = useSnackbar();
  const tid = useTestIds({});
  // Use plan or legacy schedule tasks based on the project configuration
  const tasks = project.enableProductConfigPlan
    ? project.planSchedule?.tasks.map((t) => ({ ...t, status: t.status.code }))
    : scheduleTasks;

  const onSave = useCallback(
    async (...inputs: ProjectItemInput[]) => {
      const result = await saveProjectItems({ variables: { input: { projectItems: [...inputs] } } });
      const userErrors = result.data?.saveProjectItems?.userErrors;
      const errorText = userErrors && userErrors.map((e) => e.message).join(" ");
      if (errorText) {
        console.error(errorText);
        triggerNotice({ message: errorText, icon: "error" });
      }
      return !Boolean(errorText);
      // TODO: This should update the local store, not refetch the entire thing...
    },
    [saveProjectItems, triggerNotice],
  );

  const createProjectItemUrl = useCallback(
    (projectItemId: string) => createProjectSelectionDetailsUrl(projectId, stage, projectItemId),
    [projectId, stage],
  );

  const { featureIsEnabled } = useFeatureFlags();
  const digitalBuildingExperimentsEnabled = featureIsEnabled(FeatureFlagType.DigitalBuildingExperiments);

  async function handleAddItem(addItemInput: AddSingleItemInput): Promise<void> {
    const result = await addProjectItem({
      variables: { input: { projectStageId, ...addItemInput }, filter: gqlFilter },
    });
    await handleSaveProjectItems(result);
  }

  // Called by both handleAddItem as well as ImportProjectItemsModal
  async function handleSaveProjectItems(result: FetchResult<SaveProjectItemsMutation>) {
    const userErrors = result.data?.saveProjectItems?.userErrors;
    if (userErrors) {
      showToast({ type: "error", message: userErrors.map((e) => e.message).join(", ") });
      console.error(userErrors);
    }
    const items = result.data?.saveProjectItems.projectItems;
    if (items) {
      store.addProjectItems(items);
      setQueryParams({ projectItemId: items[0].id }, "replaceIn");
    }
  }

  async function onApplyTemplate(input: ApplyItemTemplateInput) {
    const result = await applyItemTemplate({
      variables: { input, filter: gqlFilter },
    });
    const userErrors = result.data?.applyItemTemplate?.userErrors;
    if (userErrors) {
      console.error(userErrors);
    }
    const items = result.data?.applyItemTemplate.projectItems;
    if (items) {
      store.addProjectItems(items);
      setQueryParams({ projectItemId: items[0]?.id }, "replaceIn");
    }
  }

  const hasCutoffWithAssignedTask = project.cutoffs?.some((pc) => pc.task);
  const hasProjectItemsAdded = projectItems.length !== 0;

  // The overflowHidden styles in this file are to prevent the scrollable container in ProjectLayout from being displayed here.
  // The flex styles allow for the contents of this element to take up the remainder of the space allotted by the h100 defined in PageLayout.
  return (
    <div {...tid.specsAndSelectionsPage}>
      <PageHeader
        xss={Css.bgWhite.$}
        title={stage === Stage.PreConExecution ? "Pre-Con Services" : "Specs & Selections"}
        left={hasSignedContract !== undefined && <SpecsAndSelectionsHeaderTag hasSignedContract={hasSignedContract} />}
        right={
          <>
            {digitalBuildingExperimentsEnabled && (
              <Button label="Finish Schedule 🧪" onClick={createFinishScheduleUrl(projectStageId)} variant="tertiary" />
            )}
            {project.cutoffs.length > 0 &&
              stage === Stage.Construction &&
              (hasCutoffWithAssignedTask ? (
                <Button
                  label="Manage Cutoffs"
                  onClick={() => {
                    openInDrawer({
                      content: <ManageCutoffsDrawer projectId={project.id} projectStageId={projectStageId} />,
                      onClose: () => {},
                    });
                  }}
                  variant="tertiary"
                />
              ) : (
                <Button
                  label="Set Up Cutoffs"
                  onClick={() => {
                    openModal({ content: <SetupModal projectId={project.id} /> });
                  }}
                  variant="tertiary"
                />
              ))}
            <>
              <Tooltip
                title="Project stage has a signed contract or an estimate already exists"
                disabled={canEditProjectItemsDirectly}
                placement="left"
              >
                <ButtonMenu
                  disabled={!canEditProjectItemsDirectly}
                  items={[
                    {
                      label: "From Template",
                      onClick: () => {
                        openModal({
                          content: (
                            <ApplyTemplateModal
                              projectStageId={project.stage.id}
                              onApplyTemplate={onApplyTemplate}
                              stage={stage}
                            />
                          ),
                        });
                      },
                    },
                    {
                      label: "From Import",
                      onClick: () => {
                        openModal({
                          content: (
                            <ImportProjectItemsModal
                              onImport={handleSaveProjectItems}
                              projectStageId={project.stage.id}
                            />
                          ),
                        });
                      },
                    },
                    {
                      label: "New",
                      onClick: () => {
                        openModal({ content: <AddProjectItemModal onAdd={handleAddItem} /> });
                      },
                    },
                  ]}
                  trigger={{ label: "Add Items" }}
                />
              </Tooltip>
              <Tooltip
                title="Project stage has a signed contract, an estimate already exists or there are no project items added"
                disabled={hasProjectItemsAdded && canEditProjectItemsDirectly}
                placement="left"
              >
                <Button
                  label="Create Estimate"
                  disabled={!hasProjectItemsAdded || !canEditProjectItemsDirectly}
                  onClick={() => {
                    openModal({
                      content: (
                        <CreateEstimateModal
                          initialFormState={{
                            title: `${
                              project.stage.stage.code === Stage.Construction ? "Construction" : "Pre-Construction"
                            } Estimate`,
                            type: EstimateType.Final,
                          }}
                          projectId={projectId}
                          projectStage={project.stage}
                        />
                      ),
                    });
                  }}
                />
              </Tooltip>
            </>
          </>
        }
      />

      <div css={Css.oh.$}>
        <div css={Css.df.gap6.mb4.$}>
          {project.stage.readyHomePlanTemplate && (
            <LotConfigurationDetailsStaticField projectId={project.id} data-testid="planInfo" />
          )}
        </div>
        <SpecsAndSelectionsTable
          bulkActionsMenu={
            <SpecsAndSelectionsBulkActionsMenu
              canEditProjectItemsDirectly={canEditProjectItemsDirectly}
              hasSignedContract={hasSignedContract}
              onSave={onSave}
              projectId={project.id}
              projectStageId={project.stage.id}
              scheduleTasks={tasks || []}
              store={store}
              api={api}
            />
          }
          createProjectItemUrl={createProjectItemUrl}
          locations={locations}
          mode={canEditProjectItemsDirectly ? "S&S" : "S&S-PartialReadOnly"}
          onSave={onSave}
          projectItems={projectItems}
          filterDefs={filterDefs}
          uiFilter={uiFilter}
          setFilter={setFilter}
          stage={stage}
          scheduleTasks={tasks || []}
          unitsOfMeasure={unitsOfMeasure}
          api={api}
        />
      </div>
    </div>
  );
}

/** Strip off the "not actually filter" `view`, and adjust `locationId`, before sending the actual filter to the backend. */
export function toGqlFilter(uiFilter: SpecsAndSelectionsFilter): ProjectItemFilter {
  const { view, selectionStatus, locationId, unallocatedCosts, ...rest } = uiFilter;
  const gqlFilter: ProjectItemFilter = { isRemovedScope: false, ...rest };

  if (locationId) {
    gqlFilter.location = { id: locationId };
  }

  // Some kind of boilerplate code to decode our psuedo-selectionStatus to the real ones
  if (selectionStatus) {
    for (const status of selectionStatus) {
      switch (status) {
        case "Finalized":
          (gqlFilter.selectionStatus ??= []).push(HomeownerSelectionStatus.Finalized);
          break;
        case "Selected":
          (gqlFilter.selectionStatus ??= []).push(HomeownerSelectionStatus.Selected);
          break;
        case "Undecided":
          (gqlFilter.selectionStatus ??= []).push(HomeownerSelectionStatus.Undecided);
          break;
        default:
          assertNever(status);
      }
    }
  }

  return gqlFilter;
}
