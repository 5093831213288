import { Css, GridColumn, GridDataRow, RowStyles, simpleDataRows, SimpleHeaderAndData } from "@homebound/beam";
import { Fragment, useMemo } from "react";
import { priceCell, QueryTable } from "src/components";
import { PdfProjectItemFragment, PdfProjectItemsQueryResult } from "src/generated/graphql-types";
import { partition } from "src/utils";
import { queryResult } from "src/utils/queryResult";
import { ProjectItemDetails } from "./components/ProjectItemDetails";
import { usePdfProjectItemsQueryWithQueryParams } from "./hooks/usePdfProjectItemsQueryWithQueryParams";

export function ScheduleOfValuesPdf() {
  const query = usePdfProjectItemsQueryWithQueryParams();
  const rowStyles = useMemo(() => createRowStyles(), []);

  return queryResult(query, {
    data: ({ projectItems }) => {
      const [specifications, selections] = partition(
        (projectItems ?? []).filter((pi) => pi.protoCommitmentLineItemToBuyout),
        (pi) => !pi.homeownerSelection,
      );
      return (
        <Fragment>
          <h1 css={Css.gray800.fwb.xl2Sb.m0.mb2.$}>Schedule of Values</h1>
          <div css={Css.bt.bb.br.bcGray400.mb5.$}>
            <QueryTable query={query} createRows={createRows} columns={columns} rowStyles={rowStyles} />
          </div>
          <ProjectItemDetails
            id="specificationProjectItems"
            projectItems={specifications}
            title="Scope of Work - Specifications"
          />
          <ProjectItemDetails
            id="selectionProjectItems"
            projectItems={selections}
            title="Scope of Work - Product Selections"
          />
        </Fragment>
      );
    },
  });
}

type Row = SimpleHeaderAndData<PdfProjectItemFragment>;

// Headers need to be wrapped in a span to avoid default styling applied to simple strings in `maybeAddHeaderStyling`
const h = (header: string) => () => <span>{header}</span>;
const columns: GridColumn<Row>[] = [
  { header: h("Item"), data: ({ displayName }) => displayName },
  {
    header: h("Product"),
    data: ({ homeownerSelection }) =>
      homeownerSelection ? homeownerSelection.selectedOption?.product.name ?? "N/A (No Selection)" : "N/A (Spec)",
  },
  {
    header: h("Quantity"),
    data: ({ protoCommitmentLineItemToBuyout: li, unitOfMeasure }) =>
      `${li?.quantity ?? ""} ${unitOfMeasure?.name ?? ""}`,
  },
  {
    header: h("Unit Cost"),
    data: ({ totalCostInCents, quantity }) =>
      priceCell({ valueInCents: quantity ? totalCostInCents / quantity : quantity }),
  },
  {
    header: h("Total"),
    data: ({ quantity, totalCostInCents, protoCommitmentLineItemToBuyout: li }) =>
      priceCell({ valueInCents: (quantity ? totalCostInCents / quantity : totalCostInCents) * (li?.quantity ?? 0) }),
  },
];

function createRowStyles(): RowStyles<Row> {
  return {
    header: { cellCss: Css.sm.jcfs.bl.bcGray400.gray800.fwb.$ },
    data: { cellCss: Css.bl.bcGray400.gray800.jcfs.$ },
  };
}

function createRows(data?: PdfProjectItemsQueryResult["data"]): GridDataRow<Row>[] {
  return simpleDataRows(data?.projectItems);
}
