import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  AddEditItemModal_GlobalPlanTaskFragment,
  CostType,
  useAddEditItemModal_CreateNewLocationMutation,
} from "src/generated/graphql-types";
import { useCallback } from "react";
import {
  AddEditItemModalDataViewProps,
  AddEditItemObjectState,
  BoundTaskSelectField,
  CommonFields,
  ItivData,
  OnTaskSelect,
  PublishedTakeoffBanner,
  saveAddEditItem,
  useAddEditItemModalFormState,
  withGetItivQuery,
} from "./utils";
import { AddEditItemLocationsField } from "./";

export const AddEditLaborItemModal = withGetItivQuery(AddEditLaborItemModalDataView);

function AddEditLaborItemModalDataView(props: AddEditItemModalDataViewProps) {
  const { itemTemplateId, initialItivData, takeoffsManagerStore } = props;
  const [saveLocation] = useAddEditItemModal_CreateNewLocationMutation();

  const { saveItiv, itemTemplate } = takeoffsManagerStore.getState();
  const { closeModal, addCanClose } = useModal();
  const isEditing = !!initialItivData?.id;
  const readOnly = itemTemplate.canEditLineItems.allowed === false;

  const formState = useAddEditItemModalFormState({
    initialItivData,
    readOnly,
    costType: CostType.Labor,
  });

  addCanClose(() => {
    // eslint-disable-next-line no-restricted-globals
    return formState.dirty ? confirm("You have unsaved changes. Are you sure you want to close?") : true;
  });

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>
            <span css={Css.lgSb.$}>{readOnly ? "Labor Details" : isEditing ? "Edit Labor" : "Add Labor"}</span>
          </ModalHeader>
          <ModalBody>
            {readOnly && <PublishedTakeoffBanner />}
            <div css={Css.df.fdc.gap2.pb3.$}>
              <LaborFields
                itemTemplateId={itemTemplateId}
                initialItivData={initialItivData}
                readOnly={readOnly}
                formState={formState}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            {readOnly ? (
              <Button label="Close" onClick={closeModal} />
            ) : (
              <>
                <Button variant="tertiary" label="Cancel" onClick={closeModal} size="lg" />
                <Button
                  label="Save"
                  data-testid="saveBtn"
                  disabled={!formState.valid}
                  onClick={async () => {
                    await saveAddEditItem({
                      formState,
                      itemTemplateId,
                      saveItiv,
                      saveLocation,
                    });
                    closeModal();
                  }}
                  size="lg"
                />
              </>
            )}
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

function LaborFields({
  itemTemplateId,
  initialItivData,
  readOnly,
  formState,
}: {
  itemTemplateId: string;
  initialItivData?: ItivData;
  readOnly: boolean;
  formState: ObjectState<AddEditItemObjectState>;
}) {
  // When a material cost type is selected then...
  const onTaskSelect: OnTaskSelect = useCallback(
    (id: string | undefined, selectedTask: AddEditItemModal_GlobalPlanTaskFragment | undefined) => {
      // Set the task on the line
      formState.taskId.set(id);
      // Someday: Default the item UOM
      // Default the item
      if (!formState.itemId.value) {
        formState.itemId.set(selectedTask?.budgetItem?.id);
      }
    },
    [formState],
  );

  return (
    <>
      <BoundTaskSelectField
        required
        selectedTask={initialItivData?.task}
        readOnly={readOnly}
        formState={formState}
        onSelect={onTaskSelect}
      />
      <AddEditItemLocationsField
        newLocationNameField={formState.newLocationName}
        field={formState.locationId}
        onSelect={(locationId: string | undefined, location) => {
          formState.locationId.set(locationId);
          formState.location.set(location);
        }}
      />
      <CommonFields itemTemplateId={itemTemplateId} readOnly={readOnly} formState={formState} />
    </>
  );
}
