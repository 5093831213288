import { Css, LoadingSkeleton, useTestIds } from "@homebound/beam";
import { useElevationDetailsQuery } from "src/generated/graphql-types";
import { pluralize, queryResult } from "src/utils";
import { camelCase } from "change-case";

type ElevationCardProps = {
  elevationId: string;
  showOptions: boolean;
};

export function ElevationDetails({ elevationId, showOptions }: ElevationCardProps) {
  const query = useElevationDetailsQuery({ variables: { id: elevationId } });
  const tid = useTestIds({}, "elevationDetails");

  return queryResult(query, {
    data: ({ readyPlanOption: { programData, assets } }) => {
      const coverImage = assets.find((a) => a.isCoverPhoto);
      return (
        <div css={Css.df.fdc.gap3.mt2.$}>
          {coverImage && coverImage.asset.previewUrl && (
            <img src={coverImage.asset.previewUrl} alt="Elevation" css={Css.hPx(112).wPx(206).objectCover.$} />
          )}
          <div css={Css.df.gap6.jcsb.$}>
            <div css={Css.f("1 1 50%").$}>
              <h4 css={Css.baseSb.mb2.$}>Program Data</h4>
              <ul css={Css.listReset.df.fdc.gapPx(4).$}>
                {getLine(tid, "Stories", programData.stories, pluralize(programData.stories ?? 0, "story", "stories"))}
                {getLine(tid, "Total bedrooms", programData.bedrooms, pluralize(programData.bedrooms ?? 0, "bedroom"))}
                {getLine(
                  tid,
                  "First floor bedrooms",
                  programData.firstFloorBedrooms,
                  pluralize(programData.firstFloorBedrooms ?? 0, "bedroom"),
                )}
                {getLine(tid, "Primary bedroom location", programData.primaryBedroom?.name)}
                {getLine(
                  tid,
                  "Closets in Primary Suite",
                  programData.closetsInPrimarySuite,
                  pluralize(programData.closetsInPrimarySuite ?? 0, "closet"),
                )}
                {getLine(tid, "Full Bathrooms", programData.fullBaths, pluralize(programData.fullBaths ?? 0, "bath"))}
                {getLine(tid, "Half Bathrooms", programData.halfBaths, pluralize(programData.halfBaths ?? 0, "bath"))}
                {getLine(
                  tid,
                  "Formal Dining",
                  programData.diningRoom,
                  pluralize(programData.diningRoom ?? 0, "formal dining"),
                )}
                {getLine(
                  tid,
                  "Casual Dining",
                  programData.casualDining,
                  pluralize(programData.casualDining ?? 0, "causual dining"),
                )}
                {getLine(
                  tid,
                  "Media Rooms",
                  programData.mediaRooms,
                  pluralize(programData.mediaRooms ?? 0, "media room"),
                )}
                {getLine(
                  tid,
                  "Lofts/Game/Flex Rooms",
                  programData.loftGameFlexRooms,
                  pluralize(programData.loftGameFlexRooms ?? 0, "flex room"),
                )}
                {getLine(tid, "Offices", programData.offices, pluralize(programData.offices ?? 0, "office"))}
                {getLine(
                  tid,
                  "Workspaces",
                  programData.workspaces,
                  pluralize(programData.workspaces ?? 0, "workspace"),
                )}
                {getLine(tid, "Basement Type", programData.basementConfig?.name)}
                {getLine(tid, "Garage Load", programData.garageConfiguration?.name)}
                {getLine(
                  tid,
                  "Attached Garage",
                  programData.garageAttached,
                  pluralize(programData.garageAttached ?? 0, "car"),
                )}
                {getLine(
                  tid,
                  "Detached garage",
                  programData.garageDetached,
                  pluralize(programData.garageDetached ?? 0, "car"),
                )}
                {getLine(tid, "Carport", programData.garagePort, pluralize(programData.garagePort ?? 0, "car"))}
              </ul>
            </div>
            <div css={Css.f("1 1 50%").$}>
              <h4 css={Css.baseSb.mb2.$}>Square Footage</h4>
              <ul css={Css.listReset.df.fdc.gapPx(4).$}>
                {getLine(tid, "Plan Depth FT", programData.depthInFeet, "ft")}
                {getLine(tid, "Plan Width FT", programData.widthInFeet, "ft")}
                {getLine(tid, "Lot Min Size SF", programData.minLotSizeInSqft, "sqft")}
                {getLine(tid, "Lot Min Depth FT", programData.minLotDepthInFeet, "ft")}
                {getLine(tid, "Lot Min Width FT", programData.minLotWidthInFeet, "ft")}
                {getLine(tid, "Building Height FT", programData.buildingHeightInFeet, "ft")}
                {getLine(tid, "Net Buildable SF", programData.netBuildableSqft, "sqft")}
                {getLine(tid, "Gross Buildable SF", programData.grossBuildableSqft, "sqft")}
                {getLine(tid, "Permitable SF", programData.permittableSqft, "sqft")}
                {getLine(tid, "Sellable Above Ground SF", programData.sellableAboveGroundSqft, "sqft")}
                {getLine(tid, "Gross Below Ground SF", programData.grossBelowGroundSqft, "sqft")}
                {getLine(tid, "Sellable Below Ground SF", programData.sellableBelowGroundSqft, "sqft")}
                {getLine(tid, "Impervious SF", programData.imperviousSqft, "sqft")}
                {getLine(tid, "Sellable SF", programData.sellableSqft, "sqft")}
                {getLine(tid, "Unfinished Below Ground SF", programData.unfinishedBelowGroundSqft, "sqft")}
              </ul>
            </div>
          </div>
        </div>
      );
    },
    loading: () => <LoadingSkeleton rows={10} size="sm" columns={2} />,
  });
}

function getLine(
  tid: Record<string, object>,
  title: string,
  value: string | number | null | undefined,
  suffix?: string,
) {
  return (
    <li css={Css.df.jcsb.gap2.$} {...tid[camelCase(title)]}>
      <span css={Css.xs.gray700.truncate.$}>{title}</span>
      <span css={Css.xsSb.gray900.tar.$}>{value ? `${value} ${suffix ?? ""}` : "-"}</span>
    </li>
  );
}
