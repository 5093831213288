import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useRef } from "react";
import {
  CopyItemTemplateItemsInput,
  ItemTemplateItemVersionFilter,
  ItivOrderField,
  Order,
  PlanPackageTakeoffPage_TakeoffDetailFragment,
  PlanPackageTakeoffTable_GroupTotalsFragment,
  PlanPackageTakeoffTable_GroupTotalsFragmentDoc,
  PlanPackageTakeoffTable_ItemsFragment,
  SaveItemTemplateItemVersionInput,
  TakeoffsStore_CopyItemTemplateItemsDocument,
  TakeoffsStore_CopyItemTemplateItemsMutation,
  TakeoffsStore_CopyItemTemplateItemsMutationVariables,
  TakeoffsStore_SaveItemTemplateItemVersionsDocument,
  TakeoffsStore_SaveItemTemplateItemVersionsMutation,
  TakeoffsStore_SaveItemTemplateItemVersionsMutationVariables,
  useTakeoffsStore_CopyItemTemplateItemsMutation,
  useTakeoffsStore_SaveItemTemplateItemVersionsMutation,
} from "src/generated/graphql-types";
import { isItivOrderField } from "src/routes/libraries/plan-package/takeoffs/utils";
import { z } from "zod";
import { ItemTemplateItemRows } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsTable/PlanPackageTakeoffTable";
import { Button, Css, GridTableApi, useGridTableApi, useSnackbar } from "@homebound/beam";
import { createStore, StoreApi, useStore } from "zustand";
import useZodQueryString from "src/hooks/useZodQueryString";
import { FetchResult } from "@apollo/client/link/core";
import { ApolloCache, MutationTuple } from "@apollo/client";
import { UseSnackbarHook } from "@homebound/beam/dist/components/Snackbar/useSnackbar";
import { mergeEntities, pluralize, sortObjectKeys } from "src/utils";
import { toServerFilter } from "src/utils/itemTemplateItem";

export type TakeoffsStoreState = {
  itemTemplate: PlanPackageTakeoffPage_TakeoffDetailFragment;
  filter: ItemTemplateItemVersionFilter;
  setFilter: Dispatch<SetStateAction<ItemTemplateItemVersionFilter>>;
  setItemTableSearch: (search: string) => void;
  itemTableGroupBy: ItivOrderField;
  setItemTableGroupBy: (groupBy: ItivOrderField) => void;
  itemTableSortBy: ItivOrderField;
  setItemTableSortBy: (sortBy: ItivOrderField) => void;
  itemTableApi: GridTableApi<ItemTemplateItemRows> | undefined;
  saveItiv: (item: SaveItemTemplateItemVersionInput | SaveItemTemplateItemVersionInput[]) => SaveItivResult;
  copyFromTemplate: (
    input: CopyItemTemplateItemsInput,
  ) => Promise<FetchResult<TakeoffsStore_CopyItemTemplateItemsMutation>>;
  getSelectedItivIds: () => string[];
  refetchFilters?: () => void;
};

export const TakeoffsManagerContext = createContext<StoreApi<TakeoffsStoreState> | null>(null);

export type TakeoffsManagerProviderProps = {
  itemTemplate: PlanPackageTakeoffPage_TakeoffDetailFragment;
  children: ReactNode;
};

export function TakeoffsManagerProvider({ itemTemplate, children }: TakeoffsManagerProviderProps) {
  const storeRef = useRef<StoreApi<TakeoffsStoreState>>();
  const [{ groupBy: initialGroupBy, sortBy: initialSortBy, filter: initialFilter }, setQs] =
    useZodQueryString(takeoffsQuerySchema);
  const itemTableApi = useGridTableApi<ItemTemplateItemRows>();
  const { triggerNotice, closeNotice } = useSnackbar();
  const [saveItivs] = useTakeoffsStore_SaveItemTemplateItemVersionsMutation();
  const [copyFromTemplate] = useTakeoffsStore_CopyItemTemplateItemsMutation();

  if (!storeRef.current || storeRef.current.getState().itemTemplate !== itemTemplate) {
    itemTableApi.clearSelections();
    storeRef.current = createTakeoffsStore({
      initialGroupBy,
      initialSortBy,
      initialFilter,
      setQs,
      itemTableApi,
      saveItivs,
      copyFromTemplate,
      triggerNotice,
      closeNotice,
      itemTemplate,
    });
  }

  return <TakeoffsManagerContext.Provider value={storeRef.current}>{children}</TakeoffsManagerContext.Provider>;
}

// Returns the store for the takeoffs manager context for instances where reactivity is not needed
export function useTakeoffsManagerContext() {
  const store = useContext(TakeoffsManagerContext);
  if (!store) {
    throw new Error("Missing TakeoffsManagerProvider");
  }
  return store;
}

export function useTakeoffsStore<T>(selector: (state: TakeoffsStoreState) => T) {
  const store = useContext(TakeoffsManagerContext);
  if (!store) {
    throw new Error("Missing TakeoffsManagerProvider");
  }
  return useStore(store, selector);
}

type CreateTakeoffsStoreProps = {
  itemTemplate: PlanPackageTakeoffPage_TakeoffDetailFragment;
  initialGroupBy?: string;
  initialSortBy?: string;
  initialFilter?: string;
  setQs?: Function;
  itemTableApi: GridTableApi<ItemTemplateItemRows>;
  saveItivs: MutationTuple<
    TakeoffsStore_SaveItemTemplateItemVersionsMutation,
    TakeoffsStore_SaveItemTemplateItemVersionsMutationVariables
  >[0];
  copyFromTemplate: MutationTuple<
    TakeoffsStore_CopyItemTemplateItemsMutation,
    TakeoffsStore_CopyItemTemplateItemsMutationVariables
  >[0];
  triggerNotice?: UseSnackbarHook["triggerNotice"];
  closeNotice?: UseSnackbarHook["closeNotice"];
};

// Create a store for the takeoffs manager context
// Exported to simplifying testing
export function createTakeoffsStore(props: CreateTakeoffsStoreProps) {
  const {
    itemTemplate,
    initialGroupBy,
    initialSortBy,
    initialFilter,
    setQs,
    itemTableApi,
    saveItivs,
    copyFromTemplate,
    triggerNotice,
    closeNotice,
  } = props;

  const store = createStore<TakeoffsStoreState>((set, get) => ({
    // Initial Values
    filter: initialFilter ? JSON.parse(initialFilter) : {},
    itemTableGroupBy: initialGroupBy && isItivOrderField(initialGroupBy) ? initialGroupBy : ItivOrderField.CostCode,
    itemTableSortBy: initialSortBy && isItivOrderField(initialSortBy) ? initialSortBy : ItivOrderField.Item,
    itemTableApi,
    itemTemplate,

    getSelectedItivIds: () =>
      get()
        .itemTableApi?.getSelectedRows()
        .flatMap((row) => {
          switch (row.kind) {
            case "item":
              return row.data.id;
            case "groupBy":
              return row.data.itivIdsInGroup;
            default:
              return [];
          }
        })
        .unique()
        .compact() ?? [],

    // Actions
    setFilter: (maybeFunction: Function | ItemTemplateItemVersionFilter) =>
      set((state) => {
        const filterValue = typeof maybeFunction === "function" ? maybeFunction(state.filter) : maybeFunction;
        setQs && setQs({ filter: JSON.stringify(filterValue) });
        return {
          filter: filterValue,
        };
      }),
    setItemTableGroupBy: (groupBy: ItivOrderField) => {
      setQs && setQs({ groupBy, sortBy: get().itemTableSortBy });
      set({ itemTableGroupBy: groupBy });
    },

    setItemTableSortBy: (sortBy: ItivOrderField) => {
      setQs && setQs({ sortBy, groupBy: get().itemTableGroupBy });
      set({ itemTableSortBy: sortBy });
    },

    setItemTableSearch: (search: string) =>
      set((state) => {
        // Remove search and don't set a search value it is empty - avoids unnecessary API requests when going from `undefined` to ""
        const { search: oldSearchValue, ...otherFilters } = state.filter;
        return { filter: { ...otherFilters, ...(search ? { search } : {}) } };
      }),

    saveItiv: async (item: SaveItemTemplateItemVersionInput | SaveItemTemplateItemVersionInput[]) => {
      return saveItivImpl(item);
    },

    copyFromTemplate: async (
      input: CopyItemTemplateItemsInput,
    ): Promise<FetchResult<TakeoffsStore_CopyItemTemplateItemsMutation>> => {
      return copyFromTemplateImpl(input);
    },
  }));

  /** Private properties and methods */
  function getCacheQualifier() {
    return JSON.stringify({
      filter: sortObjectKeys(toServerFilter(itemTemplate.id, store.getState().filter)),
      order: [
        { direction: Order.Asc, field: store.getState().itemTableGroupBy },
        { direction: Order.Asc, field: store.getState().itemTableSortBy },
      ],
    });
  }

  async function copyFromTemplateImpl(
    input: CopyItemTemplateItemsInput,
  ): Promise<FetchResult<TakeoffsStore_CopyItemTemplateItemsMutation>> {
    return await copyFromTemplate({
      mutation: TakeoffsStore_CopyItemTemplateItemsDocument,
      variables: { input },
      update: (cache, { data }) => {
        addItemsToCache(cache, data?.copyItemTemplateItems?.copiedItemVersions ?? []);
      },
    });
  }

  async function saveItivImpl(
    input: SaveItemTemplateItemVersionInput | SaveItemTemplateItemVersionInput[],
  ): SaveItivResult {
    // TODO: Hook up `includeRemoved` and `highlightChanges`
    const includeRemoved = false;
    const requestInput = Array.isArray(input) ? input : [input];
    const result = await saveItivs({
      mutation: TakeoffsStore_SaveItemTemplateItemVersionsDocument,
      variables: {
        items: requestInput.map((itiInput) => ({ templateId: itemTemplate.id, ...itiInput })),
        filter: { template: [store.getState().itemTemplate.id], excludeRemoved: true, ...store.getState().filter },
        order: [
          { field: store.getState().itemTableGroupBy, direction: Order.Asc },
          { field: store.getState().itemTableSortBy, direction: Order.Asc },
        ],
      },
      update: (cache, { data }) => {
        const {
          deleted = [],
          removed = [],
          itemTemplateItemVersions = [],
          autoAddedLaborLines = [],
          groupTotals = [],
        } = data?.saveItemTemplateItemVersions ?? {};

        if (deleted.length > 0) {
          itemTableApi.deleteRows(deleted);
        }

        // show notice if any itiv was added that doesn't match filter
        // fail here is just safety, with the current BE implementation this should never happen
        const templateGt = groupTotals.find((gt) => gt.groupId === "template") ?? fail("No template totals found");
        const savedItivIds = itemTemplateItemVersions.map((itiv) => itiv.id);
        if (!savedItivIds.every((savedItivId) => templateGt.itivIdsInGroup.includes(savedItivId))) {
          triggerNotice?.({
            // putting the action in the message instead of using the action prop
            // to put it more inline
            message: (
              <>
                <div css={Css.dif.$}>
                  {`Success! Your item was added, but not showing due to filters. To see your item `}
                </div>
                <Button
                  label="clear filters"
                  variant="tertiary"
                  onClick={() => {
                    store.getState().setFilter({});
                    closeNotice?.("itiv-add-filter-notice");
                  }}
                />
              </>
            ),
            icon: "success",
            id: "itiv-add-filter-notice",
          });
        }

        addItemsToCache(cache, [...itemTemplateItemVersions, ...autoAddedLaborLines], groupTotals);
      },
    });

    const autoAddedLaborLines = result.data?.saveItemTemplateItemVersions.autoAddedLaborLines ?? [];
    if (autoAddedLaborLines.nonEmpty) {
      // Let the user know when labor lines are added automatically
      triggerNotice?.({
        message: `${autoAddedLaborLines.length} labor ${pluralize(
          autoAddedLaborLines.length,
          "line",
        )} automatically added due to new materials.`,
      });
    }

    return result;
  }

  function addItemsToCache(
    cache: ApolloCache<any>,
    newItems: PlanPackageTakeoffTable_ItemsFragment[],
    groupTotalsResult?: PlanPackageTakeoffTable_GroupTotalsFragment[],
  ) {
    cache.modify({
      fields: {
        [`itemTemplateItemVersionsPage:${getCacheQualifier()}`]: (
          existingResult: any, // Modifiers<ItemTemplateItemVersionsPageQuery["itemTemplateItemVersionsPage"]>, //, TODO: Fix this type?
        ) => {
          const { items: existingItivs, groupTotals: existingGroupRefs } = existingResult;

          const newOrUpdatedGroups = groupTotalsResult?.map((gt) => {
            const __ref = cache.identify(gt);
            cache.writeFragment({
              id: __ref,
              fragment: PlanPackageTakeoffTable_GroupTotalsFragmentDoc,
              data: gt,
            });
            return { __ref };
          });

          // Then the saved entities should be added to the itivPage cache
          return {
            ...existingResult,
            items: mergeEntities(
              existingItivs,
              newItems.map((itiv) => ({ __ref: cache.identify(itiv) })),
            ),
            groupTotals: newOrUpdatedGroups,
          };
        },
      },
    });
    // Refetch filters to ensure they only include facets that are still relevant
    store.getState().refetchFilters?.();
  }

  return store;
}

const takeoffsQuerySchema = z.object({
  groupBy: z.coerce.string().default(ItivOrderField.CostCode),
  sortBy: z.coerce.string().default(ItivOrderField.Item),
  filter: z.coerce.string().default("{}"),
});

export type SaveItivResult = Promise<FetchResult<TakeoffsStore_SaveItemTemplateItemVersionsMutation>>;
