import {
  BoundCheckboxField,
  BoundMultiSelectField,
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  BoundTreeSelectField,
  Button,
  Css,
  FormLines,
  HasIdAndName,
  Icon,
  IconButton,
  NestedOption,
  Palette,
  TextField,
  useComputed,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { PageHeader } from "src/routes/layout/PageHeader";
import {
  BusinessFunctionType,
  DocumentDetailDocumentTypeFragment,
  IncrementalCollectionOp,
  IncrementalSaveItemPlanTaskMappingInput,
  Market,
  Maybe,
  SaveGlobalPlanTaskInput,
  SaveGlobalPlanTaskRequiredDocumentInput,
  Scalars,
  TaskCatalogForm_DevelopmentScopeOptionFragment,
  useProjectRolesForBusinessFunctionQuery,
  useSaveGlobalPlanTaskMutation,
  useTaskCatalogFormDocumentsQuery,
  useTaskCatalogFormDropdownQuery,
  useTaskCatalogFormQuery,
  useTaskCatalogFormSchedulingDropdownQuery,
} from "src/generated/graphql-types";
import { ReactNode, useMemo, useState } from "react";
import { ListFieldState, ObjectConfig, ObjectState, required, useFormState, Rule } from "@homebound/form-state";
import { TaskCatalogFormCard } from "./TaskCatalogFormCard";
import { getRoleOptions } from "src/components/change-log/utils";
import { Observer } from "mobx-react";
import { camelCase } from "change-case";
import { useHistory, useParams } from "react-router";
import { IdOrAddParams } from "src/routes/routesDef";
import { addEntityParam } from "src/RouteUrls";
import { AddConstraintItemModal, ConstraintModalConfig } from "./AddConstraintItemModal";
import { AddGlobalPlanTagModal } from "./AddGlobalPlanTagModal";
import { getQueryStringValue } from "src/utils/queryString";
import { isDefined, mapFormInputToIncrementalOp, queryResult } from "src/utils";
import { AddRequiredDocumentModal } from "./AddRequiredDocumentModal";
import { MaterialsAndCostFormDetails } from "./MaterialsAndCostFormDetails";
import { AddStageModal } from "./AddStageModal";

export function TaskCatalogForm() {
  const { idOrAdd } = useParams<IdOrAddParams>();
  const history = useHistory();
  // grab the id from the query params to populate the form to copy
  const copiedCatalogTaskId = useMemo(
    () => getQueryStringValue(history.location, "copyFromTaskId"),
    [history.location],
  );
  const isFormNew = idOrAdd === addEntityParam;
  const isFormCopied = isDefined(copiedCatalogTaskId);

  const { data } = useTaskCatalogFormQuery({
    variables: { id: isFormCopied ? copiedCatalogTaskId : idOrAdd },
    skip: isFormNew && !isFormCopied,
  });

  const { triggerNotice } = useSnackbar();
  const [saveGlobalPlanTask] = useSaveGlobalPlanTaskMutation();

  const formState = useFormState({
    config: formValue,
    init: {
      input: data?.globalPlanTask,
      map: (gpt) => {
        // pluck out ID so that we don't initialize the form with the copied task's id
        const {
          id,
          name,
          businessFunctionType,
          projectRole,
          allowances,
          constraints,
          tags,
          scopes,
          budgetItem,
          tradeCategory,
          globalRequiredTaskDocuments,
          itemPlanTaskMappings,
          stage,
          ...others
        } = gpt;
        return {
          id: isFormCopied ? undefined : id,
          name: isFormCopied ? `Copy of ${name}` : name,
          businessFunctionType: businessFunctionType.code,
          projectRole: projectRole?.code,
          allowances: allowances.map((a) => a.constraintItem.id),
          constraints: constraints.map((c) => c.constraintItem.id),
          budgetItemId: budgetItem.id,
          tradeCategoryId: tradeCategory?.id,
          tags: tags.map((tag) => tag.id),
          scopes: scopes.map((scope) => scope.target.id),
          globalRequiredTaskDocuments: globalRequiredTaskDocuments.map(({ documentType, ...others }) => ({
            documentType: documentType.code,
            op: IncrementalCollectionOp.Include,
            ...others,
          })),
          itemPlanTaskMappings: itemPlanTaskMappings.map(({ item, unitOfMeasure, ...rest }) => ({
            op: IncrementalCollectionOp.Include,
            itemId: item.id,
            unitOfMeasureId: unitOfMeasure?.id,
            ...rest,
          })),
          stageId: stage?.id,
          ...others,
        };
      },
      ifUndefined: {
        optimisticDurationInDays: 1,
        pessimisticDurationInDays: 1,
        expectedDurationInDays: 1,
        allowances: [],
        constraints: [],
        tags: [],
        scopes: [],
        stageId: undefined,
      },
    },
    addRules: (state) => {
      // Combine the 3 duration input rules into one error message (on the last input, which is "pessimisticDurationInDays")
      state.pessimisticDurationInDays.rules.push(durationRules(state));
    },
  });

  const onSubmit = async () => {
    const { allowances, constraints, tags, scopes, globalRequiredTaskDocuments, itemPlanTaskMappings, ...rest } =
      formState.value;
    const allowanceInput = mapFormInputToIncrementalOp(
      allowances,
      isFormCopied ? [] : data?.globalPlanTask.allowances, // if we're copying, all values in the form state should be considered "new" and therefore inclduded
      {
        existingValueToId: (allowance) => allowance.constraintItem.id,
        removedMapper: (removedAllowance) => ({ id: removedAllowance.id, op: IncrementalCollectionOp.Delete }),
        addedMapper: (addedId) => ({ constraintItemId: addedId, op: IncrementalCollectionOp.Include }),
      },
    );

    const constraintInput = mapFormInputToIncrementalOp(
      constraints,
      isFormCopied ? [] : data?.globalPlanTask.constraints, // if we're copying, all values in the form state should be considered "new" and therefore included
      {
        existingValueToId: (constraint) => constraint.constraintItem.id,
        removedMapper: (removedConstraint) => ({ id: removedConstraint.id, op: IncrementalCollectionOp.Delete }),
        addedMapper: (addedId) => ({ constraintItemId: addedId, op: IncrementalCollectionOp.Include }),
      },
    );

    const scopesInput = mapFormInputToIncrementalOp(scopes, data?.globalPlanTask.scopes, {
      existingValueToId: (scope) => scope.target.id,
      removedMapper: (removedScope) => ({ id: removedScope.id, op: IncrementalCollectionOp.Delete }),
      addedMapper: (addedId) => ({ targetId: addedId, op: IncrementalCollectionOp.Include }),
    });

    const tagsInput = mapFormInputToIncrementalOp(tags, data?.globalPlanTask.tags, {
      existingValueToId: (tag) => tag.id,
      removedMapper: (removedTag) => ({ id: removedTag.id, op: IncrementalCollectionOp.Remove }),
      addedMapper: (addedId) => ({ id: addedId, op: IncrementalCollectionOp.Include }),
    });

    // Remove any item plan task mappings that were in "draft" then deleted
    // (ie, added to the form but then removed before saving)
    const itemPlanTaskMappingsInput = itemPlanTaskMappings?.filter(
      (iptm) => !(iptm.op === IncrementalCollectionOp.Delete && !iptm.id),
    );

    // one-off helper to format globalRequiredDocumentsInput
    function formatGlobalRequiredTaskDocumentsInput() {
      // if we dont have an input then return nothing
      if (!globalRequiredTaskDocuments) return [];

      const existingValues = data?.globalPlanTask.globalRequiredTaskDocuments;
      const existingValuesAsIds = existingValues?.map((grtd) => grtd.id) ?? [];

      const removedValues =
        existingValues
          ?.filter((existingValue) => !globalRequiredTaskDocuments?.find((input) => input.id === existingValue.id))
          .map((removedGrtd) => ({
            id: removedGrtd.id,
            op: IncrementalCollectionOp.Delete,
            name: removedGrtd.name,
            documentType: removedGrtd.documentType.code,
          })) ?? [];

      const addedValues = globalRequiredTaskDocuments
        .filter((input) => !existingValuesAsIds.includes(input.id ?? ""))
        .map((addedGrtd) => ({
          id: addedGrtd.id,
          name: addedGrtd.name,
          op: IncrementalCollectionOp.Include,
          documentType: addedGrtd.documentType,
          watchers: addedGrtd.watchers,
        }));

      return [...removedValues, ...addedValues];
    }

    await saveGlobalPlanTask({
      variables: {
        input: {
          // only send back the ID when we're in edit mode
          id: !isFormCopied && !isFormNew ? data?.globalPlanTask.id : undefined,
          ...(allowanceInput?.nonEmpty && { allowances: allowanceInput }),
          ...(constraintInput?.nonEmpty && { constraints: constraintInput }),
          ...(tagsInput?.nonEmpty && { tags: tagsInput }),
          ...(scopesInput?.nonEmpty && { scopes: scopesInput }),
          ...(formatGlobalRequiredTaskDocumentsInput()?.nonEmpty && {
            globalRequiredTaskDocuments: formatGlobalRequiredTaskDocumentsInput(),
          }),
          ...(itemPlanTaskMappings?.nonEmpty && { itemPlanTaskMappings: itemPlanTaskMappingsInput }),

          ...rest,
        },
      },
      onCompleted: () => {
        triggerNotice({
          message: isFormNew ? "A new task was created!" : "Task was successfully updated.",
        });
      },
    });
    // redirect back to Task Catalog Page once created (using `goBack` to preserve the previous query param filters)
    history.goBack();
  };

  return (
    <Observer>
      {() => (
        <div data-testid="taskCatalogForm">
          <PageHeader
            title={isFormNew ? "Create New Task" : "Edit Task"}
            right={
              <div css={Css.df.aic.$}>
                <div css={Css.mr1.$}>
                  <Button
                    label={isFormNew ? "Create" : "Save"}
                    onClick={onSubmit}
                    disabled={!formState.valid && isFormNew}
                  />
                </div>
              </div>
            }
          />
          <div css={Css.df.fdc.aic.maxwPx(600).ma.$}>
            <BoundTextField label="Task Name*" field={formState.name} required fullWidth />
            <div css={Css.mt2.$}>
              <TaskCatalogFormCard
                title={<TaskCatalogFormCardTitle title="General Task Details" />}
                children={<GeneralTaskFormDetails formState={formState} />}
              />
              <TaskCatalogFormCard
                title={
                  <TaskCatalogFormCardTitle
                    title="Scheduling"
                    description="Expected task duration, and what other tasks should come before and after it"
                  />
                }
                children={<SchedulingTaskFormDetails formState={formState} />}
              />
              <TaskCatalogFormCard
                title={
                  <TaskCatalogFormCardTitle
                    title="Materials and Cost"
                    description="Material and labor costs that are required to complete this task"
                  />
                }
                children={<MaterialsAndCostFormDetails formState={formState.itemPlanTaskMappings} />}
              />
              <TaskCatalogFormCard
                title={
                  <TaskCatalogFormCardTitle
                    title="Documents"
                    description="Documents that are required to complete this task, or for trades to reference"
                  />
                }
                children={<DocumentsFormDetails formState={formState.globalRequiredTaskDocuments} />}
              />
              <TaskCatalogFormCard
                title={
                  <TaskCatalogFormCardTitle
                    title="Verification Checklist"
                    description="Used to verify correctly completed work before marking the task as complete"
                  />
                }
                children={<ChecklistTaskFormDetails formState={formState} />}
              />
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
}

export function GeneralTaskFormDetails({ formState }: { formState: ObjectState<SaveGlobalPlanTaskFormValue> }) {
  const query = useTaskCatalogFormDropdownQuery({});
  const businessFunction = useComputed(() => formState.businessFunctionType.value, [formState]);

  // only call this query if we have a selected business function (from the dropdown)
  const { data: projectRoles } = useProjectRolesForBusinessFunctionQuery({
    variables: { businessFunctionType: businessFunction! },
    skip: !businessFunction,
  });

  const { openModal } = useModal();

  const roles = getRoleOptions(projectRoles?.projectRolesForBusinessFunctionType ?? []);

  return queryResult(query, ({ developments, markets, enumDetails: enums, tradeCategories, globalPlanTags, items }) => {
    const specificityOptions = getSpecificityTreeSelectOptions(markets, developments);

    return (
      <Observer>
        {() => (
          <FormLines>
            <LabelledTextWithTooltip
              label="External Name"
              tooltip="The name of the task that will be used externally with Trade Partners."
            >
              <BoundTextField label="External Name" labelStyle="hidden" field={formState.externalName} />
            </LabelledTextWithTooltip>
            <LabelledTextWithTooltip
              label="Description*"
              tooltip="A brief description of the work that this task represents. Should provide enough detail to ensure that the intention of this task is understood and the task can be used consistently."
            >
              <BoundTextAreaField label="Description" labelStyle="hidden" field={formState.description} required />
            </LabelledTextWithTooltip>
            <BoundSelectField
              label="Trade Partner Category"
              options={tradeCategories}
              field={formState.tradeCategoryId}
            />
            <LabelledTextWithTooltip
              label="Budget Item*"
              tooltip="The category of labor that this task is associated with."
            >
              <BoundSelectField
                label="Budget Item"
                labelStyle="hidden"
                options={items}
                required
                field={formState.budgetItemId}
              />
            </LabelledTextWithTooltip>
            <BoundSelectField
              label="Team*"
              options={enums?.businessFunctionType ?? []}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ code }) => code as BusinessFunctionType}
              required
              field={formState.businessFunctionType}
            />
            <BoundSelectField
              label="Role"
              options={roles}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              field={formState.projectRole}
              disabled={!formState.businessFunctionType.value}
            />
            <BoundTreeSelectField
              options={specificityOptions}
              field={formState.scopes}
              label="Specific to"
              defaultCollapsed
              onSelect={(option) => formState.scopes.set(option.root.values)}
            />
            <LabelledTextWithTooltip
              label="Automatically include this task in new project schedules"
              tooltip="This selection is further filtered by the 'Specific to Markets and Developments' input"
              inline
              before
            >
              <BoundCheckboxField
                label="Automatically include this task in new project schedules"
                checkboxOnly
                field={formState.applyToAllSchedules}
              />
            </LabelledTextWithTooltip>
            <BoundMultiSelectField
              label="Tags"
              options={globalPlanTags}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              field={formState.tags}
            />
            <Button
              label={
                <>
                  <Icon icon="plus" />
                  <span>Add a new tag</span>
                </>
              }
              variant="text"
              onClick={() =>
                openModal({
                  content: (
                    <AddGlobalPlanTagModal
                      onTagAdded={(tag) => {
                        const tags = formState.tags.value ?? [];
                        formState.tags.set([...tags, tag.id]);
                      }}
                    />
                  ),
                })
              }
            />
          </FormLines>
        )}
      </Observer>
    );
  });
}

export function SchedulingTaskFormDetails({ formState }: { formState: ObjectState<SaveGlobalPlanTaskFormValue> }) {
  const { data } = useTaskCatalogFormSchedulingDropdownQuery();
  const { openModal } = useModal();
  return (
    <FormLines>
      <BoundSelectField
        field={formState.stageId}
        label="Stage"
        options={data?.globalPlanStages ?? []}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id}
      />
      <Button
        label="Add New"
        variant="text"
        onClick={() =>
          openModal({
            content: <AddStageModal formState={formState} />,
          })
        }
      />
      <LabelledTextWithTooltip label="Expected Duration" tooltip="The initial expected duration of the task in days.">
        <BoundNumberField
          label="Expected Duration"
          labelStyle="hidden"
          field={formState.expectedDurationInDays}
          type="days"
        />
      </LabelledTextWithTooltip>
      <LabelledTextWithTooltip
        label="Best Case Duration"
        tooltip="The minimum number of days that will be required to complete this task."
      >
        <BoundNumberField
          label="Best Case Duration"
          labelStyle="hidden"
          field={formState.optimisticDurationInDays}
          type="days"
        />
      </LabelledTextWithTooltip>
      <LabelledTextWithTooltip
        label="Worst Case Duration"
        tooltip="The maximum number of days that will be required to complete this task. The worst case duration should cover 95% of all durations of this task."
      >
        <BoundNumberField
          label="Worst Case Duration"
          labelStyle="hidden"
          field={formState.pessimisticDurationInDays}
          type="days"
        />
      </LabelledTextWithTooltip>
      <LabelledTextWithTooltip
        label="Constraints"
        tooltip="Events that must occur prior to this task being started. Constraints include internal events such as the completion of other tasks, Trade Partner availability, material availability, as well as events from other data sources such as weather conditions."
      >
        <BoundMultiSelectField
          field={formState.constraints}
          label="Constraints"
          labelStyle="hidden"
          options={data?.globalPlanConstraintItems ?? []}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
        />
      </LabelledTextWithTooltip>
      <Button
        label="Add New"
        variant="text"
        onClick={() =>
          openModal({
            content: <AddConstraintItemModal constraintType={ConstraintModalConfig.Constraint} formState={formState} />,
          })
        }
      />
      <LabelledTextWithTooltip label="Allowances" tooltip="Events that are triggered on completion of this task.">
        <BoundMultiSelectField
          field={formState.allowances}
          label="Allowances"
          labelStyle="hidden"
          options={data?.globalPlanConstraintItems ?? []}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
        />
      </LabelledTextWithTooltip>
      <Button
        label="Add New"
        variant="text"
        onClick={() =>
          openModal({
            content: <AddConstraintItemModal constraintType={ConstraintModalConfig.Allowance} formState={formState} />,
          })
        }
      />
    </FormLines>
  );
}

export type SaveGlobalPlanTaskFormValue = Omit<
  SaveGlobalPlanTaskInput,
  "allowances" | "constraints" | "tags" | "scopes"
> & {
  allowances: Maybe<Array<Scalars["ID"]>>;
  constraints: Maybe<Array<Scalars["ID"]>>;
  tags: Maybe<Array<Scalars["ID"]>>;
  scopes: Maybe<Array<Scalars["ID"]>>;
};

export const globalPlanTaskRequiredDocumentConfig: ObjectConfig<SaveGlobalPlanTaskRequiredDocumentInput> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  documentType: { type: "value", rules: [required] },
  watchers: { type: "value" },
  op: { type: "value" },
};

export const itemPlanTaskMappingTaskCatalogConfig: ObjectConfig<IncrementalSaveItemPlanTaskMappingInput> = {
  id: { type: "value" },
  itemId: { type: "value", rules: [required] },
  op: { type: "value", rules: [required] },
  unitOfMeasureId: { type: "value" },
  estimatedLaborCostInCents: { type: "value" },
};

export const formValue: ObjectConfig<SaveGlobalPlanTaskFormValue> = {
  name: { type: "value", rules: [required] },
  externalName: { type: "value" },
  description: { type: "value", rules: [required] },
  tradeCategoryId: { type: "value" },
  businessFunctionType: { type: "value", rules: [required] },
  projectRole: { type: "value" },
  optimisticDurationInDays: { type: "value", rules: [required, greaterThanZero] },
  expectedDurationInDays: { type: "value", rules: [required, greaterThanZero] },
  pessimisticDurationInDays: { type: "value", rules: [required, greaterThanZero] },
  stageId: { type: "value" },
  allowances: { type: "value" },
  constraints: { type: "value" },
  tags: { type: "value" },
  scopes: { type: "value" },
  applyToAllSchedules: { type: "value" },
  budgetItemId: { type: "value", rules: [required] },
  checklistItems: {
    type: "list",
    config: { id: { type: "value" }, name: { type: "value", rules: [required] }, delete: { type: "value" } },
  },
  globalRequiredTaskDocuments: {
    type: "list",
    config: globalPlanTaskRequiredDocumentConfig,
  },
  itemPlanTaskMappings: {
    type: "list",
    config: itemPlanTaskMappingTaskCatalogConfig,
  },
};

type LabelledTextWithTooltipProps = {
  label: string;
  children: ReactNode;
  tooltip: string;
  inline?: boolean;
  before?: boolean;
};

export function LabelledTextWithTooltip({
  label,
  children,
  tooltip,
  inline = false,
  before,
}: LabelledTextWithTooltipProps) {
  return (
    <div css={Css.if(inline).df.gap1.$}>
      {before && children}
      <span css={Css.df.aic.gapPx(4).$}>
        <label css={Css.gray700.sm.$}>{label}</label>
        <Icon icon="infoCircle" color={Palette.Gray500} inc={2} tooltip={tooltip} />
      </span>
      {!before && children}
    </div>
  );
}

function TaskCatalogFormCardTitle({ title, description }: { title: string; description?: string }) {
  const tid = useTestIds({ title }, "taskCatalogFormCard");
  return (
    <>
      <div css={Css.df.jcsb.$}>
        <div {...tid[camelCase(title)]}>{title}</div>
        {/* Group completion indicators TODO [SC-47468]
        <Icon icon="circleOutline" /> 
        */}
      </div>
      <div css={Css.sm.gray900.mt2.$}>{description}</div>
    </>
  );
}

function getSpecificityTreeSelectOptions(
  markets: Pick<Market, "id" | "name">[],
  developments: TaskCatalogForm_DevelopmentScopeOptionFragment[],
): NestedOption<HasIdAndName>[] {
  const developmentsByMarketId = developments.groupBy((dev) => dev.market.id);
  return markets
    .map((market) => {
      return {
        id: market.id,
        name: market.name,
        children: developmentsByMarketId[market.id]?.map((dev) => ({ id: dev.id, name: dev.name })),
      };
    })
    .sortBy((market) => market.name);
}

type ChecklistTaskFormDetailsProps = {
  formState: ObjectState<SaveGlobalPlanTaskFormValue>;
};

function ChecklistTaskFormDetails({ formState }: ChecklistTaskFormDetailsProps) {
  const checklistItems = formState.checklistItems;
  const [newItem, setNewItem] = useState<string | undefined>();

  const checklistInputs = useComputed(
    () =>
      checklistItems?.rows
        .filter((row) => !row.value.delete)
        .map((item, i) => {
          const { id } = item.value;
          return (
            <div key={"ci" + (id || i)} css={Css.df.jcsb.aic.my1.$}>
              <BoundTextField
                data-testid="checklistItemName"
                field={item.name}
                labelStyle="hidden"
                errorInTooltip
                compact
              />
              <IconButton
                data-testid="deleteChecklistItem"
                icon={"x"}
                onClick={() => {
                  if (item.value.id) {
                    item.delete.set(true);
                  } else {
                    checklistItems.remove(i);
                  }
                }}
              />
            </div>
          );
        }),
    [checklistItems],
  );
  return (
    <div>
      {checklistInputs}
      <TextField
        label="Add a new checklist item"
        data-testid="newChecklistItem"
        labelStyle="hidden"
        value={newItem}
        placeholder="Add checklist item"
        onChange={(name) => setNewItem(name)}
        onEnter={() => {
          checklistItems.add({ name: newItem });
          setNewItem(undefined);
        }}
        compact
      />
    </div>
  );
}

function DocumentsFormDetails({ formState }: { formState: ListFieldState<SaveGlobalPlanTaskRequiredDocumentInput> }) {
  const { data } = useTaskCatalogFormDocumentsQuery();
  return (
    <Observer>
      {() => (
        <>
          {formState.rows.nonEmpty ? (
            <div css={Css.df.fdc.$} data-testid="globalRequiredTaskDocumentInformation">
              {formState.rows.map((grtd, i) => {
                const { id } = grtd.value;
                return (
                  <div css={Css.df.jcsb.mb2.aic.bgGray100.br8.p2.$} key={`td${id || i}`}>
                    <div css={Css.df.fdc.$}>
                      <div css={Css.df.gap1.hPx(30).$}>
                        <div css={Css.fw5.$}>Document Name</div>
                        <div>{grtd.name.value}</div>
                      </div>
                      <div css={Css.df.gap1.$}>
                        <div css={Css.fw5.$}>Type</div>
                        <div>
                          {data?.documentTypes.find((docType) => grtd.documentType.value === docType.code)?.name ?? ""}
                        </div>
                      </div>
                    </div>
                    <IconButton icon="x" onClick={() => formState.remove(i)} />
                  </div>
                );
              })}
              <AddRequiredDocumentModalButton formState={formState} documentTypes={data?.documentTypes ?? []} />
            </div>
          ) : (
            <div css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
              <AddRequiredDocumentModalButton formState={formState} documentTypes={data?.documentTypes ?? []} />
            </div>
          )}
        </>
      )}
    </Observer>
  );
}

function AddRequiredDocumentModalButton({
  formState,
  documentTypes,
}: {
  formState: ListFieldState<SaveGlobalPlanTaskRequiredDocumentInput>;
  documentTypes: DocumentDetailDocumentTypeFragment[];
}) {
  const { openModal, closeModal } = useModal();

  return (
    <Button
      label="Add Required Document"
      variant="text"
      onClick={() => {
        openModal({
          content: (
            <AddRequiredDocumentModal
              documentTypes={documentTypes}
              onDocumentAdd={(input) => {
                formState.add({
                  op: IncrementalCollectionOp.Include,
                  documentType: input.documentType.changedValue,
                  name: input.name.changedValue,
                  watchers: input.watchers.changedValue,
                });
                closeModal();
              }}
            />
          ),
        });
      }}
    />
  );
}

function greaterThanZero({ value }: { value: Maybe<number> }): string | undefined {
  if (!isDefined(value)) return;
  if (value < 1) return "Duration must be at least 1 day.";
}

function durationRules(formState: ObjectState<SaveGlobalPlanTaskFormValue>): Rule<Maybe<number>> {
  return ({ value: pessimisticValue }) => {
    const {
      expectedDurationInDays: { value: expectedValue },
      optimisticDurationInDays: { value: optimisticValue },
    } = formState;
    if (!isDefined(pessimisticValue) || !isDefined(expectedValue) || !isDefined(optimisticValue)) return undefined;
    if (optimisticValue === expectedValue && expectedValue === pessimisticValue) return undefined;

    if (optimisticValue > expectedValue) {
      return "Best case duration must be less than the expected duration (or both durations must be equal)";
    }

    if (expectedValue >= pessimisticValue) {
      return "Expected duration must be less than the worst case duration (or all durations must be equal)";
    }

    return undefined;
  };
}
