import { Button, Css, GridDataRow, GridTable, SimpleHeaderAndData, column } from "@homebound/beam";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo, useState } from "react";
import {
  CommentStreamVisibility,
  Inbox_CommentStreamFragment,
  InternalUser,
  useCommentFeedTradePartnersQuery,
  useCommentStreamQuery,
  useCurrentInternalUserQuery,
} from "src/generated/graphql-types";
import { sortBy } from "src/utils";
import { CommentStreamCard } from "./CommentStreamCard";
import { InboxCommentStream } from "./InboxCommentStream";

export type InboxProps = {
  commentStreamId?: string | null | undefined;
  commentableId?: string;
  streams: Inbox_CommentStreamFragment[];
  visibility?: CommentStreamVisibility;
  displayCommentableNames?: {
    closeStream: boolean;
    openStream: boolean;
  };
  onSelectedStreamChanged?: (s: Inbox_CommentStreamFragment | undefined) => void;
  maybeFetchMore?: () => void;
};

export function Inbox({
  commentStreamId,
  commentableId,
  streams,
  visibility,
  displayCommentableNames,
  onSelectedStreamChanged,
  maybeFetchMore,
}: InboxProps) {
  const { data: tradePartnersData } = useCommentFeedTradePartnersQuery({
    variables: { filter: {} },
    skip: visibility !== CommentStreamVisibility.Trades,
  });
  const { data: currentInternalUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });

  const { data: streamData } = useCommentStreamQuery({ variables: { id: commentStreamId! }, skip: !commentStreamId });

  const [streamId, setStreamId] = useState<string | null | undefined>(commentStreamId);

  const orderedStreams = useMemo(
    () =>
      sortBy(
        [
          ...(streamData ? [streamData.commentStream!] : []),
          ...streams.filter(({ streamVisibility }) => !visibility || streamVisibility.code === visibility),
        ].uniqueByKey("id"),
        (s) => s.latestComment?.createdAt,
        "DESC",
      ),
    [streamData, streams, visibility],
  );

  const stream = useMemo(() => orderedStreams.find((s) => s.id === streamId), [orderedStreams, streamId]);

  const columns = useMemo(
    () => [
      column<Row>({
        id: "inbox",
        header: " ",
        data: (stream) => ({
          typeScale: "smMd",
          content: (
            <div key={stream.id} css={Css.w100.$}>
              <CommentStreamCard
                stream={stream}
                currentUser={currentInternalUserData?.currentInternalUser as InternalUser}
                onOpenStream={() => {
                  onSelectedStreamChanged && onSelectedStreamChanged(stream);
                  setStreamId(stream.id);
                }}
                displayCommentableNames={displayCommentableNames?.closeStream}
              />
            </div>
          ),
        }),
        w: "100%",
      }),
    ],
    [currentInternalUserData?.currentInternalUser, displayCommentableNames?.closeStream, onSelectedStreamChanged],
  );

  const rows: GridDataRow<Row>[] = useMemo(
    () =>
      orderedStreams.map((stream) => ({
        id: stream.id,
        kind: "data",
        data: stream,
      })),
    [orderedStreams],
  );

  if (stream || streamId === "new") {
    return (
      <AnimatePresence>
        <motion.div
          layout="position"
          initial={{ x: `150%`, opacity: 0, height: "100%" }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <InboxCommentStream
            commentableId={commentableId}
            visibility={visibility}
            tradePartners={tradePartnersData?.tradePartners ?? []}
            stream={stream}
            currentUser={currentInternalUserData?.currentInternalUser as InternalUser}
            goBack={() => {
              onSelectedStreamChanged && onSelectedStreamChanged(undefined);
              setStreamId(null);
            }}
            onNewStream={(id: string) => setStreamId(id)}
            displayCommentableNames={displayCommentableNames?.openStream}
          />
        </motion.div>
      </AnimatePresence>
    );
  }

  return (
    <div css={Css.relative.bgWhite.br8.df.fdc.gap1.h100.sm.$}>
      <GridTable
        as="virtual"
        columns={columns}
        rows={rows}
        rowStyles={{ data: { rowCss: Css.p0.$ } }}
        infiniteScroll={{
          onEndReached: () => maybeFetchMore && maybeFetchMore(),
        }}
      />
      {commentableId && (
        <div css={Css.fixed.bottom4.right6.df.jcfe.$}>
          <Button icon="plus" label="Add new" variant="primary" onClick={() => setStreamId("new")} />
        </div>
      )}
    </div>
  );
}

type Row = SimpleHeaderAndData<Inbox_CommentStreamFragment>;
