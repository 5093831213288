import {
  BoundSelectField,
  BoundSwitchField,
  BoundTextAreaField,
  BoundTextField,
  BoundNumberField,
  Button,
  ButtonProps,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  OpenInDrawerOpts,
  PresentationProvider,
  SuperDrawerContent,
  SuperDrawerHeader,
  SuperDrawerWidth,
  Tag,
  useComputed,
  useModal,
  useSuperDrawer,
  Tooltip,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  GlobalOptionStatus,
  useSaveGlobalOptionGroupMutation,
  OptionDrawerMetadataQuery,
  SaveGlobalOptionGroupInput,
  SaveGlobalOptionInput,
  useGlobalOptionDrawerQuery,
  useOptionDrawerMetadataQuery,
  useSaveGlobalOptionsDrawerMutation,
  Maybe,
  useMatchingGlobalOptionsByAbbreviationQuery,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { DesignUpgradeIconPickerModal } from "./components/DesignUpgradeIconPickerModal";
import { DesignUpgradeIcon } from "./components/DesignUpgradeIcon";
import { useDebounce } from "src/hooks";
import { LabelledText } from "src/routes/projects/schedule-v2/components/LabelledText";

export type OptionDrawerProps = {
  optionId?: string;
  keepOpenAfterSave?: boolean;
  refetchOnCreate?: () => Promise<void>;
};

export function GlobalOptionDrawer(props: OptionDrawerProps) {
  const { optionId, keepOpenAfterSave, refetchOnCreate } = props;
  const { openModal } = useModal();
  const { closeDrawer } = useSuperDrawer();
  const { data: metadata, refetch: refetchMetadata } = useOptionDrawerMetadataQuery();
  const query = useGlobalOptionDrawerQuery({ variables: { globalOptionId: optionId! }, skip: !optionId });
  const globalOption = query.data?.globalOption;
  const [saveGlobalOption] = useSaveGlobalOptionsDrawerMutation();

  const groupedReadyPlanOptions = useMemo(
    () =>
      query.data?.globalOption.readyPlanOptions
        .filter((rpo) => rpo.active)
        .groupBy(({ readyPlan }) => readyPlan.development?.id ?? "") ?? {},
    [query.data],
  );

  const formState = useFormState({
    config,
    init: {
      query,
      map: ({ globalOption: { locations, ...globalOptionFields } }) => {
        const selectedGroup = metadata?.globalOptionGroups.find(({ id }) => id === globalOptionFields.id);
        const selectedType = metadata?.globalOptionTypes.find(({ id }) => id === globalOptionFields.type.id);
        return {
          ...globalOptionFields,
          groupId: globalOptionFields.group?.id,
          typeId: globalOptionFields.type?.id,
          // Set "hasDesignAbbreviation" in formState as we cannot access the computed values, "selectedGroup" & "selectedType", within addRules
          hasDesignAbbreviation:
            !!selectedGroup?.forDesignInterior ||
            !!selectedType?.forDesignInterior ||
            !!selectedGroup?.forDesignExterior ||
            !!selectedType?.forDesignExterior,
        };
      },
    },
  });

  const { saveDisabled, selectedGroup, selectedType } = useComputed(
    () => ({
      saveDisabled: !formState.dirty || !formState.valid,
      selectedGroup: metadata?.globalOptionGroups.find(({ id }) => id === formState.groupId.value),
      selectedType: metadata?.globalOptionTypes.find(({ id }) => id === formState.typeId.value),
    }),
    [formState, metadata],
  );

  useEffect(() => {
    if (selectedGroup) {
      formState.typeId.set(selectedGroup.type.id);
      // Setting state, so rules can evaluate the value
      formState.hasDesignAbbreviation.set(selectedGroup.forDesignInterior || selectedGroup.forDesignExterior);
    }

    if (!selectedGroup && selectedType) {
      // Setting state, so rules can evaluate the value
      formState.hasDesignAbbreviation.set(selectedType.forDesignInterior || selectedType.forDesignExterior);
    }
  }, [selectedGroup, selectedType, formState]);

  const onSave = useCallback(async () => {
    // We only use hasDesignAbbreviation in formState rules, so we remove it from the changedValue
    const { hasDesignAbbreviation, ...changedValue } = formState.changedValue;
    const { data } = await saveGlobalOption({ variables: { input: { globalOptions: [changedValue] } } });
    if (data?.saveGlobalOptions.globalOptions.first) {
      formState.id.set(data.saveGlobalOptions.globalOptions.first.id);
    }

    if (!optionId && refetchOnCreate) {
      await refetchOnCreate();
    }

    if (!keepOpenAfterSave) {
      closeDrawer();
    }
  }, [formState, saveGlobalOption, keepOpenAfterSave, closeDrawer, optionId, refetchOnCreate]);

  const onArchive = useCallback(async () => {
    await saveGlobalOption({
      variables: { input: { globalOptions: [{ id: formState.id.value, status: GlobalOptionStatus.Archived }] } },
    });
  }, [saveGlobalOption, formState]);

  const onArchiveClick = () => {
    openModal({
      content: (
        <ConfirmationModal
          confirmationMessage={
            <>
              <p css={Css.lgSb.gray900.mb2.$}>Are you sure you want to archive this option?</p>
              <p css={Css.base.gray700.$}>
                Archived options will not be available for future developments.
                <br /> This option will also be archived on any plan it has been added to.
              </p>
            </>
          }
          title="Archive Option"
          onConfirmAction={onArchive}
          label="Archive option"
          danger
        />
      ),
    });
  };

  const onNewGroupClick = () => {
    openModal({
      content: (
        <NewGlobalOptionGroupModal
          globalOptionTypes={metadata?.globalOptionTypes ?? []}
          refetchOnCreate={async () => {
            await refetchMetadata();
          }}
          onCreated={(id) => formState.groupId.set(id)}
        />
      ),
    });
  };

  const locationsContent =
    globalOption === undefined ? (
      "Locations where the option is used on plans will appear here, after the option is added to plans"
    ) : globalOption.locations.isEmpty ? (
      "This option is not used in any specific locations, but may still be used plan wide."
    ) : (
      <Tooltip title={"Active option locations only."} placement="left">
        {globalOption.locations.map((loc) => (
          <div key={loc.id} data-testid={loc.id.replace(":", "")} css={Css.dib.pr1.pb1.$}>
            <Tag text={loc.name} />
          </div>
        ))}
      </Tooltip>
    );
  return (
    <>
      <SuperDrawerHeader
        title={optionId ? globalOption?.name ?? "..." : `Create a New Option`}
        right={
          globalOption && (
            <Tag
              type={globalOption.statusDetail.code === GlobalOptionStatus.Active ? "success" : "warning"}
              text={globalOption.statusDetail.name}
            />
          )
        }
      />
      <SuperDrawerContent
        actions={[
          ...(optionId
            ? [{ label: "Archive Option", variant: "tertiaryDanger", onClick: onArchiveClick } as ButtonProps]
            : []),
          { label: "Cancel", variant: "secondary", onClick: closeDrawer },
          { label: "Save", onClick: onSave, disabled: saveDisabled },
        ]}
      >
        <PresentationProvider fieldProps={{ labelSuffix: { required: "*" } }}>
          <div css={Css.df.fdc.gap4.$}>
            <div css={Css.dg.gtc("80% auto").gap2.$}>
              <BoundTextField
                field={formState.name}
                required
                label="Name"
                placeholder="Convert Partial Unfinished..."
                fullWidth
              />
              <BoundTextField field={formState.code} required label="Option Code" placeholder="ABCDEF0001" />
            </div>
            <div css={Css.df.gap1.aife.$}>
              <BoundSelectField
                required
                label="Option Group"
                field={formState.groupId}
                options={[{ name: "-- Not Grouped --", id: undefined }, ...(metadata?.globalOptionGroups ?? [])]}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                disabled={globalOption ? disableBasedOnPotentialOperation(globalOption.canEditType) : undefined}
              />
              <div css={Css.pbPx(4).$}>
                <Button icon="plus" label="New Group" variant="tertiary" onClick={onNewGroupClick} />
              </div>
            </div>
            <BoundSelectField
              required
              label="Option Type"
              field={formState.typeId}
              options={metadata?.globalOptionTypes ?? []}
              disabled={
                selectedGroup
                  ? "Type comes from the selected group"
                  : globalOption
                    ? disableBasedOnPotentialOperation(globalOption.canEditType)
                    : undefined
              }
              readOnly={formState.groupId.value}
            />
            {(!!selectedGroup?.forDesignInterior ||
              !!selectedGroup?.forDesignExterior ||
              !!selectedGroup?.forDesignUpgrade ||
              !!selectedType?.forDesignInterior ||
              !!selectedType?.forDesignExterior ||
              !!selectedType?.forDesignUpgrade) && (
              <DesignPackageInfoSection
                hasDesignAbbreviation={
                  selectedGroup?.forDesignInterior ||
                  selectedType?.forDesignInterior ||
                  selectedGroup?.forDesignExterior ||
                  selectedType?.forDesignExterior ||
                  false
                }
                hasDesignIcon={selectedGroup?.forDesignUpgrade ?? selectedType?.forDesignUpgrade ?? false}
                optionName={globalOption?.name}
                formState={formState}
              />
            )}
            <div>
              <LabelledText label="Locations">
                <div css={Css.wsn.$}>{locationsContent}</div>
              </LabelledText>
            </div>
            <div css={Css.df.fdc.gap2.$}>
              <div css={Css.sm.$}>Requirements</div>
              <div css={Css.dg.gtc("50% 50%").gap3.$}>
                <BoundSwitchField
                  field={formState.standardDetailRequired}
                  label="Standard detail required"
                  labelStyle="inline"
                />
                <BoundSwitchField
                  field={formState.requiredInPlanDrawing}
                  label="Required in plan drawing"
                  labelStyle="inline"
                />
                <BoundSwitchField field={formState.elevationRequired} label="Elevation required" labelStyle="inline" />
                <BoundSwitchField
                  field={formState.shopDrawingRequired}
                  label="Shop drawing required"
                  labelStyle="inline"
                />
              </div>
            </div>
            <BoundTextAreaField
              field={formState.description}
              label="Description"
              placeholder="Write a short description"
            />
            <BoundNumberField field={formState.defaultOrder} label="Default Order" />
            {optionId && Object.entries(groupedReadyPlanOptions).nonEmpty && (
              <div>
                <div css={Css.sm.$}>Added to</div>
                <div css={Css.df.fww.cg6.$}>
                  {Object.entries(groupedReadyPlanOptions).map(([id, readyPlanOptions]) => (
                    <div key={id} css={Css.py1.$}>
                      <div css={Css.smSb.$}>
                        {id
                          ? `${readyPlanOptions.first?.readyPlan.development?.name}:`
                          : "Readyplans without development:"}
                      </div>
                      <ul css={Css.pl2.m0.$}>
                        {readyPlanOptions
                          .map((rpo) => rpo.readyPlan.name)
                          .unique()
                          .map((planName) => (
                            <li key={planName}>{planName}</li>
                          ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </PresentationProvider>
      </SuperDrawerContent>
    </>
  );
}

type DesignPackageInfoSectionProps = {
  formState: ObjectState<GlobalOptionFormConfig>;
  hasDesignAbbreviation: boolean;
  hasDesignIcon: boolean;
  optionName: string | undefined;
};

function DesignPackageInfoSection({
  formState,
  hasDesignAbbreviation,
  hasDesignIcon,
  optionName,
}: DesignPackageInfoSectionProps) {
  const { openModal } = useModal();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const debouncedName = useDebounce(search);
  const query = useMatchingGlobalOptionsByAbbreviationQuery({
    variables: { designPackageAbbreviation: debouncedName! },
    skip: !debouncedName,
  });
  const hasMatchingAbbreviation = query.data?.globalOptions.filter((o) => o.id !== formState.id.value).nonEmpty;
  return (
    <div css={Css.bt.bb.bcGray200.py4.$}>
      <div css={Css.mb2.lgMd.$}>Design Package Information</div>
      {hasDesignAbbreviation && (
        <>
          <div css={Css.mb2.smMd.$}>You must create a 2-letter abbreviation for this option in Design Packages:</div>
          <BoundTextField
            onChange={(value) => {
              formState.designPackageAbbreviation.set(value);
              // We use search to query for any existing abbreviations
              setSearch(value);
            }}
            field={formState.designPackageAbbreviation}
            label="Abbreviation"
            errorMsg={
              hasMatchingAbbreviation
                ? "This abbreviation already exists"
                : formState.designPackageAbbreviation.errors.nonEmpty
                  ? formState.designPackageAbbreviation.errors.join(" ")
                  : undefined
            }
          />
        </>
      )}
      {hasDesignIcon && (
        <>
          <div css={Css.mb2.smMd.$}>You must select an icon for Design Package tags for this option:</div>
          <div css={Css.df.gap2.$}>
            <Button
              variant="secondary"
              label="Change Icon"
              onClick={() =>
                openModal({
                  content: (
                    <DesignUpgradeIconPickerModal
                      optionName={optionName ?? "New Option"}
                      onClose={(activeIcon) => formState.designUpgradeIconName.set(activeIcon)}
                      selectedIcon={formState.designUpgradeIconName.value}
                    />
                  ),
                })
              }
            />
            {formState.designUpgradeIconName.value && (
              <div data-testid="designUpgradeIcon" css={Css.ptPx(3).$}>
                <DesignUpgradeIcon color="black" size={24} iconName={formState.designUpgradeIconName.value} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export function useOptionDrawer(opts?: Partial<OpenInDrawerOpts>) {
  const { openInDrawer, closeDrawer } = useSuperDrawer();

  const openOptionDrawer = useCallback(
    (props?: OptionDrawerProps, overrideOpts?: Partial<OpenInDrawerOpts>) => {
      openInDrawer({
        ...opts,
        ...overrideOpts,
        content: <GlobalOptionDrawer {...props} />,
        width: SuperDrawerWidth.Normal,
      });
    },
    [openInDrawer, opts],
  );

  useEffect(() => () => closeDrawer(), [closeDrawer]);

  return { openOptionDrawer, closeDrawer };
}

export type NewGlobalOptionGroupModalType = {
  onCreated?: (id: string) => void;
  refetchOnCreate?: () => Promise<void>;
  globalOptionTypes: OptionDrawerMetadataQuery["globalOptionTypes"];
};

export function NewGlobalOptionGroupModal(props: NewGlobalOptionGroupModalType) {
  const { onCreated, refetchOnCreate, globalOptionTypes } = props;
  const { closeModal } = useModal();
  const [saveGroup] = useSaveGlobalOptionGroupMutation();
  const formState = useFormState({ config: createGroupConfig });

  const isSaveDisabled = useComputed(() => !formState.dirty || !formState.valid, [formState]);

  const onConfirmClick = useCallback(async () => {
    // We create the new group
    const { data } = await saveGroup({
      variables: { input: { ...formState.changedValue, isSingleOptionGroup: false } },
    });
    // If the refetch was provided we call it, so the new group is available
    refetchOnCreate && (await refetchOnCreate());
    // Now we grab the id of the newly created group
    const newId = data?.saveGlobalOptionGroup.entity.id;
    // and in case it exists and the onCreated callback was provided, we call it with the id, so it can be selected on the dropdown
    newId && onCreated?.(newId);
    // we close the modal after that
    closeModal();
  }, [refetchOnCreate, saveGroup, formState, closeModal, onCreated]);

  return (
    <>
      <ModalHeader>New Global Option Group</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap2.$}>
          <BoundTextField field={formState.name} label="Name" placeholder="The group name" />
          <BoundSelectField
            field={formState.typeId}
            label="Type"
            options={globalOptionTypes}
            placeholder="Pick a type for the group"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button label="Create" onClick={onConfirmClick} disabled={isSaveDisabled} />
      </ModalFooter>
    </>
  );
}

type GlobalOptionFormConfig = SaveGlobalOptionInput & { hasDesignAbbreviation: boolean };

const config: ObjectConfig<GlobalOptionFormConfig> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  code: { type: "value", rules: [required] },
  groupId: { type: "value" },
  typeId: { type: "value", rules: [required] },
  standardDetailRequired: { type: "value" },
  requiredInPlanDrawing: { type: "value" },
  elevationRequired: { type: "value" },
  shopDrawingRequired: { type: "value" },
  description: { type: "value" },
  designPackageAbbreviation: { type: "value", rules: [mustBeTwoChars, abbreviationRequired] },
  designUpgradeIconName: { type: "value" },
  defaultOrder: { type: "value" },
  hasDesignAbbreviation: { type: "value" },
};

const createGroupConfig: ObjectConfig<SaveGlobalOptionGroupInput> = {
  name: { type: "value", rules: [required] },
  typeId: { type: "value", rules: [required] },
};

function mustBeTwoChars({ value }: { value: Maybe<string> }): string | undefined {
  if (!value) return;
  if (value.trim().length !== 2) return "Input must be exactly two characters.";
}

function abbreviationRequired(opts: any): string | undefined {
  const formState = (opts satisfies any).object;
  // if hasDesignAbbreviation is true, then the abbreviation is required
  if (formState.hasDesignAbbreviation.value && !opts.value) return `Abbreviation is required`;
  return undefined;
}
