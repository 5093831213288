import { Css, useComputed } from "@homebound/beam";
import {
  MaterialAttributeDimensionType,
  MaterialCatalogMetadataDocument,
  MaterialCatalog_MaterialAttributeDimensionFragment,
  MaterialType,
  SaveMaterialVariantInput,
  useSaveMavMutation,
} from "src/generated/graphql-types";
import { MaterialPageFormState } from "../MaterialPage";
import { ObjectState } from "@homebound/form-state";
import { MaterialValueTooltip } from "../../plan-package/takeoffs/components/AddItems/MaterialValueTooltip";
import { SelectMaybeNewField } from "src/components/selectMaybeNew/SelectMaybeNewField";

type MaterialDimensionsFormProps = {
  formState: MaterialPageFormState;
  /** This is all available dimensions within the `Item`. */
  dimensions: MaterialCatalog_MaterialAttributeDimensionFragment[];
};

export function MaterialDimensionsForm({ dimensions, formState }: MaterialDimensionsFormProps) {
  // Create a map of `dimensionId` to `mavId` to drive our per-dim Select
  const currentMavByDimension = useComputed(() => {
    return dimensions
      .map((d) => {
        const thisDimsValues = (d.values ?? []).keyBy((mav) => mav.id);
        // The dimension will have a lot of values; find the `formState.mavIds` for this dimension
        return [d.id, formState.mavIds.value?.map((mavId) => thisDimsValues[mavId]).compact().first?.id] as const;
      })
      .toObject();
  }, [dimensions, formState]);
  const [saveMavMutation] = useSaveMavMutation();
  const readOnly = useComputed(() => formState.readOnly, [formState]);

  function updateMavSelection(dimensionId: string, mavId: string | undefined) {
    // Don't worry about updating currentMavByDimension; it will recompute
    const oldMavIdForDim = currentMavByDimension[dimensionId];
    // Remove old, add new...
    formState.mavIds.value = [
      ...(formState.mavIds.value ?? []).filter((mavId) => mavId !== oldMavIdForDim),
      ...(mavId ? [mavId] : []),
    ];
  }

  // Saves a new MAV and updates form state to select the new id
  async function saveNewMAV(dimensionId: string, value: string) {
    const { data } = await saveMavMutation({
      variables: { input: { dimensionId, value } },
      refetchQueries: [MaterialCatalogMetadataDocument],
    });
    updateMavSelection(dimensionId, data?.saveMaterialAttributeValue.materialAttributeValue.id);
  }

  // Placeholder Materials dont care about MADs with useInTakeoff: false
  const mads = useComputed(
    () =>
      dimensions
        .filter((d) => formState.type.value !== MaterialType.Placeholder || d.useInTakeoff)
        .sortByKey("sortOrder"),
    [dimensions, formState.type],
  );

  return (
    <div css={Css.df.flexWrap("wrap").gap2.aife.$}>
      {mads.map(({ id, name, unitOfMeasure, values, type }) => (
        <div key={id} css={Css.w("30%").$}>
          <SelectMaybeNewField
            key={id}
            label={`${name}${unitOfMeasure?.abbreviation ? ` (${unitOfMeasure.abbreviation})` : ""}`}
            value={currentMavByDimension[id]}
            readOnly={readOnly}
            onSelect={(newMavIdForDim) => updateMavSelection(id, newMavIdForDim)}
            options={values?.map((mav) => ({ id: mav.id, name: mav.value })) ?? []}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onAdd={(v) => saveNewMAV(id, v)}
            helperText={
              formState.type.value === MaterialType.Placeholder &&
              type.code === MaterialAttributeDimensionType.Number ? (
                <div>Note: You can specify numeric ranges like '&lt; 10', '&gt; 10' or '10-20'</div>
              ) : null
            }
          />
        </div>
      ))}
    </div>
  );
}

export type MaterialDimensionsFormState = ObjectState<SaveMaterialVariantInput>;
