import { Css, ScrollableContent, useTestIds } from "@homebound/beam";
import { useParams } from "react-router";
import { useProjectLotSummaryPageQuery } from "src/generated/graphql-types";
import { EmptyState } from "src/routes/components/EmptyState";
import {
  basementLines,
  bathroomLines,
  bedroomLines,
  garageLines,
  generalLines,
  PlanDataCard,
  squareFootageLines,
} from "src/routes/developments/plan-and-options/PlanDetailsPage";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ProjectParams } from "src/routes/routesDef";
import { EmptyPlansDetailImage } from "../expenses/components/EmptyStateSvgs";

export function ProjectLotSummaryPage() {
  const tid = useTestIds({}, "ProjectLotSummaryPage");
  const { projectId } = useParams<ProjectParams>();
  const { data, loading } = useProjectLotSummaryPageQuery({ variables: { projectId } });
  const prpc = data?.project.readyPlanConfig;
  const programData = prpc?.programData;
  const elevation = prpc?.elevationOption?.readyPlanOption;

  return (
    <>
      <PageHeader title={loading ? `...` : prpc?.readyPlan?.name ?? ""} />
      <ScrollableContent>
        <div css={Css.bgGray100.mh100.pxPx(60).$}>
          <div css={Css.mb4.$} {...tid.elevation}>
            {!loading && programData && (
              <h1 css={Css.xlBd.gray900.pb3.$} {...tid.elevationTitle}>
                Elevation & Square Footages
              </h1>
            )}
            {!loading &&
              !elevation &&
              (programData ? (
                <EmptyState svg={<EmptyPlansDetailImage />} message="There are no elevations selected yet" />
              ) : (
                <EmptyState svg={<EmptyPlansDetailImage />} message="There are no lot configurations yet" />
              ))}
            {!loading && elevation && (
              <div css={Css.dg.gtc("repeat(auto-fill, 535px)").gap3.add("alignContent", "flex-start").$}>
                <PlanDataCard
                  key={elevation.id}
                  tid={tid}
                  lines={squareFootageLines(programData)}
                  title="Square Footage"
                  subtitle={`${elevation.globalOption.code} - ${elevation.globalOption.name}`}
                  icon="ruler"
                  tooltip="TBD"
                />
              </div>
            )}
          </div>
          <div css={Css.pb3.$}>
            {!loading && programData && (
              <>
                <h1 css={Css.xlBd.gray900.pb3.$} {...tid.OtherTitle}>
                  Bed, Bath, Garage
                </h1>
                <div css={Css.df.gap3.add("flexWrap", "wrap").$}>
                  <div css={Css.df.fdc.gap3.$}>
                    <PlanDataCard
                      tid={tid}
                      lines={generalLines(programData, undefined, prpc?.readyPlan?.description)}
                      title="General"
                      icon="projects"
                    />
                    <PlanDataCard tid={tid} lines={bedroomLines(programData)} title="Bedrooms" icon="bed" />
                    <PlanDataCard tid={tid} lines={bathroomLines(programData)} title="Bathrooms" icon="bath" />
                  </div>
                  <div css={Css.df.fdc.gap3.$}>
                    <PlanDataCard
                      tid={tid}
                      lines={basementLines(programData)}
                      title="Basement"
                      icon="basement"
                      alignIcon={true}
                    />
                    <PlanDataCard tid={tid} lines={garageLines(programData)} title="Garage" icon="car" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </ScrollableContent>
    </>
  );
}
