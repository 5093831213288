import {
  BoundNumberField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  ScrollableContent,
  simpleHeader,
  StaticField,
  useBreakpoint,
  useComputed,
  useModal,
  useResponsiveGrid,
  useResponsiveGridItem,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import isNumber from "lodash/isNumber";
import { Observer } from "mobx-react";
import { DocumentUploader, PdfViewer, priceCell, priceTotal } from "src/components";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { DetailItem, Details } from "src/components/contracts";
import { FormActions, FormMode } from "src/components/formFields";
import {
  BillPage_BillFragment,
  BillPage_CommitmentLineItemFragment,
  BillPage_DocumentFragment,
  BillPage_TradePartnerPaymentTermFragment,
  BillStatus,
  BillType,
  DocumentType,
  ProjectOwnersFragment,
  SaveBillInput,
  SaveBillLineItemInput,
  useSaveBillMutation,
} from "src/generated/graphql-types";
import { PageHeaderActions } from "src/routes/layout/PageHeader";
import { empty, fail, formatList, isDefined } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, ObjectState, required, useFormState } from "src/utils/formState";
import { BillDetailsSection } from "./components/DetailsSection/BillDetailsSection";
import { ReactElement } from "react";
import { BillHistory } from "./components/BillHistory";
import {
  MonitorApprovalSuperdrawerDeepLinks,
  useApprovalSuperDrawer,
} from "src/routes/components/Approval/ApprovalSuperDrawer";
import { calculateBillDueDate } from "./utils";
import { BillBanner } from "./components/BillBanner";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { SubmitApprovalModal } from "../approvals/SubmitApprovalModal";
import { BillFormLineItem, CreateBillFormState } from "src/routes/bills/BillEditorV3";
import { BillActionConfirmationModal } from "src/routes/bills/components/BillActionConfirmationModal";

export type BillEditorProps = {
  mode: FormMode;
  projectId: string;
  currentUserId: string;
  bill: BillPage_BillFragment | undefined;
  project: ProjectOwnersFragment | undefined;
  billParentLineItems: BillPage_CommitmentLineItemFragment[];
  tradePartnerId: string;
  projectStageId: string;
  parentInternalNote: string | undefined;
  onCancel: () => void;
  onEdit: () => void;
  onSave: (formState: SaveBillInput | undefined, triggerRedirect?: boolean) => Promise<void>;
  onDelete: () => Promise<void>;
  onReverse: () => Promise<void>;
  renderCommentFeed?: () => JSX.Element;
  isCredit?: boolean;
  paymentTerm: BillPage_TradePartnerPaymentTermFragment;
};

export function BillEditorV2(props: BillEditorProps) {
  const {
    bill,
    mode,
    billParentLineItems,
    isCredit,
    projectId,
    tradePartnerId,
    projectStageId,
    onDelete,
    onReverse,
    onCancel,
    onEdit,
    onSave,
    renderCommentFeed,
    parentInternalNote,
    paymentTerm,
  } = props;
  const formState = useFormState({
    config: formConfig,
    init: {
      input: bill,
      map: (bill) =>
        // If the bill already exists, allow the ability to bill items from _all_ the bill's parents
        mapToForm(
          bill,
          bill.parents.flatMap((p) => p.lineItems),
        ),
      // If bill is undefined or created manually, its safe to use the lineItems from the direct parent co/cco the bill is created from
      ifUndefined: emptyInput(billParentLineItems, !!isCredit),
    },
    readOnly: mode === "read",
  });

  const [saveBill] = useSaveBillMutation();
  const { openModal } = useModal();

  const { md, smOrMd, sm } = useBreakpoint();
  const { gridItemProps } = useResponsiveGridItem({ colSpan: 2 });
  const { gridStyles } = useResponsiveGrid({ minColumnWidth: md ? 450 : 276, columns: 3, gap: 20 });
  const billEditorTestId = useTestIds({}, "billEditor");
  const { triggerNotice } = useSnackbar();
  const openApproval = useApprovalSuperDrawer();

  const totalsRowData: ComputedTotals = useComputed(
    () =>
      formState.lineItems.rows.reduce(
        (acc, li) => {
          const { otherBilledInCents, thisBilledInCents, unBilledInCents } = calcLineItemValues(
            li,
            !!formState.isTradePartnerCredit.value,
          );
          return {
            otherBilledInCents: acc.otherBilledInCents + otherBilledInCents,
            thisBilledInCents: acc.thisBilledInCents + thisBilledInCents,
            unBilledInCents: acc.unBilledInCents + unBilledInCents,
            costChangeInCents: acc.costChangeInCents + (li.costChangeInCents.value || 0),
          };
        },
        { thisBilledInCents: 0, unBilledInCents: 0, otherBilledInCents: 0, costChangeInCents: 0 },
      ),
    [formState],
  );

  const isChangeRequestedStatus = bill?.status.code === BillStatus.ChangesRequested;
  const isManualDraftBill = bill?.type.code === BillType.Standard && bill.status.code === BillStatus.Draft;
  const isReleasabledBill =
    bill?.type.code === BillType.Deferred && [BillStatus.Draft, BillStatus.Unreleased].includes(bill.status.code);
  const isManualBill = bill?.type.code === BillType.Standard;
  const isAutoBill = !isManualBill;

  return (
    <Observer>
      {() => (
        <>
          <ResponsiveActionsWrapper smOrMd={smOrMd}>
            <div css={Css.if(smOrMd).df.fdr.gap1.else.df.$}>
              {mode === "read" && bill?.canReverse.allowed && (
                <div css={Css.mr1.$}>
                  <Button
                    label="Reverse"
                    variant="secondary"
                    onClick={() =>
                      openModal({
                        content: <BillActionConfirmationModal bill={bill} onConfirm={onReverse} action="reverse" />,
                      })
                    }
                  />
                </div>
              )}
              <FormActions
                {...{ mode, onDelete, onCancel, onEdit, formState }}
                deleteModalContent={
                  bill && <BillActionConfirmationModal bill={bill} onConfirm={onDelete} action="delete" />
                }
                deleteDisabledReason={
                  bill && bill.canDelete.allowed === false
                    ? formatList(bill.canDelete.disabledReasons.map((r) => r.message))
                    : undefined
                }
                editDisableReason={
                  bill && bill.canEdit.allowed === false
                    ? formatList(bill.canEdit.disabledReasons.map((r) => r.message))
                    : bill && isAutoBill && !isChangeRequestedStatus
                      ? "Cannot edit auto bill that is not change requested status"
                      : ""
                }
                onSave={async () => {
                  await onSave(mapToInput(formState.value, tradePartnerId, projectStageId), true);
                  formState.commitChanges();
                }}
              />

              {mode === "read" && isManualDraftBill && (
                // There maybe instances where a bill isn't successfully auto-submitted for approval during creation.
                // e.g. Auto-submission for approval would not run at bill creation if a project PM is not assigned, since there would be no bill approvers.
                // For such instances where a manual bill remains in draft after creation, we render a `Submit for approval` button to allow a manual approval-request.
                <div css={Css.if(sm).mx0.else.mx1.$}>
                  <Button
                    label="Submit for Approval"
                    disabled={disableBasedOnPotentialOperation(bill.canSubmitForApproval)}
                    onClick={() =>
                      openModal({
                        content: (
                          <SubmitApprovalModal
                            subject="Bill"
                            subjectId={bill.id}
                            predictedApprovers={bill.predictedApprovers}
                          />
                        ),
                      })
                    }
                  />
                </div>
              )}
              {bill?.canCancel.allowed && (
                <div css={Css.ml1.$}>
                  <Button
                    variant="danger"
                    label="Cancel"
                    onClick={async () => {
                      const res = await saveBill({
                        variables: { input: { id: bill.id, status: BillStatus.Cancelled } },
                      });
                      if (res.data?.saveBill.bill) {
                        triggerNotice({ message: "Bill has been successfully cancelled" });
                      }
                    }}
                  />
                </div>
              )}
              {isReleasabledBill && (
                <div css={Css.if(sm).mx0.else.mx1.$}>
                  <Button
                    label="Release"
                    onClick={async () => {
                      const res = await saveBill({
                        // Pass bill status as ReleaseScheduled when unreleased bills are released
                        variables: { input: { id: bill.id, status: BillStatus.ReleaseScheduled } },
                      });
                      if (res.data?.saveBill.bill) {
                        triggerNotice({
                          message: `Bill ${bill.tradePartnerNumber} sent to ${bill.tradePartner.name} for Approval`,
                        });
                      }
                    }}
                  />
                </div>
              )}
              {isDefined(bill?.approval) && (
                <div css={Css.if(sm).mx0.else.mx1.$}>
                  <Button label="View Approval" onClick={() => openApproval(bill?.approval?.id)} />
                </div>
              )}
            </div>
          </ResponsiveActionsWrapper>

          {mode === "read" && <BillBanner billId={bill!.id} hasSyncError={Boolean(bill!.intacctSyncError)} />}

          <ScrollableContent>
            <div css={{ ...gridStyles }} {...billEditorTestId}>
              <div {...gridItemProps}>
                <div css={Css.mt2.$}>
                  {mode !== "read" ? (
                    <div css={Css.p3.mb3.ba.bcGray400.add({ borderRadius: "8px" }).$}>
                      <DocumentUploader
                        documentType={DocumentType.Bill}
                        message="Upload Files"
                        multiple={true}
                        file={undefined}
                        onFinish={(file) => {
                          if (file && !formState.documents.value.some(({ asset }) => asset.id === file.asset.id)) {
                            formState.documents.add(file);
                          }
                        }}
                        {...{ projectId }}
                        error={formState.documents.touched ? formState.documents.errors.join(" ") : undefined}
                      />
                    </div>
                  ) : null}

                  {formState.documents.value.length > 0 ? (
                    <div css={Css.bgWhite.bshBasic.br8.mhPx(400).oa.$}>
                      <PdfViewer
                        hasHeader
                        assets={formState.documents.value.map(({ asset }) => asset)}
                        {...(mode !== "read" && {
                          handlePdfDelete: (assetId: string) =>
                            formState.documents.remove(
                              formState.documents.value.findIndex((d) => d.asset.id === assetId),
                            ),
                        })}
                        // Manual bill pdf pages are truncated compared to the increase width we've given for pdfPreview in the bill screen layout.
                        // Increase pdf page width on manual bills for readability
                        pdfPageWidth={isManualBill ? 1100 : 900}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                {mode === "read" ? (
                  <BillDetailsSection bill={bill!} />
                ) : (
                  <Details detailItems={createDetailItems(formState, bill, mode, parentInternalNote, paymentTerm)} />
                )}

                <div css={Css.mt3.bshBasic.br8.$}>
                  <GridTable
                    columns={createLineItemColumns(formState, sm)}
                    rows={createLineItemRows(formState, totalsRowData)}
                    style={{ bordered: true, allWhite: true }}
                  />
                </div>
                {renderCommentFeed && renderCommentFeed()}
                {mode === "read" && bill && (
                  <div css={Css.mt3.$}>
                    <BillHistory historyItems={bill.history} />
                  </div>
                )}
              </div>
            </div>
          </ScrollableContent>
          <MonitorApprovalSuperdrawerDeepLinks />
        </>
      )}
    </Observer>
  );
}
function createDetailItems(
  formState: FormState,
  bill: BillPage_BillFragment | undefined,
  mode: FormMode,
  parentInternalNote: string | undefined,
  paymentTerm: BillPage_TradePartnerPaymentTermFragment,
): DetailItem[] {
  const parentField = (
    <BoundTextField
      data-testid="tradePartnerNumber"
      field={formState.tradePartnerNumber}
      label={formState.isTradePartnerCredit.value ? "Credit #" : "Bill #"}
      helperText={`Enter the trade partner’s ${formState.isTradePartnerCredit.value ? "credit" : "bill"} number`}
    />
  );
  const billDateField = (
    <BoundBeamDateField
      field={formState.billDate}
      format="long"
      label={formState.isTradePartnerCredit.value ? "Credit Date" : "Bill Date"}
      helperText={`Enter the date on the trade partner’s ${formState.isTradePartnerCredit.value ? "credit" : "bill"}`}
      readOnly={mode === "read"}
      onChange={(val) => setBillDateAndDueDate(val, formState, paymentTerm)}
    />
  );
  // if paymentTerm object is present, we would disable the due date field and auto calculate the date on change
  const dueDateField = (
    <BoundBeamDateField
      data-testid="dueDate"
      field={formState.dueDate}
      format="long"
      label="Due Date"
      helperText="Enter the date we need to pay this bill by"
      readOnly={mode === "read"}
    />
  );
  const postedDateField =
    mode !== "create" && isDefined(formState.postedDate?.value) ? (
      <BoundBeamDateField field={formState.postedDate} format="long" readOnly />
    ) : null;
  const paidDateField = <BoundBeamDateField field={formState.paidDate} format="long" readOnly />;
  const paidAmountField = <BoundNumberField field={formState.paidInCents} readOnly />;
  const internalNoteField = (
    <BoundTextAreaField
      field={formState.internalNote}
      label={`Internal Description${!formState.readOnly ? " (optional)" : ""}`}
      readOnly={mode === "read"}
    />
  );
  // Identifier: parentInternalNote
  const parentInternalNoteField = (
    <StaticField data-testid="parentInternalNote" value={parentInternalNote} label="Commitment Note" />
  );

  const showPaid = mode !== "create" && isDefined(formState.paidDate?.value);

  const detailItems = [
    { component: parentField, spanColumns: true },
    { component: billDateField },
    { component: dueDateField },
    { component: postedDateField },
    ...(showPaid ? [{ component: paidDateField }, { component: paidAmountField }] : []),
    { component: internalNoteField, spanColumns: true },
    ...(parentInternalNote ? [{ component: parentInternalNoteField, spanColumns: true }] : []),
  ];

  if (mode === "read") {
    if (bill) {
      detailItems.unshift({
        component: (
          <StaticField
            value={bill.approval ? `${bill.approval.name} (${bill.approval.status.name})` : ""}
            label="Approval"
          />
        ),
        spanColumns: true,
      });
    }

    if (bill?.intacctSyncError) {
      // styling an input in the old form components. This should be upgraded to use the new beam components and use StaticField
      detailItems.unshift({
        component: (
          <div css={Css.addIn("& textarea", Css.red700.important.$).$} data-testid="syncError">
            <StaticField value={bill?.intacctSyncError} label="Sync Error" data-testid="syncErrorMessage" />
          </div>
        ),
        spanColumns: true,
      });
    }
  }

  return detailItems;
}

type HeaderRow = { kind: "header" };
type LineItemRow = { kind: "lineItem"; data: FormState["lineItems"]["rows"][0] };
type ParentRow = { kind: "parent"; data: string };
type TotalRow = {
  kind: "total";
  data: {
    thisBilledInCents: number;
    otherBilledInCents: number;
    unBilledInCents: number;
    costChangeInCents: number;
  };
};
type Row = HeaderRow | LineItemRow | ParentRow | TotalRow;

function createLineItemRows(formState: FormState, totalsRowData: ComputedTotals): GridDataRow<Row>[] {
  const groupedParentsPOs = formState.lineItems.rows.groupBy((r) => String(r.owner.accountingNumber.value));
  let rows: GridDataRow<Row>[];
  if (groupedParentsPOs.toKeys().length > 1) {
    rows = Object.keys(groupedParentsPOs).map((acctNumber) => {
      const childItemRows = groupedParentsPOs[acctNumber];
      const isCo = childItemRows.first?.owner.id.value.startsWith("cco");
      return {
        kind: "parent" as const,
        id: String(acctNumber),
        data: isCo ? `CO# ${acctNumber}` : `PO# ${acctNumber}`,
        children: childItemRows.map((r) => ({
          kind: "lineItem" as const,
          id: r.commitmentLineItemId.value!.toString(),
          data: r,
        })),
      };
    });
  } else {
    rows = formState.lineItems.rows.map((row, i) => ({
      kind: "lineItem" as const,
      id: row.commitmentLineItemId.value ?? fail("commitmentLineItemId is required"),
      data: row,
    }));
  }

  return [simpleHeader, ...rows, { kind: "total" as const, id: "total", data: totalsRowData }];
}

function createLineItemColumns(formState: FormState, sm: boolean): GridColumn<Row>[] {
  const isCredit = !!formState.isTradePartnerCredit.value;
  const label = isCredit ? "Credit" : "Bill";
  const responsiveWidth = sm ? 0.3 : 1;

  const costCodeColumn = column<Row>({
    header: () => "Project Item",
    parent: (data) => ({ content: <div data-testid="parentPo">{data}</div> }),
    lineItem: ({ displayName }) => displayName.value,
    total: () => ({ alignment: "right", content: "Total:" }),
    w: responsiveWidth,
  });

  const costColumn = numericColumn<Row>({
    header: () => "Committed",
    parent: emptyCell,
    lineItem: ({ costChangeInCents }) => priceCell({ id: "cost", valueInCents: costChangeInCents.value }),
    total: (row) => priceTotal({ valueInCents: row.costChangeInCents, id: "costTotal" }),
    w: responsiveWidth,
  });

  const otherBillsColumn = numericColumn<Row>({
    header: () => "Other Bills",
    parent: emptyCell,
    lineItem: (data) =>
      priceCell({ id: "otherBills", valueInCents: calcLineItemValues(data, isCredit).otherBilledInCents }),
    total: (row) => priceTotal({ valueInCents: row.otherBilledInCents, id: "otherBillsTotal" }),
    w: responsiveWidth,
  });

  const billAmountColumn = numericColumn<Row>({
    header: () => `${label} Amount`,
    parent: emptyCell,
    lineItem: ({ amountInCents }) => (
      <BoundNumberField
        data-testid="amount"
        field={amountInCents}
        type="cents"
        // TODO: Remove this `error` property once we are able to reference other lineItems from within this field's `rules`
        errorMsg={formState.lineItems.touched ? formState.lineItems.errors.join(" ") : undefined}
      />
    ),
    total: (row) => priceTotal({ valueInCents: row.thisBilledInCents, id: "amountTotal" }),
    w: responsiveWidth,
  });

  const unbilledColumn = numericColumn<Row>({
    header: () => "Unbilled",
    parent: emptyCell,
    lineItem: (data) =>
      priceCell({
        id: "unbilled",
        valueInCents: calcLineItemValues(data, isCredit).unBilledInCents,
      }),
    total: (row) => priceTotal({ valueInCents: row.unBilledInCents, id: "unbilledTotal" }),
    w: responsiveWidth,
  });

  return [costCodeColumn, ...(!sm ? [costColumn, otherBillsColumn] : []), billAmountColumn, unbilledColumn];
}

export type ComputedTotals = {
  thisBilledInCents: number;
  otherBilledInCents: number;
  unBilledInCents: number;
  costChangeInCents: number;
  revisedApprovedBudgetInCents?: number;
};

export type FormLineItem = Omit<SaveBillLineItemInput, "costCode"> &
  Pick<BillPage_CommitmentLineItemFragment, "pendingBilledInCents" | "costChangeInCents" | "owner"> & {
    displayName: string;
    bliId?: string;
    isPendingOrBilled: boolean;
  };

type FormInput = Omit<
  SaveBillInput,
  "tradePartnerId" | "balanceInCents" | "documents" | "quickbooksId" | "lineItems" | "dueDate" | "billDate"
> & {
  documents: BillPage_DocumentFragment[];
  lineItems: FormLineItem[];
  dueDate?: DateOnly | null;
  billDate?: DateOnly | null;
  paidInCents?: number | null;
};

type FormState = ObjectState<FormInput>;

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  tradePartnerNumber: { type: "value", rules: [required] },
  billDate: { type: "value", rules: [required] },
  dueDate: { type: "value" },
  postedDate: { type: "value" },
  paidDate: { type: "value" },
  paidInCents: { type: "value" },
  internalNote: { type: "value" },
  documents: {
    type: "list",
    rules: [required],
    config: {
      id: { type: "value" },
      name: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          attachmentUrl: { type: "value" },
          contentType: { type: "value" },
          createdAt: { type: "value" },
          downloadUrl: { type: "value" },
          version: { type: "value" },
        },
      },
    },
  },
  isTradePartnerCredit: { type: "value" },
  lineItems: {
    type: "list",
    config: {
      id: { type: "value", isIdKey: false },
      bliId: { type: "value", readOnly: true },
      displayName: { type: "value" },
      costChangeInCents: { type: "value" },
      // The list of line items in the UI is always driven from the parent's CLIs, which may or may not
      // have a Bill Line Item associated with it (the `id` key). So for form-state identity purposes,
      // treat this as the unique key. This _may_ solve a bug Kirsten was seeing with form-lines being
      // duplicated, but we've not been able to reproduce it, so this is kind of a guess.
      commitmentLineItemId: { type: "value", isIdKey: true },
      amountInCents: { type: "value" },
      pendingBilledInCents: { type: "value" },
      isPendingOrBilled: { type: "value" },
      owner: { type: "object", config: { id: { type: "value" }, accountingNumber: { type: "value" } } },
    },
    // TODO: Ideally, the below rule would apply to the `amountInCents` directly. We can't do this now because the rule needs access to all `amountInCents` line items, not just itself.
    rules: [
      ({ value }) => {
        return value.find((li) => li.amountInCents.value !== undefined) ? undefined : "At least one line item required";
      },
    ],
  },
};

function mapToForm(bill: BillPage_BillFragment, billParentLineItems: BillPage_CommitmentLineItemFragment[]): FormInput {
  const { type, status, ...others } = bill;
  const input: FormInput = {
    ...others,
    // lineitems needs to be the list of the cost codes from the Commitment, and then match each of those to the BillLine Items to get the amountInCents
    lineItems: billParentLineItems.map((cli) => {
      // See if we have a bill line item associated with the commitment's line item first.
      const bli = bill.lineItems.find((bli) => bli.commitmentLineItem?.id === cli.id);
      return {
        id: cli.id,
        bliId: bli?.id,
        amountInCents: bli?.amountInCents,
        isPendingOrBilled: bill.isPendingOrBilled,
        displayName: cli.projectItem.displayName,
        pendingBilledInCents: cli.pendingBilledInCents,
        commitmentLineItemId: cli.id,
        costChangeInCents: cli.costChangeInCents,
        owner: cli.owner,
      };
    }),
  };

  return input;
}

function emptyInput(billParentLineItems: BillPage_CommitmentLineItemFragment[], isCredit: boolean): FormInput {
  return {
    ...empty<FormInput>(),
    isTradePartnerCredit: isCredit,
    documents: [],
    lineItems: billParentLineItems.map((cli) => {
      return {
        id: cli.id,
        bliId: undefined,
        amountInCents: undefined,
        isPendingOrBilled: true,
        displayName: cli.projectItem.displayName,
        commitmentLineItemId: cli.id,
        costChangeInCents: cli.costChangeInCents,
        pendingBilledInCents: cli.pendingBilledInCents,
        owner: cli.owner,
      };
    }),
  };
}

export function calcLineItemValues(li: ObjectState<FormLineItem> | ObjectState<BillFormLineItem>, isCredit?: boolean) {
  const {
    pendingBilledInCents: { value: pendingBilledInCents = 0 },
    costChangeInCents: { value: costChangeInCents = 0 },
    isPendingOrBilled: { value: isPendingOrBilled = true },
  } = li;

  const sign = isCredit ? -1 : 1;
  const amountInCents = isPendingOrBilled ? Number(li.amountInCents.value) || 0 : 0;
  const amountInCentsOriginal = isPendingOrBilled ? Number(li.amountInCents.originalValue) || 0 : 0;
  // We do not want to include this bill amount in the "billedInCents" column, so extract this amount out.
  const otherBilledInCents = Number(pendingBilledInCents) - amountInCentsOriginal * sign;
  return {
    otherBilledInCents,
    thisBilledInCents: amountInCents,
    unBilledInCents: Number(costChangeInCents) - (otherBilledInCents + amountInCents * sign),
  };
}

function mapToInput(formStateValue: FormInput, tradePartnerId: string, projectStageId: string): SaveBillInput {
  // Drop postedDate, paidDate, paidInCents b/c they're all read only
  const { documents, dueDate, billDate, postedDate, paidDate, paidInCents, ...others } = formStateValue;
  return {
    ...others,
    projectStageId,
    tradePartnerId,
    documents: documents.map(({ id }) => id),
    dueDate: dueDate && new DateOnly(dueDate),
    billDate: billDate && new DateOnly(billDate),
    lineItems: formStateValue.lineItems
      // Filter by lineItems that have a value entered for the bill
      .filter((li) => isNumber(li.amountInCents))
      .map((li) => ({
        id: li.bliId,
        commitmentLineItemId: li.commitmentLineItemId,
        amountInCents: li.amountInCents,
      })),
  };
}

function ResponsiveActionsWrapper(props: { smOrMd: boolean; children: ReactElement }) {
  const { smOrMd, children } = props;
  return !smOrMd ? <PageHeaderActions>{children}</PageHeaderActions> : children;
}

export function setBillDateAndDueDate(
  date: Date | undefined,
  formState: FormState | CreateBillFormState,
  paymentTerm: BillPage_TradePartnerPaymentTermFragment | undefined,
) {
  // set the bill date
  const billDate = date ? new DateOnly(date) : null;
  formState.billDate.set(billDate);
  // Auto-calculate bill due date
  if (billDate && paymentTerm) {
    const { id, ...termDetails } = paymentTerm;
    const billDueDate = calculateBillDueDate(billDate, termDetails);
    formState.dueDate.set(billDueDate);
  }
}
