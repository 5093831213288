import { Accordion, BoundSelectField, BoundSwitchField, BoundTextAreaField, Button, Css } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import {
  WarrantyTicketItemDetailsFragment,
  WarrantyTicketItemStatusesFragment,
  WarrantyTicketItemTypeDetail,
} from "src/generated/graphql-types";
import { WarrantyTicketItemStatusSelect } from "./WarrantyTicketItemStatusSelect";
import { BoundAttachments } from "src/components/boundAttachments/BoundAttachments";
import { TicketItemFormInput } from "../WarrantyTicketPage";
import { isDefined, truncateString } from "src/utils";
import { getWarrantyItemDurationChip } from "../WarrantyPage";

type WarrantyListCardProps = {
  itemState: ObjectState<TicketItemFormInput>;
  warrantyTicketItemStatuses: WarrantyTicketItemStatusesFragment[];
  warrantyTicketItemTypes: WarrantyTicketItemTypeDetail[];
  onDelete: () => void;
  warrantyTicketItem: WarrantyTicketItemDetailsFragment;
  homeownerVisibleDisabled?: string;
};

export function WarrantyListCard(props: WarrantyListCardProps) {
  const {
    itemState,
    warrantyTicketItemStatuses,
    warrantyTicketItemTypes,
    onDelete,
    homeownerVisibleDisabled,
    warrantyTicketItem,
  } = props;
  const duration = getWarrantyItemDurationChip(warrantyTicketItem);

  return (
    <Accordion
      compact
      topBorder={false}
      title={
        <div css={Css.df.gap2.aic.w("90%").$}>
          <div css={Css.truncate.w25.smSb.$}>{truncateString(itemState.description.value!, 35)}</div>
          <div
            css={Css.wPx(140).$}
            onClick={(e) => {
              // prevent clicking on the select from toggling the accordion
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <WarrantyTicketItemStatusSelect options={warrantyTicketItemStatuses} statusField={itemState.status} />
          </div>
          {duration ? duration.content : <></>}
        </div>
      }
    >
      <div css={Css.df.fdc.aic.py3.$}>
        <div css={Css.df.fdc.gap2.w75.$}>
          <BoundTextAreaField field={itemState.description} fullWidth />
          <BoundSelectField
            options={warrantyTicketItemTypes}
            field={itemState.type}
            getOptionValue={(ts) => ts.code}
            getOptionLabel={(ts) => ts.name}
            fullWidth
          />
          <BoundAttachments field={itemState.attachments} />
          <div css={Css.df.aic.$}>
            <Button
              label="Delete Request"
              icon="trash"
              variant="tertiaryDanger"
              onClick={onDelete}
              disabled={isDefined(itemState.id.value) ? false : "Item creation in progress"}
            />

            <div css={Css.mla.$}>
              <BoundSwitchField
                field={itemState.homeownerVisible}
                labelStyle="inline"
                disabled={homeownerVisibleDisabled}
              />
            </div>
          </div>
        </div>
      </div>
    </Accordion>
  );
}
