import {
  Button,
  ButtonMenu,
  ButtonModal,
  Css,
  GridTable,
  Icon,
  MenuItem,
  ModalProps,
  Palette,
  ResponsiveGrid,
  ResponsiveGridItem,
  actionColumn,
  column,
  emptyCell,
  simpleHeader,
  useModal,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  createCalendarViewScheduleUrl,
  // createGanttViewScheduleUrl,
  createListViewScheduleUrl,
  createMilestoneCatalogFormUrl,
  createMilestoneDetailsPageUrl,
  createMilestoneViewScheduleUrl,
} from "src/RouteUrls";
import { ProgressPill, SearchBox, formatDate } from "src/components";
import {
  DynamicSchedulesMilestone_PlanMilestoneFragment,
  Order,
  useDynamicSchedulesMilestoneQuery,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { pluralize, queryResult } from "src/utils";
import { useQueryStorage } from "../../schedule-v2/table/filterUtils";
import { TableActions } from "src/routes/layout/TableActions";
import {
  CustomDynamicSchedulesMilestoneFilter,
  DynamicSchedulesMilestoneFilterModal,
  customDynamicSchedulesMilestoneFilterDefault,
  mapToFilter,
} from "../components/DynamicSchedulesMilestoneFilterModal";
import { PlanScheduleConfidence } from "../components/PlanScheduleConfidence";
import { ScheduleGridWrapper } from "../draft-mode/ScheduleDraftMode";
import { useScheduleSnapshots, ScheduleSnapshotsHookResult } from "../components/useScheduleSnapshots";
import { ScheduleDateVariance } from "../components/ScheduleVariance";

export function DynamicSchedulesMilestone() {
  const { projectId } = useParams<{ projectId: string }>();
  const [search, setSearch] = useState("");
  const history = useHistory();

  const { queryStorage, setQueryStorage } = useQueryStorage({
    storageKey: "dynamicSchedulesMilestoneFilter",
    initialQueryStorage: customDynamicSchedulesMilestoneFilterDefault,
  });

  const showFilterModal = useCallback(
    (close: VoidFunction) => {
      return <DynamicSchedulesMilestoneFilterModal filter={queryStorage} setFilter={setQueryStorage} onClose={close} />;
    },
    [queryStorage, setQueryStorage],
  );

  const handleClear = useCallback(() => {
    setQueryStorage({} as CustomDynamicSchedulesMilestoneFilter);
  }, [setQueryStorage]);

  const hasFilter = useMemo(() => Object.values(queryStorage).some(Boolean), [queryStorage]);

  const menuItems: MenuItem[] = [
    {
      label: "List View",
      onClick: () => {
        history.push(createListViewScheduleUrl(projectId));
        // clear out milestone view filters for other page views
        setQueryStorage({} as CustomDynamicSchedulesMilestoneFilter);
      },
    },
    // [SC-55189] Hiding the Gantt view until we come back to implementing them
    // {
    //   label: "Gantt View",
    //   onClick: () => {
    //     history.push(createGanttViewScheduleUrl(projectId));
    //     // clear out milestone view filters for other page views
    //     setQueryStorage({} as CustomDynamicSchedulesMilestoneFilter);
    //   },
    // },
    {
      label: "Calendar View",
      onClick: () => {
        history.push(createCalendarViewScheduleUrl(projectId));
        // clear out milestone view filters for other page views
        setQueryStorage({} as CustomDynamicSchedulesMilestoneFilter);
      },
    },
    {
      label: "Milestone View",
      onClick: createMilestoneViewScheduleUrl(projectId),
    },
  ];

  const query = useDynamicSchedulesMilestoneQuery({
    variables: {
      filter: {
        scheduleParent: [projectId],
        ...mapToFilter(queryStorage),
        name: search,
      },
      page: {
        offset: 0,
        limit: 200,
      },
      orderBy: { estStartDate: Order.Asc },
    },
  });

  return queryResult(query, ({ planMilestones: { entities } }) => {
    return (
      <ScheduleGridWrapper>
        <TableActions>
          <div css={Css.df.gap1.$} data-testid="dynamicSchedulesMilestoneView">
            <ButtonModal content={showFilterModal} trigger={{ label: "Filter" }} />
            <SearchBox clearable onSearch={setSearch} />
            {hasFilter && <Button variant="text" label="Clear" onClick={handleClear} />}
          </div>
          <div css={Css.df.jcsb.gap1.mr2.$}>
            <ButtonMenu items={menuItems} trigger={{ label: `View: Milestone` }} />
            <Button label="Create Milestone" onClick={createMilestoneCatalogFormUrl("add")} variant="secondary" />
          </div>
        </TableActions>
        <DynamicSchedulesMilestoneTable planMilestones={entities} projectId={projectId} />
      </ScheduleGridWrapper>
    );
  });
}

type DynamicSchedulesMilestoneTableProps = {
  planMilestones: DynamicSchedulesMilestone_PlanMilestoneFragment[];
  projectId: string;
};

export function DynamicSchedulesMilestoneTable({ planMilestones, projectId }: DynamicSchedulesMilestoneTableProps) {
  const { getPlanMilestoneSnapshot } = useScheduleSnapshots(projectId);

  const { openModal } = useModal();
  const history = useHistory();
  const columns = useMemo(
    () => createColumns(openModal, history, getPlanMilestoneSnapshot),
    [history, openModal, getPlanMilestoneSnapshot],
  );
  const rows = useMemo(() => createRows(planMilestones), [planMilestones]);

  return (
    <div css={Css.w100.h("calc(100vh - 160px)").$}>
      <GridTable
        as="virtual"
        columns={columns}
        rows={rows}
        rowStyles={{
          header: { cellCss: Css.bgGray100.$ },
          data: {
            rowLink: (row) => createMilestoneDetailsPageUrl(projectId, row.data.id),
          },
        }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: DynamicSchedulesMilestone_PlanMilestoneFragment };

export type Row = HeaderRow | DataRow;

function createColumns(
  openModal: (props: ModalProps) => void,
  history: ReturnType<typeof useHistory>,
  getPlanMilestoneSnapshot: ScheduleSnapshotsHookResult["getPlanMilestoneSnapshot"],
) {
  return [
    column<Row>({
      header: { content: "Milestones", css: Css.lgSb.jcfs.gray900.pl0.aife.$ },
      data: ({ name }) => name,
      mw: "120px",
    }),
    column<Row>({
      header: "Est. Start",
      data: ({ estStartDate }) => formatDate(estStartDate?.date, "monthShort"),
      mw: "75px",
    }),
    column<Row>({
      header: "Est. End",
      data: ({ estEndDate, id }) => (
        <>
          {formatDate(estEndDate?.date, "monthShort")}
          <ScheduleDateVariance current={estEndDate} baseline={getPlanMilestoneSnapshot(id)?.estEndDate} />
        </>
      ),
      mw: "75px",
    }),
    column<Row>({
      header: "Progress",
      data: ({ progress }) => <ProgressPill progress={progress} />,
      mw: "75px",
    }),
    column<Row>({
      header: () => (
        <div css={Css.df.jcsb.gap1.aic.$}>
          <div>Confidence</div>
          <Icon
            color={Palette.Gray600}
            inc={2}
            icon="infoCircle"
            tooltip="The certainty of a milestone ending on the estimated end date, based on an average of the maximum end dates of constrained tasks in this milestone. (High, Medium, Low, or Complete)"
          />
        </div>
      ),
      data: ({ confidence, progress }) => (
        <PlanScheduleConfidence
          probabilityBasisPoints={confidence}
          isComplete={progress === 100}
          entityType="milestone"
        />
      ),
      mw: "75px",
    }),
    column<Row>({
      header: "Duration",
      data: ({ durationInDays }) => (
        <span>
          {durationInDays} {pluralize(durationInDays, "day", "days")}
        </span>
      ),
      mw: "75px",
    }),
    actionColumn<Row>({
      header: emptyCell,
      data: (row) => (
        <ButtonMenu
          trigger={{ icon: "verticalDots" }}
          items={[
            {
              label: "Edit Milestone",
              onClick: () =>
                openModal({
                  content: (
                    <ConfirmationModal
                      confirmationMessage={
                        <div css={Css.sm.$}>
                          {" "}
                          This milestone is used in all other projects. Editing this milestone will update it
                          everywhere. Would you like to proceed with editing the milestone?
                        </div>
                      }
                      label="Yes, Edit Milestone"
                      onConfirmAction={() => history.push(createMilestoneCatalogFormUrl(row.globalPlanMilestone.id))}
                      title="Edit Milestone"
                    />
                  ),
                }),
            },
          ]}
        />
      ),
      w: "48px",
    }),
  ];
}

function createRows(data: DynamicSchedulesMilestone_PlanMilestoneFragment[]) {
  return [
    simpleHeader,
    ...data.map((pm) => ({
      id: pm.id,
      kind: "data" as const,
      data: pm,
    })),
  ];
}
