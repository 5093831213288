import {
  BoundDateField,
  BoundNumberField,
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { capitalCase } from "change-case";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import { BoundFileUploader, CaptionText, MarkupSummary, Price } from "src/components";
import {
  ContractType,
  DocumentEditorDetailFragment,
  DocumentType,
  Maybe,
  SaveHomeownerContractInput,
  Stage,
  useSaveHomeownerContractChangeOrderMutation,
  useSaveHomeownerContractMutation,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { costPlusMarkupRule } from "src/routes/projects/homeowner-contracts/utils";
import { createHomeownerContractsUrl, createSpecsAndSelectionsUrl } from "src/RouteUrls";
import { pushInNextLoop } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { openNewTab } from "src/utils/window";

type CreateContractModalProps = {
  estimateId: string;
  projectId: string;
  projectStageId: string;
  estimatedCost: number;
  homeownerPrice: number;
  isChangeOrder: boolean;
  stage: Stage;
};

export function CreateContractModal(props: CreateContractModalProps) {
  const { projectId, estimateId, estimatedCost, homeownerPrice, projectStageId, isChangeOrder, stage } = props;
  const formState = useFormState({
    config: formConfig,
    addRules(state) {
      state.costPlusMarkupBasisPoints.rules.push(costPlusMarkupRule(state));
    },
    init: {
      contractType: ContractType.Fixed,
      costPlusMarkupBasisPoints: undefined,
      document: null,
      executionDate: new Date(),
    },
  });
  const [createContract] = useSaveHomeownerContractMutation();
  const [createChangeOrder] = useSaveHomeownerContractChangeOrderMutation();
  const { closeModal } = useModal();
  const [showChangeOrderConfirmation, toggleShowChangeOrderConfirmation] = useToggle(false);

  const history = useHistory();

  const documentType =
    isChangeOrder && stage === Stage.PreConExecution
      ? DocumentType.PreconAgreementChangeOrder
      : isChangeOrder && stage === Stage.Construction
        ? DocumentType.ConstructionAgreementChangeOrder
        : stage === Stage.PreConExecution
          ? DocumentType.PreconAgreement
          : DocumentType.ConstructionAgreement;

  const title = capitalCase(documentType);

  if (showChangeOrderConfirmation) {
    return (
      <>
        <ModalHeader>Change Order Complete</ModalHeader>
        <ModalBody>
          This change order has been successfully completed. All changes are now in the Homeowner contract.
          <br />
          <br />
          View these changes in Specs and Selections.
        </ModalBody>
        <ModalFooter>
          <Observer>
            {() => (
              <>
                <Button variant="tertiary" label="Close" onClick={closeModal} />
                <Button
                  endAdornment={<Icon icon="linkExternal" inc={2} />}
                  label="Specs and Selections"
                  onClick={() => {
                    openNewTab(createSpecsAndSelectionsUrl(projectId));
                    closeModal();
                  }}
                />
              </>
            )}
          </Observer>
        </ModalFooter>
      </>
    );
  }

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            <div css={Css.mt3.df.fdc.gap2.$}>
              <div css={Css.dif.gap8.$}>
                <div>
                  <CaptionText>Cost</CaptionText>
                  <Price valueInCents={estimatedCost} />
                </div>
                <div>
                  <CaptionText>Markup</CaptionText>
                  <MarkupSummary budget={estimatedCost} price={homeownerPrice} />
                </div>
                <div>
                  <CaptionText>Price</CaptionText>
                  <Price valueInCents={homeownerPrice} />
                </div>
              </div>

              <BoundSelectField
                field={formState.contractType}
                options={[
                  { name: "Fixed", id: ContractType.Fixed },
                  {
                    name: "Cost Plus",
                    id: ContractType.CostPlus,
                  },
                ]}
              />
              {formState.contractType.value === ContractType.CostPlus && (
                <BoundNumberField field={formState.costPlusMarkupBasisPoints} label="Cost Plus %" type="basisPoints" />
              )}

              <BoundDateField field={formState.executionDate} format="short" />
              <BoundTextField label="Internal Description (optional)" field={formState.internalNote} />
              <BoundFileUploader
                projectId={projectId}
                message="Upload Executed Agreement"
                documentType={documentType}
                field={formState.document}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Observer>
              {() => (
                <>
                  <Button variant="tertiary" label="Close" onClick={closeModal} />
                  <Button
                    disabled={!formState.valid}
                    label={`Complete ${isChangeOrder ? "Change Order" : "Contract"}`}
                    onClick={async () => {
                      if (formState.canSave()) {
                        if (!isChangeOrder) {
                          const res = await createContract({
                            variables: { input: mapToInput(formState.value, estimateId, title, projectStageId) },
                          });
                          const contractId = res.data?.saveHomeownerContract.homeownerContract.id;
                          formState.commitChanges();
                          pushInNextLoop(history, createHomeownerContractsUrl(projectId, contractId));
                          closeModal();
                        } else {
                          const res = await createChangeOrder({
                            variables: { input: mapToInput(formState.value, estimateId, title) },
                          });
                          const changeOrderId =
                            res.data?.saveHomeownerContractChangeOrder.homeownerContractChangeOrder.id;
                          formState.commitChanges();
                          if (!!changeOrderId) {
                            toggleShowChangeOrderConfirmation();
                          }
                        }
                      }
                    }}
                  />
                </>
              )}
            </Observer>
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type FormValue = Pick<
  SaveHomeownerContractInput,
  "contractType" | "internalNote" | "projectStageId" | "costPlusMarkupBasisPoints"
> & {
  document: DocumentEditorDetailFragment | null | undefined;
  executionDate: Maybe<Date>;
};

function mapToInput(
  formValue: FormValue,
  estimateId: string,
  title: string,
  projectStageId?: string,
): SaveHomeownerContractInput {
  return {
    contractType: formValue.contractType,
    documentId: formValue.document?.id,
    internalNote: formValue.internalNote,
    executionDate: new DateOnly(formValue.executionDate as Date),
    costPlusMarkupBasisPoints: formValue.costPlusMarkupBasisPoints,
    projectStageId,
    estimateId,
    title,
  };
}

const formConfig: ObjectConfig<FormValue> = {
  document: { type: "value", rules: [required] },
  executionDate: { type: "value", rules: [required] },
  internalNote: { type: "value" },
  contractType: { type: "value", rules: [required] },
  costPlusMarkupBasisPoints: { type: "value" },
};
