import { useHistory, useParams } from "react-router-dom";
import {
  ItivOrderField,
  Maybe,
  Order,
  ProductOfferingScopeMetaQuery,
  ProductOfferingScopeMeta_BidPackageFragment,
  ProductOfferingScopePage_GroupTotalsFragment,
  ProductOfferingScopePage_ItivFragment,
  useProductOfferingScopeMetaQuery,
  useProductOfferingScopePageQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ProductOfferingParams } from "src/routes/routesDef";
import { foldEnum, pluralize, queryResult } from "src/utils";
import {
  createBidPackagesDetailUrl,
  createDevelopmentContractUrl,
  createProductOfferingEditUrl,
  createProductOfferingsUrl,
} from "src/RouteUrls";
import {
  Button,
  Css,
  GridDataRow,
  Icon,
  LoadingSkeleton,
  ScrollableContent,
  Tooltip,
  collapseColumn,
  column,
  emptyCell,
  numericColumn,
  useToast,
} from "@homebound/beam";
import { ProductOfferingContextProvider, useProductOfferingStore } from "./ProductOfferingScopeContext";
import { ArchivedTag } from "src/components/ArchivedTag";
import { TruncatedNameListCell } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsTable/TruncatedNameListCell";
import { useCallback, useMemo, useRef } from "react";
import { priceCell } from "src/components";
import { CostSourceCell } from "./components/CostSourceCell";
import { EmptyState } from "src/routes/components/EmptyState";
import { EmptyPlanAndOptionImage } from "../plan-and-options/components/EmptyStateSvgs";
import { SideNavLayout } from "src/components/layout/SideNavLayout";
import { SideNavProps } from "src/components/layout/SideNav";
import { ProductOfferingScopePageSideBar } from "./ProductOfferingScopePageSideBar";
import { StringParam, useQueryParams } from "use-query-params";
import { DropCodeCell } from "./components/DropCodeCell";
import { subFilterByOrder } from "src/utils/itemTemplateItem";
import { ProductOfferingScopeTable } from "./components/ProductOfferingScopeTable";
import { ProductOfferingScopeTableActions } from "./components/ProductOfferingScopeTableActions";

// TODO: SC-52964 Get cost status enum from the BE
enum CostStatus {
  GetEstimate = "Get Estimate",
  InternalEstimate = "Internal Estimate",
  Awarded = "Awarded",
}

// Most of this is copied from the
// PlanPackageTakeoffTable component - src/routes/libraries/plan-package/takeoffs/components/TakeoffsTable/PlanPackageTakeoffTable.tsx
// Since need to support the same views and functionality of that table, for offering scope (excluding edit functionality)
export function ProductOfferingScopePage() {
  const { productOfferingId } = useParams<ProductOfferingParams>();
  const query = useProductOfferingScopeMetaQuery({ variables: { id: productOfferingId } });
  return (
    <>
      {queryResult(query, ({ productOffering }) => (
        <ProductOfferingContextProvider itemTemplate={productOffering.latestTemplate}>
          <ProductOfferingScopeView productOffering={productOffering} />
        </ProductOfferingContextProvider>
      ))}
    </>
  );
}

function ProductOfferingScopeView(props: ProductOfferingScopeMetaQuery) {
  const [{ devId: developmentId }] = useQueryParams({
    devId: StringParam,
  });
  const { productOffering } = props;
  const history = useHistory();
  const { showToast } = useToast();
  // Get the reactive store states
  const filter = useProductOfferingStore((state) => state.filter);
  const itemTableGroupBy = useProductOfferingStore((state) => state.itemTableGroupBy);
  const itemTableSortBy = useProductOfferingStore((state) => state.itemTableSortBy);

  const sideNavProps: SideNavProps = {
    sideNavConfig: [],
    top: <ProductOfferingScopePageSideBar {...props} />,
    navWidth: 265,
  };

  const { data, loading, previousData, fetchMore } = useProductOfferingScopePageQuery({
    variables: {
      // TODO: SC-53210 Add the groupBy, sortBy and filter table actions
      filter: { template: [productOffering.latestTemplate.id], excludeRemoved: true, ...filter },
      first: 25,
      order: [
        { field: itemTableGroupBy, direction: Order.Asc },
        // we would like the drops to be ordered at the top of the table when order by cost code or cost division
        ...(itemTableGroupBy === ItivOrderField.CostCode || itemTableGroupBy === ItivOrderField.CostDivision
          ? [
              {
                field: ItivOrderField.DevelopmentDrop,
                direction: Order.Asc,
              },
            ]
          : []),
        { field: itemTableSortBy, direction: Order.Asc },
      ],
    },
    fetchPolicy: "cache-and-network",
  });

  const failedGroupIds = useRef<string[]>([]);
  // Lazy load itivs as the user scrolls to the row
  // This allows us to support large datasets without loading all the data upfront
  const fetchMoreGroupItems = useCallback(
    async (groupTotals: ProductOfferingScopePage_GroupTotalsFragment) => {
      // Early return if the group has failed to load
      if (failedGroupIds.current.includes(groupTotals.groupId)) return;
      try {
        await fetchMore({
          variables: {
            first: null,
            subFilter: subFilterByOrder(itemTableGroupBy, groupTotals.groupId),
          },
        });
      } catch (e) {
        console.error(e);
        failedGroupIds.current.push(groupTotals.groupId);
        showToast({ type: "error", message: `Failed to fetch items for group ${groupTotals.displayName}` });
      }
    },
    [fetchMore, showToast, itemTableGroupBy, failedGroupIds],
  );

  const { items = [], groupTotals = [] } =
    data?.itemTemplateItemVersionsPage ?? previousData?.itemTemplateItemVersionsPage ?? {};
  const bidPackages = useMemo(() => productOffering.bidPackages, [productOffering.bidPackages]);

  const columns = useMemo(
    () => createColumns(fetchMoreGroupItems, bidPackages, developmentId),
    [fetchMoreGroupItems, bidPackages, developmentId],
  );
  const rows = useMemo(() => createRows(items, groupTotals, itemTableGroupBy), [items, groupTotals, itemTableGroupBy]);

  return (
    <SideNavLayout sideNavProps={sideNavProps}>
      <PageHeader
        title={productOffering.name}
        breadcrumb={[{ label: "Product Offerings", href: createProductOfferingsUrl(developmentId) }]}
      />
      <ProductOfferingScopeTableActions />
      <ScrollableContent virtualized>
        {loading ? (
          <>
            <LoadingSkeleton rows={1} columns={1} />
            <LoadingSkeleton rows={5} columns={5} />
          </>
        ) : rows.length === 1 && Object.keys(filter).length === 0 ? (
          // TBD: Is routing to edit offering page, the correct empty state CTA?
          <EmptyState
            message="There is no scope for this offering yet"
            svg={<EmptyPlanAndOptionImage />}
            button={
              <Button
                label="Edit Product Offering"
                onClick={() => history.push(createProductOfferingEditUrl(productOffering.id, developmentId))}
              />
            }
          />
        ) : (
          <ProductOfferingScopeTable columns={columns} rows={rows} />
        )}
      </ScrollableContent>
    </SideNavLayout>
  );
}

function createRows(
  itivs: ProductOfferingScopePage_ItivFragment[],
  groupTotals: ProductOfferingScopePage_GroupTotalsFragment[],
  itemTableGroupBy: ItivOrderField,
): GridDataRow<ItemTemplateItemRows>[] {
  return [
    { kind: "header", id: "header", data: undefined },
    ...groupTotals
      .filter((gt) => gt.groupId !== "template")
      .map((gt) => {
        const itemsInGroup = itivs.filter((rpo) => gt.itivIdsInGroup.includes(rpo.id)) ?? [];
        const children: Array<GridDataRow<ItemTemplateItemRows>> = [];
        // Group the itivs by drop if the groupBy is cost code or cost division
        if (itemTableGroupBy === ItivOrderField.CostCode || itemTableGroupBy === ItivOrderField.CostDivision) {
          // This ordering is reliant upon the conditional ordering in the query
          const noDropKey = "no-drop";
          const itemsGroupedByDrop = itemsInGroup.groupBy((itiv) => itiv.developmentDrop?.id ?? noDropKey);
          Object.keys(itemsGroupedByDrop).forEach((key) => {
            // This if is to handle a rendering bug where the no drop children are not rendered
            if (key === noDropKey) {
              // add no drop children to the children array
              if (itemsGroupedByDrop[noDropKey]) {
                itemsGroupedByDrop[noDropKey].forEach((itiv) => {
                  children.push({
                    kind: "itiv" as const,
                    id: itiv.id,
                    data: itiv,
                  });
                });
              }
              return;
            }
            const dropItivs = itemsGroupedByDrop[key];
            // Add the drop children to the children array
            children.push({
              kind: "drop" as const,
              id: dropItivs[0].developmentDrop!.id,
              data: {
                dropCode: dropItivs[0].developmentDrop!.code,
                name: dropItivs[0].developmentDrop!.name,
                numberOfItis: dropItivs.length,
              },
              children: dropItivs.map((itiv) => ({
                kind: "itiv" as const,
                id: itiv.id,
                data: itiv,
              })),
            });
          });
        } else {
          // If the groupBy is not cost code or cost division, render the itivs as is
          itemsInGroup.forEach((itiv) => {
            children.push({
              kind: "itiv" as const,
              id: itiv.id,
              data: itiv,
            });
          });
        }

        const loadedItems = itemsInGroup.length;
        const initCollapsed = loadedItems === 0;
        const partiallyLoaded = loadedItems !== gt.itivsInGroup;
        if (partiallyLoaded) {
          // Set the row kind to `loading` if the itivs are partially loaded
          children.push({ kind: "loading" as const, id: `loading-${gt.groupId}`, data: gt });
          if (loadedItems === 0)
            // If no itivs are loaded, set the groupBy row id to `not-loaded`
            return {
              kind: "groupBy" as const,
              initCollapsed,
              id: gt.groupId + "not-loaded",
              data: gt,
              children,
            };
        }
        return {
          kind: "groupBy" as const,
          id: gt.groupId,
          data: gt,
          children,
        };
      }),
  ];
}

export enum Column {
  ItemCode,
  Name,
  MaterialCode,
  GenericIcon,
  Quantity,
  UnitOfMeasure,
  Location,
  Option,
  TaskAllocation,
  UnitCost,
  CostSource,
  TotalCost,
}

function createColumns(
  fetchMoreGroupItems: (groupTotals: ProductOfferingScopePage_GroupTotalsFragment) => Promise<void>,
  bidPackages: ProductOfferingScopeMeta_BidPackageFragment[],
  developmentId: Maybe<string>,
) {
  return [
    collapseColumn<ItemTemplateItemRows>({ loading: emptyCell, sticky: "left" }),
    column<ItemTemplateItemRows>({
      id: `${Column.ItemCode}`,
      header: "Code",
      groupBy: (row) => ({
        colspan: itemColumnGroupByColspan,
        content: () => (
          <span css={Css.xlSb.w100.$}>
            <span css={Css.dib.truncate.w50.$}>
              {row.displayName.length > 55 ? (
                <Tooltip
                  title={
                    <ul css={Css.pl2.$}>
                      {row.displayName.split(",").map((name) => (
                        <li key={name}>{name}</li>
                      ))}
                    </ul>
                  }
                >
                  {row.displayName}
                </Tooltip>
              ) : (
                row.displayName
              )}
            </span>
            <span css={Css.gray700.sm.ml3.$}>
              {`${row.materialsInGroup} ${pluralize(row.materialsInGroup, "material")}`}
            </span>
            <span css={Css.gray700.sm.ml3.$}>{`${row.tasksInGroup} ${pluralize(row.tasksInGroup, "task")}`}</span>
          </span>
        ),
        alignment: "left",
        css: Css.maxw("66.5vw").$,
      }),
      itiv: (row) => <DropCodeCell scope={row} />,
      loading: (row) => ({
        content: () => {
          fetchMoreGroupItems(row).catch(console.error);
          return (
            <>
              Loading...
              <Icon icon="refresh" />
            </>
          );
        },
      }),
      mw: "100px",
      sticky: "left",
      drop: (row) => row.dropCode,
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.Name}`,
      header: "Name",
      groupBy: emptyCell,
      itiv: (row) => row.name,
      loading: emptyCell,
      mw: "205px",
      sticky: "left",
      drop: (row) => ({
        content: row.name,
        css: Css.xsSb.$,
      }),
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.MaterialCode}`,
      header: "Material Code",
      groupBy: emptyCell,
      itiv: (row) => row.materialVariant?.code,
      loading: emptyCell,
      mw: "120px",
      sticky: "left",
      drop: emptyCell,
    }),
    numericColumn<ItemTemplateItemRows>({
      id: `${Column.Quantity}`,
      header: "QTY",
      groupBy: () => emptyCell,
      itiv: (row) => (row.unitOfMeasure?.useQuantity ? row.quantity ?? "" : "N/A"),
      loading: emptyCell,
      align: "right",
      mw: "80px",
      drop: (row) => row.numberOfItis + " " + pluralize(row.numberOfItis, "item"),
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.UnitOfMeasure}`,
      header: "UoM",
      groupBy: () => emptyCell,
      itiv: (row) => row.unitOfMeasure?.abbreviation,
      loading: emptyCell,
      mw: "80px",
      drop: emptyCell,
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.Location}`,
      header: "Location",
      groupBy: () => emptyCell,
      itiv: (row) => row.location.displayLocationPath,
      loading: emptyCell,
      mw: "140px",
      drop: emptyCell,
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.Option}`,
      header: "Option",
      groupBy: () => emptyCell,
      itiv: (row) => {
        const optionsCodes = row.options.map((rpo) => (
          <ArchivedTag key={rpo.id} active={rpo.active}>
            {rpo.code}
          </ArchivedTag>
        ));
        return <TruncatedNameListCell nameList={optionsCodes} />;
      },
      loading: emptyCell,
      mw: "140px",
      drop: emptyCell,
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.TaskAllocation}`,
      header: "Task Allocation",
      groupBy: () => emptyCell,
      itiv: (row) => row.task?.name,
      loading: emptyCell,
      mw: "200px",
      drop: emptyCell,
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.UnitCost}`,
      header: "Unit Cost",
      groupBy: () => emptyCell,
      itiv: (row) => priceCell({ alignment: "left", valueInCents: row.preferredBidContractLineItem?.unitCostInCents }),
      loading: emptyCell,
      mw: "200px",
      drop: emptyCell,
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.CostSource}`,
      header: "Cost Source",
      groupBy: () => emptyCell,
      itiv: (row) => {
        const maybeBidPackage = bidPackages.find((bp) => bp.costCodes.some((cc) => cc.id === row.item.costCode.id));
        const url = !row.preferredBidContractLineItem
          ? // Route to the bidders tab if no preferredBCLI
            maybeBidPackage && createBidPackagesDetailUrl(maybeBidPackage.bidPackageGroup!.id, maybeBidPackage.id)
          : // Else route to the contract page (internal estimate or awarded should be the same contract route)
            // We can assume developmentId will never be undefined since we will always be coming from a dev offering
            // to get to this page for pilot
            createDevelopmentContractUrl(developmentId!, row.preferredBidContractLineItem.revision.id);

        // TODO: SC-52964 Get cost status directly from the BE
        const label = !row.preferredBidContractLineItem
          ? "Get Estimate"
          : row.preferredBidContractLineItem.revision.bidContract.isInternalEstimate
            ? "Internal Estimate"
            : "Awarded";

        return (
          <CostSourceCell
            url={url}
            label={label}
            icon={foldEnum(label, {
              [CostStatus.GetEstimate]: "dollar",
              [CostStatus.InternalEstimate]: "document",
              [CostStatus.Awarded]: "check",
            })}
          />
        );
      },
      loading: emptyCell,
      mw: "250px",
      drop: emptyCell,
    }),
    column<ItemTemplateItemRows>({
      id: `${Column.TotalCost}`,
      header: "Total Cost",
      groupBy: () => emptyCell,
      itiv: (row) => priceCell({ alignment: "left", valueInCents: row.totalCostInCents }),
      loading: emptyCell,
      mw: "200px",
      drop: emptyCell,
    }),
  ];
}

type HeaderRow = { kind: "header" };
type GroupByRow = { kind: "groupBy"; data: ProductOfferingScopePage_GroupTotalsFragment };
type ITIVRow = { kind: "itiv"; data: ProductOfferingScopePage_ItivFragment; id: string };
type DropRow = { kind: "drop"; data: { dropCode: string; name: string; numberOfItis: number }; id: string };
// LoadingRow is a row that is used to indicate that the group is loading
// Since we are lazy loading the table itivs
type LoadingRow = { kind: "loading"; data: ProductOfferingScopePage_GroupTotalsFragment; id: string };
export type ItemTemplateItemRows = HeaderRow | GroupByRow | ITIVRow | DropRow | LoadingRow;
export const itemColumnGroupByColspan = 8;
