import { useApolloClient } from "@apollo/client";
import { ButtonDatePicker, Css, Tooltip } from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { format, isPast } from "date-fns";
import { useState } from "react";
import {
  DevelopmentBulkScheduleTaskDetailFragment,
  Maybe,
  SaveScheduleTaskInput,
  Scalars,
  ScheduleSettingDetailsFragment,
  TaskStatus,
} from "src/generated/graphql-types";
import { BaselineChip } from "src/routes/components/BaselineChip";
import { getDisabledDays } from "src/routes/projects/schedule-v2/table/DateCellField";
import { determineTaskDateEditabilityById, getDateValueToUpdate } from "src/routes/projects/schedule-v2/table/utils";
import { DateOnly } from "src/utils/dates";

type SchedulePhaseIndicatorProps = {
  endDate?: Date;
  baseline?: Date;
  scheduleTask?: DevelopmentBulkScheduleTaskDetailFragment;
  status?: TaskStatus;
  scheduleIsLocked?: boolean;
  scheduleSetting?: ScheduleSettingDetailsFragment | null | undefined;
  onClick?: () => void;
  onSave?: (input: SaveScheduleTaskInput) => void;
};

export const SchedulePhaseIndicator = ({
  endDate,
  baseline,
  status,
  scheduleTask,
  scheduleIsLocked,
  scheduleSetting,
  onClick,
  onSave,
}: SchedulePhaseIndicatorProps) => {
  const [hovered, setHovered] = useState(false);
  const completed = status === TaskStatus.Complete;

  const client = useApolloClient();
  const formState = useFormState({
    config: formConfig,
    init: {
      id: scheduleTask?.id,
      startDate: scheduleTask?.interval.startDate,
      endDate: scheduleTask?.interval.endDate,
      durationInDays: scheduleTask?.interval.durationInDays,
    },
    autoSave: async (t) => {
      const canEdit = determineTaskDateEditabilityById(t.id.value!, client);
      const { input } = getDateValueToUpdate(t, t.changedValue, canEdit, scheduleSetting);
      const { id, startDate, durationInDays } = input;
      onSave && (await onSave({ id, startDate, durationInDays }));
    },
  });

  if (!endDate || !baseline) return <span>--</span>;

  const disabledDays = getDisabledDays(
    scheduleSetting,
    scheduleTask && { before: new DateOnly(scheduleTask.interval.startDate) },
  );
  const datepickerVisible = !!(scheduleTask && hovered);
  const datepickerDisabled = scheduleTask && (scheduleIsLocked || !scheduleTask.canEditEndDate.allowed);
  const tooltipText = datepickerDisabled
    ? 'Either the schedule is "Completed" or predecessors/ successors are locked'
    : "Open Calendar";

  async function onDateSelect(date: Date) {
    formState.endDate.set(new DateOnly(date));
  }

  const baselineDelta = {
    actualDate: endDate,
    baselineDate: baseline,
  };

  const isInPast = endDate && isPast(endDate);

  return (
    <div
      data-testid="phaseIndicator"
      css={Css.df.fdr.aic.jcsb.$}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        data-testid="phaseIndicatorDate"
        css={{
          ...Css.gray900.if(!!onClick).cursorPointer.$,
          ...Css.if(!completed).fw5.$,
          ...Css.if(completed || isInPast).gray600.$,
          ...Css.if(completed).tdlt.$,
        }}
        onClick={onClick}
      >
        <span>{format(endDate, "MMM dd")}</span>
        &nbsp;
        {!completed && <BaselineChip baselineDelta={baselineDelta} status={status} scheduleSetting={scheduleSetting} />}
      </div>
      <div
        data-testid="scheduleTaskDatePicker"
        css={Css.add("transition", "visibility 100ms").vh.if(datepickerVisible).vv.$}
      >
        <Tooltip title={tooltipText} placement="right">
          <ButtonDatePicker
            trigger={{ icon: "calendar", compact: true }}
            disabledDays={disabledDays}
            value={endDate}
            disabled={datepickerDisabled}
            onSelect={onDateSelect}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export type SchedulePhaseIndicatorFormInput = {
  id: Maybe<Scalars["ID"]>;
  startDate: Maybe<DateOnly>;
  endDate: Maybe<DateOnly>;
  durationInDays: Maybe<number>;
};

const formConfig: ObjectConfig<SchedulePhaseIndicatorFormInput> = {
  id: { type: "value" },
  startDate: { type: "value" },
  endDate: { type: "value" },
  durationInDays: { type: "value" },
};
