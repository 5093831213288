import { Css } from "@homebound/beam";
import { CommentFeed } from "src/components";
import { TaskDetailsPage_PlanTaskFragment } from "src/generated/graphql-types";

type CommentsCardProps = {
  task: TaskDetailsPage_PlanTaskFragment;
};

export function CommentsCard({ task }: CommentsCardProps) {
  return (
    <div css={Css.gc(1).gr(3).maxhPx(650).df.fdc.bgWhite.br8.p3.$}>
      <h2 css={Css.xlBd.mb1.$}>Comments</h2>
      <CommentFeed commentable={task} />
    </div>
  );
}
