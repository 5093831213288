import { Css, Only, Palette, Properties } from "@homebound/beam";
import { DragEvent, Fragment, useEffect, useReducer } from "react";
import S3Uploader, { S3Response } from "react-s3-uploader";
import { FileList, Icon, ProgressPill } from "src/components";
import { DocumentEditorDetailFragment } from "src/generated/graphql-types";
import { bytesToSize, getIconKeyByFileName } from "src/utils/files";

export type FileWithDocumentId = {
  /**
   * When we upload multiple files, we need to track the association of file --> document id
   * so we are assigning this property on the browser's `file` object so we can look it up later.
   */
  documentId?: string;
} & File;

export type FileUploaderXss = Pick<
  Properties,
  "marginTop" | "marginBottom" | "height" | "maxHeight" | "backgroundColor" | "borderColor"
>;

export type FileUploaderProps<X> = {
  // Used for the uploaded state information (filename, size, url).
  file: Partial<DocumentEditorDetailFragment> | undefined;
  // Content appearing inside the drop container.
  message: string;
  /** After a file has been selected, getUploadUrl will be called expecting a
   * signed url to use to upload the file.
   * It is called once for each file when multiple prop is true. */
  getUploadUrl: (file: FileWithDocumentId) => Promise<string>;
  /** Called once for each file when it has successfully been uploaded to S3.
   * This expects a resolve or reject promise to be returned to handle the UI state. */
  onFinish: (result: S3Response, file: FileWithDocumentId) => Promise<void>;
  // called when upload progress for a file is changed
  onProgress?: (progress: number, file: FileWithDocumentId) => void;
  // List of file types accepted by this component
  allowedFileTypes?: Array<string>;
  // Triggers the error state with the passed in error content
  error?: string;
  // When clicking on the garbage icon
  onClear?: () => void;
  readOnly?: boolean;
  xss?: X;
  // Testing utility props
  pretendPendingFile?: File;
  pretendProgress?: number;
  // enables multi-file upload
  multiple?: boolean;
};

/** Generic file uploader component to render "currently uploaded" / "upload new file" */
export function FileUploader<X extends Only<FileUploaderXss, X>>(props: FileUploaderProps<X>) {
  const {
    allowedFileTypes,
    error,
    file,
    getUploadUrl,
    message,
    multiple,
    onClear,
    onFinish,
    onProgress,
    pretendPendingFile,
    pretendProgress = 0,
    readOnly = false,
    xss,
  } = props;

  // Using a reducer to keep track of uploader state.
  const [{ progress, uploading, errorMessage, pendingFile, uploadComplete }, uploaderDispatch] = useReducer(
    uploaderStateReducer,
    {
      pendingFile: pretendPendingFile,
      progress: pretendProgress,
      errorMessage: error,
      // Use separate `uploading` state to flag when the file is uploaded to both S3 and our DB. `progress` only tracks uploading status to S3.
      uploading: false || !!pretendProgress,
    },
  );

  // We internally call setErrorMessage, but if our prop ever changes, reset error message to that
  useEffect(() => {
    if (error) {
      uploaderDispatch({ type: "error", errorMessage: error });
    }
  }, [error]);

  function getFile(): Partial<DocumentEditorDetailFragment> | undefined {
    if (pendingFile) return { name: pendingFile.name, sizeInBytes: pendingFile.size };
    return file;
  }

  const unsupportedTypeMessage = `The file must be a
    ${allowedFileTypes?.map((type) => type.split("/")[1].toUpperCase()).join(", ")}. Please use a different file.`;

  function handleFileUpload(pendingFile: File, next: (file: File) => void) {
    if (allowedFileTypes && !allowedFileTypes.includes(pendingFile.type)) {
      uploaderDispatch({ type: "error", errorMessage: unsupportedTypeMessage });
      return;
    }
    uploaderDispatch({ type: "start", pendingFile });
    next(pendingFile);
  }

  // `handleDrop` currently doesn't work. Needs to somehow be hooked up to the s3 preprocessor prop.
  // Ideally this would directly call `handleFileUpload`, but we don't have access to the `next` function from s3 preprocesser.
  // Maybe look at: https://www.npmjs.com/package/react-dropzone-s3-uploader
  function handleDrop(e: DragEvent<HTMLElement>) {
    preventDragOpen(e);
    const pendingFile = e.dataTransfer.files[0];

    if (allowedFileTypes && !allowedFileTypes.includes(pendingFile.type)) {
      uploaderDispatch({ type: "error", errorMessage: unsupportedTypeMessage });
      return;
    }

    // Forcing drag to show error so users click to upload a file
    uploaderDispatch({
      type: "error",
      errorMessage: "Drag and drop not supported. Please click to upload file",
    });
  }

  function preventDragOpen(e: DragEvent<HTMLElement>) {
    e.stopPropagation();
    e.preventDefault();
  }

  function clearFile() {
    uploaderDispatch({ type: "clear" });
    onClear && onClear();
  }

  const uploadedFile = getFile();
  if (readOnly) {
    // returns read only view that takes up the same vertical space as the editor.
    return (
      <div css={Css.df.aic.w100.hPx(72).br8.$} data-testid="read-only-file-uploader">
        {uploadedFile ? <FileList files={[uploadedFile]} /> : "No files uploaded"}
      </div>
    );
  }

  return (
    <Fragment>
      {uploadedFile ? (
        <div
          css={{
            ...Css.df.aic.w100.hPx(72).borderRadius("8px").jcfs.ba.bcGray400.bgGray100.$,
            "& .fa-file-pdf, & .fa-file-image, & .fa-file": Css.ml1.$,
            ...xss,
          }}
        >
          <Icon icon={getIconKeyByFileName(uploadedFile.name || "file")} inc={4} />
          <div css={Css.ml1.w("inherit").$}>
            <div data-testid="file-name">
              {uploadedFile.name}
              {uploadComplete && (
                <span css={Css.ml1.green600.$}>
                  <Icon icon="ok" />
                </span>
              )}
            </div>
            {uploading && (
              <div css={Css.w("97%").$}>
                <ProgressPill progress={progress} changeColorOnCompleted fullWidth hideProgress />
              </div>
            )}
            {uploadedFile.sizeInBytes && (
              <div css={Css.tinySb.$} data-testid="file-caption">
                {uploading ? `${progress}% done` : bytesToSize(uploadedFile.sizeInBytes)}
              </div>
            )}
          </div>
          <div css={Css.cursorPointer.mla.mr1.$} onClick={clearFile} data-testid="file-uploader-clear">
            <Icon icon="trash" inc={4} color={Palette.Gray700} />
          </div>
        </div>
      ) : (
        <label
          css={{
            ...Css.df.aic.w100.hPx(72).borderRadius("8px").bgGray100.ba.bsDashed.bcGray700.cursorPointer.$,
            ...{
              "& .fa-cloud-upload-alt": Css.prPx(10).$,
            },
            ...xss,
          }}
          htmlFor="s3-uploader"
          onDrop={handleDrop}
          onDragOver={preventDragOpen}
          onDragEnter={preventDragOpen}
          onDragLeave={preventDragOpen}
        >
          <div css={Css.mla.$}>
            <Icon icon="cloud" inc={4} color={Palette.Blue700} />
          </div>
          <div css={Css.mra.$}>
            <div css={Css.blue700.$} data-testid="fileUploader_message">
              {message}
            </div>
            {errorMessage && (
              <div data-testid="error-message" css={Css.tinySb.mt1.red700.$}>
                {errorMessage}
              </div>
            )}
          </div>
          <S3Uploader
            accept={allowedFileTypes?.toString()}
            autoUpload
            css={Css.dn.$}
            data-testid="s3-uploader"
            getSignedUrl={async (file, callback) => {
              const signedUrl = await getUploadUrl(file);
              callback({ signedUrl } as S3Response);
            }}
            id="s3-uploader"
            multiple={multiple}
            onFinish={async (result: S3Response, file: File) => {
              try {
                await onFinish(result, file);
                uploaderDispatch({ type: "complete" });
              } catch (error) {
                uploaderDispatch({ type: "error", errorMessage: "File could not be uploaded" });
              }
            }}
            onError={(e) => uploaderDispatch({ type: "error", errorMessage: e })}
            onProgress={(progress, _status, file: FileWithDocumentId) => {
              uploaderDispatch({ type: "progress", progress });
              onProgress && onProgress(progress, file);
            }}
            preprocess={handleFileUpload}
            uploadRequestHeaders={{}}
          />
          {errorMessage && (
            <span css={Css.mr3.$}>
              <Icon icon="alertError" inc={4} color={Palette.Red700} />
            </span>
          )}
        </label>
      )}
    </Fragment>
  );
}

type UploaderState = {
  pendingFile: File | undefined;
  progress: number;
  errorMessage: string | undefined;
  uploading: boolean;
  uploadComplete?: boolean;
};

type StartAction = { type: "start"; pendingFile?: File };
type ProgressAction = { type: "progress"; progress: number };
type ErrorAction = { type: "error"; errorMessage: string };
type CompleteAction = { type: "complete" };
type ClearAction = { type: "clear" };
type UploaderAction = StartAction | ProgressAction | ErrorAction | CompleteAction | ClearAction;

function uploaderStateReducer(state: UploaderState, action: UploaderAction): UploaderState {
  const reset = {
    errorMessage: undefined,
    pendingFile: undefined,
    progress: 0,
    uploading: false,
    uploadComplete: false,
  };

  switch (action.type) {
    case "start":
      return { ...reset, pendingFile: action.pendingFile, uploading: true };
    case "progress":
      // Keep the current state and only update progress.
      return { ...state, progress: action.progress };
    case "error":
      return { ...reset, errorMessage: action.errorMessage };
    case "clear":
      return reset;
    case "complete":
      return { ...reset, uploadComplete: true };
  }
}
