import { ButtonMenu } from "@homebound/beam";
import { useRouteMatch } from "react-router";
import { tradesPath } from "../../routesDef";
import { createBidPackagesUrl, createTradesPurchaseOrdersUrl, createTradesTradePartnersUrl } from "src/RouteUrls";

export function TradesNavMenu() {
  const isTradesPath = !!useRouteMatch([tradesPath]);
  return (
    <ButtonMenu
      key="tradesNavMenu"
      trigger={{ navLabel: "Trades", variant: "global", active: isTradesPath }}
      contrast
      items={[
        { label: "Bids", onClick: createBidPackagesUrl() },
        { label: "Contracts", onClick: () => console.log("globalContractsRoute add when created") },
        { label: "Purchase Orders", onClick: createTradesPurchaseOrdersUrl() },
        { label: "Trade Index", onClick: createTradesTradePartnersUrl() },
      ]}
      showActiveBorder
    />
  );
}
