import { Button, Css, IconButton, IconKey, Palette, ScrollShadows, SelectField, useBreakpoint } from "@homebound/beam";
import { useEffect, useState } from "react";
import { Icon } from "src/components";
import { JobLogDetailFragment, useSaveJobLogMutation, WeatherType } from "src/generated/graphql-types";
import { sortBy } from "src/utils";
type JobLogWeatherSummaryProps = {
  jobLog: JobLogDetailFragment;
  canEdit: boolean;
};

export function JobLogWeatherSummary(props: JobLogWeatherSummaryProps) {
  const { jobLog, canEdit } = props;
  const { maxTemperature, minTemperature, weatherType, wind, humidity, weatherTypeChanged } = jobLog;
  const [editMode, setEditMode] = useState(false);
  const [selectedWeatherType, setSelectedWeatherType] = useState(weatherType);
  const [saveJobLog] = useSaveJobLogMutation();
  const breakpoint = useBreakpoint();

  const sortedWeatherTypes = sortBy(weatherTypes, (wt) => weatherNames[wt.value]);

  useEffect(() => {
    setSelectedWeatherType(weatherType);
  }, [weatherType]);

  const onSave = async () => {
    await saveJobLog({
      variables: {
        input: {
          id: jobLog.id,
          weatherType: selectedWeatherType,
        },
      },
    });
    setEditMode(false);
  };

  const onCancel = () => {
    setEditMode(false);
    setSelectedWeatherType(jobLog.weatherType);
  };

  const textCss = Css.df.aife.ml2.$;
  const leftTextCss = Css.plPx(4).gray900.smMd.lh(1.3).$;
  const rightTextCss = Css.plPx(4).gray700.xsMd.$;
  const name = weatherType ? weatherNames[weatherType] : "Not available";
  const icon = selectedWeatherType ? weatherIcons[selectedWeatherType] : "sunny";

  return (
    <div css={Css.df.if(breakpoint.mdAndDown).maxwPx(225).$}>
      <ScrollShadows horizontal={true} bgColor={Palette.Gray100} xss={Css.df.aic.$}>
        <div css={Css.df.aic.whiteSpace("nowrap").$}>
          <Icon icon={icon} color={Palette.Gray900} />
          {editMode ? (
            <div id={"selectWeather"} css={Css.pl1.wPx(200).$}>
              <SelectField
                options={sortedWeatherTypes ?? []}
                getOptionLabel={({ value }) => weatherNames[value]}
                getOptionValue={({ value }) => value}
                onSelect={(v) => setSelectedWeatherType(v)}
                label={""}
                aria-label="selectWeather"
                value={selectedWeatherType}
              />
            </div>
          ) : (
            <div css={Css.gray900.smMd.ml2.$}>{`${name} ${weatherTypeChanged ? "*" : ""}`}</div>
          )}
        </div>
        {editMode ? (
          <div css={Css.df.ml2.$}>
            <div css={Css.df.aic.gap(1).$}>
              <Button variant="secondary" label="Cancel" onClick={onCancel} />
              <Button label={"Save"} onClick={onSave} />
            </div>
          </div>
        ) : (
          <div css={Css.df.$}>
            <div css={Css.df.aife.ml2.$}>
              <span css={leftTextCss}>{roundFloat(maxTemperature)}°</span>
              <span css={rightTextCss}>{roundFloat(minTemperature)}°</span>
            </div>
            <div css={textCss}>
              <span css={leftTextCss}>{roundFloat(humidity)}%</span>
              <span css={rightTextCss}>Humidity</span>
            </div>
            <div css={textCss}>
              <span css={leftTextCss}>{roundFloat(wind)}&nbsp;mph</span>
              <span css={rightTextCss}>Wind</span>
            </div>
          </div>
        )}
      </ScrollShadows>
      <div css={Css.sticky.right0.bgGray100.fg0.$}>
        {canEdit && !editMode && (
          <div css={Css.ml1.$}>
            <IconButton icon="pencil" inc={2} color={Palette.Gray900} onClick={() => setEditMode(true)} />
          </div>
        )}
      </div>
    </div>
  );
}

function roundFloat(floatString: string | null | undefined) {
  return floatString && parseFloat(floatString).toFixed();
}

const selectableWeatherTypes = [
  WeatherType.Cloudy,
  WeatherType.PartlyCloudyDay,
  WeatherType.Rain,
  WeatherType.Snow,
  WeatherType.ClearDay,
  WeatherType.ThunderDay,
  WeatherType.Wind,
  WeatherType.Sleet,
  WeatherType.Hail,
  WeatherType.Haze,
  WeatherType.Ice,
  WeatherType.Smoke,
  WeatherType.Dust,
];

export const weatherNames: Record<WeatherType, string> = {
  [WeatherType.ClearDay]: "Sunny",
  [WeatherType.PartlyCloudyDay]: "Partly Cloudy",
  [WeatherType.Cloudy]: "Cloudy",
  [WeatherType.Wind]: "Windy",
  [WeatherType.Fog]: "Fog",
  [WeatherType.ShowersDay]: "Rain",
  [WeatherType.Rain]: "Rain",
  [WeatherType.ThunderShowersDay]: "Thunderstorms",
  [WeatherType.ThunderDay]: "Thunderstorms",
  [WeatherType.SnowShowersDay]: "Snow",
  [WeatherType.Snow]: "Snow",
  [WeatherType.Sleet]: "Sleet",
  [WeatherType.Hail]: "Hail",
  [WeatherType.Haze]: "Haze",
  [WeatherType.Ice]: "Ice",
  [WeatherType.Smoke]: "Smoke",
  [WeatherType.Dust]: "Dust",
};

const weatherIcons: Record<WeatherType, IconKey> = {
  [WeatherType.ClearDay]: "sunny",
  [WeatherType.PartlyCloudyDay]: "partlyCloudy",
  [WeatherType.Cloudy]: "cloudy",
  [WeatherType.Wind]: "windy",
  [WeatherType.Fog]: "fog",
  [WeatherType.ShowersDay]: "rain",
  [WeatherType.Rain]: "rain",
  [WeatherType.ThunderShowersDay]: "thunderstorms",
  [WeatherType.ThunderDay]: "thunderstorms",
  [WeatherType.SnowShowersDay]: "snow",
  [WeatherType.Snow]: "snow",
  [WeatherType.Sleet]: "ice",
  [WeatherType.Hail]: "hail",
  [WeatherType.Haze]: "fog",
  [WeatherType.Ice]: "ice",
  [WeatherType.Smoke]: "fog",
  [WeatherType.Dust]: "fog",
};

export const weatherTypes = (Object.keys(WeatherType) as Array<keyof typeof WeatherType>)
  .filter((code) => selectableWeatherTypes.includes(WeatherType[code]))
  .map((code) => {
    const name = WeatherType[code];
    return { value: name };
  });
