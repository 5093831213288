import {
  BoundSelectField,
  BoundSwitchField,
  BoundTextField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectField,
  useModal,
} from "@homebound/beam";
import { useHistory } from "react-router";
import { createWarrantyTicketDetailsUrl } from "src/RouteUrls";
import {
  SaveWarrantyTicketInput,
  useWarrantyModalProjectsQuery,
  useSaveWarrantyTicketPageMutation,
  useWarrantyModalTicketTypesAndDevelopmentsQuery,
  ProjectStatus,
} from "src/generated/graphql-types";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useMemo, useState } from "react";
import { Observer } from "mobx-react";

export function CreateWarrantyTicketModal() {
  const result = useWarrantyModalTicketTypesAndDevelopmentsQuery();
  const developments = result.data?.developments ?? [];
  const warrantyTicketTypes = result.data?.warrantyTicketTypesDetail ?? [];

  const [development, setDevelopment] = useState<string>();
  const projectsQuery = useWarrantyModalProjectsQuery({
    variables: {
      filter: {
        development: development ? [development] : undefined,
        status: [ProjectStatus.UnderWarranty, ProjectStatus.Active, ProjectStatus.Completed],
      },
    },
  });

  const projects = useMemo(() => projectsQuery.data?.projectsPage.entities ?? [], [projectsQuery.data]);

  const [saveWarrantyTicket] = useSaveWarrantyTicketPageMutation();
  const history = useHistory();
  const { closeModal } = useModal();
  const formState = useFormState({
    config: formConfig,
    init: {
      title: undefined,
      homeownerVisible: true,
      projectId: undefined,
      type: undefined,
    },
  });

  async function createWarrantyTicket() {
    const result = await saveWarrantyTicket({
      variables: {
        input: formState.value,
      },
    });
    if (result.data?.saveWarrantyTicket.warrantyTicket.id) {
      history.push(createWarrantyTicketDetailsUrl(result.data?.saveWarrantyTicket.warrantyTicket.id, "Details"));
      closeModal();
    }
  }

  return (
    <>
      <ModalHeader>Create Warranty Request</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundTextField label="Title" field={formState.title} required fullWidth />
          <BoundSelectField
            options={warrantyTicketTypes}
            field={formState.type}
            getOptionValue={(ts) => ts.code}
            getOptionLabel={(ts) => ts.name}
            label="Type"
          />
          <SelectField
            label="Development"
            options={developments}
            value={development}
            onSelect={(id) => setDevelopment(id)}
          />
          <BoundSelectField label="Project" field={formState.projectId} options={projects} required />
          <BoundSwitchField label="H/O Visible" labelStyle="filter" field={formState.homeownerVisible} />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Observer>
          {() => <Button label="Create" onClick={createWarrantyTicket} disabled={!formState.valid} />}
        </Observer>
      </ModalFooter>
    </>
  );
}

type CreateWarrantyTicketInput = Pick<SaveWarrantyTicketInput, "title" | "homeownerVisible" | "projectId" | "type">;

const formConfig: ObjectConfig<Partial<CreateWarrantyTicketInput>> = {
  title: { type: "value", rules: [required] },
  homeownerVisible: { type: "value" },
  type: { type: "value", rules: [required] },
  projectId: { type: "value", rules: [required] },
};
