import { useParams } from "react-router";
import { BidPackageDetailPageQuery, useBidPackageDetailPageQuery } from "src/generated/graphql-types";
import { BidPackageParams } from "../routesDef";
import { queryResult } from "src/utils";
import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { BidPackageHeader } from "./components/BidPackageHeader";
import { BiddersTab } from "./components/BiddersTab";
import { BidsTab } from "./components/BidsTab";
import { BillTermsTab } from "src/routes/bids/components/BillTermsTab";

export function BidPackageDetailPage() {
  const { bidPackageId } = useParams<BidPackageParams>();
  const query = useBidPackageDetailPageQuery({ variables: { id: bidPackageId } });
  return queryResult(query, ({ bidPackage }) => <BidPackageDetailView bidPackage={bidPackage} />);
}

type TabValue = "bids" | "bidders" | "billTerms";

export function BidPackageDetailView({ bidPackage }: { bidPackage: BidPackageDetailPageQuery["bidPackage"] }) {
  const [selectedTab, setSelectedTab] = useState<TabValue>("bids");
  const tradePartnerRevisions = bidPackage.requests.map((request) => ({
    tradePartner: request.tradePartner,
    revision: request.bidContract?.latestRevision,
  }));
  return (
    <>
      <BidPackageHeader bidPackage={bidPackage} />
      <TabsWithContent
        tabs={[
          { name: "Bids", value: "bids", render: () => <BidsTab bidPackage={bidPackage} /> },
          { name: "Bidders", value: "bidders", render: () => <BiddersTab requests={bidPackage.requests} /> },
          {
            name: "Bill Terms",
            value: "billTerms",
            render: () => (
              <BillTermsTab
                tradePartnerRevisions={tradePartnerRevisions}
                basePlanGlobalTasks={bidPackage.basePlanGlobalPlanTasks}
              />
            ),
          },
        ]}
        selected={selectedTab}
        onChange={setSelectedTab}
      />
    </>
  );
}
